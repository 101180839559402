import React from "react";
import "./search.css";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardBannerImage from "../../Assets/images/DashboardBannerImage.png"
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
class SearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      licentiate: [],
      verticals:[],
      all_data:[],
      exams:[],
      topics:[],
      bannerImage: `${DashboardBannerImage}`,
      collapseID: 0,
      page_head: "",
      abc: [],
      vertical: {},
      all_exams: [],
      loading: false,
      exams: [],
      cart_items: [],
      cart: [],
      all_subjects: [],
      subjects:[],
      selected_exam: null,
      view_all: false,
      data:[],
      selected_subs: [],query:'',banner:{}
    };
  }

  toggleCollapse(id, text, item) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
        page_head: ""
      });
    } else {
      console.log("eeee");
      var subjects = [];
      let { all_subjects } = this.state;
      for (var v1 = 0; v1 < all_subjects.length; v1++) {
        for (var v2 = 0; v2 < JSON.parse(all_subjects[v1].exams).length; v2++) {
          if (JSON.parse(all_subjects[v1].exams)[v2].exam_id == item.exam_id) {
            subjects.push(all_subjects[v1]);
          }
        }
      }
      this.setState({
        selected_subs: subjects,
        collapseID: id,
        page_head: text
      });
    }
  }
  componentWillMount() {
      console.log('search')
    this.getDetail();
    this.getSubjects();
    this.getBanner()
  }
  getDetail() {
      
    var data = window.localStorage.getItem("searched_data");
    var query = window.localStorage.getItem("searched_string");
    var col = window.localStorage.getItem("collapse");
    var userdata = window.localStorage.getItem("userdata");
    this.setState(
        {
            data: JSON.parse(data),
            query:query,
           
        },()=>console.log(this.state.data, "ccccccc")
      )
    
    
    if (Number(col) != 0) {
      this.setState({
        collapseID: Number(col)
      });
    }
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata)
        },
        () => this.getCart()
      );
    }
    
  }
  getSubjects = () => {
    this.setState({
      loading:true
    })
    
     this.setState({
       response: fetch("https://www.vimasmiham.com/get_subjects", {
         method: "GET",
         headers: {
           "Content-Type": "application/x-www-form-urlencoded",
           "Cache-Control": "max-age=31536000"
         },
       
       })
         .then(response => response.json())
         .then(responseJson => {
          
 console.log('subjects')
           this.setState({
             subjects: responseJson,
            
           },()=>this.getTopics());
         })
         .catch(error => {
           this.setState({
             loading: false
           });
 
           console.log(error);
         })
     });
   };
   getBanner = () => {
    this.setState({
      loading:true
    })
    
     this.setState({
       response: fetch("https://www.vimasmiham.com/get_banner", {
         method: "GET",
         headers: {
           "Content-Type": "application/x-www-form-urlencoded",
           "Cache-Control": "max-age=31536000"
         },
       
       })
         .then(response => response.json())
         .then(responseJson => {
          
 console.log(responseJson,'get_banner')
           this.setState({
             banner: responseJson,
            
           });
         })
         .catch(error => {
           this.setState({
             loading: false
           });
 
           console.log(error);
         })
     });
   };
   getTopics = () => {
     this.setState({
       loading:true
     })
     
      this.setState({
        response: fetch("https://www.vimasmiham.com/get_topics", {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000"
          },
        
        })
          .then(response => response.json())
          .then(responseJson => {
            
          
            this.setState({
              topics: responseJson,
             
            },()=>this.getVerticalsFetch());
          })
          .catch(error => {
            this.setState({
              loading: false
            });
  
            console.log(error);
          })
      });
    };
   getVerticalsFetch =  () => {
     this.setState({
     
     });
 
     this.setState({
       response: fetch("https://www.vimasmiham.com/get_verticals", {
         method: "GET",
         headers: {
           "Content-Type": "application/x-www-form-urlencoded",
           "Cache-Control": "max-age=31536000"
         }
       })
         .then(response => response.json())
         .then(responseJson => {
          
           this.setState(
             {
               verticals: responseJson.reverse(),
               
             },
             () => this.getExams()

           );
         })
         .catch(error => {
           this.setState({
             loading: false
           });
 
           console.log(error);
         })
     });
   };

 getAllData(){
  let {data} = this.state
     var topic_1=[],subjects_1=[],verticals_1=[],exams_1=[],count=0
    console.log(this.state.topics,this.state.verticals,this.state.exams,this.state.data)
    for(var v=0;v<data.length;v++){
        if(data[v].data_type=='topic'){
            topic_1.push(data[v])
            count++
        }
        else if(data[v].data_type=='subject'){
            subjects_1.push(data[v])
            count++
        }
        else if(data[v].data_type=='vertical'){
            verticals_1.push(data[v])
            count++
        }
        else if(data[v].data_type=='exam'){
            exams_1.push(data[v])
            count++
        }
    }
    if(count==this.state.data.length){
        this.createObjects(subjects_1,topic_1,verticals_1,exams_1)
    }
   
 }
 selectSubject(item) {
  window.localStorage.removeItem("selected_subject");
  window.localStorage.setItem("selected_subject", JSON.stringify(item));
  this.props.history.push("/product-details");
}
 createObjects(subject_searched,topic_searched,vertical_searched,exam_searched){
     console.log(subject_searched)
    let {topics,subjects,verticals,exams} = this.state
    var all_subjects=[],subject_count=0
     for(var v1=0;v1<subjects.length;v1++){
        subject_count++
         for(var v2=0;v2<JSON.parse(subjects[v1].topics).length;v2++){
            for(var v3=0;v3<topic_searched.length;v3++){
             if(topic_searched[v3].topic_id==JSON.parse(subjects[v1].topics)[v2].topic_id){
                all_subjects.push(subjects[v1])
                
             }
             
            } 
           
           
         }
         for(var v4=0;v4<subject_searched.length;v4++){
            if(subject_searched[v4].subject_id==subjects[v1].subject_id){
               all_subjects.push(subjects[v1])
               
            }
            
           }
         
     }
    //  for(var v5=0;v5<verticals.length;v5++){
    //   for(var v6=0;v6<vertical_searched.length;v6++){
    //     if(topic_searched[v3].topic_id==JSON.parse(subjects[v1].topics)[v2].topic_id){
    //        all_subjects.push(subjects[v1])
           
    //     }
        
    //    } 
    //  }
     console.log(subject_count,topic_searched.length,'all_subjects')
        this.checkDuplicates(all_subjects)
     

 }
 
 checkDuplicates(originalArray) {

    var trimmedArray = [];
   
    var all_courses = originalArray
if(originalArray.length!=0){
for(var i=0;i<originalArray.length;i++){
    for(var i1=0;i1<originalArray.length;i1++){

   if(i!=i1){
    console.log(originalArray[i],i,originalArray[i1],i1)
    if(originalArray[i].subject_id==originalArray[i1].subject_id){
      //   console.log(originalArray[i],originalArray[i1],'iii')
         var matched_object = originalArray[i]

         
         trimmedArray.push(matched_object)
        }
   }
     
  }


}
}

for(var i3=0;i3<all_courses.length;i3++){
  for(var i4=0;i4<trimmedArray.length;i4++){
    if(all_courses[i3].subject_id==trimmedArray[i4].subject_id){
      all_courses.splice(i3,1)
      all_courses.splice(i3,0,trimmedArray[i4])
      }
    }
  }
    this.removeDuplicates(all_courses,'subject_id')
}



removeDuplicates(originalArray, objKey) {
  var trimmedArray = [];
  var values = [];
  var value;

  for(var i = 0; i < originalArray.length; i++) {
    value = originalArray[i][objKey];

    if(values.indexOf(value) === -1) {
      trimmedArray.push(originalArray[i]);
      values.push(value);
    }
  }

  this.setState({
    all_data:trimmedArray,
    loading:false
  },()=>console.log(this.state.all_data,'iii'))

}
   getExams = async () => {
     this.setState({
       response: fetch("https://www.vimasmiham.com/get_exams", {
         method: "GET",
         headers: {
           "Content-Type": "application/x-www-form-urlencoded"
         }
       })
         .then(response => response.json())
         .then(responseJson => {
          
            this.setState(
             {
               exams: responseJson.reverse(),
             },()=>this.getAllData())
             
           
           
         })
         .catch(error => {
           this.setState({
             loading: false
           });
           console.log(error);
         })
     });
   };

  getVerticals(data) {
    var selected_verticals = [];
    for (var v = 0; v < data.length; v++) {
      for (var v1 = 0; v1 < JSON.parse(data[v].verticals).length; v1++) {
        if (
          this.state.vertical.vertical_id ==
          JSON.parse(data[v].verticals)[v1].vertical_id
        ) {
          selected_verticals.push(data[v]);
        }
      }
    }
    this.setState({
      exams: selected_verticals.reverse()
    });
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson,
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };
  

  renderSubject_demo(){
     return(
        this.state.all_data.map((item,index)=>{
          console.log(item,'iiiii')
            return(
                <div className='search-row-container2' onClick={()=>this.selectSubject(item)}>             
                    <div className='rowDefault search-row'>
                  <img className='search-image' src={item.image} />
                  <div className='search-star-mobile'>
                      <span className='search-subject-name' >{item.subject_name}</span>
{/* 
                      {item.rating != 0 ? (
                        ""
                      
                   
        ) : (
          <div style={{marginBottom:0,marginRight:0}} className="search-page-course-rating d-flex ">



<StarRatings
  rating={Number(item.ratings)}
  starDimension="16px"
  starSpacing="2px"
  starRatedColor="#dbaa34"
  style={{ marginRight: 4 }}
  name="search-page-class-rating"
/>

<span style={{ color: "black" }} className="product-page-review-font">
  ({item.total_ratings}),{" "}
  {item.enrollments} Students Enrolled
</span>
</div>
          )} */}

        <div>

        <span className="productMainSecondTextp" style={{marginBottom:0}}>
          Created By {item.author}
         
        </span>

        </div>

     
        <p className='search-subject-duration'>Duration : {Math.floor(Number(item.duration)/60)} {Math.floor(Number(item.duration)/60)>1?'hours':'hour'}</p>
                  </div>
                </div>
                </div>

            )
        })
     )
  }
  renderBanner(){
    if(this.state.banner.status==1){
      return(
        <div >  <img className='offer_banner' src={this.state.banner.banner_link} /></div>
      )
    }
  }
 
  selectVertical(item) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    window.scrollTo(0, 0);

    this.props.history.push("/dashboard");
  }
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
        console.log(this.state.data,this.state.query)
      return (
        <div>
          <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            active="My Dashboard"
          />
          <div className="dashboard-main-body">
            {/* <div className='pageHeadFont'><text>Home > Exams > Insurance Institute Exams > {this.state.page_head}</text></div> */}

            <div className='count-container'>
            <p className="search-banner mt-4 ml-lg-5">Search results for '{this.state.query}'</p>
            </div>
           


          <div className='rowDefault dashboard-para-img-mobile'>
          <div className="display-dashbord-tabs colDefault search-row-container" style={{width:this.state.banner.status==0?'100%':'75%'}}>
          <p className="search-banner mt-4 ml-lg-5">Subjects related to your search</p>
            {this.renderSubject_demo()}
            <p className="search-banner mt-4 ml-lg-5">Verticals related to your search</p>

            {this.state.data.map((item,index)=>{
              if(item.data_type=='vertical'){
                return(
                  <div className='search-row-container2'  onClick={()=>this.selectVertical(item)}>             
                    <div className='rowDefault search-row'>
                  <img className='search-image-vertical' src={item.pictogram} />
                  <div className='search-star-mobile'>
                      <span className='search-subject-name' 
                     
                      >{item.vertical_name}</span>
                      
                  </div>
                </div>
                </div>
                )
              }

            })}
            <p className="search-banner mt-4 ml-lg-5">Exams related to your search</p>
            {this.state.data.map((item,index)=>{
              var itemA
              if(item.data_type=='exam'){
                return(
                  <div className='search-row-container2'  onClick={()=>this.selectVertical(itemA)}  >             
                    <div className='rowDefault search-row'>
                  <img className='search-image-vertical' src={require('../../Assets/images/exam.png')} />
                  <div className='search-star-mobile'>
                      <span className='search-subject-name2' 
                    
                      >{item.exam_name}</span>
                      <p style={{marginBottom:0,fontSize:12,color:'grey'}}>Vertical</p>
                      {JSON.parse(item.verticals).map((item1,index1)=>{
                         itemA= item1
return( <p  className='searchvertical2'>{item1.vertical_name}</p>)
                      })}
                  </div>
                </div>
                </div>
                )
              }

            })}
            </div>
          {this.renderBanner()}
          </div> 
          </div>
         


          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
        </div>
      );
    }
  }
}

export default SearchResults;
