import React, { Component } from "react";
import "../SubjectPage/subjectPage.css";
import SidebarPage from "../Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import swal from "sweetalert";

import { MDBTable, MDBTableBody } from "mdbreact";
import { HalfCircleSpinner } from "react-epic-spinners";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import "../../commonStyles.css";

class AllTopicsQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      questions: [],
      showAction: false,
      all_selected: true,
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: [],
    };
  }
  componentWillMount() {
    this.setState({ loading: true }, () => this.getQuestions());
  }

  componentDidMount() {
    window.localStorage.removeItem("question_item");
  }

  getQuestions = async () => {
    this.setState({
      loading: true,
    });

    try {
      const topicId = window.localStorage.getItem("questions_topic");
      if (!topicId) {
        this.props.history.push("/admin/all-topics");
      } else {
        let formBody = new FormData();
        formBody.append("topic_ids", JSON.stringify([topicId]));
        formBody.append("type", "mixed");
        const res = await fetch(
          "https://vimasmiham.com/get_specific_questions",
          {
            method: "POST",
            body: formBody,
          }
        );
        const resJson = await res.json();

        let arr = [];
        resJson.forEach((element) => {
          arr = [...arr, ...element];
        });
        console.log("data", arr);
        if (arr.length === 0) {
          swal({
            title: "No Questions Found",
            text: "",
            confirmButtonText: `Save`,
            dangerMode: true,
          }).then((result) => {
            if (result) {
              //   this.props.history.push("/admin/all-topics");
              window.close();
            } else return;
          });
        }

        this.setState({
          questions: arr,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      swal("Warning!", "Check your network!", "warning");
      console.log(error);
    }

    // this.setState({
    //   response: fetch("https://vimasmiham.com/get_specific_questions", {
    //     method: "GET",
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //       console.log(responseJson);
    //       var draft_count = 0,
    //         published_count = 0,
    //         trash_count = 0;
    //       for (var v = 0; v < responseJson.length; v++) {
    //         responseJson[v].selected = 0;
    //         if (responseJson[v].status == 0) {
    //           draft_count = draft_count + 1;
    //         } else if (responseJson[v].status == 1) {
    //           published_count = published_count + 1;
    //         } else if (responseJson[v].status == 2) {
    //           trash_count = trash_count + 1;
    //         }
    //       }
    //       this.setState({
    //         questions: responseJson,
    //         draft_count: draft_count,
    //         published_count: published_count,
    //         trash_count: trash_count,
    //         loading: false,
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         loading: false,
    //       });
    //       swal("Warning!", "Check your network!", "warning");
    //       console.log(error);
    //     }),
    // });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  handleEditQuestion(item) {
    window.localStorage.setItem("question_item", JSON.stringify(item));
    //  window.localStorage.setItem('subjet_list',JSON.stringify(this.state.questions))
    this.props.history.push("/admin/edit-question");
  }

  //   handleAddNewQuestion() {
  //     //   window.localStorage.setItem('subject_list',JSON.stringify(this.state.subjects))
  //     this.props.history.push("/admin/add-question");
  //   }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            |{" "}
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditQuestion(item, index)}
              className="actionstext"
            >
              Edit
            </span>{" "}
            <span className="opacity-0">|</span>
            <span
              onClick={() => this.trashAlert(item)}
              className="actionstext d-none"
              style={{ color: "#a00" }}
            >
              Trash
            </span>{" "}
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext d-none">Edit</span> |{" "}
          <span className="actionstext" style={{ color: "#a00" }}>
            Trash
          </span>{" "}
        </div>
      );
    }
  }

  showactionbutton(item, index) {
    let { questions } = this.state;
    let selected_page = questions[index];
    for (var v = 0; v < questions.length; v++) {
      questions[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      questions,
    });
  }
  hideactionbutton() {
    let { questions } = this.state;

    for (var v = 0; v < questions.length; v++) {
      questions[v].selected = 0;
    }

    this.setState({
      questions,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }
  trashAlert = (item) => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to move this page to trash",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.trashPage(item);
      } else return;
    });
  };
  deleteAlert = (item) => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to delete this page",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.deletePage(item);
      } else return;
    });
  };
  restoreAlert = (item) => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to restore this page",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.restorePage(item);
      } else return;
    });
  };
  trashPage(item) {
    this.setState({
      loading: true,
    });
    let details = {
      id: item.question_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("http://18.221.183.249:3000/trash_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  changeStatus(item, status) {
    this.setState({
      loading: true,
    });
    let details = {
      question_id: item.question_id,
      status: status,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/subject_status", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }

  deleteQuestion(item) {
    console.log(item);
    let formData = new FormData();
    formData.append("id", item.question_id);
    fetch("https://vimasmiham.com/delete_question", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.status == 200) {
          swal("Success", "Question deleted successfully", "success");
          this.getQuestions();
        } else {
          return swal("Failed", "Could not delete question", "error");
        }
      })
      .catch((err) => {
        return swal("Failed", "Could not delete question", "error");
      });
  }

  renderTableData(item, index) {
    if (this.state.all_selected == true) {
      if (item.status != 2) {
        return (
          <tr key={item.question_id}>
            {/* <td>
              <input
                onChange={() => this.onToggle(index)}
                checked={item.checked == 1 ? true : false}
                type="checkbox"
              />
            </td> */}
            {/* <td>{item.question_id}</td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                  dangerouslySetInnerHTML={{ __html: item.question_title }}
                ></span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {item.difficulty_level == 1
                ? "Easy"
                : item.difficulty_level == 2
                ? "Moderate"
                : "Tough"}
            </td>
            <td className="datefontsize">
              {this.renderStatus(item)} <br />{" "}
              <span className="tabledate">
                {this.commentdate(item.creation_time)}
              </span>
            </td>
            <td>{this.renderActiveStatus(item)}</td>
            <td
              className="text-danger cursor-pointer"
              onClick={() => this.deleteQuestion(item)}
            >
              Delete
            </td>
          </tr>
        );
      }
    } else if (this.state.published_selected == true) {
      if (item.status == 1) {
        return (
          <tr key={item.question_id}>
            <td>
              <input className="checkbox_content" type="checkbox" />
            </td>
            {/* <td>{item.question_id}</td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.subject_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {item.difficulty_level == 1
                ? "Easy"
                : item.difficulty_level == 2
                ? "Moderate"
                : "Tough"}
            </td>
            <td className="datefontsize">
              {this.renderStatus(item)} <br />{" "}
              <span className="tabledate">
                {this.commentdate(item.creation_time)}
              </span>
            </td>
            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    } else if (this.state.draft_selected == true) {
      if (item.status == 0) {
        return (
          <tr key={item.question_id}>
            <td>
              <input className="checkbox_content" type="checkbox" />
            </td>
            {/* <td>{item.question_id}</td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.subject_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {item.difficulty_level == 1
                ? "Easy"
                : item.difficulty_level == 2
                ? "Moderate"
                : "Tough"}
            </td>
            <td className="datefontsize">
              {this.renderStatus(item)} <br />{" "}
              <span className="tabledate">
                {this.commentdate(item.creation_time)}
              </span>
            </td>
            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    } else if (this.state.trash_selected == true) {
      if (item.status == 2) {
        return (
          <tr key={item.question_id}>
            <td>
              <input className="checkbox_content" type="checkbox" />
            </td>
            {/* <td>{item.question_id}</td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.subject_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {item.difficulty_level == 1
                ? "Easy"
                : item.difficulty_level == 2
                ? "Moderate"
                : "Tough"}
            </td>
            <td className="datefontsize">
              {this.renderStatus(item)} <br />{" "}
              <span className="tabledate">
                {this.commentdate(item.creation_time)}
              </span>
            </td>{" "}
          </tr>
        );
      }
    }
  }

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { questions, selected_data } = this.state;
    let selected_item = questions[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ questions });
  }

  handleAllChecked = (event) => {
    let { questions } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < questions.length; v++) {
        questions[v].checked = 1;
      }
      this.setState({ selected_data: questions });
    } else {
      for (var v = 0; v < questions.length; v++) {
        questions[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ questions });
  };

  //   downloadQuestionsFile = () => {
  //     window.open("https://vimasmiham.com/generate_questions_file");
  //   };

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingSpin d-flex justify-content-center">
          <HalfCircleSpinner color="#3A7FCA" />
        </div>
      );
    } else {
      return (
        <div id="subject-page-list">
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={8} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0 }}>Questions</h3>
              </div>
              <div>
                <MDBTable
                  paging={true}
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    {/* <th>
                      <input
                        type="checkbox"
                        onClick={(event) =>
                          this.setState(
                            { item_selected: !this.state.item_selected },
                            () => this.handleAllChecked(event)
                          )
                        }
                        checked={this.state.item_selected}
                        value="checkedall"
                      />
                    </th> */}
                    {/* <th>Id</th> */}
                    <th className="">Title</th>
                    <th>Difficulty</th>
                    <th className="textFontSize">Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>

                  <MDBTableBody>
                    {this.state.questions.map((item, index) => {
                      return this.renderTableData(item, index);
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default AllTopicsQuestions;
