import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import { MDBIcon } from "mdbreact";
import AdminNavbar from "../Navbar/adminNavbar";
import "../Lectures/Addlecture.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import CKEditor from "ckeditor4-react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class addVerticals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      vertical_name: " ",
      verticalSEOTitle: "",
      coming: "",
      verticalSEODescription: "",
      verticalsThumbnail: null,
      verticalsThumbnailUrl: "",
      url: ``,
      content: ``,
    };
  }
  componentWillMount() {}

  addVerticals = () => {
    console.log(this.state.topicname);
    if (this.state.vertical_name.length == 0) {
      return swal("Check vertical name!", "Please enter valid name", "error");
    }
    if (this.state.url.length <= 2) {
      return swal("Check vertical URL!", "Please enter valid URL", "error");
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });
      let comingDB;
      if (this.state.coming == true) comingDB = 1;
      else comingDB = 0;
      let formData = new FormData();
      formData.append("vertical_name", this.state.vertical_name);
      formData.append("file", this.state.verticalsThumbnail);
      formData.append("page_title_seo", this.state.verticalSEOTitle);
      formData.append(
        "page_description_seo",
        this.state.verticalSEODescription
      );
      formData.append("url", this.state.url);
      formData.append("status", 1);
      formData.append("mark_coming_soon", comingDB);
      formData.append("content", this.state.content)

      for (let key of formData.entries()) {
        console.log(key[0] + ",with photo " + key[1]);
      }

      this.setState({
        response: fetch("https://www.vimasmiham.com/add_vertical", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "QWERTy");
            swal(
              "Success",
              "Vertical succesfullly added ! Please Go to Exams menu to add an Exam into Vertical !",
              "success"
            ).then((value) => {
              this.props.history.push("/admin/verticals");
            });
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  comingSoon() {
    this.setState({ coming: !this.state.coming }, () =>
      console.log(this.state.coming)
    );
  }
  renderComingSoon() {
    console.log(this.state.coming);
    return (
      <div className="col-12 ">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Coming Soon:
        </label>
        {this.state.coming == true ? (
          <input
            type="checkbox"
            name="coming_soon"
            value="coming_soon"
            style={{ width: "5%" }}
            onClick={() => this.comingSoon()}
            checked
          />
        ) : (
          <input
            type="checkbox"
            name="coming_soon"
            value="coming_soon"
            style={{ width: "5%" }}
            onClick={() => this.comingSoon()}
          />
        )}
      </div>
    );
  }
  renderVerticalNameSection() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.vertical_name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) => this.setState({ vertical_name: e.target.value })}
        />
      </div>
    );
  }
  renderSEOTitle() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          SEO Title:
        </label>
        <input
          value={this.state.verticalSEOTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) => this.setState({ verticalSEOTitle: e.target.value })}
        />
      </div>
    );
  }
  renderSEODescription() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          SEO Description:
        </label>
        <input
          value={this.state.verticalSEODescription}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) =>
            this.setState({ verticalSEODescription: e.target.value })
          }
        />
      </div>
    );
  }

  renderThumbnailVerticalImageSection() {
    if (!this.state.verticalsThumbnail) {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels"
          >
            Thumbnail:
          </label>
          <input
            type="file"
            className="thumbnail-file-input d-block"
            accept="image/*"
            aria-describedby="inputGroupFileAddon01"
            onChange={(event) =>
              this.setState({
                verticalsThumbnail: event.target.files[0],
                verticalsThumbnailUrl: URL.createObjectURL(
                  event.target.files[0]
                ),
              })
            }
          />
        </div>
      );
    } else {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels h-100"
          >
            Thumbnail:
          </label>
          <div className="position-relative">
            <img
              src={this.state.verticalsThumbnailUrl}
              style={{ margin: "10px 0px", width: "100px" }}
            />
            <MDBIcon
              style={{
                position: "absolute",
                top: "4px",
                right: "-14px",
                cursor: "pointer",
              }}
              className="cross_icon"
              onClick={() =>
                this.setState({
                  subjectThumbnail: null,
                  subjectThumbnailUrl: null,
                })
              }
              icon="times-circle"
            />
          </div>
        </div>
      );
    }
  }

  renderVerticalURL() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          URL/Link:
        </label>
        <input
          value={this.state.url}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) => this.setState({ url: e.target.value })}
        />
      </div>
    );
  }

  onEditorChange(e) {
    this.setState({ content: e.editor.getData() });
  }

  renderVerticalContent() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="image-label subject-labels h-100"
        >
          Content:
        </label>
        <CKEditor
          data={this.state.content}
          onChange={(e) => this.onEditorChange(e)}
          className="w-75"
        />
      </div>
    );
  }

  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={1} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/verticals")
                        }
                      >
                        All Verticals
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.addVerticals()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Add Vertical</h3>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderVerticalNameSection()}
                        {this.renderThumbnailVerticalImageSection()}
                        {this.renderSEOTitle()}
                        {this.renderSEODescription()}
                        {this.renderVerticalURL()}
                        {this.renderComingSoon()}
                        {this.renderVerticalContent()}
                      </div>
                    </div>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default addVerticals;
