import React, {Component} from 'react'
import AdminNavbar from '../Navbar/adminNavbar'
import SidebarPage from '../../../Components/Admin/Sidebar/sidebarPage'

import DatePicker from "react-datepicker";

import Select from 'react-select';
import {MDBIcon} from 'mdbreact';
import MediaComponent from "../MediaComponent/chooseMediaFile";

// import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import swal from 'sweetalert'

import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';


import CKEditor from 'ckeditor4-react';


const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;


class AddCaselaw extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:true,
            subjectId:``,
            instructorSay:'',
            caseLawCourtName:"",
            title:"",
            subjectCode:"",
            decisionDate:new Date(),
            authors:[{label:'Vikram Mathur',value:'Vikram Mathur',author_id:1},
            {label:'Gaurav',value:'Gaurav',author_id:2},
            {label:'Madhur',value:'Madhur',author_id:3},
            {label:'Param',value:'Param',author_id:4},
            {label:'Shalini',value:'Shalini',author_id:5},
            {label:'Pallavi',value:'Pallavi',author_id:6}],
            // 'Vikram Mathur', 'Gaurav', 'Madhur', 'Param', 'Shalini', 'Pallavi'
            selectedAuthors:[],
            subjectCost:``,
            subject_old_cost:``,
            subjectDuration:``,
            testIDs:[],
            selectedTestIDs:[],
            subjectThumbnail:``,
            subjectThumbnailUrl:``,
            subjectDescriptions:[{
                id:`1`,
                description:''
            }],
            learningRequirements:[{
                id:`1`,
                requirement:''
            }],
            learningTopics:[{
                id:`1`,
                topic:''
            }],
            exams:[],
            selectedExams:[],
            topicsArray:[],
            selectedTopics:[],
            dragDropTopics:[],
            selectedSubjectList:[],
            topicListShowing:false,
            url:'',
            seo_title:'',
            seo_description:'',
            synopsis: "<p></p>",
            caseLawCitation:'',
            issue:``,
            casenumber:''

        }
        this.onEditorChange = this.onEditorChange.bind( this );

    }
    
   

      onEditorChange( e ) {
        console.log(e)
        this.setState( {
          synopsis: e.editor.getData()
        } );
      }
      

    componentDidMount(){
        this.getSubjects();      
    }

    //GETS , POSTS , FORM VALIDATION


    renderCaseLawSynopsis() {
        return (
          <div className="col-12 mb-2 mt-0 cost-section">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="cost-label subject-labels"
            >
              Case Synopsis :
            </label>
            
               <CKEditor
                data={this.state.synopsis}
                 style={{width:'90%', margin:'10px auto'}}
                    onChange={this.onEditorChange} 
                    className="textFontSize"/> 
          </div>
        );
      }

      getSubjects(){

        this.setState({
            loading:true,
              response: fetch('https://www.vimasmiham.com/get_subjects', {
              method: 'GET',
              })
              .then((response) => response.json())
              .then((responseJson)=>{
                  console.log(responseJson)
                var draft_count=0,published_count=0,trash_count=0
                  for(var v=0;v<responseJson.length;v++){
                     responseJson[v].value = responseJson[v].subject_id
                     responseJson[v].label = responseJson[v].subject_name
                    
                      }
                this.setState({
                    subjects:responseJson,
                   loading:false
                    })
                   } 
              )
              .catch((error) => {
              this.setState({
              loading:false
              })
              swal("Warning!", "Check your network!", "warning");
            console.log(error)
                  })
              })
        } 

   


    renderChooseFile = () => {
        return (
          <div className="col-12  d-flex">
          <label htmlFor="defaultFormRegisterNameEx" className="course-duration-label subject-labels"
         
          // style={{  width: '41%' }}
          
          >
              Enter file link:
            </label>
    
        
            <div
              className="flex-column "
              style={{
                width: "39.4%",
                borderLeft: "1px solid #bfbfbf",
                height: "100%"
              }}
          
            >
              <input
                   style={{ marginTop: "10px", width: "100%"}}
                autoFocus
                type="text"
                placeholder="Choose File From Gallery"
                value={this.state.inputLink}
                onChange={text => this.handlechangeurl(text)}
                id="defaultFormRegisterNameEx"
                className="form-control duration-form custom-form link-placeholder"
              />
    
    <div   style={{ marginLeft:'5%' }}>
            <MediaComponent
           
                          onSelectMediaItem={this.handleMediaChooseImage}
                          buttonName={<MDBIcon icon="camera" />}
                          restriction="image/*"
                        />
                        </div>
        </div>
           
          </div>
        );
      };

      handlechangeurl = text => this.setState({ inputLink: text.target.value });


    compare( a, b ) {
        if ( a.label < b.label ){
          return -1;
        }
        if ( a.label > b.label ){
          return 1;
        }
        return 0;
      }






    //ADD FORM

    addCaseLaw(){



          
                this.setState({
                loading:true
         
                })

                  
                  let formData = new FormData()
                  formData.append('court_name',this.state.caseLawCourtName)
                  formData.append('title',this.state.title)
                  formData.append('issue',this.state.issue)
                  formData.append('case_number',this.state.casenumber)
                  formData.append('date_of_decision',this.state.decisionDate)
                  formData.append('citation', this.state.caseLawCitation)
                //   formData.append('instructor_say',this.state.instructorSay)
                  formData.append('synopsis', this.state.synopsis)
                //   formData.append('old_cost',this.state.subject_old_cost)
                  formData.append('link', this.state.inputLink )
                  if(this.state.selectedSubjectList || this.state.selectedSubjectList > 0) {
                      const subject_list = this.state.selectedSubjectList.map(item=>{return {name:item.subject_name, id:item.subject_id, url:item.url}})
                  formData.append('Subjects', JSON.stringify(subject_list))
                  }
                  for (let key of formData.entries()) {
                    console.log(key[0] + ',with photo ' + key[1]);
                }

                 
    
                    this.setState({
                    response: fetch('https://www.vimasmiham.com/add_caselaw', {
                    method: 'POST',
                    body: formData
                    }).then((response) => response.json())
                    .then((responseJson)=>{
                    if(responseJson.status == 200){
                        swal("Success", "Case Law succesfullly added", "success").then((value)=>{
                            this.props.history.push('/admin/case-laws')
                            });
                    }
                    else{
                        swal("Error", `${responseJson.error.sqlMessage}`, "info").then((value)=>{
                            // this.props.history.push('/admin/subject-list')
                            this.setState({loading:false})
                            });
                    }
                    })
                    .catch((error) => {
                    this.setState({
                    loading:false
                    })
                    swal("Warning!", "Check your network!", "warning")
                     console.log(error)
                        })
                    })
                 
        
                }















    //RENDER FUNCTIONS
    renderCaseLawCourtName(){
        return(
            <div className="col-12  subject-name-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                 Court:
                 </label>
                 <input
                    
                    value={this.state.caseLawCourtName}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.setState({caseLawCourtName:text.target.value})}
                    />
            </div>
        )
    }


    renderCaseLawTitle(){
        return(
            <div className="col-12  subject-name-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                 Title:
                 </label>
                 <input  
                    value={this.state.title}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.setState({title :text.target.value})}
                    />
            </div>
        )
    }

    

    renderCaseLawIssue(){
      return(
          <div className="col-12  subject-name-section d-flex">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
               Issue:
               </label>
               <input  
                  value={this.state.issue}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-name-form custom-form"
                      onChange={(text)=>this.setState({issue :text.target.value})}
                  />
          </div>
      )
  }
  
  renderCaseLawNumber(){
    return(
        <div className="col-12  subject-name-section d-flex">
             <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
             Case Number:
             </label>
             <input  
                value={this.state.casenumber}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({casenumber :text.target.value})}
                />
        </div>
    )
}



    
    //RENDER FUNCTIONS
    renderCaseLawCitation(){
      return(
          <div className="col-12  subject-name-section d-flex">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
               Citation:
               </label>
               <input
                  
                  value={this.state.caseLawCitation}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-name-form custom-form"
                      onChange={(text)=>this.setState({caseLawCitation:text.target.value})}
                  />
          </div>
      )
  }

renderCourtDateDecision() {
    return (
      <div className="col-12  subject-name-section d-flex">
      <label htmlFor="defaultFormRegisterNameEx" className="date-from-label subject-labels mr-3">
           Court decision date:
      </label>
      <DatePicker
      selected={this.state.decisionDate}
      onChange={(date)=>this.setState({decisionDate:date })}
      dateFormat="dd/MM/yyyy"
    />
       </div>
    );
  }

  
renderSubjects(){
    return (
        <React.Fragment>
            <div className="col-12  subject-name-section d-flex">
            <label htmlFor="defaultFormRegisterNameEx" className="subject-labels">
                Related Subjects:
            </label>
            <Select
                closeMenuOnSelect={false}
                isMulti
                onChange={e=>this.setState({selectedSubjectList:e},()=>console.log(this.state.selectedSubjectList))}
                value={this.state.selectedSubjectList}
                className='select-exam select mb-0'
                options={this.state.subjects}

            />
            </div>
        </React.Fragment>
    )
}

handleMediaChooseImage = inputLink =>{
    this.setState({inputLink})
    }


    render(){
        console.log(this.state)
        return(
            <div id="add-subject-page">
            <AdminNavbar {...this.props} />

            <div className="d-flex">
              <SidebarPage {...this.props} active={3}/>

              <div className="add-subject-column">
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
                    <React.Fragment>
                      

                    <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                    <button className="admin-button-style" onClick={()=>this.props.history.push('/admin/case-laws')}>
                        All Case Laws
                    </button>
                    <button className="admin-button-style" onClick={()=>this.addCaseLaw()}> Save &amp; Publish</button>

                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Add Case Law</h3> 
                    </div>
                    </div>
                <div className="d-flex">
                <div className="subject-content-container ml-4">
                <div className="row px-0 mx-0">
                    {this.renderCaseLawTitle()}
                    {this.renderCaseLawIssue()}
                    {this.renderCaseLawNumber()}
                    {this.renderCaseLawCourtName()}
                    {this.renderCaseLawCitation()}
                    {this.renderCourtDateDecision()}
                    {this.renderSubjects()}
                   {this.renderCaseLawSynopsis()}
                   {this.renderChooseFile()}
                </div>
                </div>

                </div>

    

                <hr />

                    <button className="add-subject my-5 float-right mr-4" onClick={()=>this.addCaseLaw()}>Save &amp; Publish</button>
                    
                    
          
                    </React.Fragment>
                   )
                }
              </div>


            </div>

        </div>
        )
    }
}


export default AddCaselaw;