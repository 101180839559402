import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import React from "react";
import Iframe from "react-iframe";

const ShowPdf = (props) => {
  console.log("prpsos", props.match.params.id);

  if (props.match.params.id) {
    const data = JSON.parse(atob(props.match.params.id));

    return (
      <div>
        <p className="modalFramePara">{data.name}</p>
        <Iframe
          width="100%"
          height="100%"
          // id="myId"
          // className="myClassname"
          url={data.link}
          display="initial"
          position="relative"
        />
      </div>
    );
  }
  return <h2>something went wrong</h2>;
};

export default ShowPdf;
