import React,{Component} from 'react';
import SidebarPage from '../Sidebar/sidebarPage';
import AdminNavbar from '../Navbar/adminNavbar';
import swal from 'sweetalert';
import { css } from '@emotion/core';
import { MDBTable, MDBTableBody } from 'mdbreact';
// import { HalfCircleSpinner } from 'react-epic-spinners';
import GridLoader from 'react-spinners/GridLoader'; 
import ActivePNG from '../../../Assets/images/activestatus.png';
import InactivePNG from '../../../Assets/images/inactivestatus.png';
import CKEditor from 'ckeditor4-react';

import '../tax/addTax.css'

const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:20% 45%;
`;


class allNews extends Component {
  constructor( props ) {
      super( props );

      this.state = {
        loading:false,
        news:[],
        showAction:false,
        draft_count:0,
        published_count:0,
        trash_count:0,all_selected:true,draft_selected:false,
        published_selected:false,trash_selected:false,selected_data:[],
        showUEModal:false
      };

      this.onEditorChange = this.onEditorChange.bind( this );


  }
componentWillMount(){
      this.setState({loading:true}, ()=>this.getUpcomingExams())

}

componentDidMount(){
      window.localStorage.removeItem('exam_date_item');
    }


    onEditorChange( e ) {
      console.log(e)
      this.setState( {
          homepage_info: e.editor.getData()
      } );
    }


  getUpcomingExams=async()=>{

    this.setState({
        loading:true
      })         
          this.setState({
          response: fetch('https://www.vimasmiham.com/get_upcoming_exams', {
          method: 'GET',
          }).then((response) => response.json())
          .then((responseJson)=>{
              console.log(responseJson,'xxx')
            var draft_count=0,published_count=0,trash_count=0
              for(var v=0;v<responseJson.length;v++){
                 
                  responseJson[v].selected = 0
                  if(responseJson[v].status==0){
                    draft_count=draft_count+1
                  }
                  else if(responseJson[v].status==1){
                    published_count=published_count+1
                                      }
                  else if(responseJson[v].status==2){
                    trash_count=trash_count+1
                       }
                  }
            this.setState({
                news:responseJson,
                draft_count:draft_count,
                published_count:published_count,
                trash_count:trash_count
                },()=>this.getHomepageInfo())
               } 
          )
          .catch((error) => {
          this.setState({
          loading:false
          })
          swal("Warning!", "Check your network!", "warning");
        console.log(error)
              })
          })
    } 


    async getHomepageInfo(){
      const response = await fetch("https://www.vimasmiham.com/get_homepage_upcoming_info");
      const homepage_info = await response.json();
      console.log(homepage_info)
      this.setState({homepage_info:homepage_info[0].information, loading:false},()=>console.log(this.state))
    }

  

    handleEditNews(item){
        window.localStorage.setItem('exam_date_item', JSON.stringify(item))
        window.localStorage.setItem('exam_date_list',JSON.stringify(this.state.news))
        this.props.history.push('/admin/edit-exam-date');
     }

     handleAddNews(){
      window.localStorage.setItem('exam_date_list',JSON.stringify(this.state.news))
      this.props.history.push('/admin/add-exam-date');
   }

      renderActions(item,index){
          if(item.selected==1){
              if(this.state.trash_selected){
                return( <div style={{width:'15%'}} className='actions_div'> <span onClick={()=>this.restoreAlert(item)} className='actionstext'>Restore</span>
                 <span onClick={()=>this.deleteAlert(item)} className='actionstext' style={{color:'#a00'}}>Delete</span>
                </div>)
              }
              else{
                return( <div  className='actions_div'><span onClick={()=>this.handleEditNews(item,index)} 
                className='actionstext'>Edit</span> 
                </div>)
              }
          }
          else{
            return( <div className='actions_div' style={{visibility:'hidden'}}><span className='actionstext'>Edit</span> </div>)    
          }
      }
      showactionbutton(item,index){
          let {news} = this.state
          let selected_page = news[index]
          for(var v=0;v<news.length;v++){
            news[v].selected=0
          }
          selected_page.selected=1 
          this.setState({
            news
          })
      }
      hideactionbutton(){
        let {news} = this.state
       
        for(var v=0;v<news.length;v++){
            news[v].selected=0
        }
      
        this.setState({
            news
        })
      }
      renderStatus(item){
          if(item.status==0){
              return 'Last Modified'
          }
          else{
              return 'Published'
          }
      }


        changeStatus(item,status){
            this.setState({
                loading:true
              })
               let details = {
               upcoming_exam_id:item.upcoming_exam_id,
               status:status
             };
             console.log(details,'details')
             let formBody = [];
             for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
             }
             formBody = formBody.join("&");
             
             this.setState({
              response: fetch('https://www.vimasmiham.com/upcoming_exams_status', {
                method: 'POST',
                headers: {
                 
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Cache-Control': 'max-age=31536000'
              },
             body:formBody
               
                
              }).then((response) => response.json())
              .then((responseJson)=>{
                this.setState({loading:false},()=>this.getUpcomingExams())              
              }
             
              )
                .catch((error) => {
               this.setState({
                 loading:false
               })
               swal("Warning!", "Check your network!", "warning");
             console.log(error)
                 })
                })
        }

        


  renderActiveStatus(item){
    console.log(item)
    if(item.status==1){
      return <img src={ActivePNG} onClick={()=>this.changeStatus(item,0)} alt="Course is active" className="course-status-icon" />
    }
    else{
      return <img src={InactivePNG} onClick={()=>this.changeStatus(item,1)} alt="Course is INactive" className="course-status-icon" />
    }
  }
    
  renderTableData(item,index){
      
  if(this.state.all_selected==true ){
      if( item.status!=2){
      // console.log(this.state.all_selected,item.status,'bigE')
      return (     
        <tr key={item.upcoming_exam_id}>
        {/* <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td> */}
        <td style={{width:'25%'}} className="tdName textFontSize" >
        <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
        className='name_div_table'>
        <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.upcoming_exam_title}</span>
        {this.renderActions(item,index)}
        </div></td>
        <td >   <span dangerouslySetInnerHTML={{__html:item.upcoming_exam_description}} style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}></span> 
        </td>
        <td>{this.renderActiveStatus(item)}</td>
        </tr>
         )
      }

  
  }
  else if(this.state.published_selected==true ){
  if(item.status==1){
  return (  
      <tr key={item.news_id}>
      {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
   <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.news_title}</span>
      {this.renderActions(item,index)}
      </div></td>
      <td >   <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.news_description}</span> </td>
                          <td>{this.renderActiveStatus(item)}</td>
      
                              </tr>)
  }
  

}
else if(this.state.draft_selected==true){
  if( item.status==0){
  return (      
    <tr key={item.news_id}>
      {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
     <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.news_title}</span>
      {this.renderActions(item,index)}
      </div></td>
      <td >   <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.news_description}</span> </td>
                 <td>{this.renderActiveStatus(item)}</td>
                           </tr>)
  }


}
// else if(this.state.trash_selected==true){
// if( item.status==2){
//   return (         <tr key={item.tax_id}>
//       <td><input className='checkbox_content' type='checkbox' /></td>
//       <td style={{width:'25%'}} className="tdName textFontSize" >
//       <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
//       className='name_div_table'>
//       <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.tax_name}</span>
//       {this.renderActions(item,index)}
//       </div></td>
//           <td className="datefontsize">{this.renderStatus(item)} <br />
//           <span style={{fontFamily:'OpenSans-Semibold',color:'#0073aa'}}>{item.tax_value}</span></td>     </tr>)
//                       }
//             }
    }


  onToggle(index, e){
    console.log(index,'kjkk')
  let {news,selected_data} = this.state
  let selected_item=news[index]
  if(selected_item.checked==1){
   selected_item.checked=0
   selected_data.splice(index,1)
  }
  else{
    selected_item.checked=1
  selected_data.push(selected_item)
  }
  
  this.setState({selected_data})
  this.setState({news})
  
  }
  
  
handleAllChecked = (event) => {
  let {news} = this.state
if(this.state.item_selected){
  for(var v=0;v<news.length;v++){
    news[v].checked=1
  }
  this.setState({selected_data:news})
}
else{
  for(var v=0;v<news.length;v++){
    news[v].checked=0
  }
  this.setState({selected_data:[]})
}
  this.setState({news})
  
}

handleHomepageExamDisplay(){
  this.setState({showUEModal:true},()=>console.log(this.state))
}


saveHomepageUpcomingExam(){
  let formBody = new FormData()
  formBody.append("homepage_info",this.state.homepage_info)
  console.log(formBody)
  for (var [key, value] of formBody.entries()) { 
    console.log(key, value);
  }
  fetch("https://www.vimasmiham.com/post_homepage_upcoming_info",{
    method:'POST',
    body:formBody
  })
  .then(()=>console.log("updated"))
  .catch(error=>{
    console.log(error)
  })
}


renderHomepageUpcomingExam(){
  if(this.state.showUEModal)
  return(
        <React.Fragment>
          <div style={styles.modal}>
          <div className="d-flex justify-content-center align-items-center mt-5">
          <div className="upcoming-image-admin" style={{'right':'unset'}}>
                    <img
                      src="https://www.vimasmiham.com/upcomingExams.png"
                      alt="Upcoming Exam"
                      className="mt-4"
                    />
        <div className="upcoming-exam-heading ">
           <p>Upcoming Exams</p>
        </div>
        <div className="upcoming-exam-notification" dangerouslySetInnerHTML={{__html:`${this.state.homepage_info}`}}></div>
        </div>
        {this.renderEditor()}
        </div>
        <div className="d-flex justify-content-center">
        <span onClick={()=>this.saveHomepageUpcomingExam()} className='add_new_button mx-1'>Save and Publish</span>
        <span onClick={()=>this.setState({showUEModal:false})} className='add_new_button mx-1 btn-danger'>Close</span>
        </div>
        </div>
        </React.Fragment>
        
        )
    }

    renderEditor(){
      return ( <React.Fragment>
                  <div className="d-flex align-items-start mt-3 mb-3">
                    <div className="position-relative w-100 ml-3 mr-4">
                      <CKEditor
                  data={this.state.homepage_info}
                  style={{width:'88%'}}
                  onChange={this.onEditorChange} 
                  className="textFontSize"/> 
                    </div>
                    </div>
                </React.Fragment>
            )
    
}
  render() {
    if(this.state.loading){
      return( <div className='sweet-loading'>
      <GridLoader
      css={override}
      sizeUnit={"px"}
      size={25}
      color={'#2fb2eb'}
      loading={this.state.loading}
      />
  </div>)
    } 
   else{
    return (
      <div id="subject-page-list">
           <AdminNavbar {...this.props}/>
      <div style={{display:'flex',flexDirection:'row'}}>
      <SidebarPage {...this.props}  active={13} />
      
        <div className='colDefault' style={{width:'100%',backgroundColor:'#F9F9F9'}}>
        <div className="page_heading"><h3 style={{marginBottom:0,marginRight:'5px'}}>All Exam Dates</h3>
        <span onClick={()=>this.handleAddNews()} className='add_new_button mr-2'>Add New</span>
        <span onClick={()=>this.handleHomepageExamDisplay()} className='add_new_button mr-0'>Homepage Info</span>
        <span onClick={()=>this.props.history.push('/admin/upcoming-exam-seo')} className='add_new_button'>Add/Edt SEO content</span>

        </div> 
        <div>
        <ul className="subsubsub">
	<li className="all">
    <span onClick={()=>this.setState({all_selected:true,published_selected:false,trash_selected:false,draft_selected:false})}  
    className={this.state.all_selected?"text_Pub current":"text_Pub"} 
    aria-current="page">All <span className="count">({this.state.draft_count+this.state.published_count})</span></span> </li>
	<li className="publish">
    <span onClick={()=>this.setState({all_selected:false,published_selected:true,trash_selected:false,draft_selected:false})} 
    className={this.state.published_selected ?"text_Pub current":"text_Pub"}  >Active
     <span className="count">({this.state.published_count})</span></span> </li>
	<li className="draft">
    <span onClick={()=>this.setState({all_selected:false,published_selected:false,trash_selected:false,draft_selected:true})} 
    className={this.state.draft_selected ?"text_Pub current":"text_Pub"}  >In-Active
     <span className="count">({this.state.draft_count})</span></span> </li>
  
</ul>
        {/* <div className='tablenav'>
        <div className="alignleft actions bulkactions">
			<select name="action" id="bulk-action-selector-top">
<option value="-1">Bulk Actions</option>

	<option value="trash">Move to Trash</option>
</select>
<span onClick={()=>console.log(this.state.selected_data)} className='apply'>Apply</span>
		</div>
        </div> */}


              <MDBTable  striped className="pages_table" style={{backgroundColor:'#F9F9F9'}}>
              
                  <tr style={{backgroundColor:'white'}}>
                  {/* <th><input type="checkbox" onClick={(event)=> this.setState({item_selected:!this.state.item_selected},()=>this.handleAllChecked(event))}  checked={this.state.item_selected}  value="checkedall" /></th> */}
                       <th className="" >Exam Name</th>
                      <th className="textFontSize">Exam Description</th>
                      <th>Status</th>
                  </tr>
                 
                  <MDBTableBody>
                  { this.state.news.map((item,index) => {
                    return this.renderTableData(item,index)
                              })}
                  </MDBTableBody>
              </MDBTable>
         
        </div>
       
      
             
           </div>
        </div>
        {this.renderHomepageUpcomingExam()}
      </div>

  );
   }
}
}


const styles={
  modal:{
    height:'100vh',
    width:'100vw',
    position:'fixed',
    zIndex:99,
    backgroundColor:'rgba(0, 0, 0, 0.5)',
    top:0,
    left:0

  }
}

export default allNews;


