import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import Select from "react-select";
import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";
import Fade from "react-reveal";
import Swal from "sweetalert2";

// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class UpcomingExamsSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      seo_title: "",
      seo_description: "",
      content: "",
    };
  }
  onEditorChange(e) {
    console.log(e);
    this.setState({
      content: e.editor.getData(),
    });
  }

  componentDidMount() {
    this.getSEO();
  }

  onEditorChange(e) {
    this.setState({ content: e.editor.getData() });
  }

  getSEO = () => {
    fetch("https://vimasmiham.com/get_upcoming_exams_seo")
      .then((res) => res.json())
      .then(({ data }) =>
        this.setState({
          seo_title: data[0].seo_title,
          seo_description: data[0].seo_description,
          content: data[0].content,
          id: data[0].id,
          loading: false,
        })
      );
  };

  saveData() {
    fetch("https://vimasmiham.com/post_upcoming_exams_seo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        seo_title: this.state.seo_title,
        seo_description: this.state.seo_description,
        content: this.state.content,
        id: this.state.id,
      }),
    })
      .then(({ status }) => {
        if (status === 200) {
          return Swal.fire(
            "",
            "SEO Content successfully updated",
            "success"
          ).then(() => this.props.history.push("/admin/upcoming-exams"));
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  renderCourseSeoTitle() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          SEO Title
        </label>
        <input
          value={this.state.seo_title}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(text) => this.setState({ seo_title: text.target.value })}
        />
      </div>
    );
  }

  renderCourseSeoDescription() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          SEO Description
        </label>
        <input
          value={this.state.seo_description}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form "
          onChange={(text) =>
            this.setState({ seo_description: text.target.value })
          }
        />
      </div>
    );
  }

  renderUpcomingExamsContent = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="image-label subject-labels h-100"
        >
          Content:
        </label>
        <CKEditor
          data={this.state.content}
          onChange={(e) => this.onEditorChange(e)}
          className="w-75"
        />
      </div>
    );
  };

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={3} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/upcoming-exams")
                        }
                      >
                        Back
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.saveData()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3>Upcoming Exams SEO content</h3>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderCourseSeoTitle()}
                        {this.renderCourseSeoDescription()}
                        {this.renderUpcomingExamsContent()}
                      </div>
                    </div>
                  </div>

                  <hr />

                  <button
                    className="add-subject my-5 float-right mr-4"
                    onClick={() => this.saveData()}
                  >
                    Save &amp; Publish
                  </button>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default UpcomingExamsSEO;
