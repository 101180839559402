import React, { Component } from 'react'
import CheckRight from '../../Assets/images/checkright.png'
import CheckWrong from '../../Assets/images/checkwrong.png'

export default class Captcha extends Component {
    constructor(props){
        super(props);
        this.state={
            firstNumber:2,
            secondNumber:3,
            answer:null,
            isRight:null
        }
    }


    componentDidMount(){
        console.log(Math.floor(Math.random() * 10) + 1  )
        this.setState({firstNumber:Math.floor(Math.random() * 10) + 1,
                        secondNumber:Math.floor(Math.random() * 10) + 1,
                    })
    }

    checkAnswer(){
        if((Number(this.state.firstNumber) + Number(this.state.secondNumber)) === Number(this.state.answer)){
            this.setState({isRight:true},()=>this.props.checkCaptcha(this.state.isRight))
        }
        else{
            this.setState({isRight:false},()=>this.props.checkCaptcha(this.state.isRight))
    }
}
    
    render() {
        return (
            <>
            <div className={`d-flex ${this.props.alignment?this.props.alignment:'justify-content-center'} align-items-center py-2 px-0`}>
                <p style={this.styles.numbers}>{this.state.firstNumber} &nbsp; + &nbsp; {this.state.secondNumber} &nbsp; = &nbsp;</p>
                <input type="tel" 
                onChange={(e)=>this.setState({answer:e.target.value},()=>this.checkAnswer())} 
                value={this.state.answer}/>
                <img src={this.state.isRight?CheckRight:CheckWrong} className={this.state.isRight == null?'opacity-0':""} style={{width:'30px',paddingLeft:'10px'}} />
            </div>
          
            </>
        )
    }


    styles = {
        numbers:{
            fontSize:'20px',
            color:this.props.textColor,
            padding:'2px 10px',
            margin:0,
        }
    }
}



