import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import "./questions.css";
import { MDBIcon } from "mdbreact";
import Select from "react-select";
import swal from "sweetalert";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import CKEditor from "ckeditor4-react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class EditQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherImage1: "",
      otherImage2: "",
      otherImage3: "",
      answerImage: "",
      inputLink: "",
      loading: false,
      questionTitle: "",
      answers: [],
      keyword: null,
      correctAnswer: "",
      otherOption1: "",
      selectedQuestionType: {},
      options: [
        { value: "subjective", label: "subjective" },
        { value: "objective", label: "objective" },
      ],
      otherOption2: "",
      otherOption3: "",
      questionImage: null,
      questionImageUrl: null,
      topics: [],
      selectedTopic: {},
      difficultyLevels: [
        { value: 1, label: "Easy" },
        { value: 2, label: "Moderate" },
        { value: 3, label: "Tough" },
      ],
      selectedDifficultyLevel: null,
    };

    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillMount() {
    this.getTopics();
  }

  componentDidMount() {}

  onEditorChange(e) {
    console.log(e);
    this.setState(
      {
        questionTitle: e.editor.getData(),
      },
      () => console.log(this.state)
    );
  }

  setInitialQuestionState() {
    var question_item = JSON.parse(
      window.localStorage.getItem("question_item")
    );

    console.log(question_item);
    var answers = [];
    answers.push(question_item.correct_answer);
    answers.push(question_item.other_option_1);
    answers.push(question_item.other_option_2);
    answers.push(question_item.other_option_3);
    // var answers = JSON.parse(question_item.answers);
    var question_topic = question_item.topic;
    let selectedTopic = {};
    let selectedQuestionType = question_item.type;
    let objective = question_item.type == "subjective" ? 0 : 1;
    let keyword = question_item.keywords;
    this.state.topics.forEach((element) => {
      if (question_topic == element.value) {
        var object = new Object();
        object.label = element.label;
        object.value = element.value;
        selectedTopic = object;
      }
    });

    console.log("topics", question_item);
    console.log(this.state.topics);
    this.setState(
      {
        questionId: question_item.question_id,
        choseImage: question_item.question_image,
        keyword,
        objective,
        selectedQuestionType: {
          value: selectedQuestionType,
          label: selectedQuestionType,
        },
        questionTitle: question_item.question_title,

        correctAnswer: answers[0],
        otherOption1: answers[1],
        otherOption2: answers[2],
        otherOption3: answers[3],
        //  otherImage1:answers[1].link,
        //otherImage2:answers[2].link,
        // otherImage3:answers[3].link,
        //  answerImage:answers.find(item=>item.value==1).link,
        selectedTopic: selectedTopic,

        selectedDifficultyLevel: this.state.difficultyLevels.find(
          (item) => item.value == question_item.difficulty_level
        ),
        loading: false,
      },
      () => {}
    );
  }

  getTopics = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_topics", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          responseJson.forEach((element) => {
            element.value = element.topic_id;
            element.label = element.topic_name;
          });
          console.log(responseJson);
          this.setState({ topics: responseJson }, () =>
            this.setInitialQuestionState()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  handleSaveQuestion() {
    console.log(this.state.selectedQuestionType);
    var id = JSON.parse(window.localStorage.getItem("question_item"))
      .question_id;
    console.log(id, "ididididi");
    var questionObject = new Object();
    if (this.state.selectedQuestionType.label == "objective") {
      //question title
      questionObject.id = id;
      questionObject.question = this.state.questionTitle;
      questionObject.questionImage = this.state.choseImage;
      //answers
      questionObject.answers = new Array();
      //correct answer
      questionObject.answers[0] = new Object();
      // questionObject.answers[0].id=0;
      questionObject.answers[0].answer = this.state.correctAnswer;
      //  questionObject.answers[0].value=1;
      // questionObject.answers[0].link =this.state.answerImage;
      //oter answers
      questionObject.answers[1] = new Object();
      // questionObject.answers[1].id=1;
      questionObject.answers[1].answer = this.state.otherOption1;
      //  questionObject.answers[1].value=0;
      //  questionObject.answers[1].link=this.state.otherImage1;

      questionObject.answers[2] = new Object();
      //  questionObject.answers[2].id=2;
      questionObject.answers[2].answer = this.state.otherOption2;
      //  questionObject.answers[2].value=0;
      //questionObject.answers[2].link=this.state.otherImage2;

      questionObject.answers[3] = new Object();
      //questionObject.answers[3].id=3;
      questionObject.answers[3].answer = this.state.otherOption3;
      //questionObject.answers[3].value=0;
      //questionObject.answers[3].link=this.state.otherImage3;

      questionObject.difficultyLevel = this.state.selectedDifficultyLevel.value;

      //topics
      questionObject.topic = this.state.selectedTopic;

      this.editQuestion(questionObject);
    } else if (this.state.selectedQuestionType.label == "subjective") {
      this.editSubjectiveQuestion();
    }
  }

  editSubjectiveQuestion() {
    console.log(this.state.selectedTopic);
    var id = JSON.parse(window.localStorage.getItem("question_item"));
    console.log(id);
    if (this.state.selectedTopic == null) {
      return swal(
        "Check topic field!",
        "Please choose a topic for yours question",
        "error"
      );
    } else if (this.state.questionTitle.length == 0) {
      return swal(
        "Check question field!",
        "Please enter valid question",
        "error"
      );
    } else if (!this.state.selectedDifficultyLevel) {
      return swal(
        "Select difficulty level!",
        "Choose a difficulty level",
        "error"
      );
    } else if (this.state.selectedQuestionType == null) {
      return swal(
        "Select Question type!",
        "Please choose question type",
        "error"
      );
    } else if (this.state.keyword == null) {
      return swal("Check Keyword", "Please write some keywords", "error");
    }
    let formData = new FormData();
    formData.append(
      "difficulty_level",
      this.state.selectedDifficultyLevel.value
    );
    formData.append("question_id", id.question_id);
    formData.append("question_title", this.state.questionTitle);
    formData.append("topic", this.state.selectedTopic.value);
    formData.append("correct_answer", "");
    formData.append("other_option_1", "");
    formData.append("other_option_2", "");
    formData.append("other_option_3", "");
    formData.append("question_image", "");
    formData.append("keywords", this.state.keyword);
    formData.append("type", "subjective");

    console.log(this.state.selectedTopic);
    for (let key of formData.entries()) {
      console.log(key[0] + ",with photo " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_question", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Question succesfullly edited", "success").then(
            () => {
              this.setState({ loading: false }, () =>
                this.props.history.push("/admin/dashboard")
              );
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  editQuestion(question) {
    this.setState({
      loading: true,
    });

    console.log(question, "RRR");

    if (this.state.questionTitle.length == 0) {
      return swal(
        "Check question field!",
        "Please enter valid question",
        "error"
      );
    } else if (!this.state.selectedDifficultyLevel) {
      return swal(
        "Select difficulty level!",
        "Choose a difficulty level",
        "error"
      );
      console.log(this.state.selectedDifficultyLevel);
    } else if (this.state.correctAnswer.length < 1) {
      return swal("Check correct answer field!", "Cannot be emp", "error");
    } else if (
      this.state.otherOption1.length < 1 ||
      this.state.otherOption2.length < 1 ||
      this.state.otherOption3.length < 1
    ) {
      return swal(
        "Check Other answers field",
        "Complete all other answer fields",
        "error"
      );
    }
    let formData = new FormData();

    formData.append("question_id", question.id);
    formData.append("difficulty_level", question.difficultyLevel);
    formData.append("question_title", question.question);
    formData.append("topic", question.topic.value);
    formData.append("correct_answer", question.answers[0].answer);
    formData.append("other_option_1", question.answers[1].answer);
    formData.append("other_option_2", question.answers[2].answer);
    formData.append("other_option_3", question.answers[3].answer);
    formData.append("question_image", question.questionImage);
    formData.append("keywords", "");
    formData.append("type", "objective");

    for (let key of formData.entries()) {
      console.log(key[0] + ",with photo " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_question", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Question succesfullly edited", "success").then(
            () => {
              this.setState({ loading: false }, () =>
                this.props.history.push("/admin/dashboard")
              );
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  //HANDLE FUNCTIONS

  renderQuestionTitleSection() {
    return (
      <React.Fragment>
        <div className="col-12 my-2 course-duration-section question-title-section">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className=" subject-labels w-25"
          >
            Question:
          </label>

          <CKEditor
            data={this.state.questionTitle}
            onChange={this.onEditorChange}
            className=""
          />
        </div>

        <div className="col-12 d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels  w-25"
          >
            Choose Image:
            <MediaComponent
              onSelectMediaItem={this.handleMediaChooseImage}
              buttonName={<MDBIcon icon="camera" />}
              restriction="image/*"
            />
          </label>

          <input
            value={this.state.choseImage}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control other-answer-form custom-form"
            onChange={(data) =>
              this.setState({ choseImage: data.target.value })
            }
          />
        </div>
        <div className="col-8 text-center">
          <img src={this.state.choseImage} style={{ width: "200px" }} />
        </div>
      </React.Fragment>
    );
  }

  renderAnswerSection() {
    return (
      <div className="col-8 my-2 right-answer-section">
        <p className="question-label">Answers</p>

        <div className="d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Correct Answer:
          </label>

          <input
            value={this.state.correctAnswer}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control correct-answer-form custom-form w-100"
            onChange={(data) =>
              this.setState({ correctAnswer: data.target.value })
            }
          />
          <img
            src={this.state.answerImage}
            className={
              this.state.answerImage.length < 2 ? "d-none" : "answers-image"
            }
          />
          <MediaComponent
            onSelectMediaItem={this.handleMediaChangeAnswer}
            buttonName={<MDBIcon icon="camera" />}
          />
        </div>
        <div className="d-flex  mt-3 other-answers-section">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Other options:
          </label>

          <div className="d-flex flex-column " style={{ width: "110%" }}>
            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption1}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption1: data.target.value })
                }
              />

              <img
                src={this.state.otherImage1}
                className={
                  this.state.otherImage1.length < 2 ? "d-none" : "answers-image"
                }
              />

              <div>
                <MediaComponent
                  onSelectMediaItem={this.handleMediaChangeOption1}
                  buttonName={<MDBIcon icon="camera" />}
                />
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption2}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption2: data.target.value })
                }
              />

              <img
                src={this.state.otherImage2}
                className={
                  this.state.otherImage2.length < 2 ? "d-none" : "answers-image"
                }
              />

              <div>
                <MediaComponent
                  onSelectMediaItem={this.handleMediaChangeOption2}
                  buttonName={<MDBIcon icon="camera" />}
                />
              </div>
            </div>

            <div
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={this.state.otherOption3}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control other-answer-form custom-form w-100"
                onChange={(data) =>
                  this.setState({ otherOption3: data.target.value })
                }
              />

              <img
                src={this.state.otherImage3}
                className={
                  this.state.otherImage3.length < 2 ? "d-none" : "answers-image"
                }
              />

              <div>
                <MediaComponent
                  onSelectMediaItem={this.handleMediaChangeOption3}
                  buttonName={<MDBIcon icon="camera" />}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  //PART OF MEDIA COMPONENT
  handleMediaChangeAnswer = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ answerImage: `https://www.vimasmiham.com/${item.file}` });
  };

  handleMediaChangeOption1 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage1: `https://www.vimasmiham.com/${item.file}` });
  };
  handleMediaChangeOption2 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage2: `https://www.vimasmiham.com/${item.file}` });
  };
  handleMediaChangeOption3 = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ otherImage3: `https://www.vimasmiham.com/${item.file}` });
  };

  handleMediaChooseImage = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ choseImage: `https://www.vimasmiham.com/${item.file}` });
  };

  renderRelatedTopicsSection() {
    return (
      <div className="col-6 rquestion-topics-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Topics:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.setState({ selectedTopic: e })}
          value={this.state.selectedTopic}
          className="select-question-topics select"
          options={this.state.topics}
        />
      </div>
    );
  }

  renderDifficultyLevelSection() {
    return (
      <div className="col-4 diffiulty-level-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="difficulty-label subject-labels question-label "
        >
          Difficulty Level:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.setState({ selectedDifficultyLevel: e })}
          value={this.state.selectedDifficultyLevel}
          className="select-difficulty select"
          options={this.state.difficultyLevels}
        />
      </div>
    );
  }
  renderSubjectiveAnswerSection() {
    return (
      <div className="col-8 my-2 right-answer-section">
        <div className="d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="course-duration-label subject-labels"
          >
            Keywords
          </label>

          <textarea
            value={this.state.keyword}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control correct-answer-form custom-form w-100"
            onChange={(data) => this.setState({ keyword: data.target.value })}
          />
        </div>
      </div>
    );
  }
  renderQuestionType() {
    return (
      <div className="col-4 diffiulty-level-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="difficulty-label subject-labels question-label "
        >
          Question Type:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) =>
            this.setState(
              { selectedQuestionType: e },
              () => {
                if (this.state.selectedQuestionType.label == "objective") {
                  this.setState({ objective: 1 });
                } else this.setState({ objective: 0 });
              },
              () => console.log()
            )
          }
          value={this.state.selectedQuestionType}
          className="select-difficulty select"
          options={this.state.options}
        />
      </div>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page" className="add-question-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/all-questions")
                        }
                      >
                        All Questions
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Question</h3>
                      <button
                        className="admin-button-style"
                        onClick={() => this.handleSaveQuestion()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>

                  <div className="row px-2 my-2 mx-0">
                    {this.renderQuestionType()}
                    {this.renderRelatedTopicsSection()}
                    {this.renderDifficultyLevelSection()}
                    <hr />
                    {this.renderQuestionTitleSection()}

                    <hr />

                    {this.state.objective == 1
                      ? this.renderAnswerSection()
                      : ""}
                    {this.state.objective == 0
                      ? this.renderSubjectiveAnswerSection()
                      : ""}

                    <hr />

                    <button
                      className="add-subject"
                      onClick={() => this.handleSaveQuestion()}
                    >
                      Save &amp; Publish
                    </button>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
