import React from "react";
import Fade from "react-reveal/Fade";
import "./accountDetails.css";

import NewHeader from "../../Reusable/NewHeader/newHeader";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import MetaTags from "react-meta-tags";
// import Helmet from 'react-helmet';

import swal from "sweetalert";
import Swal from "sweetalert2";
import { MDBIcon, MDBModal, MDBModalHeader, MDBModalBody } from "mdbreact";
import { RegionDropdown } from "react-country-region-selector";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
var md5 = require("md5");

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cart: [],
      page_title: "",
      page_description: "",
      userDetails: [
        {
          name: ``,
          user_id: 0,
          phone: " ",
          email: ``,
          company: "",
          designation: "",

          gender: "",
          city: "",
          date_of_birth: "",
          userdata: null,
          qualifications: [
            {
              academic: ``,
              professional: ``,
            },
          ],
          company: ``,
          designation: ``,
          address: ``,
        },
      ],
      editpage: false,
      qualifications: [],
      email: "",
      phone: Number,
      valid_email: false,
      valid_email_t: false,
      valid_phone: false,
      password: "",
      profile_pic_url: "",
      editedPicture: null,
      profile_pic: null,
      city: "",
      gender: null,
      state: null,
      address: null,
      pincode: null,
      passwordModal:false,
      newPassword:``,
      ConfirmNewPassword:``,
      otp:``
    };
  }

  onsave = async () => {
    let checkuser = this.state.password;
    // var passwordHash = require('password-hash');

    //     var hashedPassword = passwordHash.generate(checkuser);
    console.log(this.state.phone, "phone");
    if (this.state.email.length == 0) {
      return swal("Warning!", "Enter email!", "warning");
    } else if (this.state.valid_email == false) {
      return swal("Warning!", "Enter valid email!", "warning");
    } else if (this.state.phone == null) {
      return swal("Warning!", "Enter phone number!", "warning");
    } else if (this.state.phone.length == 0) {
      return swal("Warning!", "Enter phone number!", "warning");
    } else if (this.state.phone.length != 10) {
      return swal("Warning!", "Enter valid phone!", "warning");
    } else if (this.state.valid_phone == false) {
      return swal("Warning!", "Enter valid phone!", "warning");
    }
    // else if(this.state.gender==null){
    //   return  swal("Warning!", "Please choose a Gender!", "warning");
    // }
    else {
      let login_type = JSON.parse(window.localStorage.getItem("userdata")).type;

      if (login_type == 0 || login_type == 1) {
        const { value: password } = await Swal.fire({
          title: "Enter your password",
          input: "password",
          inputPlaceholder: "Enter your password",
          showCancelButton: true,
          backdrop: false,
          inputAttributes: {
            maxLength: 40,
            autoCapitalize: "off",
            autoCorrect: "off",
            autoComplete: "off",
          },
        });

        if (password) {
          let val = md5(password);
          if (val != checkuser) {
            return Swal.fire("Wrong Password");
          }
          swal("Success!", "Successfully changed !", "success");
        } else {
          return;
        }
      }

      // if (tenure!==this.) {
      //     alert("You have entered " + tenure + " years" );
      // }
      let details = {
        user_id: this.state.user_id,
        name: this.state.userDetails.name,
        phone: this.state.phone,
        email: this.state.email,
        password: this.state.password,
        gender: this.state.gender,
        city: this.state.userDetails.city,
        address: this.state.userDetails.address,
        company: this.state.company,
        designation: this.state.designation,
        // qualifications:this.state.aqualification,
        // qualifications:this.state.pqualification,
        qualifications: this.state.userDetails.qualifications,

        date_of_birth: this.state.userDetails.date_of_birth,
      };
      console.log(details);
      // this.validateemail(details.email)
      // window.location.reload()

      //  console.log(details,'fetching-details')
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_user_details", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            this.editAddress();
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };
  componentDidMount() {
    console.log(this.state);
  }
  componentWillMount() {
    this.getUserSEO();

    var user = window.localStorage.getItem("userdata");
    console.log(user, "json user");
    //console.log(user);
    if (user != null) {
      this.setState(
        {
          userdata: JSON.parse(user),
          user_id: JSON.parse(user).user_id,
          loading: true,
        },
        () => this.getUserDetails()
      );
    } else {
      this.props.history.push("/");
    }
  }

  getUserSEO = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_user_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "clients");
          this.setState(
            {
              page_title: responseJson[0].account_title_seo,
              page_description: responseJson[0].account_description_seo,
            },
            () =>
              console.log(this.state, "eggggggggggggggggggggggggggggggggggg")
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  getCart = () => {
    let details = {
      user_id: this.state.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //   console.log(responseJson,'cart')

          this.setState(
            {
              cart: responseJson,
            },
            () => this.getAddress()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getUserDetails = async () => {
    let details = {
      user_id: this.state.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },

        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log(JSON.parse(responseJson[0].qualifications))
          this.setState(
            {
              userDetails: responseJson[0],
              // user_id:responseJson[0].user_id,
              qualifications: JSON.parse(responseJson[0].qualifications),
              email: responseJson[0].email,
              designation: responseJson[0].designation,
              company: responseJson[0].company,
              phone: responseJson[0].phone,
              password: responseJson[0].password,
              profile_pic_url: responseJson[0].profile_pic,
              gender: responseJson[0].gender,
            },
            () => this.getCart()
          );
        })
        .catch((error) => {
          this.setState({
            loading: true,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  hanglechangename = (e) => {
    let userdetails = { ...this.state.userDetails };
    userdetails.name = e.target.value;
    this.setState({ userDetails: userdetails });
  };

  editname() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Name"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          type="text"
          value={this.state.userDetails.name}
          onChange={this.hanglechangename}
          className="p-1"
        />
      );
    } else {
      return <>{this.state.userDetails.name}</>;
    }
  }

  validatemobile = (text) => {
    console.log(text);
    let reg = /^[0-9]{10}$/;
    if (reg.test(text) === false) {
      this.setState({ phone: text, valid_phone: false });
      return false;
    } else {
      this.setState({ phone: text, valid_phone: true });
    }
  };

  editmobile() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Mobile"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          type="number"
          value={this.state.phone}
          onChange={(text) => this.validatemobile(text.target.value)}
          className="p-1"
        />
      );
    } else {
      if (this.state.phone != null) {
        return <>{this.state.phone}</>;
      } else {
        return <>---</>;
      }
    }
  }

  validateemail = (text) => {
    console.log(text);
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };
  // handlechangeemail = e => {
  //   // this.validateemail(e.target.value)
  //      let userdetails = { ...this.state.userDetails };

  //   userdetails.email = this.state.email;
  //   console.log(e.target.value)
  //   this.setState({ userDetails: userdetails });
  // };
  editemail() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Email"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          type="text"
          value={this.state.email}
          onChange={(text) => this.validateemail(text.target.value)}
          className="p-1"
        />
      );
    } else {
      return <>{this.state.userDetails.email}</>;
    }
  }

  handlechangegender = (e) => {
    let userdetails = this.state.userDetails;
    // console.log(this.state.userDetails)
    userdetails.gender = e.target.value;
    console.log(userdetails);
    // this.setState({ userDetails: userdetails });
  };
  editgender() {
    if (this.state.editpage === true) {
      return (
        <>
          {" "}
          <select
            value={this.state.gender}
            onChange={(e) => this.setState({ gender: e.target.value })}
            className="p-1"
          >
            <option value="none">Not selected</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </>
      );
    } else {
      return <>{this.state.userDetails.gender}</>;
    }
  }

  handlechangecity = (e) => {
    let userdetails = { ...this.state.userDetails };
    userdetails.city = e.target.value;
    this.setState({ userDetails: userdetails });
  };

  editcity() {
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="City"
          style={{
            height: "33px",
            borderRadius: "5px",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          type="text"
          value={this.state.userDetails.city}
          value={this.state.userDetails.city}
          onChange={this.handlechangecity}
          className="p-1"
        />
      );
    } else {
      return <>{this.state.userDetails.city}</>;
    }
  }
  handleDobChange = (e) => {
    let userdetails = { ...this.state.userDetails };
    userdetails.date_of_birth = e.target.value;
    console.log(userdetails);
    this.setState({ userDetails: userdetails });
  };

  getDobValue = () => {
    console.log(this.state.userDetails.date_of_birth);
  };

  editdob() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="dd/mm/yyy"
          className="editDateInput"
          type="text"
          className="RegistrationInputType placeholder-fix p-1"
          name="dateofbirth"
          id="dateofbirth"
          defaultValue={data.date_of_birth}
          onChange={this.handleDobChange}
        />
      );
    } else {
      return <>{this.state.userDetails.date_of_birth}</>;
    }
  }

  handlechangequalidate = (e) => {
    //   console.log(this.state.userDetails)
    let userdetails = { ...this.state.userDetails };
    let degree = JSON.parse(userdetails.qualifications);
    console.log(degree, "dddd");
    if (degree) {
      degree[0].academics = e.target.value;
    } else {
      degree = [];
      degree.push({ academics: e.target.value });
    }

    let final = JSON.stringify(degree);

    userdetails.qualifications = final;
    // console.log(JSON.stringify(userdetails.qualifications)

    this.setState({ userDetails: userdetails });
  };
  editqualidate() {
    let data = this.state.userDetails;
    let qual = JSON.parse(data.qualifications);
    console.log(qual, "qual");

    if (qual != null) {
      var result = qual[0].academics;
    } else {
      var result = "";
    }

    if (this.state.editpage === true) {
      return (
        <>
          <select
            onChange={this.handlechangequalidate}
            value={result}
            onChange={this.handlechangequalidate}
            className="p-1"
          >
            <option defaultValue value={0}>
              Academic Qualifications
            </option>

            <option value="Graduate" label="Graduate" />
            <option value="Postgraduate" label="Postgraduate" />
            <option value="Other" label="Other" />
          </select>
        </>
      );
    } else {
      if (qual != null) {
        let qual = JSON.parse(data.qualifications);
        let degree = qual[0].academics;
        return (
          <>
            Academic <br /> {degree}
          </>
        );
      } else {
        return <>---</>;
      }
    }
  }

  handlechangequalidegree = (e) => {
    let userdetails = { ...this.state.userDetails };
    let degree = JSON.parse(userdetails.qualifications);
    if (degree != null) {
      degree[0].professional = e.target.value;
      let final = JSON.stringify(degree);

      userdetails.qualifications = final;
      // console.log(JSON.stringify(userdetails.qualifications)

      this.setState({ userDetails: userdetails });
    } else {
      degree = [];
      degree.push({ professional: e.target.value });
      let final = JSON.stringify(degree);

      userdetails.qualifications = final;
      // console.log(JSON.stringify(userdetails.qualifications)

      this.setState({ userDetails: userdetails });
    }
  };
  editqualidegree() {
    let data = this.state.userDetails;
    let qual = JSON.parse(data.qualifications);

    if (qual != null) {
      var result = qual[0].professional;
    } else {
      var result = "";
    }

    if (this.state.editpage === true) {
      return (
        <>
          <select
            onChange={this.handlechangequalidate}
            value={result}
            onChange={this.handlechangequalidegree}
            className="p-1"
          >
            {" "}
            <option defaultValue value={0}>
              Professional Qualifications
            </option>
            <option value="Licentiate" label="Licentiate" />
            <option value="Associate" label="Associate" />
            <option value="Fellowship" label="Fellowship" />
            <option value="Other" label="Other" />
          </select>
        </>
      );
    } else {
      if (qual != null) {
        let qual = JSON.parse(data.qualifications);
        let degree = qual[0].professional;
        return (
          <>
            Professional <br /> {degree}
          </>
        );
      } else {
        return <>---</>;
      }
    }
  }
  handlechangecompany = (e) => {
    let userdetails = { ...this.state.userDetails };

    this.setState({ company: e.target.value });
  };

  editcompany() {
    console.log(this.state.userDetails.company);
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Your Current Company"
          style={{
            height: "33px",
            borderRadius: "5px",
            width: "100%",
            border: "1.2px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          type="text"
          value={this.state.company != null ? this.state.company : "---"}
          onChange={this.handlechangecompany}
          className="p-1"
        />
      );
    } else {
      if (this.state.company) {
        return <>{this.state.company}</>;
      } else {
        return <>---</>;
      }
    }
  }
  selectCountry(val) {
    console.log(val);
    this.setState({ country: val });
  }

  selectRegion(val) {
    console.log(val);
    this.setState({ state: val });
  }
  hanglechangeedit = (e) => {
    this.setState({ designation: e.target.value });
  };
  editdesignation() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <input
          placeholder="Your Designation"
          style={{
            width: "100%",
            height: "33px",
            borderRadius: "5px",
            border: "1.5px solid #E4E4E4",
            backgroundColor: "#fff",
          }}
          value={this.state.designation != null ? this.state.designation : "--"}
          onChange={this.hanglechangeedit}
          type="text"
          className="p-1"
        />
      );
    } else {
      if (this.state.designation) {
        return <>{this.state.designation}</>;
      } else {
        return <>---</>;
      }
    }
  }

  editpasstext() {
    if (this.state.editpage === true) {
      return null;
    } else {
      return <>Password</>;
    }
  }

  editpass() {
    if (this.state.editpage === true) {
      return null;
    } else {
      return <>*******</>;
    }
  }
  // handlechangeaddress = e => {
  //   let userdetails = { ...this.state.userDetails };
  //   userdetails.address = e.target.value;
  //   this.setState({ userDetails: userdetails });
  // };
  renderEditAddress() {
    let data = this.state.userDetails;
    if (this.state.editpage === true) {
      return (
        <div>
          <input
            placeholder="address"
            style={{
              width: "100%",
              height: "33px",
              borderRadius: "5px",
              border: "1.2px solid #E4E4E4",
              backgroundColor: "#fff",
            }}
            type="text"
            value={this.state.address}
            onChange={(e) => this.setState({ address: e.target.value })}
            className="p-1"
          />
          <input
            placeholder="City"
            style={{
              width: "100%",
              height: "33px",
              borderRadius: "5px",
              border: "1.2px solid #E4E4E4",
              backgroundColor: "#fff",
            }}
            type="text"
            value={this.state.city == null ? "" : this.state.city}
            onChange={(e) => this.setState({ city: e.target.value })}
            className="p-1 mt-1"
          />

          <div
            className="rowDefault "
            style={{ justifyContent: "space-between" }}
          >
            <RegionDropdown
              country="India"
              className="region_select"
              value={this.state.state}
              onChange={(val) => this.selectRegion(val)}
            />
            <input
              autofocus
              value={this.state.pincode == null ? "" : this.state.pincode}
              type="text"
              id="defaultFormRegisterNameEx"
              className="form-control region_select"
              placeholder="Enter pincode"
              onChange={(text) => this.setState({ pincode: text.target.value })}
            />
          </div>
        </div>
      );
    } else {
      return (
        <p>{`${this.state.address ? this.state.address : ""}, ${
          this.state.city ? this.state.city : ""
        }, ${this.state.state ? this.state.state : ""}, ${
          this.state.pincode ? this.state.pincode : ""
        }`}</p>
      );
    }
  }

  getAddress = () => {
    this.setState({ loading: true });
    let details = {
      user_id: this.state.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            address_id: responseJson.address_id,
            address: responseJson.address,
            state: responseJson.state,
            pincode: responseJson.pincode,
            city: responseJson.city,
            address_loading: false,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  editAddress = () => {
    if (this.state.state == null || this.state.state == "") {
      swal("Please fill the mandatory fields", "", "info");
    } else {
      this.setState({
        address_loading: true,
      });
      let details = {
        address_id: this.state.address_id,
        address: this.state.address,
        state: this.state.state,
        city: this.state.city,
        pincode: this.state.pincode,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      console.log(formBody);
      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_address", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.addImage();
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };

  showbutton() {
    if (this.state.editpage === false) {
      return (
        <button
          className="edit-button btn-custom"
          onClick={() =>
            this.setState({
              editpage: true,
              valid_email: true,
              valid_phone: true,
            })
          }
        >
          Edit{" "}
        </button>
      );
    } else {
      return (
        <>
          <button className="edit-button btn-custom" onClick={this.onsave}>
            Save
          </button>
          <button
            className="edit-button btn-custom reset"
            onClick={() =>
              this.setState(
                {
                  user_id: JSON.parse(window.localStorage.getItem("userdata"))
                    .user_id,
                  editpage: false,
                  loading: true,
                },
                () => this.getUserDetails()
              )
            }
          >
            Cancel
          </button>
        </>
      );
    }
  }

  // onsave = async () => {
  //   let checkuser = this.state.password;
  //   // var passwordHash = require('password-hash');

  //   //     var hashedPassword = passwordHash.generate(checkuser);

  //   if (this.state.email.length == 0) {
  //     return swal("Warning!", "Enter email!", "warning");
  //   } else if (this.state.valid_email == false) {
  //     return swal("Warning!", "Enter valid email!", "warning");
  //   }
  //   else if (this.state.phone.length==0) {
  //     return swal("Warning!", "Enter phone number!", "warning");
  //   }
  //   else if (this.state.phone.length != 10) {
  //     return swal("Warning!", "Enter valid phone!", "warning");
  //   } else if (this.state.valid_phone == false) {
  //     return swal("Warning!", "Enter valid phone!", "warning");
  //   }

  //   const { value: password } = await Swal.fire({
  //     title: "Enter your password",
  //     input: "password",
  //     inputPlaceholder: "Enter your password",
  //     inputAttributes: {
  //       maxLength: 40,
  //       autoCapitalize: "off",
  //       autoCorrect: "off",
  //       autoComplete: "off"
  //     }
  //   });

  //   let val = md5(password);

  //   if (val != checkuser) {
  //     return Swal.fire("Wrong Password");
  //   }
  //   swal("Success!", "Successfully changed !", "success");

  //   // if (tenure!==this.) {
  //   //     alert("You have entered " + tenure + " years" );
  //   // }
  //   let details = {
  //     user_id: this.state.user_id,
  //     name: this.state.userDetails.name,
  //     phone: this.state.phone,
  //     email: this.state.email,
  //     password: this.state.password,
  //     gender: this.state.gender,
  //    // city: this.state.userDetails.city,
  //   //  address: this.state.userDetails.address,
  //     company: this.state.userDetails.company,
  //     designation: this.state.userDetails.designation,
  //     // qualifications:this.state.aqualification,
  //     // qualifications:this.state.pqualification,
  //     qualifications: this.state.userDetails.qualifications,

  //     date_of_birth: this.state.userDetails.date_of_birth
  //   };
  //   // this.validateemail(details.email)
  //   // window.location.reload()

  //   //  console.log(details,'fetching-details')
  //   let formBody = [];
  //   for (let property in details) {
  //     let encodedKey = encodeURIComponent(property);
  //     let encodedValue = encodeURIComponent(details[property]);
  //     formBody.push(encodedKey + "=" + encodedValue);
  //   }
  //   formBody = formBody.join("&");

  //   this.setState({
  //     response: fetch("https://www.vimasmiham.com/edit_user_details", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //         "Cache-Control": "max-age=31536000"
  //       },
  //       body: formBody
  //     })
  //       .then(response => response.json())
  //       .then(responseJson => {
  //         window.localStorage.removeItem('userdata')
  //           window.localStorage.setItem('userdata',JSON.stringify(responseJson))
  //         this.addImage();
  //       })
  //       .catch(error => {
  //         this.setState({
  //           loading: false
  //         });

  //         console.log(error);
  //       })
  //   });

  // };
  addImage = async () => {
    if (this.state.profile_pic != null) {
      let formData = new FormData();
      formData.append("user_id", this.state.user_id);
      formData.append("file", this.state.profile_pic);
      for (let key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
      this.setState({
        response: fetch("https://www.vimasmiham.com/add_profile_pic", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            window.location.reload();
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    } else {
      window.location.reload();
    }
  };

  editPicture() {
    let imageUrl = "";
    if (this.state.editpage === true) {
      if (this.state.profile_pic_url == null) {
        return (
          <React.Fragment>
            <hr />
            <div className="col-9 pl-2">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="image-label subject-labels"
              >
                Profile Picture:
              </label>
              <div className="custom-file">
                <input
                  type="file"
                  className="custom-file-input"
                  id="inputGroupFile01"
                  aria-describedby="inputGroupFileAddon01"
                  onChange={(event) =>
                    this.setState({
                      profile_pic: event.target.files[0],
                      profile_pic_url: URL.createObjectURL(
                        event.target.files[0]
                      ),
                    })
                  }
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  Choose file
                </label>
              </div>
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "10px 10px",
              width: "45%",
            }}
            className="col-3 pl-1"
          >
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="image-label subject-labels"
            >
              Profile picture:
            </label>
            <img
              src={this.state.profile_pic_url}
              className=""
              style={{ margin: "0", width: "100px" }}
              alt="news_image"
            />
            <MDBIcon
              className="cross_icon"
              onClick={() => {
                this.setState({ profile_pic: null, profile_pic_url: null });
              }}
              icon="times-circle"
              style={{ position: "absolute", top: "20px", right: "-5px" }}
            />
          </div>
        );
      }
    }
  }

  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Please make sure the passwords match!", {
        className: "red-bg"
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email,
      otp: this.state.otp,
      password: this.state.newPassword
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              passwordModal: false,

              // add_modal:false
            });
            swal(
              "Password changed!",
              "Your password has been reset!",
              "success"
            );
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  changePassword() {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {

            swal(
              "OTP Verification!",
              "An OTP has been sent to your registered email!",
              "info"
            );
            this.setState((prevState) => ({
              loading:false, 
              passwordModal:!prevState.passwordModal
            })
            )
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });

  }

  togglePasswordChange = () => {
    
  };

  renderChangePasswordModal() {
    return (
      <MDBModal isOpen={this.state.passwordModal} toggle={this.togglePasswordChange}>
        <MDBModalHeader toggle={this.togglePasswordChange}>
          <span
            style={{
              fontFamily: "Ubuntu-r",
              fontSize: "18px",
              color: "#4A4A4A",
            }}
          >
            Change password
          </span>{" "}
        </MDBModalHeader>
        <MDBModalBody>
        <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%"
              }}
            >
              <div style={{ textAlign: "center", margin: "3%" }}>
                <text>Set New Password</text>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  placeholder="OTP"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text => this.setState({ otp: text.target.value })}
                />
              </div>

              <div style={{ width: "100%" }} className="d-none">
                <input
                  placeholder="Email or Phone"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="Email"
                  onChange={text =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter New Password"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ newPassword: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter Password Again"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ ConfirmNewPassword: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#FF8500",
                    color: "#fff"
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.handleOtp()}
                >
                  Change Password
                </button>
              </div>
            </div>
        </MDBModalBody>
      </MDBModal>
    );
  }

  renderData() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    let data = this.state.userDetails;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <Fade>
          <div className="account-details-container">
            <h1 className="my-account-title mt-4 mb-0 text-center text-md-left">
              {" "}
              My Account
            </h1>
            <p className="my-account-subtitle mb-4 text-center text-md-left">
              {" "}
              Edit your account details here
            </p>

            <hr />

            <div className="row ml-sm-3 col-sm-12 my-acc">
              <div className="col-12 col-sm-6">
                <table className="table table-responsive">
                  <tbody>
                    <tr>
                      <td>User Name</td>
                      <td>{this.editname()}</td>
                    </tr>

                    <tr>
                      <td>Mobile</td>
                      <td>{this.editmobile()}</td>
                    </tr>

                    <tr>
                      <td>Email</td>
                      <td>{this.editemail()}</td>
                    </tr>

                    <tr>
                      <td> {this.editpasstext()}</td>
                      <td>{this.editpass()}</td>
                    </tr>

                    <tr>
                      <td>Gender</td>
                      <td>{this.editgender()}</td>
                    </tr>

                    <tr>
                      <td>Date of Birth</td>
                      <td>{this.editdob()}</td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <button
                          className="edit-button btn-custom float-left"
                          onClick={() => this.changePassword()}
                        >
                          Change password
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-12 col-sm-6">
                <table class="table table-responsive">
                  <tbody>
                    <tr>
                      <td>Qualifications</td>

                      <td>
                        <p className="academic-title">
                          <span>{this.editqualidate()}</span>{" "}
                        </p>
                        <p className="professional-title">
                          <span>{this.editqualidegree()}</span>{" "}
                        </p>
                      </td>
                    </tr>

                    <tr>
                      <td>Company</td>
                      <td>{this.editcompany()}</td>
                    </tr>

                    <tr>
                      <td>Designation</td>
                      <td>{this.editdesignation()}</td>
                    </tr>

                    <tr>
                      <td>Billing Address</td>
                      <td>{this.renderEditAddress()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {this.editPicture()}
            {this.renderChangePasswordModal()}
          </div>
        </Fade>
      );
    }
  }

  // onsave = e => {
  //   e.preventDefault();
  //   let userdata = this.state.userDetails;
  //   fetch("/", {
  //     method: "post",
  //     headers: { "Content-Type": "application/json" },
  //     body: {
  //       userdata
  //     }
  //   });
  // };
  render() {
    console.log(this.state, "state");
    return (
      <div id="my-account" className="bg-whitesmoke">
        {/* <Helmet title="My Title - Test!" /> */}
        <NewHeader
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
          active="My Account"
        />

        <div className="container-c container-my-mobile-ipad-div">
          <MetaTags>
            <title>{this.state.page_title}</title>

            <meta
              id="account"
              name="description"
              content={this.state.page_description}
            />

            {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
          </MetaTags>
          <div className="row bg-white account-components-border">
            <div className="col-12 col-md-3 px-0 account-nav-ipad">
              {this.state.userdata != null ? (
                <AccountNav
                  {...this.props}
                  toggleEdit={this.state.editpage}
                  profile_pic_url={this.state.profile_pic_url}
                  active="My Account"
                />
              ) : (
                <div></div>
              )}
            </div>

            <div className="col-12 col-sm-12 col-lg-9 align-self-start min-account">
              {this.renderData()}
            </div>
          </div>
        </div>
        <div className="container-c ">
          <div className="row flex-row-reverse mx-0">
            {this.showbutton()}

            <button className="reset-button btn-custom d-none">Reset</button>
          </div>
        </div>
        <div style={{ height: "auto" }} className="windowView">
          <MainFooterPage {...this.props} />
        </div>
        <div className="mobView">
          <StickyFooterPage {...this.props} />
        </div>
      </div>
    );
  }
}

export default AccountDetails;
