import React from "react";
import TestBg from "../../Assets/images/Evolution-BG.jpg";
import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import "./startTest.css";
import swal from "sweetalert";

import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";

class startDemoTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentWillMount() {
    this.setState({ loading: true }, () => this.getTestConfig());
  }

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  getTestConfig = async () => {
    let subject_id = window.localStorage.getItem("selected_test");
    console.log(subject_id);
    let details = {
      subject_id: subject_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_demo_test", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.log(responseJson[0])
          console.log(responseJson[0], "topics response");
          window.localStorage.setItem(
            "test_id",
            JSON.stringify(responseJson[0].test_id)
          );
          var testConfig = JSON.parse(responseJson[0].test_config);
          // var duration = JSON.parse(responseJson[0].duration);
          var type = responseJson[0].type;
          var test_name = responseJson[0].test_name;
          console.log(testConfig, "test topics");
          // console.log(duration, "duration");
          this.setState({ testConfig, type, test_name });
        })
        .then(() => this.getAllQuestions())

        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getAllQuestions = () => {
    this.setState({
      loading: true,
    });

    let topicIds = [];
    this.state.testConfig.forEach((element) => {
      topicIds.push(Number(element.topic_id));
    });
    console.log(topicIds);
    console.log(this.state);
    let formBody = new FormData();
    formBody.append("topic_ids", JSON.stringify(topicIds));
    formBody.append("type", this.state.type);

    fetch("https://vimasmiham.com/get_specific_questions", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        //COMBINING ARRAYS OF QUESTIONS FROM DIFFERENT TOPICS INTO ONE ARRAY
        let questionsResponse = [];
        responseJson.forEach((array, index) => {
          //questionsResponse = [...questionsResponse,...array]
          array.forEach((question) => {
            let topics = [];
            topics.push(question.topic);
            let answers = [];
            answers[0] = new Object();
            answers[0].id = 0;
            answers[0].answer = question.correct_answer;
            answers[0].value = 1;
            answers[0].link = "";
            //other answers
            answers[1] = new Object();
            answers[1].id = 1;
            answers[1].answer = question.other_option_1;
            answers[1].value = 0;
            answers[1].link = "";

            answers[2] = new Object();
            answers[2].id = 2;
            answers[2].answer = question.other_option_2;
            answers[2].value = 0;
            answers[2].link = "";

            answers[3] = new Object();
            answers[3].id = 3;
            answers[3].answer = question.other_option_3;
            answers[3].value = 0;
            answers[3].link = "";

            question.answers = JSON.stringify(answers);
            question.topics = topics;
          });
          questionsResponse.push({
            topic_id: topicIds[index],
            questions: array,
          });
        });
        console.log(questionsResponse);
        //STRUCTURING EACH QUESTION OBJECT
        this.setState({ questions: questionsResponse });
      })
      .then(() => this.groupTestQuestions());
  };

  pickRandomQuestions(questions, limit) {
    console.log(limit);
    console.log(questions);
    var questionObject = [];
    const orderedQuestions = questions;
    console.log(orderedQuestions);
    if (orderedQuestions.length <= limit) {
      return orderedQuestions;
    } else {
      const randomizedQuestions = this.shuffleArray(orderedQuestions);
      for (let i = 0; i < limit; i++) {
        questionObject.push(randomizedQuestions[i]);
      }

      return questionObject;
    }
  }

  shuffleArray(array) {
    console.log(array);
    var shuffledArray = array;
    for (var i = shuffledArray.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = shuffledArray[i];
      shuffledArray[i] = shuffledArray[j];
      shuffledArray[j] = temp;
    }
    console.log(shuffledArray);
    return shuffledArray;
  }

  groupTestQuestions() {
    let testConfig = this.state.testConfig;

    var testsArray = this.state.questions;
    console.log(testsArray);
    //Tests Array of Objects consisting of the sorted questions
    //  var testsArray = [];
    var evaluationQuestions = [];

    testsArray.forEach((element) => {
      if (element.questions.length > 0)
        var topicConfig = testConfig.find(
          (item) => item.topic_id == element.topic_id
        );
      if (topicConfig !== undefined) {
        console.log(topicConfig);

        var questionObject = this.sortGroupedQuestions(element, topicConfig);
        console.log(questionObject);
        evaluationQuestions = [...evaluationQuestions, questionObject];
        console.log(evaluationQuestions, "evaluating questins");
        console.count("loop");
      }
    });

    var finalTestQuestions = [];

    if (this.state.type !== "mixed") {
      evaluationQuestions.forEach((element) => {
        for (var i = 0; i < element.length; i++) {
          console.log(element);
          finalTestQuestions.push(element[i]);
        }
      });
    } else {
      console.log(evaluationQuestions);

      let length = 0;
      evaluationQuestions.forEach((element) => {
        length += element.length;
      });
      console.log(length);
      let ratioValues = this.state.testConfig[this.state.testConfig.length - 1];
      const { subjective, objective } = ratioValues;
      const subjectiveLimit = Math.floor(8 * (subjective / 100));
      const objectiveLimit = Math.ceil(8 * (objective / 100));
      console.log(ratioValues, length, subjectiveLimit, objectiveLimit);

      let subjectiveCounter = 0;
      let objectiveCounter = 0;

      evaluationQuestions.forEach((element) => {
        for (var i = 0; i < element.length; i++) {
          if (element[i].type == "objective") {
            if (objectiveCounter == objectiveLimit) {
            } else {
              finalTestQuestions.push(element[i]);
              objectiveCounter++;
              console.log(finalTestQuestions, objectiveCounter);
            }
          } else {
            console.log(subjectiveCounter, subjectiveLimit);
            console.log(element);
            if (subjectiveCounter == subjectiveLimit) {
            } else {
              finalTestQuestions.push(element[i]);
              subjectiveCounter++;
              console.log(finalTestQuestions, subjectiveCounter);
            }
          }
        }
      });
      console.log(subjectiveCounter, objectiveCounter);
    }

    let array = this.shuffleArray(finalTestQuestions);

    console.log(array, "final final fingers crossed");
    finalTestQuestions.forEach((element) => {
      if (element.question_id) {
        var answers = JSON.parse(element.answers);
        answers = this.shuffleArray(answers);
        element.answers = JSON.stringify(answers);
        console.log(element.answers);
      }
    });

    console.log(finalTestQuestions);
    this.setState({ finalTestQuestions, loading: false });
  }

  sortGroupedQuestions(topicQuestions, topicConfig) {
    var easyQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 1),
      topicConfig.easy
    );
    var moderateQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 2),
      topicConfig.moderate
    );
    var toughQuestions = this.pickRandomQuestions(
      topicQuestions.questions.filter((item) => item.difficulty_level == 3),
      topicConfig.tough
    );
    console.log(easyQuestions, "easy");
    console.log(moderateQuestions, "modeate");
    console.log(toughQuestions, "tough");

    return [...easyQuestions, ...moderateQuestions, ...toughQuestions];
  }

  renderInstructionAndDisclaimer() {
    return (
      <div>
        <p>
          The test series has been designed by industry experts to mimic the
          actual exam, and uses fuzzy logic to randomly pick questions from a
          bank of more than 3000 questions. The candidates are therefore
          encouraged to take the tests as many times as possible , especially
          since additional questions are also being added to the bank every
          week.
        </p>
        <ul>
          <h6>
            <strong>The detailed coding for the test is as follows:</strong>
          </h6>
          <li>Filled orange box denotes the current question you are on</li>
          <li>Filled blue boxes denote questions you have answered</li>
          <li>
            Boxes with an orange outline denote unanswered/unvisited questions
          </li>
          <li>
            Boxes with a green outline denote questions you would like to review
            later
          </li>
          {/* <li>You may revisit any question by clicking on the numbered boxes on the right hand side</li>
            <li>You may leave the test at any time, but none of the session data will be saved. You will have to restart the test from the beginning</li> */}
        </ul>
      </div>
    );
  }

  handleStartTest() {
    this.props.history.push({
      pathname: "/demo-questions",
      state: {
        questions: this.state.finalTestQuestions,
        duration: this.state.duration,
        test_type: this.state.type,
        test_name: this.state.test_name,
      },
    });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
          className="start-test-page"
        >
          <div className="windowView">
            <NewHeader
              {...this.props}
              backgroundColor="#FFFFFF"
              textcolor="#000"
              width="100%"
              height="auto"
            />
          </div>

          <div className="vsMobView">
            <NewHeader
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div>

          <div className="start-test">
            <div className="position-relative">
              {" "}
              <div className="bg_img_div">
                <img className="bg_img" src={TestBg} />
              </div>
              <div className="button_and_text">
                <div className="text_on_test_BGimg">
                  <h2 className="productMainSecondTextH2">
                    {this.state.test_name}
                  </h2>

                  <text>
                    Ensure your marks in the Insurance Industry by completing
                    this test.{" "}
                  </text>
                </div>
                <div className="buttons_flex">
                  <div>
                    <button
                      className="Button_NotNow"
                      onClick={() => this.props.history.push("/lessons")}
                    >
                      {" "}
                      Not Now
                    </button>
                  </div>

                  <div style={{ marginLeft: "10px" }}>
                    <button
                      className="Button_startTest"
                      onClick={() => this.handleStartTest()}
                    >
                      {" "}
                      Start Test{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="startTestDecrpition">
              <div>
                <h1>Instructions And Disclaimer</h1>
                <div className="RegistrationCheckBoxhr"></div>
              </div>
              <div className="startTestList">
                {this.renderInstructionAndDisclaimer()}
              </div>
            </div>
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default startDemoTest;
