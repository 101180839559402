import React from "react";
import Logo from "../../../Assets/images/logo.png";
import "./newLessonHeader.css";

import UserPNG from "../../../Assets/images/dp1.png";
//import TogglePNG from '../../../Assets/images/Toggle.png'
import LeftArrow from "../../../Assets/images/left-arrow.png";
import RightArrow from "../../../Assets/images/buttons-arrow.png";
import "../../../Components/commonStyles.css";
import ExamPNG from "../../../Assets/images/exams-toggle.png";
import SearchPNG from "../../../Assets/images/search.png";
import swal from "sweetalert";
import "../../../Components/HomeHeader/header.css";
import "../../../index.css";
import { css } from "@emotion/core";
import MaleAvatar from '../../../Assets/images/male.png'
import FemaleAvatar from '../../../Assets/images/female.png'

import StarsRating from "stars-rating";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";
import TogglePNG from "../../../Assets/images/Toggle2.png";
import { Button, ButtonToolbar } from "react-bootstrap";
import {
  MDBCloseIcon,
  MDBCollapse,
  MDBIcon,
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import GoogleImg from "../../../Assets/images/google.png";
import FbImg from "../../../Assets/images/facebook.png";
import { matchPath } from "react-router-dom";
import Fade from "react-reveal";
const responseFacebook = response => {
  console.log(response);
};
const responseGoogle = response => {
  console.log(response);
};

class NewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subjects: [],
      header_data: [],
      menu_data: [],
      email: "",
      heade_width:0,
      password: "",
      clicked: [],
      activeIndex: 0,
      login: false,
      forgetPassword: false,
      userdata: null,
      otp: false,
      menu_selected: false,

      buttons: [
        {
          name: "My Account",
          url: "/my-account"
        },
        {
          name: "My Cart",
          url: "/my-cart"
        },
        {
          name: "My Courses",
          url: "/my-courses"
        },
        {
          name: "Order History",
          url: "/purchase-history"
        },
        {
          name: "Payment Options",
          url: "/my-account"
        }
      ],
      menuItems: [
        {
          itemName: "My Cart",
          url: "/my-cart"
          // icon:<MDBIcon icon="caret-down" />
        },
        {
          itemName: "Exams",
          subItems: true
          // icon:<MDBIcon icon="caret-down" />
        },

        {
          itemName: "Test Series"
        },
        {
          itemName: "Case Laws",
          url: "/case-laws "
        }
      ],

      activeButton: props.active,
      userImage: `${UserPNG}`,
      userName: `Priyanka Singh`,
      userEmail: `priyankasingh@gmail.com`,
      viewForm: "",
      userdata: null,
      login: false,
      forgetPassword: false,
      otp: false,
      email: "",
      password: "",
      loading: false,
      examsMenu: [],
      verticalsMenu: [],
      showSubmenu: false,
      ratingReview: false
    };
  }

  componentDidMount() {
    this.setState({ viewForm: "My Account" });
    this.getExams();
    this.getVerticals();
  }

  getVerticals = () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "verticals");
          this.setState({
            verticalsMenu: responseJson.reverse(),
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };

  getExams = async () => {
    console.log("fetching exams");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log("Ëxams", responseJson);
          console.log(JSON.parse(responseJson[0].verticals));
          this.setState({
            examsMenu: responseJson.reverse()
          });
        })
        .catch(error => {
          console.log(error);
        })
    });
  };



  setActiveButton(data) {
    this.setState({ activeButton: data.name }, () =>
      this.props.history.push(data.url)
    );
  }

  renderButtons() {
    return this.state.buttons.map((data, index) => {
      return index <= 4 ? (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {data.name}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      ) : (
        ""
      );
    });
  }

  renderMobileButtons() {
    return this.state.buttons.map((data, index) => {
      return index !== 4 ? (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {data.name}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      ) : (
        <React.Fragment>
          <button
            className={
              this.state.activeButton === data.name
                ? "navigation-button bg-active"
                : "navigation-button"
            }
            value={data.name}
            onClick={() => this.setActiveButton(data)}
            key={index}
          >
            {data.name}{" "}
            <span className="">
              <img className="arrow-image d-none d-sm-block" src={RightArrow} />
            </span>
          </button>
          <hr className="mobile-menu-items-divider" />
        </React.Fragment>
      );
    });
  }

  renderProfilePic() {
    if (
      this.state.userdata.profile_pic == null ||
      this.tate.userdata.profile_pic === undefined
    ) {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer avatar"
          src={
            this.state.userdata.gender == "Male"
              ? MaleAvatar
              : FemaleAvatar
          }
        />
      );
    } else {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }

  renderProfilePic2() {
    if (this.state.userdata.profile_pic == null) {
      console.log('no profile pic')
      return (
        <img
          className="user-img cursor-pointer avatar navBarDP"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../../Assets/images/male.png")
              : require("../../../Assets/images/female.png")
          }
        />
      );
    } else {
      console.log(this.state.userdata.profile_pic)
      return (
        <img
          className="user-img cursor-pointer avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }
  // logout(){
  //   window.localStorage.removeItem('userdata')
  //   window.location.reload()
  // }
  renderMenu() {
    if (this.state.menu_selected) {
      return (
        <div className="headerToggleMenu" style={{ position: "absolute" }}>
          <div
            className="rowDefault"
            style={{ alignItems: "center", marginBottom: 10, paddingLeft: 20 }}
          >
            {this.renderProfilePic()}
            <div className="colDefault">
              <text>{this.state.userdata.name}</text>
              <text className="email-text-profile-menu">
                {this.state.userdata.email}
              </text>
            </div>
          </div>
          <p
            onClick={() => this.props.history.push("/my-account")}
            className="profile-menu-list"
          >
            My Account
          </p>
          <p
            onClick={() => this.props.history.push("/my-courses")}
            className="profile-menu-list"
          >
            My Courses
          </p>
          <p
            onClick={() => this.props.history.push("/purchase-history")}
            className="profile-menu-list"
          >
            Purchase History
          </p>
          <p
            onClick={() => this.props.history.push("/my-cart")}
            className="profile-menu-list"
          >
            My Cart
          </p>

         
          <hr />
          {/* <p className="profile-menu-list">Help</p> */}
          <p
            style={{ marginBottom: 20 }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p>
        </div>
      );
    }
  }

  getSubjects = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET"
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({
            subjects: responseJson,

            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata)
          // subjext_id:this.props.subject_id
        },
        () => console.log(this.state.userdata, "uuuu")
      );
    }
  }
  componentWillMount() {
    this.getDetails();
    this.getSubjects();
  }

  toggle_edit = () => {
    this.setState({
      login: !this.state.login
    });
  };

  renderModal() {
    if (this.state.login) {
      return (
        <MDBModal isOpen={this.state.login} toggle={this.toggle_edit}>
          <MDBModalHeader toggle={this.toggle_edit}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A"
              }}
            >
              Log in to Your Vima Smiham Account
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <div className="socialButtonDiv">
                <FacebookLogin
                  appId="1409504552532102"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={responseFacebook}
                  render={renderProps => (
                    <button
                      className="socialIconbutton1"
                      onClick={renderProps.onClick}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={FbImg}
                          alt="google-logo"
                          className="logoHeight"
                          height="20px"
                        />
                      </div>
                      <span style={{ float: "left", marginLeft: "10%" }}>
                        {" "}
                        Continue with Facebook
                      </span>
                    </button>
                  )}
                />
              </div>

              <div className="socialButtonDiv">
                <GoogleLogin
                  clientId="854571101897-u01v27l8i6b06fo6okvi0tt5akkq5hck.apps.googleusercontent.com"
                  render={renderProps => (
                    <button
                      className="socialIconbutton2"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={GoogleImg}
                          alt="google-logo"
                          className="logoHeight"
                          height="20px"
                        />
                      </div>{" "}
                      <span
                        style={{
                          float: "left",
                          marginLeft: "7%",
                          color: "#4A4A4A"
                        }}
                      >
                        {" "}
                        Continue with Google
                      </span>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  // onLogoutSuccess={logout}
                />
              </div>
              <form onSubmit={() => this.LoginForm()} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input
                    placeholder="Email or Phone"
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff"
                    }}
                    type="Email"
                    onChange={text =>
                      this.setState({ email1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff"
                    }}
                    placeholder="Password"
                    type="password"
                    onChange={text =>
                      this.setState({ password1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <button
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      backgroundColor: "#dbaa34",
                     border: "1.5px solid #dbaa34",
                      color: "#fff"
                    }}
                    className="ButtonLogIn"
                    type="button"
                    onClick={() => this.LoginForm()}
                  >
                    Log in
                  </button>

                  <p
                    className="forgetPassword"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A"
                    }}
                  >
                    <text>or </text>
                    <span style={{ color: "#0093D9" }}>
                      <a
                        onClick={() =>
                          this.setState({ login: false, forgetPassword: true })
                        }
                      >
                        <text>Forgot Password</text>
                      </a>
                    </span>
                  </p>
                  <p
                    className="dontHaveAccount"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A"
                    }}
                  >
                    Don't have a account ?{" "}
                    <a
                      onClick={() =>
                        this.setState({ login: false }, () =>
                          this.props.history.push("/sign-up")
                        )
                      }
                    >
                      <span style={{ color: "#0093D9" }}>Sign Up</span>
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };

  renderExamsMenu() {
    if (this.state.showSubmenu) {
      return (
        <Fade>
          {" "}
          <div className="exam-submenu-container">
            <div className="row_reverse">
              <div
                className="cross_div"
                id="up"
                onClick={() => this.setState({ showSubmenu: false })}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={require("../../../Assets/images/cross.png")}
                  className="cross"
                  alt="cross"
                />
              </div>

              <div className="exam_menu_logo">
                <img src={Logo} alt="Vima Logo" className="exams_menu_logo" />
              </div>
            </div>
            <div className="exam-submenu">
              {this.state.verticalsMenu.sort((a,b)=>a.sort_order-b.sort_order).map((item, index) => {
                if (item.status == 1)
                  return (
                    <ul className="px-0 w-25" key={index}>
                      {item.vertical_name}
                      <hr
                        className="mt-1 mx-0 w-50"
                        style={{ border: "1px solid #d2d2d2" }}
                      />
                      {this.renderVerticalsSubMenu(item.vertical_id)}
                    </ul>
                  );
              })}
            </div>
          </div>
        </Fade>
      );
    } else {
      return null;
    }
  }
  renderSubjectsInExams = (item, exam_id) => {
    let subjectExams = JSON.parse(item.exams);
    let exam_ids = [];
    subjectExams.forEach(element => {
      exam_ids.push(element.exam_id);
    });
    if (exam_ids.includes(exam_id)) {
      return (
        <li
          className="border-bottom pb-2 w-75 pt-2 ml-3"
          onClick={() => this.setSubject(item)}
        >
          {item.subject_name}
        </li>
      );
    }
  };
  setSubject = item => {
    console.log(item);
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(item));
    this.props.history.push("/product-details");
  };
  renderVerticalsSubMenu(vertical_id) {
    return this.state.examsMenu.map(examItem => {
      var exam_verticals = JSON.parse(examItem.verticals);
      return exam_verticals.map((item, index) => {
        if (item.vertical_id === vertical_id && examItem.status == 1) {
          return (
            <>
              <li
                key={index}
                className="d-flex align-items-center justify-content-betweeen"
                style={{ marginBottom: "1rem" }}
              >
                <span onClick={() => this.selectVertical(item, index)}>
                  {examItem.exam_name}{" "}
                </span>
                <MDBIcon
                  icon="plus"
                  className="iconPlus"
                  onClick={this.toggleCollapse(examItem.exam_name)}
                  color="primary"
                  style={{ marginLeft: "0.5rem" }}
                />
              </li>
              <MDBCollapse
                id={examItem.exam_name}
                isOpen={this.state.collapseID}
              >
                <MDBContainer>
                  <ul className="pl-0">
                    {this.state.subjects.map((item, index) => {
                      return this.renderSubjectsInExams(item, examItem.exam_id);
                    })}
                  </ul>
                </MDBContainer>
              </MDBCollapse>
            </>
          );
        }
      });
    });
  }

   renderLogOut(){
    if (this.state.userdata == null) {
      return (
        <div className="button-mobile-side-bar">
                </div>
      );
    } else {
      return (
        <div className="headerToggleMenu side-bar-mobile-help-loin-top">
        
          <hr />
          <p style={{ color: "white" }} className="profile-menu-list d-none">
            Help
          </p>
          <p
            style={{ marginBottom: "20", color: "white" }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p>
        </div>
      );
    }
  }


  selectVertical(item, index) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    //   window.localStorage.setItem('collapse',0)
    //   if (this.props.location.pathname.indexOf("dashboard") === 0 ) {
    //     document.location.reload();
    // }
    //  else{
    //    this.props.history.push('/dashboard');
    // }

    if (matchPath(this.props.location.pathname, "/dashboard")) {
      document.location.reload();
    } else {
      this.props.history.push("/dashboard");
    }
  }

  handleClick(index, e) {
    let clicked = this.state.clicked;
    clicked[index] = !clicked[index];
    this.setState({ clicked: clicked });
  }
  handleOutsideClick(event) {
    if (!this.refs.megaMenu.contains(event.target)) {
      this.setState({
        clicked: []
      });
    }
  }

  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Password and confirm Password not Match!", {
        className: "red-bg"
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
      otp: this.state.otp,
      password: this.state.newPassword
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: false,
              forgetPassword: false,
              login: true

              // add_modal:false
            });
            swal(
              "Password changed!",
              "Your password has been reset. Please Login!",
              "success"
            );
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  forgotPassword = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: true,
              forgetPassword: false

              // add_modal:false
            });
            swal(
              "OTP Verification!",
              "An OTP has been sent to your registered email!",
              "info"
            );
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  LoginForm = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email1,
      password: this.state.password1
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/user_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            this.setState({
              loading: false
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            window.location.reload();
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  toggle_edit11 = () => {
    this.setState({
      otp: !this.state.otp
    });
  };

  renderModalOtp() {
    if (this.state.otp) {
      return (
        <MDBModal isOpen={this.state.otp} toggle={this.toggle_edit11}>
          <MDBModalHeader toggle={this.toggle_edit11}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A"
              }}
            >
              One Time Password{" "}
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%"
              }}
            >
              <div style={{ textAlign: "center", margin: "3%" }}>
                <text>Set New Password</text>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  placeholder="OTP"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text => this.setState({ otp: text.target.value })}
                />
              </div>

              <div style={{ width: "100%" }} className="d-none">
                <input
                  placeholder="Email or Phone"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="Email"
                  onChange={text =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter New Password"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ newPassword: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter Password Again"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ ConfirmNewPassword: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#FF8500",
                    color: "#fff"
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.handleOtp()}
                >
                  Change Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  toggle_edit1 = () => {
    this.setState({
      forgetPassword: !this.state.forgetPassword
    });
  };

  renderModalForget() {
    if (this.state.forgetPassword) {
      return (
        <MDBModal isOpen={this.state.forgetPassword} toggle={this.toggle_edit1}>
          <MDBModalHeader toggle={this.toggle_edit1}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A"
              }}
            >
              Forgot Password
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%"
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "5%" }}>
                <text>Please Enter Your Email </text>
              </div>
              <div style={{ width: "100%", marginBottom: "2%" }}>
                <input
                  placeholder="Email"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="Email"
                  onChange={text =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: ".5px solid #E5E5E5",
                    backgroundColor: "#FF8500",
                    color: "#fff"
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.forgotPassword()}
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  renderMobileMenuitems() {
    return this.state.menuItems.map((item, index) => {
      return !item.subItems ? (
        <Button
          variant=""
          key={index}
          onClick={() => this.props.history.push(item.url)}
        >
          {item.itemName}
        </Button>
      ) : (
        <>
          <MDBBtn
            color="#0093d9"
            onClick={this.toggleCollapse("basicCollapse")}
            style={{}}
          >
            <span> {item.itemName}</span>{" "}
            <span style={{ marginLeft: "5px", marginTop: "2px" }}>
              {item.icon}
            </span>
          </MDBBtn>
          <MDBCollapse
            id="basicCollapse"
            isOpen={this.state.collapseID}
            style={{ backgroundColor: "rgb(41, 128, 169)" }}
          >
            <p>{this.renderModals()}</p>
          </MDBCollapse>
        </>
      );
    });
  }

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata)
      });
    }
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };
  logout() {
    window.localStorage.clear();
    this.props.history.push("/");
  }

  renderMenu1() {
    if (this.state.userdata == null) {
      {console.log("no user data")}
      return (
        <div className="button-mobile-side-bar">
          <button
            style={{ color: "white" }}
            onClick={this.toggle_edit}
            id="login-button-mobile"
          >
            Log In
          </button>
          <button
            style={{ color: "white" }}
            onClick={() => this.props.history.push("/sign-up")}
            id="register-button-mobile"
          >
            Register
          </button>
          {/* <button style={{marginLeft:'50px'}} className="header-options"> <img src={PinterestLogo} alt="pinterest logo" /> </button> */}
        </div>
      );
    } else {
      {console.log("yes user data")}
      return (
        <div className="headerToggleMenu">
          <div
            className="rowDefault"
            style={{
              margin: "20px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <div className="mx-auto">{this.renderProfilePic2()} </div>
            <div className="colDefault mx-auto" >
              <text
                   style=
                   {{color: 'white',
                    fontSize: '18px',
                    fontWeight:'lighter',
                    textAlign:'center'
                  }} 
              >
                {this.state.userdata.name}
              </text>
              <text
                
                className="email-text-profile-menu"
                style=
                {{color: 'white',
                 fontSize: '18px',
                 fontWeight:'lighter',
                textAlign:'center'
               }} 
              >
                {this.state.userdata.email}
              </text>
            </div>
          </div>
          <p
            style={{ color: "white" }}
            onClick={() => this.props.history.push("/my-account")}
            className="profile-menu-list"
          >
            My Account
          </p>
          <p
            style={{ color: "white" }}
            onClick={() => this.props.history.push("/my-courses")}
            className="profile-menu-list"
          >
            My Courses
          </p>
          <p
            style={{ color: "white" }}
            onClick={() => this.props.history.push("/purchase-history")}
            className="profile-menu-list"
          >
            Purchase History
          </p>
       
     
        {/* <p style={{ color: "white" }} className="profile-menu-list">
            Help
          </p> */}
          {/* <p
            style={{ marginBottom: "20", color: "white" }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p> */}
        </div>
      );
    }
  }

  renderProfilePic() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer  avatar"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../../Assets/images/male.png")
              : require("../../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }

  renderProgress() {
    const ratingChanged = newRating => {
      console.log(newRating);
    };
    const percentage = 1.5;
    if (this.state.menu_selected1) {
      return (
        <div
          className="headerToggleMenuProgress MDBCloseIconXX"
          style={{ position: "absolute" }}
        >
          <div>
            <MDBCloseIcon
              className="MDBCloseIcon1"
              onClick={() =>
                this.setState({ menu_selected1: !this.state.menu_selected1 })
              }
            />
          </div>
          <div>
            <p className="percentage-menu-list">
              <span style={{ fontSize: "14px" }}>
                You've completed{" "}
                <span style={{ color: "#0093D9" }}>
                  {this.props.progress >= 100?100:this.props.progress}%{" "}
                </span>{" "}
               of the course
              </span>

              {/* <p style={{fontSize:'12px'}} >Finish course to get your certificate</p>
               */}
            </p>
          </div>
        </div>
      );
    }
  }

  renderButtons() {
    return this.state.buttons.map((data, index) => {
      return index <= 4 ? (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {data.name}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      ) : (
        ""
      );
    });
  }

  renderLogin() {
    const ratingChanged = newRating => {
      console.log(newRating);
    };
    const percentage = 1.5;
    if (this.state.userdata == null) {
      return (
        <div className="">
          <a
            onClick={this.toggle_edit}
            className="header-options"
            id="login-button"
          >
            Log In
          </a>
          <a
            onClick={() => this.props.history.push("/sign-up")}
            className="header-options mr-4"
            id="register-button"
          >
            Register
          </a>
          <button className="header-options-fb">
          <MDBIcon fab icon="facebook-f" />
          </button>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative" }}>
          <div className="rowDefault  align-items-center icon-div-home icon-div-lesson justify-content-end">
            <div className="haderRatingChanged" style={{opacity:0}}>
              <div className="LeaveArating">
                {" "}
                <a
                  onClick={() => this.setState({ ratingReview: true })}
                  style={{ fontSize: "16px" }}
                >
                  <MDBIcon far icon="star" />{" "}
                  <span style={{ fontSize: "13px" }}> Leave a Rating</span>{" "}
                </a>
              </div>
            </div>
            {/* <img className="wishlist-img cursor-pointer mr-2 wishlist-img-color " src={WishlistPNG} /> */}

            <div className="haderProggressBar">
              <CircularProgressbarWithChildren
                value={this.props.progress}
                styles={buildStyles({
                  pathColor: "#FE8400",
                  trailColor: "black",
                  position: "relative",
                  cursor: "pointer"
                })}
              >
                {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                {/* <img className='TrophyProgressbar' style={{ width: 20,}} src={Trophy} alt="doge" /> */}
                {/* */}
                <i
                  className="fas fa-trophy TrophyProgressbar"
                  onClick={() =>
                    this.setState({
                      menu_selected1: !this.state.menu_selected1
                    })
                  }
                ></i>
                {this.renderProgress()}
              </CircularProgressbarWithChildren>
            </div>

            <div className="d-none">
              {/* <text> YourProgress</text> */}
              <div>
                <button
                  style={{ fontSize: "13px" }}
                  className="YourProgressBtn"
                  onClick={() =>
                    this.setState({
                      menu_selected1: !this.state.menu_selected1
                    })
                  }
                >
                  Your Progress
                </button>
              </div>
            </div>

            {this.renderProfilePic()}
            {/* <button   style={{background:'transparent',border:'none'}}  className="menu-toggle"><img src={TogglePNG}/></button> */}
          </div>
          {this.renderMenu()}
        </div>
      );
    }
  }

  checklogin = () => {
    if (this.state.userdata === null) return;
    else {
      return (
        <div className="col-7 text-center exam-input-column">
          <button
            className="exam-toggle"
            onClick={() =>
              this.setState({ showSubmenu: !this.state.showSubmenu })
            }
          >
            <img src={ExamPNG} />
            &nbsp;&nbsp;&nbsp;<span id="exam-toggle-text">Exams</span>
          </button>
          <input
            className="search-input form-control p-2 rounded header-search"
            type="search"
            placeholder="Search"
          />
          <img src={SearchPNG} alt="search" className="search-icon-header" />
        </div>
      );
    }
  };
  toggle_edit_rating = () => {
    this.setState({
      ratingReview: !this.state.ratingReview
    });
  };

  renderModalRating() {
    const ratingChanged = newRating => {
      console.log(newRating, "newRating");
    };

    if (this.state.ratingReview) {
      return (
        <MDBModal
          isOpen={this.state.ratingReview}
          toggle={this.toggle_edit_rating}
        >
          <MDBModalHeader toggle={this.toggle_edit_rating}>
            <span
              style={{
                fontFamily: "Ubuntu-b",
                fontSize: "18px",
                color: "#4A4A4A",
                textAlign: "center"
              }}
            >
              How would you rate this course?
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div className="ratingChangedDiv">
              <div className="ratingChangedDiv_Select_Rating">
                <text
                  style={{
                    fontFamily: "Ubuntu-b",
                    fontSize: "18px",
                    color: "#4A4A4A",
                    textAlign: "center"
                  }}
                >
                  Select Rating
                </text>
              </div>
              <div className="ratingChangedDiv_StarsRating">
                <StarsRating
                  count={5}
                  onChange={ratingChanged}
                  size={60}
                  color2={"#ffd700"}
                />
              </div>
              <div className="ratingChangedDiv_ReviewInput">
                <MDBInput
                  type="textarea"
                  label="Enter your Review"
                  outline
                  size="lg"
                  className="textArea_ReviewInput"
                  onChange={text =>
                    this.setState({ Review: text.target.value })
                  }
                />
              </div>

              <div className="ratingChangedButtonDiv">
                <button
                  className="ratingChangedButton ButtonReset"
                  onClick={() => this.setState({ ratingReview: false })}
                >
                  Submit
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  openHeaderMobileNav() {
    console.log("clicked")
   this.setState({heade_width:250})
  }

  closeHeaderMobileNav() {
    this.setState({heade_width:0})

  }

  // renderMobileMenuitems() {
  //   return this.state.menuItems.map((item, index) => {
  //     return !item.subItems ? (
  //       <Button
  //         variant=""
  //         key={index}
  //         onClick={() => this.props.history.push(item.url)}
  //       >
  //         {item.itemName}
  //       </Button>
  //     ) : (
  //       <>
  //         <MDBBtn
  //           color="#0093d9"
  //           onClick={this.toggleCollapse("basicCollapse")}
  //           style={{}}
  //         >
  //           <span> {item.itemName}</span>{" "}
  //           <span style={{ marginLeft: "5px", marginTop: "2px" }}>
  //             {item.icon}
  //           </span>
  //         </MDBBtn>
  //         <MDBCollapse
  //           id="basicCollapse"
  //           isOpen={this.state.collapseID}
  //           style={{ backgroundColor: "rgb(41, 128, 169)" }}
  //         >
  //           <p>{this.renderModals()}</p>
  //         </MDBCollapse>
  //       </>
  //     );
  //   });
  // }


  renderModals(){
    return this.state.verticalsMenu.map((modal,index) => {
      if(modal.status==1){
      return (
        <div style={{cursor:'pointer'}} onClick={()=>this.selectVertical(modal,index)}  key={index}>
         
            
             
              <p className="mobile-sidebar-exams">{modal.vertical_name}</p>
      
        </div>
      )
      }
    })
  
  }


  renderMobileProgress() {

    return(
      <React.Fragment>
      <CircularProgressbarWithChildren
      value={this.props.progress}
      styles={buildStyles({
        pathColor: "#FE8400",
        trailColor: "black",
        position: "relative",
        cursor: "pointer"
            })}
      onClick={() =>
        this.setState({
          menu_selected1: !this.state.menu_selected1
        })
      }
    >
      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
      {/* <img className='TrophyProgressbar' style={{ width: 20,}} src={Trophy} alt="doge" /> */}
      {/* */}
      <i
        className="fas fa-trophy TrophyProgressbar"
      ></i>
    </CircularProgressbarWithChildren>
          {this.renderProgress()}

    </React.Fragment>
    
          )
        }
    
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    return (
      <React.Fragment>
        <div id="lesson-header">
          <div className="header border-bottom container-c">
            <div className="row align-items-center">
              <div
                className="col-1 logo-container px-3 text-left pt-1 pb-3"
                style={{ padding: "1.2rem", paddingTop: "0.30rem" }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  className="header-lesson-logo"
                  src={Logo}
                  onClick={() => this.props.history.push("/")}
                />
              </div>
              <div className="col-7 offset-1 text-center exam-input-column">
                <button
                  className="exam-toggle"
                  onClick={() =>
                    this.setState({ showSubmenu: !this.state.showSubmenu })
                  }
                >
                  <img src={ExamPNG} />
                  &nbsp;&nbsp;&nbsp;<span id="exam-toggle-text">Exams</span>
                </button>
                <input
                  className="search-input form-control p-2 rounded header-search"
                  type="search"
                  placeholder="Search"
                />
                <img
                  src={SearchPNG}
                  alt="search"
                  className="search-icon-header search-icon-header-lesson"
                />
              </div>
              <div className="col-3 renderLogin-4 ">{this.renderLogin()}</div>
            </div>
          </div>

          <div className="lesson-mobile-header sticky-top shadow">
            <div className="row align-items-center">
              <div className="col-2" onClick={() => this.openHeaderMobileNav()}>
                <button
                  className="menu-toggle btn-custom bg-transparent"
                >
                  <img src={TogglePNG} />
                </button>
              </div>

              <div className="col-2 text-left">
              <img src={Logo} className="new-header-logo" 
                onClick={() => this.props.history.push("/")}
              />
              </div>
              <div className="col-8">
                {this.renderMobileProgress()}
              </div>
            </div>
          </div>
        </div>
        <div id="header-mobile-nav1" style={{width:this.state.heade_width}}>
          <div style={{ paddingTop: "35px" }}>{this.renderMenu1()}</div>
          <button
            class="close-mob-menu-button btn-custom"
            onClick={() => this.closeHeaderMobileNav()}
          >
            <img src={LeftArrow} alt="Close Account Menu" />
          </button>
          {/* {this.renderMobileButtons()} */}
          <ButtonToolbar className="mobile-menu-button-toolbar">
            {this.renderMobileMenuitems()}

            {this.renderLogOut()}
          </ButtonToolbar>
        </div>
        {this.renderModal()}
        {this.renderModalForget()}
        {this.renderModalOtp()}
        {this.renderModalRating()}
        {this.renderExamsMenu()}
      </React.Fragment>
    );
  }
}
//}

export default NewHeader;
