import React from "react";

import StickyFooter from "react-sticky-footer";

import swal from "sweetalert";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBIcon,
  MDBInput,
  MDBBtn,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";


class StickyFooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageUpdated: false,
      contact: false,
      newsLetter: false,

      insight_mail: "",
      menu_data: [],

      clicked: [],
      activeIndex: 0,
      
    };
  }
  onChange=(value)=> {
    console.log("Captcha value:", value);
    this.setState({
      captcha_value:value
    })
  }
  validateEmailInsight = text => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ insight_mail: text, valid_email: false });
      return false;
    } else {
      this.setState({ insight_mail: text, valid_email: true });
    }
  };
  addInsight = async () => {
    if (this.state.insight_mail.length == 0) {
      return  swal("Enter email for subscription!", {
        buttons: false,
        timer: 2000
      });

      
    } else if (this.state.valid_email == false) {
      console.log(this.state.insight_mail, "iii");
      return  swal("Enter valid email!", {
        buttons: false,
        timer: 2000
      });
    } else {
      let details = {
        email: this.state.insight_mail
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/insight_mail", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000"
          },
          body: formBody
        })
          .then(response => response.json())
          .then(responseJson => {
            this.setState(
              {
                insight_mail: "",

                loading: false
              },
              () => {
                if (responseJson.status == 200) {
                  swal("", responseJson.message, "info");
                  this.setState({
                    loading: false,
                    // isPageUpdated:true,
                    newsLetter: false
                  });
                } else {
                  swal("", responseJson.message, "info");
                }
              }
            );
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            //  swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  };

  // isPageUpdated_x=()=>{

  //   this.setState({
  //     isPageUpdated: !this.state.isPageUpdated
  //   });
  // }

  // renderX(){
  //   if(this.state.isPageUpdated){
  //   return(

  //     <div>

  //       <p>xas</p>
  //     </div>
  //   )
  //   }
  // }

  toggle_newsLetter = () => {
    this.setState({
      newsLetter: !this.state.newsLetter
    });
  };

  renderModalNewsLetter() {
    if (this.state.newsLetter) {
      return (
        <MDBModal
          isOpen={this.state.newsLetter}
          toggle={this.toggle_newsLetter}
        >
          <MDBModalHeader toggle={this.toggle_newsLetter}></MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12">
                  <div className="mobile-footer-contact-us-form">
                    <p className="h5 text-center mb-4">
                      Stay current with our latest insights
                    </p>
                    <div className="grey-text d-flex flex-column align-items-start">
                      <MDBInput
                        label="Your email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        onChange={e =>
                          this.validateEmailInsight(e.target.value)
                        }
                        value={this.state.insight_mail}
                      />
                    </div>
                


                    <div className="text-center contact-us-sticky-footer-button">
                      <button
                   outline
                        onClick={() => this.addInsight()}
                      >
                        Submit{" "}
                        <MDBIcon far icon="paper-plane" className="ml-1" />
                      </button>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  addContact = async (e) => {
if (
      this.state.phone.length < 10 ||
      this.state.phone.length > 14
    ) {

         
      swal("Check Phone number!", {
        buttons: false,
        timer: 2000
      });
      
 
    }
    else if ( this.state.email.length === 0|| this.state.email.lenght<=6) {
 
      swal("Please enter valid Email Id!", {
        buttons: false,
        timer: 2000
      });
    }
    
    else {
 
        this.setState({
          loading: true
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message
        };

        console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://www.vimasmiham.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000"
            },
            body: formBody
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson);
              // window.location.reload();
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                  loading: false,
                  
                },
                
                () =>
                  swal("Success!", "We will contact you shortly!", "success").then(()=>this.toggle_contact())
              );
            })
            .catch(error => {
              this.setState({
                loading: false
              });

              console.log(error);
            })
        });
      
    }
  };

  toggle_contact = () => {
    this.setState({
      contact: !this.state.contact
    });
  };

  validateEmail = text => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  generateCaptch = () => 
 {
  var captcha;
 
    var a = Math.floor((Math.random() * 10));
    var b = Math.floor((Math.random() * 10));

	captcha=a.toString()+b.toString();
	
    document.getElementById("captcha").value = captcha;
}


check=() =>{
  var captcha;
 
	var input=document.getElementById("inputText").value;
 
	if(input==captcha){
		alert("Equal");
	}
	else{
		alert("Not Equal");
	}
}
  renderModal() {
    if (this.state.contact) {
      return (
        <MDBModal isOpen={this.state.contact} toggle={this.toggle_contact}>
          <MDBModalHeader toggle={this.toggle_contact} >
          <p className="h5 text-center">Get in touch</p>
          </MDBModalHeader>
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="6">
                  <div className="mobile-footer-contact-us-form">
                  
                    <div className="grey-text d-flex flex-column align-items-start">
                      <MDBInput
                        label="Your name"
                        icon="user"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                        value={this.state.name}
                        onChange={text =>
                          this.setState({ name: text.target.value })
                        }
                      />
                      <MDBInput
                        label="Your email"
                        icon="envelope"
                        group
                        type="email"
                        validate
                        error="wrong"
                        success="right"
                        value={this.state.email}
                        onChange={e => this.validateEmail(e.target.value)}
                      />
                      <MDBInput
                        label="Phone"
                        icon="phone-alt"
                        group
                        type="number"
                        validate
                        error="wrong"
                        success="right"
                        value={this.state.phone}
                        // maxlength="10" pattern="\d{10}"
                        onChange={text =>
                          this.setState({ phone: text.target.value })
                        }
                      />
                      <MDBInput
                        type="textarea"
                        rows="2"

                        label="Your message"
                        icon="pencil-alt"
                        value={this.state.message}
                        onChange={text =>
                          this.setState({ message: text.target.value })
                        }
                      />
                    </div>
                    
                   
                    <div className="text-center contact-us-sticky-footer-button">
                      <button
                        outline
                        onClick={(e) => this.addContact(e)}
                      >
                        Send <MDBIcon far icon="paper-plane" className="ml-1" />
                      </button>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  render() {
    return (
      <div>
        <StickyFooter
          bottomThreshold={50}
          normalStyles={{
            backgroundColor: "white"
          }}
          stickyStyles={{
            backgroundColor: "white",
            position: "fixed",
            bottom: "0px",
            width: "100%"
          }}
        >
          <MDBCard>
            <div className="d-flex mob-sticky-div">
              <div
                className="d-flex w-50 footer-icon-div"
                style={{ justifyContent: "center", backgroundColor: "#0093D9" }}
                onClick={() => this.props.history.push("/")}
              >
                <MDBIcon style={{ color: "white" }} icon="home" size="lg" />
              </div>

              <div
                className="d-flex w-50 footer-icon-div"
                style={{
                  justifyContent: "center",
                  backgroundColor:"#dbaa34",
                  
                  borderRadius: "0%"
                }}
                onClick={() => this.setState({ contact: true })}
              >
                <MDBIcon style={{ color: "white" }} size="lg" icon="envelope" />
              </div>

              <div
                className="d-flex w-50 footer-icon-div"
                style={{
                  justifyContent: "center",
                  backgroundColor: "#0093D9",
                  borderRadius: "0%"
                }}
                onClick={() => this.setState({ newsLetter: true })}
              >
                <MDBIcon
                  style={{ color: "white" }}
                  size="lg"
                  far
                  icon="newspaper"
                />
              </div>
            </div>
          </MDBCard>
        </StickyFooter>
        {this.renderModal()}
        {this.renderModalNewsLetter()}
        {/* {this.renderX()} */}
      </div>
    );
  }
}

export default StickyFooterPage;
