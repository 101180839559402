import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";
import Select from "react-select";
import {
  MDBIcon,
  MDBModal,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBModalTitle,
  MDBCol,
  MDBCarousel,
  MDBRow,
  MDBCarouselItem,
  MDBCarouselInner,
  MDBTooltip,
} from "mdbreact";
import Fade from "react-reveal";
import { CircularProgressbar } from "react-circular-progressbar";
import swal from "sweetalert";
import "../Lectures/Addlecture.css";
// import { MDBIcon } from "mdbreact";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import MediaComponent from "../MediaComponent/chooseMediaFile";
import { css } from "@emotion/core";
const axios = require("axios").default;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class AddLecture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "image",
      username: "",
      selectedType: "",
      testType: "",
      verticals: [],
      test_evaluations_completed: [],
      selectedVerticals: "",
      explainationSubjective: "",
      evaluationDetails: [],
      evaluations: [],
      evaluationsCompleted: [],
      inputLink: "",
      comments: "",
    };
  }
  componentDidMount() {
    console.log(this.state.value);
  }
  componentWillMount() {
    let evaluationDetails = JSON.parse(localStorage.getItem("user_evaluation"));
    localStorage.setItem("test_id", evaluationDetails.test_id);
    this.setState({ evaluationDetails });
    let test_questions = JSON.parse(evaluationDetails.test_questions);
    test_questions.forEach((element) => {
      element.explaination = null;
    });
    //   let testType=localStorage.getItem('test_type')
    // console.log(testType)
    this.setState({
      username: evaluationDetails.user_name,
      test_evaluations: JSON.parse(evaluationDetails.test_questions),
      testType: evaluationDetails.test_type,
    });
  }

  handlechangeurl = (text) => this.setState({ inputLink: text.target.value });

  handleExplanation(e, index, questionID) {
    console.log(questionID);
    let test_evaluations = this.state.test_evaluations;
    let tetsItem = test_evaluations[index];
    tetsItem.explanation = e.target.value;
    console.log(tetsItem);
    test_evaluations[index] = tetsItem;
    this.setState({ test_evaluations }, () =>
      console.log(this.state.test_evaluations, this.state.evaluationDetails)
    );
  }

  renderSubjectiveQuestionsTest() {
    console.log(this.state.test_evaluations);
    let c = 0;
    return this.state.test_evaluations.map((item, index) => {
      console.log(item.keywords);

      return (
        <>
          <div className="col-6">
            <p>
              <b>
                Question {++c} -{" "}
                <div
                  dangerouslySetInnerHTML={{ __html: item.question_title }}
                />
              </b>
            </p>
            <p>
              <b>Answer - </b>{" "}
              {item.type == "subjective" ? item.answer : item.answer.answer}
            </p>
            <br />
            {item.type == "subjective" && (
              <p className="d-flex justify-content-between">
                <span>
                  <b>Keywords - </b> {item.keywords}
                </span>{" "}
                <span style={{ marginLeft: "30px" }}></span>
              </p>
            )}
            <br />
            <br />
            <br />
          </div>
          <div className="col-6">
            <textarea
              id="form7"
              class="md-textarea form-control"
              rows="3"
              placeholder="Explanation here ..."
              value={item.explaination}
              onChange={(e) =>
                this.handleExplanation(e, index, item.question_id)
              }
            ></textarea>
          </div>
          <hr />
        </>
      );
    });
  }
  renderSelectedAnswer(item) {
    if (item.selected_answer == null) return "Not attempted";
    else return item.selected_answer.answer;
  }
  renderCorrectAnswer(item) {
    console.log(item);
    let findCorrectAnswer = JSON.parse(item.answers);
    // console.log(correctAnswer)
    let correctAnswer = findCorrectAnswer.filter((item) => item.value == 1);
    console.log(correctAnswer);
    return correctAnswer[0].answer;
  }

  renderObjectiveQuestionsTest() {
    let c = 0;
    let opt = ["a", "b", "c", "d"];
    return this.state.test_evaluations.map((item, index) => {
      let options = JSON.parse(item.answers);
      return (
        <>
          <div className="col-7">
            <p>
              <b>{++c}. Question - </b> {item.question_title}
            </p>
            <p>
              <b>Options - </b>{" "}
              {options.map((item, index) => (
                <p className="itemanswers">
                  {opt[index]}) {item.answer}
                </p>
              ))}
            </p>
            <span style={{ fontFamily: "OpenSans-B" }}>
              User Answer - {this.renderSelectedAnswer(item)}
            </span>
            <p style={{ fontFamily: "OpenSans-B" }}>
              Correct Answer - {this.renderCorrectAnswer(item)}
            </p>
            {/* <p>User Answer - {item.selected_answer.answer}</p> */}
            <br />
            {/* <p><b>Keywords - </b> {item.keywords}</p> <br/><br/><br/> */}
          </div>
          <div className="col-4">
            <textarea
              id="form7"
              class="md-textarea form-control"
              rows="3"
              placeholder="Explanation here ..."
              value={this.state.explaination}
              onChange={(e) => this.handleExplanation(e, index)}
            ></textarea>
          </div>
          <hr />
        </>
      );
    });
  }

  renderChooseFile = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          Enter file link:
        </label>

        <input
          autoFocus
          type="text"
          value={this.state.inputLink}
          onChange={(text) => this.handlechangeurl(text)}
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form "
        />
      </div>
    );
  };

  addExplaination = async () => {
    let regexOpen = /<p>/;
    let regexClose = /<\/p>/;
    let details = {
      explainations: JSON.stringify(
        this.state.test_evaluations.map((item) => ({
          question: item.question_title
            .replace(regexOpen, "")
            .replace(regexClose, ""),
          answer: item.type=="subjective" ? item.answer : item.answer.answer,
          explaination: item.explanation,
          question_type:item.type
        }))
      ),
      user_name: this.state.evaluationDetails.user_name,
      user_email: this.state.evaluationDetails.user_email,
      test_id: this.state.evaluationDetails.test_id,
      comments: this.state.comments,
      id: this.state.evaluationDetails.evaluation_id,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://vimasmiham.com/send_explaination", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            swal(
              "Evaluation Sent",
              "Your evaluation has been succesfully sent by email",
              "success"
            ).then(() => this.props.history.push("/3gLyL9z/evaluation"));
          } else {
            this.setState({
              loading: false,
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  //PART OF MEDIA COMPONENT

  render() {
    console.log(this.state.evaluationDetails, "state");
    return (
      <div id="add-subject-page">
        {/* <AdminNavbar {...this.props} /> */}

        <div className="d-flex">
          {/* <SidebarPage {...this.props} active={11} /> */}

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#c01825"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 top_button_bar d-flex align-items-center">
                    <button
                      className="admin-button-style admin-button-style-margin"
                      onClick={() =>
                        this.props.history.push("/3gLyL9z/evaluation")
                      }
                    >
                      All Evaluations
                    </button>

                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.addExplaination()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h4 style={{ fontFamily: "OpenSans-R" }}>
                      {" "}
                      Evaluation for{" "}
                      <span style={{ fontFamily: "OpenSans-R" }}>
                        {this.state.username}
                      </span>
                    </h4>
                  </div>
                </div>

                {/* <div className="subject-content-container ml-4 fit-content">
                      <div className="row px-0 mx-0">rv
                      </div>
                    </div> */}
                <div className="row align-items-start px-0 mx-5 my-5">
                  {this.renderSubjectiveQuestionsTest()}
                  <div className="col-12">
                    <label htmlFor="comments">Comments:</label>
                    <textarea
                      value={this.state.comments}
                      onChange={(e) =>
                        this.setState({ comments: e.target.value })
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
