import React, { Component } from "react";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { Link } from "react-router-dom";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import Slider from "react-slick";

import MetaTags from "react-meta-tags";
export default class Exams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      all_subjects: [],
      sortedSubjects: [],
      cart: [],
      order_items: [],
      purchased_exams: [],
      testsArray: [],
    };
  }
  componentDidMount() {
    console.log(this.props.match.params.examurl);
    this.getSubjects();
  }

  componentWillReceiveProps(props) {
    if (this.props.match.params.examurl !== props.match.params.examurl) {
      this.setState({ loading: true }, () =>
        this.getSubjectsOfExams(props.match.params.examurl)
      );
    }
  }

  next() {
    this.slider.slickNext();
  }

  prev() {
    this.slider.slickPrev();
  }

  getCart = (params) => {
    console.log("get cart");

    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "cart");

          this.setState(
            {
              cart: responseJson,
            },
            () => this.getSubjectsOfExams(params)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  renderExamsBanner() {
    return (
      <>
        <div className="w-10 d-flex justify-content-centUpcoming Examser News-heading actsRegulation caseLawsSearch">
          <h1 className="exam-news-heading-font text-center w-100">
            {this.state.exam.exam_name}
          </h1>
        </div>

        <div>{this.renderExamNavigation()}</div>
      </>
    );
  }

  renderExamNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 8%" }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / {this.state.exam.exam_name}
        </p>
      </div>
    );
  }

  startTest(item) {
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem("selected_test", item.subject_id);
    this.props.history.push("/start-test");
  }

  getUserDetails(params) {
    console.log("get user details");
    this.setState({ loading: true });
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart(params)
      );
    } else {
      this.setState({ userdata: null }, () => this.getSubjectsOfExams(params));
    }
  }
  getTests() {
    console.log("get tests");

    fetch("https://www.vimasmiham.com/get_tests", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (this.state.userdata == null) {
          this.setState({
            testsArray: responseJson,
            loading: false,
          });
        } else {
          this.setState(
            {
              testsArray: responseJson,
            },
            () => this.getOrders()
          );
        }
      });
  }

  removeDuplicates(originalArray, objKey) {
    console.log("removing duplicates");

    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    let order_items = trimmedArray.filter((item) => item.test_included === 1);
    this.setState({
      order_items,
      loading: false,
    });
  }

  handleTestPurchase(item) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 1,
            subject_included: 0,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  trigger() {
    this.getNewCart();
    setTimeout(() => {
      this.setState({
        showAdded: false,
      });
    }, 2000);
  }

  getNewCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //   console.log(responseJson,'cart')

          this.setState({
            cart: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  renderTestsList() {
    return this.state.testsArray.map((item) => {
      console.log(item, "testsArrayy");
      var orderItem = this.state.order_items.find(
        (element) => element.subject_id == item.subject_id
      );
      //IF NOT LOGGED IN
      if (orderItem === undefined) {
        return (
          <div
            className="row align-items-center  py-4 px-3 test-series-item my-2 "
            key={item.test_id}
          >
            <div className="test-name">{item.test_name}</div>
            <div className="test-detail text-right d-flex flex-column align-items-center">
              <p style={{ marginBottom: 0 }}>
                Test Duration: {item.duration} minutes
              </p>

              <span className="mb-1">Rs.{item.cost}/-</span>
              <button
                className="start-learning btn-custom text-white"
                onClick={() => this.handleTestPurchase(item)}
              >
                Buy now
              </button>
              <p className="text-muted text-center test-pc-or-desktop d-none">
                <i>Tests can only be attempted on a PC or Laptop.</i>
              </p>
            </div>
          </div>
        );
      }

      //IF LOGGED IN
      else {
        if (orderItem.test_included != 1) {
          return (
            <div
              className="row align-items-center py-4 px-3 test-series-item my-2 "
              key={item.test_id}
            >
              <div className="test-name">{item.test_name}</div>
              <div className="test-detail text-right d-flex flex-column align-items-center">
                <p style={{ marginBottom: 0 }}>
                  Test Duration: {item.duration} minutes
                </p>
                <span className="mb-1">Rs.{item.cost}/-</span>
                <button
                  className="start-learning btn-custom text-white"
                  onClick={() => this.handleTestPurchase(item)}
                >
                  Buy now
                </button>
                <p className="text-muted text-center d-none">
                  <i>Tests can only be attempted on a PC or Laptop.</i>
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div
              className="row align-items-center py-4 px-3 test-series-item my-2 "
              key={item.test_id}
            >
              <div className="test-name">{item.test_name}</div>
              <div className="test-detail text-right d-flex flex-column align-items-center">
                <span className="mb-1 text-muted">
                  <i>Item purchased</i>
                </span>
                <button
                  className="start-learning start-test-button-testseries btn-custom text-white"
                  onClick={() => this.startTest(item)}
                >
                  Start Test
                </button>
                <p className="text-muted text-center d-none">
                  <i>Tests can only be attempted on a PC or Laptop.</i>
                </p>
              </div>
            </div>
          );
        }
      }
    });
  }

  checkDuplicates(originalArray) {
    console.log("checking duplicates");

    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              //   console.log(originalArray[i],originalArray[i1],'iii')
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    console.log(all_courses, "iii");

    // }
    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);

          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  getOrders = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var items = [];
          var exam_items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              if (data.exam_included == false) {
                items.push(data);
              }
              if (data.exam_included == true) {
                exam_items.push(data);
              }
            }
          }

          this.setState({ purchased_exams: exam_items });
          console.log(items, "orig");
          this.checkDuplicates(items);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      />
    );
  };

  renderSubjectSlide() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 2,
      vertical: true,
      autoplay: true,
      slidesToScroll: 1,
      nextArrow: <this.SampleNextArrow ref={this.subjectSlider} />,
      prevArrow: <this.SamplePrevArrow ref={this.subjectSlider} />,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: true,
            arrows:true,
            vertical:false
          }
        },
        {
          breakpoint: 565,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: true,
            arrows:true,
            vertical:false
          }
        },
      ]
    };

    return (
      <React.Fragment>
        <Slider
          ref={(subjectSlider) => (this.subjectSlider = subjectSlider)}
          {...settings}
        >
          {this.renderSubjectData()}
        </Slider>
      </React.Fragment>
    );
  }

  renderTestSlide() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 1,
      vertical: true,
      autoplay: true,
      slidesToScroll: 1,
      nextArrow: <this.SampleNextArrow ref={this.testSlider} />,
      prevArrow: <this.SamplePrevArrow ref={this.testSlider} />,
      responsive: [
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: true,
            arrows:true,
            vertical:false
          }
        },
        {
          breakpoint: 565,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: true,
            arrows:true,
            vertical:false
          }
        },
      ]
    };

    return (
      <React.Fragment>
        <Slider
          ref={(testSlider) => (this.testSlider = testSlider)}
          {...settings}
        >
          {this.renderTestsList()}
        </Slider>
      </React.Fragment>
    );
  }

  getSubjects = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "subjects");

          this.setState(
            {
              loading: true,
              all_subjects: responseJson,
            },
            () => {
              let params = this.props.match.params.examurl;
              this.getUserDetails(params);
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  getSubjectsOfExams = (params) => {
    let details = {
      params: params,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subject_of_exams", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.status == 200) {
            let subjects = [];
            console.log(responseJson, "examss1");
            let exam = responseJson.exam;
            //   let exam = this.state.exam;
            let allSubjects = this.state.all_subjects;
            for (let i = 0; i < allSubjects.length; i++) {
              for (
                let j = 0;
                j < JSON.parse(allSubjects[i].exams).length;
                j++
              ) {
                if (
                  JSON.parse(allSubjects[i].exams)[j].exam_id == exam.exam_id
                ) {
                  subjects.push(allSubjects[i]);
                }
              }
            }
            this.setState(
              {
                sortedSubjects: subjects,
                exam: responseJson.exam,
              },
              () => this.getTests(params)
            );
            console.log(subjects, "subjectssort");
          } else {
            this.props.history.push("/error");

            // this.props.history.push("/error");
          }
        })
        .catch((error) => {
          // throw error;
          this.props.history.push("/error");
          console.log(error);
        }),
    });
  };

  renderSubjectData = () => {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;
    return this.state.sortedSubjects.map((item) => {
      return (
        // <div className="col-12 col-md-6 col-lg-3">
        <div style={{ padding: "2%" }} className="cardMainDiv exams-page-subject-item">
          <Link to={`/courses/${item.url}`}>
            <Card
              // onClick={() => this.selectSubject(item, index)}
              className="dashCardW1"
            >
              <CardImg className="img-fluid courseCardImg" src={item.image} />
              <CardBody className="licCardBodyH">
                <CardTitle className="CardMainTitleDiv">
                  <p className="dashCardTitleFont">{item.subject_name}</p>
                  <p className="dashTextFont" style={{ marginBottom: 0 }}></p>
                </CardTitle>
                <CardText>
                  <div className="licCardTopM">
                    <div className="dashboard-rating-container opacity-0"></div>
                    <div className="licCardFlex-end">
                      <text className="fullprice oldPriceFont">
                        &#8377; {item.old_cost}
                      </text>
                      <text
                        className="newPriceFont"
                        style={{ marginLeft: 0, color: "black" }}
                      >
                        &#8377; {item.cost}
                      </text>
                    </div>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Link>
        </div>
        // </div>
      );
    });
  };

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div>
          <NewHeader
            // cart={
            //   this.state.cart.length == 0
            //     ? 0
            //     : JSON.parse(this.state.cart[0].items).length
            // }
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
            showLogin={this.state.loginToggle}
          />

          {this.renderExamsBanner()}
          <MetaTags>
            <title>{this.state.exam.seo_title}</title>

            <meta
              name="description"
              content={this.state.exam.seo_description}
            />
          </MetaTags>
          <div className="dashboard-main-body">
            <div className="container-c my-5">
              <div className="row">
                <div className="col-12 col-lg-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.state.exam.content,
                    }}
                    className="exams-content"
                  ></div>
                </div>
                <div className="col-12 col-lg-4 exams-vertical-slide">
                  {this.state.sortedSubjects.length > 0
                    ? this.renderSubjectSlide()
                    : ""}
                   <hr className="slider-divider" />
                  {this.state.testsArray.length > 0 ? (
                    <div
                      className="tests-vertical"
                      style={{ marginTop: "4rem" }}
                    >
                      {this.renderTestSlide()}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <br />
              <br />
            </div>
          </div>
          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="mobView">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
