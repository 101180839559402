import React from "react";
import "./feedback.css";
import "../Evaluation/evaluation.css";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import { FulfillingBouncingCircleSpinner } from "react-epic-spinners";
import { MDBIcon } from "mdbreact";
import { FormGroup, Label, Input } from "reactstrap";

import swal from "sweetalert";

class TestFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_ques: 0,
      selected_time: 0,
      selected_lang: 0,
      id1: 1,
      id2: 2,
      id3: 3,
      suggestion: "",
      right: 0,
      wrong: 0,
    };
  }

  componentWillMount() {
    this.setState({
      questions: JSON.parse(window.localStorage.getItem("test_questions")),
      test_id: JSON.parse(window.localStorage.getItem("test_id")),
    });
  }

  componentDidMount() {
    //  const right=this.props.history.location.state.correct
    //  const wrong=this.props.history.location.state.wrong
    //  const skipped = this.props.history.location.state.size - right - wrong
    //   this.setState({right:right,wrong:wrong, skipped:skipped})
    //  console.log(right,wrong,'sfddddddddddddddddddddd')

    let right = 0;
    this.state.questions.forEach((item) => {
      if (item.selected_answer) {
        if (item.selected_answer.value == 1) {
          right = right + 1;
        }
      }
    });

    this.setState({ right });
  }

  addReview() {
    let details = {
      // product_id:this.state.selected_products.product_id,

      test_id: this.state.test_id,
      difficulty_rating: this.state.selected_ques,
      timing_rating: this.state.selected_time,
      language_rating: this.state.selected_lang,
      other_suggestions: this.state.suggestion,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/add_test_review", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            swal(
              "Review submitted!",
              "Thank you for your feedback",
              "success"
            ).then(() => this.props.history.push("/start-test"));
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  getClients = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_clients", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "clients");
          this.setState({ clients: responseJson, loading: false });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  renderEmojis(id) {
    if (id == 1) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_ques == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_ques: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_ques == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_ques: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_ques == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_ques: 3 })}
          />
        </div>
      );
    } else if (id == 2) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_time == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_time: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_time == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_time: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_time == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_time: 3 })}
          />
        </div>
      );
    } else if (id == 3) {
      return (
        <div className="feedEmojiFont">
          <MDBIcon
            far
            icon="smile"
            style={{
              color: this.state.selected_lang == 1 ? "green" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_lang: 1 })}
          />
          <MDBIcon
            far
            icon="meh"
            style={{
              color: this.state.selected_lang == 2 ? "purple" : "#d2d2d2",
            }}
            onClick={() => this.setState({ selected_lang: 2 })}
          />
          <MDBIcon
            far
            icon="frown"
            style={{ color: this.state.selected_lang == 3 ? "red" : "#d2d2d2" }}
            onClick={() => this.setState({ selected_lang: 3 })}
          />
        </div>
      );
    }
  }

  renderSkip_SubmitButton() {
    return (
      <div className="feedBtnMainDiv">
        <div
          className="skipBtnDiv"
          onClick={() => this.props.history.push("/start-test")}
          style={{ cursor: "pointer" }}
        >
          Skip
        </div>
        {this.renderSubmitButton()}
      </div>
    );
  }

  renderSubmitButton() {
    return (
      <div
        className="saveBtnDiv"
        style={{ cursor: "pointer" }}
        onClick={() => this.addReview()}
      >
        Submit
      </div>
    );
  }

  renderAnswers(question) {
    console.log(question);
    let answers = JSON.parse(question.answers);
    let selected_answer = question.selected_answer;
    console.log(selected_answer);

    if (selected_answer == null) {
      return answers.map((item) => {
        if (item.answer != null)
          return (
            <li
              className={
                item.value == 1
                  ? "p-1 mt-2 ml-5 bg-green w-50 w-lg-25"
                  : "p-1 mt-2 ml-5 w-50 w-lg-25"
              }
              key={item.id}
            >
              {item.answer}
            </li>
          );
      });
    } else {
      return answers.map((item) => {
        if (item.answer != null) {
          if (selected_answer.value == 0 && selected_answer.id == item.id) {
            return (
              <li className="p-1 mt-2 ml-5 bg-red w-50 w-lg-25" key={item.id}>
                {item.answer}
              </li>
            );
          } else if (
            selected_answer.value == 1 &&
            selected_answer.id == item.id
          ) {
            return (
              <li className="p-1 mt-2 ml-5 bg-green w-50 w-lg-25" key={item.id}>
                {item.answer}
              </li>
            );
          } else {
            return (
              <li
                className={
                  item.value == 1
                    ? "p-1 mt-2 ml-5 bg-green w-50 w-lg-25"
                    : "p-1 mt-2 ml-5 w-50 w-lg-25"
                }
                key={item.id}
              >
                {item.answer}
              </li>
            );
          }
        }
      });
    }
  }

  renderStatus(question) {
    if (question.selected_answer == null)
      return <i className="ml-4"> &#91;unattempted &#93;</i>;
  }

  renderQuestionsAndAnswers() {
    return this.state.questions.map((item, index) => {
      return (
        <div className="question-container">
          <ul>
            {`${index + 1}. ${item.question_title}`}
            <br />
            {this.renderStatus(item)}
            {this.renderAnswers(item)}
          </ul>
        </div>
      );
    });
  }

  renderTestScore() {
    return (
      <div className="answer_output">
        <h2 className="quiz_answer_Result_font">Result</h2>
        <hr style={{ color: "#fff !important" }}></hr>
        <span className="quiz_answer_font">
          {" "}
          {this.state.right} / {this.state.questions.length}
        </span>
      </div>
    );
  }

  render() {
    console.log(this.state, "satet");
    if (this.state.loading) {
      return (
        <div className="loadingSpin">
          <FulfillingBouncingCircleSpinner color="#FF6600" />
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="windowView">
            <NewHeader {...this.props} active="My Dashboard" />
          </div>

          <div style={{ width: "100%" }}>
            <div className="test-questions-with-answers-container p-2 p-lg-5">
              {this.renderTestScore()}
              <hr />
              {this.renderQuestionsAndAnswers()}
            </div>

            <div className="testTimerMainDiv windowView">
              <p className="testMainHeadingFont">
                Provide your valuable feedback for the quiz:
              </p>
            </div>
            <div className="marginLeftFeedMainDiv">
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Questions's difficulty:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id1)}
                </div>
              </div>
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Quiz Timings:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id2)}
                </div>
              </div>
              <div className="feedbackFlex">
                <p className="testMainHeadingFont feedbackHeadingW">
                  Questions's Language:
                </p>
                <div className="feedEmojiDiv">
                  {this.renderEmojis(this.state.id3)}
                </div>
              </div>

              <div className="d-flex">
                <FormGroup className="locationForm">
                  <Input
                    type="textarea"
                    name="city"
                    id="exampleCity"
                    placeholder="Any other suggestions..."
                    value={this.state.suggestion}
                    style={{ width: "400px", marginTop: "20px" }}
                    onChange={(text) =>
                      this.setState({ suggestion: text.target.value })
                    }
                    className="textFontSize"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
          <div className="feedbackSubmitBtnDiv">
            {this.renderSkip_SubmitButton()}
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="vsMobView" style={{ height: "auto" }}></div>
        </div>
      );
    }
  }
}

export default TestFeedback;
