import React, { Component } from "react";
import "../tax/allTax.css";
import SidebarPage from "../Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import swal from "sweetalert";
import { css } from "@emotion/core";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import * as moment from 'moment'
import GridLoader from "react-spinners/GridLoader";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

class Evaluation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      subjects: [],
      showAction: false,
      evaluations: [],
      draft_count: 0,
      published_count: 0,
      trash_count: 0,
      all_selected: true,
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: [],
    };
  }
  componentWillMount() {
    this.setState({ loading: true }, () => this.getEvaluation());
  }

  componentDidMount() {
    //   window.localStorage.removeItem('subject_item');
  }

  getEvaluation = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://vimasmiham.com/get_evaluation", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");

          this.setState(
            {
              evaluations: responseJson,
              loading: false,
            },
            () => console.log(this.state.evaluations)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  //   getSubjects=async()=>{

  //     this.setState({
  //         loading:true
  //       })
  //           this.setState({
  //           response: fetch('https://10x10learning.com/get_tax', {
  //           method: 'GET',
  //           }).then((response) => response.json())
  //           .then((responseJson)=>{
  //               console.log(responseJson,'xxx')
  //             var draft_count=0,published_count=0,trash_count=0
  //               for(var v=0;v<responseJson.length;v++){

  //                   responseJson[v].selected = 0
  //                   if(responseJson[v].status==0){
  //                     draft_count=draft_count+1
  //                   }
  //                   else if(responseJson[v].status==1){
  //                     published_count=published_count+1
  //                                       }
  //                   else if(responseJson[v].status==2){
  //                     trash_count=trash_count+1
  //                        }
  //                   }
  //             this.setState({
  //                 subjects:responseJson,
  //                 draft_count:draft_count,
  //                 published_count:published_count,
  //                 trash_count:trash_count,
  //                 loading:false
  //                 })
  //                }
  //           )
  //           .catch((error) => {
  //           this.setState({
  //           loading:false
  //           })
  //           swal("Warning!", "Check your network!", "warning");
  //         console.log(error)
  //               })
  //           })
  //     }

  //     handleEditSubject(item){
  //         window.localStorage.setItem('subject_item', JSON.stringify(item))
  //         window.localStorage.setItem('subject_list',JSON.stringify(this.state.subjects))
  //         this.props.history.push('/limitedaccess/edit-tax');
  //      }

  //      handleAddNewSubject(){
  //       window.localStorage.setItem('subject_list',JSON.stringify(this.state.subjects))
  //       this.props.history.push('/limitedaccess/add-tax');
  //    }

  //   renderActions(item,index){
  //       if(item.selected==1){
  //           if(this.state.trash_selected){
  //             return( <div style={{width:'15%'}} className='actions_div'> <span onClick={()=>this.restoreAlert(item)} className='actionstext'>Restore</span>
  //              <span onClick={()=>this.deleteAlert(item)} className='actionstext' style={{color:'#a00'}}>Delete</span>
  //             </div>)
  //           }
  //           else{
  //             return( <div  className='actions_div'><span onClick={()=>this.handleEditSubject(item,index)}
  //             className='actionstext'>Edit</span>
  //             </div>)
  //           }
  //       }
  //       else{
  //         return( <div className='actions_div' style={{visibility:'hidden'}}><span className='actionstext'>Edit</span> </div>)
  //       }
  //   }
  //   showactionbutton(item,index){
  //       let {subjects} = this.state
  //       let selected_page = subjects[index]
  //       for(var v=0;v<subjects.length;v++){
  //         subjects[v].selected=0
  //       }
  //       selected_page.selected=1
  //       this.setState({
  //         subjects
  //       })
  //   }
  //   hideactionbutton(){
  //     let {subjects} = this.state

  //     for(var v=0;v<subjects.length;v++){
  //         subjects[v].selected=0
  //     }

  //     this.setState({
  //         subjects
  //     })
  //   }
  //   renderStatus(item){
  //       if(item.status==0){
  //           return 'Last Modified'
  //       }
  //       else{
  //           return 'Published'
  //       }
  //   }

  changeStatus(item, status) {
    this.setState({
      loading: true,
    });
    let details = {
      tax_id: item.tax_id,
      status: status,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://10x10learning.com/tax_status", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  evaluateUser = (item) => {
    console.log(item);
    localStorage.setItem("user_evaluation", JSON.stringify(item));
    this.props.history.push(`/3gLyL9z/evaluation/${item.evaluation_id}`);
  };

  renderTableData(item, index) {
    if (this.state.all_selected == true) {
      if (item.status != 2) {
        // console.log(this.state.all_selected,item.status,'bigE')
        return (
          <tr key={item.evaluation_id}>
            {/* <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div className="name_div_table">
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.user_name}
                </span>
                {/* {this.renderActions(item,index)} */}
              </div>
            </td>
            <td>{item.test_name}</td>
            <td>{moment(item.creation_time).format('DD/MM/YY')}</td>
            <td className="evaluate">
              {" "}
              <span
                style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                onClick={() => this.evaluateUser(item)}
              >
                Click to Evaluate
              </span>
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.published_selected == true) {
      if (item.status == 1) {
        return (
          <tr key={item.evaluation_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div className="name_div_table">
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.user_name}
                </span>
                {/* {this.renderActions(item,index)} */}
              </div>
            </td>
            <td>
              {" "}
              <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                {}
              </span>{" "}
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.draft_selected == true) {
      if (item.status == 0) {
        return (
          <tr key={item.evaluation_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div className="name_div_table">
                <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                  {item.user_name}
                </span>
                {/* {this.renderActions(item,index)} */}
              </div>
            </td>
            <td>
              {" "}
              <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
                {}
              </span>{" "}
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    }
    // else if(this.state.trash_selected==true){
    // if( item.status==2){
    //   return (         <tr key={item.tax_id}>
    //       <td><input className='checkbox_content' type='checkbox' /></td>
    //       <td style={{width:'25%'}} className="tdName textFontSize" >
    //       <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()}
    //       className='name_div_table'>
    //       <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_name}</span>
    //       {this.renderActions(item,index)}
    //       </div></td>
    //           <td className="datefontsize">{this.renderStatus(item)} <br />
    //           <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_value}</span></td>     </tr>)
    //                       }
    //             }
  }

  //   onToggle(index, e){
  //     console.log(index,'kjkk')
  //   let {subjects,selected_data} = this.state
  //   let selected_item=subjects[index]
  //   if(selected_item.checked==1){
  //    selected_item.checked=0
  //    selected_data.splice(index,1)
  //   }
  //   else{
  //     selected_item.checked=1
  //   selected_data.push(selected_item)
  //   }

  //   this.setState({selected_data})
  //   this.setState({subjects})

  //   }

  // handleAllChecked = (event) => {
  //   let {subjects} = this.state
  // if(this.state.item_selected){
  //   for(var v=0;v<subjects.length;v++){
  //     subjects[v].checked=1
  //   }
  //   this.setState({selected_data:subjects})
  // }
  // else{
  //   for(var v=0;v<subjects.length;v++){
  //     subjects[v].checked=0
  //   }
  //   this.setState({selected_data:[]})
  // }
  //   this.setState({subjects})

  // }

  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          
        </div>
      );
    } else {
      return (
        <div id="subject-page-list">
          {/* <AdminNavbar {...this.props} /> */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <SidebarPage {...this.props} active={6} /> */}

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0, marginRight: "5px" }}>
                  All Evaluations
                </h3>
                {/* <span onClick={()=>this.handleAddNewSubject()} className='add_new_button'>Add New</span> */}
              </div>
              <div>
    

                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    {/* <th><input type="checkbox" onClick={(event)=> this.setState({item_selected:!this.state.item_selected},()=>this.handleAllChecked(event))}  checked={this.state.item_selected}  value="checkedall" /></th> */}
                    <th className="">User Name</th>
                    <th className="">Test Name</th>
                    <th className="">Date</th>
                    <th className="textFontSize">Evaluations</th>
                    {/* <th>Status</th> */}
                  </tr>

                  <MDBTableBody>
                    {this.state.evaluations.map((item, index) => {
                      return this.renderTableData(item, index);
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Evaluation;
