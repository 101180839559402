import React, { Component, Fragment, Suspense } from "react";
import "../../index.css";
import "./homepage.css";
import MetaTags from "react-meta-tags";
import { Carousel } from "react-responsive-carousel";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "react-loader-spinner";
import Fade from "react-reveal/Fade";

import Header from "../HomeHeader/Header";

import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import { css } from "@emotion/core";

import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import GridLoader from "react-spinners/GridLoader";
import Searchbar from "../../Reusable/Searchbar/Searchbar";
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBContainer,
  MDBBtn,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import { ModalBody } from "reactstrap";

const InlighterVein = React.lazy(() =>
  import("../HomepageComponents/InlighterVein/InlighterVein")
);

// const Downloads = React.lazy(() =>
//   import("../HomepageComponents/Downloads/Downloads")
// );

const InsuranceIndustry = React.lazy(() =>
  import("../HomepageComponents/InsuranceIndustry/InsuranceIndustry")
);

const Faqs = React.lazy(() => import("../HomepageComponents/FAQs/Faqs"));

const OurFaculty = React.lazy(() =>
  import("../HomepageComponents/OurFaculty/OurFaculty")
);

const AboutUs = React.lazy(() =>
  import("../HomepageComponents/AboutUs/AboutUs")
);

const Testimonials = React.lazy(() =>
  import("../HomepageComponents/Testimonials/Testimonials")
);

const UpcomingExams = React.lazy(() =>
  import("../HomepageComponents/UpcomingExams/UpcomingExams")
);

const FunFact = React.lazy(() =>
  import("../HomepageComponents/FunFact/FunFact")
);

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      search_array: [],
      value: "",
      showBanner: false,
      aboutUsLoading: true,
      //BANNER STATE
      bannerHeading: `Vima Smiham`,
      bannerHeadingHighlighted: ``,
      faculties: [],
      bannerSubheading: `One stop learning portal`,
      bannerSubheadingHihglighted: `to make your mark`,
      bannerContent: `in the `,
      description: "",
      headerSubjects: null,

      componentMounting: true,
      //ABOUT US STATE
      mainTitle: "",
      mainImage: "",
      mainText: "",
      objectiveImage: "",
      objectiveText: "",
      objectiveTitle: "",
      visionImage: "",
      visionTitle: "",
      visionText: "",
      missionImage: "",
      missionText: "",
      missionTitle: "",
      statistics: {},
      //WHY US STATE

      showItems: 3,

      //testimonial

      //FAQ STATE

      //DOWNLOAD STATE

      //INLIGHTER VEIN STATE

      verticals: [],
      cart: [],
      examsMenu: [],
      subjects: [],
      verticalsMenu: [],
      news: [],
      bannerLoading: true,
    };

    fetch("https://www.vimasmiham.com/get_homepage_seo", {
      metho: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          page_title_seo: responseJson[0].page_title_seo,
          page_description_seo: responseJson[0].page_description_seo,
        });
      });
  }

  componentWillMount() {
    this.getDetails();
  }

  componentDidMount() {
    this.setState({ componentMounting: false });
    this.getBanner();
    this.getVerticals();
    this.getStatistics();
  }
  //CACHE LOADING FUNCTIONS

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    }
  }

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            cart: responseJson,
            //loading:false
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getSubjects = () => {
    // this.setState({
    //   loading:true
    // })
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].data_type = "subject";
          }

          this.setState(
            {
              subjects: responseJson.concat(
                this.state.verticals,
                this.state.examsMenu
              ),
              headerSubjects: responseJson,
            },
            () => this.getTopics()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getBanner() {
    fetch("https://www.vimasmiham.com/get_banner_client")
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        console.log(window.localStorage.getItem("banner_read"));
        const banner_read = window.localStorage.getItem("banner_read");
        let showBanner = false;
        if (banner_read) {
          showBanner =
            JSON.parse(banner_read).banner_read == false
              ? true
              : new Date().getTime() -
                  new Date(JSON.parse(banner_read).time).getTime() >=
                60 * 60 * 1000 * 6
              ? true
              : false;
        } else {
          showBanner = true;
        }
        this.setState(
          {
            bannerDetails: responseJson,
            showBanner,
          },
          () => console.log(this.state, "banner state")
        );
      });
  }
  getStatistics() {
    fetch("https://vimasmiham.com/get_statistics")
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("statistics", responseJson);

        this.setState(
          {
            statistics: responseJson.statistic,
          },
          () => console.log(this.state, "statistics")
        );
      });
  }

  getTopics = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_topics", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].data_type = "topic";
          }
          this.setState({
            search_array: responseJson.concat(this.state.subjects),
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  compare = (a, b) => {
    if (a.sort_order < b.sort_order) {
      return -1;
    }
    if (a.sort_order > b.sort_order) {
      return 1;
    }
    return 0;
  };

  getVerticals = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].data_type = "vertical";
          }

          this.setState(
            {
              verticals: responseJson,
              verticalsMenu: responseJson,
              aboutUsLoading: false,
            },
            () => this.getOfflineExams()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getOfflineExams() {
    var examsMenu = JSON.parse(window.localStorage.getItem("examsMenu"));
    if (examsMenu != null) {
      console.log("menu from cache", examsMenu);
      this.setState({
        examsMenu,
      });
    } else {
      // this.setState({loading:true})
    }
    this.getExams();
  }

  getExams = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          responseJson = responseJson.reverse();
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].data_type = "exam";
          }

          window.localStorage.removeItem("examsMenu");
          window.localStorage.setItem(
            "examsMenu",
            JSON.stringify(responseJson)
          );
          console.log("menu from api", responseJson);
          this.setState(
            {
              examsMenu: responseJson,
            },
            () => this.getSubjects()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          console.log(error);
        }),
    });
  };

  selectVertical(item) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    window.localStorage.setItem("collapse", 0);
    this.props.history.push("/dashboard");
  }

  renderModals() {
    if (this.state.aboutUsLoading) {
      return (
        <Loader
          type="Bars"
          color="rgb(0, 147, 217)"
          height={80}
          width={80}
          timeout={200000} //3 secs
          className="svg-component"
        />
      );
    } else {
      return this.state.verticals.sort(this.compare).map((modal, index) => {
        console.log(modal);
        if (modal.status == 1) {
          return (
            <div
              style={{ cursor: "pointer" }}
              // onClick={() => this.selectVertical(modal, index)}
              className="box-container mx-2 text-center"
              key={modal.vertical_id}
            >
              <Link to={`/categories/${modal.url}`}>
                <div className="box-content">
                  <img
                    className="img-fluid box-icon"
                    src={modal.pictogram}
                    alt={modal.modalText}
                    width={120}
                    style={{ height: "120px" }}
                  />
                  <p
                    className="box-text"
                    style={{ textTransform: "capitalize" }}
                  >
                    {modal.vertical_name}
                  </p>
                  <span className="box-text1">
                    {modal.mark_coming_soon == 1 ? <>Coming soon...</> : ""}
                  </span>
                </div>
              </Link>
            </div>
          );
        }
      });
    }
  }

  fun() {
    this.props.history.push("/upcoming-exams");
  }

  selectSuggestion(item) {
    console.log(this.state.value);
    if (item.data_type == "vertical") {
      this.selectVertical(item);
    } else {
      window.localStorage.removeItem("searched_data");

      window.localStorage.setItem(
        "searched_data",
        JSON.stringify(this.state.suggestions)
      );
      window.localStorage.removeItem("searched_string");

      window.localStorage.setItem("searched_string", this.state.value);
      this.props.history.push("/search-results");
    }
  }
  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState(
      {
        suggestions: this.getSuggestions(value),
      },
      () => console.log(this.state.suggestions, "onSuggestionsFetchRequested")
    );
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  getSuggestions = (value) => {
    const escapedValue = this.escapeRegexCharacters(value.trim());

    if (escapedValue === "") {
      return [];
    }

    const regex = new RegExp(escapedValue, "i");

    return this.state.search_array.filter((text) =>
      regex.test(
        text.subject_name ||
          text.vertical_name ||
          text.topic_name ||
          text.exam_name
      )
    );
  };

  renderSuggestion = (suggestion, { query }) => {
    const matches = AutosuggestHighlightMatch(
      suggestion.subject_name ||
        suggestion.vertical_name ||
        suggestion.topic_name ||
        suggestion.exam_name,
      query
    );
    const parts = AutosuggestHighlightParse(
      suggestion.subject_name ||
        suggestion.vertical_name ||
        suggestion.topic_name ||
        suggestion.exam_name,
      matches
    );

    return (
      <span>
        {parts.map((part, index) => {
          console.log(part);
          const className = part.highlight
            ? "react-autosuggest__suggestion-match"
            : null;

          return (
            <span key={index} onClick={() => this.selectSuggestion(suggestion)}>
              {part.text}
            </span>
          );
        })}
      </span>
    );
  };

  shouldRenderSuggestions(value) {
    return value.trim().length > 1;
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  getSuggestionValue = (suggestion) => {
    return (
      <span onClick={() => this.selectSuggestion(suggestion)}>
        {suggestion.subject_name}
      </span>
    );
  };

  renderAboutUs() {
    return (
      <div className="about-us-container">
        <div className="boxmodals-container">
          <div className="row justify-content-center">
            {this.renderModals()}
            {/*         
       <div className="box-container col-12 col-sm-6 col-lg-3 text-center d-none" >
          <div className="box-content col ">
            <img className="img-fluid box-icon" src={ProfExam} alt='picto' />
            <p className="box-text">Other Exams</p>
          </div>
       </div> */}
          </div>
        </div>
        <Suspense
          fallback={
            <Loader
              type="Bars"
              color="#87A1E8"
              height={20}
              width={20}
              timeout={200000} //3 secs
              className="svg-component"
            />
          }
        >
          <AboutUs {...this.props} />
        </Suspense>
      </div>
    );
  }

  renderHeader() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <Loader
          type="Bars"
          color="rgb(0, 147, 217)"
          height={30}
          width={30}
          timeout={200000} //3 secs
          className="svg-component-header text-center py-5"
        />
      );
    } else {
      return (
        <Header
          {...this.props}
          verticalsMenu={this.state.verticalsMenu}
          examsMenu={this.state.examsMenu}
          headerSubjects={this.state.headerSubjects}
          userdata={this.state.userdata}
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
        />
      );
    }
  }

  handleBannerClick = () => {
    window.localStorage.setItem(
      "banner_read",
      JSON.stringify({ banner_read: true, time: new Date() })
    );
    this.setState({ showBanner: false });
  };

  renderBanner() {
    // if(this.state.showBanner){
    // console.log(this.state.bannerDetails)
    if (this.state.showBanner) {
      console.log("banner should be showing");
      return (
        <div
          className="offer-banner-background"
          onClick={this.handleBannerClick}
        >
          <img
            src={this.state.bannerDetails.banner_link}
            alt="Latest offer by Vimasmiham"
          />
        </div>
      );
    } else {
      console.log("banner should be showing");
      return null;
    }
    // }
  }

  render() {
    if (this.state.componentMounting) {
      console.log(this.state);
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.componentMounting}
          />
        </div>
      );
    } else {
      console.log(this.state);
      const { value, suggestions } = this.state;

      const inputProps = {
        placeholder: "Search...",
        value,
        onChange: this.onChange,
      };
      return (
        <React.Fragment>
          <div id="homepage">
            <div className="home-page-banner">
              {this.renderHeader()}

              <div className="banner">
                <div className="row mx-0 position-relative">
                  <div className="banner-image col-3 ">
                    <img
                      src="https://www.vimasmiham.com/BannerImage.png"
                      alt="Vima Smiham is here to help"
                      className="mt-4"
                    />
                  </div>

                  <div className="webView banner-text-container">
                    <Searchbar
                      showIcon="block"
                      {...this.props}
                      subjects={this.state.headerSubjects}
                    />

                    <div
                      style={{
                        marginTop: "30px",
                        width: "600px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Carousel
                        showStatus={false}
                        showArrows={false}
                        showThumbs={false}
                        showIndicators={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        interval={3000}
                      >
                        <p className="p-4 text-white h2-responsive">
                          A To Z of Insurance Learning
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          93% success rate
                        </p>

                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalQuestions || "-"
                          } Practice Questions `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalQuestionBank || "-"
                          } Question Banks `}
                        </p>

                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalSubjects || "-"
                          } Subjects `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalLectures || "-"
                          } Lectures `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          Regular updation of Study Material
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          2500 plus hours of Study Material
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          Don’t compromise – Go for the best
                        </p>
                      </Carousel>
                    </div>
                  </div>
                  <div className="mobView search-text-container">
                    <Searchbar
                      showIcon="block"
                      {...this.props}
                      subjects={this.state.headerSubjects}
                    />

                    <div style={{ marginTop: "30px" }}>
                      <Carousel
                        showStatus={false}
                        showArrows={false}
                        showIndicators={false}
                        infiniteLoop={true}
                        autoPlay={true}
                        showThumbs={false}
                        interval={3000}
                      >
                        <p className="p-4 text-white h2-responsive">
                          Vima Smiham - A To Z of Insurance Learning
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          93% success rate
                        </p>

                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalQuestions || "-"
                          } Practice Questions `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalQuestionBank || "-"
                          } Question Banks `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalSubjects || "-"
                          } Subjects `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          {`${
                            this.state.statistics?.totalLectures || "-"
                          } Lectures `}
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          Regular updation of Study Material
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          2500 plus hours of Study Material
                        </p>
                        <p className="p-4 text-white h2-responsive">
                          Don’t compromise – Go for the best
                        </p>
                      </Carousel>
                    </div>
                  </div>
                  <div className="upcoming-image col-12 col-lg-3">
                    <div className="mobView banner-text-container"></div>
                    <img
                      src="https://www.vimasmiham.com/upcomingExams.png"
                      alt="Upcoming Exam"
                      className="mt-4"
                    />
                    <div className="upcoming-exam-heading ">
                      <p>Important Information</p>
                    </div>
                    <div className="upcoming-exam-notification">
                      <Suspense
                        fallback={
                          <Loader
                            type="Bars"
                            color="#DB5D7B"
                            height={20}
                            width={20}
                            timeout={200000} //3 secs
                            className="svg-component"
                          />
                        }
                      >
                        <Fade>
                          <UpcomingExams showIcon="block" {...this.props} />
                        </Fade>
                      </Suspense>

                      <Link
                        className="d-flex justify-content-center "
                        style={{ color: "#0074c5" }}
                        to="/upcoming-exams"
                      >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.renderAboutUs()}

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="#87A1E8"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <Testimonials {...this.props} />
              </Fade>
            </Suspense>

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="#87A1E8"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <OurFaculty {...this.props} />
              </Fade>
            </Suspense>

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="#87A1E8"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <Faqs {...this.props} />
              </Fade>
            </Suspense>

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="#DB5D7B"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <InsuranceIndustry {...this.props} />
              </Fade>
            </Suspense>

            {/* <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="#EBB044"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <Downloads {...this.props} />
              </Fade>
            </Suspense> */}

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="rgb(0, 147, 217)"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <InlighterVein {...this.props} />
              </Fade>
            </Suspense>

            <Suspense
              fallback={
                <Loader
                  type="Bars"
                  color="rgb(0, 147, 217)"
                  height={80}
                  width={80}
                  timeout={200000} //3 secs
                  className="svg-component"
                />
              }
            >
              <Fade>
                <FunFact {...this.props} />
              </Fade>
            </Suspense>
            <div style={{ height: "auto" }} className="windowView">
              <MainFooterPage {...this.props} />
            </div>
            <div className="mobView">
              <StickyFooterPage {...this.props} style={{ width: "100%" }} />
            </div>
          </div>

          <MetaTags>
            <title>{this.state.page_title_seo}</title>

            <meta
              id="course"
              name="description"
              content={this.state.page_description_seo}
            />
          </MetaTags>

          {this.renderBanner()}
        </React.Fragment>
      );
    }
  }
}
