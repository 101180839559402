import React, { Component } from 'react'
import AdminNavbar from '../Navbar/adminNavbar'
import SidebarPage from '../Sidebar/sidebarPage'
import MediaComponent from "../MediaComponent/chooseMediaFile";
import swal from 'sweetalert'
import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';
import CKEditor from 'ckeditor4-react';
import {MDBIcon} from 'mdbreact';
import './funfacts.css'

const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;

export default class EditTDD extends Component {

    constructor(props){
        super(props);
        this.state={
            funfact_id:-1,
            funfact:'',
            inputLink:'',
            author:'',
            loading:true

        }
        this.onEditorChange = this.onEditorChange.bind( this );

    }

    onEditorChange( e ) {
        console.log(e)
        this.setState( {
          funfact: e.editor.getData()
        } );
      }

    componentDidMount(){
       this.getFunFactDetails()
    }


    getFunFactDetails(){
        let funfact= window.localStorage.getItem('quote_item')
        if(!funfact){
            this.props.history.push('/admin/all-tdds')
        }
        else{
            let funFact = JSON.parse(funfact)
            this.setState({
                funfact_id:funFact.id,
                funfact:funFact.text,
                inputLink:funFact.image,
                author:funFact.author,
                loading:false
            })
        }
    }

    handleMediaChooseImage = inputLink =>{
      this.setState({inputLink})
    }
   
    editFunFact(){
        this.setState({loading:true})
        let formBody = new FormData();
        formBody.append('id',this.state.funfact_id)
        formBody.append('text', this.state.funfact)
        formBody.append('image', this.state.inputLink)
        formBody.append('author', this.state.author)

        fetch('https://www.vimasmiham.com/edit_ttd',{
            method:'POST',
            body:formBody
        })
        .then(response=>response.json())
        .then(responseJson=>{
            swal("Success!", responseJson.message, 'success')
            .then(()=>{
                this.props.history.push('/admin/all-tdds')
            })
        })
        .catch(err=>{
            swal('Warning!', "There was an error", 'warning')
            this.setState({loading:false})
            console.log(err)
        })
    }


    renderFunFactText() {
        return (
          <div className="col-12 mb-2 mt-0 cost-section">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="cost-label subject-labels"
            >
             Quote :
            </label>
            
               <CKEditor
              data={this.state.funfact}
              style={{width:'90%', margin:'10px auto'}}
              onChange={this.onEditorChange} 
              className="textFontSize"/> 
          </div>
        );
      }

      renderAuthor = () => {
        return (
          <div className="col-12  d-flex">
          <label htmlFor="defaultFormRegisterNameEx" className="course-duration-label subject-labels"
         
          // style={{  width: '41%' }}
          
          >
              Author:
            </label>
    
        
            <div
              className="flex-column "
              style={{
                width: "39.4%",
                borderLeft: "1px solid #bfbfbf",
                height: "100%"
              }}
          
            >
              <input
                   style={{ marginTop: "10px", width: "100%"}}
                autoFocus
                type="text"
                placeholder="Author..."
                value={this.state.author}
                onChange={text => this.setState({author:text.target.value})}
                id="defaultFormRegisterNameEx"
                className="form-control duration-form custom-form link-placeholder"
              />
    

        </div>
           
          </div>
        );
      };
      renderChooseFile = () => {
        return (
          <div className="col-12  d-flex">
          <label htmlFor="defaultFormRegisterNameEx" className="course-duration-label subject-labels"
         
          // style={{  width: '41%' }}
          
          >
              Enter file link:
            </label>
    
        
            <div
              className="flex-column "
              style={{
                width: "39.4%",
                borderLeft: "1px solid #bfbfbf",
                height: "100%"
              }}
          
            >
              <input
                   style={{ marginTop: "10px", width: "100%"}}
                autoFocus
                type="text"
                placeholder="Choose File From Gallery"
                value={this.state.inputLink}
                onChange={text => this.handlechangeurl(text)}
                id="defaultFormRegisterNameEx"
                className="form-control duration-form custom-form link-placeholder"
              />
    
    <div   style={{ marginLeft:'5%' }}>
            <MediaComponent
           
                          onSelectMediaItem={this.handleMediaChooseImage}
                          buttonName={<MDBIcon icon="camera" />}
                          restriction="image/*"
                        />
                        </div>
        </div>
           
          </div>
        );
      };


      handlechangeurl = text => this.setState({ inputLink: text.target.value });

    render(){
        console.log(this.state)
        return(
            <div id="add-subject-page">
            <AdminNavbar {...this.props} />

            <div className="d-flex">
              <SidebarPage {...this.props} active={3}/>

              <div className="add-subject-column">
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
                    <React.Fragment>
                      

                    <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                    <button className="admin-button-style" onClick={()=>this.props.history.push('/admin/all-tdds')}>
                        All Quotes
                    </button>
                    <button className="admin-button-style" onClick={()=>this.editFunFact()}> Save &amp; Publish</button>

                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Quote</h3> 
                    </div>
                    </div>
                <div className="d-flex">
                <div className="subject-content-container ml-4">
                <div className="row px-0 mx-0">
                
       
                   {this.renderFunFactText()}
                   {this.renderAuthor()}
                   {this.renderChooseFile()}
                </div>
                </div>

                </div>

    

                <hr />                    
          
                    </React.Fragment>
                   )
                }
              </div>


            </div>
        </div>
        )
    }
}
