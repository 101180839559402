import React, { Fragment } from "react";
import "../../Components/Exam-News/Exam-news.css";

import "../../Components/Homepage/homepage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from "sweetalert";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import "./Aboutus.css";

import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import {Link} from 'react-router-dom'


class Aboutus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: "",
      mainTitle: "",
      faculties: [],
      mainText: "",
      objectiveImage: ``,
      objectiveText: "",
      objectiveTitle: "",
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      visionTitle: "",
      visionText: "",
      visionImage: ``,
      cart:[],
      loading:true
    };
  }
  componentWillMount() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata)
      },()=>this.getCart());
    }
   
      this.getaboutus()
    
  }

  componentDidMount(){
    console.log(this.state, 'state')
    //document.title = this.state.aboutUs[0].page_title_seo
  }


  getaboutus = () => {
    let formData = new FormData();

   // formData.append("about_us_id", 1);
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_new_about_us", {
        method: "GET",
      //  body: formData
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);
        
            this.setState({aboutUs:responseJson, loading:false})
        
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id
    };
  
    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
  
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "cart");
  
          this.setState({
            cart: responseJson,
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
  
          console.log(error);
        })
    });
  };
  


  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p  className="productMainFirstText">
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / About
        </p>
      </div>
    );
  }

renderContent(){
 // let details = this.state.aboutUs.descriptions
  let details = JSON.parse(this.state.aboutUs[0].descriptions)
  console.log(details)
  return details.map((item,index)=>{
    return(
      <Fragment>
      <h2 className="text-center my-3">{item.title}</h2>
      <div className="row align-items-center">
        <div className={`col-12 col-md-6 text-center text-md-left ${index%2==0?`order-2 order-md-1`:`order-2 order-md-2`}`}>
          <p dangerouslySetInnerHTML={{__html:item.description}}></p>
        </div>
        <div className={`col-12 col-md-6 text-center ${index%2==0?`order-1 order-md-2`:`order-1 order-md-1`}`}>
          <img src={item.image} alt={item.title} className="img-fluid"/>
        </div>
      </div>
      </Fragment>
    )
  })
}


  render() {
    {
     // console.log(this.state.faculties);
    }
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      console.log(this.state)
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
        
        <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
                      />

          <div id="aboutUs">
            <div className="about-main ">
              <h1 className="about-us-heading">About us</h1>
            </div>

            <div>{this.renderAboutNavigation()}</div>
           
            <div className="redirect-boxes d-none d-md-flex" style={{display: 'flex',justifyContent: 'center',flexWrap: 'wrap'}}>
                <Link className="btn" style={redirectButtons} to="/acts_and_regulations" >Acts &amp; Regulations</Link>
                <Link className="btn" style={redirectButtons} to="/categories/promotional-exams">Promotional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/categories/professional-exams" >Professional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/latest-news" >Latest News</Link>
                <Link className="btn" style={redirectButtons} to="/test-series" >Test Series</Link>
                <Link className="btn" style={redirectButtons} to="/all-faculty" >All Faculty</Link>
                <Link className="btn" style={redirectButtons} to="/upcoming-exams" >Upcoming Exams</Link>
            
            </div>
            
            <div className="about-us-content-container container-c">
              {this.renderContent()}
            </div>

           
           
            <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
          </div>
        </div>
      );
    }
  }
}

const redirectButtons = {
  textTransform:'capitalize',
  borderRadius:'4px',
  fontSize: '16px',
  fontFamily: 'Ubuntu-M',
  color: '#908b8b'
}

export default Aboutus;
