import React, { Component } from "react";
import { withRouter } from "react-router";

class Authorizer extends Component {
  constructor(props) {
    super(props);
    this.checkAuthorization()
  }



  componentWillReceiveProps(nextProps){
      console.log('route changed')
      this.checkAuthorization()
  }

  checkAuthorization = () => {
    console.log(this.props.location)
    const adminToken = window.localStorage.getItem("adminToken");
    if (!adminToken) {
      this.props.history.push("/admin");
    } else {
      const today = new Date().getTime();
      if (today >= adminToken) {
        window.localStorage.removeItem("adminToken");
        this.props.history.push("/admin");
      }
      else return;
    }
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(Authorizer);
