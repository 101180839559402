import React from "react";
import "../../Components/Exam-News/Exam-news.css";
import "./Acts_Regulations.css";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import "../../Components/Homepage/homepage.css";
import Iframe from "react-iframe";
import swal from "sweetalert";
import { MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";

import "../Downloads/download.css";

import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";

class Circulars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mainImage: "",
      mainTitle: "",
      textToFind: "",
      faculties: [],
      mainText: "",
      circulars: [],
      objectiveImage: ``,
      objectiveText: "",
      objectiveTitle: "",
      search: false,
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      downloads: [],
      visionTitle: "",
      visionText: "",
      visionImage: ``,
      faqs: [],
      cart: [],
      circularFrame: false,
      activeLink: null,
      activeFilter: 0,
    };
  }
  componentWillMount() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    }

    this.getCirculars();
  }

  getCirculars = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_circulars", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState({
            circulars: responseJson,
            loading: false,
          });
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  renderAboutNavigation() {
    return (
      <div
        style={{ width: "100%" }}
        className="mt-2 align-items-center d-flex justify-content-between "
      >
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 6%" }}>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / Acts &amp; Regulations
        </p>
        <div>
          <FacebookShareButton
            url="https://www.vimasmiham.com/acts_and_regulations.html"
            className="m-1"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton
            url="https://www.vimasmiham.com/acts_and_regulations.html"
            className="m-1"
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton
            url="https://www.vimasmiham.com/acts_and_regulations.html"
            className="m-1"
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            url="https://www.vimasmiham.com/acts_and_regulations.html"
            className="m-1"
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <EmailShareButton
            url="https://www.vimasmiham.com/acts_and_regulations.html"
            className="m-1"
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    );
  }

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  compare = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const bandA = a.circular_name.toUpperCase();
    const bandB = b.circular_name.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  };

  toggleFrame = (item) => {
    this.setState({
      cicularFrame: !this.state.cicularFrame,
    });
  };

  renderModalFrame() {
    console.log("mr.smith");
    if (this.state.circularFrame) {
      return (
        <MDBModal
          className="circularModal"
          size="lg"
          isOpen={this.state.circularFrame}
          toggle={() => this.setState({ circularFrame: false })}
        >
          <MDBModalHeader
            toggle={() => this.setState({ circularFrame: false })}
          >
            <p className="modalFramePara">
              {" "}
              {this.state.activeLink.circular_name}
            </p>
          </MDBModalHeader>
          <MDBModalBody>
            <Iframe
              width="100%"
              height="100%"
              // id="myId"
              // className="myClassname"
              url={`${this.state.activeLink.circular_link}`}
              display="initial"
              position="relative"
            />
          </MDBModalBody>
        </MDBModal>
      );
    } else {
      return null;
    }
  }

  renderCirculars() {
    let searchedArray = [];

    console.log(this.state.circulars);
    if (this.state.search == true) {
      this.state.circulars.forEach((item) => {
        if (
          item.circular_name
            .toLowerCase()
            .includes(this.state.textToFind.toLowerCase())
        ) {
          searchedArray.push(item);
        }
      });
      return searchedArray
        .filter((item) => item.status == 1)
        .sort(this.compare)
        .map((item) => {
          return (
            <div
              className="my-3 pl-2 pl-md-0 d-flex align-items-center circular-item-container"
              key={item.download_id}
            >
              <MDBIcon icon="bell" className="mr-2" />
              <p
                className="circular-item-main"
                onClick={() =>
                  // this.setState({ activeLink: item }, () =>
                  //   this.setState({ circularFrame: true })
                  // )
                  // window.open(item.circular_link)

                  {
                    const encryptedData = btoa(
                      JSON.stringify({
                        name: item.circular_name,
                        link: item.circular_link,
                      })
                    );
                    window.open(`/pdf/${encryptedData}`);
                  }
                }
              >
                <span> {item.circular_name}</span>
              </p>
            </div>
          );
        });
    } else if (this.state.search == false) {
      if (this.state.activeFilter == 0) {
        return [...this.state.circulars]
          .filter((item) => item.status == 1)
          .sort(this.compare)
          .map((item) => {
            return (
              <div
                className="my-3 pl-2 pl-md-0 d-flex align-items-center circular-item-container"
                key={item.download_id}
              >
                <MDBIcon icon="bell" className="mr-2" />
                <p
                  className="circular-item-main"
                  onClick={() =>
                    // this.setState({ activeLink: item }, () =>
                    //   this.setState({ circularFrame: true })
                    // )
                    // window.open(item.circular_link)

                    {
                      const encryptedData = btoa(
                        JSON.stringify({
                          name: item.circular_name,
                          link: item.circular_link,
                        })
                      );
                      window.open(`/pdf/${encryptedData}`);
                    }
                  }
                >
                  <span> {item.circular_name}</span>
                </p>
              </div>
            );
          });
      } else {
        console.log(this.state.circulars);
        return [...this.state.circulars]
          .filter((item) => item.status == 1)
          .sort(
            (a, b) =>
              new Date(b.date_of_circular) - new Date(a.date_of_circular)
          )
          .map((item) => {
            console.log(item);
            return (
              <div
                className="my-3 pl-2 pl-md-0 d-flex align-items-center circular-item-container"
                key={item.download_id}
              >
                <MDBIcon icon="bell" className="mr-2" />
                <p
                  className="circular-item-main"
                  onClick={() =>
                    // this.setState({ activeLink: item }, () =>
                    //   this.setState({ circularFrame: true })
                    // )
                    // window.open(item.circular_link)

                    {
                      const encryptedData = btoa(
                        JSON.stringify({
                          name: item.circular_name,
                          link: item.circular_link,
                        })
                      );
                      window.open(`/pdf/${encryptedData}`);
                    }
                  }
                >
                  <span> {item.circular_name}</span>
                </p>
              </div>
            );
          });
      }
    }
  }

  handleFilterChange = (e) => {
    this.setState(
      { activeFilter: e.target.value, search: false, textToFind: "" },
      () => console.log(this.state)
    );
  };

  renderFilter() {
    return (
      <>
        <label className="circulars-filter-label">
          <select
            id="circulars-filter"
            onChange={this.handleFilterChange}
            value={this.state.activeFilter}
          >
            <option value={Number(0)}>A-Z</option>
            <option value={Number(1)}>Recent</option>
          </select>
        </label>
      </>
    );
  }

  searchByType(text) {
    this.setState({ textToFind: text, search: true });
  }
  render() {
    console.log(this.state.faculties);
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
          className="acts-and-regulations-page"
        >
          <Helmet>
            <title>Acts | Vima Smiham</title>
          </Helmet>
          <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            // active="My Dashboard"
          />

          <div id="aboutUs">
            <div className="about-main actsRegulation">
              <h1 className="about-us-heading actsRegulation">ACTS</h1>
            </div>

            <div>{this.renderAboutNavigation()}</div>

            <Quicklinks />
            <div className="inputBox">
              <input
                type="text"
                className="actsSearch"
                placeholder="Search Acts here..."
                onChange={(e) => this.searchByType(e.target.value)}
              ></input>
            </div>
            <div className=" my-4 container-c">
              <div className="row">
                <div
                  className="col-12 col-md-6 order-2 order-md-1 align-self-center circulars-container"
                  style={{
                    height: "500px",
                    overflowY: "scroll",
                    borderRadius: "5px",
                    padding: "0px 15px",
                    boxShadow: "inset -1px -2px 3px 0px #dcdcdc",
                    border: "1px solid #d4d4d4",
                  }}
                >
                  {this.renderFilter()}
                  {this.renderCirculars()}
                </div>
                <div className="col-12 col-md-6 order-1 order-md-2 d-flex align-items-center faq-im-con">
                  <img
                    className="downloads-image img-fluid"
                    src="https://www.vimasmiham.com/ActsRegulations.png"
                    alt="Downloads"
                  />
                </div>
              </div>
            </div>

            <div style={{ height: "auto" }} className="windowView">
              <MainFooterPage {...this.props} />
            </div>
            <div className="mobView">
              <StickyFooterPage {...this.props} />
            </div>
          </div>
          {this.renderModalFrame()}
        </div>
      );
    }
  }
}
export default Circulars;
