import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import swal from "sweetalert";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import "./signUP.css";
import { RegionDropdown } from 'react-country-region-selector';

import RangePicker from "react-range-picker"

import {
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import GoogleImg from "../../Assets/images/google.png";
import FbImg from "../../Assets/images/facebook.png";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";

const responseFacebook = response => {
  console.log(response);
};
const responseGoogle = response => {
  console.log(response);
};

//import { FulfillingBouncingCircleSpinner } from 'react-epic-spinners';

class signUP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit_image: null,
      image: null,

      name: "",
      email: "",
      password: "",

      confirmPassword: "",
      gender: "",
      date_of_birth: null,
      company: "",
      gstin:"",
      designation: "",
      // profile_pic:'',
      qualifications: "",
      mobile: "",
      loading: false,

      login: false,
      forgetPassword: false,
      otp: false,

      aqualification: "",
      pqualification: "",
      isChecked: false,
      date: null,
      loginToggle:false,
      state:null,
      pincode:null,
      city:null,
      address:null,
      
    };
    this.handleChecked = this.handleChecked.bind(this); // set this, because you need get methods from CheckBox
  }

  
  componentWillMount() {
this.getVerticals()
  }
  getVerticals=async()=>{

    this.setState({
      loading:true
    })
  
  
    
    this.setState({
    response: fetch('https://www.vimasmiham.com/get_verticals', {
    method: 'GET',
    headers: {
     
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'max-age=31536000'
    },
    
    
    
    }).then((response) => response.json())
    .then((responseJson)=>{
  
      console.log(responseJson,'verticals')
   this.setState({
     verticals:responseJson.reverse(),
     verticalsMenu:responseJson,
    
   },()=> this.getExams())
      
        }).catch((error) => {
    this.setState({
     loading:false
    })
   
    console.log(error)
      })
     })
    }
    
  
    getExams = async() => {
      console.log("fetching exams")
      this.setState({
      response: fetch('https://www.vimasmiham.com/get_exams', {
          method: 'GET',
          headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
      },
  
  
      }).then((response) => response.json())
      .then((responseJson)=>{
      console.log("Ëxams",responseJson);
      console.log(JSON.parse(responseJson[0].verticals))
      this.setState({
          examsMenu:responseJson.reverse(),
          loading:false
         
      })
       })
       .catch((error) => {
         this.setState({
           loading:false
         })
        console.log(error);
          })
      })
  }
  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Password and confirm Password not Match!", {
        className: "red-bg"
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
      otp: this.state.otp,
      password: this.state.newPassword
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              otp: true,
              forgetPassword: false,
              loading: false
              // add_modal:false
            });
            swal("Great!", responseJson.message, "success");
          } else {
            this.setState({
              isChecked:false,
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  forgotPassword = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "Forgot");
          if (responseJson.status == 200) {
            this.setState({
              otp: true,
              forgetPassword: false,
              loading: false
              // add_modal:false
            });
            swal("Otp Sent!", responseJson.message, "info");
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  LoginForm = () => {
    this.setState({
      loading: true
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email1,
      password: this.state.password1
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/user_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            this.setState({
              loading: false
              // add_modal:false
            });
            this.props.history.push("/dashboard");
          } else {
            this.setState({
              loading: false
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };


  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }


  addAddress(user_id){
    let details = {
      // product_id:this.state.selected_products.product_id,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pincode,
      user_id:user_id
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/add_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "rrrr");
          if (responseJson.status == 400) {
            this.setState({
              loading: false
              // add_modal:false
            });
            swal("Warning!", responseJson.message, "warning");
          } else {
            this.setState({
              name: "",
              phone: "",
              email: "",
              password: "",
              gender: "",
              city: "",
              address: "",
              company: "",
              designation: "",
              city:"",
              pinocde:'',
              state:null
              // add_modal:false
            });
            swal({
              title: "Registration successful!",
              text: responseJson.message,
              icon: "success",  
            }).then(() => {
             
              this.props.history.push('/')
         
            });
          }
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  addForm = () => {
    console.log(this.state.pqualification)
    var now = new Date().getFullYear()
    if (
      this.state.name.length === 0 ||
      this.state.mobile.length === 0 ||
      this.state.email.length === 0 ||    
      this.state.password.length == 0 ||
      this.state.confirmPassword.length == 0
    ) {
      swal("Check all fields!", "Fields with * are mandatory", "warning");
    } else if (this.state.valid_email == false) {
      swal("Check email!", "Invalid email", "warning");
    } else if (this.state.valid_company == false) {
      swal("Check Company!", "Invalid Company name", "warning");
    } else if (
      this.state.mobile.length != 10 &&
      this.state.mobile.length != 11
    ) {
      swal("Check mobile number! ", "Invalid mobile number", "warning");
    } else if (
       this.state.date == null
    ) {
      swal("Check date of birth!", "Invalid date of birth", "warning");
    } else if (this.state.isChecked == false) {
      swal("Accept terms & conditions!", "Please accept terms & conditons", "warning");
    } else if((now - this.state.date.getFullYear()) <= 18){
     
        swal("Below age!", "You have to be 18 years or above to register an account.", 'info');

    }else {
      this.setState({
        loading: true
        // add_modal:false
      });
      if (this.state.image == null) {
        let details = {
          // product_id:this.state.selected_products.product_id,
          name: this.state.name,
          phone: this.state.mobile,
          email: this.state.email,
          password: this.state.password,
          gender: this.state.gender,
          city: this.state.city,
          address: this.state.address,
          GSTIN: this.state.gstin,
          company: this.state.company,
          designation: this.state.designation,
          // qualifications:this.state.aqualification,
          // qualifications:this.state.pqualification,
          qualifications: JSON.stringify([
            {
              academics: this.state.aqualification,
              professional: this.state.pqualification
            }
          ]),

          date_of_birth: this.commentdate(this.state.date)
        };
        console.log(details, "add-without photo");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        this.setState({
          response: fetch("https://www.vimasmiham.com/user_signup", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000"
            },
            body: formBody
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson, "rrrr");
              if (responseJson.status == 400) {
                this.setState({
                  loading: false
                  // add_modal:false
                });
                swal("Warning!", responseJson.message, "warning");
              } else {
                this.addAddress(responseJson.user_id)
              }
             })
            .catch(error => {
              this.setState({
                loading: false
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            })
        });
      } else {
        console.log(this.state.image,'imageSign')
        let formData = new FormData();
        // var filename = (this.state.selected_products.product_image).substr(28,(this.state.selected_products.product_image).length)
        // formData.append('product_id',this.state.selected_products.product_id)
        formData.append("name", this.state.name);
        formData.append("phone", this.state.mobile);
        formData.append("gender", this.state.gender);
        formData.append("file", this.state.image);
        formData.append("date_of_birth", this.commentdate(this.state.date));
        formData.append("city", this.state.city);
        formData.append("address", this.state.address);
        formData.append("email", this.state.email);
        formData.append("GSTIN", this.state.gstin);
      

        formData.append(
          "qualifications",
          JSON.stringify([
            {
              academics: this.state.aqualification,
              professional: this.state.pqualification
            }
          ])
        );

        formData.append("password", this.state.password);
        formData.append("company", this.state.company);
        formData.append("designation", this.state.designation);

        // formData.append('filename',filename)

        for (let key of formData.entries()) {
          console.log(key[0] + ",with photo " + key[1]);
        }

        this.setState({
          response: fetch("https://www.vimasmiham.com/user_signup", {
            method: "POST",
            body: formData
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson, "rrrr");
              if (responseJson.status == 400) {
                this.setState({
                  loading: false
                  // add_modal:false
                });
                swal("Warning!", responseJson.message, "warning");
              } else {
                this.addAddress(responseJson.user_id)
              }
             })
            .catch(error => {
              this.setState({
                loading: false
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            })
        });
      }
    }
  };

  handleChecked(e) {
    this.setState({ isChecked: !this.state.isChecked });

    console.log(e.target.value, "xax");
  }

  toggle_edit11 = () => {
    this.setState({
      otp: !this.state.otp
    });
  };

  renderModalOtp() {
    if (this.state.otp) {
      return (
        <MDBModal isOpen={this.state.otp} toggle={this.toggle_edit11}>
          <MDBModalHeader toggle={this.toggle_edit11}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A"
              }}
            >
              One Time Password{" "}
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%"
              }}
            >
              <div style={{ textAlign: "center", margin: "3%" }}>
                <text>Set New Password</text>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  placeholder="OTP"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  value={this.state.otp}
                  onChange={text => this.setState({ otp: text.target.value })}
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter New Password"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ newPassword: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter Password Again"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="text"
                  onChange={text =>
                    this.setState({ ConfirmNewPassword: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#dbaa34",
                    color: "#fff"
                  }}
                  className="ButtonLogIn"
                  type="submit"
                  onClick={() => this.handleOtp()}
                >
                  Forget Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  toggle_edit1 = () => {
    this.setState({
      forgetPassword: !this.state.forgetPassword
    });
  };

  renderModalForget() {
    if (this.state.forgetPassword) {
      return (
        <MDBModal isOpen={this.state.forgetPassword} toggle={this.toggle_edit1}>
          <MDBModalHeader toggle={this.toggle_edit1}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A"
              }}
            >
              Forget Password
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%"
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "5%" }}>
                <text>Please Enter Your Email </text>
              </div>
              <div style={{ width: "100%", marginBottom: "2%" }}>
                <input
                  placeholder="Email"
                  autoComplete="off"
                  autoFill="off"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff"
                  }}
                  type="Email"
                  onChange={text =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: ".5px solid #E5E5E5",
                    backgroundColor: "#dbaa34",
                    color: "#fff"
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.forgotPassword()}
                >
                  Forget Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  handleSubmit = () => {
    const { password, confirmPassword } = this.state;
    // perform all neccassary validations
    // const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    // const isOk = re.test(password);
    let isOk = true;
    console.log(isOk, "Ms12");
    if (isOk == false) {
      swal(
        "Password must contain",
        "1. Minimum length should be 8\n 2. Must contains atleast one capital letter\n3. Must contains atleast one small letter\n4. Must contains atleast one special character (!@#$%^&*)",
        "warning"
      );
    } else if (this.state.isChecked == false) {
      swal(
        "Terms and Conditions",
        "You have not ticked the the terms and conditions",
        "warning"
      );
    } else {
      if (password !== confirmPassword) {
        swal("info!", "Passwords do not match", "warning");
      } else {
        this.addForm();
      }
    }
  };

  validateEmail = text => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  validateCompany = text => {
    var reg = /^[\w]+([-_\s]{1}[a-z0-9]+)*$/i;

    if (reg.test(text) === false) {
      this.setState({ company: text, valid_company: false });

      return false;
    } else {
      this.setState({ company: text, valid_company: true });
    }
  };

  toggle_edit = () => {
    this.setState({
      login: !this.state.login
    });
  };

  renderModal() {
    if (this.state.login) {
      return (
        <div className="login-modal">
          <MDBModal
            isOpen={this.state.login}
            toggle={this.toggle_edit}
            className="login-modal"
          >
            <MDBModalHeader toggle={this.toggle_edit}>
              <span
                style={{
                  fontFamily: "Ubuntu-r",
                  fontSize: "18px",
                  color: "#4A4A4A"
                }}
              >
                Log in to Your Vima Smiham Account
              </span>{" "}
            </MDBModalHeader>
            <MDBModalBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <div className="socialButtonDiv">
                  <FacebookLogin
                    appId="2519212541693120"
                    autoLoad={false}
                    fields="name,email,picture"
                    // onClick={componentClicked}
                    callback={responseFacebook}
                    render={renderProps => (
                      <button
                        className="socialIconbutton1"
                        onClick={renderProps.onClick}
                      >
                        <div style={{ float: "left", marginLeft: "5%" }}>
                          <img
                            src={FbImg}
                            alt="google-logo"
                            className="logoHeight-margin"
                            height="20px"
                          />
                        </div>
                        <span style={{ float: "left", marginLeft: "10%" }}>
                          {" "}
                          Continue with Facebook
                        </span>
                      </button>
                    )}
                  />
                </div>

                <div className="socialButtonDiv">
                  <GoogleLogin
                    clientId="625056575297-iv7kuaa1i6k74nphbvoj1fmpmuk0sh3f.apps.googleusercontent.com"
                    render={renderProps => (
                      <button
                        className="socialIconbutton2"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <div style={{ float: "left", marginLeft: "5%" }}>
                          <img
                            src={GoogleImg}
                            alt="google-logo"
                            className="logoHeight-margin"
                            height="20px"
                          />
                        </div>{" "}
                        <span
                          style={{
                            float: "left",
                            marginLeft: "7%",
                            color: "#4A4A4A"
                          }}
                        >
                          {" "}
                          Continue with Google
                        </span>
                      </button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                    // onLogoutSuccess={logout}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    placeholder="Email or Phone"
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff"
                    }}
                    type="Email"
                    onChange={text =>
                      this.setState({ email1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff"
                    }}
                    placeholder="Password"
                    type="password"
                    onChange={text =>
                      this.setState({ password1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <button
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      backgroundColor: "#dbaa34",
                     border: "1.5px solid #dbaa34",
                      color: "#fff"
                    }}
                    className="ButtonLogIn"
                    type="button"
                    onClick={() => this.LoginForm()}
                  >
                    Log in
                  </button>

                  <p
                    className="forgetPassword"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A"
                    }}
                  >
                    <text>or </text>
                    <span style={{ color: "#0093D9" }}>
                      <a
                        onClick={() =>
                          this.setState({ login: false, forgetPassword: true })
                        }
                      >
                        <text>Forgot Password</text>
                      </a>
                    </span>
                  </p>
                  <p
                    className="dontHaveAccount"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A"
                    }}
                  >
                    Don't have a account ?{" "}
                    <a onClick={() => this.setState({ login: false })}>
                      <span style={{ color: "#0093D9" }}>Sign Up</span>
                    </a>
                  </p>
                </div>
              </div>
            </MDBModalBody>
          </MDBModal>
        </div>
      );
    }
  }


  handleImageUpload(event){
    if(event.target.files[0].size>500000){
      swal('Image exceeds size limit!','Please choose an image less than 500Kb', 'info')
    }
    else{
    this.setState({
      image: event.target.files[0],
      imageUrl: URL.createObjectURL(event.target.files[0])
    })
  }
  }

  renderEditImage() {
    let imageUrl = "";

    if (this.state.image == null) {
      return (
        <div className="input-group" style={{ width: "100%" }}>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              accept="image/*"
              aria-describedby="inputGroupFileAddon01"
              onChange={event =>
               this.handleImageUpload(event)
              }
            />
            <label className="custom-file-upload" htmlFor="inputGroupFile01">
              Choose file
            </label>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-around",
            margin: "0 10px",
            width: "45%"
          }}
          className="show_image"
        >
          <img
            className="imgSize"
            src={this.state.imageUrl}
            className="slider_div_image"
            style={{ marginLeft: "70px", width: "150px" }}
            alt="news_image"
          />
          <MDBIcon
            className="cross_icon"
            onClick={() => this.setState({ image: null })}
            icon="times-circle"
          />
        </div>
      );
    }
  }
  selectRegion (val) {
    console.log(val)
    this.setState({ state: val });
  }
  cancelCourse = () => {
    document.getElementById("create-course-form").reset();
  };
  changePQ = event=>{
    console.log(event)
    if(event.target.value !== 0)
    this.setState({pqualification: event.target.value});
    else{
      swal('Please Choose vald qualification', '','info')
    }
}
changeAQ = event=>{
  if(event.target.value !== 0)
  this.setState({aqualification: event.target.value});
  else{
    swal('Please Choose vald qualification', '','info')
  }
}


handleDate(e){

    this.setState({date:e})
}
  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;
    var txt;
    if (this.state.isChecked) {
      txt = "checked";
    } else {
      txt = "unchecked";
    }
    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      console.log(this.state.image)
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>

          <NewHeader
            {...this.props}
            cart={
       0
            }
            active="My Dashboard"
          />
          <form id="create-course-form">
            <div className="mainRegistration">
              <div className="mainRegistrationHeading">
                <h2>Register</h2>
                <p>Register and Start learning with Vima Smiham</p>
              </div>
              <div className="RegistrationForm">
                <div className="RegistrationInputDiv">
                  <div className="RegistrationInputDiv1">
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="name">
                          Name*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv name-oinphisfsdf">
                        <input
                          placeholder="Name"
                          value={this.state.name}
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          onChange={text =>
                            this.setState({ name: text.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="Mobile">
                          Mobile*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="Mobile"
                          type="number"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.mobile}
                          onChange={text =>
                            this.setState({ mobile: text.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="email">
                          Email*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          style={{ background: "none" }}
                          placeholder="Email"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.email}
                          onChange={text =>
                            this.validateEmail(text.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="email">
                          Password*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          style={{ background: "none" }}
                          placeholder="Password"
                          type="password"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.password}
                          onChange={text =>
                            this.setState({ password: text.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="email">
                          Confirm Password*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          style={{ background: "none" }}
                          placeholder="Confirm Your Password"
                          type="password"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.confirmPassword}
                          onChange={text =>
                            this.setState({
                              confirmPassword: text.target.value
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="gender">
                          Gender
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                    
                  <select name="gender" className="RegistrationInputType"
                  type="text1"
                   value={this.state.gender}
                   onChange={text1 =>
                     this.setState({ gender: text1.target.value })
                   }>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                      </div>
                    </div>
                        
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="state">
                          State
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                      <RegionDropdown
                      country="India"
                      className='region_select w-100'
                      value={this.state.state}
                      onChange={(val) => this.selectRegion(val)} 
                      />
                      </div>
                      </div>
                  
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="city">
                          City
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="Your City"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.city}
                          onChange={text1 =>
                            this.setState({ city: text1.target.value })
                          }
                        />

                        <datalist id="city">
                          <option value="Delhi" />
                          <option value="Mumbai" />
                          <option value="Other Citys" />
                        </datalist>
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="address">
                          Address
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="Your Complete Address"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.address}
                          onChange={text =>
                            this.setState({ address: text.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable">
                        <label className="lableText" for="address">
                          Pincode
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="For ex: 122018"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.pincode}
                          onChange={text =>
                            this.setState({ pincode: text.target.value })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="RegistrationInputDiv2">
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="qualifications">
                          Qualifications
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv RegistrationInputTypeDiv1">
                        <select     label="Single select"
                          onChange={this.changeAQ} type="text1" className='RegistrationInputType' id="aq">
                        <option defaultValue value={0}>Academic Qualifications</option>


                          <option value="Graduate" label="Graduate" />
                          <option value="Postgraduate" label="Postgraduate" />
                          <option value="Other"  label="Other" />
                        </select>
                      </div>
                    </div>
                    <div className="RegistrationLableAndType RegistrationProfessionalQualificationDiv">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label for="qualifications"></label>
                      </div>

                      <div className="RegistrationInputTypeDiv RegistrationInputTypeDiv1">
                      <select onChange={this.changePQ} type="text1" className='RegistrationInputType' id="pw">
                      <option defaultValue value={0}>Professional Qualifications</option>
                      <option value="Licentiate"  label="Licentiate" />
                      <option  value="Associate" label="Associate" />

                          <option  value="Fellowship" label="Fellowship" />
                          <option value="Other"    label="Other"/>
                        </select>
                      </div>
                    </div>

                    <div className="RegistrationLableAndType RegistrationDivDate">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="dateofbirth">
                          Date of Birth*
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv dateDiv RegistrationInputTypeDiv1 dob-input-container">
                        <div className="dateDiv1">
                          <input
                            className="dateInput"
                            type="date"
                            className="RegistrationInputType placeholder-fix dateInput"
                            name="dateofbirth"
                            format="dd/mm/yyy"
                            id="dateofbirth"
                           // max={moment().format("YYYY-MM-DD")}
                            onChange={date =>
                              this.setState({
                                date: new Date(date.target.value)
                              })
                            }
                          />
                     
                        </div>
                        <div className="dateDiv2 ">
                       
                        </div>
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="name">
                          Company
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="Your Current Company"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.company}
                          onChange={text =>
                            this.setState({company:text.target.value})
                          }

                          // onChange={(text)=>this.setState({company:(text.target.value)})}
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="name">
                          GSTIN
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="GSTIN"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.gstin}
                          onChange={text =>
                            this.setState({gstin:text.target.value})
                          }

                          // onChange={(text)=>this.setState({company:(text.target.value)})}
                        />
                      </div>
                    </div>
                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="name">
                          Designation
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv">
                        <input
                          placeholder="Your Designation"
                          type="text"
                          className="RegistrationInputType placeholder-fix"
                          value={this.state.designation}
                          onChange={text =>
                            this.setState({ designation: text.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="RegistrationLableAndType">
                      <div className="RegistrationInputLable RegistrationInputLable1">
                        <label className="lableText" for="uplod photo">
                          Upload photo <br />
                          <i className="file-size-limit">File size limit: 500kb</i>
                        </label>
                      </div>

                      <div className="RegistrationInputTypeDiv RegistrationInputTypeDiv1  ">
                        {this.renderEditImage()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="RegistrationCheckBox">
                <input
                  type="checkbox"
                  checked={this.state.isChecked}
                  onChange={this.handleChecked}
                  className="filled-in mx-2"
                  id="filled-in-box"
                />{" "}
                <text className="checkboxText">
                  {" "}
                  Accept all terms and conditions{" "}
                </text>{" "}
                <br />
                <div className="RegistrationCheckBoxhr"></div>
              </div>

              <div className="RegistrationButtonMain">
                <div className="RegistrationButtonLogIn">
                  <h1>
                    {" "}
                    <span className="RegistrationText">
                      Already have an Account ?
                    </span>{" "}
                    <a onClick={() => this.setState({ login: true })}>
                      <span className="RegistrationTextColor">Log In</span>
                    </a>{" "}
                  </h1>
                </div>

                <div className="RegistrationButton">
             
                  <div className="RegistrationButton2">
                    <button
                      className="ButtonConfirm"
                      type="button"
                      onClick={() => this.handleSubmit()}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
          {this.renderModal()}
          {this.renderModalForget()}
          {this.renderModalOtp()}
        </div>
      );
    }
  }
}

export default signUP;



