import React, { Component } from "react";
// import "../../Downloads/all_donwloads.css";
import SidebarPage from "../Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import swal from "sweetalert";
import { css } from "@emotion/core";
import { MDBTable, MDBTableBody, MDBIcon } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

class Circulars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      circulars: [],
      showAction: false,
      draft_count: 0,
      published_count: 0,
      trash_count: 0,
      all_selected: true,
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: [],
    };
  }
  componentWillMount() {
    this.setState({ loading: true }, () => this.getCirculars());
  }

  componentDidMount() {
    window.localStorage.removeItem("regulation_item");
  }

  getCirculars = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_circular_no_acts", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "xxx");
          var draft_count = 0,
            published_count = 0,
            trash_count = 0,
            data = [];
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].selected = 0;
            if (responseJson[v].status == 0) {
              draft_count = draft_count + 1;
            } else if (responseJson[v].status == 1) {
              published_count = published_count + 1;
            } else if (responseJson[v].status == 2) {
              trash_count = trash_count + 1;
            }
            if (responseJson[v].status == 1) {
            }

            data.push(responseJson[v]);
          }
          this.setState({
            circulars: data,
            draft_count: draft_count,
            published_count: published_count,
            trash_count: trash_count,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  handleEditCircular(item) {
    window.localStorage.removeItem("regulation_item");
    window.localStorage.setItem("regulation_item", JSON.stringify(item));

    this.props.history.push("/admin/edit-circular");
  }

  handleAddNewCircular() {
    // window.localStorage.setItem('subject_list',JSON.stringify(this.state.circulars))
    this.props.history.push("/admin/add-circular");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditCircular(item, index)}
              className="actionstext"
            >
              Edit
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext">Edit</span>{" "}
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { circulars } = this.state;
    let selected_page = circulars[index];
    for (var v = 0; v < circulars.length; v++) {
      circulars[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      circulars,
    });
  }
  hideactionbutton() {
    let { circulars } = this.state;

    for (var v = 0; v < circulars.length; v++) {
      circulars[v].selected = 0;
    }

    this.setState({
      circulars,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }

  changeStatus(item, status) {
    this.setState({
      loading: true,
    });
    let details = {
      regulation_id: item.regulation_id,
      status: status,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_regulation", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }
  renderTableData(item, index) {
    return (
      <tr key={item.circular_id}>
        {" "}
        <td style={{ width: "25%" }} className="tdName textFontSize">
          <div
            onMouseEnter={() => this.showactionbutton(item, index)}
            onMouseLeave={() => this.hideactionbutton()}
            className="name_div_table"
          >
            <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
              {item.regulation_name}
            </span>
            {this.renderActions(item, index)}
          </div>
        </td>
        <td>
          {" "}
          View File{" "}
          <MDBIcon
            style={{ cursor: "pointer" }}
            onClick={() => window.open(item.regulation_link, "_blank")}
            icon="eye"
          />
        </td>
        <td>
          {" "}
          <span style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}>
            {this.commentdate(item.date_of_regulation)}
          </span>
        </td>
        <td>{this.renderActiveStatus(item)}</td>
      </tr>
    );
  }

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { circulars, selected_data } = this.state;
    let selected_item = circulars[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ circulars });
  }

  handleAllChecked = (event) => {
    let { circulars } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < circulars.length; v++) {
        circulars[v].checked = 1;
      }
      this.setState({ selected_data: circulars });
    } else {
      for (var v = 0; v < circulars.length; v++) {
        circulars[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ circulars });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={25}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="subject-page-list">
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={6} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0, marginRight: "5px" }}>
                  Circulars
                </h3>
                <span
                  onClick={() => this.handleAddNewCircular()}
                  className="add_new_button"
                >
                  Add New
                </span>
              </div>
              <div>
                <ul className="subsubsub">
                  <li className="all">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: true,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: false,
                        })
                      }
                      className={
                        this.state.all_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                      aria-current="page"
                    >
                      All{" "}
                      <span className="count">
                        ({this.state.draft_count + this.state.published_count})
                      </span>
                    </span>{" "}
                    |
                  </li>
                  <li className="publish">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: true,
                          trash_selected: false,
                          draft_selected: false,
                        })
                      }
                      className={
                        this.state.published_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      Active{" "}
                      <span className="count">
                        ({this.state.published_count})
                      </span>
                    </span>{" "}
                    |
                  </li>
                  <li className="draft">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: true,
                        })
                      }
                      className={
                        this.state.draft_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      In-Active{" "}
                      <span className="count">({this.state.draft_count})</span>
                    </span>{" "}
                    |
                  </li>
                </ul>

                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    <th className="">Name</th>
                    <th className="">File</th>
                    <th className="textFontSize">Date of Creation</th>
                    <th>Status</th>
                  </tr>

                  <MDBTableBody>
                    {this.state.circulars.map((item, index) => {
                      return this.renderTableData(item, index);
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Circulars;
