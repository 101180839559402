import React,{Component} from 'react';
import '../SubjectPage/subjectPage.css';
import SidebarPage from '../Sidebar/sidebarPage';
import AdminNavbar from '../Navbar/adminNavbar';
import swal from 'sweetalert';

import { MDBTable, MDBTableBody} from 'mdbreact';
import { HalfCircleSpinner } from 'react-epic-spinners';
import ActivePNG from '../../../Assets/images/activestatus.png';
import InactivePNG from '../../../Assets/images/inactivestatus.png';
import Format from '../../../Assets/images/questionformat.png'
import '../../commonStyles.css'

class UploadQuestionFile extends Component {
  constructor( props ) {
      super( props );

      this.state = {
        loading:false,
        questions:null,
        file:null,
        verifyingFile:false,
        isLoaded:true
      };

  }
componentWillMount(){
     
    }

componentDidMount(){

    }



  handleFileUpload = () =>{
    this.setState({
      isLoaded:false, verifyingFile:true
    })

    let formBody = new FormData()
    formBody.append('file', this.state.file)
      fetch('https://www.vimasmiham.com/post-question-file',{
          method:'POST',
          body:formBody
      })
      .then((response)=>response.json())
      .then(responseJson=>{
          console.log(responseJson)

          if(responseJson.type == 'success'){
        
                fetch('https://www.vimasmiham.com/add-questions-to-table')
                .then(response=>response.json())
                .then(({message, type})=>{
                  if(type == "error"){
                    swal('Error', 'Questions Could not be uploaded', 'error')
                  }
                  else{
                    swal('Success', 'Questions Successfully uploaded', 'success')
                  }
                })
                .then(()=>window.location.reload())
        }
        else{
          swal('Error', responseJson.message, 'error')
          .then(
            ()=>fetch('https://www.vimasmiham.com/delete-csv-data')
            .then(()=>window.location.reload())
          )}          
       })
      .catch(err=>{
        console.log(err)
          // swal('Error', err, 'error')
      })
  }

  render() {
    if(this.state.loading){
      return(<div className="loadingSpin"><HalfCircleSpinner color="#3A7FCA"/></div>)
    } 
    else if(this.state.verifyingFile){
      return swal({
        title:'Verifying File', 
        text:'Please wait...', 
        type: 'info',
        icon:'info',
        buttons: false
       })
    }
   else if(this.state.isLoaded){
    return (
      <div id="subject-page-list">
      <AdminNavbar {...this.props}/>
      <div style={{display:'flex',flexDirection:'row'}}>
      <SidebarPage {...this.props} active={8}/>
          
      <div className='colDefault' style={{width:'85%',backgroundColor:'#F9F9F9'}}>
        <div className="page_heading"> <input type="file" id="upload-questions-file" className="d-block"  onChange={(event)=>this.setState({file:event.target.files[0]})}/>

                <button onClick={this.handleFileUpload} className={this.state.file?"add_new_button" :'d-none'}>Upload</button>
                <button onClick={()=>this.props.history.push('/admin/all-questions')} className="add_new_button mr-2 ml-auto">Back to all questions</button> </div>
          
        
           
      
            <div className="review-questions-uploaded">
            { this.state.questions !== null 
            ?
            <>
                <p className="px5 my-2 text-center"><strong>Here are the questions you uploaded</strong></p>
            <MDBTable  paging={true} striped className="pages_table" style={{backgroundColor:'#F9F9F9'}}>
              
              <tr style={{backgroundColor:'white'}}>
             
                   <th className="" >Title</th>
                   <th>Topic ID</th>
                   <th>Difficulty</th>
                   <th>Correct Answer</th>

              </tr>
                
              <MDBTableBody>
           
              {this.state.questions.map((item,index) => {
               return (     
                    <tr key={index}>
                    <td style={{width:'25%'}} className="tdName textFontSize" >
                        {item.question}
                    </td>
                    <td className="datefontsize">{item.topic_id}</td>
                    <td>{ item.difficulty==1?("Easy"):(item.difficulty==2?"Moderate":"Tough")}</td>
                    <td>{item.correct_answer}</td>
                    </tr>
                     )
                          })
                          
                     
                }
                        
              </MDBTableBody>
          </MDBTable>
          </>
    :
    <>
    <p className="px5 my-2 text-center"><strong>Please ensure the excel file is in the following format</strong></p>
    <div style={{width:'90%', overflowX:'scroll',border: '2px solid grey',margin: '0 auto',boxShadow: '0px 1px 12px 0px #b3b3b3'}}>
      <img src={Format} style={{width:'150%'}} />
    </div>
    </>
}
            </div>
       </div>        
     
      </div>
      </div>

  );
   }
  }
}

export default UploadQuestionFile;


