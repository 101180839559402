import React from "react";
import "./dashboard.css";
import { MDBCollapse, MDBIcon, MDBBtn } from "mdbreact";
import MetaTags from "react-meta-tags";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import DashboardBannerImage from "../../Assets/images/DashboardBannerImage.png";

import StarRatings from "react-star-ratings";

import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import { Link } from "react-router-dom";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      licentiate: [
        {
          image: require("../../Assets/images/adult-analyzing.png"),
          heading: "Principles of Insurance (IC-01)",
          name: "Rakesh Kumar",
          rating: "4.4",
          review: "1001",
          price: "11000",
          offer_price: "1000",
        },
        {
          image: require("../../Assets/images/AdobeStock.png"),
          heading: "Principles of General Insurance (IC-11)",
          name: "Rakesh Kumar",
          rating: "4.3",
          review: "2603",
          price: "11000",
          offer_price: "1000",
        },
        {
          image: require("../../Assets/images/helloquence.png"),
          heading: "Regulations of Insurance Business (IC-14) (IC-01)",
          name: "Rakesh Kumar",
          rating: "4",
          review: "2650",
          price: "11000",
          offer_price: "1000",
        },
        {
          image: require("../../Assets/images/iStock.png"),
          heading: "Health Insurance (IC-27)",
          name: "Rakesh Kumar",
          rating: "4.7",
          review: "2152",
          price: "11000",
          offer_price: "1000",
        },
        {
          image: require("../../Assets/images/adult-analyzing.png"),
          heading: "Principles of Insurance (IC-01)",
          name: "Rakesh Kumar",
          rating: "4.8",
          review: "2009",
          price: "11000",
          offer_price: "1000",
        },
      ],
      bannerImage: `${DashboardBannerImage}`,
      collapseID: -1,
      page_head: "",
      abc: [],
      vertical: {},
      all_exams: [],
      loading: true,
      exams: [],
      cart_items: [],
      cart: [],
      all_subjects: [],
      selected_exam: null,
      view_all: false,
      selected_subs: [],
    };
  }

  toggleCollapse(id, text, item) {
    console.log(id, this.state.collapseID);
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
        page_head: "",
      });
    } else {
      console.log("eeee");
      var subjects = [];
      let { all_subjects } = this.state;
      for (var v1 = 0; v1 < all_subjects.length; v1++) {
        for (var v2 = 0; v2 < JSON.parse(all_subjects[v1].exams).length; v2++) {
          if (JSON.parse(all_subjects[v1].exams)[v2].exam_id == item.exam_id) {
            subjects.push(all_subjects[v1]);
          }
        }
      }
      this.setState({
        selected_subs: subjects,
        collapseID: id,
        page_head: text,
      });
    }
  }
  componentWillMount() {
    this.getDetail();
  }

  componentDidMount() {
    // console.log(this.props);
  }

  getDetail() {
    //  var data = window.localStorage.getItem("selected_vertical");
    var col = window.localStorage.getItem("collapse");
    var userdata = window.localStorage.getItem("userdata");

    let form = new FormData();
    form.append("url", this.props.match.params.vertical);
    let data = null;
    fetch("https://vimasmiham.com/get_vertical", {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        data = responseJson[0];
        if (data != null) {
          if (data.status == 1) {
            this.setState(
              {
                vertical: data,
              },
              () => this.getExams()
            );
          } else {
            this.props.history.push("/");
          }

          if (userdata != null) {
            this.setState(
              {
                userdata: JSON.parse(userdata),
              },
              () => this.getCart()
            );
          }
        } else {
          this.props.history.push("/error");
        }
      });
  }

  getSubjects = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var subjects = [];
          let { exams } = this.state;
          this.setState(
            {
              all_subjects: responseJson,
              loading: false,
            },
            () => this.toggleCollapse(1, exams[0].exam_name, exams[0])
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  getVerticals(data) {
    var selected_verticals = [];
    for (var v = 0; v < data.length; v++) {
       if(data[v].status == 1){
        for (var v1 = 0; v1 < JSON.parse(data[v].verticals).length; v1++) {
          if (
            this.state.vertical.vertical_id ==
            JSON.parse(data[v].verticals)[v1].vertical_id
          ) {
            selected_verticals.push(data[v]);
          }
        }
       }
    }
    this.setState(
      {
        exams: selected_verticals.reverse(),
      },
      () => this.getSubjects()
    );
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  getExams = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              all_exams: responseJson.reverse(),
            },
            () => this.getVerticals(responseJson.reverse())
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  renderData() {
    console.log(this.state.exams);
    if (this.state.view_all == false) {
      return this.state.exams.map((item, index) => {
        return (
          <div style={{ backgroundColor: "" }} className="courseHeadMainDiv">
            <MDBBtn
              style={{ width: "100%" }}
              color=""
              onClick={() =>
                this.toggleCollapse(index + 1, item.exam_name, item)
              }
              className="solutionCollapseDiv"
            >
              <div className="vsCollapseMainDiv">
                <h4 className="dashCollapseHeading">{item.exam_name}</h4>
                <MDBIcon
                  className={
                    this.state.collapseID === index + 1
                      ? "rotateIcon"
                      : "rotateIcon2"
                  }
                  icon="chevron-down"
                />
              </div>
            </MDBBtn>
            <MDBCollapse
              id={index + 1}
              isOpen={this.state.collapseID}
              className="toggleDivColor"
            >
              {this.renderSubjectSlide()}
            </MDBCollapse>
          </div>
        );
      });
    } else {
      return <div>all</div>;
    }
  }

  renderNext() {
    return (
      <div className="slick-arrow">
        <img
          className="arrow-image"
          src={require("../../Assets/images/next1.png")}
        />
      </div>
    );
  }
  renderPrev() {
    return (
      <div className="slick-arrow">
        <img
          className="arrow-image"
          src={require("../../Assets/images/previous1.png")}
        />
      </div>
    );
  }
  renderSubjectSlide() {
    var settings = {
      dots: false,
      nextArrow: this.renderNext(),
      prevArrow: this.renderPrev(),
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 577,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider ref={(c) => (this.slider = c)} {...settings}>
        {this.renderSubjectData()}
      </Slider>
    );
  }
  // selectSubject(item, index) {
  //   window.localStorage.removeItem("selected_subject");
  //   window.localStorage.setItem("selected_subject", JSON.stringify(item));
  //   this.props.history.push(`/courses/${item.url}`);
  // }
  renderRatings(data) {
    if (data.ratings != null) {
      return (
        <StarRatings
          rating={4.5}
          starDimension="16px"
          starSpacing="2px"
          starRatedColor="#dbaa34"
          style={{ marginRight: 4 }}
        />
      );
    } else {
      return (
        <p className="reviewFont" style={{ fontWeight: "bold" }}>
          New Course
        </p>
      );
    }
  }
  renderSubjectData() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.selected_subs.length != 0) {
      let compulsory_subjects = [];
      let optional_subjects = [];
      console.log(this.state.selected_subs);
      this.state.selected_subs.forEach((subject) => {
        if (subject.subject_name.indexOf("Compulsory") >= 0) {
          compulsory_subjects.push(subject);
        } else {
          optional_subjects.push(subject);
        }
      });
      const compulsorySubjects = [...compulsory_subjects].sort((a, b) => {
        return a.subject_name.localeCompare(b.subject_name);
      });
      const optionalSubjects = [...optional_subjects].sort((a, b) => {
        return a.subject_name.localeCompare(b.subject_name);
      });

      const finalSubjects = [...compulsorySubjects, ...optionalSubjects];
      return finalSubjects.map((item, index) => {
        if (item.status == 1) {
          return (
            <div style={{ padding: "2%" }} className="cardMainDiv">
              <Link to={`/courses/${item.url}`}>
                <Card
                  // onClick={() => this.selectSubject(item, index)}
                  className="dashCardW1"
                >
                  <CardImg
                    className="img-fluid courseCardImg"
                    src={item.image}
                  />
                  <CardBody className="licCardBodyH">
                    <CardTitle className="CardMainTitleDiv">
                      <p className="dashCardTitleFont">{item.subject_name}</p>
                      <p
                        className="dashTextFont"
                        style={{ marginBottom: 0 }}
                      ></p>
                    </CardTitle>
                    <CardText>
                      <div className="licCardTopM">
                        <div className="dashboard-rating-container opacity-0">
                          {/* {this.renderRatings(item)}

                      <p className="reviewFont">
                        {item.ratings != null ? item.ratings : ""}
                        {item.total_ratings != null
                          ? `(${item.total_ratings})`
                          : ""} */}
                          {/* </p> */}
                        </div>
                        <div className="licCardFlex-end">
                          <text className="fullprice oldPriceFont">
                            &#8377; {item.old_cost}
                          </text>
                          <text
                            className="newPriceFont"
                            style={{ marginLeft: 0, color: "black" }}
                          >
                            &#8377; {item.cost}
                          </text>
                        </div>
                      </div>
                    </CardText>
                  </CardBody>
                </Card>
              </Link>
            </div>
          );
        }
      });
    } else {
      return (
        <div>
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    }
  }

  renderVName() {
    if (!this.state.view_all) {
      return (
        <h3 className="MarginLeft insuranceHeadFont">
          {this.state.vertical.vertical_name}
        </h3>
      );
    }
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div>
          <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            active="My Dashboard"
          />
          <div className="dashboard-main-body">
            <MetaTags>
              <title>{this.state.vertical.page_title_seo}</title>

              <meta
                id="dashboard"
                name="description"
                content={this.state.vertical.page_description_seo}
              />

              {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
            </MetaTags>
            {/* <div className='pageHeadFont'><text>Home > Exams > Insurance Institute Exams > {this.state.page_head}</text></div> */}

            <div className="dashboard-image-row row mb-4 mx-0">
              <img
                src={this.state.bannerImage}
                className="dashboard-banner-image"
              />
              <div className="blue-overlay"></div>
              <div className="dashboard-banner-text-container">
                <p className="dashboard-banner-text-one">
                  make your mark in the
                </p>
                <p className="dashboard-banner-text-two">INSURANCE INDUSTRY</p>
                <p className="dashboard-banner-text-three">
                  We are a leading insurance exam preparation portal where you
                  can learn and evaluate your learnings to gear up for
                  Professional and Promotion exams.
                </p>
              </div>
            </div>

            {this.renderVName()}

            <div className="display-dashbord-tabs">
              <div>{this.renderData()}</div>
            </div>

            <div
              className="container-c my-5 exams-content"
              dangerouslySetInnerHTML={{ __html: this.state.vertical.content }}
            />
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="mobView">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
