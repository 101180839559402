import React from "react";
import "./News.css";
import { MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";

const redirectButtons = {
  textTransform: "capitalize",
  borderRadius: "4px",
  fontSize: "16px",
  fontFamily: "Ubuntu-M",
  color: "#908b8b",
};

class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news_data: [],
      loading: false,
      startDate: new Date(),
    };
  }

  componentDidMount() {
    this.getNewsUpdate();
  }

  getNewsUpdate = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_news", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var response = responseJson.reverse();
          console.log(response, "news_data");
          this.setState({
            news_data: response,
            news_data_date: response.news_date,
            loading: false,
          });
        }),
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd;
  }

  commentMonth(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return monthNames[Number(dt.getMonth())];
  }

  commentYear(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dt.getFullYear().toString();
  }

  renderDate(item, v) {
    return (
      <div className="newsDateDisplay">
        <div className="newsDate bg-newsblue">
          <span className="newsDate_font">
            {this.commentdate(item.news_date)}
          </span>
          <span className="newsMonth_font">
            {this.commentMonth(item.news_date).toUpperCase()}
          </span>
        </div>
        <div className="newsYear">
          <span className="newsYear_font">
            {this.commentYear(item.news_date)}
          </span>
        </div>
        {/* <text className='rotateText'>Upcoming Exam</text> */}
      </div>
    );
  }

  renderNews() {
    if (this.state.news_data == null || this.state.news_data.length <= 0) {
      return (
        <h3 className="text-muted font-italic my-5 text-center">
          There are currently no news items to show!
        </h3>
      );
    }
    return this.state.news_data
      .sort(function (x, y) {
        return new Date(y.news_date) - new Date(x.news_date);
      })
      .map((item, index) => {
        if (item.status == 1) {
          console.log(item.news_date, "140");
          let now = new Date();
          let examDate = new Date(item.news_date);

          return (
            <div>
              <MDBCardBody className="newsDivPosition">
                <div className="news_justify_content">
                  <div className="NewsDivWidth">
                    <MDBCardTitle tag="h5">
                      {this.renderDate(item, index)}
                    </MDBCardTitle>
                  </div>

                  <div className="NewsDivWidth-title-description">
                    <MDBCardTitle
                      tag="h4"
                      style={{ color: "#303030" }}
                      className="newsHeadingFont"
                    >
                      {item.news_title.toUpperCase()}
                    </MDBCardTitle>

                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.news_description,
                        }}
                      ></div>
                    </MDBCardText>
                  </div>
                </div>{" "}
              </MDBCardBody>
            </div>
          );
        }
      });
  }

  renderExamNavigation() {
    return (
      <div
        style={{
          width: "100%",
        }}
        className="mt-2 align-items-center d-flex justify-content-between "
      >
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 8%" }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / News
        </p>
        <div>
          <FacebookShareButton
            url="https://www.vimasmiham.com/news.html"
            className="m-1"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton
            url="https://www.vimasmiham.com/news.html"
            className="m-1"
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton
            url="https://www.vimasmiham.com/news.html"
            className="m-1"
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            url="https://www.vimasmiham.com/news.html"
            className="m-1"
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <EmailShareButton
            url="https://www.vimasmiham.com/news.html"
            className="m-1"
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    );
  }
  selectExams(item, index) {}

  renderExamTab() {
    return (
      <div className="news-tab-btn-div d-flex">
        <button className="btn-news-half"></button>
        <button className="btn-news" onClick={() => this.selectExams()}>
          <span className="btn-news-text"> IRDAI</span>{" "}
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Industry</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Exams</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Others</span>
        </button>
        <button className="btn-news-half"></button>
      </div>
    );
  }

  render() {
    return (
      <div
        id="color-footer"
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Helmet>
          <title>News Updates | Vima Smiham</title>
        </Helmet>
        <div className="">
          <NewHeader
            {...this.props}
            backgroundColor="white"
            textcolor="#000"
            height="auto"
            width="100%"
          />
        </div>

        <div className="exam-news-main mb-5">
          <div className="w-10 d-flex justify-content-center News-heading">
            <h1 className="exam-news-heading-font">News</h1>
          </div>

          <div>{this.renderExamNavigation()}</div>

          <Quicklinks />

          <div className="d-none">{this.renderExamTab()}</div>

          <div>{this.renderNews()}</div>
        </div>

        <div className="windowView">
          <MainFooterPage />
        </div>

        <div className="vsMobView ">
          <StickyFooterPage {...this.props} />
        </div>
      </div>
    );
    // }
  }
}

export default News;
