import React from "react";
import Fade from "react-reveal/Fade";
import "./courseComponent.css";
import CourseThumb from "../../Assets/images/course-thumb.png";
import { MDBIcon } from "mdbreact";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import MetaTags from "react-meta-tags";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
class CourseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page_title: "",
      page_description: "",
      cart: [],
      all_items: [],
      myCourses: [
        {
          courseName: `Health Insurance (IC-27)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`
        },
        {
          courseName: `Human Resources Management (IC-90)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`
        },
        {
          courseName: `Question Bank`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`
        },
        {
          courseName: `Marketing and Public Relations (IC-88)`,
          purchaseDate: `27 July 2019`,
          expiryDate: `26 July 2020`,
          courseThumb: `${CourseThumb}`,
          url: `/`
        }
      ]
    };
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          //   console.log(responseJson,'cart')

          this.setState({
            cart: responseJson
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          console.log(error);
        })
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    // console.log(dt.getMonth()+1)
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (
      dd +
      "/" +
      monthNames[Number(dt.getMonth())] +
      "/" +
      dt.getFullYear().toString()
    );
  }
  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    this.setState({
      all_items: trimmedArray,
      loading: false
    });
  }
  checkDuplicates(originalArray) {
    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    console.log(all_courses, "iii");

    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);

          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  getOrders = async () => {
    this.setState({
      loading: true
    });
    let details = {
      user_id: this.state.userdata.user_id
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              items.push(data);
            }
          }
          console.log(items, "orig");
          this.checkDuplicates(items);
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  componentWillMount() {
    this.getDetails();
    this.getUserSEO();
  }

  getUserSEO = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_user_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "clients");
          this.setState(
            {
              page_title: responseJson[0].course_title_seo,
              page_description: responseJson[0].course_description_seo
            },
            () =>
              console.log(this.state, "eggggggggggggggggggggggggggggggggggg")
          );
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
          loading: true
        },
        () => {
          this.getOrders();
          this.getCart();
        }
      );
    } else {
      this.props.history.push("/");
    }
  }

  startTest(item) {
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem("selected_test", item.subject_id);
    window.localStorage.setItem("test_id", item.subject_id);
    this.props.history.push("start-test");
  }

  startExam(item) {
    window.localStorage.removeItem("examination_item");
    window.localStorage.setItem("examination_item", JSON.stringify(item));
    this.props.history.push({
      pathname: "/start-exam"
    });
  }

  buyCourseWOTest(item) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 0,
            subject_included: 1
          }
        ])
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: formBody
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false
              },
              () => this.selectiveTrigger()
            );
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  }

  buyTestWOSubject(item) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 1,
            subject_included: 0
          }
        ])
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          body: formBody
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false
              },
              () => this.selectiveTrigger()
            );
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  }

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  trigger() {
    this.getCart();
    setTimeout(() => {
      this.setState({
        showAdded: false
      });
    }, 2000);
  }

  isMobileDevice() {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  }

  renderButton(item, index) {
    console.log(item);
    if (item.subject_included == 0 && item.test_included == 1) {
      return (
        <div className="col-12 col-lg-5 col-sm-12 py-3 render-button-div">
          <button
            onClick={() => this.buyCourseWOTest(item)}
            className="start-learning btn-custom"
            style={{ color: "black", background: "white" }}
          >
            Buy Course
          </button>
          <button
            onClick={() => this.startTest(item)}
            className={`${JSON.parse(item.test_id).length == 0?'d-none':"start-learning btn-test btn-custom"}`}
            style={{ color: "white", marginTop: 15, marginLeft: 10 }}
          >
            {" "}
            Start Test
          </button>
        </div>
      );
    } else if (item.subject_included == 1 && item.test_included == 0) {
      return (
        <div
          className="col-12 col-lg-5 text-end py-3 py-md-auto  render-button-div"
          style={{
            flex: "0 0 53.333333%",
            flexDirection: "row",
            paddingRight: 0
          }}
        >
          <button
            onClick={() => this.buyTestWOSubject(item)}
            className={`${JSON.parse(item.test_id).length == 0?'d-none':"start-learning btn-custom"}`}
            style={{ color: "black", background: "white" }}
          >
            Buy Test
          </button>
          <button
            onClick={() => this.fetchSubject(item.subject_id, item)}
            className="start-learning btn-custom mr-2"
            style={{ color: "white", marginTop: 15, marginLeft: 10 }}
          >
            {" "}
            Start Learning
          </button>
        </div>
      );
    } else {
      return (
        <div
          className="col-12 col-lg-5 text-end py-3 py-md-auto pl-0 pl-sm-1 pr-0 pr-sm-3 render-button-div"
          style={{
            flex: "0 0 53.333333%",
            flexDirection: "row",
            paddingRight: 0
          }}
        >
          <button
            onClick={() => this.fetchSubject(item.subject_id, item)}
            className="start-learning btn-custom mr-2"
            style={{ color: "white" }}
          >
            {" "}
            Start Learning
          </button>
          <button
            onClick={() => this.startTest(item)}
            className="start-learning btn-test btn-custom"
            style={{ color: "white", marginTop: 15, marginLeft: 10 }}
          >
            {" "}
            Start Test
          </button>
        </div>
      );
    }
  }

  renderExamButton(item) {
    return (
      <div className="col-12 col-lg-5 col-sm-12 render-button-div">
        <button
          onClick={() => this.startExam(item)}
          className="start-learning btn-test btn-custom float-right"
          style={{
            color: "white",
            marginTop: 15,
            marginLeft: "auto",
            background: "#ff9900"
          }}
        >
          {" "}
          Start Exam
        </button>
      </div>
    );
  }
  renderCourses() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;
    console.log(this.state.all_items);

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      if (this.state.all_items.length == 0) {
        return (
          <div className="styles--sc-shopping-list--empty--1isYv">
            <MDBIcon
              style={{ color: "#e8e9eb", marginBottom: 20 }}
              size="5x"
              icon="book-open"
            />
            <p>Your course list is empty. Keep exploring to find a course!</p>
            <a
              className="btn "
              onClick={() => this.props.history.push("/")}
              data-purpose="keep-shopping-action"
              style={{ backgroundColor: "#0093d9", color: "white" }}
            >
              Keep Learning
            </a>
          </div>
        );
      } else {
        return this.state.all_items.map((item, index) => {
          console.log(item);
          if (item.exam_included == false) {
            return (
              <div key={index} className="col-12 course-row">
                <div className="row outer-div">
                  <div className="col-12 col-sm-12 col-12 col-lg-2 course-thumb-div">
                    <img className="course-thumb " src={item.image} />
                  </div>
                  <div className="col-12 col-lg-5 col-sm-12 col-12 my-course-name">
                    <p className="course-title">{item.subject_name}</p>
                    <p className="course-dates">
                      <span className="course-purchase">
                        Purchased on {this.commentdate(item.creation_time)}
                      </span>{" "}
                    </p>
                  </div>
                  {this.renderButton(item, index)}
                </div>
              </div>
            );
          } else if (item.exam_included == true) {
            return (
              <div key={index} className="col-12 course-row">
                <div className="row outer-div">
                  <div className="col-12 col-sm-12 col-12 col-lg-2 course-thumb-div">
                    <img className="course-thumb " src={item.image} />
                  </div>
                  <div className="col-12 col-lg-5 col-sm-12 col-12 my-course-name">
                    <p className="course-title">{item.exam_name}</p>
                    <p className="course-dates">
                      <span className="course-purchase">
                        Purchased on {this.commentdate(item.creation_time)}
                      </span>{" "}
                    </p>
                  </div>
                  {this.renderExamButton(item, index)}
                </div>
              </div>
            );
          } else {
            return (
              <div key={index} className="col-12 course-row">
                <div className="row outer-div">
                  <p>Unrecognised Item</p>
                </div>
              </div>
            );
          }
        });
      }
    }
  }

  fetchSubject = (subjectid, item) => {
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid, test_included: item.test_included })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(false));
    this.props.history.push({
      pathname: "/lessons",
      state: {
        id: subjectid
      }
    });
  };

  render() {
    const ismobile = this.isMobileDevice();
    console.log(ismobile);
    return (
      <div id="my-courses" className="bg-whitesmoke">
        <NewHeader
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
          active="My Courses"
        />

        <div className="container-c">
          <MetaTags>
            <title>{this.state.page_title}</title>

            <meta
              id="course"
              name="description"
              content={this.state.page_description}
            />
          </MetaTags>
          <div className="row bg-white account-components-border">
            <div className="col-12 col-md-3  px-0 account-nav-ipad">
              {this.state.userdata != null ? (
                <AccountNav {...this.props} active="My Courses" />
              ) : (
                <div></div>
              )}
            </div>
            <div className="col-12 col-md-12 col-sm-12 col-lg-9 ">
              <Fade>
                <div className="my-courses-container">
                  <h1 className="my-courses-title mt-4 mb-0 text-center text-md-left">
                    My Courses
                  </h1>
                  <p className="my-account-subtitle mb-4 text-center text-md-left">
                    {" "}
                    Let's start learning new things for exploring the new world.
                  </p>

                  <hr />
                  {this.renderCourses()}
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div style={{ height: "auto" }} className="windowView">
          <MainFooterPage {...this.props} />
        </div>
        <div className="mobView">
          <StickyFooterPage {...this.props} />
        </div>
      </div>
    );
  }
}

export default CourseComponent;
