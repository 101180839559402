import React from "react";
import { BrowserRouter, Route, Switch, HashRouter } from "react-router-dom";
import ReactGA from "react-ga";
import InlighterVein from "../Components/Admin/InlighterVein/InlighterVein";
import MyAccount from "../Components/My Account/AccountDetails";
import CourseComponent from "../Components/My Courses/CourseComponent";
import OrderHistory from "../Components/Order History/OrderHistory";
import Dashboard from "../Components/Dashboard/dashboardPage";
import MyCart from "../Components/My Cart/myCart";
import Scroll from "./Scroll";
import Homepage from "../Components/Homepage/Homepage";
import AllUserDetails from "../Components/Admin/UserDetail/userDetail";
import EditUserPage from "../Components/Admin/UserDetail/editUser/editUserDetail";
import AllVerticals from "../Components/Admin/Verticals/verticals";
import signUP from "../Components/SignUp/signUP";
import Exams from "../Components/Exams/Exams";
import ScrollAnimation from "react-animate-on-scroll";
import HomepageSEO from "../Components/Admin/HomepageSeo/HomepageSeo";
// import Homepage from '../../src/Components/Homepage';
import AddExam from "../Components/Admin/Exam/addExam";
import allExams from "../Components/Admin/Exam/allExams";
import editExam from "../Components/Admin/Exam/editExam";
import UserSEO from "../Components/Admin/UserDetail/UserSEO";
import Product from "../Components/Products/products";
import AddSubject from "../Components/Admin/AddSubject/AddSubject";
import SubjectPage from "../Components/Admin/SubjectPage/SubjectPage.js";
import EditSubject from "../Components/Admin/EditSubject/EditSubject";
import AddTax from "../Components/Admin/tax/addTax";
import allTax from "../Components/Admin/tax/allTax";
import editTax from "../Components/Admin/tax/editTax";
import OrderPage from "../Components/Admin/OrderPage/allOrders";
import AddPromoCode from "../Components/Admin/PromoCode/AddPromoCode/addPromoCode";
import AllPromoCode from "../Components/Admin/PromoCode/allPromoCode";
import editPromo from "../Components/Admin/PromoCode/editPromo";
import AllQuestions from "../Components/Admin/Questions/AllQuestions";
import AddQuestion from "../Components/Admin/Questions/AddQuestion";
import EditQuestion from "../Components/Admin/Questions/EditQuestion";
import AddTest from "../Components/Admin/TopicTests/AddTest.jsx";
import EditFooter from "../Components/Admin/Footer/editfooter";
import EvaluationTestPage from "../Components/Evaluation/evaluationTest";
import lessons from "../Components/lesson/lesson";
import Topic from "../Components/Admin/Topic/Topic";
import Lecture from "../Components/Admin/Lectures/Lectures";
import AddLecture from "../Components/Admin/Lectures/Addlectures";
import AddTopic from "../Components/Admin/Topic/AddTopic";
import Editlecture from "../Components/Admin/Lectures/Editlecture";
import EditTopic from "../Components/Admin/Topic/EditTopics";
import Feedback from "../Components/Feedback/feedback";
import startTest from "../Components/StarTest/startTest";
import newEvaluation from "../Components/Evaluation/newEvaluation";
import Topictest from "../Components/Admin/TopicTests/Test.jsx";
import EditTest from "../Components/Admin/TopicTests/EditTest";
import examinations from "../Components/Admin/Examination/examinations";
import addExamination from "../Components/Admin/Examination/addExamination";
import editExamination from "../Components/Admin/Examination/editExamination";
import addVerticals from "../Components/Admin/Verticals/addVerticals";
import editVerticals from "../Components/Admin/Verticals/editVerticals";
import UpcomingExams from "../Components/Admin/UpcomingExams/Exams";
import AddUpcomingExam from "../Components/Admin/UpcomingExams/AddExam";
import EditUpcomingExam from "../Components/Admin/UpcomingExams/EditExam";
import adminLogin from "../Components/Admin/adminLogIn/admin-login";
import ExamNews from "../Components/Exam-News/Exam-news";
import Faculty from "../Components/Admin/Faculty/Faculty";
import Addfaculty from "../Components/Admin/Faculty/Addfaculty";
import Editfaculty from "../Components/Admin/Faculty/Editfaculty";
import Testimonial from "../Components/Admin/Testimonials/Testimonials";
import Addtestimonial from "../Components/Admin/Testimonials/addTestimonials";
import Edittestimonial from "../Components/Admin/Testimonials/edittestimonials";
import ExamSeries from "../Components/ExamSeries/ExamSeries";
import StartExam from "../Components/StartExam/StartExam";
import Subscribers from "../Components/Admin/Subscribers/subscribers";
import Menu from "../Components/Admin/Menu/Menu";

import TopicQuiz from "../Components/Quiz/TopicQuiz";
import Quiz from "../Components/Admin/AdminQuiz/Quiz";
import AddQuiz from "../Components/Admin/AdminQuiz/AddQuiz";
import EditQuiz from "../Components/Admin/AdminQuiz/EditQuiz";
import AddFAQ from "../Components/Admin/FAQ/addFAQ";
import allFAQ from "../Components/Admin/FAQ/allFAQ";
import editFAQ from "../Components/Admin/FAQ/editFAQ";
import FAQ from "../Components/FAQs/FAQs";
import allDown from "../Components/Admin/Downloads/all_downloads";
import AddDown from "../Components/Admin/Downloads/add_download";
import editDown from "../Components/Admin/Downloads/edit_download";
import Downloads from "../Components/Downloads/Downloads";
import ContentPage from "../Components/ContentPages/ContentPages";
import SearchResults from "../Components/SearchResults/Search";
import Offer from "../Components/Admin/Offer/Offer";
import Reports from "../Components/Admin/Reports/Reports";
import aboutUsadmin from "../Components/Admin/AboutUs/AboutUs";
import Aboutus from "../Components/Aboutus/Aboutus";
//import AboutUs from '../Components/Admin/AboutUs/AboutUs';
import EditActsRegulations from "../Components/Admin/Acts_Regulations/EditActs_Regulations";
import AddActsRegulations from "../Components/Admin/Acts_Regulations/AddActs_Regulations";
import AllActsRegulations from "../Components/Admin/Acts_Regulations/Acts_Regulations";
import ActsandRegulations from "../Components/Acts_Regulations/Acts_Regulations";
import CaseLawPage from "../Components/CaseLaw/CaseLaw";

// import Faq from '../Components/Admin/faq/faq'
// import Addfaq from '../Components/Admin/faq/addfaq'
// import Editfaq from '../Components/Admin/faq/editfaq'
import "../App.css";
import AllFaculty from "../Components/Faculty/AllFaculty";
import Appraisal from "../Components/Appraisal/Appraisal";
import Edit_Appraisal from "../Components/Admin/Appraisal/Edit_appraisal";
import EditActuarial from "../Components/Admin/ActuarialStudies/EditActuarial";
import EditActs from "../Components/Admin/ActsAndRegs/EditActs";
import ActsAndRegs from "../Components/ActsAndRegulations/ActsAndRegs";
import ActuarialStudies from "../Components/ActuarialStudies/ActuarialStudies";
import Pages from "../Components/Admin/pages/pages";

import AddPages from "../Components/Admin/pages/add-pages";
import EditPages from "../Components/Admin/pages/edit-pages";

import AddPage from "../Components/AddPage/AddPage";
import AboutUs from "../Components/HomepageComponents/AboutUs/AboutUs";
import AdminNews from "../Components/Admin/News/News";
import AddNews from "../Components/Admin/News/AddNews";
import EditNews from "../Components/Admin/News/EditNews";
import News from "../Components/News/News";
import ReportsNew from "../Components/Admin/Reports/ReportsNew";
import Page404 from "../Components/Page404/Page404";
import sampleLesson from "../Components/lesson/sampleLesson";
import App from "../App";
import Caselaws from "../Components/Admin/CaseLaws/Caselaws";
import AddCaselaw from "../Components/Admin/CaseLaws/AddCaselaw";
import EditCaselaw from "../Components/Admin/CaseLaws/EditCaselaw";
import AddFunfacts from "../Components/Admin/Funfacts/AddFunfacts";
import Funfacts from "../Components/Admin/Funfacts/Funfacts";
import EditFunFacts from "../Components/Admin/Funfacts/EditFunFacts";
import UploadQuestionFile from "../Components/Admin/Questions/UploadQuestionFile";
import TDDs from "../Components/Admin/ThoughtofTheDay/TDDs";
import AddTDD from "../Components/Admin/ThoughtofTheDay/AddTDD";
import EditTDD from "../Components/Admin/ThoughtofTheDay/EditTDD";

import AdminRegulations from "../Components/Admin/Acts_Regulations/Regulations/Regulations";
import AdminAddRegulations from "../Components/Admin/Acts_Regulations/Regulations/AddRegulations";
import AdminEditRegulations from "../Components/Admin/Acts_Regulations/Regulations/EditRegulations";
import Regulations from "../Components/Acts_Regulations/Regulations/Regulations";
import AllCirculars from "../Components/Admin/Circulars/AllCirculars";
import AddCircular from "../Components/Admin/Circulars/AddCircular";
import EditCircular from "../Components/Admin/Circulars/EditCircular";
import Circulars from "../Components/Circulars/Circulars";
import PrivacyPolicy from "../Components/PrivacyPolicy/PrivacyPolicy";
import UpcomingExamSEO from "../Components/Admin/UpcomingExams/UpcomingExamSEO";
import swal from "sweetalert";
import AdminEvaluation from "../Components/Admin/Evaluation/evaluation";
import EvaluationDetail from "../Components/Admin/Evaluation/EvaluationDetail";
import InfoPage from "../Components/InfoPage/infoPage";
import InfoPages from "../Components/Admin/InfoPages/InfoPages";
import AddInfoPage from "../Components/Admin/InfoPages/AddInfoPage";
import EditInfoPage from "../Components/Admin/InfoPages/EditInfoPage";
import AdminLessons from "../Components/Admin/SubjectPage/adminLessons";
import AllTopicsQuestions from "../Components/Admin/Questions/TopicsQuestions";
import SearchQuestion from "../Components/Admin/Questions/SearchQuestion";
import QuestionsCount from "../Components/Admin/Questions/QuestionsCount";
import ShowPdf from "../Components/ShowPdf";
import startDemoTest from "../Components/StarTest/StartDemoTest";
import DemoQuestions from "../Components/Evaluation/DemoQuestions";
import Authorizer from "../Components/Authorizer"
import Captcha from '../Components/CaptchaComponent/Captcha'

const warningContainerStyles = {
  margin: "0 auto",
  backgroundColor: "rgba(0,0,0,0.9)",
  padding: "10px",
  position: "fixed",
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "999999",
};

const paraStyles = {
  fontFamily: "Ubuntu-M",
  fontSize: "22px",
  color: "#dedede",
  letterSpacing: "1px",
  textAlign: "center",
  lineHeight: "2.2",
};

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInstallMessage: false,
      isIe: false,
      name: ``,
      email: ``,
      phone: ``,
      message: ``,
      fadeIn: false,
    };
  }

  msieversion = () => {
    var isIe = false;
    var ua = window.navigator.userAgent;
    var old_ie = ua.indexOf("MSIE ");
    var new_ie = ua.indexOf("Trident/");

    if (old_ie > -1 || new_ie > -1) {
      console.log(this.state);
      this.setState({ isIe: true });
    }
  };

  renderWarningModal = () => {
    console.log("asdasdsad");
    if (this.state.isIe)
      return (
        <div style={warningContainerStyles}>
          <p style={paraStyles}>
            We see that you are using an outdated browser. In order to have the
            BEST browsing experience, we recommend using latest versions of
            Google Chrome, Mozilla Firefoz, Microsoft Edge, Safari or Opera
          </p>
        </div>
      );
  };

  componentDidMount() {
    this.msieversion();
  }
  dontshow() {
    window.localStorage.removeItem("show_message");
    window.localStorage.setItem("show_message", 0);
    this.setState({
      showInstallMessage: false,
    });
  }
  showUpdateBar() {
    if (this.state.showInstallMessage) {
      return (
        <ScrollAnimation
          animateOnce={false}
          animateIn="fadeIn"
          className="fadeIn toast2"
          animationOut="fadeOut"
          isVisible={true}
        >
          <div className="snack">
            To create app icon click on share button below then add to home
            screen <br />
            <text style={{ marginTop: 2 }}>Already done? &nbsp;</text>
            <text
              onClick={() => this.dontshow()}
              style={{ cursor: "pointer", textDecoration: "underline" }}
            >
              Click Here
            </text>
          </div>
        </ScrollAnimation>
      );
    }
  }
  componentWillMount() {
    var message = window.localStorage.getItem("show_message");
    if (message != null) {
      if (JSON.parse(message) == 1) {
        const isIos = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test(userAgent);
        };
        // Detects if device is in standalone mode
        const isInStandaloneMode = () =>
          "standalone" in window.navigator && window.navigator.standalone;
        console.log("isInStandaloneMode");
        // Checks if should display install popup notification:
        if (isIos() && !isInStandaloneMode()) {
          this.setState({ showInstallMessage: true });
        }
      }
    } else {
      const isIos = () => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
      };
      // Detects if device is in standalone mode
      const isInStandaloneMode = () =>
        "standalone" in window.navigator && window.navigator.standalone;
      console.log("isInStandaloneMode");
      // Checks if should display install popup notification:
      if (isIos() && !isInStandaloneMode()) {
        this.setState({ showInstallMessage: true });
      }
    }
  }

  initializeReactGA() {
    ReactGA.initialize("UA-158634601-1");
    ReactGA.pageview("/");
  }

  getUrl() {
    console.log(window.location.href, "window location");
  }

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "All fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        //console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://www.vimasmiham.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              //console.log(responseJson);
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                  loading: false,
                },
                () =>
                  swal(
                    "Thank you!",
                    "We will contact you shortly!",
                    "success"
                  ).then(() => this.closeContact())
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              //console.log(error);
            }),
        });
      }
    }
  };

  renderContactUs() {
    const url = window.location.pathname;
    if (
      url.indexOf("admin") >= 0 ||
      url.indexOf("order") >= 0 ||
      url.indexOf("newEvaluation") >= 0 ||
      url.indexOf("3gLyL9z") >= 0
    ) {
      return;
    }
    return (
      <div
        className={`contact-us-container ${
          this.state.fadeIn ? "fadein-contact" : ""
        }`}
        id="myContact"
      >
        <div className="contact-us-form">
          <h3
            className="Wcolor contact_header client_heading_font"
            style={{ fontSize: "2rem" }}
          >
            Share your Feedback
          </h3>
          <input
            placeholder="Name *"
            group
            type="text"
            validate
            className="px-2 py-1 border-rounded form mb-2 "
            value={this.state.name}
            onChange={(text) => this.setState({ name: text.target.value })}
          />
          <input
            placeholder="E-mail *"
            group
            type="text"
            validate
            error="wrong"
            style={{ border: "1px solid #776969" }}
            success="right"
            value={this.state.email}
            onChange={(e) => this.validateEmail(e.target.value)}
            className="contact_input_email  shadow-none px-2 py-1 border-rounded form mb-2 bg-white"
          />
          <input
            placeholder="Phone *"
            group
            type="phone"
            validate
            className="contact_input_text feedback_phone px-2 py-1 border-rounded form mb-2"
            value={this.state.phone}
            // maxlength="10" pattern="\d{10}"
            onChange={(text) => this.setState({ phone: text.target.value })}
          />
          <textarea
            rows="3"
            placeholder="Message/Feedback*"
            className="contact_input_text feedback_message px-2 py-1 border-rounded form mb-2"
            value={this.state.message}
            onChange={(text) => this.setState({ message: text.target.value })}
          />
          <button
            className="contact_button contact_send_btn "
            onClick={() => this.addContact()}
          >
            Send
          </button>
        </div>
        <div className="contact-us-button-container">
          <button
            className="contact-us-button"
            onClick={
              this.state.showContact === true
                ? () => {
                    this.closeContact();
                    this.setState({ showContact: false });
                  }
                : () => {
                    this.openContact();
                    this.setState({ showContact: true });
                  }
            }
          >
            {" "}
            Share Feedback
          </button>
        </div>
      </div>
    );
  }

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  openContact() {
    this.setState({ fadeIn: true });
  }

  closeContact() {
    this.setState({ fadeIn: false });
  }

  render() {
    return (
      <React.Fragment>
        {this.renderWarningModal()}
        <BrowserRouter style={{ overflow: "auto" }}>
          <Scroll>
            <Switch>
              {console.log(window.location.href, "window location")}
              {/* HOME */}
              <Route exact path="/" component={Homepage} />


              {/* CLIENT SIDE ROUTES */}
              <Route exact path="/captcha" component={Captcha} />
              <Route exact path="/case-laws" component={CaseLawPage} />
              <Route exact={true} path="/about-us" component={Aboutus} />
              <Route exact path="/newEvaluation" component={newEvaluation} />
              <Route exact path="/demo-questions" component={DemoQuestions} />
              <Route exact path="/feedback" component={Feedback} />
              <Route exact path="/my-account" component={MyAccount} />
              <Route exact path="/my-courses" component={CourseComponent} />
              <Route exact path="/purchase-history" component={OrderHistory} />
              <Route exact path="/my-cart" component={MyCart} />
              {/* <Route exact path="/dashboard" component={Dashboard} /> */}
              <Route exact path="/sign-up" component={signUP} />
              <Route exact path="/order-list" component={OrderPage} />
              <Route exact path="/upcoming-exams" component={ExamNews} />
              <Route exact path="/test-series" component={ExamSeries} />
              <Route exact path="/start-exam" component={StartExam} />
              <Route exact path="/start-quiz" component={TopicQuiz} />
              <Route exact path="/all-faculty" component={AllFaculty} />
              <Route exact path="/start-test" component={startTest} />
              <Route exact path="/start-demo-test" component={startDemoTest} />
              <Route exact path="/lessons" component={lessons} />
              <Route exact path="/faqs" component={FAQ} />
              <Route exact path="/downloads" component={Downloads} />
              <Route exact path="/content_page" component={ContentPage} />
              <Route exact path="/search-results" component={SearchResults} />
              <Route
                exact
                path="/acts_and_regulations"
                component={ActsandRegulations}
              />
              <Route exact path="/pdf/:id" component={ShowPdf} />
              <Route
                exact
                path="/appraisal_for_insurance"
                component={Appraisal}
              />
              <Route
                exact
                path="/actuarial_studies"
                component={ActuarialStudies}
              />
              <Route exact path="/latest-news" component={News} />
              <Route exact path="/add-page" component={AddPage} />

              {/* DYNAMIC ROUTES */}

              {/* Courses */}
              <Route
                exact
                path="/courses/:product"
                component={Product}
                render={(props) => <Product key={props.match.params.product} />}
              />

              {/* Verticals */}

              <Route
                exact
                path="/categories/:vertical"
                component={Dashboard}
                render={(props) => (
                  <Dashboard key={props.match.params.vertical} />
                )}
              />

              <Route
                exact
                path="/sample-lessons/:id"
                component={sampleLesson}
              />
              <Route
                exact
                path="/exams/:examurl"
                component={Exams}
                render={(props) => <Exams key={props.match.params.examurl} />}
              />

              <Route exact path="/regulations" component={Regulations} />

              <Route exact path="/circulars" component={Circulars} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                path="/blog"
                component={() => {
                  window.location.href = "https://vimasmiham.com/blog";
                  return null;
                }}
              />

              <Route
                exact
                path="/3gLyL9z/evaluation"
                component={AdminEvaluation}
              />

              <Route
                exact
                path="/3gLyL9z/evaluation/:type"
                component={EvaluationDetail}
              />


              {/* ADMIN ROUTES */}
              <Route exact path="/admin" component={adminLogin} />

              <Authorizer>
                <Route
                  exact
                  path="/admin/user-details"
                  component={AllUserDetails}
                />

                <Route exact path="/admin/add-subject" component={AddSubject} />
                <Route
                  exact
                  path="/admin/edit-subject"
                  component={EditSubject}
                />
                <Route exact path="/admin/edit-user" component={EditUserPage} />
                <Route exact path="/admin/verticals" component={AllVerticals} />
                <Route
                  exact
                  path="/admin/subject-list"
                  component={SubjectPage}
                />
                <Route exact path="/admin/add-exam" component={AddExam} />
                <Route exact path="/admin/all-exams" component={allExams} />
                <Route exact path="/admin/edit-exam" component={editExam} />
                <Route
                  exact
                  path="/admin/subscribers"
                  component={Subscribers}
                />
                <Route
                  exact
                  path="/admin/add-promocode"
                  component={AddPromoCode}
                />
                <Route exact path="/admin/promocode" component={AllPromoCode} />
                <Route
                  exact
                  path="/admin/edit-promocode"
                  component={editPromo}
                />
                <Route exact path="/admin/menu" component={Menu} />
                <Route exact path="/admin/add-tax" component={AddTax} />
                <Route exact path="/admin/all-tax" component={allTax} />
                <Route exact path="/admin/edit-tax" component={editTax} />
                <Route
                  exact
                  path="/admin/all-questions"
                  component={AllQuestions}
                />
                <Route
                  exact
                  path="/admin/search-question"
                  component={SearchQuestion}
                />
                <Route
                  exact
                  path="/admin/question-count"
                  component={QuestionsCount}
                />
                <Route
                  exact
                  path="/admin/all-topic-questions"
                  component={AllTopicsQuestions}
                />
                <Route
                  exact
                  path="/admin/add-question"
                  component={AddQuestion}
                />
                <Route
                  exact
                  path="/admin/edit-question"
                  component={EditQuestion}
                />
                <Route
                  exact
                  path="/admin/upload-questions"
                  component={UploadQuestionFile}
                />
                <Route exact path="/admin/test" component={Topictest} />
                <Route exact path="/admin/user-seo" component={UserSEO} />
                <Route
                  exact
                  path="/admin/examinations"
                  component={examinations}
                />
                <Route
                  exact
                  path="/admin/add-examination"
                  component={addExamination}
                />
                <Route
                  exact
                  path="/admin/edit-examination"
                  component={editExamination}
                />
                <Route
                  exact
                  path="/admin/add-verticals"
                  component={addVerticals}
                />
                <Route
                  exact
                  path="/admin/edit-verticals"
                  component={editVerticals}
                />
                <Route
                  exact
                  path="/admin/upcoming-exams"
                  component={UpcomingExams}
                />
                <Route
                  exact
                  path="/admin/add-exam-date"
                  component={AddUpcomingExam}
                />
                <Route
                  exact
                  path="/admin/edit-exam-date"
                  component={EditUpcomingExam}
                />
                <Route exact path="/admin/add-test" component={AddTest} />
                <Route exact path="/admin/edit-test" component={EditTest} />
                <Route exact path="/admin/edit-footer" component={EditFooter} />
                <Route exact path="/admin/add-topic" component={AddTopic} />
                <Route exact path="/admin/all-topics" component={Topic} />
                <Route exact path="/admin/edit-topic" component={EditTopic} />
                <Route exact path="/admin/lecture" component={Lecture} />
                <Route exact path="/admin/add-lecture" component={AddLecture} />
                <Route
                  exact
                  path="/admin/edit-lecture"
                  component={Editlecture}
                />
                <Route exact path="/admin/faculties" component={Faculty} />
                <Route exact path="/admin/add-faculty" component={Addfaculty} />
                <Route
                  exact
                  path="/admin/edit-faculty"
                  component={Editfaculty}
                />
                <Route
                  exact
                  path="/admin/testimonials"
                  component={Testimonial}
                />
                <Route
                  exact
                  path="/admin/add-testimonial"
                  component={Addtestimonial}
                />

                <Route
                  exact
                  path="/admin/edit-testimonial"
                  component={Edittestimonial}
                />
                <Route exact path="/admin/add-faq" component={AddFAQ} />
                <Route exact path="/admin/all-faqs" component={allFAQ} />
                <Route exact path="/admin/edit-faq" component={editFAQ} />
                <Route exact path="/admin/all-downloads" component={allDown} />
                <Route exact path="/admin/add-download" component={AddDown} />
                <Route exact path="/admin/edit-download" component={editDown} />
                <Route exact path="/admin/quiz" component={Quiz} />
                <Route exact path="/admin/add-quiz" component={AddQuiz} />
                <Route exact path="/admin/edit-quiz" component={EditQuiz} />
                <Route exact path="/admin/about-us" component={aboutUsadmin} />
                <Route exact path="/admin/offer" component={Offer} />
                <Route exact path="/admin/dashboard" component={Reports} />
                <Route
                  exact
                  path="/admin/edit_actuarial"
                  component={EditActuarial}
                />
                <Route
                  exact
                  path="/admin/edit_acts_and_regs"
                  component={EditActs}
                />
                <Route
                  exact
                  path="/admin/InlighterVein"
                  component={InlighterVein}
                />
                <Route
                  exact
                  path="/admin/add-acts-regulations"
                  component={AddActsRegulations}
                />
                <Route
                  exact
                  path="/admin/edit-acts-regulations"
                  component={EditActsRegulations}
                />
                <Route
                  exact
                  path="/admin/acts-regulations"
                  component={AllActsRegulations}
                />
                <Route exact path="/admin/pages" component={Pages} />
                <Route exact path="/admin/add-pages" component={AddPages} />
                <Route exact path="/admin/edit-pages" component={EditPages} />
                <Route exact path="/admin/all-news" component={AdminNews} />
                <Route exact path="/admin/add-news" component={AddNews} />
                <Route exact path="/admin/edit-news" component={EditNews} />
                <Route
                  exact
                  path="/admin/homepageseo"
                  component={HomepageSEO}
                />
                <Route exact path="/admin/reports" component={ReportsNew} />

                <Route exact path="/admin/case-laws" component={Caselaws} />
                <Route
                  exact
                  path="/admin/add-case-law"
                  component={AddCaselaw}
                />
                <Route
                  exact
                  path="/admin/edit-case-law"
                  component={EditCaselaw}
                />
                <Route exact path="/admin/fun-facts" component={Funfacts} />
                <Route
                  exact
                  path="/admin/add-fun-facts"
                  component={AddFunfacts}
                />
                <Route
                  exact
                  path="/admin/edit-fun-facts"
                  component={EditFunFacts}
                />
                <Route exact path="/admin/all-tdds" component={TDDs} />
                <Route exact path="/admin/add-tdd" component={AddTDD} />
                <Route exact path="/admin/edit-tdd" component={EditTDD} />
                <Route
                  exact
                  path="/admin/regulations"
                  component={AdminRegulations}
                />
                <Route
                  exact
                  path="/admin/add-regulation"
                  component={AdminAddRegulations}
                />
                <Route
                  exact
                  path="/admin/edit-regulation"
                  component={AdminEditRegulations}
                />
                <Route
                  exact
                  path="/admin/upcoming-exam-seo"
                  component={UpcomingExamSEO}
                />

                {/* Circulars  */}
                <Route exact path="/admin/circulars" component={AllCirculars} />
                <Route
                  exact
                  path="/admin/add-circular"
                  component={AddCircular}
                />
                <Route
                  exact
                  path="/admin/edit-circular"
                  component={EditCircular}
                />

                <Route
                  exact
                  path="/admin/edit-circular"
                  component={EditCircular}
                />
                <Route
                  exact
                  path="/admin/evaluation"
                  component={AdminEvaluation}
                />
                <Route
                  exact
                  path="/admin/evaluation/:type"
                  component={EvaluationDetail}
                />

                <Route exact path="/admin/info-pages" component={InfoPages} />

                <Route
                  exact
                  path="/admin/add-info-page"
                  component={AddInfoPage}
                />
                <Route
                  exact
                  path="/admin/edit-info-page"
                  component={EditInfoPage}
                />

                <Route
                  exact
                  path="/admin/lessons/:id"
                  component={AdminLessons}
                />
              </Authorizer>

              
              <Route
                exact
                path="/:info_page"
                component={InfoPage}
                render={(props) => (
                  <InfoPage key={props.match.params.info_page} />
                )}
              />

              <Route exact path="/*" component={Page404} />


              {/* Page 404 */}

              <Route exact path="/error" component={Page404} />
            </Switch>
          </Scroll>
          {this.showUpdateBar()}
          {this.renderContactUs()}
        </BrowserRouter>
      </React.Fragment>
    );
  }
}
export default Routes;
