import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import { MDBIcon } from "mdbreact";

import AdminNavbar from "../Navbar/adminNavbar";
import "../Lectures/Addlecture.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import CKEditor from "ckeditor4-react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class editVerticals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      vertical_name: " ",
      verticalsThumbnail: null,
      verticalsThumbnailUrl: "",
      coming: "",
      verticalSEOTitle: "",
      verticalSEODescription: "",
      editedThumbnail: null,
      filename: "",
      url:``,
      content:``
    };
  }
  componentWillMount() {
    console.log(JSON.parse(window.localStorage.getItem("vertical_item")));
    this.setState({ loading: true }, () => this.getVerticalDetails());
  }

  getVerticalDetails = async () => {
    var vertical_item = JSON.parse(
      window.localStorage.getItem("vertical_item")
    );
    console.log(vertical_item, "Vertical");
    if (vertical_item.mark_coming_soon == 1) {
      this.setState(
        {
          vertical_id: vertical_item.vertical_id,
          vertical_name: vertical_item.vertical_name,
          verticalsThumbnail: vertical_item.pictogram,
          verticalsThumbnailUrl: vertical_item.pictogram,
          coming: true,
          url:vertical_item.url,
          verticalSEOTitle: vertical_item.page_title_seo,
          verticalSEODescription: vertical_item.page_description_seo,
          status: vertical_item.status,
          content:vertical_item.content,

          filename:
            vertical_item.pictogram == null
              ? ""
              : vertical_item.pictogram.substr(
                  28,
                  vertical_item.pictogram.length
                ),
          loading: false,
        },
        () => console.log(this.state, "rwgwgewgewgewgewgwegegwegewgew")
      );
    } else {
      this.setState(
        {
          vertical_id: vertical_item.vertical_id,
          vertical_name: vertical_item.vertical_name,
          verticalsThumbnail: vertical_item.pictogram,
          verticalsThumbnailUrl: vertical_item.pictogram,
          url:vertical_item.url,
          coming: false,
          verticalSEOTitle: vertical_item.page_title_seo,
          verticalSEODescription: vertical_item.page_description_seo,
          status: vertical_item.status,
          content:vertical_item.content,
          filename:
            vertical_item.pictogram == null
              ? ""
              : vertical_item.pictogram.substr(
                  28,
                  vertical_item.pictogram.length
                ),
          loading: false,
        },
        () => console.log(this.state, "rwgwgewgewgewgewgwegegwegewgew")
      );
    }
  };

  editVerticals = () => {
    console.log(this.state.topicname);
    if (this.state.vertical_name.length == 0) {
      swal("Check topic name!", "Please enter valid name", "error");
    } else {
      if (this.state.editedThumbnail == null) {
        let comingDB;
        if (this.state.coming == true) comingDB = 1;
        else comingDB = 0;
        let details = {
          vertical_id: this.state.vertical_id,
          vertical_name: this.state.vertical_name,
          page_title_seo: this.state.verticalSEOTitle,
          page_description_seo: this.state.verticalSEODescription,
          mark_coming_soon: comingDB,
          url:this.state.url,
          content:this.state.content
        };

        console.log(details, "add-without photo");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_vertical", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson);

              this.props.history.push("/admin/verticals");
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      } else {
        let formData = new FormData();

        formData.append("vertical_id", this.state.vertical_id);
        formData.append("vertical_name", this.state.vertical_name);
        formData.append("file", this.state.editedThumbnail);
        formData.append("filename", this.state.filename);
        formData.append("page_title_seo", this.state.verticalSEOTitle);
        formData.append(
          "page_description_seo",
          this.state.verticalSEODescription
        );
        formData.append("status", 1);
        formData.append("content", this.state.content)

        for (let key of formData.entries()) {
          console.log(key[0] + ",with photo " + key[1]);
        }

        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_vertical", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson, "QWERTy");
              swal("Success", "Topic succesfullly added", "success").then(
                (value) => {
                  this.props.history.push("/verticals");
                }
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      }
    }
  };

  _renderThumbnailVerticalImageSection() {
    let imageUrl = "";

    if (this.state.verticalsThumbnail == null) {
      if (this.state.editedThumbnail == null) {
        return (
          <div className="col-10" style={{ padding: "0%" }}>
            <label
              style={{
                marginRight: "0%",
                width: "100%",
                fontWeight: "bold",
              }}
              htmlFor="defaultFormRegisterNameEx"
              className="textFontSize"
            >
              Verticals Thumbnail:
            </label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                onChange={(event) =>
                  this.setState({
                    editedThumbnail: event.target.files[0],
                    verticalsThumbnailUrl: URL.createObjectURL(
                      event.target.files[0]
                    ),
                  })
                }
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                Choose file
              </label>
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              margin: "0 10px",
              width: "45%",
            }}
            className="col-3"
          >
            <label
              style={{
                marginRight: "0%",
                width: "100%",
                fontWeight: "bold",
              }}
              htmlFor="defaultFormRegisterNameEx"
              className="textFontSize"
            >
              Verticals Thumbnail:
            </label>
            <img
              src={this.state.verticalsThumbnailUrl}
              className="promo-pic"
              style={{ margin: "0", width: "80%" }}
              alt="news_image"
            />
            <MDBIcon
              className="cross_icon"
              onClick={() => this.setState({ editedThumbnail: null })}
              icon="times-circle"
              style={{ position: "absolute", top: "65px", left: "-5px" }}
            />
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "0 10px",
            width: "100%",
          }}
          className="col-3"
        >
          <label
            style={{
              marginRight: "0%",
              width: "100%",
              fontWeight: "bold",
            }}
            htmlFor="defaultFormRegisterNameEx"
            className="textFontSize"
          >
            Verticals Thumbnail:
          </label>
          <img
            src={this.state.verticalsThumbnail}
            className="promo-pic"
            style={{ margin: "0", width: "80%" }}
            alt="news_image"
          />
          <MDBIcon
            className="cross_icon"
            onClick={() => this.setState({ verticalsThumbnail: null })}
            icon="times-circle"
            style={{ position: "absolute", top: "65px", left: "-5px" }}
          />
        </div>
      );
    }
  }

  renderThumbnailVerticalImageSection() {
    let imageUrl = "";

    if (this.state.verticalsThumbnail == null) {
      if (this.state.editedThumbnail == null) {
        return (
          <div className="col-12 d-flex align-items-center subject-thumbnail-container">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="subject-labels"
            >
              Thumbnail:
            </label>

            <input
              type="file"
              className="thumbnail-file-input d-block"
              id="inputGroupFile01"
              accept="image/*"
              aria-describedby="inputGroupFileAddon01"
              onChange={(event) =>
                this.setState({
                  editedThumbnail: event.target.files[0],
                  verticalsThumbnailUrl: URL.createObjectURL(
                    event.target.files[0]
                  ),
                })
              }
            />
          </div>
        );
      } else {
        return (
          <div
            className="col-12 subject-thumbnail-container"
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="image-label subject-labels h-100"
            >
              Thumbnail:
            </label>
            <div className="position-relative">
              <img
                src={this.state.verticalsThumbnailUrl}
                className=""
                style={{ margin: "10px 0px", width: "100px" }}
                alt="news_image"
              />
              <MDBIcon
                className="cross_icon"
                onClick={() => this.setState({ editedThumbnail: null })}
                icon="times-circle"
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "-5px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels h-100"
          >
            Thumbnail:
          </label>
          <div className="position-relative">
            <img
              src={this.state.verticalsThumbnail}
              className=""
              style={{ margin: "10px 0px", width: "100px" }}
              alt="news_image"
            />
            <MDBIcon
              className="cross_icon"
              onClick={() => this.setState({ verticalsThumbnail: null })}
              icon="times-circle"
              style={{
                position: "absolute",
                top: "20px",
                right: "-5px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      );
    }
  }

  renderVerticalURL() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          URL/Link
        </label>
        <input
          value={this.state.url}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) =>
            this.setState({ url: e.target.value })
          }
        />
      </div>
    );
  }

  renderVerticalNameSection() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.vertical_name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) => this.setState({ vertical_name: e.target.value })}
        />
      </div>
    );
  }
  renderSEOTitle() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          SEO Title:
        </label>
        <input
          value={this.state.verticalSEOTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) => this.setState({ verticalSEOTitle: e.target.value })}
        />
      </div>
    );
  }

  renderSEODescription() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          SEO Description:
        </label>
        <input
          value={this.state.verticalSEODescription}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(e) =>
            this.setState({ verticalSEODescription: e.target.value })
          }
        />
      </div>
    );
  }
  comingSoon() {
    this.setState({ coming: !this.state.coming }, () =>
      console.log(this.state.coming)
    );
  }
  renderComingSoon() {
    console.log(this.state.coming);
    return (
      <div className="col-12 ">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Coming Soon:
        </label>
        {this.state.coming == true ? (
          <input
            type="checkbox"
            name="coming_soon"
            value="coming_soon"
            style={{ width: "5%" }}
            onClick={() => this.comingSoon()}
            checked
          />
        ) : (
          <input
            type="checkbox"
            name="coming_soon"
            value="coming_soon"
            style={{ width: "5%" }}
            onClick={() => this.comingSoon()}
          />
        )}
      </div>
    );
  }


  
  onEditorChange(e) {
    this.setState({content:e.editor.getData()})
  }

  renderVerticalContent(){
    return (
      <div className="col-12  d-flex">
      <label
        htmlFor="defaultFormRegisterNameEx"
        className="image-label subject-labels h-100"
      >
        Content:
      </label>
      <CKEditor
        data={this.state.content}
        onChange={(e) => this.onEditorChange(e)}
        className="w-75"
      />
    </div>
    )
  }
  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={1} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/verticals")
                        }
                      >
                        All Verticals
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.editVerticals()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Vertical</h3>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderVerticalNameSection()}
                        {this.renderThumbnailVerticalImageSection()}
                        {this.renderSEOTitle()}
                        {this.renderSEODescription()}
                        {this.renderVerticalURL()}
                        {this.renderComingSoon()}
                        {this.renderVerticalContent()}
                      </div>
                    </div>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default editVerticals;
