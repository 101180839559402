import React, { Fragment } from "react";
import swal from "sweetalert";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import { Link } from "react-router-dom";

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: "",
      mainTitle: "",
      faculties: [],
      mainText: "",
      objectiveImage: ``,
      objectiveText: "",
      objectiveTitle: "",
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      visionTitle: "",
      visionText: "",
      visionImage: ``,
      cart: [],
      loading: false,
    };
  }

  componentDidMount() {}

  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p className="productMainFirstText">
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / Privacy Policy
        </p>
      </div>
    );
  }

  renderPrivacyPolicy = () => {
    return (
      <React.Fragment>
        <div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "center",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span
                  style={{
                    fontSize: 19,
                    lineHeight: "150%",

                    color: "black",
                  }}
                >
                  Vima Smiham – PRIVACY POLICY
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: "11.25pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              Vima Smiham/ (or “The Company”) is a
            </span>
            <span style={{ color: "black" }}>n</span>
            <span style={{ color: "black" }}>
              &nbsp;online educational website that seeks to provide quality
              content for preparation of insurance promotional and professional
              exams through comprehensive content curated by some of the
              seasoned professionals of the industry.&nbsp;
            </span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: "11.0pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              This privacy policy ("<strong>Privacy Policy</strong>") explains
              how we undertake the collection, use, storage, processing,
              disclosure and transfer of the User’s Information (defined below)
              when the User utilizes the&nbsp;
            </span>
            <span>
              <a href="https://www.vimasmiham.com/">
                <span style={{ color: "black" }}>Website</span>
              </a>
              <span style={{ color: "black" }}>, App and/or the Services</span>
            </span>
            <span style={{ color: "black" }}>
              &nbsp;(defined below) or any other offline sources of information
              provided by Vima Smiham. The Privacy Policy applies to current and
              former Users of the Service.
            </span>
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: "11.0pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              Vima Smiham is committed to protecting the User’s privacy and
              personal information and with this in mind we have created this
              Privacy Policy.&nbsp;
            </span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: "11.25pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              The User’s use of and access to the Services is subject to this
              Privacy Policy, Terms of Service and Refund Policy.
            </span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "decimal",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>DEFINITIONS</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.25pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "decimal" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  “<strong>Application</strong>”&nbsp;or&nbsp;“
                  <strong>App”</strong> means the software application, website,
                  interface or any other means the User utilizes to access the
                </span>
                <span style={{ fontFamily: '"Mangal",serif' }}>&nbsp;</span>
                <span style={{ color: "black" }}>Vima Smiham</span>
                <span style={{ fontFamily: '"Mangal",serif', color: "black" }}>
                  &nbsp;
                </span>
                <span style={{ color: "black" }}>database.</span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.25pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <ol className="decimal_type" style={{ listStyleType: "decimal" }}>
            <li>
              <span style={{ color: "black" }}>
                “<strong>User</strong>”&nbsp;means any individual or business
                entity including but not limited to subscribers, visitors,
                tutors that has subscribed to or utilizes the
              </span>
              <span style={{ color: "black" }}>&nbsp;</span>
              <span style={{ color: "black" }}>Vima Smiham</span>
              <span style={{ color: "black" }}>&nbsp;</span>
              <span style={{ color: "black" }}>
                service, either through its App or website.&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
                <br /> <br />
                &nbsp;
              </span>
            </li>
            <li>
              <span style={{ color: "black" }}>
                “<strong>User Data</strong>”&nbsp;means information (including
                Personal Information) relating to a User, including order
                information, payment information, or account information.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.25pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "decimal" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  “<strong>End User</strong>” means any person, including
                  customers, subscribers, visitors whose personal information,
                  including sensitive personal information would be stored in
                  Vima Smiham’s database.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.25pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "decimal" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  “<strong>Personal Information</strong>”&nbsp;means any
                  information relating to a natural person who can be
                  identified, directly or indirectly, in particular by reference
                  to an identifier such as a name, a phone number, an email
                  address, an identification number, location data, an online
                  identifier, or any other information specific to that natural
                  person.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.25pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <ol className="decimal_type" style={{ listStyleType: "decimal" }}>
            <li>
              <span style={{ color: "black" }}>
                “<strong>Sensitive Personal Information</strong>
              </span>
              <span style={{ color: "black" }}>”</span>
              <span style={{ color: "black" }}>
                &nbsp;means Personal Information that can reveal racial or
                ethnic origin. Sensitive Personal Information also includes data
                that can uniquely identify a natural person, payment information
                (including payment card or bank account numbers), and government
                identifiers that uniquely identify a natural person such as a
                GST number.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp;&nbsp;
                <br /> <br />
                &nbsp;
              </span>
            </li>
            <li>
              <strong>
                <span style={{ color: "black" }}>“Service</span>
              </strong>
              <span style={{ color: "black" }}>
                ”&nbsp;means any services offered by the Company,
              </span>
              <span style={{ color: "black" }}>&nbsp;</span>
              <span style={{ color: "black" }}>Vima Smiham</span>
              <span style={{ color: "black" }}>&nbsp;</span>
              <span style={{ color: "black" }}>
                related entities, provided through means including but not
                limited to&nbsp;
              </span>
              <span style={{ color: "black" }}>Vima Smiham&nbsp;</span>
              <span style={{ color: "black" }}>software applications</span>
            </li>
          </ol>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <span style={{ color: "black" }}>
                    C<u>ONSENT</u>
                  </span>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  By using the Services or by otherwise giving us your
                  information, the User (including <strong>End User</strong>)
                  will be deemed to have read, understood and agreed to the
                  practices and policies outlined in this privacy policy and
                  thus consents to be bound by the privacy policy.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              marginLeft: ".5in",
              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The User hereby consents to the collection, storage,
                  processing, disclosure and transfer of User’s Personal
                  Information, including Sensitive Personal Information in
                  accordance with the provisions of this Privacy Policy.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  If the User uses the services on behalf of someone other than
                  himself, including but not limited to minor children or an
                  entity, the User represents that he/she is authorised by such
                  individual or entity to (i) accept this privacy policy on such
                  individual’s or entity’s behalf, and (ii) consent on behalf of
                  such individual or entity to our collection, use and
                  disclosure of such individual’s or entity’s information as
                  described in this privacy policy.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>
                      CHANGES TO THE PRIVACY POLICY
                    </span>
                  </u>
                </strong>
                <span style={{ color: "black" }}>&nbsp;</span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  We reserve the right to change, modify, add or delete portions
                  of the terms of this Privacy Policy, at our sole discretion,
                  at any time.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  If the User does not agree with this privacy policy at any
                  time, the User is requested to not use any of the services or
                  give us any of your information, for any continued use would
                  be deemed to be implied consent.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>DATA COLLECTION</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Kind of data: The data submitted by the Users can be personal
                  and financial information including name, address, contact
                  number, gender, demographic details, etc and can be personal
                  information including sensitive personal information.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              marginLeft: ".5in",
              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Information posted by Users: The company collects information
                  the Users post in a public space on our website or on a
                  third-party social media site belonging to the company.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>
                      PURPOSE AND USE OF DATA COLLECTED
                    </span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The company makes sure that the data collected from the Users
                  is only used for purposes of maintaining a database of
                  information for ready access and effective diagnosis and
                  treatment of the User/End User.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Acknowledging the sensitive nature of the information, Vima
                  Smiham ensures that the information so collected by various
                  sources is stored in a secure and encrypted format. Vima
                  Smiham does not access, process, analyse, sell or share this
                  information and the information so collected is totally
                  confidential and can be only accessed by the User after
                  successfully using the login credentials set by them.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The company shall use contact information and other similar
                  data collected to approach the User via. calls, SMS, emails
                  and/or any other means of communication
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>OTHER RELEVANT DATA</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The company may collect information about the IP address and
                  server used by the Users.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The company may get basic information about any person from
                  third parties like social media websites and data reservoirs.
                  This data could include the name and email address of the
                  Users.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>
                      SHARING OF INFORMATION WITH THIRD-PARTIES
                    </span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The Company will share the database with third parties with
                  whom the company has tie-ups with, these third parties may be
                  located outside of India.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <ol className="decimal_type" style={{ listStyleType: "undefined" }}>
            <li>
              <span style={{ color: "black" }}>
                The Company will share data to respond to a court order or
                subpoena. They may also share data if a government agency or
                investigatory body requests or if and when they happen to be
                investigating a potential fraud.&nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;&nbsp;
                <br /> <br />
                &nbsp;
              </span>
            </li>
            <li>
              <span style={{ color: "black" }}>
                The Company may share its data with any successor to all or part
                of their business.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The Company assures that only User or account information of
                  the Users, shall be shared and no sensitive personal or
                  medical information shall be disclosed to any third-party.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>
                      THIRD-PARTY WEBSITES&nbsp;
                    </span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  If the User clicks on one of the links to third party websites
                  provided on the Vima Smiham Application or otherwise, the User
                  may be taken to websites that the Company does not
                  control.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  This policy does not apply to the privacy practices of those
                  websites. In this regard, kindly read the privacy policy of
                  other websites carefully. Thereby, the Company is not in any
                  way responsible for these third-party websites.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>COOKIES</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Third party websites may place their own cookies or other
                  files on the Users' computer, collect data or solicit personal
                  information from the Users, for which Vima Smiham is not
                  responsible or liable.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Vima Smiham through its Application may use cookies, web
                  beacons (also known as action tags or single-pixel gifs), and
                  other technologies (collectively known as “cookies”).&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Cookies enable us to learn about what ads you see, what ads
                  you click, and other actionns that the User may take on The
                  Company’s Application.&nbsp;
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The Company will never associate the User’s interaction with
                  unaffiliated sites with your identity in providing you with
                  interest-based ads.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  The Company do not provide any personal information to
                  advertisers or to third party sites that display our
                  interest-based ads. However, advertisers and other
                  third-parties (including the ad networks, ad-serving
                  companies, and other service providers) may assume that Users
                  who interact with or click on a personalised ad or content are
                  part of the group that the ad or content is directed towards.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Also, some third-parties may provide us information about
                  Users or potential Users from offline and online sources that
                  The Company may use to provide more relevant and useful
                  advertising.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  In some situations, the advertisers or advertisement companies
                  may technically work to serve the ads that appear on our sites
                  directly to your browser. We do not have access to or control
                  over cookies or other features that advertisers and third
                  party sites may use, and the information practices of these
                  advertisers and third party websites are not covered by our
                  Privacy Policy. Please contact them directly for more
                  information about their privacy practices.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>ADVERTISEMENTS</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  Vima Smiham may display interest-based advertisements/ads,
                  using information you make available to us when you interact
                  with our platform/ Application.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: "42.55pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol style={{ marginBottom: "0in", listStyleType: "undefined" }}>
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <span style={{ color: "black" }}>
                  In providing interest-based ads, we follow applicable laws, as
                  well as the Code for Self-Regulation in Advertising by the
                  Advertising Standards Council of India and the Self-Regulatory
                  Principles for Online Behavioral Advertising developed by the
                  Digital Advertising Alliance (a coalition of marketing, online
                  advertising, and consumer advocacy organizations).
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".55in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>GRIEVANCES</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              In accordance with Information Technology Act 2000 and rules made
              there under, in case you have any questions about regarding this
              Policy or other privacy concerns, you can email us at:&nbsp;
            </span>
            <span>
              <a href="mailto:contact@vimasmiham.com">
                <span style={{ color: "black" }}>contact@vimasmiham.com</span>
              </a>
              <span style={{ color: "black" }}>.</span>
            </span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: "11.0pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
          <div
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,
            }}
          >
            <ol
              style={{
                marginBottom: "0in",
                listStyleType: "undefined",
                marginLeft: "-0.25in",
              }}
            >
              <li
                style={{
                  margin: "0in",
                  marginBottom: ".0001pt",
                  fontSize: 16,
                }}
              >
                <strong>
                  <u>
                    <span style={{ color: "black" }}>JURISDICTION</span>
                  </u>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".25in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <strong>
              <u>
                <span style={{ color: "black" }}>
                  <span style={{ textDecoration: "none" }}>&nbsp;</span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "0in",
              marginRight: "0in",
              marginBottom: ".0001pt",
              marginLeft: ".5in",
              fontSize: 16,

              textAlign: "justify",
              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>
              If the User chooses to visit the Vima Smiham website, application
              or any other interface, &nbsp;their visit and/or any dispute over
              privacy which is subject to this Policy and the website's terms
              and conditions shall be governed by, and construed in accordance
              with, the laws of the Union of India and subject to the
              jurisdiction of the courts of New Delhi, India without regard to
              the principles of conflicts to law of any other jurisdiction.
            </span>
          </p>
          <p
            style={{
              margin: "0in",
              marginBottom: ".0001pt",
              fontSize: 16,

              lineHeight: "150%",
            }}
          >
            <span style={{ color: "black" }}>&nbsp;</span>
          </p>
        </div>
      </React.Fragment>
    );
  };

  renderTermsOfService = () => {
    return (
      <>
        <p className="text-center text-underline">
          <strong style={{ textDecoration: "underline" }}>
            Vima Smiham &ndash; TERMS OF SERVICE
          </strong>
        </p>

        <p>Welcome to Vima Smiham,</p>

        <p>
          &nbsp;Vima Smiham is an online insurance education portal which
          provides a platform for subscribers to access online content available
          on &nbsp;www.vimismiham.com (&ldquo;Website&rdquo;) and our
          application (&ldquo;Application&rdquo;) ( &ldquo;App&rdquo;)(both free
          and paid).
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Introduction</strong>
        </p>

        <p>
          The website / Application is managed and operated by Vima Smiham
          having its registered office at 202, S-6, Saraswati Apartments, D-6
          Vasant Kunj, New Delhi -110070 (hereinafter, referred to as &ldquo;the
          Company&rdquo;) India to regularly update and administer the website /
          Application. &nbsp;
        </p>

        <p>
          The Terms of Service (hereinafter referred to as &ldquo;TOS&rdquo;)
          provides an overview of the terms of use and the services of Vima
          Smiham through website/ Application or any other interface.
        </p>

        <p>
          The TOS, Privacy Policy, Refund Policy, along with the other ancillary
          policies, of the Company together serve as the Company&rsquo;s
          policies.
        </p>

        <p>
          The following TOS governs this particular website /Application and
          also the relationship between the User of the services and the
          Company.
        </p>

        <p>
          This TOS applies to all viewers and Users of this website /
          Application.
        </p>

        <p>
          By continuing to read this text, &nbsp;you agree to be bound by the
          Company&rsquo;s policies and consent to the following requisitions
          mentioned as below.
        </p>

        <p>
          <strong>Definitions</strong>
        </p>

        <p>
          &nbsp;Website interface or any other means the User utilizes to access
          the Vima Smiham portal.
        </p>

        <p>
          &ldquo;User&rdquo; means any individual or business entity including
          but not limited to employees, agents, brokers, individuals that have
          subscribed to or utilizes the Vima Smiham service, either through its
          website or its Application.
        </p>

        <p>
          &ldquo;User Data&rdquo; means information (including Personal
          Information) relating to a User, including order, payment, or account
          information would be stored in Vima Smiham&rsquo;s database.
        </p>

        <p>
          &ldquo;Service&rdquo; means any services offered by the Company, Vima
          Smiham or Vima Smiham related entities, provided through means
          including but not limited to Vima Smiham software applications.
        </p>

        <p>
          &ldquo;SAAS&rdquo; stands for Software as a Service and in this case
          refers to the services of Vima Smiham provided by the Company.
        </p>

        <p>
          <strong>Registration</strong>
        </p>

        <p>
          In order to view or access complete information and Service offered by
          the Company on the website / App, the User shall be required to make
          an account along with the required details with the website / App by
          clicking at the link &lsquo;register&rsquo;.
        </p>

        <p>
          By registering themselves, the User agrees to be contacted by the
          Company through call, email and/or any other means of communication.
        </p>

        <p>
          In order to view or access the &nbsp;paid courses offered by the
          website/App, the user shall be required to pay through a secured
          payment gateway via credit card/ Debit card/ Internet Banking after
          registration. There is no provision for payment in cash.
        </p>

        <p>
          The User may be contacted for service-related reminders, updates,
          promotions, and latest schemes offered by the Company.
        </p>

        <p>
          <strong>Terms of Service</strong>
        </p>

        <p>
          The Company grants to the User a non-exclusive, non-transferable
          license to use the software as a service (&ldquo;Vima Smiham&rdquo;)
          identified for the purpose of use as set out below in this TOS.
        </p>

        <p>
          The User may use the Service in executable format for its own use
          after subscribing to the Service, and shall not translate or modify
          the service code and programs or incorporate them into other software.
        </p>

        <p>
          The User shall not also, transfer or sub-license the Service to any
          third party, in whole or in part or in any other form, whether
          modified or unmodified.
        </p>

        <p>
          The Service shall only be utilised for the intended purpose of
          learning, education by the User.
        </p>

        <p>
          Any unauthorised use of the Service will result in the termination of
          the User&rsquo;s subscription to the Service and shall warrant
          appropriate legal action against the User.
        </p>

        <p>
          Vima Smiham is a subscription-based Service wherein the User would be
          required to hold a registered account with an active subscription to
          access the said Service.
        </p>

        <p>
          The subscription may be purchased for periods as specified in the
          payment gateway.
        </p>

        <p>
          <strong>No liability</strong>&nbsp;
        </p>

        <p>
          The Service is a sophisticated tool which can be used by the
          candidates to improve their performance in various exams.
        </p>

        <p>
          &nbsp;Although every effort has been made to have updated and correct
          information on the Service, the Company does not take responsibility
          for any inaccuracies and incorrect information.
        </p>

        <p>
          The Company does not guarantee accuracy of the information in the
          database and will not be liable for any loss, injury or damage
          incurred by the User or the End User during the subscription period
          and after the subscription has expired.
        </p>

        <p>
          The Company does not guarantee accessibility to the App/ Website at
          all times to the users as the access is merely dependent on external
          factors like the internet service networks and the availability of the
          internet network.
        </p>

        <p>
          &nbsp;The Company is not responsible to ensure that the data given by
          the user is accurate and up to date.
        </p>

        <p>
          Notwithstanding the above, possible inaccuracies may arise due to
          various factors including but not limited to unavailability of
          communication systems i.e. non-functional or low speed
          internet/network, or virus in the Service or App/website or any other
          interface offered by the Company or cyber-attack/ hacking, or any
          unauthorised access to computer data and storage devices, computer
          crashes, malfunctioning in the computer terminal, or the systems
          getting affected by any malicious, destructive or corrupting code or
          programme, mechanical or technical errors/failures or power shutdown,
          etc. The Company does not bear any liability in respect of
          inaccuracies and any loss, injury or damage that may be caused due to
          such inaccuracy. 9
        </p>

        <p>
          &nbsp;<strong>Feed back</strong>
        </p>

        <p>
          The feedback given by the user with respect to the website/App shall
          be deemed to be non-confidential.
        </p>

        <p>
          Furthermore, the feedback may be used on an unrestricted basis to
          enhance the working of the website
        </p>

        <p>
          <strong>No endorsement&nbsp;</strong>
        </p>

        <p>
          The Company does not recommend, endorse or solicit business for any
          particular provider mentioned on its website and App.
        </p>

        <p>
          <strong>Third party links and advertisements</strong>
        </p>

        <p>
          The Company does not endorse or advertise any content from any other
          website / App but its own.
        </p>

        <p>Transfer of information prohibited</p>

        <p>
          This website / App prohibits the User to send or transfer information
          or data to any third-parties.
        </p>

        <p>&nbsp; Right to block users</p>

        <p>
          The Company reserves its rights to block users who are found to be
          misusing the content of the website/ App or using the Service against
          the service terms specified in this policy or are found to be causing
          any harm, loss or damage including but not limited to defamation of
          the website/ App, in any manner.
        </p>

        <p>&nbsp; Indemnification</p>

        <p>
          The Users agree to indemnify the Company against all losses, damages,
          costs and expenses incurred by the Company by way of breach of any of
          the Company&rsquo;s policies.
        </p>

        <p>&nbsp; Intellectual Property Rights</p>

        <p>
          The Application seriously takes in view its intellectual property. All
          the content produced on this website / App is protected under the
          Copyright Act, 1957 and the Trademark Act, 1999. Any person/entity
          found to be misusing its content or using the same for commercial
          purposes will be held liable under the applicable laws.
        </p>

        <p>&nbsp; &nbsp;Severability</p>

        <p>
          If any provisions of the Company&rsquo;s policies shall be declared
          invalid or illegal for any reason whatsoever, then, notwithstanding
          such invalidity or illegality, the remaining terms and provisions of
          the said policies shall remain in full force and effect in the same
          manner as if the invalid or illegal provisions had been contained
          herein.
        </p>

        <p>&nbsp;Waiver of Rights</p>

        <p>
          Non-exercise of rights by the Company would not mean waiver of its
          rights and remedies.
        </p>

        <p>
          &nbsp;<strong>Amendment of the TOS</strong>
        </p>

        <p>
          Although every effort has been made to ensure the accuracy of the
          information contained online, the Company cannot accept responsibility
          for any errors or omissions, and reserve its rights to vary, amend,
          supplement or cancel any of the information or offers featured online
          at any time.
        </p>

        <p>
          These terms may be modified from time to time at our sole instance
          without any prior intimation.
        </p>

        <p>
          <strong>Dispute Resolution</strong>
        </p>

        <p>
          This website / App and any disputes/issues arising out of it shall be
          governed by, and construed in accordance with, the laws of the Union
          of India.&nbsp;
        </p>

        <p>
          Unless otherwise mandated by applicable law, any and all dispute,
          claim or controversy arising out of, or relating to this Agreement,
          including the determination of the scope or applicability of this TOS
          and/or any of the Company&rsquo;s policies, shall be settled by
          arbitration in India, before a sole arbitrator appointed by the
          Company.
        </p>

        <p>
          The said Arbitration shall be conducted in accordance with the rules
          of arbitration of the Indian Arbitration and Conciliation Act, 1996 as
          in effect on the date hereof, with the seat of the arbitration at New
          Delhi, India. The proceedings of the arbitration, including, but not
          limited to, any awards, interim or final, shall be in the English
          language. The award will be final and binding on the parties to the
          dispute.
        </p>

        <p>
          The award shall be in writing and be a reasonable award. The
          prevailing party shall be entitled to claim its costs and expenses
          (including reasonable counsel fees), but the arbitral panel shall not
          be required to award costs and expenses. The award shall be final and
          conclusive and judgment thereon may be entered in a court having
          jurisdiction for its enforcement.&nbsp;
        </p>

        <p>
          Neither party shall be entitled to commence or maintain any action in
          a court of law upon any matter in dispute until such matter shall have
          been submitted and decided by the arbitral pane as herein provided and
          then only for the enforcement of the arbitral panel&rsquo;s award;
          provided, however, that the parties hereto shall be permitted to seek
          temporary or preliminary equitable relief in a court located in New
          Delhi, India pending resolution of any arbitration proceeding
          hereunder. The parties agree to use the courts in New Delhi
          exclusively to seek temporary or preliminary equitable relief in
          connection with this Agreement, irrespective of where the cause of
          action has arisen. User /End User expressly waives objections to the
          exclusive jurisdiction of the courts in New Delhi on the grounds that
          the courts in New Delhi are inconvenient or inappropriate.
        </p>

        <p>
          &nbsp; <strong>Termination</strong>
        </p>

        <p>
          Without prejudice to the rights of the User/ End User to initiate any
          legal action, the Company may terminate and suspend the delivery of
          any of the Services offered to the User
        </p>

        <p>
          <strong>Miscellaneous Provisions</strong>
        </p>

        <p>
          Malware/viruses &ndash; substantial consideration and investment has
          been vested in to this website /App and the Service by this Company to
          ensure the smooth and virus/malware-free functioning of the same.
          However, the Company cannot take liability of unwarranted activity
          caused on the website/ Application.
        </p>

        <p>
          All transactions facilitated by the Company are only through the
          designated online payment gateway available on the Company&rsquo;s
          website. No offline payments are allowed.
        </p>

        <p>
          Force Majeure &ndash; The Company shall not be made liable for any
          transaction that does not fructify, or be completed, or for any
          failure on the part of the Company to perform any of its obligations
          under the Company&rsquo;s policies, or those applicable specifically
          to its services/facilities if performance is prevented, hindered or
          delayed by a Force Majeure event. In such a case, its obligations
          shall be suspended for so long as the Force Majeure event continues.
        </p>
      </>
    );
  };
  render() {
    {
      // console.log(this.state.faculties);
    }
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      console.log(this.state);
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
          />

          <div id="aboutUs">
            <div className="about-main ">
              <h1 className="about-us-heading">Privacy Policy</h1>
            </div>

            <div>{this.renderAboutNavigation()}</div>

            <div
              className="redirect-boxes d-none d-md-flex"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Link
                className="btn"
                style={redirectButtons}
                to="/acts_and_regulations"
              >
                Acts &amp; Regulations
              </Link>
              <Link
                className="btn"
                style={redirectButtons}
                to="/categories/promotional-exams"
              >
                Promotional Exams
              </Link>
              <Link
                className="btn"
                style={redirectButtons}
                to="/categories/professional-exams"
              >
                Professional Exams
              </Link>
              <Link className="btn" style={redirectButtons} to="/latest-news">
                Latest News
              </Link>
              <Link className="btn" style={redirectButtons} to="/test-series">
                Test Series
              </Link>
              <Link className="btn" style={redirectButtons} to="/all-faculty">
                All Faculty
              </Link>
              <Link
                className="btn"
                style={redirectButtons}
                to="/upcoming-exams"
              >
                Upcoming Exams
              </Link>
            </div>

            <div className="about-us-content-container container-c pt-5">
              {this.renderPrivacyPolicy()}
              <br />
              <br />
              {this.renderTermsOfService()}
            </div>

            <div style={{ height: "auto" }} className="windowView">
              <MainFooterPage {...this.props} />
            </div>
            <div className="mobView">
              <StickyFooterPage {...this.props} />
            </div>
          </div>
        </div>
      );
    }
  }
}

const redirectButtons = {
  textTransform: "capitalize",
  borderRadius: "4px",
  fontSize: "16px",
  fontFamily: "Ubuntu-M",
  color: "#908b8b",
};

export default PrivacyPolicy;
