import React from "react";
import "./evaluation.css";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import {
  MDBIcon,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle
} from "mdbreact";

import Countdown from "react-countdown-now";
import Timer from "react-compound-timer";
import swal from "sweetalert";

const renderer = ({ total, hours, minutes, seconds }) => {
  // Render a countdown
  return (
    <span>
      0{hours} : {minutes} : {seconds}
    </span>
  );
};
class EvaluationTestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      about_content: [],
      test_config: [],
      answer: [],
      easyque: [],
      moderateque: [],
      toughque: [],
      test: [],
      topicids: [],
      loading: true,
      // answer:[{name:'Use economically'},{name:'Spend wastefully'},{name:'Siphon judiciously'},{name:'Donate generously'}],
      // questions: this.props.history.location.state.questions.filter(item=>item.question_id),
      selected_question: {},
      selected_option: 0,
      ques_index: 0,
      count: "3600000",
      right_answer: 0,
      wrong_answer: 0,
      selected_answer: -1
    };
  }

  componentWillMount() {
   this.setState({questions:this.props.history.location.state.questions.filter(item=>item.question_id)},()=>this.setState({selected_question:this.state.questions[0]}));
  
  }


  componentDidMount() {
    console.log(this.state)
  }






  selectQuestion(data, index) {
    let { questions } = this.state;
    let selected_question = questions[index];
    // let selected_options1 = questions[index]
    for (var i = 0; i < questions.length; i++) {
      if (questions[i].selected_option == 0) {
        questions[i].selected = 0;
      } else if (questions[i].saved == 1) {
        questions[i].selected = 2;
      }
    }

    if (selected_question.selected_option == 0) {
      for (var v = 0; v < selected_question.options.length; v++) {
        selected_question.options[v].select = 0;
      }
      //  selected_question.options = selected_question.options
    }

    selected_question.selected = 1;

    this.setState({ questions, ques_index: index });
    this.setState({ loading: false });
  }

  selectQuestionBack(data, index) {
    let { questions } = this.state;
    let selected_question = questions[index];
    let selected_question2 = questions[index + 1];

    if (selected_question.selected_option == 0) {
      for (var v = 0; v < selected_question.options.length; v++) {
        selected_question.options[v].select = 0;
      }
    }

    if (selected_question2.selected_option == 0) {
      selected_question2.selected = 0;
    } else if (
      selected_question2.saved == 1 ||
      selected_question.selected == 2 ||
      selected_question.selected_option != 0
    ) {
      // selected_question2.selected_option=0
      selected_question2.selected = 2;
    } else if (
      selected_question.saved == 1 &&
      selected_question.selected_option != 0
    ) {
      selected_question.selected = 2;
    }

    selected_question.selected = 1;
    this.setState({ questions, ques_index: index });
    this.setState({ loading: false });
  }

  attemptedQuestion(index) {
    let { questions } = this.state;
    let selected_question = questions[index];
    for (var i = 0; i < questions.length; i++) {
      if (questions[i].selected_option == 0) {
        questions[i].selected = 0;
      } else if (questions[i].selected_option != 0) {
        questions[i].selected = 2;
      }
    }
    selected_question.selected = 1;
    this.setState({ questions, ques_index: index });
    this.setState({ loading: false });
  }

  renderQuestions() {
    let columns = [];
    this.state.questions.forEach((data, key) => {
      // push column
      if (data.selected == 1) {
        columns.push(
          <div
            className="quesMainDiv"
            style={{ backgroundColor: "#dbaa34", color: "white" }}
            onClick={() =>
              this.setState(
                { selected_question: data, answer: JSON.parse(data.answers) },
                () => this.selectQuestion(data, key)
              )
            }
          >
            <text>Q.{key + 1}</text>
          </div>
        );
      } else if (data.selected == 2) {
        columns.push(
          <div
            className="quesMainDiv"
            style={{
              backgroundColor: "#009FE3",
              color: "white",
              border: "none"
            }}
            onClick={() =>
              this.setState(
                {
                  selected_question: data,
                  ques_index: key,
                  answer: JSON.parse(data.answers)
                },
                () => this.attemptedQuestion(key)
              )
            }
          >
            <text>Q.{key + 1}</text>
          </div>
        );
      } else {
        columns.push(
          <div
            className="quesMainDiv"
            onClick={() =>
              this.setState(
                {
                  selected_question: data,
                  ques_index: key,
                  answer: JSON.parse(data.answers)
                },
                () => this.selectQuestion(data, key)
              )
            }
          >
            <text>Q.{key + 1}</text>
          </div>
        );
      }

      // force wrap to next row every 8 columns
      if ((key + 1) % 8 === 0) {
        columns.push(<div className="w-100"></div>);
      }
    });
    return (
      <div className="row" style={{ justifyContent: "", margin: 0 }}>
        {columns}
      </div>
    );
  }

  renderMobViewAbout() {
    return (
      <MDBDropdown>
        <MDBDropdownToggle caret color="primary" style={{ width: "100%" }}>
          {this.state.selected_type.name}
        </MDBDropdownToggle>
        <MDBDropdownMenu basic className="vsDropdownWidth">
          {this.state.newsInsightData.map((item, index) => {
            return (
              <MDBDropdownItem
                onClick={() =>
                  this.setState({ selected_type: item }, () =>
                    this.selectNews(item, index)
                  )
                }
              >
                {item.name}
              </MDBDropdownItem>
            );
          })}
        </MDBDropdownMenu>
      </MDBDropdown>
    );
  }
  calrightanswer = () => {
    return this.state.right_answer;
  };
  calwronganswer = () => {
    return this.state.wrong_answer;
  };

  testCompleted = async () => {
    const right = await this.calrightanswer();
    const wrong = await this.calwronganswer();

    swal({
      title: "Are you sure ?",
      text: "you want to submit test!!",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.props.history.push({
          pathname: "/feedback",
          state: { right, wrong }
        });
        this.props.history.push("/feedback");
      } else return;
    });
  };

  renderTimer() {
    return (
      <Timer
        initialTime={this.state.count}
        direction="backward"
        checkpoints={[
          {
            time: 600000,
            callback: () => swal("You have 10 mins left !")
          },
          {
            time: 0,
            callback: () => {
              swal("Time over !");
              this.props.history.push("/feedback");
            }
          }
        ]}
      >
        {() => (
          <React.Fragment>
            <Timer.Hours /> :
            <Timer.Minutes /> :
            <Timer.Seconds />
          </React.Fragment>
        )}
      </Timer>
    );
  }
  checkSelect(data) {
    // console.log(data,'12313')
    if (data.select == 1) {
      return true;
    } else {
      return false;
    }
  }
  renderOptions(data, head_key) {
    // {console.log(data.value,"xax")}

    return (
      <div>
        <label class="container">
          {data.answer}
          <input
            type="radio"
            checked={this.checkSelect(data)}
            name="radio"
            onClick={() => this.handleOptionChange(data, head_key)}
          />
          <span class="checkmark"></span>
        </label>
      </div>
    );
  }
  handleOptionChange = (data, head_key) => {
    let { answer } = this.state;

    var target_task = answer[head_key];
    for (var v = 0; v < answer.length; v++) {
      answer[v].select = 0;
    }

    target_task.select = 1;
    this.setState({
      answer,
      selected_option: head_key + 1
    });

    this.setState({ selected_answer: data.value });
  };

  renderCountdown() {
    if (this.state.timer == true) {
      return (
        <div className="testTimer" style={{ backgroundColor: "#f9f9f9" }}>
          <Countdown
            date={Date.now() + 3442356}
            autoStart={true}
            renderer={renderer}
          />
        </div>
      );
    } else {
      return (
        <div className="testTimer">
          <Countdown
            date={Date.now() + 3442356}
            autoStart={false}
            renderer={renderer}
            onStart={() => this.startTimer()}
          />
        </div>
      );
    }
  }

  saveAnswer() {
    if (this.state.selected_option !== 0) {
      if (this.state.selected_answer === 1) {
        this.setState({ right_answer: this.state.right_answer + 1 });
      } else {
        this.setState({ wrong_answer: this.state.wrong_answer + 1 });
      }

      let { questions } = this.state;
      let target_ans = questions[this.state.ques_index];
      target_ans.selected_option = this.state.selected_option;
      target_ans.saved = 1;
      this.setState({ questions }, () =>
        this.nextQuestion(this.state.ques_index)
      );
    } else {
      swal("choose any option ");
    }
  }

  nextQuestion(index) {
    if (index < 39) {
      let { questions } = this.state;
      let selected_question = questions[index];
      for (var k = index; k < questions.length; k++) {
        if (
          selected_question.selected == 1 ||
          selected_question.selected == 2
        ) {
          questions[k].selected = 0;
          questions[k + 1].selected = 1;
          this.setState({
            selected_question: questions[k + 1],
            answer: JSON.parse(questions[k + 1].answers),
            selected_option: 0
          });
        }
      }
      selected_question.selected = 2;
      this.setState({ questions }, () =>
        this.selectQuestion(this.state.selected_question, index + 1)
      );
    } else {
      swal({
        title: "Test completed !!",
        text: "Thank you for giving test!!",
        icon: "success"
      });
    }
  }

  renderBackButton() {
    if (this.state.ques_index != 0) {
      return (
        <div
          className="backBtnDiv"
          onClick={() =>
            this.setState(
              {
                selected_question: this.state.questions[
                  this.state.ques_index - 1
                ],
                answer: JSON.parse(
                  this.state.questions[this.state.ques_index - 1].answers
                ),
                ques_index: this.state.ques_index - 1
              },
              () =>
                this.selectQuestionBack(
                  this.state.selected_question,
                  this.state.ques_index
                )
            )
          }
        >
          <div className="">
            <MDBIcon icon="chevron-left" className="" />{" "}
            <span className="saveBtnMargin">Back</span>
          </div>
        </div>
      );
    }
  }

  renderSkip_NextButton() {
    if (this.state.ques_index < 117) {
      return (
        <div className="skipBtnMainDiv">
          <div
            className="skipBtnDiv"
            style={{ backgroundColor: "#fff" }}
            onClick={() =>
              this.setState(
                {
                  selected_question: this.state.questions[
                    this.state.ques_index + 1
                  ],
                  answer: JSON.parse(
                    this.state.questions[this.state.ques_index + 1].answers
                  ),
                  ques_index: this.state.ques_index + 1,
                  selected_option: 0
                },
                () =>
                  this.selectQuestion(
                    this.state.selected_question,
                    this.state.ques_index
                  )
              )
            }
            style={{ cursor: "pointer" }}
          >
            Skip
          </div>
          {this.renderNextButton()}
        </div>
      );
    } else if (this.state.ques_index == 29) {
      return (
        <div className="skipBtnMainDiv">
          <div
            className="saveBtnDiv"
            style={{
              cursor: "pointer",
              backgroundColor:
                this.state.selected_option != 0 ? "#0093D9" : "#89bfe8"
            }}
            onClick={() => this.saveAnswer()}
          >
            <div>Save</div>
          </div>
          <div
            className="saveBtnDiv"
            style={{ backgroundColor: "#4A4A4A", cursor: "pointer" }}
            onClick={() => this.testCompleted()}
          >
            <div>Submit Test</div>
          </div>
        </div>
      );
    }
  }

  renderNextButton() {
    if (this.state.selected_option != 0) {
      return (
        <div
          className="saveBtnDiv"
          style={{ cursor: "pointer" }}
          onClick={() => this.saveAnswer()}
        >
          <div>
            Save & Next{" "}
            <MDBIcon icon="chevron-right" className="saveBtnMargin" />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="saveBtnDiv"
          style={{ backgroundColor: "#89bfe8" }}
          onClick={() => this.saveAnswer()}
        >
          <div>
            Save & Next{" "}
            <MDBIcon icon="chevron-right" className="saveBtnMargin" />
          </div>
        </div>
      );
    }
  }
  render() {
    console.log(this.props)

    
    // console.log(this.state.selected_question,'290')
 
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="windowView">
            <NewHeader {...this.props} active="My Dashboard" />
          </div>

          <div className="testTimerMainDiv windowView">
            <span className="testMainHeadingFont">Evaluation</span>

            <div className="countdown_btn">
              <div className="subTimerDiv">
                <div
                  className="testTimer"
                  style={{ backgroundColor: "#0092D8" }}
                >
                  {/* <Countdown date={Date.now() + 3600000} 
              renderer={renderer} /> */}
                  {this.renderTimer()}
                </div>
              </div>
              <div className="btn_clock">
                <img
                  src={require("../../Assets/images/clock.png")}
                  alt="clockImage"
                />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="quesDivPosition">
                <div className="quesDiv">
                  <p className="questionsFont">
                    {this.state.selected_question.question_title}
                  </p>
                </div>
                <div className="optionDiv">
                  {JSON.parse(this.state.selected_question.answers).map((data, index) => {
                    // {console.log(data,"ANSWER")}
                    return this.renderOptions(data, index);
                  })}
                </div>
                <p style={{ color: "#FD9840" }} className="captionPadding">
                  Choose a correct answer for the questions.
                </p>
              </div>
              <div className="quesOuterDiv">{this.renderQuestions()}</div>
            </div>
          </div>
          <div className="bottomBtnDiv">
            {this.renderBackButton()}
            <div className="skip_Next_btnFlex_end">
              {this.renderSkip_NextButton()}
            </div>
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="vsMobView" style={{ height: "auto" }}>
            {/* <StickyFooterPage {...this.props}/> */}
          </div>
    </div>
      
    
    // <div>

    // </div>
    
    
    );
    }
  
}


export default EvaluationTestPage;
