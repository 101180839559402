import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import MediaComponent from '../MediaComponent/chooseMediaFile'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  MDBIcon
} from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class EditCircular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      tax_id: "",
      taxName: "",
      faq_question: "",
      faq_answer: "",
      taxValue: "",
      circular_id: "",
      circular_name: "",
      circular_link: "",
      file: null,
      date_of_circular:null
    };
  }

  componentWillMount() {
    console.log(JSON.parse(window.localStorage.getItem("circular_item")));
    this.setState({ loading: true }, () => this.getCircularDetails());
  }

  getCircularDetails = async () => {
    var circular_item = JSON.parse(
      window.localStorage.getItem("circular_item")
    );
    console.log(circular_item, "tax123");

    this.setState({
      circular_id: circular_item.circular_id,
      circular_name: circular_item.circular_name,
      circular_link: circular_item.circular_link,
      date_of_circular:new Date(circular_item.date_of_circular),
      file:circular_item.circular_link,
      loading: false
    },()=>window.scrollTo(500,0));
  };

  componentDidMount() {}

  //Edit FORM

    handleMediaChooseImage = (circular_link) =>{
    this.setState({circular_link})
  }

  editCircular() {
    console.log(this.state.circular_name,typeof this.state.circular_id,this.state.circular_id,this.state.file)
    if (this.state.circular_name.length == 0) {
      swal("Check Question!", "Please enter question", "error");
    } else {

        this.setState({
          loading: true
          // add_modal:false
        });

        let formData = new FormData();
        formData.append("circular_name", this.state.circular_name);
        formData.append("circular_id", this.state.circular_id);
        formData.append("circular_link", this.state.circular_link);
        formData.append('date_of_circular',this.state.date_of_circular)
        // console.log(this.state.facultyName,this.state.facultyThumbnail,this.state.profession,this.state.description)
        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_circular", {
            method: "POST",
            body: formData
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson, "QWERTy");
              swal("Success", "File succesfullly updated", "success").then(
                value => {
                  this.props.history.push("/admin/acts-regulations");
                }
              );
            })
            .catch(error => {
              this.setState({
                loading: false
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            })
        });
      }
    
  }


  renderCircularDate() {
    return (
      <div className="col-6 my-2">
      <label htmlFor="defaultFormRegisterNameEx" className="date-from-label subject-labels mr-3">
           Circular date:
      </label>
      <DatePicker
      selected={this.state.date_of_circular}
      onChange={(date)=>this.setState({date_of_circular:date })}
      dateFormat="dd/MM/yyyy"
    />
       </div>
    );
  }

  //HANDLE CHANGE FUNCTIONS

  handleCircularNameChange = text => {
    console.log("Tax Name:", text.target.value);
    this.setState({ circular_name: text.target.value });
  };



  rendertaxNameSection() {
    return (
      <div className="col-12 my-2 subject-name-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Display Name :
        </label>
        <input
          autoFocus
          value={this.state.circular_name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={text => this.handleCircularNameChange(text)}
        />
      </div>
    );
  }

  renderCircularLinkSection() {
    return (
      <div className="col-12 my-2 subject-name-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels mr-3"
          style={{ width: "auto" }}
        >
          File Url :
        </label>
        <br />
        <input
          onChange={e => this.setState({ circular_link: e.target.value })}
          value={this.state.circular_link}
          placeholder="Choose File From Gallery"
        />
        <MediaComponent style={{marginLeft:'-20px'}} onSelectMediaItem={this.handleMediaChooseImage} buttonName={<MDBIcon icon="camera" /> } restriction= 'image/*' /> 
        

      </div>
    );
  }

  render() {
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={6} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/acts-regulations")
                        }
                      >
                        All Acts
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Acts</h3>
                      <button
                        className="admin-button-style"
                        onClick={() => this.editCircular()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>

                  <div className="row px-2 my-2 mx-0">
                    {this.rendertaxNameSection()}

                    <hr />
                    {this.renderCircularLinkSection()}

                    {this.renderCircularDate()}
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>

          {console.log(this.state)}
        </div>
      </div>
    );
  }
}

export default EditCircular;
