import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";

import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";
import swal from "sweetalert";
import "../Lectures/Addlecture.css";
import { MDBIcon } from "mdbreact";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class HomepageSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeTitle: "",
      homeDescription: ""
    };
  }
  componentDidMount() {}
  componentWillMount() {
    this.getUserSEO();
    // this.getHomePageSEO()
  }
  getUserSEO = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_homepage_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "clients");
          this.setState({
            homeTitle: responseJson[0].page_title_seo,
            homeDescription: responseJson[0].page_description_seo,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  renderHomeTitle = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          HomePage Title:
        </label>
        <input
          value={this.state.homeTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={text => this.setState({ homeTitle: text.target.value })}
        />
      </div>
    );
  };
  renderHomeDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          HomePage Description:
        </label>
        <input
          value={this.state.homeDescription}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={text =>
            this.setState({ homeDescription: text.target.value })
          }
        />
      </div>
    );
  };

    editHomePageSEO() {

      let formData = new FormData();
      formData.append("page_title_seo", this.state.homeTitle);
      formData.append("page_description_seo", this.state.homeDescription);
      formData.append("id",1)
      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_homepage_seo", {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "QWERTy");
            swal("Success", "Homepage SEO added", "success");
            this.props.history.push("/admin/dashboard");
          })
          .catch(error => {
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }

  render() {
    console.log(this.state, "state");
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
     
          <SidebarPage {...this.props} active={11} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 top_button_bar d-flex align-items-center">
                    {/* <button
                      className="admin-button-style admin-button-style-margin"
                      onClick={() => this.props.history.push("/admin/lecture")}
                    >
                      All Lectures
                    </button> */}
                    {/* <MediaComponent
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    /> */}
                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.editHomePageSEO()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> HomePage SEO</h3>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4 fit-content">
                    <div className="row px-0 mx-0">
                      {this.renderHomeTitle()}
                      {this.renderHomeDescription()}
                    </div>
                  </div>
                  <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4 h-100">
                    {/* {this.renderChooseFileType()} */}
                  </div>
                </div>

                {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addlectures()}>Save &amp; Publish</button>  */}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
