import React from "react";
import "../Exam-News/Exam-news.css";
import "../Acts_Regulations/Acts_Regulations.css";
import { MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import Iframe from "react-iframe";
import { MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";

class ExamNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news_data: [],
      search: false,
      startDate: new Date(),
      circularFrame: false,
      textToFind: "",
    };
  }
  componentWillMount() {
    this.getNewsUpdate();
  }

  getNewsUpdate = () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_caselaws", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var response = responseJson.reverse();
          console.log(response, "news_data");
          this.setState({
            news_data: response,
            // news_data_date: response.creation_time,
            loading: false,
          });
        }),
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd;
  }

  commentMonth(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return monthNames[Number(dt.getMonth())];
  }

  commentYear(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dt.getFullYear().toString();
  }

  renderDate(item, v) {
    return (
      <div className="newsDateDisplay">
        <div className="newsDate" style={{ backgroundColor: "#73047d" }}>
          <span className="newsDate_font">
            {this.commentdate(item.date_of_decision)}
          </span>
          <span className="newsMonth_font">
            {this.commentMonth(item.date_of_decision).toUpperCase()}
          </span>
        </div>
        <div className="newsYear">
          <span className="newsYear_font">
            {this.commentYear(item.date_of_decision)}
          </span>
        </div>
        {/* <text className='rotateText'>Upcoming Exam</text> */}
      </div>
    );
  }
  renderModalFrame(item) {
    if (this.state.circularFrame) {
      return (
        <MDBModal
          className="circularModal modal-lg"
          isOpen={this.state.circularFrame}
          toggle={() => this.setState({ circularFrame: false })}
          style={{ backgroundColor: "white" }}
        >
          <MDBModalHeader
            toggle={() => this.setState({ circularFrame: false })}
          >
            <p className="modalFramePara"></p>
          </MDBModalHeader>
          <MDBModalBody>
            <Iframe
              width="100%"
              height="100%"
              // id="myId"
              // className="myClassname"
              url={`${item.link}`}
              display="initial"
              position="relative"
            />
          </MDBModalBody>
        </MDBModal>
      );
    } else {
      return null;
    }
  }

  renderNews() {
    let searchedArray = [];

    console.log(this.state.news_data);
    if (this.state.search == true) {
      this.state.news_data.forEach((item) => {
        if (
          item.citation
            .toLowerCase()
            .includes(this.state.textToFind.toLowerCase()) ||
          item.court_name
            .toLowerCase()
            .includes(this.state.textToFind.toLowerCase()) ||
          item.title
            .toLowerCase()
            .includes(this.state.textToFind.toLowerCase()) ||
          item.date_of_decision
            .toLowerCase()
            .includes(this.state.textToFind.toLowerCase())
        ) {
          searchedArray.push(item);
        }
      });

      return searchedArray.map((item, index) => {
        if (item.status == 1) {
          console.log(item, "140");
          const subjects = JSON.parse(item.Subjects);
          console.log(subjects);
          //  console.log(subjectsElement, '184')
          let now = new Date();
          let examDate = new Date(item.date_of_decision);

          return (
            <div>
              <MDBCardBody className="newsDivPosition">
                <div className="news_justify_content">
                  <div className="NewsDivWidth">
                    <MDBCardTitle tag="h5">
                      {this.renderDate(item, index)}
                    </MDBCardTitle>
                  </div>

                  <div className="NewsDivWidth-title-description">
                    <MDBCardTitle
                      tag="h6"
                      style={{ color: "#303030" }}
                      className="newsHeadingFont"
                    ></MDBCardTitle>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <span>
                        <strong>Citation</strong>:{" "}
                      </span>
                      <span
                        dangerouslySetInnerHTML={{ __html: item.citation }}
                      ></span>
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Name of the Court / Forum / Tribunal</strong>:{" "}
                      {item.court_name}
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Case Number</strong>: {item.case_number}
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Title of the Case</strong> : {item.title}
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Date of Decision / Order</strong> :{" "}
                      {this.commentdate(item.date_of_decision)}&nbsp;
                      {this.commentMonth(item.date_of_decision)},{" "}
                      {this.commentYear(item.date_of_decision)}
                    </MDBCardText>

                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Subject / Issue</strong> : {item.issue}
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <strong>Synopsis</strong>:
                      <div
                        dangerouslySetInnerHTML={{ __html: item.synopsis }}
                      ></div>
                    </MDBCardText>

                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <p className="related-subjects-caselaw">
                        <strong>Related Subjects</strong>:&nbsp;
                        {subjects != null
                          ? subjects.map((item, index) => {
                              if (index !== subjects.length - 1) {
                                return (
                                  <span>
                                    <Link to={`/courses/${item.url}`}>
                                      {item.name}
                                    </Link>
                                    , &nbsp;
                                  </span>
                                );
                              } else {
                                return (
                                  <span>
                                    <Link to={`/courses/${item.url}`}>
                                      {item.name}
                                    </Link>{" "}
                                    &nbsp;
                                  </span>
                                );
                              }
                            })
                          : ""}
                      </p>
                    </MDBCardText>
                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <p>
                        <p
                          onClick={() => this.setState({ circularFrame: true })}
                          style={{ color: "blue" }}
                        >
                          Read More
                        </p>
                      </p>
                    </MDBCardText>
                    {this.renderModalFrame(item)}
                  </div>
                </div>{" "}
              </MDBCardBody>
            </div>
          );
        }
      });
    } else if (this.state.search == false) {
      const news_data = [...this.state.news_data]
        .sort((a, b) => {
          let aDate = new Date(a.date_of_decision);
          let bDate = new Date(b.date_of_decision);
          return aDate - bDate;
        })
        .filter((item) => item.status == 1);
      if (!news_data || news_data.length <= 0) {
        return (
          <p className="mt-5 text-center">
            There are currently no case laws to view
          </p>
        );
      } else {
        return [...news_data].reverse().map((item, index) => {
          if (item.status == 1) {
            console.log(item, "140");
            const subjects = JSON.parse(item.Subjects);
            console.log(subjects);
            //  console.log(subjectsElement, '184')
            let now = new Date();
            let examDate = new Date(item.date_of_decision);

            return (
              <div>
                <MDBCardBody className="newsDivPosition">
                  <div className="news_justify_content">
                    <div className="NewsDivWidth">
                      <MDBCardTitle tag="h5">
                        {this.renderDate(item, index)}
                      </MDBCardTitle>
                    </div>

                    <div className="NewsDivWidth-title-description">
                      <MDBCardTitle
                        tag="h6"
                        style={{ color: "#303030" }}
                        className="newsHeadingFont"
                      ></MDBCardTitle>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <span>
                          <strong>Citation</strong>:{" "}
                        </span>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.citation }}
                        ></span>
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>
                          Name of the Court / Commission / Tribunal
                        </strong>
                        : {item.court_name}
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>Case Number</strong>: {item.case_number}
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>Title of the Case</strong> : {item.title}
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>Date of Decision / Order</strong> :{" "}
                        {this.commentdate(item.date_of_decision)}&nbsp;
                        {this.commentMonth(item.date_of_decision)},{" "}
                        {this.commentYear(item.date_of_decision)}
                      </MDBCardText>

                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>Subject / Issue</strong> : {item.issue}
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <strong>Synopsis</strong>:
                        <div
                          dangerouslySetInnerHTML={{ __html: item.synopsis }}
                        ></div>
                      </MDBCardText>

                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <p className="related-subjects-caselaw">
                          <strong>Related Subjects</strong>:&nbsp;
                          {subjects != null
                            ? subjects.map((item, index) => {
                                if (index !== subjects.length - 1) {
                                  return (
                                    <span>
                                      <Link to={`/courses/${item.url}`}>
                                        {item.name}
                                      </Link>
                                      , &nbsp;
                                    </span>
                                  );
                                } else {
                                  return (
                                    <span>
                                      <Link to={`/courses/${item.url}`}>
                                        {item.name}
                                      </Link>{" "}
                                      &nbsp;
                                    </span>
                                  );
                                }
                              })
                            : ""}
                        </p>
                      </MDBCardText>
                      <MDBCardText
                        style={{ color: "#303030" }}
                        className="newsText"
                      >
                        <p>
                          <a
                            onClick={() =>
                              this.setState({ circularFrame: true })
                            }
                            style={{ color: "blue" }}
                          >
                            Read More
                          </a>
                        </p>
                      </MDBCardText>
                      {this.renderModalFrame(item)}
                    </div>
                  </div>{" "}
                </MDBCardBody>
              </div>
            );
          }
        });
      }
    }
  }
  searchByType(text) {
    this.setState({ textToFind: text, search: true });
  }

  renderExamNavigation() {
    return (
      <div
        style={{ width: "100%" }}
        className="mt-2 align-items-center d-flex justify-content-between "
      >
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 8%" }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / Upcoming Exams
        </p>
        <div>
          <FacebookShareButton
            url="https://www.vimasmiham.com/case-laws.html"
            className="m-1"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton
            url="https://www.vimasmiham.com/case-laws.html"
            className="m-1"
          >
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <LinkedinShareButton
            url="https://www.vimasmiham.com/case-laws.html"
            className="m-1"
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TwitterShareButton
            url="https://www.vimasmiham.com/case-laws.html"
            className="m-1"
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <EmailShareButton
            url="https://www.vimasmiham.com/case-laws.html"
            className="m-1"
          >
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    );
  }

  renderExamTab() {
    return (
      <div className="news-tab-btn-div d-flex">
        <button className="btn-news-half"></button>
        <button className="btn-news" onClick={() => this.selectExams()}>
          <span className="btn-news-text"> IRDAI</span>{" "}
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Industry</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Exams</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Others</span>
        </button>
        <button className="btn-news-half"></button>
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Helmet>
            <title>Case Laws | Vima Smiham</title>
          </Helmet>
          <div className="">
            <NewHeader
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div>

          <div className="exam-news-main mb-5">
            <div className="w-10 d-flex justify-content-centUpcoming Examser News-heading actsRegulation caseLawsSearch">
              <h1 className="exam-news-heading-font text-center w-100">
                Case Laws
              </h1>
            </div>

            <div>{this.renderExamNavigation()}</div>

            <Quicklinks />

            <div className="inputBox">
              <input
                type="text"
                className="actsSearch"
                placeholder="Search Case Laws here..."
                onChange={(e) => this.searchByType(e.target.value)}
              ></input>
            </div>
            <div className="d-none">{this.renderExamTab()}</div>

            <div>{this.renderNews()}</div>
          </div>

          <div className="windowView">
            <MainFooterPage />
          </div>

          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default ExamNews;
