import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import swal from "sweetalert";
import { css } from "@emotion/core";
import { MDBTable, MDBTableBody } from "mdbreact";
// import { HalfCircleSpinner } from 'react-epic-spinners';
import GridLoader from "react-spinners/GridLoader";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import "../tax/addTax.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

class AdminNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      news: [],
      showAction: false,
      draft_count: 0,
      published_count: 0,
      trash_count: 0,
      all_selected: true,
      draft_selected: false,
      published_selected: false,
      trash_selected: false,
      selected_data: []
    };
  }
  componentWillMount() {
    this.setState({ loading: true }, () => this.getNews());
  }

  componentDidMount() {
    window.localStorage.removeItem("news_item");
  }

  getNews = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_news", {
        method: "GET"
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "xxx");
          var draft_count = 0,
            published_count = 0,
            trash_count = 0;
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].selected = 0;
            if (responseJson[v].status == 0) {
              draft_count = draft_count + 1;
            } else if (responseJson[v].status == 1) {
              published_count = published_count + 1;
            } else if (responseJson[v].status == 2) {
              trash_count = trash_count + 1;
            }
          }
          this.setState({
            news: responseJson,
            draft_count: draft_count,
            published_count: published_count,
            trash_count: trash_count,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  handleEditNews(item) {
    window.localStorage.setItem("news_item", JSON.stringify(item));
    window.localStorage.setItem("news_list", JSON.stringify(this.state.news));
    this.props.history.push("/admin/edit-news");
  }

  handleAddNews() {
    window.localStorage.setItem("news_list", JSON.stringify(this.state.news));
    this.props.history.push("/admin/add-news");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditNews(item, index)}
              className="actionstext"
            >
              Edit
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext">Edit</span>{" "}
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { news } = this.state;
    let selected_page = news[index];
    for (var v = 0; v < news.length; v++) {
      news[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      news
    });
  }
  hideactionbutton() {
    let { news } = this.state;

    for (var v = 0; v < news.length; v++) {
      news[v].selected = 0;
    }

    this.setState({
      news
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }

  changeStatus(item, status) {
    this.setState({
      loading: true
    });
    let details = {
      news_id: item.news_id,
      status: status
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/news_status", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          this.setState({loading:false},()=>this.getNews())
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={()=>this.changeStatus(item,0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={()=>this.changeStatus(item,1)}
        />
      );
    }
  }

  renderTableData(item, index) {
    if (this.state.all_selected == true) {
      if (item.status != 2) {
        // console.log(this.state.all_selected,item.status,'bigE')
        return (
          <tr key={item.news_id}>
            {/* <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
                >
                  {item.news_title}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {" "}
              <span
                dangerouslySetInnerHTML={{ __html: item.news_description }}
                style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
              ></span>
            </td>
            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    } else if (this.state.published_selected == true) {
      if (item.status == 1) {
        return (
          <tr key={item.news_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
                >
                  {item.news_title}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {" "}
              <span
                style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
              >
                {item.news_description}
              </span>{" "}
            </td>
            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    } else if (this.state.draft_selected == true) {
      if (item.status == 0) {
        return (
          <tr key={item.news_id}>
            {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
                >
                  {item.news_title}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td>
              {" "}
              <span
                style={{ fontFamily: "OpenSans-Semibold", color: "#0073aa" }}
              >
                {item.news_description}
              </span>{" "}
            </td>
            <td>{this.renderActiveStatus(item)}</td>
          </tr>
        );
      }
    }
  }

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { news, selected_data } = this.state;
    let selected_item = news[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ news });
  }

  handleAllChecked = event => {
    let { news } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < news.length; v++) {
        news[v].checked = 1;
      }
      this.setState({ selected_data: news });
    } else {
      for (var v = 0; v < news.length; v++) {
        news[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ news });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={25}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="subject-page-list">
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={13} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0, marginRight: "5px" }}>
                  All News
                </h3>
                <span
                  onClick={() => this.handleAddNews()}
                  className="add_new_button"
                >
                  Add New
                </span>
              </div>
              <div>
                <ul className="subsubsub">
                  <li className="all">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: true,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: false
                        })
                      }
                      className={
                        this.state.all_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                      aria-current="page"
                    >
                      All{" "}
                      <span className="count">
                        ({this.state.draft_count + this.state.published_count})
                      </span>
                    </span>{" "}
                  </li>
                  <li className="publish">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: true,
                          trash_selected: false,
                          draft_selected: false
                        })
                      }
                      className={
                        this.state.published_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      Active
                      <span className="count">
                        ({this.state.published_count})
                      </span>
                    </span>{" "}
                  </li>
                  <li className="draft">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: true
                        })
                      }
                      className={
                        this.state.draft_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      In-Active
                      <span className="count">({this.state.draft_count})</span>
                    </span>{" "}
                  </li>
                </ul>

                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}>
                  <tr style={{ backgroundColor: "white" }}>
                    <th className="">Exam Name</th>
                    <th className="textFontSize">Exam Description</th>
                    <th>Status</th>
                  </tr>

                  <MDBTableBody>
                    {this.state.news.sort((a,b)=>(new Date(b.news_date) - new Date(a.news_date))).map((item, index) => {
                      return this.renderTableData(item, index);
                    })}
                  </MDBTableBody>
                </MDBTable>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default AdminNews;
