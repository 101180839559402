import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import "../AddSubject/addSubject.css";
import Select from "react-select";
import CheckRight from "../../../Assets/images/checkright.png";
import CheckWrong from "../../../Assets/images/checkwrong.png";
import ReactTooltip from "react-tooltip";

import {
  MDBNavbar,
  MDBBtn,
  MDBNavItem,
  MDBNavbarNav,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";
import Fade from "react-reveal";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class EditSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      instructorSay: "",
      subjectId: ``,
      subjectName: "",
      subjectSubtitle: "",
      subjectCode: "",
      selectedAuthors: [],
      subjectCost: ``,
      subjectDuration: ``,
      testIDs: [],
      selectedTestIDs: [],
      subjectThumbnail: null,
      editedThumbnail: null,
      subjectThumbnailUrl: ``,
      subjectDescriptions: [
        {
          id: `1`,
          description: "",
        },
      ],
      learningRequirements: [
        {
          id: `1`,
          requirement: "",
        },
      ],
      learningTopics: [
        {
          id: `1`,
          topic: "",
        },
      ],
      exams: [],
      selectedExams: [],
      topicsArray: [],
      selectedTopics: [],
      dragDropTopics: [],
      topicListShowing: true,
      filename: "",
      subjectList: [],
      selectedSubjectList: [],
      url: "",
      seo_title: "",
      seo_description: "",
      urlExists: null,
      testChecked: false,
    };
    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(e) {
    console.log(e);
    this.setState({
      instructorSay: e.editor.getData(),
    });
  }

  componentWillMount() {
    if (JSON.parse(window.localStorage.getItem("subject_item"))) {
      this.getSubjectDetails();
    } else {
      this.props.history.push("/admin/subject-list");
    }
    this.getSubjectList();
    this.getTopics();
  }

  componentDidMount() {}

  getTopics = async () => {
    var topic_list = window.localStorage.getItem("topic_list");
    this.setState({
      topicsArray: JSON.parse(topic_list),
    });
  };

  //GETS , POSTS , FORM VALIDATION

  getSubjectDetails = async () => {
    var subject_item = JSON.parse(window.localStorage.getItem("subject_item"));

    console.log(subject_item.description.length, "768");
    let descriptionGet;
    let learningTopicGet;
    let requirementsGet;
    //    let v

    if (subject_item.description.length == 0) {
      descriptionGet = [];
    } else {
      descriptionGet = JSON.parse(subject_item.description);
    }
    if (subject_item.learning_topic.length == 0) {
      learningTopicGet = [];
    } else {
      learningTopicGet = JSON.parse(subject_item.learning_topic);
    }
    if (subject_item.requirements.length == 0) {
      requirementsGet = [];
    } else {
      requirementsGet = JSON.parse(subject_item.requirements);
    }

    this.setState(
      {
        subjectId: subject_item.subject_id,
        subjectName: subject_item.subject_name,
        subjectSubtitle: subject_item.sub_title,
        subjectCode: subject_item.subject_code,
        subjectCost: Number(subject_item.cost),
        old_subjectCost: Number(subject_item.old_cost),
        subjectDuration: Number(subject_item.duration),
        selectedSubjectList: JSON.parse(subject_item.related_subjects),
        subjectDescriptions: descriptionGet,
        learningTopics: learningTopicGet,
        learningRequirements: requirementsGet,
        instructorSay: subject_item.instructor_say,
        selectedExams: JSON.parse(subject_item.exams),
        dragDropTopics: JSON.parse(subject_item.topics),
        selectedTopics: JSON.parse(subject_item.topics),
        subjectThumbnail: subject_item.image,
        subjectThumbnailUrl: subject_item.image,
        selectedTestIDs: JSON.parse(subject_item.test_id),
        seo_title: subject_item.page_title_seo,
        seo_description: subject_item.page_description_seo,
        url: subject_item.url,
        filename: subject_item.image.substr(28, subject_item.image.length),
        testChecked: subject_item.test_id == "false" ? false : true,
        loading: false,
      },
      () => this.getExams()
    );
  };

  compare = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };

  gettests = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_tests", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].label = responseJson[v].test_name;
            responseJson[v].value = responseJson[v].test_id;
          }
          console.log(responseJson);
          this.setState({
            testIDs: responseJson,
            loading: false,
          });
        })

        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
          console.log(error);
        }),
    });
    console.log(this.state.testIDs);
  };
  getExams = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].label = responseJson[v].exam_name;
            responseJson[v].value = responseJson[v].exam_id;
          }
          this.setState({
            exams: responseJson.sort(this.compare),
            loading: false,
          });
        })

        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
          console.log(error);
        }),
    });
    // this.gettests();
  };

  getSubjectList = async () => {
    var subject_list = JSON.parse(window.localStorage.getItem("subject_list"));
    let subjectList = [];
    for (var i = 0; i < subject_list.length; i++) {
      var subject_object = new Object();
      subject_object.value = subject_list[i].subject_id;
      subject_object.label = subject_list[i].subject_name;
      subject_object.url = subject_list[i].url;
      subjectList.push(subject_object);
    }

    if (!JSON.parse(window.localStorage.getItem("subject_item"))) {
      this.props.history.push("/admin/subject-list");
    } else {
      this.setState({
        subjectList: subjectList
          .sort(this.compare)
          .filter(
            (item) =>
              item.value !=
              JSON.parse(window.localStorage.getItem("subject_item")).subject_id
          ),
      });
    }
  };

  //ADD FORM

  editSubject() {

    if (this.state.subjectName.length == 0) {
      swal("Check subject name!", "Please enter valid name", "error");
    } else if (isNaN(this.state.subjectCost)) {
      swal("Check cost field!", "Enter valid cost", "error");
    } else if (
      !this.state.selectedExams ||
      this.state.selectedExams.length == 0
    ) {
      swal(
        "Check Exams field",
        "Please select related exams for your course!",
        "error"
      );
    } else if (
      !this.state.dragDropTopics ||
      this.state.dragDropTopics.length == 0
    ) {
      swal("Check Topics", "Please select topics for your course!", "error");
    }
    // else if(!this.state.selectedSubjectList || this.state.selectedSubjectList.length==0){
    //     swal("Check Related Subjects","Please select subjects related to your course!","error");
    // }
    else if (this.state.url == null || this.state.url.length <= 2) {
      swal(
        "Check URL field",
        "Please enter a valid URL for your course!",
        "error"
      );
    } else if (
      parseInt(this.state.old_subjectCost) < parseInt(this.state.subjectCost)
    ) {
      swal(
        "The discounted cost cannot be more than the original cost!",
        "Please revisit cost section.",
        "error"
      );
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });

      if (this.state.editedThumbnail == null) {
        let description;
        let learning_topic;
        let learningRequirements;
        let relatedSubjects;

        if (this.state.subjectDescriptions.length === 0) {
          description = "";
        } else if (this.state.subjectDescriptions[0].description === "") {
          description = "";
        } else {
          description = JSON.stringify(this.state.subjectDescriptions);
        }

        if (this.state.learningTopics.length === 0) {
          learning_topic = "";
        } else if (this.state.learningTopics[0].topic === "") {
          learning_topic = "";
        } else {
          learning_topic = JSON.stringify(this.state.learningTopics);
        }

        if (this.state.learningRequirements.length === 0) {
          learningRequirements = "";
        } else if (this.state.learningRequirements[0].requirement === "") {
          learningRequirements = "";
        } else {
          learningRequirements = JSON.stringify(
            this.state.learningRequirements
          );
        }

        //   if(this.state.selectedSubjectList=== 0){
        //     relatedSubjects=[]
        //   }
        //   else{

        //     relatedSubjects=JSON.stringify(this.state.selectedSubjectList)
        //   }

        let details = {
          subject_id: this.state.subjectId,
          subject_name: this.state.subjectName,
          sub_title: this.state.subjectSubtitle,
          cost: this.state.subjectCost,
          old_cost: this.state.old_subjectCost,
          duration: this.state.subjectDuration,
          description: description,
          instructor_say: this.state.instructorSay,
          page_title_seo: this.state.seo_title,
          page_description_seo: this.state.seo_description,
          learning_topic: learning_topic,
          requirements: learningRequirements,
          subject_code: this.state.subjectCode,
          test_id: JSON.stringify(this.state.testChecked),
          related_subjects: JSON.stringify(this.state.selectedSubjectList),
          exams: JSON.stringify(this.state.selectedExams),
          topics: JSON.stringify(this.state.dragDropTopics),
          url: this.state.url,
          status: 1,
        };

        console.log(details, "111");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_subject", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              this.props.history.push("/admin/subject-list");
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      } else {
        let formData = new FormData();
        // var pdf_filename = (this.state.selected_products.pdf_file).substr(28,(this.state.selected_products.pdf_file).length)
        formData.append("subject_id", this.state.subjectId);
        formData.append("subject_name", this.state.subjectName);
        formData.append("sub_title", this.state.subjectSubtitle);
        formData.append("cost", this.state.subjectCost);
        formData.append("old_cost", this.state.old_subjectCost);
        formData.append("instructor_say", this.state.instructorSay);
        formData.append("duration", this.state.subjectDuration);
        if (this.state.subjectDescriptions.length === 0) {
          formData.append("description", "");
        } else if (this.state.subjectDescriptions[0].description === "") {
          formData.append("description", "");
        } else {
          formData.append(
            "description",
            JSON.stringify(this.state.subjectDescriptions)
          );
        }

        if (this.state.learningTopics.length === 0) {
          formData.append("learning_topic", "");
        } else if (this.state.learningTopics[0].topic === "") {
          formData.append("learning_topic", "");
        } else {
          formData.append(
            "learning_topic",
            JSON.stringify(this.state.learningTopics)
          );
        }

        if (this.state.learningRequirements.length === 0) {
          formData.append("requirements", "");
        } else if (this.state.learningRequirements[0].requirement === "") {
          formData.append("requirements", "");
        } else {
          formData.append(
            "requirements",
            JSON.stringify(this.state.learningRequirements)
          );
        }

        //   if(this.state.selectedSubjectList.length === 0){
        //     formData.append('related_subjects','')
        //   }
        //   else{

        //     formData.append('related_subjects',JSON.stringify(this.state.selectedSubjectList))
        //   }

        formData.append(
          "related_subjects",
          JSON.stringify(this.state.selectedSubjectList)
        );

        formData.append("test_id", JSON.stringify(this.state.testChecked));
        formData.append("exams", JSON.stringify(this.state.selectedExams));
        formData.append("topics", JSON.stringify(this.state.dragDropTopics));

        formData.append("status", 1);
        formData.append("file", this.state.editedThumbnail);
        formData.append("filename", this.state.filename);

        for (let key of formData.entries()) {
        }

        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_subject", {
            method: "POST",
            body: formData,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              this.props.history.push("/admin/subject-list");
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            }),
        });
      }
    }
  }

  //GET FUNCTIONS

  //REACT PICKY EVENTS
  selectMultipleOption(value) {
    this.setState({ selectedTopics: value });
  }

  //ITEM RENDERER DRAG DROP
  itemRenderer(item, index) {
    return (
      <div className="item">
        <p className="title">{item.name}</p>
        <button
          className="delete-subject-topic-button"
          onClick={() => this.deleteSubjectTopic(item)}
        >
          <i className="fas fa-trash"></i>
        </button>
      </div>
    );
  }

  //HANDLE CLICK EVENTS
  addSubjectDescription() {
    let { subjectDescriptions } = this.state;
    subjectDescriptions.push({
      id: `${subjectDescriptions.length + 1}`,
      description: "",
    });
    this.setState({ subjectDescriptions });
  }

  removeSubjectDescription(index) {
    let { subjectDescriptions } = this.state;
    if (subjectDescriptions.length > 0) {
      subjectDescriptions.splice(index, 1);
    }
    for (var i = 0; i < subjectDescriptions.length; i++) {
      subjectDescriptions[i].id = i + 1;
    }
    this.setState({ subjectDescriptions });
  }

  addLearningRequirement() {
    let { learningRequirements } = this.state;
    learningRequirements.push({
      id: `${learningRequirements.length + 1}`,
      requirement: "",
    });
    this.setState({ learningRequirements });
  }

  removeLearningRequirement(index) {
    let { learningRequirements } = this.state;
    if (learningRequirements.length > 0) {
      learningRequirements.splice(index, 1);
    }
    for (var i = 0; i < learningRequirements.length; i++) {
      learningRequirements[i].id = i + 1;
    }
    this.setState({ learningRequirements });
  }

  addLearningTopic() {
    let { learningTopics } = this.state;
    learningTopics.push({ id: `${learningTopics.length + 1}`, topic: "" });
    this.setState({ learningTopics });
  }

  removeLearningTopic(index) {
    let { learningTopics } = this.state;
    if (learningTopics.length > 0) {
      learningTopics.splice(index, 1);
    }
    for (var i = 0; i < learningTopics.length; i++) {
      learningTopics[i].id = i + 1;
    }
    this.setState({ learningTopics });
  }

  addSubjectTopics(selectedTopicsArray) {
    if (selectedTopicsArray.length == 0) {
      swal({
        title: `No Selections Made`,
        text: `You have not seleted any topics to add`,
        icon: "error",
        buttons: false,
        dangerMode: true,
      });
    } else {
      swal({
        title: `Confirm selection?`,
        text: `You are adding ${selectedTopicsArray.length} topics to this course`,
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((willAdd) => {
        if (willAdd) {
          var array = [];
          var i = 0;

          selectedTopicsArray.forEach((element) => {
            var topicObject = new Object();
            topicObject.id = element.topic_id;
            topicObject.topic_id = element.topic_id;
            topicObject.name = element.topic_name;
            topicObject.topic_name = element.topic_name;
            topicObject.sortOrder = i;
            array.push(topicObject);
            i++;
          });
          this.setState({ dragDropTopics: array });
        } else {
        }
      });
    }
  }

  deleteSubjectTopic(item) {
    let { dragDropTopics } = this.state;
    var newTopicList = dragDropTopics.filter(
      (Listitem) => Listitem.id != item.id
    );
    var i = 0;
    newTopicList.forEach((element) => {
      element.sortOrder = i;
      i++;
    });
    this.setState({
      dragDropTopics: newTopicList,
      selectedTopics: newTopicList,
    });
  }

  //HANDLE CHANGE FUNCTIONS

  handleSubjectNameChange = (text) => {
    this.setState({ subjectName: text.target.value });
  };

  handleSubjectSubtitleChange = (text) => {
    this.setState({ subjectSubtitle: text.target.value });
  };

  handleCostChange = (cost) => {
    this.setState({ subjectCost: cost.target.value });
  };

  handleOldCostChange = (cost) => {
    this.setState({ old_subjectCost: cost.target.value });
  };

  handleDurationChange = (duration) => {
    this.setState({ subjectDuration: duration.target.value });
  };

  handleTestIdChange = (testid) => {
    this.setState({ testID: testid.target.value });
  };

  handleSubjectDescription(item, index) {
    let subjectDescriptions = this.state.subjectDescriptions;
    let descriptionObject = subjectDescriptions[index];
    descriptionObject.description = item.target.value;
    this.setState({ subjectDescriptions });
  }

  handleLearningRequirement(item, index) {
    let { learningRequirements } = this.state;
    let requirementObject = learningRequirements[index];
    requirementObject.requirement = item.target.value;
    this.setState({ learningRequirements });
  }

  handleLearningTopic(item, index) {
    let { learningTopics } = this.state;
    let topicObject = learningTopics[index];
    topicObject.topic = item.target.value;
    this.setState({ learningTopics });
  }

  handleRLDDChange(reorderedItems) {
    const sortItem = reorderedItems.map((ele, index) => {
      ele.sortOrder = index;
      return ele;
    });

    console.log("drag", reorderedItems);
    this.setState({ dragDropTopics: sortItem });
  }

  //RENDER FUNCTIONS
  renderSubjectNameSection() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.subjectName}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.handleSubjectNameChange(text)}
        />
      </div>
    );
  }

  renderSubjectSubtitleSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Subtitle:
        </label>
        <input
          value={this.state.subjectSubtitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form "
          onChange={(text) => this.handleSubjectSubtitleChange(text)}
        />
      </div>
    );
  }

  renderSubjectCode() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Subject Code:
        </label>
        <input
          value={this.state.subjectCode}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) =>
            this.setState({ subjectCode: text.target.value }, () =>
              console.log(this.state.subjectCode)
            )
          }
        />
      </div>
    );
  }

  renderCostSection() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Discounted Cost:
        </label>
        <input
          value={this.state.subjectCost}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(cost) => this.handleCostChange(cost)}
        />
      </div>
    );
  }

  renderDiscountedSection() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="cost-label subject-labels"
        >
          Original Cost:
        </label>
        <input
          value={this.state.old_subjectCost}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control cost-form custom-form w-25"
          onChange={(cost) => this.handleOldCostChange(cost)}
        />
      </div>
    );
  }

  renderCourseDurationSection() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          Duration in minutes (approx):-
        </label>
        <input
          value={this.state.subjectDuration}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form w-25"
          onChange={(duration) => this.handleDurationChange(duration)}
        />
      </div>
    );
  }

  renderCourseSeoTitle() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          SEO Title
        </label>
        <input
          value={this.state.seo_title}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(text) => this.setState({ seo_title: text.target.value })}
        />
      </div>
    );
  }

  renderCourseSeoDescription() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          SEO Description
        </label>
        <input
          value={this.state.seo_description}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form "
          onChange={(text) =>
            this.setState({ seo_description: text.target.value })
          }
        />
      </div>
    );
  }

  handleUrlValidation() {
    console.log(this.state);
    let exists = false;
    this.state.subjectList.forEach((element) => {
      if (element.url == this.state.url) {
        exists = true;
      }
    });
    if (exists) {
      this.setState({ urlExists: true });
    } else {
      this.setState({ urlExists: false });
    }
  }

  renderCourseUrl() {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
        >
          URL / Link
        </label>
        <input
          value={this.state.url}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control duration-form custom-form"
          onChange={(text) =>
            this.setState({ url: text.target.value }, () =>
              this.handleUrlValidation()
            )
          }
        />
        {this.state.urlExists == null ? null : (
          <div>
            <img
              style={{ width: "25px" }}
              alt={
                this.state.urlExists ? "URL already exists" : "URL is unique"
              }
              src={this.state.urlExists ? CheckWrong : CheckRight}
            />
          </div>
        )}
      </div>
    );
  }

  renderTestIdSection() {
    return (
      <div className="col-12  test-id-section d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="test-id-label subject-labels"
        >
          Test included:
        </label>
        {/* <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={e => this.setState({ selectedTestIDs: e })}
          value={this.state.selectedTestIDs}
          className="select-testid select"
          options={this.state.testIDs}
        /> */}
        <input
          type="checkbox"
          class="custom-control-input-subject-test d-block"
          style={{ width: "50px" }}
          id="testIncludedCheck"
          checked={this.state.testChecked}
          onChange={() =>
            this.setState(
              (prevState) => {
                return { testChecked: !prevState.testChecked };
              },
              () => console.log(this.state)
            )
          }
        />
      </div>
    );
  }

  renderEditImage() {
    let imageUrl = "";

    if (this.state.subjectThumbnail == null) {
      if (this.state.editedThumbnail == null) {
        return (
          <div className="col-12 d-flex align-items-center subject-thumbnail-container">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="subject-labels"
            >
              Subject Thumbnail: <br />{" "}
              <span className="text-right text-muted ml-auto">
                {" "}
                <i>(275 x 180 pixels)</i>
              </span>
            </label>

            <input
              type="file"
              className="thumbnail-file-input d-block"
              id="inputGroupFile01"
              accept="image/*"
              aria-describedby="inputGroupFileAddon01"
              onChange={(event) =>
                this.setState({
                  editedThumbnail: event.target.files[0],
                  subjectThumbnailUrl: URL.createObjectURL(
                    event.target.files[0]
                  ),
                })
              }
            />
          </div>
        );
      } else {
        return (
          <div
            className="col-12 subject-thumbnail-container"
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="image-label subject-labels h-100"
            >
              Subject Thumbnail: <br />{" "}
              <span className="text-right text-muted ml-auto">
                {" "}
                <i>(275 x 180 pixels)</i>
              </span>
            </label>
            <div className="position-relative">
              <img
                src={this.state.subjectThumbnailUrl}
                className=""
                style={{ margin: "10px 0px", width: "300px" }}
                alt="news_image"
              />
              <MDBIcon
                className="cross_icon"
                onClick={() => this.setState({ editedThumbnail: null })}
                icon="times-circle"
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "-5px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels h-100"
          >
            Subject Thumbnail: <br />{" "}
            <span className="text-right text-muted ml-auto">
              {" "}
              <i>(275 x 180 pixels)</i>
            </span>
          </label>
          <div className="position-relative">
            <img
              src={this.state.subjectThumbnail}
              className=""
              style={{ margin: "10px 0px", width: "300px" }}
              alt="news_image"
            />
            <MDBIcon
              className="cross_icon"
              onClick={() => this.setState({ subjectThumbnail: null })}
              icon="times-circle"
              style={{
                position: "absolute",
                top: "20px",
                right: "-5px",
                cursor: "pointer",
              }}
            />
          </div>
        </div>
      );
    }
  }

  renderSubjectDescriptions() {
    return this.state.subjectDescriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="subject-description"
                className="subject-description-textarea w-100"
                type="text"
                value={item.description}
                onChange={(text) => this.handleSubjectDescription(text, index)}
              />
              <MDBIcon
                className="cross_icon"
                onClick={() => this.removeSubjectDescription(index)}
                icon="times-circle"
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }

  renderLearningRequirements() {
    return this.state.learningRequirements.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="learning-requirement-label"
                className="subject-description-textarea w-100"
                type="text"
                value={item.requirement}
                onChange={(text) => this.handleLearningRequirement(text, index)}
              />
              <MDBIcon
                className="cross_icon"
                onClick={() => this.removeLearningRequirement(index)}
                icon="times-circle"
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }

  renderLearningTopics() {
    return this.state.learningTopics.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="d-flex align-items-start mt-3">
            <label className="p-1"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <textarea
                label="learning-topic-label"
                className="subject-description-textarea w-100"
                type="text"
                value={item.topic}
                onChange={(text) => this.handleLearningTopic(text, index)}
              />
              <MDBIcon
                className="cross_icon"
                onClick={() => this.removeLearningTopic(index)}
                icon="times-circle"
              />
            </div>
          </div>
          <div className="w-100" />
        </React.Fragment>
      );
    });
  }

  renderSubjectDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Description(s):
          </label>

          <div className="descriptions-container">
            {this.renderSubjectDescriptions()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addSubjectDescription()}
            >
              Add para
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderLearningRequirementsContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-descriptions-container d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Learning Requirement(s):
          </label>
          <div className="descriptions-container">
            {this.renderLearningRequirements()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addLearningRequirement()}
            >
              Add para
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderLearningTopicsContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Learning Topic(s):
          </label>
          <div className="descriptions-container">
            {this.renderLearningTopics()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addLearningTopic()}
            >
              Add para
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderExamSection() {
    return (
      <div className="col-12 exam-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="exam-label subject-labels pl-0"
        >
          Related Exams:
        </label>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(e) => this.setState({ selectedExams: e })}
          value={this.state.selectedExams}
          className="select-exam select"
          options={this.state.exams}
        />
      </div>
    );
  }

  renderTopicListSection() {
    return (
      <React.Fragment>
        <button
          className="show-topics-list-button"
          onClick={() =>
            this.setState({ topicListShowing: !this.state.topicListShowing })
          }
        >
          Topics List <i className="fa fa-chevron-down" />
        </button>
        {this.state.topicListShowing ? (
          <div className="topic-list-picky-container">
            <Picky
              value={this.state.selectedTopics}
              options={this.state.topicsArray}
              onChange={(value) => this.selectMultipleOption(value)}
              open={true}
              valueKey="topic_id"
              labelKey="topic_name"
              multiple={true}
              includeSelectAll={true}
              includeFilter={true}
              dropdownHeight={350}
              keepOpen={true}
              placeholder="Topics"
            />
            <div>
              <button
                className="w-50 float-right admin-button-style"
                onClick={() => this.addSubjectTopics(this.state.selectedTopics)}
              >
                Save Topics
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  renderTopicStructureSection() {
    if (this.state.dragDropTopics.length != 0)
      return (
        <RLDD
          cssClasses="drag-drop-topic-list"
          items={this.state.dragDropTopics}
          itemRenderer={this.itemRenderer}
          onChange={this.handleRLDDChange}
        />
      );
    else {
      return (
        <p className="text-muted text-center">
          There are currently no topics for this course!
        </p>
      );
    }
  }

  renderSubjectTopicsSection() {
    return (
      <React.Fragment>
        <div className="col-12 subject-topics-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="exam-label subject-labels py-3"
          >
            Add Topics:
          </label>
          <div className="row mx-0 bg-white">
            <div className="col-3 topic-list-column px-0">
              {this.renderTopicListSection()}
            </div>

            <div className="col-9 topic-structure-column">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="exam-label subject-labels text-center w-100"
              >
                Topics Order:
              </label>
              <hr />
              {this.renderTopicStructureSection()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderRelatedSubjectsSection() {
    return (
      <React.Fragment>
        <div className="col-12 related-subjects-container my-2">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="related-subjects-label subject-labels pl-0"
          >
            Related Subjects:
          </label>
          <Select
            closeMenuOnSelect={false}
            isMulti
            onChange={(e) =>
              this.setState({ selectedSubjectList: e }, () =>
                console.log(this.state.selectedSubjectList)
              )
            }
            value={this.state.selectedSubjectList}
            className="select-exam select"
            options={this.state.subjectList}
          />
        </div>
      </React.Fragment>
    );
  }

  renderInstructorSay() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center mt-1 mr-1">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            What Instructor has to say:
          </label>
          <div className="descriptions-container">
            <div className="position-relative w-100 ml-3 mr-4">
              <CKEditor
                data={this.state.instructorSay}
                onChange={this.onEditorChange}
                className="textFontSize ckeditorInstructorSay mt-1"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={3} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/subject-list")
                        }
                      >
                        All Subjects
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.editSubject()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Edit Subject</h3>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderSubjectCode()}
                        {this.renderSubjectNameSection()}
                        {this.renderSubjectSubtitleSection()}
                        {/* <hr /> */}
                        {this.renderCostSection()}
                        {this.renderDiscountedSection()}
                        {this.renderCourseDurationSection()}
                        {/* <hr /> */}
                        {this.renderTestIdSection()}
                        {/* <hr /> */}
                        {this.renderEditImage()}
                        {/* <hr /> */}
                        {this.renderSubjectDescriptionContainer()}
                        {/* <hr /> */}
                        {this.renderLearningRequirementsContainer()}
                        {/* <hr /> */}
                        {this.renderLearningTopicsContainer()}
                        {/* <hr /> */}
                        {this.renderInstructorSay()}
                        {this.renderCourseSeoTitle()}
                        {this.renderCourseSeoDescription()}
                        {this.renderCourseUrl()}
                      </div>
                    </div>
                    <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4">
                      {this.renderExamSection()}
                      {this.renderRelatedSubjectsSection()}
                    </div>
                  </div>

                  <div className="subject-topics-container mx-4 mt-4">
                    <div className="row p-0 mx-0">
                      {this.renderSubjectTopicsSection()}
                    </div>
                    {/* <hr /> */}
                  </div>

                  <hr />

                  <button
                    className="add-subject my-5 float-right mr-4"
                    onClick={() => this.editSubject()}
                  >
                    Save &amp; Publish
                  </button>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EditSubject;
