import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import {
  MDBIcon,
} from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class InlighterVein extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loadng:true,
      position: "",
      title: "",
      name: "",
      description: "",
      InlighterVein: [],
      inputLink:''
    };
  }

  componentWillMount() {}

  componentDidMount() {
    this.getInlighterVein();
  }

  //get Inlighter Vein

  getInlighterVein = async () => {
    this.setState({
      loading: false
    });

    let formData = new FormData();

    formData.append("homepage_id", 1);

    for (let key of formData.entries()) {
      console.log(key[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_inlighter_vein", {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson[0].inlighter_vein, "InlighterVein");
          let inlighter_van = JSON.parse(responseJson[0].inlighter_vein);

          this.setState(
            {
              title: inlighter_van.title,
              name: inlighter_van.name,
              description: inlighter_van.description,
              position: inlighter_van.position,
              inputLink: inlighter_van.inputLink
            },
            () => console.log(this.state.title)
          );

          // this.props.history.push("/admin/InlighterVein");
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  //ADD Inlighter Vein

  addInlighterVein() {
    if (this.state.title.length === 0)
      return swal("Check title!", "Please enter title", "error");

    if (this.state.position.length === 0)
      return swal("Check position!", "Please enter position", "error");
    else if (this.state.name.length === 0)
      return swal("Check the name!", "Please enter name", "error");
    else if (this.state.description.length === 0)
      return swal(
        "Check the description!",
        "Please enter description",
        "error"
      );
    else if (this.state.inputLink.length === 0)
      return swal("Check the Link!", "Please enter Link", "error");
    else {
      var InlighterVeinobject = new Object();

      InlighterVeinobject.position = this.state.position;
      InlighterVeinobject.title = this.state.title;
      InlighterVeinobject.name = this.state.name;
      InlighterVeinobject.description = this.state.description;
      InlighterVeinobject.inputLink = this.state.inputLink;

      let InlighterVein_object = JSON.stringify(InlighterVeinobject);

      let formData = new FormData();

      formData.append("homepage_id", 1);

      formData.append("inlighter_vein", InlighterVein_object);

      for (let key of formData.entries()) {
        console.log(key[0] + ",with photo " + key[1]);
      }

      this.setState({
        response: fetch("https://www.vimasmiham.com/add_inlighter_vein", {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "QWERTy");
            swal("Success", "Section succesfullly updated", "success");
            this.props.history.push("/admin/InlighterVein");
          })
          .catch(error => {
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  }

  renderImage() {
    if (this.state.inputLink.length == 0) {
      return "";
    } else {
      return (
        <img
          src={this.state.inputLink}
          style={{ width: "150px", marginTop: "10px" }}
        ></img>
      );
    }
  }


  
  handleMediaChooseImage = inputLink =>{
    this.setState({inputLink})
  }
 


  handlechangeurl = text => this.setState({ inputLink: text.target.value });

  //RENDER FUNCTIONS

  renderNameSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ name: text.target.value })}
        />
      </div>
    );
  }

  renderTitleSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Title:
        </label>
        <input
          value={this.state.title}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ title: text.target.value })}
        />
      </div>
    );
  }

  renderPositionSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Position
        </label>
        <input
          // value={this.state.position}
          value={this.state.position}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ position: text.target.value })}
        />
      </div>
    );
  }

  renderDescriptionSection() {
    return (
      <div className="col-12 subject-description-container  d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-labels h-100"
        >
          Description :
        </label>
        <div className="descriptions-container">
          <div className="d-flex align-items-start mt-3">
            <div className="position-relative w-100 mr-4">
              <textarea
                style={{ marginLeft: "3%", width:"84%" }}
                value={this.state.description}
                label="subject-description"
                className="subject-description-textarea "
                type="text"
                onChange={text =>
                  this.setState({ description: text.target.value })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChooseFile = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
          style={{ borderRight: "none" }}
        >
          Enter file link:
        </label>

        <div
          className="flex-column "
          style={{
            width: "39.4%",
            borderLeft: "1px solid #bfbfbf",
            height: "100%"
          }}
        >
          <input
            style={{ marginTop: "10px", width: "100%"}}
            type="text"
            placeholder="Choose File From Gallery"
            value={this.state.inputLink}
            onChange={text => this.handlechangeurl(text)}
            id="download_link"
            className="form-control duration-form custom-form link-placeholder "
          />
      
      <div   style={{ marginLeft:'5%' }}>
        <MediaComponent
       
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    />
                    </div>
        </div>
 
      </div>
    );
  };

  render() {
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={0} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    {/* <button className="admin-button-style ">
                                      Home page
                                    </button> */}

                  
                    <button
                      className="admin-button-style"
                      onClick={() => this.addInlighterVein()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Quote of the day</h3>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4">
                    <div className="row px-0 mx-0">
                      {this.renderTitleSection()}
                      {this.renderDescriptionSection()}
                      {this.renderNameSection()}
                      {this.renderPositionSection()}
                      {this.renderChooseFile()}
                    </div>
                  </div>
                </div>

                {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addExam()}>Save &amp; Publish</button> */}
              </React.Fragment>
            )}
          </div>

          {console.log(this.state)}
        </div>
      </div>
    );
  }
}

export default InlighterVein;
