import React, { Component, Fragment } from "react";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import MetaTags from "react-meta-tags";
import "./allFaculty.css";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";





export default class AllFaculty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart:[],
      facultyMembers: [],
      loading: true,
      facultyTitle:'',
      facultyDescription:'',
    };
  }
componentWillMount(){
this.getfacultySEO()
}

  componentDidMount(){
    
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata)
      },()=>this.getCart());
    }
    this.getFaculty()
  }
  
  getfacultySEO = async () => {
    this.setState({
      loading: true
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_faculty_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "clients");
          this.setState({
            facultyTitle: responseJson[0].faculty_title_seo,
            facultyDescription: responseJson[0].faculty_description_seo,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };


  getCart = () => {

    let details = {
      user_id: this.state.userdata.user_id
    };
  
    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
  
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "cart");
  
          this.setState({
            cart: responseJson
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
  
          console.log(error);
        })
    });
  };

  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p  className="productMainFirstText">
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / Team Vima Smiham
        </p>
      </div>
    );
  }



  getFaculty = () => {
    fetch("https://www.vimasmiham.com/get_our_faculty", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => response.json())
      .then(responseJson =>
        this.setState({ facultyMembers: responseJson, loading: false }, () =>
          console.log(this.state)
        )
      );
  };


  renderFacultyMembers() {
    return [...this.state.facultyMembers].sort((a,b)=>a.sort_order - b.sort_order).map(faculty => {
      console.log(faculty)
      return (
        <div
          className="row align-items-center faculty-member-row mb-4"
          key={faculty.faculty_id}
        >
          <div className="col-12 col-md-3 text-center align-self-start ">
            <img
              src={faculty.faculty_picurl}
              alt={faculty.faculty_name}
              className="faculty-image mb-3 mb-md-0"
            />
          </div>
          <div className="col-12 col-md-9 content-container">
            <h3 className=" text-center text-md-left">{faculty.faculty_name}</h3>
            <h4 className="text-muted  text-center text-md-left faculty_upper"> {faculty.faculty_profession}</h4>
            <div
              dangerouslySetInnerHTML={{ __html: faculty.faculty_description }}
              className="faculty-member-content  text-center text-md-left"
            ></div>
            {/* {faculty.faculty_description} */}
          </div>
        </div>
      );
    });
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div id="faculty-page">
             <NewHeader
            {...this.props}
            cart={
              (this.state.cart.length === undefined ||this.state.cart.length == 0)
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
          
          />
          <div className="about-main ">
            <h1 className="about-us-heading">Team Vima Smiham</h1>
          </div>
          <div>{this.renderAboutNavigation()}</div>
          <MetaTags>
                    <title>{this.state.facultyTitle}</title>

                    <meta
                      id="faculty"
                      name="description"
                      content={this.state.facultyDescription}
                    />

                    {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
                  </MetaTags>
                  <Quicklinks />
          <div className="faculty-members-container container-c">
            {this.renderFacultyMembers()}
          </div>
          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="mobView">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
