import React from "react";

import "./sidebar.css";

import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBInput,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavLink,
  MDBIcon,
  MDBNavItem,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
} from "mdbreact";

class SidebarPage extends React.Component {
  render() {
    return (
      <div
        className="d-print-none"
        style={{
          backgroundColor: "rgb(48,51,54)",
          minHeight: "100vh",
          width: "15%",
        }}
      >
        <h4
          style={{
            fontSize: "15px",
            backgroundColor: "rgb(74, 62, 36)",
            textAlign: "center",
            padding: "5% 0% 5% 0%",
          }}
        >
          {" "}
          <a style={{ color: "#fff" }} href="/" target="_blank">
            Open Website
          </a>
        </h4>
        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/dashboard")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="list-alt" />
              </div>
              <div className="sidebar_dropdown_name">Dashboard</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>
        <div id="dropdown">
          <button className="sidebar-home-btn">
            {" "}
            <MDBIcon icon="tools" />{" "}
            <span className="sidebar-home-btn-span">Homepage</span>{" "}
          </button>
          <div className="dropdown-content">
            <a onClick={() => this.props.history.push("/admin/InlighterVein")}>
              Quote of the day
            </a>
            <a onClick={() => this.props.history.push("/admin/testimonials")}>
              Testimonials
            </a>
            <a onClick={() => this.props.history.push("/admin/faculties")}>
              Team VS
            </a>
            <a onClick={() => this.props.history.push("/admin/subscribers")}>
              Subscribers
            </a>
            <a onClick={() => this.props.history.push("/admin/homepageseo")}>
              HomePage SEO
            </a>
          </div>
        </div>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/verticals")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="align-center" />
              </div>
              <div className="sidebar_dropdown_name">Verticals </div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-exams")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="align-justify" />
              </div>
              <div className="sidebar_dropdown_name">Exams</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/all-exams')} >All Exams</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-exam')} >Add Exams</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/lecture")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="anchor" />
              </div>
              <div className="sidebar_dropdown_name">Lectures</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/lecture')} >All Lectures</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-lecture')} >Add Lectures</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-topics")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="bookmark" />
              </div>
              <div className="sidebar_dropdown_name">Topics</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/all-topics')} >All Topic</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-topic')} >Add Topic</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/subject-list")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="book" />
              </div>
              <div className="sidebar_dropdown_name">Subjects</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/subject-list')} >All Subject</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-subject')} >Add Subject</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/acts-regulations")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="clipboard-list" />
              </div>
              <div className="sidebar_dropdown_name">Acts</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/circulars")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="clipboard-list" />
              </div>
              <div className="sidebar_dropdown_name">Circulars</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/regulations")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="clipboard-list" />
              </div>
              <div className="sidebar_dropdown_name">Regulations</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>


    <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/all-circulars')} >All Circulars</MDBDropdownItem>
    <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-cirular')} >Add Circular</MDBDropdownItem>
   
   
   </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/test")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="award" />
              </div>
              <div className="sidebar_dropdown_name">Test</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/test')} >All Test</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-test')} >Add Test</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-questions")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="question" />
              </div>
              <div className="sidebar_dropdown_name">Questions</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/all-questions')} >All Questions</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-question')} >Add Questions</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/search-question")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="search" />
              </div>
              <div className="sidebar_dropdown_name">Search Questions</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/question-count")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="balance-scale" />
              </div>
              <div className="sidebar_dropdown_name">Questions Count</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/examinations")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="book-open" />
              </div>
              <div className="sidebar_dropdown_name">Examinations</div>
            </div>
          </MDBDropdownToggle>
          {/* <MDBDropdownMenu  className='sidebar_dropdown_Menu_Item'>
 

      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/examinations')} >All Examinations</MDBDropdownItem>
      <MDBDropdownItem className='sidebar_dropdown_Item'  onClick={()=>this.props.history.push('/admin/add-examination')} >Add Examinations</MDBDropdownItem>
     
     
     </MDBDropdownMenu> */}
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/pages")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="file" />
              </div>
              <div className="sidebar_dropdown_name">Pages</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/info-pages")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="file" />
              </div>
              <div className="sidebar_dropdown_name">Info Pages</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/user-details")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="user-alt" />
              </div>
              <div className="sidebar_dropdown_name">User Detail </div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown dropright className="sidebar_dropdown">
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/order-list")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="archive" />
              </div>
              <div className="sidebar_dropdown_name">Orders</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/promocode")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="barcode" />
              </div>
              <div className="sidebar_dropdown_name">PromoCode</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/offer")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="gift" />
              </div>
              <div className="sidebar_dropdown_name">Offer Banner</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-tax")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="hand-holding-usd" />
              </div>
              <div className="sidebar_dropdown_name">Tax</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/upcoming-exams")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="newspaper" />
              </div>
              <div className="sidebar_dropdown_name">Upcoming Exams</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-news")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="newspaper" />
              </div>

              <div className="sidebar_dropdown_name">News</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/case-laws")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="newspaper" />
              </div>
              <div className="sidebar_dropdown_name">Case Laws</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/fun-facts")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="newspaper" />
              </div>
              <div className="sidebar_dropdown_name">Fun Facts</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-tdds")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="newspaper" />
              </div>
              <div className="sidebar_dropdown_name">Quotes</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/quiz")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="question-circle" />
              </div>
              <div className="sidebar_dropdown_name">Quiz</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-downloads")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="arrow-circle-down" />
              </div>
              <div className="sidebar_dropdown_name">Downloads</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>
        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/all-faqs")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="receipt" />
              </div>
              <div className="sidebar_dropdown_name">FAQ</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>
        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/menu")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="caret-square-down" />
              </div>
              <div className="sidebar_dropdown_name">Menu</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/evaluation")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon far icon="caret-square-down" />
              </div>
              <div className="sidebar_dropdown_name">Evaluations</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>

        <MDBDropdown
          dropright
          className="sidebar_dropdown"
          style={{ width: "100%" }}
        >
          <MDBDropdownToggle
            onClick={() => this.props.history.push("/admin/edit-footer")}
            color="gray"
            className="sidebar_dropdown_btn"
            style={{ color: "#fff" }}
          >
            <div className="sidebar_dropdown_name_icon_main">
              {" "}
              <div className="sidebar_dropdown_icon">
                <MDBIcon icon="bookmark" />
              </div>
              <div className="sidebar_dropdown_name">Footer</div>
            </div>
          </MDBDropdownToggle>
        </MDBDropdown>
      </div>
    );
  }
}

export default SidebarPage;
