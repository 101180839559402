import React, { Component, Fragment } from "react";
import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
import MetaTags from "react-meta-tags";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";

export default class InfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seo_title: "",
      seo_description: "",
      content: "",
      page_title: "",
      cart:[],
      loading: true,
    };
  }

  componentDidMount() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    }
    this.getInfoPage();
  }

  getInfoPage = async () => {
    console.log(this.props);
    if (
      !this.props.match.params.info_page ||
      this.props.match.params.info_page == ""
    ) {
      this.props.history.push("/");
    } else {
      let formBody = new FormData();
      formBody.append("page_url", this.props.match.params.info_page);
      let response = await fetch("https://vimasmiham.com/get_info_page", {
        method: "POST",
        body: formBody,
      });
      let page_details = await response.json();
      console.log(page_details);
      this.setState({ page_details: page_details[0], loading:false });
    }
  };

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "cart");

          this.setState({
            cart: responseJson,
            loading:false
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p className="productMainFirstText">
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / {this.state.page_details.page_title}
        </p>
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div className="info-page">
          <NewHeader
            {...this.props}
            cart={
              this.state.cart.length === undefined ||
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
          />
          <div className="about-main">
            <h1 className="about-us-heading">
              {this.state.page_details.page_title || ""}
            </h1>
          </div>
          <div>{this.renderAboutNavigation()}</div>
          <MetaTags>
            <title>{this.state.page_details.seo_title || ""}</title>

            <meta
              id="faculty"
              name="description"
              content={this.state.page_details.seo_description || ''}
            />

            {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
          </MetaTags>
          {/* <Quicklinks /> */}
          <div
            className="faculty-members-container container-c"
            dangerouslySetInnerHTML={{
              __html: this.state.page_details.content,
            }}
          ></div>
          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="mobView">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
