import React from "react";
import "../../Components/Exam-News/Exam-news.css";

import '../../Components/Homepage/homepage.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from 'sweetalert'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import PDFIcon from "../../Assets/images/pdfIcon.png";


import "./download.css";

import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";

class Downloads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: "",
      mainTitle: "",
      faculties:[],
      mainText: "",
      objectiveImage: ``,
      objectiveText:'',
      objectiveTitle: "",
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      downloads:[],
      visionTitle: "",
      visionText: "",
      visionImage: ``,faqs:[],cart:[]
    };
  }
  componentWillMount() {
   
      var userdata = window.localStorage.getItem("userdata");
      if (userdata != null) {
        this.setState({
          userdata: JSON.parse(userdata)
        },()=>this.getCart());
      }
    
    this.getDownloads();
  }
 

  getDownloads = () => {
  
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_downloads", {
        method: "GET",
        headers: {
                   
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson[0]);
var data = []
for(var v=0;v<responseJson.length;v++){
  if(responseJson[v].status==1){
    data.push(responseJson[v])
  }
}
          this.setState({
            downloads:data
          });
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  next() {
    this.slider.slickNext();
  }

  prev() {
    this.slider.slickPrev();
  }
  renderObjectives(){

    return(
      <div className="row mission-row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">

      <img src={this.state.objectiveImage}  className='image-about-us'/>
 
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
     <p className='about-us-description-font'>{this.state.objectiveText}</p> 
      </div>
    </div>

    )
  }


  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 6%" }}>
         
          <span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/')} on>Home</span> / Downloads
        </p>
      </div>
    );
  }
  

  renderVision(){

    return(
      <div className="row mission-row">
     
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
     <p className='about-us-description-font'>{this.state.visionText}</p> 
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">

<img src={this.state.visionImage}  className='image-about-us'/>

</div>
    </div>

    )
  }

  renderFaqs(){
    return this.state.faqs.map((faq,index) => {
    
      return ( <div key={index}>
        <p className="faq-main-question" >{faq.faq_question}</p>  
        <p className="faq-answer">{faq.faq_answer}</p>
        </div>
      )
     }
    )
 }


 getCart = () => {
  let details = {
    user_id: this.state.userdata.user_id
  };

  console.log(details, "fetching-details");
  let formBody = [];
  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  this.setState({
    response: fetch("https://www.vimasmiham.com/get_cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000"
      },
      body: formBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, "cart");

        this.setState({
          cart: responseJson
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });

        console.log(error);
      })
  });
};

renderDownloadItems() {
  return this.state.downloads.map(item => {
    return (
      <div className="my-3 pl-2 pl-md-0" key={item.download_id}>
        <a href={item.download_link} className="downloads-item-main">
          <img src={PDFIcon} alt={item.download_name} className="" />{" "}
          &nbsp; <span> {item.download_name}</span>
        </a>
      </div>
    );
  });
}


  render() {
    {console.log(this.state.faculties)}
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
        

        <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            active="My Dashboard"
          />
 
<div id="aboutUs">
          <div className="about-main ">
            <h1 className="about-us-heading">DOWNLOADS</h1>
          </div>

          <div>{this.renderAboutNavigation()}</div>
         

          

       
               
          <div className="downloads-container-main my-4">
              <div className="row">
                <div className="col-12 col-md-6 order-2 order-md-1">
                 
                  {this.renderDownloadItems()}
                 
                </div>
                <div className="col-12 col-md-6 order-1 order-md-2 d-flex align-items-center faq-im-con">
                  <img
                    className="downloads-image img-fluid"
                    src="https://www.vimasmiham.com/downloads.png"
                    alt="Downloads"
                  />
                </div>
              </div>
            </div>      
          
                   
         

         

            <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
        </div>
        </div>
      );
    }
  }
}

export default Downloads;
