import React, { Component, Fragment } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import "./test.css";

import Select from "react-select";
import swal from "sweetalert";
import { ie10 } from "react-reveal/globals";
import CKEditor from "ckeditor4-react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class AddTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      testTitle: "",
      isDemoTest: false,
      numberOfQuestions: null,
      compulsoryQuestions: [],
      selectedSubject: [],
      difficultyLevels: [
        { value: 1, label: "Easy" },
        { value: 2, label: "Moderate" },
        { value: 3, label: "Tough" },
      ],
      selectedDifficultyLevel: [],
      topics: null,
      questions: null,
      subjectList: [],
      testArray: [],
      testCost: null,
      total_questions: 0,
      testTime: null,
      selectedTestType: null,
      subjectiveRatio: "",
      objectiveRatio: "",
      totalError: false,
      description: "",
    };
  }

  componentWillMount() {
    this.getSubjects();
  }

  componentDidMount() {
    console.log("State", this.state);
  }

  getSubjects = async () => {
    this.setState({ loading: true });
    var subjectList = JSON.parse(window.localStorage.getItem("subject_list"));
    subjectList.forEach((element) => {
      element.value = element.subject_id;
      element.label = element.subject_name;
    });
    this.setState({ subjectList, loading: false });
  };

  //HANDLE FUNCTIONS

  getTopics = async (subject_id) => {
    let details = {
      subject_id: subject_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    console.log("fetching details");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subject_topics", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //   var response = JSON.parse(responseJson)
          console.log(responseJson[0]);
          let testArray = this.state.testArray;
          JSON.parse(responseJson[0].topics).forEach((element) => {
            var topic = new Object();
            topic.topic_id = element.topic_id;
            testArray.push(topic);
          });
          this.setState(
            {
              topics: JSON.parse(responseJson[0].topics),
              testArray,
              loading: false,
            },
            () => this.getNumberOfQuestions()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getNumberOfQuestions = () => {
    fetch("https://vimasmiham.com/get_number_of_questions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.topics.map((item) => item.id)),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let questions = responseJson.questions;
        let topics = [...this.state.topics];
        topics.forEach((item) => {
          item.easyCount = 0;
          item.moderateCount = 0;
          item.difficultCount = 0;
        });
        questions.forEach((element) => {
          let index = topics.findIndex(
            (item) => item.topic_id == element.topic
          );
          console.log(index);
          console.log(element.difficulty_level);
          if (
            this.state.selectedTestType == null ||
            this.state.selectedTestType.value == "mixed"
          ) {
            if (element.difficulty_level == 1) topics[index].easyCount++;
            if (element.difficulty_level == 2) topics[index].moderateCount++;
            if (element.difficulty_level == 3) topics[index].difficultCount++;
          } else if (this.state.selectedTestType.value == "objective") {
            if (element.difficulty_level == 1 && element.type == "objective")
              topics[index].easyCount++;
            if (element.difficulty_level == 2 && element.type == "objective")
              topics[index].moderateCount++;
            if (element.difficulty_level == 3 && element.type == "objective")
              topics[index].difficultCount++;
          } else {
            if (element.difficulty_level == 1 && element.type == "subjective")
              topics[index].easyCount++;
            if (element.difficulty_level == 2 && element.type == "subjective")
              topics[index].moderateCount++;
            if (element.difficulty_level == 3 && element.type == "subjective")
              topics[index].difficultCount++;
          }
        });
        console.log(topics);
        this.setState({ topics, questions });
      });
  };

  addTest() {
    if (this.state.selectedTestType.value == "mixed" && this.state.totalError)
      return;

    var finalTestArray = [];
    this.state.testArray.forEach((element) => {
      if (!(element.easy == 0 && element.moderate == 0 && element.tough == 0)) {
        finalTestArray.push(element);
      }
    });
    finalTestArray.push({
      subjective: this.state.subjectiveRatio,
      objective: this.state.objectiveRatio,
    });

    let details = this.state.isDemoTest
      ? {
          test_name: this.state.testTitle,
          status: 1,
          test_config: JSON.stringify(finalTestArray),
          subject_id: this.state.selectedSubject.subject_id,
          type: this.state.selectedTestType.value,
          testDescription: this.state.description,
        }
      : {
          test_name: this.state.testTitle,
          status: 1,
          test_config: JSON.stringify(finalTestArray),
          cost: this.state.testCost,
          duration: this.state.testTime,
          subject_id: this.state.selectedSubject.subject_id,
          type: this.state.selectedTestType.value,
          testDescription: this.state.description,
        };

    console.log(details, "add-without photo");
    let formBody = [];

    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // console.log("body", formBody);
    if (this.state.isDemoTest) {
      this.setState({
        response: fetch("https://www.vimasmiham.com/add_demo_test", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            swal("Success!", "Test added", "success");
          })
          .then((value) => this.props.history.push("/admin/test"))
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    } else {
      this.setState({
        response: fetch("https://www.vimasmiham.com/add_test", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            swal("Success!", "Test added", "success");
          })
          .then((value) => this.props.history.push("/admin/test"))
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  handleInputChange(data, item, difficulty) {
    if (isNaN(data) || data.length === 0) data = 0;
    let testArray = this.state.testArray;
    console.log(testArray);
    let testObject = testArray.find(
      (topic) => topic.topic_id === item.topic_id
    );
    if (difficulty === 0) {
      testObject.easy = data;
      if (!testObject.moderate) testObject.moderate = 0;
      if (!testObject.tough) testObject.tough = 0;
    }
    if (difficulty === 1) {
      testObject.moderate = data;
      if (!testObject.easy) testObject.easy = 0;
      if (!testObject.tough) testObject.tough = 0;
    }
    if (difficulty == 2) {
      testObject.tough = data;
      if (!testObject.easy) testObject.easy = 0;
      if (!testObject.moderate) testObject.moderate = 0;
    }

    this.setState({ testArray }, () => this.getTotalQuestions(testArray));
  }

  getTotalQuestions(testArray) {
    var total_easy = 0,
      total_moderate = 0,
      total_tough = 0;
    for (var v = 0; v < testArray.length; v++) {
      if (testArray[v].easy) {
        total_easy = total_easy + testArray[v].easy;
      }
      if (testArray[v].moderate) {
        total_moderate = total_moderate + testArray[v].moderate;
      }
      if (testArray[v].tough) {
        total_tough = total_tough + testArray[v].tough;
      }
    }
    this.setState({
      total_questions: total_easy + total_moderate + total_tough,
    });
  }

  recalculateNumberOfQuestions = () => {
    let questions = [...this.state.questions];
    let topics = [...this.state.topics];

    topics.forEach((item) => {
      item.easyCount = 0;
      item.moderateCount = 0;
      item.difficultCount = 0;
    });

    questions.forEach((element) => {
      let index = topics.findIndex((item) => item.topic_id == element.topic);
      console.log(index);
      console.log(element.difficulty_level);

      if (
        this.state.selectedTestType == null ||
        this.state.selectedTestType.value == "mixed"
      ) {
        console.log("looping mixed");
        if (element.difficulty_level == 1) {
          console.log("easy");
          topics[index].easyCount++;
        }
        if (element.difficulty_level == 2) {
          console.log("moderate");
          topics[index].moderateCount++;
        }
        if (element.difficulty_level == 3) {
          console.log("difficults");
          topics[index].difficultCount++;
        }
      } else if (this.state.selectedTestType.value == "objective") {
        console.log("is objective");
        if (element.difficulty_level == 1 && element.type == "objective") {
          console.log("easy");
          topics[index].easyCount++;
        }
        if (element.difficulty_level == 2 && element.type == "objective") {
          console.log("moderate");
          topics[index].moderateCount++;
        }
        if (element.difficulty_level == 3 && element.type == "objective") {
          console.log("difficults");
          topics[index].difficultCount++;
        }
      } else {
        console.log("looping question");
        if (element.difficulty_level == 1 && element.type == "subjective")
          topics[index].easyCount++;
        if (element.difficulty_level == 2 && element.type == "subjective")
          topics[index].moderateCount++;
        if (element.difficulty_level == 3 && element.type == "subjective")
          topics[index].difficultCount++;
      }
    });
    console.log(topics);
    this.setState({ topics, questions });
  };

  recalculate = () => {
    this.state.questions !== null &&
      this.state.topics !== null &&
      this.recalculateNumberOfQuestions();
  };

  handleTestTypeChange = (e) => {
    this.setState({ selectedTestType: e, errorType: false }, () => {
      if (this.state.selectedTestType.value == "objective") {
        this.setState({ objective: 1 }, () => this.recalculate());
      } else this.setState({ objective: 0 }, () => this.recalculate());
    });
  };
  renderTestType() {
    return (
      <div className="col-4 diffiulty-level-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="difficulty-label subject-labels question-label "
        >
          Test Type:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.handleTestTypeChange(e)}
          value={this.state.selectedTestType}
          className={`select-difficulty select ${
            this.state.errorType ? "errorClass" : ""
          }`}
          options={[
            { value: "objective", label: "Objective" },
            { value: "subjective", label: "Subjective" },
            { value: "mixed", label: "Mixed" },
          ]}
        />
      </div>
    );
  }

  renderTopicsSection() {
    if (!this.state.topics) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <Fragment>
          <div className="topicsSection col-10">
            <table style={{ width: "100%" }} className="test-topics-table">
              <tr>
                <th>Topics</th>
                <th>Easy</th>
                <th>Moderate</th>
                <th>Difficult</th>
              </tr>

              <tbody style={{ marginTop: "20px" }}>
                {this.state.topics.map((item, index) => {
                  return (
                    <tr key={item.topic_id}>
                      <td>
                        {item.name} - <strong>{item.id}</strong>
                      </td>
                      <td>
                        <input
                          type="number"
                          onChange={(e) =>
                            this.handleInputChange(
                              parseInt(e.target.value),
                              item,
                              0
                            )
                          }
                          className="number-of-questions-input"
                        />
                        &nbsp;/&nbsp;{item.easyCount}
                      </td>
                      <td>
                        <input
                          type="number"
                          onChange={(e) =>
                            this.handleInputChange(
                              parseInt(e.target.value),
                              item,
                              1
                            )
                          }
                          className="number-of-questions-input"
                        />
                        &nbsp;/&nbsp;{item.moderateCount}
                      </td>
                      <td>
                        <input
                          type="number"
                          onChange={(e) =>
                            this.handleInputChange(
                              parseInt(e.target.value),
                              item,
                              2
                            )
                          }
                          className="number-of-questions-input"
                        />
                        &nbsp;/&nbsp;{item.difficultCount}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {this.renderTotalQuestion()}
          </div>

          <hr />
        </Fragment>
      );
    }
  }

  handleSubjectSelect(e) {
    this.setState({ selectedSubject: e, loading: true });
    this.getTopics(e.value);
    console.log(e.value);
  }

  renderTestSubjectSection() {
    return (
      <div className="col-6  test-subject-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Subject:
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e) => this.handleSubjectSelect(e)}
          value={this.state.selectedSubject}
          className="select-question-topics select"
          options={this.state.subjectList}
        />
        {console.log(this.state.selectedAuthors, "Selected Authors:")}
      </div>
    );
  }

  renderTestTitle() {
    return (
      <div className="col-4 test-title-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Test name:
        </label>
        <input
          value={this.state.testTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control  custom-form"
          onChange={(text) => this.setState({ testTitle: text.target.value })}
        />
      </div>
    );
  }

  renderIsTestDemoCheckbox() {
    return (
      <div className="col-12 d-flex ">
        <label className="w-auto" for="flexCheckDefault">
          Demo Test
        </label>

        <input
          style={{ width: "60px" }}
          className="form-control  custom-form"
          type="checkbox"
          checked={this.state.isDemoTest}
          onChange={() => this.setState({ isDemoTest: !this.state.isDemoTest })}
          value=""
          id="flexCheckDefault"
        />
      </div>
    );
  }

  onEditorChange(e) {
    this.setState({ description: e.editor.getData() });
  }

  renderDescriptionEditor() {
    return (
      <div className="col-6 test-title-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label"
        >
          Description :
        </label>
        <CKEditor
          data={this.state.description}
          onChange={(e) => this.onEditorChange(e)}
          className="w-75"
        />
      </div>
    );
  }

  renderTestCost() {
    return (
      <div className="col-4 test-cost-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Cost:
        </label>
        <input
          value={this.state.testCost}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control  custom-form"
          onChange={(text) => this.setState({ testCost: text.target.value })}
        />
      </div>
    );
  }

  renderTotalQuestion() {
    if (this.state.total_questions != 0) {
      return (
        <>
          <hr />

          <div
            className="rowDefault total-cost-section total-question-section"
            style={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <h5 style={{ marginBottom: 0 }}>Total Questions</h5>
            <h4 className="total_question_value">
              {this.state.total_questions}
            </h4>
          </div>
        </>
      );
    }
  }

  renderTestTime() {
    return (
      <div className="col-4 test-cost-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Time(in Min):
        </label>
        <input
          value={this.state.testTime}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control  custom-form"
          onChange={(text) => this.setState({ testTime: text.target.value })}
        />
      </div>
    );
  }

  onRatioBlur = () => {
    console.log(
      this.state.subjectiveRatio,
      this.state.objectiveRatio,
      this.state.subjectiveRatio + this.state.objectiveRatio
    );
    if (
      Number(this.state.subjectiveRatio) + Number(this.state.objectiveRatio) !==
      100
    ) {
      this.setState({ totalError: true });
    } else {
      this.setState({ totalError: false });
    }
  };

  renderQuestionTypeRatio() {
    return (
      <div className="col-12">
        <h5>
          <strong>Questions Ratio</strong>
        </h5>
        <div className="form-group">
          <label htmlFor="subjective" style={{ width: "8%" }}>
            Subjective:
          </label>
          <input
            type="number"
            onBlur={this.onRatioBlur}
            value={this.state.subjectiveRatio}
            style={{ width: "6%", textAlign: "right" }}
            id="subjective"
            onChange={(e) => this.setState({ subjectiveRatio: e.target.value })}
          />
          %
        </div>

        <div className="form-group">
          <label htmlFor="objective" style={{ width: "8%" }}>
            Objective:
          </label>
          <input
            type="number"
            value={this.state.objectiveRatio}
            onBlur={this.onRatioBlur}
            style={{ width: "6%", textAlign: "right" }}
            id="objective"
            onChange={(e) => this.setState({ objectiveRatio: e.target.value })}
          />
          %
        </div>
        {this.state.totalError && (
          <p className="text-danger">Must total to a 100%</p>
        )}
      </div>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page" className="add-test-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={9} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12">
                      <button
                        className="admin-button-style"
                        onClick={() => this.props.history.push("/admin/test")}
                      >
                        All Tests
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Add Test</h3>
                      <button
                        className="admin-button-style"
                        onClick={() => this.addTest()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                  </div>

                  <div className="row px-2 my-2 mx-0">
                    {this.renderTestType()}
                    {this.renderTestTitle()}
                    {this.renderIsTestDemoCheckbox()}
                    {this.renderDescriptionEditor()}
                    {this.state.isDemoTest === false && (
                      <>
                        <hr />
                        {this.renderTestCost()}
                      </>
                    )}
                    <hr />
                    {this.state.isDemoTest === false && (
                      <>{this.renderTestTime()}</>
                    )}
                    {this.renderTestSubjectSection()}
                    <hr />
                    {console.log(this.state.topics, "topics")}
                    {this.renderTopicsSection()}
                    <div></div>
                    {this.state.selectedTestType &&
                      this.state.selectedTestType.value == "mixed" &&
                      this.renderQuestionTypeRatio()}
                    <button
                      className="add-subject"
                      onClick={() => this.addTest()}
                    >
                      Save &amp; Publish
                    </button>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
