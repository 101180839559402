import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import MediaComponent from "../MediaComponent/chooseMediaFile";

import {

  MDBIcon,
} from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from 'ckeditor4-react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class Edit_Appraisal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loadng:true,
      status:0,
      title: "",
      content: "<p></p>",
      description: "",
      appraisal: []
    };
    this.onEditorChange = this.onEditorChange.bind( this );

  }

  componentWillMount() {}

  componentDidMount() {
    this.getappraisal();
  }

  //get Inlighter Vein

  getappraisal = async () => {
    this.setState({
      loading: false
    });

    let formData = new FormData();

    formData.append("homepage_id", 1);

    for (let key of formData.entries()) {
      console.log(key[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_appraisal", {
        method: "GET",
      })
        .then(response => response.json())
        .then(responseJson => {
console.log(responseJson)
          this.setState(
            {
              title: responseJson[0].heading,
              status: responseJson[0].status,
              content: (responseJson[0].content).toString(),
              
            },
            () => console.log(this.state.content)
          );

          // this.props.history.push("/admin/InlighterVein");
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  //ADD Inlighter Vein

  addInlighterVein() {
    if (this.state.title.length === 0)
      return swal("Check title!", "Please enter title", "error");
    else {
   
      this.setState({
        loading:true
  
        })

      let details = {
        // product_id:this.state.selected_products.product_id,
       heading:this.state.title,
       content:this.state.content,
       status:this.state.status
      
        
      
      
      };
      console.log(details,'add-without photo')
      let formBody = [];
      for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      
      this.setState({
      response: fetch('https://www.vimasmiham.com/edit_appraisal', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'max-age=31536000'
      },
      body:formBody
      
      
      }).then((response) => response.json())
      .then((responseJson)=>{

        console.log(responseJson,'123')
        swal("", "Page Updated", "success").then(()=>window.location.reload());
                
                
                
                
                }
      
      )
      .catch((error) => {
      this.setState({
       loading:false
      })
      swal("Warning!", "Check your network!", "warning");
      console.log(error)
       })
     })
    }
  }

  renderImage() {
    if (this.state.inputLink.length == 0) {
      return "";
    } else {
      return (
        <img
          src={this.state.inputLink}
          style={{ width: "150px", marginTop: "10px" }}
        ></img>
      );
    }
  }

  handlechangeurl = text => this.setState({ inputLink: text.target.value });

  //RENDER FUNCTIONS

  renderUrlSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ name: text.target.value })}
        />
      </div>
    );
  }

  renderTitleSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Heading:
        </label>
        <input
          value={this.state.title}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ title: text.target.value })}
        />
      </div>
    );
  }

  renderStatusSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Status
        </label>
        <select defaultValue={this.state.status} 
        class="form-control subject-subtitle-form custom-form ml-3" 
        id="exampleFormControlSelect1" 
        onChange={(e)=>this.setState({status:e.target.value})}>
      <option value={1}>Active</option>
      <option value={0}>Inactive</option>
    </select>
        
       {/* {this.state.status==0?"Inactive":"Active"} */}
      </div>
    );
  }


  renderDescriptionSection() {
    console.log(this.state.content,'ccccccccc')
    return (
      <div className="col-12 subject-description-container  d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels h-100"
        >
          Description :
        </label>
        <CKEditor
            data={this.state.content}
             style={{width:'72%',margin:'5px auto'}}
                onChange={this.onEditorChange} 
                className="textFontSize"/> 
      </div>
    );
  }

  renderChooseFile = () => {
    return (
      <div className="col-12  d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="course-duration-label subject-labels"
          style={{ borderRight: "none" }}
        >
          Enter file link:
        </label>

        <div
          className="flex-column "
          style={{
            width: "74.5%",
            borderLeft: "1px solid #bfbfbf",
            height: "100%"
          }}
        >
          <input
            style={{ marginTop: "10px", width: "82%" }}
            type="text"
            value={this.state.inputLink}
            onChange={text => this.handlechangeurl(text)}
            id="download_link"
            className="form-control duration-form custom-form "
          />
          {/* <div className='d-flex justify-content-center m-2'>
      
                   {this.renderImage()}
      
                   </div> */}
        </div>
      </div>
    );
  };
  onEditorChange( e ) {
    console.log(e)
    this.setState( {
      content: e.editor.getData()
    } );
  }
  render() {
    {console.log(this.state)}
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={0} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
              
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    {/* <button className="admin-button-style ">
                                      Home page
                                    </button> */}

                    <MediaComponent
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    />
                    <button
                      className="admin-button-style"
                      onClick={() => this.addInlighterVein()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3>Appraisal of Insurance</h3>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4">
                    <div className="row px-0 mx-0">
                      {this.renderTitleSection()}
                      {this.renderDescriptionSection()}
                      {this.renderStatusSection()}
                    </div>
                  </div>
                </div>

                {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addExam()}>Save &amp; Publish</button> */}
              </React.Fragment>
            )}
          </div>

        </div>
      </div>
    );
  }
}

export default Edit_Appraisal;
