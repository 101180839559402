import React from "react";
import { Link } from "react-router-dom";

export default class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: window.localStorage.getItem("aboutUs")
        ? JSON.parse(window.localStorage.getItem("aboutUs"))[0]
        : null,
    };
  }

  setAboutUs = (aboutUs) => {
    this.setState({ aboutUs });
  };

  componentDidMount() {
    let formData = new FormData();
    formData.append("about_us_id", 1);
    fetch("https://www.vimasmiham.com/get_aboutus", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        window.localStorage.removeItem("aboutUs");
        window.localStorage.setItem("aboutUs", JSON.stringify(responseJson));
        this.setAboutUs(responseJson[0]);
      });
  }

  render() {
    let { aboutUs } = this.state;

    if (aboutUs != null) {
      return (
        <div className="row about-us-div align-items-center">
          <div className="col-12 col-md-6 ">
            <img
              className="img-fluid about-us-image"
              src={aboutUs.main_image}
              alt="Vima About US"
            />
          </div>
          <div className="col-12 col-md-6 about-home-mobile">
            {/* <h1 className="about-us-title">{aboutUs.main_title}</h1> */}
            <div className="about-us-para">
              <div className="about-us-content">
                <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                  A Comprehensive Way of Insurance Exam Preparation
                </h2>
                <p>
                  Vima Smiham is the one stop destination for Insurance Exam
                  Preparation. We provide comprehensive study material and mock
                  test series to mark your success in the world of insurance.
                  Find all relevant Acts, Regulations, Circulars plus treatise
                  and FAQs on difficult to understand topics as well as
                  comparison between new and old laws where applicable for
                  clarity.
                </p>
                <p>
                  In addition to quality updated content prepared by best in the
                  field and verified by peers, we provide
                  <strong> Online Mock Tests </strong>
                  with hundreds of new questions freshly prepared by our
                  faculty, all doubly checked for accuracy to help you with your
                  Various Insurance Exams.
                </p>

                <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                  What’s unique about Insurance Exam Preparation with us?
                </h2>
                <p>
                  We provide study material for Licentiate, Associateship and
                  Fellowship exam as well as PSGICs Promotional Exams in simple,
                  easy to understand manner with graphs and illustrations, to
                  guarantee your success in the Exam.
                </p>

                <p>
                  To help our students, our Test Series Section has been
                  specially designed to mimic real time insurance exams. It uses
                  a fuzzy logic method to change the order of the answer. Each
                  test series has been carefully designed to include questions
                  from all chapters and questions with varying difficulty level
                  in each set.
                </p>
              </div>
            </div>
            <Link to="/about-us" style={{ textAlign: "center" }}>
              <button className="btn about-us-read-more">Read More</button>
            </Link>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
