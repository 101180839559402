import React, { Component } from "react";
import swal from "sweetalert";
import "./topicQuiz.css";
import Fade from "react-reveal";



export default class TopicQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      showStartQuiz: true,
      showQuestions: false,
      showEndquiz:false,
      checkMode: false,
      correctAnswers: 0,
      quiz_item:null,
      wrongAnswers: 0,
      skippedAnswers: 0,
      selected_answer: null,
      count: "0",
      shouldBlockNavigation: true,
      quiz_name:'',
      abc: false
    };
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array
}

  componentWillMount() {
    var quiz_details = JSON.parse(window.localStorage.getItem("quiz_details"));
    console.log(quiz_details)
    if (quiz_details) {
      let questions = quiz_details.questions;
    
      questions.forEach(question => {
        let answers = [];
        answers[0] = new Object();
        answers[0].id = 0;
        answers[0].answer = question.correct_answer;
        answers[0].value = 1;
        answers[0].link = "";
        //other answers
        answers[1] = new Object();
        answers[1].id = 1;
        answers[1].answer = question.other_option_1;
        answers[1].value = 0;
        answers[1].link = "";

        answers[2] = new Object();
        answers[2].id = 2;
        answers[2].answer = question.other_option_2;
        answers[2].value = 0;
        answers[2].link = "";

        answers[3] = new Object();
        answers[3].id = 3;
        answers[3].answer = question.other_option_3;
        answers[3].value = 0;
        answers[3].link = "";
        question.answers = JSON.stringify(this.shuffleArray(answers));
  
      });
      this.setState({
        quiz_details,
        questions: quiz_details.questions,
        quiz_subject: quiz_details.subject,
        quiz_name:quiz_details.quiz_name,
        quiz_topic: quiz_details.topic,
        quiz_item:quiz_details,
        currentIndex: 0
      });
      console.log(quiz_details.subject,quiz_details.topic)
    } else {
      this.setState({loading:true});
    }
  }

  componentDidMount() {

  }

  componentWillUnmount(){
    window.localStorage.removeItem("quiz_details")
  }
  

  // renderQuestion() {
  //   //  console.log(this.props.history.location.state);
  //   if (this.state.questions != undefined) {
  //     let question = this.state.questions[this.state.currentIndex];
  //     question.visited = true;
  //     return (
  //       <div className="question-container">
  //         <p className="question-container-question">
  //           Q{this.state.currentIndex + 1}. {question.question_title}
  //         </p>
  //         {this.renderAnswers(JSON.parse(question.answers))}
  //         <button onClick={() => this.checkAnswer()}>Next</button>
  //       </div>
  //     );
  //   }
  // }

  //HANDLE FUNTION
  updatedQuiz=()=>{
    let userid=JSON.parse(localStorage.getItem('userdata')).user_id
    
    
        let formData = new FormData();
   
        formData.append("user_id",userid);
        formData.append("quiz_id",this.state.quiz_item.quiz_id);
        formData.append("subject_id",this.state.quiz_item.subject_id);
        formData.append("topic_id",this.state.quiz_item.topic_id);
        formData.append("score",`${this.state.correctAnswers} / ${this.state.questions.length}`);
    
        this.setState({
          response: fetch("https://www.vimasmiham.com/update_progress_quiz", {
            method: "POST",
            body: formData
          })
            .then(response => response.json())
            .then(responseJson => {
              this.props.onSelectClose();      
            })
            .catch(error => {
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            })
        });
    }
    
  checkAnswer() {
    let questions = this.state.questions;
    let question = questions[this.state.currentIndex];
    let correct = this.state.correctAnswers;
    let wrong = this.state.wrongAnswers;

    if (this.state.selected_answer == null) {
      swal({
        title: "PLease select an option",
        text: "",
        icon: "warning",
        dangerMode: true
      }).then(() => {
        return;
      });
    } else {
      question.selected_answer = this.state.selected_answer;

      //calculate score
   
          if (question.selected_answer.value == 0) {
            wrong++;
          }
          if (question.selected_answer.value == 1) {
            correct++;
          }
        
    

      this.setState({
        correctAnswers: correct,
        wrongAnswers: wrong,
        questions: questions,
        checkMode: true
      });
    }
  }

  componentDidUpdate = () => {
    // let { shouldBlockNavigation } = this.state;
    // if (shouldBlockNavigation) {
    //   window.onbeforeunload = () => true;
    // } else {
    //   window.onbeforeunload = undefined;
    // }
  };

  checkSubmit() {
    if (this.state.questions != undefined)
      if (this.state.questions.length - 1 == this.state.currentIndex) {
        return false;
      }

    return true;
  }

  startQuiz() {
    this.setState({ showStartQuiz: false, showQuestions: true });
  }

  renderStartQuiz() {
    console.log(this.state.quiz_details)
    return this.state.showStartQuiz === true ? (
      <div className="row w-50">
        <div className="col-12">
          <p className="my-2">
            <span className="quiz-subject d-none">{this.state.quiz_subject}</span> {" "}
            <span className="quiz-topic">{this.state.quiz_name}</span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-0">Ready to take the quiz?</p>
          <p className="text-muted"> {this.state.questions.length} questions</p>
        </div>
        <div className="col-12">
          <button
            className="start-quiz-button"
            onClick={() => this.startQuiz()}
          >
            Start Quiz
          </button>
        </div>
      </div>
    ) : (
      ""
    );
  }

  renderAnswers(question) {
    let answers = JSON.parse(question.answers);
    
    if (this.state.checkMode) {
      let selected_answer = question.selected_answer;
      console.log(selected_answer);

      if (selected_answer == null) {
        console.log("if slectted answer == null")
        return answers.map(item => {
          return (
            <li
              className={
                item.value == 1
                  ? "p-1 mt-2 bg-green"
                  : "p-1 mt-2"
              }
              key={item.id}
            >
              {item.answer}
            </li>
          );
        });
      } else {
        return answers.map(item => {
          if (selected_answer.value == 0 && selected_answer.id == item.id) {
            console.log("if selected answer wrong")
            return (
              <li className="p-1 mt-2 bg-red" key={item.id}>
                {item.answer}
              </li>
            );
          } else if (
            selected_answer.value == 1 &&
            selected_answer.id == item.id
          ) {
            console.log("if slectted answer right")
            return (
              <li className="p-1 mt-2 bg-green" key={item.id}>
                {item.answer}
              </li>
            );
          } else {
            console.log("if slectted answer unattempted")
            return (
              <li
                className={
                  item.value == 1
                    ? "p-1 mt-2 bg-green "
                    : "p-1 mt-2"
                }
                key={item.id}
              >
                {item.answer}
              </li>
            );
          }
        });
      }
    } else {
      return answers.map(item => {
        console.log("non check mode")
        return (
          <div
            key={item.id}
            className="d-flex"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "10px 15px"
            }}
          >
            <div>
              <input
                type="radio"
                checked={
                  this.state.selected_answer
                    ? item.id === this.state.selected_answer.id
                      ? true
                      : false
                    : false
                }
                onChange={() => this.setState({ selected_answer: item })}
              />
            </div>

            <div>
              <span style={{ marginLeft: "5px" }}>{item.answer}</span>
            </div>
          </div>
        );
      });
    }
  }


  handleNext(){
    if(this.state.currentIndex < this.state.questions.length - 1){
    this.setState({
      currentIndex:this.state.currentIndex+1,
      checkMode:false,
      selected_answer:null,

    })
  }
  else{
    this.setState({showQuestions:false,
                    showEndquiz:true})
  }
}
componentWillReceiveProps(props){
  this.setState({
    props:props
  })
}
  endQuiz(){
    this.updatedQuiz()
         
   
  }
  renderEndQuiz(){
    return this.state.showEndquiz === true ? (
      <div className="row w-50">
        <div className="col-12">
          <p className="my-2">
            <span className="quiz-subject">{this.state.quiz_subject}</span> -{" "}
            <span className="quiz-topic">{this.state.quiz_topic}</span>
          </p>
        </div>
        <div className="col-12">
          <p className="mb-0">Thank you for taking the quiz</p>
          <p className="text-muted"> Score: {this.state.correctAnswers} / {this.state.questions.length}</p>
        </div>
        <div className="col-12">
          <button
            className="start-quiz-button"
            onClick={() => this.endQuiz()}
          >
            End Quiz
          </button>
        </div>
      </div>
    ) : (
      ""
    );
  }
  renderQuizQuestion() {
    //  console.log(this.props.history.location.state)
    if (this.state.showQuestions) {
      if (this.state.questions != undefined) {
        let question = this.state.questions[this.state.currentIndex];
        return (
          <div className="question-container w-75">
            <p className="question-container-question"  dangerouslySetInnerHTML={{__html:`Q${this.state.currentIndex + 1}.${question.question_title}`}}></p>
            <div className="quiz-answers-container px-3">
            {this.renderAnswers(this.state.questions[this.state.currentIndex])}
            </div>

            <div className="w-100 text-right">
          
              {this.state.checkMode?
      <button className="btn btn-sm bg-white mr-2 my-3" onClick={() => this.handleNext()} >{this.state.currentIndex === this.state.questions.length - 1? 'Next' : 'Next'}</button>
              :
              <button className="btn btn-sm bg-white mr-2 my-3" onClick={() => this.checkAnswer()} >Check</button>}
            </div>
          </div>
        );
      }
    }
  }

  render() {
    console.log(this.state, "state");
    if(this.state.loading){

    }
    else{
      return (
        <Fade>
        <div className="start-quiz-page py-5 px-5">
          {this.renderStartQuiz()}
          {this.renderQuizQuestion()}
          {this.renderEndQuiz()}
        </div>
        </Fade>
      );
    }
    }
    
}
