import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import "./editExam.css";
import Select from "react-select";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import CKEditor from "ckeditor4-react";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class editExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      exam_id: "",
      Exam_name: "",
      seoTitle: "",
      seoDescription: "",
      url: "",
      content: "",
      description: "",

      edit_data: false,

      verticals: [],
      selectedVerticals: [],
    };
  }

  componentWillMount() {
    this.setState({ loading: true }, () => this.getExamDetails());
  }

  getExamDetails = async () => {
    var exam_item = JSON.parse(window.localStorage.getItem("exam_item"));
    console.log(exam_item, "exam123");
    // let selectedVerticals = [];
    // let verticals = JSON.parse(exam_item.verticals);
    // for(var i = 0; i<verticals.length;i++){
    //     var object = new Object();
    //     object.label = verticals[i].vertical_name;
    //     object.value = verticals[i].vertical_id;

    //     selectedVerticals.push(object);

    // }
    this.setState(
      {
        exam_id: exam_item.exam_id,
        Exam_name: exam_item.exam_name,
        seoTitle: exam_item.seo_title,
        seoDescription: exam_item.seo_description,
        content: exam_item.content,
        url: exam_item.url,
        description: exam_item.description,
        selectedVerticals: JSON.parse(exam_item.verticals),

        loading: false,
      },
      () => this.getVerticals()
    );
  };

  componentDidMount() {
    console.log(
      JSON.parse(window.localStorage.getItem("exam_item")),
      "exam_item"
    );
  }

  //Edit FORM

  editExam() {
    if (this.state.Exam_name.length == 0) {
      swal("Check exam name!", "Please enter valid name", "error");
    } else if (this.state.description == null || this.state.description == "") {
      swal(
        "Check description field!",
        "Enter valid seo description value",
        "error"
      );
      console.log(this.state.taxValue);
    } else if (this.state.seoTitle == "" || this.state.seoTitle == null) {
      return swal("Check Seo title field!", "Enter valid seo title ", "error");
    } else if (
      this.state.seoDescription == "" ||
      this.state.seoDescription == null
    ) {
      return swal(
        "Check Seo Description field!",
        "Enter valid seo description ",
        "error"
      );
    } else if (this.state.url == "" || this.state.url == null) {
      return swal("Check URL field!", "Enter valid url value", "error");
    } else if (this.state.content == "" || this.state.content == null) {
      return swal("Check Content field!", "Enter valid content value", "error");
    } else {
      this.setState({
        loading: true,
      });

      let details = {
        // product_id:this.state.selected_products.product_id,
        exam_id: this.state.exam_id,
        exam_name: this.state.Exam_name,
        description: this.state.description,
        seo_title: this.state.seoTitle,
        seo_description: this.state.seoDescription,
        url: this.state.url,
        content: this.state.content,
        verticals: JSON.stringify(this.state.selectedVerticals),
      };
      console.log(details, "add-without photo");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_exam", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "123");
            if (responseJson.status == 200) {
              this.setState({
                loading: false,
                // add_modal:false
              });
              this.props.history.push("/admin/all-exams");
            } else {
              this.setState({
                loading: false,
                // add_modal:false
              });

              swal("Warning!", responseJson.message, "warning");
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  renderSeoTitle() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          SEO Title:
        </label>
        <input
          value={this.state.seoTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.setState({ seoTitle: text.target.value })}
        />
      </div>
    );
  }
  renderSeoDescription() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          SEO Description:
        </label>
        <input
          value={this.state.seoDescription}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) =>
            this.setState({ seoDescription: text.target.value })
          }
        />
      </div>
    );
  }
  renderUrl() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Url:
        </label>
        <input
          value={this.state.url}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.setState({ url: text.target.value })}
        />
      </div>
    );
  }
  onEditorChange(e) {
    this.setState({ content: e.editor.getData() });
  }
  renderEditor() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="image-label subject-labels h-100"
        >
          Content:
        </label>
        <CKEditor
          data={this.state.content}
          onChange={(e) => this.onEditorChange(e)}
          className="w-75"
        />
      </div>
    );
  }
  getVerticals = async () => {
    console.log("fetching Verticals");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "XAxax");
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].label = responseJson[v].vertical_name;
            responseJson[v].value = responseJson[v].vertical_id;
          }
          this.setState({
            verticals: responseJson,
            loading: false,
          });

          console.log(this.state);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
          console.log(error);
        }),
    });
  };

  //HANDLE CHANGE FUNCTIONS

  //RENDER FUNCTIONS
  renderExamNameSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Name:
        </label>
        <input
          value={this.state.Exam_name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.setState({ Exam_name: text.target.value })}
        />
      </div>
    );
  }

  renderDescriptionSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Description:
        </label>
        <input
          value={this.state.description}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.setState({ description: text.target.value })}
        />
      </div>
    );
  }

  renderVerticalSection() {
    return (
      <div className="col-12 exam-section h-25 min-height-unset">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="exam-label subject-labels pl-0"
        >
          Verticals:
        </label>
        <Select
          closeMenuOnSelect={false}
          isMulti
          onChange={(e) => this.setState({ selectedVerticals: e })}
          value={this.state.selectedVerticals}
          className="select-exam select"
          options={this.state.verticals}
        />
      </div>
    );
  }

  render() {
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={7} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                      <button
                        className="admin-button-style"
                        onClick={() =>
                          this.props.history.push("/admin/all-exams")
                        }
                      >
                        All Exams
                      </button>
                      <button
                        className="admin-button-style"
                        onClick={() => this.editExam()}
                      >
                        {" "}
                        Save &amp; Publish
                      </button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                      <h3> Add Exam</h3>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="subject-content-container ml-4">
                      <div className="row px-0 mx-0">
                        {this.renderExamNameSection()}
                        {this.renderDescriptionSection()}
                        {this.renderSeoTitle()}
                        {this.renderSeoDescription()}
                        {this.renderUrl()}
                        {this.renderEditor()}
                      </div>
                    </div>
                    <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4">
                      {this.renderVerticalSection()}
                    </div>
                  </div>

                  {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addExam()}>Save &amp; Publish</button> */}
                </Fade>
              </React.Fragment>
            )}
          </div>

          {console.log(this.state)}
        </div>
      </div>
    );
  }
}

export default editExam;
