import React, { Fragment } from "react";
import swal from "sweetalert";
import { Document, Page, pdfjs } from "react-pdf";
import ReactPlayer from "react-player";
import "./lesson.css";
import ProductHeaderPage from "../../Reusable/NewHeader/LessonHeader/newLessonHeader";
import "../../../node_modules/video-react/dist/video-react.css";
import ProductMobMenuBar from "../../Reusable/NewHeader/LessonHeader/newLessonHeader";
import MainFooterPage from "../../Reusable/Footer/footer";
import FacultyImage from "../../Assets/images/avatar.jpg";
import ContentImg from "../../Assets/images/helloquence.png";
import ReactTooltip from "react-tooltip";
import Img from "react-image";
import {
  MDBCollapse,
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import TopicQuiz from "../Quiz/TopicQuiz";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const sources = {
  sintelTrailer: "http://media.w3.org/2010/05/sintel/trailer.mp4",
  bunnyTrailer: "http://media.w3.org/2010/05/bunny/trailer.mp4",
  bunnyMovie: "http://media.w3.org/2010/05/bunny/movie.mp4",
  test: "http://media.w3.org/2010/05/video/movie_300.webm",
};
var sampleMode;
class lessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      source: sources.bunnyMovie,
      subjectid: "",
      showCourseTopicDiv: true,
      selected_subject: null,
      selectedSubjectTopic: [],
      test_included: 0,
      quizzes: [],
      topics: [],

      //lesson STATE
      lessonArray: [
        {
          memberImage: `${FacultyImage}`,
        },
        {
          memberImage: `${ContentImg}`,
        },
      ],
      aboutCourseDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,

      instructorName: `Mala Prasad`,
      instructorSub: `Accounting And Finance Lecturer`,
      instructorDescription: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
      instructorImage: `${FacultyImage}`,

      //description

      descriptionCourse: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ",
      ],

      descriptionList: [
        "Lorem Ipsum is simply d",
        "Ipsum has been the industrys standard ",
        "Type specimen book. It has survived not only five centuries",
      ],

      topicInc: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "Ipsum has been the industrys standard .",
        "Type specimen book. It has survived not only five centuries.",
        "It was popularised in the 1960s .",
        "Publishing software like Aldus PageMaker.",
        "including versions of Lorem Ipsum.",
        "content of a page when looking at its layou.",
      ],
      whatYou: [
        "Pellentesque sed nisi quis enim convallis vulputate.",
        "Nulla iaculis sapien vitae nisl rutrum sodales.",
        "Nulla eleifend dolor ac condimentum dapibus.",
        "Nulla condimentum neque sit amet ante sollicitudin commodo.",
        "Sed sed ipsum sit amet lorem placerat imperdiet.",
      ],

      R_there_any_course: [
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      ],

      Who_course: [
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      ],

      lectures: [],
      selected_Lec: null,
      sampleMode: null,
      userid: "",
      subjectid: "",
      selected_lecture_index: 0,
      selected_topic_index: 0,
      progress: "",
      getcompleted_lectures: [],
      completed_quiz: [],
      completed: 0,
      lecture_quiz: false,
      quiz_modal: false,
      numPages: null,
      pageNumber: 1,
      showTutorSpeak:false
    };
  }
  toggle_quiz = () => {
    this.setState({
      quiz_modal: !this.state.quiz_modal,
    });
  };
  handleClose = () => {
    this.setState({ quiz_modal: false });
    let topic_ids = this.state.selectedSubjectTopic.map((item) => {
      return { topic_id: item.topic_id };
    });
    this.getcompleted_Quiz();
    this.getQuizzes(topic_ids);
  };
  renderModal() {
    if (this.state.quiz_modal) {
      return (
        <MDBModal
          isOpen={this.state.quiz_modal}
          toggle={this.toggle_quiz}
          fullHeight
          backdrop={false}
          position="right"
          className="promo-modal-container"
        >
          <MDBModalBody style={{ height: "90vh" }}>
            <TopicQuiz
              checkscore={0}
              closeModal={false}
              onSelectClose={this.handleClose}
            />
          </MDBModalBody>
          <MDBModalFooter>
            <button
              onClick={() => this.setState({ quiz_modal: false })}
              className="start-quiz-button"
              type="button"
            >
              Close
            </button>
          </MDBModalFooter>
        </MDBModal>
      );
    }
  }
  componentWillMount() {
    // this.setState({sample_mode:this.props.history.location.state.sampleMode})
    //console.logthis.props);
    var id = window.localStorage.getItem("selected_course_user");
    let userid = localStorage.getItem("userdata");
    let subjectid = localStorage.getItem("selected_subject");
    sampleMode = JSON.parse(window.localStorage.getItem("sample_mode"));
    if (sampleMode === undefined || userid == undefined || !userid) {
      this.props.history.push("/");
    } else {
      //console.logthis.props);
      try {
        this.setState(
          {
            subjectid: JSON.parse(id).id,
            test_included: JSON.parse(id).test_included,
            sampleMode,
            userid,
          },
          () => this.getSubject()
        );
      } catch (err) {
        console.log(err);
      }
    }
  }
  setLecture = (item) => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    //console.loguserid);
    //console.logthis.state.subjectid);
    //console.logitem, "152");
    let formData = new FormData();

    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);
    formData.append("lecture_id", item.id);

    this.setState({
      response: fetch("https://www.vimasmiham.com/add_progress", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.getlecture();
          // this.getcompleted_lectures()

          this.setState({});
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };
  addQuiz = (item) => {
    console.log(item);
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    //console.loguserid);
    //console.logthis.state.subjectid);
    //console.logitem, "152");
    let formData = new FormData();

    formData.append("user_id", userid);
    formData.append("quiz_id", item.quiz_id);
    formData.append("subject_id", this.state.subjectid);
    formData.append(
      "topic_id",
      this.state.topics[this.state.selected_topic_index].topic_id
    );

    this.setState({
      response: fetch("https://www.vimasmiham.com/add_progress_quiz", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.getQuestionsByQuestionId(item);
          this.getcompleted_Quiz();

          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          this.getTopic(topic_ids, 0);
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };
  componentDidMount() {
    this.getcompleted_lectures();
    this.getcompleted_Quiz();

    //console.logthis.state);
    // const subjectid = this.props.history.location.state.id;
    // this.setState({ subjectid: subjectid }, () => this.getSubject());
  }

  getlecture = async () => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    let formData = new FormData();
    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);

    for (let key of formData.entries()) {
      //console.logkey[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_progress", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          var val1 = Math.round(
            (Number(responseJson.completed_lectures) /
              Number(this.state.total_lectures)) *
              100
          );

          this.setState({
            progress: String(val1),
            loading: false,
          });

          // this.props.history.push("/admin/InlighterVein");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };

  getcompleted_lectures = async () => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    let formData = new FormData();
    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);

    for (let key of formData.entries()) {
      //console.logkey[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_completed_lectures", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.logresponseJson, "sas1");

          this.setState({
            getcompleted_lectures: responseJson.results,
          });

          // this.props.history.push("/admin/InlighterVein");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };

  getcompleted_Quiz = async () => {
    let userdata = JSON.parse(this.state.userid);
    let userid = userdata.user_id;
    let formData = new FormData();
    formData.append("user_id", userid);
    formData.append("subject_id", this.state.subjectid);

    for (let key of formData.entries()) {
      //console.logkey[0] + ",with  " + key[1]);
    }

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_completed_quiz", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.logthis.state.completed_quiz);
          let { completed_quiz } = this.state;

          //console.logresponseJson, "sas2");

          this.setState({
            completed_quiz: responseJson.results,
          });

          // this.props.history.push("/admin/InlighterVein");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  getSubject = async () => {
    let formData = new FormData();
    formData.append("subject_id", this.state.subjectid);
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          let responseJsonTopics = JSON.parse(responseJson[0].topics);
          let subject_name = responseJson[0].subject_name;
          this.setState(
            {
              selectedSubjectTopic: responseJsonTopics,
              selected_subject: responseJson[0],
              subject_name,
            },
            () => console.log(this.state.selected_subject, "cool")
          );
        })
        .then(() => {
          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          //console.logtopic_ids);
          this.getQuizzes(topic_ids);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };

  getTopic = async (topicids, allow) => {
    //console.logtopicids); //aaray

    let formData = new FormData();
    for (let key of formData.entries()) {
      //console.logkey[0] + "" + key[1]);
    }
    formData.append("topic_ids", JSON.stringify(topicids));
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_multiple_topics", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //console.logresponseJson, "sas");
          var completed_lectures = this.state.getcompleted_lectures;
          var completed_quiz = this.state.completed_quiz;
          const topics = responseJson.filter((item) => item.sample !== 1);
          var total = 0;
          for (var v = 0; v < topics.length; v++) {
            var data = JSON.parse(topics[v].lectures);

            total = total + JSON.parse(topics[v].lectures).length;

            for (var v1 = 0; v1 < data.length; v1++) {
              data[v1].selected = 0;
              topics[v].lectures = data;

              //console.logdata[v1]);

              for (let j = 0; j < completed_lectures.length; j++) {
                if (data[v1].id === completed_lectures[j].lecture_id) {
                  data[v1].completed = 1;
                }

                topics[v].lectures = data;
              }
            }
          }

          for (var i = 0; i < topics.length; i++) {
            var datai = topics[i].lectures;
            for (var q = 0; q < this.state.quizzes.length; q++) {
              console.log(this.state.quizzes[q]);
              if (topics[i].topic_id == this.state.quizzes[q].topic_id) {
                if (this.state.quizzes[q].status == 1) {
                  if (this.state.quizzes[q].lecture_id != null) {
                    let lecture = topics[i].lectures.find(
                      (lecture) =>
                        lecture.id == this.state.quizzes[q].lecture_id
                    );
                    // console.log(this.state.quizzes[q])
                    // console.log(topics[i].lectures)
                    lecture.quiz_item = this.state.quizzes[q];
                    console.log(lecture);
                  } else {
                    datai.push(this.state.quizzes[q]);
                    // topics[i].quiz_item = this.state.quizzes[q]
                  }
                }
              }
            }
            for (var i1 = 0; i1 < datai.length; i1++) {
              datai[i1].selected = 0;
              topics[i].lectures = datai;
            }
          }
          //console.logdatai)

          if (allow == 1) {
            //console.log"allowed");
            this.setState({ topics: topics, total_lectures: total }, () =>
              this.selectLecture(topics[0].lectures[0], 0, 0)
            );
          } else {
            //console.log"not  allowed");
            //console.logtopics);
            this.setState({ topics: topics, total_lectures: total });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };
  getallLecture() {
    let { topics, lectures } = this.state;
  }
  selectLecture(item, index, index1) {
    console.log(item, "sssss");
    if (item.type != 10) {
      let { lectures, topics } = this.state;
      console.log(topics);
      let current_topic = topics[index1];
      console.log(current_topic);
      let current_lectures = topics[index1].lectures;
      console.log(current_lectures);

      for (var v = 0; v < current_lectures.length; v++) {
        current_lectures[v].selected = 0;
      }
      current_lectures[index].selected = 1;

      //topics[index1].lectures = current_lectures
      // selected_lecture.selected = 1;
      this.setState(
        {
          selected_Lec: item,
          selected_lecture_index: index,
          collapseID: this.state.topics[index1].topic_id,
          selected_topic_index: index1,
          pageNumber: 1,
        },
        () => {
          this.setLecture(item);
          this.getcompleted_lectures();
          let topic_ids = this.state.selectedSubjectTopic.map((item) => {
            return { topic_id: item.topic_id };
          });
          this.getTopic(topic_ids, 0);
        }
      );

      this.setState({ topics });
    } else {
      //  if(item.lecture_id == null){
      console.log(item);

      let { lectures, topics } = this.state;
      let current_topic = topics[index1];

      //topics[index1].lectures = current_lectures
      // selected_lecture.selected = 1;
      // if(item.lecture_id!=null){
      //   this.setState(
      //     {
      //       selected_Lec: null,
      //       selected_lecture_index: -1,
      //       collapseID: this.state.topics[index1].topic_id,
      //       selected_topic_index: index1,
      //       lecture_quiz:true
      //     },
      //     () => {
      //       this.addQuiz(item);
      //       this.setState({ quiz_modal: true });
      //       //this.selectQuiz(quiz_item)
      //       //window.open("http://localhost:3000/#/start-quiz", "_blank", "width=1280,height=720");
      //     }
      //   );
      // }
      // else{
      this.setState(
        {
          selected_Lec: null,
          selected_lecture_index: -1,
          collapseID: this.state.topics[index1].topic_id,
          selected_topic_index: index1,
          lecture_quiz: false,
        },
        () => {
          this.addQuiz(item);

          //this.selectQuiz(quiz_item)
          //window.open("http://localhost:3000/#/start-quiz", "_blank", "width=1280,height=720");
        }
      );
      // }

      this.setState({ topics });
      //    }

      // else{

      // }
    }
  }

  view() {}

  next() {
    let { lectures } = this.state;
    var selected_index = this.state.selected_Lec.id;
    let selected_lecture = lectures[selected_index];
    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }
    selected_lecture.selected = 1;

    this.setState({ lectures });
    this.setState({
      selected_Lec: lectures[selected_index],
    });
  }

  prev() {
    let { lectures } = this.state;
    var selected_index = this.state.selected_Lec.id;
    let selected_lecture = lectures[selected_index - 2];
    for (var v = 0; v < lectures.length; v++) {
      lectures[v].selected = 0;
    }
    selected_lecture.selected = 1;

    this.setState({ lectures });
    this.setState({
      selected_Lec: lectures[selected_index - 2],
    });
  }

  renderWho_course() {
    const { expanded } = this.state;

    return this.state.Who_course.map((modal) => {
      return (
        <>
          <li>{modal}</li>
        </>
      );
    });
  }

  renderR_there_any_coursec() {
    const { expanded } = this.state;

    return this.state.R_there_any_course.map((modal) => {
      return (
        <>
          <li>{modal}</li>
        </>
      );
    });
  }

  renderWhatYou() {
    const { expanded } = this.state;

    return this.state.whatYou.map((modal) => {
      return (
        <>
          <li>{modal}</li>
        </>
      );
    });
  }

  renderTopicInc() {
    const { expanded } = this.state;

    return this.state.topicInc.map((modal) => {
      return (
        <>
          <li className="DescriptionP">{modal}</li>
        </>
      );
    });
  }

  renderdescriptionList() {
    const { expanded } = this.state;

    return this.state.descriptionList.map((modal) => {
      return (
        <>
          <li>{modal}</li>
        </>
      );
    });
  }

  renderdescriptionCourse() {
    const { expanded } = this.state;
    console.log(this.state.selected_subject.instructor_say.length);

    return this.state.descriptionCourse.map((modal) => {
      return (
        <>
          <p>{modal}</p>
        </>
      );
    });
  }

  renderLessonItems() {
    return this.state.lessonArray.map((member) => {
      return (
        <div key={member.memberName}>
          <div>
            <div>
              <img src={member.memberImage} />
            </div>
            <div></div>
          </div>
        </div>
      );
    });
  }

  toggleCollapse(id) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
      });
    } else {
      this.setState({
        collapseID: id,
      });
    }
  }
  renderTxt(data) {
    //   var dbx = new Dropbox({ accessToken: 'nuv25l5gk8ktbug' });
    // dbx.filesListFolder({path: data.link})
    //     .then(function (response) {
    //         var blob = response.fileBlob;
    //         var reader = new FileReader();
    //         reader.addEventListener("loadend", function() {
    //           return( <p>{reader.result}</p>) // will print out file content
    //         });
    //         reader.readAsText(blob);
    //     })
    //     .catch(function (error) {
    //     })
  }
  readTextFile = (file) => {
    var rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);
    rawFile.onreadystatechange = () => {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status == 0) {
          var allText = rawFile.responseText;

          this.setState({
            fundData: allText,
          });
        }
      }
    };
    rawFile.send(null);
  };
  renderIcon(data) {
    let type = data.type.toLowerCase();
    // var str = data.type;
    // var res = str.split('.');
    // //console.logres)

    if (type == "text") {
      return (
        <img
          src={require("../../Assets/images/doc_icon.png")}
          style={{ cursor: "pointer" }}
          alt="vima-logo"
          className="lesson_type_icon"
          height="12px"
        />
      );
    } else if (type.toLowerCase() === "image") {
      return (
        <img
          src={require("../../Assets/images/image_icon.png")}
          style={{ cursor: "pointer" }}
          alt="vima-logo"
          className="lesson_type_icon"
        />
      );
    } else if (type.toLowerCase() == "pptx") {
      return (
        <img
          src={require("../../Assets/images/ppt_icon.png")}
          style={{ cursor: "pointer" }}
          alt="vima-logo"
          className="lesson_type_icon"
        />
      );
    } else if (type.toLowerCase() == "ppt") {
      return (
        <img
          src={require("../../Assets/images/ppt_icon.png")}
          style={{ cursor: "pointer" }}
          alt="vima-logo"
          className="lesson_type_icon"
        />
      );
    } else if (type.toLowerCase() == "video") {
      return (
        <img
          src={require("../../Assets/images/video_icon.png")}
          style={{ cursor: "pointer", color: "gray" }}
          alt="vima-logo"
          className="lesson_type_icon"
        />
      );
    } else if (type.toLowerCase() == "pdf") {
      return (
        <img
          src={require("../../Assets/images/pdf_icon.png")}
          style={{ cursor: "pointer", color: "gray" }}
          alt="vima-logo"
          className="lesson_type_icon"
        />
      );
    }
  }

  renderSampleCourseTopics() {
    var sampleTopics = this.state.topics.filter((topic) => topic.sample == 1);
    return sampleTopics.map((modal, index1) => {
      if (modal.sample === 0) {
        return (
          <>
            <del className="d-none">
              <MDBBtn
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "initial",
                  alignItems: "center",
                }}
                className="CollapseBtn CollapseBtnCourse"
                color="primary"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "initial",
                  }}
                >
                  <div className="CourseIntroduction d-flex justify-space-between align-items-center">
                    <span>{modal.topic_name}</span>{" "}
                    <MDBIcon
                      icon={
                        modal.topic_id == this.state.collapseID
                          ? "minus"
                          : "plus"
                      }
                      className="ml-auto mr-3"
                    />
                  </div>

                  <div className="CourseLectures">
                    <text>{modal.lectures.length} lecture(s) </text>
                  </div>
                </div>
              </MDBBtn>
            </del>
          </>
        );
      } else {
        return (
          <>
            <MDBBtn
              className="CollapseBtn CollapseBtnCourse"
              color="primary"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "initial",
                alignItems: "center",
              }}
              onClick={() => this.toggleCollapse(modal.topic_id)}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "initial",
                }}
              >
                <div className="CourseIntroduction d-flex justify-space-between align-items-center">
                  <span>{modal.topic_name}</span>{" "}
                  <MDBIcon
                    icon={
                      modal.topic_id == this.state.collapseID ? "minus" : "plus"
                    }
                    className="ml-auto mr-3"
                  />{" "}
                </div>

                <div className="CourseLectures">
                  <text>{modal.lectures.length} lecture(s) </text>{" "}
                </div>
              </div>
            </MDBBtn>

            {modal.lectures.map((item, index) => {
              return (
                <MDBCollapse
                  className="CollapseInside_lesson"
                  id={modal.topic_id}
                  isOpen={this.state.collapseID}
                  style={{
                    backgroundColor: item.selected == 1 ? "#e6f2f5" : "#fff",
                  }}
                >
                  <div
                    onClick={() => this.selectLecture(item, index, index1)}
                    className="d-flex justify-content-between align-items-center inside_collapse"
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        color: "#4A4A4A",
                        width: "110%",
                        fontSize: "15px",

                        marginBottom: ".5%",
                      }}
                    >
                      {index + 1}. {item.lecture_name}
                    </p>
                    <p className="duration_text" style={{ marginBottom: 0 }}>
                      {this.renderIcon(item)} {item.duration}min
                    </p>
                  </div>
                </MDBCollapse>
              );
            })}

            {/* {JSON.parse(modal.lectures).forEach(item=>item.lecture_name)} */}
          </>
        );
      }
    });
  }

  getQuestionsByQuestionId(quiz) {
    console.log(quiz);
    let formData = new FormData();
    formData.append("question_id", quiz.questions);
    // let result = null
    fetch("https://www.vimasmiham.com/get_multiple_questions", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responsejson) => {
        //console.logresponsejson);
        //    window.localStorage.removeItem("quiz_details")
        window.localStorage.setItem(
          "quiz_details",
          JSON.stringify({
            questions: responsejson,
            quiz_name: quiz.quiz_name,
            subject: this.state.selected_subject.subject_name,
            subject_id: this.state.selected_subject.subject_id,
            topic_id: quiz.topic_id,
            topic: this.state.selectedSubjectTopic.find(
              (element) => element.topic_id === quiz.topic_id
            ).topic_name,
          })
        );
      })
      .then(() => this.setState({ quiz_modal: true }))
      .catch((error) => {
        //console.logerror);
        swal("Warning", "Network error", "warning");
      });
  }

  renderQuizModal(quiz_item) {
    this.getQuestionsByQuestionId(quiz_item);
    //this.selectQuiz(quiz_item)
    window.open(
      "http://localhost:3000/#/start-quiz",
      "_blank",
      "width=1280,height=720"
    );
  }

  renderLectureQuiz(quiz_item, index, index1) {
    return (
      <MDBCollapse
        className="CollapseInside_lesson"
        id={quiz_item.topic_id}
        isOpen={this.state.collapseID}
        style={{
          backgroundColor: quiz_item.selected == 1 ? "#e6f2f5" : "#fff",
        }}
        onClick={() => this.selectLecture(quiz_item, index, index1)}
      >
        <div className="d-flex justify-content-between align-items-center inside_collapse">
          <p
            style={{
              cursor: "pointer",
              color: "#4A4A4A",

              fontSize: "15px",

              marginBottom: ".5%",
            }}
            className=""
          >
            <MDBIcon icon="pencil-alt" /> {quiz_item.quiz_name}{" "}
            {this.renderCompleted(quiz_item, `Score: ${quiz_item.score}`)}
          </p>
        </div>
        <ReactTooltip place="bottom" type="dark" effect="float" />
      </MDBCollapse>
    );
  }

  renderCourseTopic() {
    if (this.state.topics.length != 0) {
      //console.logthis.state.topics);
      var courseTopics = this.state.topics.filter((topic) => topic.sample == 0);
      return courseTopics.map((modal, index1) => {
        let quizForTopic = false;
        let quiz = null;
        return (
          <>
            <MDBBtn
              className="CollapseBtn CollapseBtnCourse pl-2"
              color="primary"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "initial",
                alignItems: "center",
              }}
              onClick={() => this.toggleCollapse(modal.topic_id)}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "initial",
                }}
              >
                <div className="CourseIntroduction d-flex justify-space-between align-items-center">
                  <span>{modal.topic_name}</span>{" "}
                </div>

                <div className="CourseLectures">
                  <text>{modal.lectures.length} lecture(s) </text>{" "}
                </div>
              </div>
              <MDBIcon
                icon={
                  modal.topic_id == this.state.collapseID ? "minus" : "plus"
                }
                className="ml-auto mr-3"
              />
            </MDBBtn>
            {/* {console.log(modal.lectures)} */}
            {modal.lectures.map((item, index) => {
              console.log(index, item, "Lecture");

              //console.logitem)
              if (item.type != 10) {
                return (
                  <Fragment>
                    <MDBCollapse
                      className="CollapseInside_lesson"
                      id={modal.topic_id}
                      isOpen={this.state.collapseID}
                    >
                      <div
                        style={{
                          backgroundColor:
                            this.state.selected_Lec != null &&
                            item.id == this.state.selected_Lec.id
                              ? "#e6f2f5"
                              : "#fff",
                        }}
                        onClick={() => this.selectLecture(item, index, index1)}
                        className="d-flex justify-content-between align-items-center inside_collapse"
                      >
                        <p
                          style={{
                            cursor: "pointer",
                            color: "#4A4A4A",

                            fontSize: "15px",

                            marginBottom: ".5%",
                          }}
                          className=""
                        >
                          {index + 1}. {item.lecture_name}
                        </p>
                        <p
                          className="duration_text"
                          style={{ marginBottom: 0 }}
                        >
                          {this.renderIcon(item)} {item.duration}min{" "}
                          {this.renderCompleted(item, "Lecture Completed")}
                        </p>
                      </div>
                      <ReactTooltip place="bottom" type="dark" effect="float" />
                    </MDBCollapse>
                    {item.quiz_item
                      ? this.renderLectureQuiz(item.quiz_item, index, index1)
                      : ""}
                  </Fragment>
                );
              } else {
                //console.logitem);
                return (
                  <MDBCollapse
                    className="CollapseInside_lesson"
                    id={modal.topic_id}
                    isOpen={this.state.collapseID}
                    style={{
                      backgroundColor: item.selected == 1 ? "#e6f2f5" : "#fff",
                    }}
                    onClick={() => this.selectLecture(item, index, index1)}
                  >
                    <div className="d-flex justify-content-between align-items-center inside_collapse">
                      <p
                        style={{
                          cursor: "pointer",
                          color: "#4A4A4A",

                          fontSize: "15px",

                          marginBottom: ".5%",
                        }}
                        className=""
                      >
                        <MDBIcon icon="pencil-alt" /> {item.quiz_name}{" "}
                        {this.renderCompleted(item, `Score: ${item.score}`)}
                      </p>
                    </div>
                    <ReactTooltip place="bottom" type="dark" effect="float" />
                  </MDBCollapse>
                );
              }
            })}
          </>
        );
      });
    }
  }

  renderCompleted(item, messsage) {
    if (item.completed) {
      return (
        <MDBIcon
          style={{ color: "#FD8400", minWidth: 30, textAlign: "center" }}
          data-tip={messsage}
          icon="check-circle"
        />
      );
    }
  }

  handlePreviousPDFPage() {
    console.log(this.state.pageNumber);
    if (this.state.pageNumber <= 1 && this.state.selected_topic_index == 0) {
    } else if (this.state.pageNumber > 1) {
      this.setState({
        pageNumber: this.state.pageNumber - 1,
      });
    } else {
      this.selectLecture(
        this.state.topics[
          this.state.selected_lecture_index == 0
            ? this.state.selected_topic_index - 1
            : this.state.selected_topic_index
        ].lectures[
          this.state.selected_lecture_index == 0
            ? this.state.topics[
                this.state.selected_lecture_index == 0
                  ? this.state.selected_topic_index - 1
                  : this.state.selected_topic_index
              ].lectures.length - 1
            : this.state.selected_lecture_index - 1
        ],
        this.state.selected_lecture_index == 0
          ? this.state.topics[
              this.state.selected_lecture_index == 0
                ? this.state.selected_topic_index - 1
                : this.state.selected_topic_index
            ].lectures.length - 1
          : this.state.selected_lecture_index - 1,
        this.state.selected_lecture_index == 0
          ? this.state.selected_topic_index - 1
          : this.state.selected_topic_index
      );
    }
  }

  handleNextPDFPage() {
    if (
      this.state.pageNumber >= this.state.numPages &&
      this.state.selected_topic_index == this.state.topics.length - 1
    ) {
    } else if (this.state.pageNumber < this.state.numPages) {
      this.setState({
        pageNumber: this.state.pageNumber + 1,
      });
    } else {
      this.selectLecture(
        this.state.topics[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? this.state.selected_topic_index + 1
            : this.state.selected_topic_index
        ].lectures[
          this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
            ? 0
            : this.state.selected_lecture_index + 1
        ],
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? 0
          : this.state.selected_lecture_index + 1,
        this.state.selected_lecture_index ==
          this.state.topics[this.state.selected_topic_index].lectures.length - 1
          ? this.state.selected_topic_index + 1
          : this.state.selected_topic_index
      );
    }
  }

  renderLectures() {
    //console.logthis.state.selected_Lec, "lllll");
    const { pageNumber, numPages } = this.state;

    if (this.state.selected_Lec != null) {
      if (this.state.selected_Lec.type == "Image") {
        return (
          <Img src={this.state.selected_Lec.link} className="renderImage" />
        );
      } else if (this.state.selected_Lec.type == "PPT") {
        return (
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vTjjZQVU39rQygP9ZKLje1vbJzpkdogtCK5X8op_CFi3ByhkbpVNRBFFxYyTaziSCipXyADUmV2wHoh/embed?start=false&loop=false&delayms=3000"
            frameborder="0"
            width="1280"
            height="749"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          ></iframe>
        );
      } else if (this.state.selected_Lec.type == "PDF") {
        const { pageNumber, numPages } = this.state;
        return (
          <>
            <div>
              <Document
                file={this.state.selected_Lec.link}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>
          </>
        );
      } else if (this.state.selected_Lec.type == "Text") {
        return (
          <iframe
            src={this.state.selected_Lec.link}
            frameborder="0"
            width="100%"
            height="500"
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          >
            {" "}
          </iframe>
        );
      } else if (this.state.selected_Lec.type == "Video") {
        return (
          <>
            <ReactPlayer
              url={this.state.selected_Lec.link}
              width="100%"
              height="500px"
              playing
              controls={true}
              className="bg-dark video-lecture"
            />
          </>
        );
      }
    }
  }

  closeCourseContent() {
    document.getElementById("lessonContent").style.width = "90%";
    // document.getElementById("myCourse").style.opacity = "1";
    document.getElementById("myCourse").style.display = "none";
    document.getElementById("lessonCloseBtnDiv").style.display = "block";
    // document.getElementsByClassName("video-react").style.paddingTop='41%';
  }
  openCourseContent() {
    document.getElementById("lessonCloseBtnDiv").style.display = "none";

    document.getElementById("lessonContent").style.width = "70%";

    document.getElementById("myCourse").style.display = "block";
  }

  overViewColor() {
    document.getElementById("overViewChange").style.borderBottom =
      "4px solid #0093D9";
  }
  toggleDiv = () => {};

  getQuizzes = async (topic_ids) => {
    let formData = new FormData();
    formData.append("subject_id", this.state.selected_subject.subject_id);
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_quiz_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          let { completed_quiz } = this.state;
          for (var r = 0; r < responseJson.length; r++) {
            for (var q = 0; q < completed_quiz.length; q++) {
              if (responseJson[r].quiz_id == completed_quiz[q].quiz_id) {
                //console.logcompleted_quiz[q]);
                if (completed_quiz[q].score != null) {
                  responseJson[r].completed = 1;
                  responseJson[r].score = completed_quiz[q].score;
                }
              }
            }
          }
          this.setState({ quizzes: responseJson }, () =>
            this.getTopic(topic_ids, 1)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          //console.logerror);
        }),
    });
  };

  startTest() {
    //console.logthis.state.test_included);
    if (this.state.test_included == 1) {
      window.localStorage.removeItem("selected_test");
      window.localStorage.setItem(
        "selected_test",
        this.state.selected_subject.subject_id
      );
      this.props.history.push("/start-test");
    } else {
      swal(
        "",
        "To use the test series for this course, please purchase it from the Course Page",
        "info"
      );
    }
  }
  onclickstart = () => {};



 

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: calc(100vh / 2) auto;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      console.log(this.state);

      if (this.state.topics.length != 0) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
            id="lesson-page"
          >
            <div className="windowView">
              <ProductHeaderPage
                {...this.props}
                backgroundColor="#FFFFFF"
                textcolor="#000"
                width="100%"
                height="auto"
                progress={this.state.progress}
              />
            </div>

            <div className="vsMobView">
              <ProductMobMenuBar
                {...this.props}
                backgroundColor="white"
                textcolor="#000"
                height="auto"
                progress={this.state.progress}
                width="100%"
              />
            </div>
            <div className="productMainFirst">
              <text className="productMainFirstText">
                <span onClick={() => this.props.history.push("/")}>Home</span> >{" "}
                <span onClick={() => this.props.history.push("/my-courses")}>
                  {" "}
                  My Courses
                </span>
              </text>
            </div>

            <div className="position-relative" style={{ height: "80vh" }}>
              <div
                className={
                  this.state.showCourseTopicDiv
                    ? "d-block renderCloseArrow"
                    : "d-none"
                }
              >
                <button
                  className="btn-inverted"
                  onClick={() => this.setState({ showCourseTopicDiv: false })}
                >
                  <i
                    style={{ marginRight: "5px" }}
                    className="fas fa-arrow-right"
                  ></i>
                  {/* {console.logthis.state)} */}
                </button>
              </div>
              <div
                className="row mx-0"
                style={{ height: "100%", overflowY: "scroll" }}
                id="lessonContent"
              >
                <div
                  className={
                    this.state.showCourseTopicDiv
                      ? "lessonShowContent"
                      : "col-12 lessonShowContent vh-100"
                  }
                >
                  {this.renderLectures()}

                  <div
                    className={
                      this.state.showCourseTopicDiv
                        ? "d-none"
                        : "d-block renderopenArrow"
                    }
                  >
                    <button
                      className="lessonCloseBtn"
                      onClick={() =>
                        this.setState({ showCourseTopicDiv: true })
                      }
                    >
                      <i
                        style={{ marginRight: "5px" }}
                        className="fas fa-arrow-left"
                      ></i>
                      {/* {console.logthis.state)} */}
                    </button>
                  </div>
                  <img
                    style={{
                      display: "block",
                    }}
                    src={require("../../Assets/images/arrowRight.png")}
                    className="arrowRight"
                    onClick={() => this.handleNextPDFPage()}
                  />

                  <img
                    style={{
                      display: "block",
                    }}
                    onClick={() => this.handlePreviousPDFPage()}
                    src={require("../../Assets/images/arrowLeft.png")}
                    className="arrowLeft"
                  />
                </div>

                {this.state.selected_Lec &&
                  this.state.selected_Lec.type == "PDF" && (
                    <p
                      className="pdf-arrows"
                      style={
                        this.state.showCourseTopicDiv
                          ? { position: "absolute", width: "68%" }
                          : { position: "static", width: "98%" }
                      }
                    >
                      <MDBIcon
                        icon="angle-left"
                        style={{
                          color: "black",
                          pointerEvents: "none",
                          cursor: "pointer",
                          opacity: 0,
                        }}
                        onClick={() => this.handlePreviousPDFPage()}
                      />{" "}
                      Page {this.state.pageNumber} of {this.state.numPages}{" "}
                      <MDBIcon
                        icon="angle-right"
                        style={{
                          color: "black",
                          pointerEvents: "none",
                          cursor: "pointer",
                          opacity: 0,
                        }}
                        onClick={() => this.handleNextPDFPage()}
                      />
                    </p>
                  )}
                <div
                  className={this.state.showCourseTopicDiv ? "" : "d-none"}
                  id="lessonTopics"
                >
                  <p className="mt-3 mb-3 ml-3 d-flex justify-content-between courseContentIcon">
                    <span className="subjectName">
                      {this.state.subject_name}
                    </span>
                    <img
                      className="cross2 d-none"
                      onClick={() =>
                        this.setState({ showCourseTopicDiv: false })
                      }
                      src={require("../../Assets/images/cross_2.png")}
                    />
                  </p>
                  <div className="lessonTopics-topics-container">
                    {this.state.sampleMode
                      ? this.renderSampleCourseTopics()
                      : this.renderCourseTopic()}
                  </div>
                </div>

                <div className="w-100"></div>
              </div>
            </div>

            <div>
              <div
                className={
                  sampleMode == true
                    ? "d-none col-9"
                    : "col-9 lesson-details-container"
                }
              >
                <div className="w-100">
                  <div className="aboutCourse d-none">
                    <p className="aboutCourseHeading">About this course</p>

                    <text className="aboutCourseParagraph">
                      {this.state.aboutCourseDescription}
                    </text>
                  </div>

                  <div className="Features d-none">
                    <div className="Featurestext">
                      <text>Features</text>
                    </div>
                    <div className="FeaturesAvailable">
                      <text>
                        Available on{" "}
                        <span className="FeaturesSpan">iOS and Android</span>
                      </text>
                    </div>
                    <div className="FeaturesCertifcate">
                      <text>Certifcate of completion</text>
                    </div>
                  </div>

                  <div className="description d-none">
                    <div className="descriptiontext">
                      <text>Description</text>
                    </div>
                    <div className="descriptionPara">
                      <div>
                        {this.renderdescriptionCourse()}

                        {this.renderdescriptionList()}
                      </div>
                      <div className="descriptionTextHeadingDivMargin">
                        <text className="descriptionTextHeading">
                          Topic include :
                        </text>

                        {this.renderTopicInc()}
                      </div>
                      <div className="descriptionTextHeadingDivMargin">
                        <text className="descriptionTextHeading">
                          What you'll learn :
                        </text>

                        {this.renderWhatYou()}
                      </div>
                      <div className="descriptionTextHeadingDivMargin">
                        <text className="descriptionTextHeading">
                          Are there any course requirements prerequisites :
                        </text>

                        {this.renderR_there_any_coursec()}
                      </div>
                      <div className="descriptionTextHeadingDivMargin">
                        <text className="descriptionTextHeading">
                          Who this course is for :
                        </text>

                        {this.renderWho_course()}
                      </div>
                    </div>
                  </div>

                  {this.state.selected_subject.instructor_say == "null" ||
                  this.state.selected_subject.instructor_say == null ||
                  this.state.selected_subject.instructor_say.length <=
                    0 ? null : (
                    <div className="instructor mt-5">
                      <div className="testSeriestext mr-3" onClick={()=>this.setState((prevState)=>({showTutorSpeak:!prevState.showTutorSpeak}))}>
                        <text>Tutor speak</text>{" "}
                        {this.state.showTutorSpeak  ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
                      </div>
                      <br />
                      
                      <MDBCollapse
                        id="tutor_speak"
                        isOpen={this.state.showTutorSpeak}
                      >
                        <div className="instructorInfo">
                          <div className="instructorInfo_pic_sub"></div>
                          <div className="instructorSubDescription">
                            <text
                              dangerouslySetInnerHTML={{
                                __html: this.state.selected_subject
                                  .instructor_say,
                              }}
                            ></text>
                          </div>
                        </div>
                      </MDBCollapse>
                    </div>
                  )}
                  <div
                    className={
                      this.state.selected_subject.test_id == "false"
                        ? "d-none"
                        : "testSeries mt-5"
                    }
                  >
                    <div className="testSeriestext mr-3 mt-4">
                      <text>Test Series</text>
                    </div>
                    <div className="testSeriesTextBtn">
                      <div>
                        <text></text>
                      </div>
                      <div>
                        <button
                          className="ButtonReset ButtonTest"
                          type="button"
                          onClick={() => this.startTest()}
                        >
                          Start Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ height: "auto" }} className="windowView">
              <MainFooterPage {...this.props} />
            </div>
            <div className="vsMobView ">
              <StickyFooterPage {...this.props} />
            </div>
            {this.renderModal()}
          </div>
        );
      } else {
        return (
          <div className="sweet-loading">
            <GridLoader
              css={override}
              sizeUnit={"px"}
              size={5}
              color={"#2fb2eb"}
              loading={this.state.loading}
            />
          </div>
        );
      }
    }
  }
}
export default lessons;
