import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";
import Select from "react-select";
import Fade from "react-reveal";
import swal from "sweetalert";
// import "react-picky/dist/picky.css"; //
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import "./quiz.css";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;


export default class EditQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false,
      quizTitle:'',
      subjects:[],
      subjectTopics:[],
      selectedSubject:{},
      selectedSubjectTopic:{},
      questionListShowing:true,
      selectedTopicQuestions:[],
      dragDropQuestions:[],
      topicLectures:[],
      selectedLecture:{}
    };

    this.itemRenderer = this.itemRenderer.bind(this);
    this.handleRLDDChange = this.handleRLDDChange.bind(this);
  }

  componentWillMount() { 
    this.setState({loading:true})
    this.getQuizDetails()

  }


  getQuizDetails(){
    if(!window.localStorage.getItem('quiz_item')){
        this.props.history.push('/admin/quiz')
    }
    else{
      console.log(JSON.parse(window.localStorage.getItem('quiz_item')))
        let quizDetails = JSON.parse(window.localStorage.getItem('quiz_item'))
         //    let selectedSubject = this.state.subjects.find(item => item.subject_id === quizDetails.subject_id)
            // if(selectedSubject === undefined){
            //   swal("The subject for this quiz no longer exists in the database", '', 'info')
            //   .then(value => {
            //     this.props.history.push('/admin/quiz')
            //   })
            // }
            // else{
         //    console.log(this.state.subjects)
            this.setState({
                quizDetails
            },()=>this.getSubjects())
            //this.getSubjectTopics(selectedSubject.value) )
         // }
        }
  }

  getSubjects(){
      fetch("https://www.vimasmiham.com/get_subjects", {
          method:'GET'
      })
      .then(response => response.json())
      .then(responseJson => {
            responseJson.forEach(element => {
                    element.label = element.subject_name;
                    element.value = element.subject_id;
            })
        console.log(responseJson)
        this.setState({subjects:responseJson},()=>this.getSubjectTopics(this.state.quizDetails.subject_id))
          //()=>  this.getQuizDetails())
      }
      );
    
  }

  getSubjectTopics(subject_id){

        //this.setState({loading:true})
        
        let formData = new FormData()
        formData.append('subject_id',subject_id)
    for (let key of formData.entries()) {
                console.log(key[0] + ',with photo ' + key[1]);
            }
        fetch("https://www.vimasmiham.com/get_subject_topics", {
            method:'POST',
            body:formData
        })
        .then(response => response.json())
        .then(responseJson => {
            let topics = JSON.parse(responseJson[0].topics)
            topics.forEach(element => {
                    element.label = element.name;
                    element.value = element.topic_id;
            })
            console.log(topics)
            this.setState({subjectTopics:topics}, ()=>this.getTopicQuestions(this.state.quizDetails.topic_id))
             }
           );
    
    //selectedSubjectTopic:topics.find(item=>item.topic_id === this.state.quizDetails.topic_id)?topics.find(item=>item.topic_id === this.state.quizDetails.topic_id):null}
  }

  getTopicQuestions(topic_id){

      //  this.setState({loading:true})
        
      if(topic_id == null){
       
      }
      else{
        let formData = new FormData()
        formData.append('topics',topic_id)
    for (let key of formData.entries()) {
                console.log(key[0] + ',with photo ' + key[1]);
            }
        fetch("https://www.vimasmiham.com/get_topic_questions ", {
            method:'POST',
            body:formData
        })
        .then(response => response.json())
        .then(responseJson => {
            console.log(responseJson)

 

        this.setState({topicQuestions:responseJson},()=>this.getTopicLectures(topic_id));   
        })
      }

    }

  setQuizDetails(){ 
    console.log(this.state)
    let {quizDetails, topicQuestions} = this.state; 
        topicQuestions.forEach((element, index) => {
                element.label = `${index+1}. ${element.question_title}`;
                element.value = element.question_id;
                element.id = element.question_id
        })

    console.log(topicQuestions)
    let selectedTopicQuestions = []
    console.log(JSON.parse(quizDetails.questions))
    let quizQuestions = JSON.parse(quizDetails.questions)
        if(quizQuestions !== null){
          quizQuestions.forEach(question_id=>{
            let question = topicQuestions.find(item=>item.question_id === question_id)
            if(question){
            console.count("question matched")
            selectedTopicQuestions.push(question)
              }
              else{
              
              }
          })
        }
    this.setState({
      quizTitle:quizDetails.quiz_name,
      selectedSubject:this.state.subjects.find(subject => subject.subject_id == quizDetails.subject_id),
      selectedSubjectTopic:this.state.subjectTopics.find(topic => topic.topic_id == quizDetails.topic_id),
      selectedLecture:quizDetails.lecture_id == null?null:this.state.topicLectures.find(lecture => lecture.id == quizDetails.lecture_id),
      selectedTopicQuestions,
      dragDropQuestions:selectedTopicQuestions,
      loading:false
    })
  }

  getTopicLectures(topic_id){
    let formData = new FormData()
    formData.append("topic_id", topic_id)

    fetch("https://www.vimasmiham.com/get_topic_lectures", {
      method:'POST',
      body:formData
    })
    .then(response => response.json())
    .then(responseJson => {
      console.log(responseJson)
      let topicLectures = JSON.parse(responseJson[0].lectures)
      this.setState({topicLectures}, ()=>this.setQuizDetails())
    })
  }

  editQuiz(){
    if(this.state.selectedSubject == null || this.state.selectedSubject.length <= 0){
        swal("","Please choose a subject!", 'warning')
    }
    else if(this.state.selectedSubjectTopic == null || this.state.selectedSubjectTopic.length <= 0){
        swal("","Please choose a topic!", 'warning')
    }
    else if(this.state.selectedTopicQuestions == null || this.state.selectedTopicQuestions.length <= 0){
        swal("","Please choose a few questions for the quiz!", 'warning')
    }
    else{
        let questions = []
        this.state.dragDropQuestions.forEach(element => {
            questions.push(element.question_id)
        });
        let formData  = new FormData();
        formData.append('quiz_id', this.state.quizDetails.quiz_id)
        formData.append('topic_id', this.state.selectedSubjectTopic.value)
        formData.append('questions', JSON.stringify(questions))
        formData.append('subject_id', this.state.selectedSubject.value)
        formData.append('quiz_name', this.state.quizTitle)
        formData.append('lecture_id', this.state.selectedLecture.value)
        console.log(formData)
        for (let key of formData.entries()) {
            console.log(key[0] + ',with photo ' + key[1]);
        }
        fetch("https://www.vimasmiham.com/edit_quiz", {
            method:"POST",
            body:formData
        })
        .then(response => {
            console.log(response)
            swal("Quiz succesfully edited", '', 'success')
            this.props.history.push('/admin/quiz')
        })
        .catch(error=> {
            console.log(error)
            swal("Network issue", "Please check your network", "warning")
        })
    }
  }

  //ITEM RENDERER DRAG DROP
  itemRenderer(item, index) {
    return (
      <div className="item" key={item.question_id}>
        <p className="title">{item.question_title}</p>
        <button
          className="delete-subject-topic-button"
          onClick={() => this.deleteLecture(item)}
        >
          <i className="fas fa-trash"></i>
        </button>
      </div>
    );
  }

  handleRLDDChange(reorderedItems) {
    this.setState({ dragDropQuestions: reorderedItems });
  }


  addTopicQuestions(selectedTopicQuestions) {
    console.log(selectedTopicQuestions)
    if (!selectedTopicQuestions) {
      swal({
        title: `No Selections Made`,
        text: `You have not seleted any questions to add`,
        icon: "error",
        buttons: false,
        dangerMode: true
      });
    } else {
      swal({
        title: `Confirm selection?`,
        text: `You are adding ${selectedTopicQuestions.length} questions to this course`,
        icon: "info",
        buttons: true,
        dangerMode: false
      }).then(willAdd => {
        if (willAdd) {
          var array = [];
          var i = 0;

          selectedTopicQuestions.forEach(element => {
            var questionObject = new Object();
            questionObject = element;
            questionObject.sortOrder = i;
            array.push(questionObject);
            i++;
          });
          console.log(array);
          this.setState({ dragDropQuestions: array });
        } else {
        }
      });
    }
  }

  deleteLecture(item) {
    let { dragDropQuestions } = this.state;
    console.log("deleted", item);
    var newQuestionsList = dragDropQuestions.filter(
      Listitem => Listitem.id != item.id
    );
    var i = 0;
    newQuestionsList.forEach(element => {
      element.sortOrder = i;
      i++;
    });
    console.log("topics", newQuestionsList);
    this.setState({
      dragDropQuestions: newQuestionsList,
      selectedTopicQuestions: newQuestionsList
    });
  }


  handleSubjectChange(e){
      let {quizDetails}= this.state;
      quizDetails.subject_id = e.value;
      quizDetails.topic_id = null;
      quizDetails.lecture_id = null;
      quizDetails.questions = null
      this.setState({
        selectedSubject:e,
        selectedLecture:{},
        selectedSubjectTopic:{},
        selectedTopicQuestions:null,
        dragDropQuestions:null,
        quizDetails 
        }, ()=>this.getSubjectTopics(this.state.selectedSubject.value))
  }

  renderQuizTitle(){
    return(
        <div className="col-12  subject-name-section d-flex">
             <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                  Title:
             </label>
             <input
                    value={this.state.quizTitle}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({quizTitle:text.target.value})}
                />
        </div>
    )
}

  renderSubjectSelect = () => {
    return (
      <div className="col-12  test-id-section d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="test-id-label subject-labels"
        >
          Subject :
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e)=>this.handleSubjectChange(e)}
          value={this.state.selectedSubject}
          className="select-testid select "
          options={this.state.subjects}
        />
      </div>
    );
  };

  handleTopicSelect(e){
    let {quizDetails} = this.state
    quizDetails.topic_id = e.value
    quizDetails.lecture_id = null

    this.setState({
      selectedSubjectTopic:e,
      selectedLecture:null,
      quizDetails
    }, ()=>this.getTopicQuestions(this.state.selectedSubjectTopic.topic_id))
  }

  renderTopicSelect = () => {
    return (
      <div className="col-12  test-id-section d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="test-id-label subject-labels"
        >
          Topic :
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e)=>this.handleTopicSelect(e)}
          value={this.state.selectedSubjectTopic}
          className="select-testid select "
          options={this.state.subjectTopics}
        />
      </div>
    );
  };

  
  handleLectureSelect(e){
    this.setState({selectedLecture:e})
  }


  renderLectureSelect(){
    return (
      <div className="col-12  test-id-section d-flex align-items-center">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="test-id-label subject-labels"
        >
          Lecture : 
          <br/>
          <p className="my-0 text-muted font-italic optional-lecture-text"> (Optional)</p>
        </label>
        <Select
          closeMenuOnSelect={true}
          onChange={(e)=>this.handleLectureSelect(e)}
          value={this.state.selectedLecture}
          className="select-testid select "
          options={this.state.topicLectures}
        />
      </div>
    );
  }

  renderQuestionsPickySection() {
    return (
      <React.Fragment>
        <button
          className="show-topics-list-button mb-3"
          onClick={() =>
            this.setState({ questionListShowing: !this.state.questionListShowing })
          }
        >
          {" "}
          Questions list <i className="fa fa-chevron-down" />
        </button>
        {this.state.questionListShowing ? (
          <div className="lecture-list-picky-container">
            <Select
              closeMenuOnSelect={false}
              isMulti
              onChange={e =>
                this.setState({ selectedTopicQuestions: e }, () =>
                  console.log(this.state.selectedTopicQuestions)
                )
              }
              value={this.state.selectedTopicQuestions}
              className="select-exam select w-100"
              options={this.state.topicQuestions}
            />
            <div>
              <button
                className="w-50 float-right admin-button-style"
                onClick={() =>
                  this.addTopicQuestions(this.state.selectedTopicQuestions)
                }
              >
                Save questions
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  renderQuestionsStructureSection() {
    if (this.state.dragDropQuestions)
      return (
        <RLDD
          cssClasses="drag-drop-topic-list"
          items={this.state.dragDropQuestions}
          itemRenderer={this.itemRenderer}
          onChange={this.handleRLDDChange}
          
        />
      );
    else {
      return (
        <p className="text-center text-muted">
          There are no questions currently selected for this quiz!
        </p>
      );
    }
  }

  renderQuestionsSection() {
    return (
      <React.Fragment>
        <div className="col-12 subject-topics-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="exam-label subject-labels py-3"
          >
            Add Questions:
          </label>
          <div className="row mx-0 bg-white">
            <div className="col-3 topic-list-column px-0">
              {this.renderQuestionsPickySection()}
            </div>

            <div className="col-9 topic-structure-column">
              <label
                htmlFor="defaultFormRegisterNameEx"
                className="exam-label subject-labels text-center w-100"
              >
                Questions Order:
              </label>
              <hr />
              {this.renderQuestionsStructureSection()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={10} />

          <div className="add-subject-column">
          {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
            <React.Fragment>
              <Fade>
            <div>
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <button
                      className="admin-button-style"
                      onClick={() => this.props.history.push("/admin/quiz")}
                    >
                      All Quizzes
                    </button>
                    <button
                      className="admin-button-style"
                      onClick={() => this.editQuiz()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Quiz</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="subject-content-container ml-4">
                    <div className="row px-0 mx-0">
                      {this.renderQuizTitle()}
                      {this.renderSubjectSelect()}
                      {this.renderTopicSelect()}
                      {this.renderLectureSelect()}
                      
                      {/* <hr /> */}
                    </div>
                  </div>
                  <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4"></div>
                </div>
                <div className="subject-topics-container mx-4 mt-4">
                  <div className="row p-0 mx-0">
                    {this.renderQuestionsSection()}
                  </div>
                  {/* <hr /> */}
                </div>
                <button
                  className="add-subject my-5 float-right mr-4"
                  onClick={() => this.editQuiz()}
                >
                  Save &amp; Publish
                </button>
                </div>
              </Fade>
            </React.Fragment>

                )
              }
            </div>
        </div>
      </div>
    );
  }
}
