import React from "react";
import "../../Components/Exam-News/Exam-news.css";

import '../../Components/Homepage/homepage.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from 'sweetalert'
import "react-responsive-carousel/lib/styles/carousel.min.css";


import "./appraisal.css";


import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import {Link} from 'react-router-dom'

const redirectButtons = {
  textTransform:'capitalize',
  borderRadius:'4px',
  fontSize: '16px',
  fontFamily: 'Ubuntu-M',
  color: '#908b8b'
}


class ActsAndRegs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: "",
      mainTitle: "",
      faculties:[],
      appraisal:null,
      mainText: "",
      objectiveImage: ``,
      objectiveText:'',
      objectiveTitle: "",
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      visionTitle: "",
      visionText: "",
      visionImage: ``,faqs:[],cart:[],collapseID:0
    };
  }
  componentWillMount() {
   
      var userdata = window.localStorage.getItem("userdata");
      if (userdata != null) {
        this.setState({
          userdata: JSON.parse(userdata)
        },()=>this.getCart());
      }
    
    this.getAppraisal();
  }
 
  toggleCollapse(id) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0
      });
    } else {
      this.setState({
        collapseID: id
      });
    }
  }
  getAppraisal = () => {
  this.setState({
    loading:true
  })
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_acts_and_regs", {
        method: "GET",
        headers: {
                   
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);

          this.setState({
            appraisal:responseJson,
           loading:false
          });
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  next() {
    this.slider.slickNext();
  }

  prev() {
    this.slider.slickPrev();
  }
  renderObjectives(){

    return(
      <div className="row mission-row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">

      <img src={this.state.objectiveImage}  className='image-about-us'/>
 
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
     <p className='about-us-description-font'>{this.state.objectiveText}</p> 
      </div>
    </div>

    )
  }


  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 6%" }}>
         
        <span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/')} on>Home</span> / {this.state.appraisal[0].heading}
        </p>
      </div>
    );
  }
  

 

  renderAppraisal(){
      if(this.state.appraisal[0].status==1){
          return(
              <div dangerouslySetInnerHTML={{__html:this.state.appraisal[0].content}}></div>
          )
      }
      else{
          return(
                  <h2 className='coming_soon'>Coming Soon</h2>
          )
      }
 }


 getCart = () => {
  let details = {
    user_id: this.state.userdata.user_id
  };

  console.log(details, "fetching-details");
  let formBody = [];
  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  this.setState({
    response: fetch("https://www.vimasmiham.com/get_cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000"
      },
      body: formBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, "cart");

        this.setState({
          cart: responseJson
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });

        console.log(error);
      })
  });
};



  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
        

        <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            active="My Dashboard"
          />
 
<div id="aboutUs">
          <div className="about-main ">
            <h1 className="about-us-heading">{this.state.appraisal[0].heading}</h1>
          </div>

          <div>{this.renderAboutNavigation()}</div>
         

          <div className="redirect-boxes d-none d-md-flex" style={{display: 'flex',justifyContent: 'center',flexWrap: 'wrap'}}>
                <Link className="btn" style={redirectButtons} to="/acts_and_regulations" >Acts &amp; Regulations</Link>
                <Link className="btn" style={redirectButtons} to="/categories/promotional-exams">Promotional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/categories/professional-exams" >Professional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/latest-news" >Latest News</Link>
                <Link className="btn" style={redirectButtons} to="/test-series" >Test Series</Link>
                <Link className="btn" style={redirectButtons} to="/all-faculty" >All Faculty</Link>
                <Link className="btn" style={redirectButtons} to="/case-laws" >Case Laws</Link>
            
            </div>

       
               
            <div className="faq-container py-3">
            <div className="row py-4 " style={{justifyContent:'space-between'}} >
                
                      {this.renderAppraisal()}

                 

                
              </div>
            </div>       
          
                   
         

         

            <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
        </div>
        </div>
      );
    }
  }
}

export default ActsAndRegs;
