import React from 'react';
import SearchIcon from "../../Assets/images/search.png";
import {Redirect} from 'react-router-dom'

import './searchbar.css'

class Searchbar extends React.Component {

  constructor(props){
    super(props);
    this.state={
      search:'',
      response:null,
      subjects:null
  }
}




  getSubjects = async() => {
    const response = await fetch("https://www.vimasmiham.com/get_subjects")
    const subjects = await response.json()
    return subjects
  }


  componentDidMount = async()=>{
    const response = await this.getSubjects()
     if(response){
      let subjects = []
      response.forEach(element => {
        let subject = {}
        const subject_name = element.subject_name + ' | ';
        var exams = []
        JSON.parse(element.exams).forEach(exam=>{
          exams.push(exam.exam_name)
        })
        const exam_names = exams.join(' | ')
        const final_name = subject_name + exam_names
        subject.name = final_name
        subject.value = element.url
        subjects.push(subject)
      });
    
      this.setState({subjects})
    }
  }



  renderOptions(){
    if(this.state.subjects){
      if(this.state.search.length <= 0 ){
        return <div></div>
      }
      else{
        return(
          <div className="search-options">
        <ul className="list-unstyled">
          { this.state.subjects.filter(subject=>subject.name.toLowerCase().indexOf(this.state.search.toLowerCase())>=0).map((subject)=>{
        return <li key={subject.value} onClick={()=>this.props.history.push(`/courses/${subject.value}`)} >{subject.name}</li>
          })
      }
        </ul>
        </div>
         )     
    }
      }
  }
    render(){
       
      

      return (
        <div className="search-bar col ">

      <input type="text" placeholder="Search..." value={this.state.search} onChange={(e)=>this.setState({search:e.target.value})} />
       <div className="col" style={{display:this.props.showIcon}}>
                        <img
                        onClick={()=>console.log('asdasd')}
                          src={SearchIcon}
                          className="search-icon"
                          alt="search"
                        />
                      </div>
      
        
          {this.renderOptions()}
    

     
    </div>
      );
    }
}

export default Searchbar;
