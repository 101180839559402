import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import "../AddSubject/addSubject.css";
import "../Lectures/Addlecture.css";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import { MDBIcon } from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";

// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class Addfaculty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      facultyName: "",
      profession: "",
      sortOrder: 0,
      description: ``,
      facultyThumbnail: ``,
      facultyThumbnailUrl: ``,
    };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillMount() {
    //    this.setState({loading:true})
  }

  componentDidMount() {}

  //GETS , POSTS , FORM VALIDATION

  onEditorChange(e) {
    console.log(e);
    this.setState({
      description: e.editor.getData(),
    });
  }

  handleMediaChooseImage = (inputLink) => {
    this.setState({ facultyThumbnailUrl: inputLink });
  };

  //ADD FORM

  addFaculty() {
    if (this.state.facultyName.length == 0) {
      swal("Check  name!", "Please enter valid name", "error");
    } else if (!this.state.profession) {
      swal("Check thumbnail field", "Please enter profession!", "error");
    } else if (!this.state.description) {
      swal("Check description", "Please enter description!", "error");
    } else if (!this.state.facultyThumbnailUrl) {
      swal("Check Thumbnail url", "Please upload a thumbnail!", "error");
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });

      console.log(this.state.facultyThumbnailUrl);
      console.log(this.state.sortOrder);
      let formData = new FormData();
      formData.append("faculty_name", this.state.facultyName);
      formData.append("faculty_profession", this.state.profession);
      formData.append("faculty_description", this.state.description);
      formData.append("faculty_picurl", this.state.facultyThumbnailUrl);
      formData.append("sort_order", this.state.sortOrder);
      // console.log(this.state.facultyName,this.state.facultyThumbnail,this.state.profession,this.state.description)
      this.setState({
        response: fetch("https://www.vimasmiham.com/add_our_faculty", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "QWERTy");
            swal("Success", "Faculty succesfullly added", "success").then(
              (value) => {
                this.props.history.push("/admin/faculties");
              }
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  //GET FUNCTIONS

  //ITEM RENDERER DRAG DROP

  //HANDLE CLICK EVENTS

  //HANDLE CHANGE FUNCTIONS

  handleFacultyNameChange = (text) => {
    this.setState({ facultyName: text.target.value });
  };

  handleProfessionChange = (text) => {
    this.setState({ profession: text.target.value });
  };
  handleFacultyDescription = (text) => {
    this.setState({ description: text.target.value });
  };

  //RENDER FUNCTIONS
  renderFacultyNameSection() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Faculty Name:
        </label>
        <input
          value={this.state.facultyName}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.handleFacultyNameChange(text)}
        />
      </div>
    );
  }

  renderSortOrder() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Faculty Order:
        </label>
        <input
          value={this.state.sortOrder}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.setState({ sortOrder: text.target.value })}
        />
      </div>
    );
  }

  renderFacultyProfessionSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Qualifications:
        </label>
        <input
          value={this.state.profession}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.handleProfessionChange(text)}
        />
      </div>
    );
  }

  renderFacultyThumbSection() {
    if (!this.state.subjectThumbnail) {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels"
          >
            Faculty Thumbnail Url: <br />{" "}
            <span className="text-right text-muted ml-auto">
              {" "}
              <i>(280 x 200 pixels)</i>
            </span>
          </label>
          <div
            className="flex-column "
            style={{
              width: "39.4%",
              borderLeft: "1px solid #bfbfbf",
              height: "100%",
              padding: "5px 0px",
            }}
          >
            <input
              type="text"
              placeholder="Choose File From Gallery"
              className="thumbnail-file-input d-block link-placeholder"
              accept="image/*"
              aria-describedby="inputGroupFileAddon01"
              value={this.state.facultyThumbnailUrl}
              onChange={(text) =>
                this.setState({ facultyThumbnailUrl: text.target.value })
              }
            />
            <div style={{ marginLeft: "5%" }}>
              <MediaComponent
                onSelectMediaItem={this.handleMediaChooseImage}
                buttonName={<MDBIcon icon="camera" />}
                restriction="image/*"
              />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-12 d-flex align-items-center subject-thumbnail-container">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="image-label subject-labels h-100"
          >
            Faculty Thumbnail:
          </label>
          <div className="position-relative">
            <img
              src={this.state.facultyThumbnailUrl}
              style={{ margin: "10px 0px", width: "300px" }}
            />
            <MDBIcon
              style={{
                position: "absolute",
                top: "20px",
                right: "-5px",
                cursor: "pointer",
              }}
              className="cross_icon"
              onClick={() =>
                this.setState({
                  facultyThumbnail: null,
                  facultyThumbnailUrl: null,
                })
              }
              icon="times-circle"
            />
          </div>
        </div>
      );
    }
  }

  renderFacultyDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Faculty Description:
          </label>
          <div className="descriptions-container">
            {this.renderFacultyDescriptions()}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFacultyDescriptions() {
    return (
      <React.Fragment>
        <div className="d-flex align-items-start mt-3 mb-3">
          <div className="position-relative w-100 ml-3 mr-4">
            <CKEditor
              data={this.state.description}
              style={{ width: "88%" }}
              onChange={this.onEditorChange}
              className="textFontSize"
            />
          </div>
        </div>
        <div className="w-100" />
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={1} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div
                    className="col-12 top_button_bar d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <button
                      className="admin-button-style admin-button-style-margin"
                      onClick={() =>
                        this.props.history.push("/admin/faculties")
                      }
                    >
                      All Team Members
                    </button>

                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.addFaculty()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Add Member</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="subject-content-container ml-4"
                    style={{ width: "80%" }}
                  >
                    <div className="row px-0 mx-0 ">
                      {this.renderFacultyNameSection()}
                      {this.renderFacultyProfessionSection()}
                      {this.renderFacultyDescriptionContainer()}
                      {this.renderSortOrder()}

                      {this.renderFacultyThumbSection()}
                    </div>
                  </div>
                </div>

                <hr />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Addfaculty;
