import React, { Component, Fragment } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import "./test.css";
import Select from "react-select";
import swal from "sweetalert";
import CKEditor from "ckeditor4-react";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

export default class EditTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      testTitle: "",
      subjectList: [],
      description: "",
      testCost:0,

      test_id: null,
    };
  }

  componentWillMount() {
    this.getSubjects();
  }

  componentDidMount() {
    console.log("State", this.state);
  }

  getSubjects() {
    var subjectList = JSON.parse(window.localStorage.getItem("subject_list"));
    this.getDetails(subjectList);
  }

  getDetails(subjectList) {
    var testItem = JSON.parse(window.localStorage.getItem("test_item"));
    console.log(testItem);
    let subject_id = subjectList.find(
      (item) => item.subject_id === testItem.subject_id
    ).subject_id;
    let subject_name = subjectList.find(
      (item) => item.subject_id === testItem.subject_id
    ).label;

    this.setState({
      testTitle: testItem.test_name,

      test_id: testItem.test_id,

      description: testItem.testDescription,

      testCost: testItem.cost
    });
  }

  addTest() {
    this.setState({
      loading: true,
    });

    let details = {
      test_name: this.state.testTitle,
      test_id: this.state.test_id,
      testDescription: this.state.description,
      testCost:this.state.testCost
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_test", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          swal("Success!", "Test added", "success");
        })
        .then((value) => this.props.history.push('/admin/test'))
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderTestTitle() {
    return (
      <div className="col-6 test-title-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Test name:
        </label>
        <input
          value={this.state.testTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control  custom-form"
          onChange={(text) => this.setState({ testTitle: text.target.value })}
        />
      </div>
    );
  }

  onEditorChange(e) {
    this.setState({ description: e.editor.getData() });
  }

  renderDescriptionEditor() {
    return (
      <div className="col-6 test-title-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label"
        >
          Description :
        </label>
        <CKEditor
          data={this.state.description}
          onChange={(e) => this.onEditorChange(e)}
          className="w-75"
        />
      </div>
    );
  }

  renderTestCost() {
    return (
      <div className="col-4 test-cost-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="question-topics-label subject-labels question-label "
        >
          Cost:
        </label>
        <input
          value={this.state.testCost}
          type="number"
          id="defaultFormRegisterNameEx"
          className="form-control  custom-form"
          onChange={(text) => this.setState({ testCost: text.target.value })}
        />
      </div>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page" className="add-test-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={9} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <Fade>
                  <h1 className="px-5 my-3"> Edit Test</h1>
                  <div className="row px-5 my-5 mx-0">
                    {this.renderTestTitle()}

                    <hr />
                    {this.renderDescriptionEditor()}
                    {this.renderTestCost()}

                    <hr />
                    <button
                      className="add-subject"
                      onClick={() => this.addTest()}
                    >
                      Save
                    </button>
                  </div>
                </Fade>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
