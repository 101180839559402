import React from "react";
import "./myCart.css";
import swal from "sweetalert";
import MetaTags from "react-meta-tags";
import AccountNav from "../../Reusable/AccountNavigation/AccountNav";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import Fade from "react-reveal/Fade";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import ReactToPrint from "react-to-print";
import ReactTooltip from "react-tooltip";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import { HollowDotsSpinner } from "react-epic-spinners";
import Logo from "../../Assets/images/logo.png";
import {
  MDBIcon,
  MDBModal,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import { PDFExport } from "@progress/kendo-react-pdf";
import { RegionDropdown } from "react-country-region-selector";
import StateCodes from "./stateCodes.json";

class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myCart: [],
      cart: [],
      all_subjects: [],
      old_total: 0,
      address: null,
      edit_address: false,
      add_address: false,
      address_edit_text: "",
      page_title: "",
      page_description: "",
      address_add_text: "",
      address_loading: false,
      calculated_tax: 0,
      calculated_cess: 0,
      date: new Date(),
      invoice_modal: false,
      invoice_number: "",
      promos: [],
      PromoCode: "",
      new_total: 0,
      all_orders: [],
      discount: 0,
      promo_data: null,
      promoApplied: false,
      total_cost: 0,
      promo_modal: false,
      country: "",
      region: "",
      phone: "",
      pincode: "",
      state: "",
      tax: [],
      new_order_id: 0,
      download_invoice: 0,
    };
  }
  getPromos = async () => {
    this.setState({
      loading: true,
    });

    let details = {};

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_promo_codes_client", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          const filterdResponse = responseJson.filter((promoCode) => {
            const promoCodeUsers = promoCode.users
              ? JSON.parse(promoCode.users)
              : [];
            const userId = this.state.userdata.user_id;

            return (
              new Date(promoCode.end_date).getTime() > new Date().getTime() &&
              (promoCodeUsers.length === 0
                ? true
                : promoCodeUsers?.find((u) => u.value === userId)
                ? true
                : false)
            );
          });
          this.setState({
            promos: filterdResponse,
          });

          console.log(this.state.promos, "promos");
        })
        .catch((error) => {
          this.setState({});

          console.log(error);
        }),
    });
  };

  getUsers = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_users", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            users: responseJson,
          });

          console.log(this.state.users, "users____");
        })
        .catch((error) => {
          this.setState({});

          console.log(error);
        }),
    });
  };

  componentDidMount() {
    // this.getHeader()
    console.log(StateCodes);
  }

  componentWillMount() {
    this.getDetails();
    this.getPromos();
    this.getUsers();
    this.getInvoiceNo();
    this.getTax();
    this.getUserSEO();
  }
  getUserSEO = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_user_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "clients");
          this.setState(
            {
              page_title: responseJson[0].cart_title_seo,
              page_description: responseJson[0].cart_description_seo,
            },
            () =>
              console.log(this.state, "eggggggggggggggggggggggggggggggggggg")
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  handleApply = () => {
    console.log("called");
    if (this.state.PromoCode.length == 0) {
    } else {
      var promos = [],
        offerCount = 0;
      this.setState({ dialogVisible: false, old_total: this.state.total_cost });
      for (let v = 0; v < this.state.promos.length; v++) {
        console.log(
          this.state.PromoCode,
          this.state.promos[v].promo_code.toUpperCase()
        );
        if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 0
        ) {
          offerCount = offerCount + 1;

          for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
            if (
              this.state.promos[v].promo_id ===
              this.state.all_orders[v1].promo_id
            ) {
              promos.push(this.state.all_orders[v1]);
            }
          }
          if (promos.length >= this.state.promos[v].use_limit) {
            swal("Warning!", "You have used this offer!", "warning");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (this.state.promos[v].status === 2) {
            swal("Warning!", "This offer is no longer available", "warning");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (
            this.state.old_total >=
            parseFloat(this.state.promos[v].minimum_order)
          ) {
            let discount = (
              this.state.old_total *
              (this.state.promos[v].discount / 100)
            ).toFixed(2);
            if (discount > parseFloat(this.state.promos[v].max_off)) {
              this.setState({
                old_total:
                  this.state.old_total -
                  parseFloat(this.state.promos[v].max_off),
                promoApplied: true,
                promo_id: this.state.promos[v].promo_id,
                discount: parseFloat(this.state.promos[v].max_off),
                promo_data: this.state.promos[v],
              });
            } else {
              this.setState({
                old_total: this.state.old_total - discount,
                promoApplied: true,
                promo_id: this.state.promos[v].promo_id,
                discount: discount,
                promo_data: this.state.promos[v],
              });
            }
          } else {
            swal(
              "Warning!",
              `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 1
        ) {
          offerCount = offerCount + 1;
          console.log("====1");
          for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
            if (
              this.state.promos[v].id === this.state.all_orders[v1].promo_id
            ) {
              promos.push(this.state.all_orders[v1]);
            }
          }
          if (promos.length >= this.state.promos[v].use_limit) {
            swal("Warning!", "You have used this offer!", "warning");

            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else if (this.state.promos[v].status === 0) {
            swal("", "This offer is no longer available!", "info");
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            let offerItems = [];
            let orderItems = [];
            let count = 0;
            console.log(this.state.myCart);
            for (let v3 = 0; v3 < this.state.myCart.length; v3++) {
              orderItems.push(this.state.myCart[v3].subject_id);
            }
            for (
              let v2 = 0;
              v2 < JSON.parse(this.state.promos[v].products).length;
              v2++
            ) {
              offerItems.push(
                JSON.parse(this.state.promos[v].products)[v2].value
              );
            }
            console.log(orderItems, offerItems);
            for (let a = 0; a < orderItems.length; a++) {
              if (offerItems.includes(orderItems[a]) === false) {
                count = count + 1;
              }
            }
            if (count > 0) {
              swal(
                "Warning!",
                "This offer is not applicable on all items!",
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else {
              if (
                this.state.old_total >=
                parseFloat(this.state.promos[v].minimum_order)
              ) {
                let discount = (
                  this.state.old_total *
                  (this.state.promos[v].discount / 100)
                ).toFixed(2);
                if (discount > parseFloat(this.state.promos[v].max_off)) {
                  this.setState({
                    old_total:
                      this.state.old_total -
                      parseFloat(this.state.promos[v].max_off),
                    promoApplied: true,
                    promo_id: this.state.promos[v].promo_id,
                    discount: parseFloat(this.state.promos[v].max_off),
                    promo_data: this.state.promos[v],
                  });
                } else {
                  this.setState({
                    old_total: this.state.old_total - discount,
                    promoApplied: true,
                    promo_id: this.state.promos[v].promo_id,
                    discount: discount,
                    promo_data: this.state.promos[v],
                  });
                }
              } else {
                swal(
                  "Warning!",
                  `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                  "warning"
                );
                this.setState({
                  promoApplied: false,
                  PromoCode: "",
                });
              }
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 2
        ) {
          offerCount = offerCount + 1;
          let count = 0;
          this.state.myCart.forEach((element) => {
            if (element.test_included == 1 && element.subject_included == 1) {
              count = count + 2;
            } else {
              count = count + 1;
            }
          });
          if (this.state.promos[v].lower_limit > count) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("Warning!", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("Warning!", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 3
        ) {
          offerCount = offerCount + 1;
          if (this.state.promos[v].lower_limit > this.state.myCart.length) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("Warning!", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("Warning!", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        } else if (
          this.state.PromoCode.toUpperCase() ===
            this.state.promos[v].promo_code.toUpperCase() &&
          this.state.promos[v].type === 4
        ) {
          offerCount = offerCount + 1;
          var valid_user = 0;

          if (this.state.promos[v].lower_limit > this.state.myCart.length) {
            swal(
              "",
              `To avail this offer you have to purchase minimum ${this.state.promos[v].lower_limit} products`,
              "warning"
            );
            this.setState({
              promoApplied: false,
              PromoCode: "",
            });
          } else {
            for (let v1 = 0; v1 < this.state.all_orders.length; v1++) {
              if (
                this.state.promos[v].promo_id ===
                this.state.all_orders[v1].promo_id
              ) {
                promos.push(this.state.all_orders[v1]);
              }
            }
            if (this.state.promos[v].users === "null") {
              valid_user = valid_user + 1;
              // console.log("helloooo");
            } else {
              for (
                let i = 0;
                i < JSON.parse(this.state.promos[v].users).length;
                i++
              ) {
                if (
                  JSON.parse(this.state.promos[v].users)[i].value ===
                  this.state.userdata.user_id
                ) {
                  valid_user = valid_user + 1;
                }
              }
            }
            if (promos.length >= this.state.promos[v].use_limit) {
              swal("", "You have used this offer!", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (this.state.promos[v].status === 2) {
              swal("", "This offer is no longer available", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (valid_user == 0) {
              swal("", "Offer is not applicable", "warning");
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            } else if (
              this.state.old_total >=
              parseFloat(this.state.promos[v].minimum_order)
            ) {
              let discount = (
                this.state.old_total *
                (this.state.promos[v].discount / 100)
              ).toFixed(2);
              if (discount > parseFloat(this.state.promos[v].max_off)) {
                this.setState({
                  old_total:
                    this.state.old_total -
                    parseFloat(this.state.promos[v].max_off),
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: parseFloat(this.state.promos[v].max_off),
                  promo_data: this.state.promos[v],
                });
              } else {
                this.setState({
                  old_total: this.state.old_total - discount,
                  promoApplied: true,
                  promo_id: this.state.promos[v].promo_id,
                  discount: discount,
                  promo_data: this.state.promos[v],
                });
              }
            } else {
              swal(
                "Warning!",
                `Applicable on cart value of more than Rs.${this.state.promos[v].minimum_order}`,
                "warning"
              );
              this.setState({
                promoApplied: false,
                PromoCode: "",
              });
            }
          }
        }
      }

      if (offerCount === 0) {
        swal("Warning!", "Invalid Promo Code!", "warning");
        this.setState({
          promoApplied: false,
          PromoCode: "",
          old_total: this.state.total_cost,
        });
      }
    }
  };

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
          phone: JSON.parse(userdata).phone,
          loading: true,
        },
        () => {
          this.getCart();
          this.getOrders();
        }
      );
    } else {
      this.props.history.push("/");
    }
  }
  confirmDelete = (item) => {
    swal({
      title: "Payment Successfull !!",
      text: "Thank you for purchasing this course!!",
      icon: "success",
    }).then(() => {
      this.props.history.push("/my-courses");
    });
  };
  activeC(data) {
    let { myCart } = this.state;

    for (var v = 0; v < myCart.length; v++) {
      let sl = myCart[v];
      sl.course_status = "active";
    }
    this.setState(
      {
        myCart,
      },
      () => this.addOrder(data)
    );
  }
  addOrder = async (data) => {
    this.setState({
      loading: true,
    });

    const igst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "igst"
    );
    console.log(igst);
    const cgst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "cgst"
    );
    const sgst = this.state.tax.filter(
      (item) => item.tax_name.toLowerCase() == "sgst"
    );
    let details = {
      user_id: this.state.userdata.user_id,
      name: data.name,
      address: JSON.stringify(data.address),
      phone: data.phone,
      amount: parseFloat(
        this.state.old_total +
          (this.state.old_total * this.state.calculated_tax) / 100
      ).toFixed(2),
      cost_exc_tax: this.state.old_total.toFixed(2),
      // IGST: this.state.state.toLowerCase() == "delhi" ? 0 : igst[0].tax_value,
      // SGST: this.state.state.toLowerCase() == "delhi" ? sgst[0].tax_value : 0,
      // CGST: this.state.state.toLowerCase() == "delhi" ? cgst[0].tax_value : 0,
      IGST: 0,
      SGST: sgst[0].tax_value,
      CGST: cgst[0].tax_value,
      promo_id: data.promo_id,
      payment_id: data.payment_id,
      items: JSON.stringify(this.state.myCart),
    };

    console.log(details);
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/insert_order", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          //this.confirmDelete()
          this.setState(
            {
              new_order_id: responseJson.order_id,
              invoice_modal: true,
            },
            () => document.getElementById("save_button").scrollIntoView()
          );
          // this.setState({
          //   loading:false
          // })
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  toggle_invoice = () => {
    this.setState({
      invoice_modal: !this.state.invoice_modal,
    });
  };
  titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
  }
  getInvoiceNo = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_invoice_no", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({ invoice_number: responseJson.invoice_number });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  renderTaxColumn() {
    // if (this.state.state.toLowerCase() != "delhi") {
    //   return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
    //     if (tax_data.tax_id == 8) {
    //       return (
    //         <>
    //           <tr style={{ fontSize: 14 }}>
    //             <td> {tax_data.tax_name}</td>
    //             <td>{tax_data.tax_value}%</td>

    //             <td style={{ textAlign: "end" }}>
    //               {(Number(this.state.old_total) * tax_data.tax_value) / 100}
    //             </td>
    //           </tr>
    //           {this.renderCess2(tax_data, tax_data_key)}
    //         </>
    //       );
    //     }
    //     if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
    //       return (
    //         <>
    //           <tr style={{ fontSize: 14 }}>
    //             <td> {tax_data.tax_name}</td>
    //             <td>{tax_data.tax_value}%</td>
    //             <td style={{ textAlign: "end" }}>0</td>
    //           </tr>
    //         </>
    //       );
    //     }
    //   });
    // } else {
    return [...this.state.tax].reverse().map((tax_data, tax_data_key) => {
      if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
        return (
          <>
            <tr style={{ fontSize: 14 }}>
              <td>{tax_data.tax_name}</td>
              <td>{tax_data.tax_value}%</td>

              <td style={{ textAlign: "end" }}>
                {(Number(this.state.old_total) * tax_data.tax_value) / 100}
              </td>
            </tr>
            {this.renderCess2(tax_data, tax_data_key)}
          </>
        );
      }
      if (tax_data.tax_id == 8) {
        return (
          <>
            <tr style={{ fontSize: 14 }}>
              <td> {tax_data.tax_name}</td>
              <td>{tax_data.tax_value}%</td>

              <td style={{ textAlign: "end" }}>0</td>
            </tr>
          </>
        );
      }
    });
    // }
  }

  renderCess2(data, index) {
    if (data.cess != null) {
      return JSON.parse(data.cess).map((item, index) => {
        return (
          <tr style={{ fontSize: 14 }}>
            <td></td>
            <td>
              {item.name} @ {item.value}%
            </td>

            <td style={{ textAlign: "end" }}>
              {(
                (((Number(this.state.old_total) * data.tax_value) / 100) *
                  Number(item.value)) /
                100
              ).toFixed(2)}
            </td>
          </tr>
        );
      });
    }
  }
  renderCess(data, index) {
    if (data.cess != null) {
      return JSON.parse(data.cess).map((item, index) => {
        return (
          <div className="rowDefault myCartTotal">
            <text className="tax-font">
              {item.name} @ {item.value}%
            </text>

            <text className="tax-font" style={{ textAlign: "end" }}>
              {(
                (((Number(this.state.old_total) * data.tax_value) / 100) *
                  Number(item.value)) /
                100
              ).toFixed(2)}
            </text>
          </div>
        );
      });
    }
  }
  renderTaxColumn2() {
    if (this.state.myCart.length != 0) {
      if (this.state.tax.length != 0) {
        // if (this.state.state != "Delhi") {
        //   return this.state.tax.map((tax_data, tax_data_key) => {
        //     if (tax_data.tax_id == 8) {
        //       return (
        //         <>
        //           <div className="rowDefault myCartTotal">
        //             <text className="tax-font">
        //               {tax_data.tax_name} @ {tax_data.tax_value}%
        //             </text>

        //             <text className="tax-font" style={{ textAlign: "end" }}>
        //               {(
        //                 (Number(this.state.old_total) * tax_data.tax_value) /
        //                 100
        //               ).toFixed(2)}
        //             </text>
        //           </div>
        //           {this.renderCess(tax_data, tax_data_key)}
        //         </>
        //       );
        //     } else return;
        //   });
        // } else {
        return this.state.tax.map((tax_data, tax_data_key) => {
          if (tax_data.tax_id == 1 || tax_data.tax_id == 2) {
            console.log(
              `Rs.${(Number(this.state.old_total) * tax_data.tax_value) / 100}`
            );
            return (
              <>
                <div className="rowDefault myCartTotal">
                  <text>
                    {tax_data.tax_name} @ {tax_data.tax_value}%
                  </text>

                  <text style={{ textAlign: "end" }}>
                    {(
                      (Number(this.state.old_total) * tax_data.tax_value) /
                      100
                    ).toFixed(2)}
                  </text>
                </div>
                {this.renderCess(tax_data, tax_data_key)}
              </>
            );
          } else return;
        });
        // }
      }
    }
  }
  renderTestCostRow(data, key) {
    if (data.test_included == 1) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td>{data.subject_included ? key + 2 : key + 1}</td>
          <td>{data.subject_name} Test</td>

          <td style={{ textAlign: "end" }}>
            {Number(JSON.parse(data.test_id)[0].cost)}
          </td>
        </tr>
      );
    }
  }
  renderDiscountRow() {
    if (this.state.promoApplied) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td></td>
          <td>Discount </td>

          <td style={{ textAlign: "end" }}>- {this.state.discount}</td>
        </tr>
      );
    }
  }
  renderSujectCostRow(data, key) {
    if (data.subject_included == 1) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td>{key + 1}</td>
          <td>{data.subject_name} </td>

          <td style={{ textAlign: "end" }}>{Number(data.cost)}</td>
        </tr>
      );
    }
  }

  renderExamCostRow(data, key) {
    return (
      <tr style={{ fontSize: 14 }}>
        <td>{key + 1}</td>
        <td>{data.exam_name} </td>

        <td style={{ textAlign: "end" }}>{Number(data.cost)}</td>
      </tr>
    );
  }
  printDiv() {
    var printContents = document.getElementById("invoice").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  }

  renderCostWithoutTax() {
    return (
      <>
        <tr>
          <td></td>
          <td>
            <strong>Taxable value</strong>
          </td>
          <td style={{ textAlign: "right" }}>{Number(this.state.old_total)}</td>
        </tr>
      </>
    );
  }

  invoiceData() {
    let toWords = require("to-words");
    console.log(
      parseFloat(
        this.state.old_total +
          (this.state.old_total * this.state.calculated_tax) / 100
      ).toFixed(2)
    );
    if (this.state.invoice_modal) {
      let { orderData } = this.state;

      var fileData = {
        order_id: this.state.new_order_id,
        invoice_number: this.state.invoice_number,
        email: this.state.userdata.email,
        donwload: this.state.download_invoice,
      };
      return (
        <React.Fragment>
          <div className="invoice_container" id="save_button">
            <div className="cart-invoice-modal">
              <MDBModalBody style={{ padding: 10, opacity: 1 }}>
                <div className="save_pdf_button_container">
                  <MDBIcon
                    style={{
                      color: "#000",
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({ download_invoice: 1 }, this.exportPDF)
                    }
                    icon="save"
                    data-tip="Download Invoice"
                    size="lg"
                  />
                  <ReactTooltip place="bottom" />
                  <ReactToPrint
                    trigger={() => (
                      <MDBIcon
                        style={{
                          color: "#000",
                          marginRight: 10,
                          cursor: "pointer",
                        }}
                        pageStyle="@page { size: auto; margin: 100mm; } }"
                        icon="print"
                        data-tip="Print Invoice"
                        size="lg"
                      />
                    )}
                    content={() => this.resume}
                  />
                  <MDBIcon
                    style={{
                      color: "#000",
                      marginRight: 10,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({ download_invoice: 0 }, this.exportPDF)
                    }
                    icon="times"
                    data-tip="Close"
                    size="lg"
                  />
                </div>
                <PDFExport
                  paperSize={"A4"}
                  scale={0.8}
                  // JSON.stringify(fileData)
                  fileName={JSON.stringify(fileData)}
                  title=""
                  subject=""
                  proxyTarget={this.state.download_invoice == 1 ? "_blank" : ""}
                  keywords=""
                  forceProxy={true}
                  crossorigin="anonymous"
                  proxyURL="https://www.vimasmiham.com/add_order_invoice"
                  ref={(r) => (this.resume = r)}
                >
                  <div id="invoice" style={{ margin: 10, marginTop: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="logoDiv" stylle={{ width: "25%" }}>
                        <img
                          className="logo"
                          style={{ width: 100, marginTop: "80px" }}
                          src={Logo}
                          crossorigin="anonymous"
                        />
                        <div style={{ marginTop: "20px", fontSize: "14px" }}>
                          Name&nbsp;:&nbsp;
                          <text style={{ color: "black" }}>
                            <strong>{this.state.userdata.name}</strong>
                          </text>
                          <br />
                          Address&nbsp;:&nbsp;
                          <strong>
                            <text>
                              {this.state.address_edit_text == null ||
                              this.state.address_edit_text == "null"
                                ? ""
                                : this.state.address_edit_text}
                            </text>
                            ,
                            <text className="">
                              {this.state.state == null ||
                              this.state.state == "null"
                                ? ""
                                : this.state.state}
                              {","}
                              {this.state.pincode == null ||
                              this.state.pincode == "null"
                                ? ""
                                : this.state.pincode}
                            </text>
                          </strong>
                          <br />
                          Phone&nbsp;: &nbsp;
                          <text style={{ color: "black" }}>
                            <strong>{this.state.phone}</strong>
                          </text>
                          <br />
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          Email&nbsp;:&nbsp;
                          <text style={{ color: "black" }}>
                            <strong>{this.state.userdata.email}</strong>
                          </text>
                          <br />
                        </div>
                        <div style={{ marginTop: "20px", fontSize: "14px" }}>
                          GSTIN : <strong>NA</strong>
                          <br />
                          Place of Supply :{" "}
                          <strong>
                            {/* {this.state.state} */}
                            DELHI
                          </strong>
                          <br />
                          Type of Supply :{" "}
                          <strong>
                            {/* {this.state.state.toLowerCase() == "delhi"
                              ? "Intra-State Supply"
                              : "Inter-State Supply"} */}
                            Intra-State Supply
                          </strong>
                          <br />
                          State Code :{" "}
                          <strong>
                            07
                            {/* {StateCodes[this.state.state]} */}
                          </strong>
                          <br />
                          Reverse Charge Applicable (Yes or No) :{" "}
                          <strong>No</strong>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "75%",
                          padingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <text
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        ></text>
                        <div
                          style={{ padding: 5, marginLeft: 10, fontSize: 14 }}
                        >
                          <strong>
                            <span style={{ color: "#0099CC" }}>
                              <strong>VIMA SMIHAM</strong>
                            </span>
                            <br />
                            Sarswati Block-6 Pocket-6
                            <br />
                            Flat No. 202, Sector D, Vasant Kunj
                            <br />
                            New Delhi, South Delhi, Delhi, 110070
                            <br />
                            Tel&nbsp;: 0121-2233440
                            <br />
                          </strong>
                          <br />
                          GSTIN&nbsp;:&nbsp;<strong>07AAAPP7540E1ZV</strong>
                          <br />
                          Description &nbsp;:&nbsp;
                          <strong>
                            Other Educational Support Services
                          </strong>
                          <br />
                          SAC Code&nbsp;:&nbsp;<strong>999299</strong>
                          <br />
                          <hr
                            style={{
                              backgroundColor: "#0099CC",
                              padding: "1px",
                              margin: "20px 0px",
                            }}
                          />
                        </div>

                        <div
                        // style={{
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "flex-start"
                        // }}
                        ></div>

                        <div
                          style={{
                            padingLeft: 10,
                            paddingRight: 10,
                          }}
                        >
                          <div>
                            Date:{" "}
                            <text style={{ color: "black", fontSize: "14px" }}>
                              <strong>
                                {this.state.date.getDate()}-
                                {this.state.date.getMonth() + 1}-
                                {this.state.date.getFullYear()}
                              </strong>
                            </text>
                            <br />
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            Invoice No.:{" "}
                            <text style={{ color: "black" }}>
                              <strong>{this.state.invoice_number}</strong>
                            </text>
                            <br />
                          </div>
                        </div>
                        <div
                        // style={{
                        //   display: "flex",
                        //   flexDirection: "row",
                        //   alignItems: "center",
                        //   justifyContent: "space-between",
                        //   padingLeft: 10,
                        //   paddingRight: 10,
                        //   marginBottom: 10
                        // }}
                        >
                          <p style={{ textAlign: "center", marginTop: "20px" }}>
                            <strong>TAX INVOICE</strong>
                          </p>

                          <MDBTable
                            className="table table-sm dataTable"
                            style={{ borderCollapse: "seperate" }}
                          >
                            <MDBTableHead color="">
                              <tr style={{ color: "", fontSize: 14 }}>
                                <th
                                  style={{ width: "25%", borderBottom: "0px" }}
                                >
                                  <strong>S.NO</strong>
                                </th>
                                <th
                                  style={{ width: "50%", borderBottom: "0px" }}
                                >
                                  <strong>Particulars</strong>
                                </th>
                                <th
                                  style={{ width: "20%", borderBottom: "0px" }}
                                >
                                  <strong>Amount(INR)</strong>
                                </th>
                              </tr>
                            </MDBTableHead>
                            <MDBTableBody>
                              {this.state.myCart.map((data, key) => {
                                if (data.exam_included == false) {
                                  return (
                                    <>
                                      {this.renderSujectCostRow(data, key)}
                                      {this.renderTestCostRow(data, key)}
                                    </>
                                  );
                                } else {
                                  return (
                                    <>{this.renderExamCostRow(data, key)}</>
                                  );
                                }
                              })}
                              {this.renderDiscountRow()}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              {this.renderCostWithoutTax()}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr style={{ fontSize: "14px" }}>
                                <td>
                                  <strong>Taxes Applicable</strong>
                                </td>
                                <td>
                                  <strong>Rate</strong>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <strong>GST</strong>
                                </td>
                              </tr>
                              {this.renderTaxColumn()}
                              <tr style={{ fontSize: "14px" }}>
                                <td>
                                  <strong>Total</strong>
                                </td>
                                <td></td>
                                <td style={{ textAlign: "end" }}>
                                  Rs.{" "}
                                  {parseFloat(
                                    this.state.old_total +
                                      (this.state.old_total *
                                        this.state.calculated_tax) /
                                        100
                                  ).toFixed(2)}
                                </td>
                              </tr>
                              {/* <tr style={{fontSize:15}}>

<td style={{fontWeight:'bold'}}>Grand Total</td>
<td ></td>
<td style={{textAlign:'end'}}>Rs. {this.state.total_cost}</td>
</tr> */}
                              <tr>
                                <td colSpan="3">
                                  In Words:{" "}
                                  <strong>
                                    {toWords(
                                      Number(
                                        parseFloat(
                                          this.state.old_total +
                                            (this.state.old_total *
                                              this.state.calculated_tax) /
                                              100
                                        ).toFixed(2)
                                      )
                                    )}{" "}
                                    Only
                                  </strong>
                                </td>
                              </tr>
                            </MDBTableBody>
                          </MDBTable>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        className="sign"
                        src={require("../../Assets/images/sign.svg")}
                      />
                      <text
                        style={{
                          fontSize: 14,
                          textAlign: "end",
                          paddingRight: 5,
                        }}
                      >
                        Authorised Signatory
                      </text>
                    </div>
                  </div>
                </PDFExport>
              </MDBModalBody>
              <MDBModalFooter>
                <button
                  onClick={() =>
                    this.setState({ download_invoice: 0 }, this.exportPDF)
                  }
                  className="view-details-button btn-custom"
                  type="button"
                >
                  Close
                </button>
              </MDBModalFooter>
            </div>
            <div className="d-block d-md-none  mobile-save-reciept-button">
              <button
                onClick={() =>
                  this.setState({ download_invoice: 0 }, this.exportPDF)
                }
              >
                Go to My Courses
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  exportPDF = () => {
    this.resume.save();
  };

  payment() {
    if (this.state.state == null || this.state.state.length <= 0) {
      swal("Select state", "", "info");
    } else if (this.state.phone == null || this.state.phone.length == 0) {
      swal("Enter phone", "", "info");
    } else if (
      this.state.address_edit_text == null ||
      this.state.address_edit_text.length == 0
    ) {
      swal("Enter address", "", "info");
    } else {
      if (this.state.myCart.length != 0) {
        var orderData = {
          name: this.state.userdata.name,
          phone: this.state.userdata.phone,
          items: this.state.myCart,
          address: this.state.address,
          price: this.state.old_total,
          promo_id:
            this.state.promo_data == null ? 0 : this.state.promo_data.promo_id,
        };

        console.log(orderData);

        // rzp_test_J4VnbqSjsxdVlo
        // xwCFj6HVrWErsNuleHMdckJ6

        // rzp_live_SBiIxnIIZdySNZ
        //ilsOUhGsqczx9dl3tztm7oHN

        let options = {
          currency: "INR",
          key: "rzp_live_SBiIxnIIZdySNZ",
          amount: Math.floor(
            (
              (orderData.price +
                (Number(
                  this.state.promoApplied
                    ? this.state.total_cost - this.state.discount
                    : this.state.old_total
                ) *
                  18) /
                  100) *
              100
            ).toFixed(2)
          ), // 2000 paise = INR 20, amount in paisa
          name: "Vima Smiham ",
          description: "Pay to Vima Smiham",

          handler: (response) => {
            orderData.payment_id = response.razorpay_payment_id;
            this.setState({
              orderData: orderData,
            });
            this.activeC(orderData);
          },
          prefill: {
            email: this.state.userdata.email,
            contact: this.state.phone,
            name: this.state.userdata.name,
          },

          theme: {
            color: "#e7a331",
          },
        };

        console.log(options);

        let rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        swal("Cart is empty", "", "error");
      }
    }

    // console.log(this.state)
  }

  trigger() {
    this.getCart();
    console.log(this.state.promoApplied);
  }
  removeCart = async (data) => {
    this.setState({
      loading: true,
    });
    let details = {
      user_id: this.state.userdata.user_id,
      items: JSON.stringify([{ subject_id: data.subject_id }]),
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/remove_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({}, () => this.trigger());
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  toggle_promo = () => {
    this.setState({
      promo_modal: !this.state.promo_modal,
    });
  };
  renderModal() {
    if (this.state.promo_modal) {
      return (
        <MDBModal
          isOpen={this.state.promo_modal}
          toggle={this.toggle_promo}
          fullHeight
          position="right"
          className="promo-modal-container"
        >
          <MDBModalHeader toggle={this.toggle_promo}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              Promo Codes
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div className="promo-container">
              {this.state.promos.map((item) => {
                return (
                  <div className="promo-item" key={item.promo_id}>
                    <p className="promo-name">
                      <span>
                        <span>
                          <img src={item.image} className="promo-image" />
                        </span>
                        <span>{item.promo_code}</span>
                      </span>
                    </p>
                    <p className="promo-heading"> {item.heading} </p>
                    <p className="promo-description">{item.description}</p>
                    <button
                      className="apply-promo-button btn"
                      onClick={() =>
                        this.setState({ PromoCode: item.promo_code }, () => {
                          (() => this.handleApply())();
                          (() => this.setState({ promo_modal: false }))();
                        })
                      }
                    >
                      Apply Promo
                    </button>
                  </div>
                );
              })}
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }
  renderCost(modal) {
    console.log(modal, "aaa");
    if (modal.exam_included == false) {
      if (modal.old_cost == 0) {
        return (
          <div className="myCoursesCostAfterSingle mt-n1">
            <text
              style={{ display: modal.subject_included == 0 ? "none" : "flex" }}
              className="myCoursesFinalCost"
            >
              Rs. {Number(modal.cost).toFixed(2)}{" "}
            </text>
            {this.renderTestCost(modal)}
          </div>
        );
      } else {
        return (
          <div
            className="colDefault align-items-center mt-2 colDefault-mycart-mobile "
            style={{ flex: 2 }}
          >
            <div
              style={{ display: modal.subject_included == 0 ? "none" : "flex" }}
              className="rowDefault rowDefault-mycart-mobile align-items-center w-100"
            >
              <div className="myCoursesCostBefore" style={{ flex: 0.5 }}>
                <strike>
                  <text className="myCoursesCost">
                    Rs. {modal.old_cost.toFixed(2)}{" "}
                  </text>
                </strike>
              </div>
              <div className="myCoursesYouSaved" style={{ flex: 1 }}>
                <text className="myCoursesCost">
                  You Saved Rs. {(modal.old_cost - modal.cost).toFixed(2)}
                </text>
              </div>
              <div className="myCoursesCostAfter" style={{ flex: 0.5 }}>
                <text className="myCoursesFinalCost">
                  Rs. {Number(modal.cost).toFixed(2)}{" "}
                </text>
              </div>
            </div>

            {this.renderTestCost(modal)}
          </div>
        );
      }
    } else {
      return (
        <div className="myCoursesCostAfterSingle ml-auto">
          <text style={{ display: "flex" }} className="myCoursesFinalCost">
            Rs. {modal.cost.toFixed(2)}{" "}
          </text>
          {/* {this.renderTestCost(modal)} */}
        </div>
      );
    }
  }
  renderTestCost(modal) {
    if (modal.test_included == 1) {
      console.log(modal);
      return (
        <div className="test_cost_cart myCoursesFinalCost">
          Rs. {JSON.parse(modal.test_id)[0].cost.toFixed(2)}
        </div>
      );
    }
  }
  renderMyCart() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      const { expanded } = this.state;

      if (this.state.myCart.length == 0) {
        return (
          <div className="styles--sc-shopping-list--empty--1isYv">
            <MDBIcon
              style={{ color: "#e8e9eb", marginBottom: 20 }}
              size="5x"
              icon="shopping-cart"
            />
            <p>Your cart is empty. Keep exploring to find a course!</p>
            <a
              className="btn "
              onClick={() => this.props.history.push("/")}
              data-purpose="keep-shopping-action"
              style={{ backgroundColor: "#0093d9", color: "white" }}
            >
              Keep Learning
            </a>
          </div>
        );
      } else {
        return this.state.myCart.map((modal) => {
          console.log(modal, "modal");

          if (modal.exam_included == false) {
            return (
              <>
                <div
                  className="myCourses"
                  style={{
                    height:
                      modal.test_included == 1 && modal.subject_included == 1
                        ? "fit-content"
                        : 70,
                  }}
                >
                  <div className="myCoursesImg">
                    {" "}
                    <img
                      src={modal.image}
                      alt="vima-logo"
                      width="100%"
                      height={modal.test_included == 1 ? "fit-content" : "100%"}
                    />{" "}
                  </div>
                  <div className="myCoursesNameAndDel">
                    <div className="text-center text-md-left">
                      <text className="myCoursesTitle">
                        {modal.subject_name}{" "}
                      </text>

                      {modal.subject_included == 0 && modal.test_included == 1
                        ? `MCQ`
                        : ""}
                      {modal.subject_included == 1 &&
                      modal.test_included == 1 ? (
                        <>
                          <br /> {modal.subject_name} MCQ
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="myCoursesRemove d-none d-sm-block">
                      <a
                        className="myCoursesRemove"
                        onClick={() => this.removeCart(modal)}
                      >
                        {" "}
                        <MDBIcon icon="trash-alt" /> Remove{" "}
                      </a>
                    </div>
                  </div>
                  {this.renderCost(modal)}
                  <div className="myCoursesRemove d-block d-sm-none ml-auto">
                    <a
                      className="myCoursesRemove"
                      onClick={() => this.removeCart(modal)}
                    >
                      {" "}
                      <MDBIcon icon="trash-alt" /> Remove{" "}
                    </a>
                  </div>
                  <div></div>
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  className="myCourses"
                  style={{ height: "fit-content", minHeight: "100px" }}
                >
                  <div className="myCoursesImg">
                    {" "}
                    <img
                      src={modal.image}
                      alt="vima-logo"
                      style={{ display: "none" }}
                      width="100%"
                      height="100%"
                    />{" "}
                  </div>
                  <div className="myCoursesNameAndDel">
                    <div>
                      <text className="myCoursesTitle">{modal.exam_name} </text>

                      {/*                     
                    {modal.subject_included==0 && modal.test_included==1?`Test`:''}
                    {modal.subject_included==1 && modal.test_included==1?<><br /> {modal.subject_name} Test</>:''} */}
                    </div>
                    <div className="myCoursesRemove d-none d-sm-block ">
                      <a
                        className="myCoursesRemove"
                        onClick={() => this.removeCart(modal)}
                      >
                        {" "}
                        <MDBIcon icon="trash-alt" /> Remove{" "}
                      </a>
                    </div>
                  </div>
                  {this.renderCost(modal)}
                  <div className="myCoursesRemove d-block d-sm-none ml-auto">
                    <a
                      className="myCoursesRemove"
                      onClick={() => this.removeCart(modal)}
                    >
                      {" "}
                      <MDBIcon icon="trash-alt" /> Remove{" "}
                    </a>
                  </div>
                </div>
              </>
            );
          }
        });
      }
    }
  }

  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => this.getSubjects()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getTax = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_tax_user", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "get_tax_user");
          this.setState({
            tax: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getOrders = async () => {
    //   this.setState({
    //     loading:true
    // })
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            all_orders: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getSubjects = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              all_subjects: responseJson,
            },
            () => this.getExaminations()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getExaminations() {
    fetch("https://www.vimasmiham.com/get_examinations", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        this.setState({ examinations: responseJson }, () =>
          this.getCartSubjects()
        );
      });
  }

  getAddress = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_address", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (this.state.userdata.phone == null || responseJson.state == null) {
            this.setState({ edit_address: true });
          }
          this.setState(
            {
              address: responseJson,
              address_edit_text: responseJson.address,
              state: responseJson.state,
              pincode: responseJson.pincode,
              address_loading: false,
            },
            () => this.selectRegion(responseJson.state)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  editAddress = () => {
    if (
      this.state.address_edit_text == null ||
      this.state.address_edit_text == ""
    ) {
      swal("Enter address", "", "info");
    } else if (this.state.state == null || this.state.state == "") {
      swal("Choose state", "", "info");
    } else if (this.state.pincode == null || this.state.pincode == "") {
      swal("Enter pincode", "", "info");
    } else if (this.state.phone == null || this.state.phone == "") {
      swal("Choose state", "", "info");
    } else {
      this.setState({
        address_loading: true,
      });
      let details = {
        address_id: this.state.address.address_id,
        address: this.state.address_edit_text,
        state: this.state.state,
        pincode: this.state.pincode,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_address", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.getAddress();
            this.editPhone();
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };

  editPhone = () => {
    console.log(this.state.phone.length);
    if (this.state.phone.length != 10) {
      swal("Enter valid phone phone", "", "info");
      this.setState({
        address_loading: false,
        edit_address: true,
      });
    } else {
      this.setState({
        address_loading: true,
      });
      let details = {
        phone: this.state.phone,
        user_id: this.state.userdata.user_id,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_user_details", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            this.setState({
              loading: false,
              address_loading: false,
              edit_address: false,
            });
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });

            console.log(error);
          }),
      });
    }
  };

  getCartSubjects() {
    let { myCart, all_subjects, cart, examinations } = this.state;

    let cart_array = [],
      total = 0;
    var total_test_cost = 0;
    var total_subject_cost = 0;
    console.log(cart);
    var total_exam_cost = 0;
    if (JSON.parse(cart[0].items).length > 0) {
      for (var v = 0; v < all_subjects.length; v++) {
        for (var v1 = 0; v1 < JSON.parse(cart[0].items).length; v1++) {
          if (
            JSON.parse(cart[0].items)[v1].subject_id ==
            all_subjects[v].subject_id
          ) {
            let data3 = all_subjects[v];

            data3.test_included = JSON.parse(cart[0].items)[v1].test_included;
            data3.subject_included = JSON.parse(cart[0].items)[
              v1
            ].subject_included;
            data3.exam_included = false;
            let details = {
              subject_id: data3.subject_id,
            };
            console.log(details, "details");
            let formBody = [];
            for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
            }
            formBody = formBody.join("&");
            fetch("https://www.vimasmiham.com/get_test", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Cache-Control": "max-age=31536000",
              },
              body: formBody,
            })
              .then((response) => response.json())
              .then((responseJson) => {
                console.log(responseJson);
                data3.test_id = JSON.stringify(responseJson);
                cart_array.push(data3);
                if (data3.subject_included != 0) {
                  console.log(data3.cost);
                  total_subject_cost = total_subject_cost + Number(data3.cost);
                  console.log(total_subject_cost);
                }
                console.log(total_subject_cost);

                if (data3.test_included != 0) {
                  console.log(this.state.selected_subject);

                  console.log(
                    Number(JSON.parse(data3.test_id)[0].cost),
                    "test_iiii"
                  );
                  total_test_cost =
                    total_test_cost + Number(JSON.parse(data3.test_id)[0].cost);
                  console.log(total_test_cost);
                }

                if (v == all_subjects.length) {
                  // console.log('v = subjectslengthh')
                  for (var i = 0; i < examinations.length; i++) {
                    for (var j = 0; j < JSON.parse(cart[0].items).length; j++) {
                      if (JSON.parse(cart[0].items)[j].exam_id !== undefined) {
                        if (
                          JSON.parse(cart[0].items)[j].exam_id ===
                          examinations[i].exam_id
                        ) {
                          let exam = examinations[i];
                          exam.exam_included = true;
                          cart_array.push(exam);
                          total_exam_cost = total_exam_cost + Number(exam.cost);
                        }
                      }
                    }
                  }
                  console.log(total_subject_cost);
                  console.log(total_test_cost);

                  total =
                    total_subject_cost + total_test_cost + total_exam_cost;
                  console.log(total);

                  // console.log('reached end of cart state')
                  this.setState(
                    {
                      myCart: cart_array,
                      old_total: total,
                      total_cost: total,
                      loading: false,
                    },
                    () => {
                      console.log(this.state);
                      this.getAddress();
                      if (this.state.promoApplied == true) {
                        this.handleApply();
                      }
                    }
                  );
                }
              });
          }
        }
      }
    } else {
      this.setState(
        {
          myCart: cart_array,
          old_total: total,
          total_cost: total,
          loading: false,
        },
        () => {
          console.log(this.state);
          this.getAddress();
          if (this.state.promoApplied == true) {
            this.handleApply();
          }
        }
      );
    }
  }
  selectCountry(val) {
    console.log(val);
    this.setState({ country: val });
  }

  selectRegion(val) {
    console.log(val);
    console.log(StateCodes[val]);
    if (val != null) {
      console.log(val);
      var calculated_cess = 0,
        calculated_tax = 0,
        total_tax = 0;
      this.setState({ state: val });
      for (var v = 0; v < this.state.tax.length; v++) {
        if (val != "Delhi") {
          if (this.state.tax[v].tax_id == 8) {
            if (this.state.tax[v].cess != null) {
              for (
                var v1 = 0;
                v1 < JSON.parse(this.state.tax[v].cess).length;
                v1++
              ) {
                calculated_cess =
                  calculated_cess +
                  Number(JSON.parse(this.state.tax[v].cess)[v1].value);
              }
            }
            calculated_tax =
              calculated_tax + Number(this.state.tax[v].tax_value);
          }
        } else {
          if (this.state.tax[v].tax_id == 1 || this.state.tax[v].tax_id == 2) {
            if (this.state.tax[v].cess != null) {
              for (
                var v1 = 0;
                v1 < JSON.parse(this.state.tax[v].cess).length;
                v1++
              ) {
                calculated_cess =
                  calculated_cess +
                  Number(JSON.parse(this.state.tax[v].cess)[v1].value);
              }
            }
            calculated_tax =
              calculated_tax + Number(this.state.tax[v].tax_value);
          }
        }
      }

      if (val == "Delhi") {
        total_tax =
          calculated_tax + (calculated_tax * (calculated_cess / 2)) / 100;
      } else {
        total_tax = calculated_tax + (calculated_tax * calculated_cess) / 100;
      }
      this.setState({
        calculated_cess: calculated_cess,
        calculated_tax: total_tax,
      });
    }
  }
  renderAddress() {
    const { country, region } = this.state;

    if (this.state.myCart.length != 0) {
      if (this.state.address == null) {
        return <div></div>;
      } else {
        if (this.state.address_loading) {
          return (
            <div
              className="myCartAddress"
              style={{ alignItems: "center", justifyContent: "space-between" }}
            >
              <div className="myCartAddressH">
                <text className="myCartAddressH1">Billing Address:</text>
              </div>
              <HollowDotsSpinner color="#2fb2eb" />
              <div className="myCartAddressButton">
                {/* <button onClick={()=>this.setState({edit_address:false},()=>this.editAddress())} className='myCartButtonEdit' type="button" >Save</button> */}
                {/* <button className='myCartButtonNew' type="button"  >New</button> */}
              </div>
            </div>
          );
        } else {
          if (this.state.edit_address) {
            return (
              <div className="myCartAddress">
                <div className="myCartAddressH">
                  <text className="myCartAddressH1">Billing Address:</text>
                </div>
                <div className="colDefault edit_address_div">
                  <input
                    autofocus
                    value={
                      this.state.address_edit_text == null ||
                      this.state.address_edit_text == "null"
                        ? ""
                        : this.state.address_edit_text
                    }
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control"
                    style={{ marginRight: 15 }}
                    onChange={(text) =>
                      this.setState({ address_edit_text: text.target.value })
                    }
                  />
                  {/* <CountryDropdown
          value={country}
          onChange={(val) => this.selectCountry(val)} /> */}

                  <div
                    className="rowDefault "
                    style={{ justifyContent: "space-between" }}
                  >
                    <RegionDropdown
                      country="India"
                      className="region_select"
                      value={this.state.state}
                      onChange={(val) => this.selectRegion(val)}
                    />
                    <input
                      autofocus
                      value={
                        this.state.pincode == null ||
                        this.state.pincode == "null"
                          ? ""
                          : this.state.pincode
                      }
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control region_select"
                      placeholder="Enter pincode"
                      onChange={(text) =>
                        this.setState({ pincode: text.target.value })
                      }
                    />
                  </div>
                  <input
                    autofocus
                    value={
                      this.state.phone == null || this.state.phone == "null"
                        ? ""
                        : this.state.phone
                    }
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control region_select"
                    placeholder="Enter 10 digit phone number"
                    onChange={(text) =>
                      this.setState({ phone: text.target.value })
                    }
                  />
                </div>
                <div className="myCartAddressButton">
                  <button
                    onClick={() =>
                      this.setState({ edit_address: false }, () =>
                        this.editAddress()
                      )
                    }
                    className="myCartButtonEdit"
                    type="button"
                  >
                    Save
                  </button>
                  {/* <button className='myCartButtonNew' type="button"  >New</button> */}
                </div>
              </div>
            );
          } else {
            return (
              <div className="myCartAddress">
                <div className="myCartAddressH">
                  <text className="myCartAddressH1">Billing Address:</text>
                </div>
                <div className="myCartAddressN">
                  <text
                    style={{
                      display:
                        this.state.address.address == "Edit Address"
                          ? "none"
                          : "block",
                    }}
                    className="myCartAddressN1"
                  >
                    {this.state.userdata.name}{" "}
                  </text>
                  <text className="myCartAddressN2">
                    {this.state.address_edit_text == null ||
                    this.state.address_edit_text == "null"
                      ? ""
                      : this.state.address_edit_text}
                  </text>
                  <br />

                  <text className="myCartAddressN2">
                    {this.state.state == null || this.state.state == "null"
                      ? ""
                      : this.state.state}{" "}
                    {this.state.pincode == null || this.state.pincode == "null"
                      ? ""
                      : this.state.pincode}
                  </text>
                  <br />
                  <text className="myCartAddressN2">
                    {this.state.phone == null ? "" : this.state.phone}
                  </text>
                </div>
                <div className="myCartAddressButton">
                  <button
                    onClick={() =>
                      this.setState({
                        address_edit_text: this.state.address.address,
                        edit_address: true,
                      })
                    }
                    className="myCartButtonEdit"
                    type="button"
                  >
                    Edit
                  </button>
                  {/* <button className='myCartButtonNew' type="button"  >New</button> */}
                </div>
              </div>
            );
          }
        }
      }
    }
  }
  toggleEdit = () => {
    this.setState({
      edit_address: !this.state.edit_address,
    });
  };
  renderSave() {
    console.log(this.state.discount);
    if (this.state.promoApplied) {
      return (
        <p style={{ marginBottom: 0, fontSize: 12, color: "#6A6A6A" }}>
          You Saved Rs.{" "}
          {(
            Number(this.state.discount) +
            (Number(this.state.discount) * this.state.calculated_tax) / 100
          ).toFixed(2)}
        </p>
      );
    }
  }
  renderTotal() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="myCartTotal">
          <text className="myCartTotalName">Grand Total :</text>

          <div className="myCartTotalName">
            Rs.{" "}
            {(
              this.state.old_total +
              (this.state.old_total * this.state.calculated_tax) / 100
            ).toFixed(2)}
            {this.renderSave()}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderDiscountedCost() {
    if (this.state.promoApplied) {
      return (
        <text style={{ color: "black", marginLeft: 20 }}>
          Rs. {this.state.old_total.toFixed(2)}
        </text>
      );
    }
  }
  renderOldCostWitoutTax() {
    if (this.state.promoApplied) {
      return <del>Rs. {this.state.total_cost.toFixed(2)}</del>;
    } else {
      return <>Rs. {this.state.total_cost.toFixed(2)}</>;
    }
  }
  renderTotalWithoutTax() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="myCartTotal">
          <text className="myCartTotalName2">Total :</text>

          <div className="myCartTotalName2">
            {this.renderOldCostWitoutTax()}
            {this.renderDiscountedCost()}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderPromoContent() {
    if (this.state.promoApplied) {
      return (
        <div className="myCartPromoCodeInputMain">
          <div className="myCartPromoCodeInputtypeDiv" style={{ width: "6%" }}>
            Applied:{" "}
          </div>

          <div className="myCartPromoCodeApply">
            <text style={{ color: "#dbaa34", textDecoration: "underline" }}>
              {this.state.PromoCode}
            </text>
            &nbsp;&nbsp;
            <text
              className="removePromoText"
              onClick={() =>
                this.setState({
                  PromoCode: "",
                  promoApplied: false,
                  old_total: this.state.total_cost,
                })
              }
            >
              Remove
            </text>
          </div>
        </div>
      );
    } else {
      return (
        <div className="myCartPromoCodeInputMain">
          <div className="myCartPromoCodeInputtypeDiv">
            <input
              placeholder="Enter Promo Code"
              style={{
                width: "100%",
                borderRadius: "5px",
                textAlign: "left",
                border: ".5px solid #E5E5E5",
                backgroundColor: "#fff",
                paddingLeft: 10,
              }}
              className="myCartPromoCodeInput"
              type="text"
              value={this.state.PromoCode}
              onChange={(text) =>
                this.setState({ PromoCode: text.target.value })
              }
            />{" "}
          </div>

          <div className="myCartPromoCodeApply">
            <a>
              <text onClick={() => this.handleApply()}>Apply</text>
            </a>
          </div>
        </div>
      );
    }
  }
  renderPromoCode() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="windowView myCartPromoCode">
          <div
            className="rowDefault align-items-center rowDefault-mobile-width "
            style={{ justifyContent: "space-between" }}
          >
            <text className="myCartItem">Promo Code: </text>
            <text
              onClick={() => this.setState({ promo_modal: true })}
              className="viewPromoText"
              style={{ display: this.state.promoApplied ? "none" : "block" }}
            >
              View Promo Codes{" "}
            </text>
          </div>
          {this.renderPromoContent()}
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderPromoCodeMobile() {
    if (this.state.myCart.length != 0) {
      return (
        <div className="vsMobView myCartPromoCode">
          <div
            className="rowDefault align-items-center rowDefault-mobile-width "
            style={{ justifyContent: "space-between", flexDirection: "column" }}
          >
            <text className="myCartItem">Promo Code: </text>
            {this.renderPromoContent()}
            <text
              onClick={() => this.setState({ promo_modal: true })}
              className="viewPromoText"
              style={{
                display: this.state.promoApplied ? "none" : "block",
                marginTop: 5,
              }}
            >
              View Promo Codes{" "}
            </text>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
  renderButton() {
    if (this.state.myCart.length == 0) {
      return <div></div>;
    } else {
      return (
        <div className="">
          <button
            onClick={() => this.payment()}
            className="view-details-button btn-custom"
            type="button"
          >
            Continue
          </button>
        </div>
      );
    }
  }

  showAlert = (value) => {
    alert(value);
  };

  renderFade() {
    if (this.state.invoice_modal == false) {
      return (
        <Fade>
          <div>
            <h1 className="myCartMainHeading">My Cart</h1>
            <p className="myCartandLearn">Start learning With Vima Smiham</p>
            {this.renderMyCart()}
            {/* <hr style={{display:this.state.myCart.length==0?'none':'block'}} className='myCartHR'></hr> */}
            <div>
              {this.renderPromoCode()}
              {this.renderPromoCodeMobile()}
              {this.renderTotalWithoutTax()}
              <hr
                style={{
                  display: this.state.myCart.length == 0 ? "none" : "block",
                }}
                className="myCartHR"
              ></hr>
              {this.renderTaxColumn2()}
              {this.renderTotal()}

              {this.renderAddress()}
            </div>
          </div>
        </Fade>
      );
    }
  }
  render() {
    console.log(this.state);
    return (
      <div id="my-cart" className="bg-whitesmoke">
        <NewHeader
          cart={
            this.state.cart.length == 0
              ? 0
              : JSON.parse(this.state.cart[0].items).length
          }
          {...this.props}
          active="My Cart"
        />

        <div className="container-c">
          <div className="row account-components-border">
            <div className="col-12 col-md-3 account-nav-ipad px-0">
              <AccountNav {...this.props} active="My Cart" />
            </div>

            <div className="myCartDetail col-12 col-lg-9 margin-top-mobile">
              {this.renderFade()}
              <MetaTags>
                <title>{this.state.page_title}</title>

                <meta
                  id="cart"
                  name="description"
                  content={this.state.page_description}
                />

                {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
              </MetaTags>
            </div>
          </div>
        </div>

        <div className="myCartButton">{this.renderButton()}</div>

        <div style={{ height: "auto" }} className="windowView">
          <MainFooterPage {...this.props} />
        </div>
        <div className="mobView">
          <StickyFooterPage {...this.props} />
        </div>
        {this.renderModal()}
        {this.invoiceData()}
        <ReactTooltip place="bottom" type="dark" effect="float" />
      </div>
    );
  }
}
export default Cart;
