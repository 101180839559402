import React, {Component} from 'react'
import AdminNavbar from '../Navbar/adminNavbar'
import SidebarPage from '../../../Components/Admin/Sidebar/sidebarPage'
// import './addSubject.css';
import DatePicker from "react-datepicker";

import Select from 'react-select';
import {MDBIcon} from 'mdbreact';
import MediaComponent from "../MediaComponent/chooseMediaFile";

// import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import swal from 'sweetalert'
// import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';
// import CKEditor from 'ckeditor4-react';
import Fade from 'react-reveal'

import CKEditor from 'ckeditor4-react';
// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;


class EditCaselaw extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:true,   
            caselaw_id:-1,  
            casenumber:'',  
            caseLawSubjects:[], 
            selectedSubjectList:[],
            caseLawCourtName:"",
            title:"",
            decisionDate:new Date(),
            synopsis: "<p>Demo text</p>",
            inputLink:'',
            caseLawCitation:'',
            issue:``
        }

        this.onEditorChange = this.onEditorChange.bind( this );
    }

   

      onEditorChange( e ) {
        console.log(e)
        this.setState( {
          synopsis: e.editor.getData()
        } );
      }
      

    componentDidMount(){
        this.getCaseLawDetails()     
    }

    //GETS , POSTS , FORM VALIDATION


    renderCaseLawSynopsis() {
        return (
          <div className="col-12 mb-2 mt-0 cost-section">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="cost-label subject-labels"
            >
              Case Synopsis :
            </label>
            
               <CKEditor
                 data={this.state.synopsis}
                 style={{width:'90%', margin:'10px auto'}}
                 onChange={this.onEditorChange} 
                 className="textFontSize"/> 
          </div>
        );
      }

    getSubjectList = async ()=> {
        const response = await fetch("https://www.vimasmiham.com/get_subjects")
        const subject_list = await response.json()
        console.log(subject_list)
        console.log(this.state.caseLawSubjects)
        let subjectList = []
        let selectedSubjectList = []
        for(var i = 0; i< subject_list.length;i++){
            // console.log(this.state.subjectId, "subjectId")
            let subject_object = subject_list[i];
            subject_object.value=subject_list[i].subject_id;
            subject_object.label = subject_list[i].subject_name;
            subject_object.url = subject_list[i].url
            subjectList.push(subject_object);
            if(this.state.caseLawSubjects!=null){
            if(this.state.caseLawSubjects.some(item=>item.id == subject_list[i].value)){
              selectedSubjectList.push(subject_object)
            }
          }
        }
        this.setState({
            subjectList:subjectList,
            selectedSubjectList,
            loading:false
          },()=>console.log(this.state))
    }


    getCaseLawDetails(){
      let case_law = window.localStorage.getItem('caselaw_item')
      console.log(case_law)
      if(!case_law){
        this.props.history.push('/admin/case-laws')
      }
      else{
        const caseLaw = JSON.parse(case_law);
        this.setState({
          caseLawCourtName:caseLaw.court_name,
          caseLawSubjects:JSON.parse(caseLaw.Subjects),
          decisionDate:new Date(caseLaw.date_of_decision),
          caselaw_id:caseLaw.caselaw_id,
          title:caseLaw.title,
          casenumber:caseLaw.case_number,
          caseLawCitation:caseLaw.citation,
          synopsis:caseLaw.synopsis,
          inputLink:caseLaw.link,
          issue:caseLaw.issue
        },()=>this.getSubjectList())
      }
    }

    renderChooseFile = () => {
        return (
          <div className="col-12  d-flex">
          <label htmlFor="defaultFormRegisterNameEx" className="course-duration-label subject-labels"
         
          // style={{  width: '41%' }}
          
          >
              Enter file link:
            </label>
    
        
            <div
              className="flex-column "
              style={{
                width: "39.4%",
                borderLeft: "1px solid #bfbfbf",
                height: "100%"
              }}
          
            >
              <input
                   style={{ marginTop: "10px", width: "100%"}}
                autoFocus
                type="text"
                placeholder="Choose File From Gallery"
                value={this.state.inputLink}
                onChange={text => this.handlechangeurl(text)}
                id="defaultFormRegisterNameEx"
                className="form-control duration-form custom-form link-placeholder"
              />
    
    <div   style={{ marginLeft:'5%' }}>
            <MediaComponent
           
                          onSelectMediaItem={this.handleMediaChooseImage}
                          buttonName={<MDBIcon icon="camera" />}
                          restriction="image/*"
                        />
                        </div>
        </div>
           
          </div>
        );
      };




    compare( a, b ) {
        if ( a.label < b.label ){
          return -1;
        }
        if ( a.label > b.label ){
          return 1;
        }
        return 0;
      }






    //ADD FORM

    editCaseLaw(){
      this.setState({
        loading:true
        // add_modal:false
        })

          
            const id = this.state.caselaw_id
          let formData = new FormData()
          formData.append('caselaw_id', id)
          formData.append('court_name',this.state.caseLawCourtName)
          formData.append('title',this.state.title)
          formData.append('case_number',this.state.casenumber)
          formData.append('date_of_decision',this.state.decisionDate)
          formData.append('citation', this.state.caseLawCitation)
          formData.append('issue', this.state.issue)
          //   formData.append('instructor_say',this.state.instructorSay)
          formData.append('synopsis', this.state.synopsis)
        //   formData.append('old_cost',this.state.subject_old_cost)
          formData.append('link', this.state.inputLink )
          if(this.state.selectedSubjectList || this.state.selectedSubjectList > 0) {
              const subject_list = this.state.selectedSubjectList.map(item=>{return {name:item.subject_name, id:item.subject_id,url:item.url}})
          formData.append('Subjects', JSON.stringify(subject_list))
          }
          for (let key of formData.entries()) {
            console.log(key[0] + ',with photo ' + key[1]);
        }

         

            this.setState({
            response: fetch('https://www.vimasmiham.com/edit_caselaw', {
            method: 'POST',
            body: formData
            }).then((response) => response.json())
            .then((responseJson)=>{
            if(responseJson.status == 200){
                swal("Success", "Case law succesfullly edited", "success").then((value)=>{
                    this.props.history.push('/admin/case-laws')
                    });
            }
            else{
                swal("Error", `${responseJson.error.sqlMessage}`, "info").then((value)=>{
                    // this.props.history.push('/admin/subject-list')
                    this.setState({loading:false})
                    });
            }
            })
            .catch((error) => {
            this.setState({
            loading:false
            })
            swal("Warning!", "Check your network!", "warning")
             console.log(error)
                })
            })
         

        }


    //RENDER FUNCTIONS
    renderCaseLawCourtName(){
        return(
            <div className="col-12  subject-name-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                 Court:
                 </label>
                 <input
                    
                    value={this.state.caseLawCourtName}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.setState({caseLawCourtName:text.target.value})}
                    />
            </div>
        )
    }


    renderCaseLawTitle(){
        return(
            <div className="col-12  subject-name-section d-flex">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                 Title:
                 </label>
                 <input  
                    value={this.state.title}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.setState({title:text.target.value})}
                    />
            </div>
        )
    }

    renderCaseLawIssue(){
      return(
          <div className="col-12  subject-name-section d-flex">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
               Issue:
               </label>
               <input  
                  value={this.state.issue}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-name-form custom-form"
                      onChange={(text)=>this.setState({issue:text.target.value})}
                  />
          </div>
      )
  }

    renderCaseLawCitation(){
      return(
          <div className="col-12  subject-name-section d-flex">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
               Citation:
               </label>
               <input
                  
                  value={this.state.caseLawCitation}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-name-form custom-form"
                      onChange={(text)=>this.setState({caseLawCitation:text.target.value})}
                  />
          </div>
      )
  }
  
  renderCaseLawNumber(){
    return(
        <div className="col-12  subject-name-section d-flex">
             <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
             Case Number:
             </label>
             <input
                
                value={this.state.casenumber}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({casenumber:text.target.value})}
                />
        </div>
    )
}
  
    







renderCourtDateDecision() {
    return (
      <div className="col-12  subject-name-section d-flex">
      <label htmlFor="defaultFormRegisterNameEx" className="date-from-label subject-labels mr-3">
           Court decision date:
      </label>
      <DatePicker
      selected={this.state.decisionDate}
      onChange={(date)=>this.setState({decisionDate:date })}
      dateFormat="dd/MM/yyyy"
    />
       </div>
    );
  }

  


    renderSubjects(){
        return (
            <React.Fragment>
               <div className="col-12  subject-name-section d-flex">
                <label htmlFor="defaultFormRegisterNameEx" className="subject-labels">
                 Related Subjects:
                </label>
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    onChange={e=>this.setState({selectedSubjectList:e},()=>console.log(this.state.selectedSubjectList))}
                    value={this.state.selectedSubjectList}
                    className='select-exam select mb-0'
                    options={this.state.subjectList}

                />
                </div>
            </React.Fragment>
        )
    }
    handleMediaChooseImage = inputLink =>{
        this.setState({inputLink})
      }


    render(){
      
        return(
            <div id="add-subject-page">
            <AdminNavbar {...this.props} />

            <div className="d-flex">
              <SidebarPage {...this.props} active={3}/>

              <div className="add-subject-column">
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
                    <React.Fragment>
                    <Fade>          

                    <div className="row my-3 px-4">
                    <div className="col-12 d-flex align-items-center justify-content-between">
                    <button className="admin-button-style" onClick={()=>this.props.history.push('/admin/case-laws')}>
                        All Case Laws
                    </button>
                    <button className="admin-button-style" onClick={()=>this.editCaseLaw()}> Save &amp; Publish</button>

                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Case Law</h3> 
                    </div>
                    </div>
                <div className="d-flex">
                <div className="subject-content-container ml-4">
                <div className="row px-0 mx-0">
                    {this.renderCaseLawTitle()}
                    {this.renderCaseLawIssue()}
                    {this.renderCaseLawNumber()}
                    {this.renderCaseLawCourtName()}
                    {this.renderCaseLawCitation()}
                    {this.renderCourtDateDecision()}
                    {this.renderSubjects()}
                   {this.renderCaseLawSynopsis()}
                   {this.renderChooseFile()}
                </div>
                </div>

                </div>

    

                <hr />

                    <button className="add-subject my-5 float-right mr-4" onClick={()=>this.editCaseLaw()}>Save &amp; Publish</button>
                    
                    
                    </Fade>
                    </React.Fragment>
                   )
                }
              </div>


            </div>
        </div>
        )
    
    }
}


export default EditCaselaw;