import { MDBTable, MDBTableBody } from "mdbreact";
import React, { useEffect, useState } from "react";
// import { HalfCircleSpinner } from "react-epic-spinners";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";

const QuestionsCount = (props) => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState(null);

  const getData = async () => {
    try {
      const response = await fetch(
        "https://www.vimasmiham.com/all-questions-count"
      );
      const responseJson = await response.json();
      console.log(responseJson);

      let myObject = {};
      responseJson.questions.forEach((question) => {
        if (myObject[question.topic] === undefined) {
          myObject[question.topic] = 0;
        }

        myObject[question.topic] = myObject[question.topic] + 1;
      });

      let subjectObj = {};
      responseJson.subject.forEach((subject) => {
        const topics = JSON.parse(subject.topics);
        let numberOfQuestions = 0;
        topics.forEach((ele) => {
          numberOfQuestions += myObject[ele.topic_id]
            ? myObject[ele.topic_id]
            : 0;
        });
        subjectObj[subject.subject_name] = numberOfQuestions;
      });
      console.log("obj", myObject, "sub", subjectObj);
      setQuestions({ myObject, subjectObj });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderTableData = () => {
    let array = [];
    for (const key in questions.subjectObj) {
      //   if (questions[key].name) {
      //   console.log("key", questions.myObject[key], questions.subjectObj[key]);
      array.push(
        <tr key={key} id={`tr-${key}`}>
          <td>{key}</td>
          <td>{questions.subjectObj[key]}</td>
        </tr>
      );
      //   }
    }
    return array;
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;
    return (
      <div className="sweet-loading-main">
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={5}
          color={"#2fb2eb"}
          loading={loading}
        />
      </div>
    );
  } else {
    return (
      <div id="subject-page-list">
        <AdminNavbar {...props} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <SidebarPage {...props} active={8} />

          <div
            className="colDefault"
            style={{ width: "100%", backgroundColor: "#F9F9F9" }}
          >
            <div className="page_heading d-flex justify-content-between">
              <h3 style={{ marginBottom: 0 }}>Questions Count</h3>
            </div>
            <div>
              <MDBTable
                paging={true}
                striped
                className="pages_table"
                style={{ backgroundColor: "#F9F9F9" }}
              >
                <tr style={{ backgroundColor: "white" }}>
                  {/* <th>Id</th> */}
                  <th className="">Subject</th>
                  <th>Total Questions</th>
                </tr>

                <MDBTableBody>{renderTableData()}</MDBTableBody>
              </MDBTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default QuestionsCount;
