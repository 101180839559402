import React,{Component} from 'react';
// import './subjectPage.css';
import SidebarPage from '../Sidebar/sidebarPage';
import AdminNavbar from '../Navbar/adminNavbar';
import swal from 'sweetalert';

import { MDBTable, MDBTableBody, MDBTableHead,MDBIcon, MDBCollapse } from 'mdbreact';
import { HalfCircleSpinner } from 'react-epic-spinners';
import ActivePNG from '../../../Assets/images/activestatus.png';
import InactivePNG from '../../../Assets/images/inactivestatus.png';
import '../../commonStyles.css'
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import GridLoader from "react-spinners/GridLoader";
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;


class Caselaws extends Component {
  constructor( props ) {
      super( props );

      this.state = {
        loading:false,
        subjects:[],
        showAction:false,
        draft_count:0,collapseID:0,
        published_count:0,
        trash_count:0,all_selected:true,draft_selected:false,
        published_selected:false,trash_selected:false,selected_data:[],exams:[]
      };
  
  }
componentWillMount(){
      this.setState({loading:true}, ()=>this.getCaselaws())
    //   this.getTopics()
       
}

componentDidMount(){
      window.localStorage.removeItem('subject_item');
    }


  handleChangeAddName=(e)=> {
    this.setState({name: e.target.value});
  }
  handleChangeAddLink=(e)=> {
    this.setState({website_link: e.target.value});
  }

  handleChangeAddLogo=(e)=> {
    this.setState({logo: e.target.files[0]});
  }
 
  

    toggleCollapse(id) {
      if (this.state.collapseID === id) {
        this.setState({
          collapseID: 0,
        });
      } else {
        this.setState({
          collapseID: id,
        });
      
      }
    }

  getCaselaws=async()=>{

    this.setState({
        loading:true
      })         
          this.setState({
          response: fetch('https://www.vimasmiham.com/get_caselaws', {
          method: 'GET',
          }).then((response) => response.json())
          .then((responseJson)=>{
            var draft_count=0,published_count=0,trash_count=0
              for(var v=0;v<responseJson.length;v++){
                 responseJson[v].id = responseJson[v].subject_id
                  responseJson[v].selected = 0
                  }
                  console.log(responseJson)
            this.setState({
                subjects:responseJson,
                loading:false
                })
               } 
          )
          .catch((error) => {
          this.setState({
          loading:false
          })
          swal("Warning!", "Check your network!", "warning");
        console.log(error)
              })
          })
    } 



  

    commentdate(timestamp){ 
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Novr", "Dec"
      ];
        var dt = new Date(timestamp);
        var dd = dt.getDate(); 
        var mm = (dt.getMonth()+1); 
        if (dd < 10) { 
            dd = '0' + dd; 
        } 
        if (mm < 10) { 
            mm = '0' + mm; 
        } 
       return (  dd +"/"+mm+ "/" +(dt.getFullYear()).toString());
      }

    commenttime(timestamp){  
        var convertdLocalTime = new Date(timestamp);
        var hours = convertdLocalTime.getHours();
        var minutes = convertdLocalTime.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;         
      }

    handleEditSubject(item){
        window.localStorage.setItem('caselaw_item', JSON.stringify(item))
        // window.localStorage.setItem('caselaw_item',JSON.stringify(this.state.subjects))
        this.props.history.push('/admin/edit-case-law');
     }

     handleAddNewSubject(){
      window.localStorage.setItem('subject_list',JSON.stringify(this.state.subjects))
      this.props.history.push('/admin/add-case-law');
   }

   renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.trash_selected) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            |{" "}
            <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span>
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditSubject(item, index)}
              className="actionstext"
            >
              Edit
            </span>
            <span className="">|</span>
            <span
              onClick={() => this.trashAlert(item)}
              className="actionstext d-none"
              style={{ color: "#a00" }}
            >
              Trash
            </span>{" "}
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "" }}>
          <span className="actionstext" onClick={() => this.handleEditSubject(item, index)}>Edit</span> 
          {/* <span className="actionstext" style={{ color: "#a00" }}>
            Trash
          </span>{" "} */}
        </div>
      );
    }
  }


      showactionbutton(item,index){
          let {subjects} = this.state
          let selected_page = subjects[index]
          for(var v=0;v<subjects.length;v++){
            subjects[v].selected=0
          }
          selected_page.selected=1 
          this.setState({
            subjects
          })
      }
      hideactionbutton(){
        let {subjects} = this.state
       
        for(var v=0;v<subjects.length;v++){
            subjects[v].selected=0
        }
      
        this.setState({
            subjects
        })
      }
      renderStatus(item){
          if(item.status==0){
              return 'Last Modified'
          }
          else{
              return 'Published'
          }
      }
      trashAlert= (item)=>{
         swal({
     
           title: "Are you sure?",
           text:'You want to move this page to trash',
           buttons: true,
           dangerMode: true,
         })
         .then((willAdd) => {
           if (willAdd) {
             this.trashPage(item)} else return
         });
     
     
        }
        deleteAlert= (item)=>{
             swal({
         
               title: "Are you sure?",
               text:'You want to delete this page',
               buttons: true,
               dangerMode: true,
             })
             .then((willAdd) => {
               if (willAdd) {
                 this.deletePage(item)} else return
             });
         
         
            }
        restoreAlert= (item)=>{
             swal({
         
               title: "Are you sure?",
               text:'You want to restore this page',
               buttons: true,
               dangerMode: true,
             })
             .then((willAdd) => {
               if (willAdd) {
                 this.restorePage(item)} else return
             });
         
         
            }
        trashPage(item){
            this.setState({
                loading:true
              })
               let details = {
               id:item.subject_id,
              
             };
             let formBody = [];
             for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
             }
             formBody = formBody.join("&");
             
             this.setState({
              response: fetch('http://18.221.183.249:3000/trash_content', {
                method: 'POST',
                headers: {
                 
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Cache-Control': 'max-age=31536000'
              },
             body:formBody
               
                
              }).then((response) => response.json())
              .then((responseJson)=>{
                   window.location.reload()
                
              }
             
              )
                .catch((error) => {
               this.setState({
                 loading:false
               })
               swal("Warning!", "Check your network!", "warning");
             console.log(error)
                 })
                })
        }

        changeStatus(item,status){
            this.setState({
                loading:true
              })
               let details = {
               caselaw_id:item.caselaw_id,
               status:status
             };
             let formBody = [];
             for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
             }
             formBody = formBody.join("&");
             
             this.setState({
              response: fetch('https://www.vimasmiham.com/caselaw_status', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Cache-Control': 'max-age=31536000'
              },
             body:formBody
               
                
              }).then((response) => response.json())
              .then((responseJson)=>{
                  this.getCaselaws()         
              }
             
              )
                .catch((error) => {
               this.setState({
                 loading:false
               })
               swal("Warning!", "Check your network!", "warning");
             console.log(error)
                 })
                })
        }
        restorePage(item){
            // this.setState({
            //     loading:true
            //   })
            //    let details = {
            //    id:item.id,
              
            //  };
            //  console.log(details,'details')
            //  let formBody = [];
            //  for (let property in details) {
            //   let encodedKey = encodeURIComponent(property);
            //   let encodedValue = encodeURIComponent(details[property]);
            //   formBody.push(encodedKey + "=" + encodedValue);
            //  }
            //  formBody = formBody.join("&");
             
            //  this.setState({
            //   response: fetch('http://18.221.183.249:3000/restore_content', {
            //     method: 'POST',
            //     headers: {
                 
            //       'Content-Type': 'application/x-www-form-urlencoded',
            //       'Cache-Control': 'max-age=31536000'
            //   },
            //  body:formBody
               
                
            //   }).then((response) => response.json())
            //   .then((responseJson)=>{
            //        window.location.reload()
                
            //   }
             
            //   )
            //     .catch((error) => {
            //    this.setState({
            //      loading:false
            //    })
            //    swal("Warning!", "Check your network!", "warning");
            //  console.log(error)
            //      })
            //     })
        }
  

  renderActiveStatus(item){
    if(item.status==1){
      return <img src={ActivePNG} alt="Course is active" className="course-status-icon" onClick={()=>this.setState({loading:true},this.changeStatus(item,0))}/>
    }
    else{
      return <img src={InactivePNG} alt="Course is INactive" className="course-status-icon" onClick={()=>this.setState({loading:true},this.changeStatus(item,1))} />
    }
  }
    
//   onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()
  renderTableData(item,index){
      
  if(this.state.all_selected==true ){
      if( item.status!=2){
      return (     
        <tr key={item.caselaw_id}>
          
        <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td>
        <td style={{width:'25%'}} className="tdName textFontSize" >
        <div className='name_div_table'>
        <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.title}</span>
        {this.renderActions(item,index)}
        </div>
        </td>
      <td>{item.court_name}</td>
      <td>{this.commentdate(item.date_of_decision)}</td>
        {/* <td className="datefontsize">{this.renderStatus(item)} <br /> <span className='tabledate'>{this.commentdate(item.creation_time)}</span>
        </td>*/}
        <td>{this.renderActiveStatus(item)}</td>
        </tr>
         )
      }

  
  }
  else if(this.state.published_selected==true ){
  if(item.status==1){
  return (  
      <tr key={item.subject_id}>
      <td><input className='checkbox_content' type='checkbox' /></td>
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
      <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.subject_name}</span>
      {this.renderActions(item,index)}
      </div></td>
                          <td className="datefontsize">{this.renderStatus(item)} <br /> <span className='tabledate'>{this.commentdate(item.creation_time)}</span></td>
                          <td>{this.renderActiveStatus(item)}</td>
      
                              </tr>)
  }
  

}
else if(this.state.draft_selected==true){
  if( item.status==0){
  return (      
      <tr key={item.subject_id}>
      <td><input className='checkbox_content' type='checkbox' /></td>
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
      <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.subject_name}</span>
      {this.renderActions(item,index)}
      </div></td>
                          <td className="datefontsize">{this.renderStatus(item)} <br /> <span className='tabledate'>{this.commentdate(item.creation_time)}</span></td>
                          <td>{this.renderActiveStatus(item)}</td>
                           </tr>)
  }


}
else if(this.state.trash_selected==true){
if( item.status==2){
  return (      <tr key={item.subject_id}>
      <td><input className='checkbox_content' type='checkbox' /></td>
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
      <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.subject_name}</span>
      {this.renderActions(item,index)}
      </div></td>
          <td className="datefontsize">{this.renderStatus(item)} <br /> <span className='tabledate'>{this.commentdate(item.creation_time)}</span></td>     </tr>)
                      }
            }
    }


  onToggle(index, e){
  let {subjects,selected_data} = this.state
  let selected_item=subjects[index]
  if(selected_item.checked==1){
   selected_item.checked=0
   selected_data.splice(index,1)
  }
  else{
    selected_item.checked=1
  selected_data.push(selected_item)
  }
  
  this.setState({selected_data})
  this.setState({subjects})
  
  }
  
  
handleAllChecked = (event) => {
  let {subjects} = this.state
if(this.state.item_selected){
  for(var v=0;v<subjects.length;v++){
    subjects[v].checked=1
  }
  this.setState({selected_data:subjects})
}
else{
  for(var v=0;v<subjects.length;v++){
    subjects[v].checked=0
  }
  this.setState({selected_data:[]})
}
  this.setState({subjects})
  
}

  render() {
  
    return (
      <div id="subject-page-list">
           <AdminNavbar {...this.props}/>
      <div style={{display:'flex',flexDirection:'row'}}>
      <SidebarPage {...this.props} active={3}/>
      
        <div className='colDefault' style={{width:'100%',backgroundColor:'#F9F9F9'}}>

        {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
             <React.Fragment>
        <div className="page_heading"><h3 style={{marginBottom:0}}>Case Laws</h3>
        <span onClick={()=>this.handleAddNewSubject()} className='add_new_button'>Add New</span>
        </div> 
        <div>

               <MDBTable  striped className="pages_table" style={{backgroundColor:'#F9F9F9'}}>
              
                  <tr style={{backgroundColor:'white'}}>
                  <th><input type="checkbox" onClick={(event)=> this.setState({item_selected:!this.state.item_selected},()=>this.handleAllChecked(event))}  checked={this.state.item_selected}  value="checkedall" /></th>
                       <th className="" >Title</th>
                       <th>Court</th>
                      <th className="textFontSize">Date</th>
                      <th>Status</th>
                  </tr>
                 
                  <MDBTableBody>
                  { this.state.subjects.map((item,index) => {
                    return this.renderTableData(item,index)
                              })}
                  </MDBTableBody>
              </MDBTable> 
        {/* {this.state.exams.map((item,index)=>{
        if(item.subjects.length!=0){
          return(
            this.renderDraggableSubjects(item,index)
          )
        }
        })} */}
             </div>
             </React.Fragment>
                   )
                }       
           </div>
        </div>
      </div>

  );
   
}
}

export default Caselaws;


