import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MDBIcon } from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class AddCircular extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,

      taxName: "",
      faq_question: "",
      faq_answer: "",
      taxValue: "",
      circular_name: "",
      file: null,
      date_of_circular: new Date(),
    };
  }

  componentWillMount() {}

  componentDidMount() {}

  //ADD FORM
  renderCircularDate() {
    return (
      <div className="col-6 my-2">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="date-from-label subject-labels mr-3"
        >
          Circular date:
        </label>
        <DatePicker
          selected={this.state.date_of_circular}
          onChange={(date) => this.setState({ date_of_circular: date })}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    );
  }
  addCircular() {
    console.log(
      this.state.circular_name,
      this.state.circular_id,
      this.state.file
    );
    if (this.state.circular_name.length == 0) {
      swal("Check Name field!", "Please enter display name of file", "error");
    } else if (this.state.file == null) {
      swal("", "Choose file", "error");
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });

      let formData = new FormData();
      formData.append("circular_name", this.state.circular_name);
      formData.append("circular_link", this.state.file);
      formData.append("date_of_circular", this.state.date_of_circular);
      console.log(formData);
      this.setState({
        response: fetch("https://www.vimasmiham.com/add_circular", {
          method: "POST",
          body: formData
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "QWERTy");
            swal("Success", "File succesfullly added", "success").then(
              value => {
                this.props.history.push("/admin/acts-regulations");
              }
            );
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  }

  //HANDLE CHANGE FUNCTIONS

  handleNameChange = (text) => {
    console.log("Tax Name:", text.target.value);
    this.setState({ circular_name: text.target.value });
  };

  //RENDER FUNCTIONS
  rendercircularNameSection() {
    return (
      <div className="col-12 my-2 subject-name-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Display Name :
        </label>
        <input
          autoFocus
          value={this.state.circular_name}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.handleNameChange(text)}
        />
      </div>
    );
  }

  handleMediaChooseImage = (file) => {
    this.setState({ file });
  };

  rendercircularLinkSection() {
    return (
      <div className="col-12 my-2 subject-name-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels mr-3"
          style={{ width: "auto" }}
        >
          File Url:
        </label>
        <br />
        <input
          onChange={(e) => this.setState({ file: e.target.value })}
          value={this.state.file}
          // className="link-placeholder"
          placeholder="Choose File From Gallery"
        />
        <MediaComponent
          style={{ marginLeft: "-20px" }}
          onSelectMediaItem={this.handleMediaChooseImage}
          buttonName={<MDBIcon icon="camera" />}
          restriction="image/*"
        />
      </div>
    );
  }

  render() {
    console.log(this.props.history);
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={6} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <button
                      className="admin-button-style"
                      onClick={() =>
                        this.props.history.push("/admin/acts-regulations")
                      }
                    >
                      All Acts
                    </button>
                    <span></span>
                    <span></span> <span></span>
                    <span></span>
                    <span></span>
                    <button
                      className="admin-button-style"
                      onClick={() => this.addCircular()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                </div>
                {/* <div className="col-12 mt-4 d-flex justify-content-between align-items-center"> */}
                <h3 style={{ marginLeft: "20px" }}>
                  {" "}
                  Add Acts
                </h3>

                {/* </div> */}

                <div className="row px-2 my-2 mx-0">
                  {this.rendercircularNameSection()}

                  {this.rendercircularLinkSection()}

                  {this.renderCircularDate()}
                </div>
              </React.Fragment>
            )}
          </div>

          {console.log(this.state)}
        </div>
      </div>
    );
  }
}

export default AddCircular;
