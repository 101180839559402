import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import { MDBTable, MDBTableBody } from "mdbreact";
import AdminNavbar from "../Navbar/adminNavbar";
import "./userDetail.css";
import "../Lectures/Addlecture.css";
import "../tax/addTax.css";
import swal from "sweetalert";
// import { HalfCircleSpinner } from "react-epic-spinners";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";
import { MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
class AllUserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      user_data: [
        {
          name: "Raman",
          status: 1,
          mobile: "9813586213",
          email: "xyz@gmail.com",
          gender: "Male",
          city: "Gurgaon",
          date_of_birth:
            "Fri Mar 20 2017 18:18:55 GMT+0530 (India Standard Time)",
          qualification: [
            { academic_qualification: "B.Tech" },
            { profession_qualification: "M.Tech" },
          ],
          company: "xyz",
          designation: "Manager",
          billing_address: "abc street near xyz mall, guragaon,122001",
        },
      ],
      modal_edit: false,
      logo: null,
      name: "",
      link: "",
      delete_item: "",
      clientData: "",
      selected_client: "",
      selected_data: [],
      updated_status: "",
      id: "",
      tabs: ["B.Tect, Bsc, Msc, M.Tech, BCA"],
    };
  }

  componentWillMount() {
    this.checkUserOrder();
  }

  checkUserOrder = async () => {
    this.setState({ loading: true });
    this.setState({
      response: fetch("https://www.vimasmiham.com/check_user_orders", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "clients");
          let detailsWithOrder = [],
            userids = [];
          let filteredArray = responseJson.map((item) => {
            if (userids.indexOf(item.user_id) == -1) {
              userids.push(item.user_id);
              let userObjWithOrder = {};
              userObjWithOrder.user_id = item.user_id;
              userObjWithOrder.name = item.name;
              userObjWithOrder.email = item.email;
              userObjWithOrder.phone = item.phone;
              userObjWithOrder.status = item.status;
              if (item.items == null) {
                userObjWithOrder.orders = null;
              } else {
                userObjWithOrder.orders = [];

                let orderItem = {};
                orderItem.amount = item.amount;
                orderItem.items = [];
                let purchaseItems = JSON.parse(item.items);
                purchaseItems.forEach((element) => {
                  let purchaseItem = {};
                  purchaseItem.subject_name = element.subject_name;
                  purchaseItem.test_included = element.test_included;
                  purchaseItem.subject_included = element.subject_included;
                  purchaseItem.time = element.ts;
                  orderItem.items.push(purchaseItem);
                });
                userObjWithOrder.orders.push(orderItem);
              }
              // userObjWithOrder.amount=item.amount
              detailsWithOrder.push(userObjWithOrder);
              console.log(detailsWithOrder);
            } else {
              let idToFind = item.user_id;
              for (let i = 0; i < detailsWithOrder.length; i++) {
                if (detailsWithOrder[i].user_id == idToFind) {
                  let orderItem = {};
                  orderItem.amount = item.amount;
                  orderItem.items = [];
                  let purchaseItems = JSON.parse(item.items);
                  purchaseItems.forEach((element) => {
                    let purchaseItem = {};
                    purchaseItem.subject_name = element.subject_name;
                    purchaseItem.test_included = element.test_included;
                    purchaseItem.subject_included = element.subject_included;
                    purchaseItem.time = element.ts;
                    orderItem.items.push(purchaseItem);
                  });
                  detailsWithOrder[i].orders.push(orderItem);
                }
              }
            }
          });
          this.setState({ user_data: detailsWithOrder, loading: false }, () =>
            console.log(detailsWithOrder)
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  updateStatus = async () => {
    this.setState({
      loading: true,
    });

    let details = {
      user_id: this.state.id,
      status: this.state.updated_status,
    };
    console.log(details, "updated-detail");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  // -------------function for rendering  data---------

  confirmDelete = (item) => {
    swal({
      title: "Are you sure?",
      text: "You are deleting this client!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.deleteClient(item);
      } else return;
    });
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }
  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  edit_user(data) {
    console.log(data, "edit");
    window.localStorage.removeItem("userdata");
    window.localStorage.setItem("userdata", JSON.stringify(data));
    this.props.history.push("/admin/edit-user");
  }

  showModal() {
    let item = this.state.selected_user;

    console.log(item, "NEO");
    if (this.state.open) {
      return (
        <MDBModal
          size="lg"
          isOpen={this.state.open}
          toggle={() => this.setState({ open: false })}
        >
          <MDBModalHeader toggle={() => this.setState({ open: false })}>
            <p
              style={{
                fontSize: "20px",
                textAlign: "center",
                margin: "0",
              }}
            >
              {item.name}
            </p>
          </MDBModalHeader>
          <MDBModalBody>
            <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
              <p style={{ marginLeft: "38%", marginTop: "10px" }}>
                <span className="showModalSpan">Email :</span> {item.email}
              </p>
              <p style={{ marginLeft: "38%" }}>
                <span className="showModalSpan">Mobile No : </span>
                {item.phone}
              </p>
              {/* <hr className="showModalSubjectItem"></hr> */}

              {item.orders !== null
                ? item.orders.map((subject) => {
                    return (
                      <div
                        style={{ marginBottom: "10px" }}
                        className="showModalSubjectItem d-flex"
                      >
                        <div className="order-items-list w-75">
                          {subject.items.map((item) => {
                            return (
                              <p style={{ fontFamily: "Ubuntu-M" }}>{`${
                                item.subject_name
                              }: ${
                                item.subject_included == 1 &&
                                item.test_included == 1
                                  ? "Study Material + Test Series"
                                  : item.subject_included == 0 &&
                                    item.test_included == 1
                                  ? "Test Series"
                                  : "Study Material"
                              }`}</p>
                            );
                          })}
                        </div>
                        <p
                          className="w-25 text-right Ubuntu-M"
                          style={{ fontFamily: "Ubuntu-M" }}
                        >
                          Rs.{subject.amount}
                        </p>
                      </div>
                    );
                  })
                : ""}
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    } else {
      return null;
    }
  }

  renderShowTransactions(item, index) {
    if (item.selected == 1) {
      return (
        <div className="actions_div">
          <text
            // onClick={() => this.setOrders(item, index)}

            onClick={() =>
              this.setState({ selected_user: item }, () =>
                this.setState({ open: true })
              )
            }
            className="actionstext"
          >
            Details
          </text>
        </div>
      );
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <text className="actionstext">Details</text>
        </div>
      );
    }
  }
  renderActions(item, index) {
    if (item.selected == 1) {
      return (
        <div className="actions_div">
          <text
            onClick={() => this.edit_user(item, index)}
            className="actionstext"
          >
            Details
          </text>
        </div>
      );
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <text className="actionstext">Details</text>
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { user_data } = this.state;
    let selected_page = user_data[index];
    for (var v = 0; v < user_data.length; v++) {
      user_data[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      user_data,
    });
  }
  hideactionbutton() {
    let { user_data } = this.state;

    for (var v = 0; v < user_data.length; v++) {
      user_data[v].selected = 0;
    }

    this.setState({
      user_data,
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { user_data, selected_data } = this.state;
    let selected_item = user_data[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ user_data });
  }

  handleAllChecked = (event) => {
    let { user_data } = this.state;
    if (this.state.all_selected) {
      for (var v = 0; v < user_data.length; v++) {
        user_data[v].checked = 1;
      }
      this.setState({ selected_data: user_data });
    } else {
      for (var v = 0; v < user_data.length; v++) {
        user_data[v].checked = 0;
      }
      this.setState({ selected_data: user_data });
    }
    this.setState({ user_data });
  };

  changeStatus(item, status) {
    this.setState({
      loading: true,
    });
    let details = {
      user_id: item.user_id,
      status: status,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_user_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }
  handleOptionChange = (data, head_key) => {
    let { user_data } = this.state;

    var target_task = user_data[head_key];
    if (user_data[head_key].status == 1) {
      target_task.status = 0;
    } else {
      target_task.status = 1;
    }
    this.setState(
      {
        user_data,
        updated_status: target_task.status,
        id: user_data[head_key].user_id,
      },
      () => this.updateStatus()
    );
  };

  renderAccountTitleSEO() {
    return (
      <div className="col-12 my-2 subject-name-section">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Accounts Title SEO :
        </label>
        <input
          autoFocus
          value={this.state.taxName}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.handletaxNameChange(text)}
        />
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;
    if (this.state.loading) {
      return (
        // <div className="loadingSpin">
        //   <HalfCircleSpinner color="#3A7FCA" />
        // </div>
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      {
        console.log(this.state);
      }
      return (
        <div>
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={0} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
              <div className="page_heading">
                <h3 style={{ marginBottom: 0 }}>User Details</h3>
                <button
                  className="admin-button-style ml-4"
                  onClick={() => this.props.history.push("/admin/user-seo")}
                >
                  {" "}
                  User SEO
                </button>
              </div>
              <div>
                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    <th>
                      <input
                        type="checkbox"
                        onClick={(event) =>
                          this.setState(
                            { all_selected: !this.state.all_selected },
                            () => this.handleAllChecked(event)
                          )
                        }
                        checked={this.state.all_selected}
                        value="checkedall"
                      />
                    </th>
                    <th className="">Name</th>
                    <th className="">Email</th>
                    <th className="">Phone</th>
                    <th>Transactions by User</th>
                    <th className="">Status</th>
                  </tr>

                  <MDBTableBody>
                    {this.state.user_data.map((item, index) => (
                      <tr key={item.id}>
                        <td>
                          {" "}
                          <input
                            type="checkbox"
                            onChange={() => this.onToggle(index)}
                            checked={item.checked == 1 ? true : false}
                          />
                        </td>
                        <td
                          style={{ width: "25%" }}
                          className="tdName textFontSize"
                        >
                          <div
                            onMouseEnter={() =>
                              this.showactionbutton(item, index)
                            }
                            onMouseLeave={() => this.hideactionbutton()}
                            className="name_div_table"
                          >
                            <text
                              style={{
                                fontFamily: "Ubuntu-B",
                                color: "#0073aa",
                              }}
                            >
                              {item.name}
                            </text>
                            {this.renderActions(item, index)}
                          </div>
                        </td>
                        <td
                          style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                          className="tdName textFontSize"
                        >
                          {item.email}
                        </td>
                        <td
                          className="tdName textFontSize"
                          style={{ color: "#0073aa" }}
                        >
                          {item.phone}
                        </td>
                        <td
                          style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                          className="tdName textFontSize"
                        >
                          <div
                            onMouseEnter={() =>
                              this.showactionbutton(item, index)
                            }
                            onMouseLeave={() => this.hideactionbutton()}
                            className="name_div_table"
                          >
                            <text
                              style={{
                                fontFamily: "Ubuntu-B",
                                color: "#0073aa",
                              }}
                            >
                              {item.orders == null || item.orders.length <= 0
                                ? "Unpaid"
                                : "Paid"}
                            </text>
                            {this.renderShowTransactions(item, index)}
                          </div>
                        </td>
                        <td
                          className="tdName textFontSize"
                          style={{ color: "#0073aa" }}
                        >
                          {" "}
                          {this.renderActiveStatus(item)}
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
                {this.showModal()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default AllUserDetails;
