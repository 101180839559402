import React, {Component} from 'react'
import AdminNavbar from '../Navbar/adminNavbar'
import SidebarPage from '../Sidebar/sidebarPage'
import './offer.css';
import {MDBIcon} from 'mdbreact';
import 'react-picky/dist/picky.css';
import swal from 'sweetalert'
import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';
import Fade from 'react-reveal'
import MediaComponent from '../MediaComponent/chooseMediaFile';


const topicsList=[];
for(var i=1;i<=100;i++){
    topicsList.push({id:i,name:`Topic ${i}`})
}

const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;


class Offer extends Component {
    constructor(props){
        super(props);
        this.state={
            loadng:true,
           banner:{},
           edit_image:null,edit_image_url:null,
           inputLink:''
           
        }

   


    }



    componentWillMount(){
  
         this.setState({loading:true},()=>this.getBanner());
        }
    
      //GET FUNCTION
      getBanner = async() => {
        console.log("fetching orders")
         this.setState({
          loading:true
      })
       this.setState({
      response: fetch('https://www.vimasmiham.com/get_banner', {
       method: 'GET',
       headers: {
        
         'Content-Type': 'application/x-www-form-urlencoded',
       
      },
      
      
       
      }).then((response) => response.json())
      .then((responseJson)=>{
        console.log(responseJson,'promos') 
     this.setState({  
        banner:responseJson,
        inputLink:responseJson.banner_link,
        loading:false
     })
      }
      
      )
       .catch((error) => {
      this.setState({
        loading:false
      })
      alert("Warning!", "Check your network!", "warning");
      console.log(error)
        })
       })
    }
   


    componentDidMount(){
        }




        handleMediaChooseImage = inputLink =>{
            this.setState({inputLink})
          }


    //Edit FORM

    editTax(){


      

            this.setState({
                loading:true
          
                })
        
                let formData = new FormData()
                // var pdf_filename = (this.state.selected_products.pdf_file).substr(28,(this.state.selected_products.pdf_file).length)
                 formData.append('file', this.state.inputLink)
             
              
              this.setState({
              response: fetch('https://www.vimasmiham.com/edit_banner', {
              method: 'POST',
            
              body:formData
              
              
              }).then((response) => response.json())
              .then((responseJson)=>{
        
                console.log(responseJson,'123')
                 this.getBanner()
           swal("","Banner Updated","success")
                        
                        
                        
                        
                        }
              
              )
              .catch((error) => {
              this.setState({
               loading:false
              })
              swal("Warning!", "Check your network!", "warning");
              console.log(error)
               })
             }) 
      
         }
      

         changeStatus(data){

          let  details = {
                // product_id:this.state.selected_products.product_id,
               
               
                status:data
              
                
              
              
            
          }
      
      console.log(details,'add-without photo')
      let formBody = [];
      for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      
      this.setState({
      response: fetch('https://www.vimasmiham.com/change_banner_status', {
      method: 'POST',
      headers: {
       
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'max-age=31536000'
      },
      body:formBody
      
      
      }).then((response) => response.json())
      .then((responseJson)=>{

     this.getBanner()

                
                
                
                
                }
      
      )
      .catch((error) => {
      this.setState({
       loading:false
      })
      swal("Warning!", "Check your network!", "warning");
      console.log(error)
       })
     })
                    
                            }

    //HANDLE CHANGE FUNCTIONS

    handletaxNameChange=(text)=> {
        console.log("Tax Name:",text.target.value,)
        this.setState({faq_question:text.target.value})
        }

  

    handletaxValueChange=(cost)=> {
        console.log("Tax value",cost.target.value)
        this.setState({faq_answer:cost.target.value})
        }



    //RENDER FUNCTIONS
  

    // renderTestIdSection(){
    //     return(
    //         <div className="col-4 my-2 test-id-section">
    //         <label htmlFor="defaultFormRegisterNameEx" className="test-id-label subject-labels">
    //              Test ID:
    //         </label>
    //         <Select
    //                 closeMenuOnSelect={false}
    //                 isMulti
    //                 onChange={e=>this.setState({selectedTestIDs:e})}
    //                 value={this.state.selectedTestIDs}
    //                 className='select-testid select'
    //                 options={this.state.testIDs}

    //             />
    //          </div>
    //  )
    // }

    rendertaxNameSection(){
        return(
            <div className="col-12 my-2 subject-name-section">
                 <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Question :
                 </label>
                 <input
                    autoFocus
                    value={this.state.faq_question}
                        type="text"
                        id="defaultFormRegisterNameEx"
                        className="form-control subject-name-form custom-form"
                        onChange={(text)=>this.handletaxNameChange(text)}
                    />
            </div>
        )
    }



    rendertaxValueSection(){
        console.log(this.state.faq_answer,'faq_answer')
        return(
                 <div className="col-12 my-2 cost-section">
                 <label htmlFor="defaultFormRegisterNameEx" className="cost-label subject-labels">
                 Answer :
                 </label>
                 <input
                    
                    value={this.state.faq_answer}
                        type="textarea"
                        rows={4}
                        id="defaultFormRegisterNameEx"
                        className="form-control cost-form custom-form"
                        onChange={(cost)=>this.handletaxValueChange(cost)}
                    />
                  </div>
        )}
 

   

 
        handlechangeurl = text => this.setState({ inputLink: text.target.value });





     



    render(){
        console.log(this.state)
        return(
            <div id="add-subject-page">
            <AdminNavbar {...this.props} />

            <div className="d-flex">
              <SidebarPage {...this.props } active={6}/>

              <div className="add-subject-column">
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
                    <React.Fragment>
                           <Fade>   
                    <div className="row my-3 px-4">
                    <div className="col-12" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                    <button className="admin-button-style" onClick={()=>this.changeStatus(this.state.banner.status==1?0:1)}>
                        {this.state.banner.status==1?'Deactivate':'Activate'}
                    </button>
                    <button className="admin-button-style"  onClick={()=>this.editTax()}> Save &amp; Publish</button>
                    </div>
                  
                    </div>
                               
                    <div className="row px-2 my-2 mx-0 flex-column">
                    
                    <MediaComponent
           
           onSelectMediaItem={this.handleMediaChooseImage}
           buttonName={<MDBIcon icon="camera" />}
           restriction="image/*"
         />
           <input
                   style={{ marginTop: "10px", width: "100%"}}
                autoFocus
                type="text"
                placeholder="Choose File From Gallery"
                value={this.state.inputLink}
                onChange={text => this.handlechangeurl(text)}
                id="defaultFormRegisterNameEx"
                className="form-control duration-form custom-form link-placeholder w-50"
              />

                 
                 
             <img src={this.state.inputLink} className="mx-auto mt-4" />       
                    
                    </div>
                    </Fade>
                    </React.Fragment>
                   )
                }
              </div>


            {console.log(this.state)}
            </div>
        </div>
        )
    }
}


export default Offer




            