import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import "../tax/editTax.css";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import { MDBIcon } from "mdbreact";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import CKEditor from "ckeditor4-react";

const topicsList = [];
for (var i = 1; i <= 100; i++) {
  topicsList.push({ id: i, name: `Topic ${i}` });
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class editFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      aboutusId: "",
      aboutus: "",
      address: "",
      email_tutor: "",
      email_support:'',
      phone: ""
    };

    this.onEditorChange = this.onEditorChange.bind(this);
  }

  componentWillMount() {
    this.setState({ loading: true }, () => this.getFooterDetails());
  }

  getFooterDetails = async () => {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://www.vimasmiham.com/get_footer", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);
          this.setState({
            aboutus: responseJson[0].about_us,
            phone: responseJson[0].phone,
            email_support: responseJson[0].email_support,
            email_tutor:responseJson[0].email_tutor,
            address: responseJson[0].address,
            loading: false
          });

          console.log(this.state);
        })
        .catch(error => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  //Edit FORM

  editFooter = () => {
    if (this.state.aboutus.length == 0) {
      swal("Check About us!", "Please enter about us", "error");
    } else if (this.state.email_tutor.length == 0) {
        swal("Check Email!", "Enter email", "error");
      } else if (this.state.email_support.length == 0) {
      swal("Check Email!", "Enter email", "error");
    }  else {
      this.setState({
        loading: true
      });

      let details = {
        // product_id:this.state.selected_products.product_id,
        id: 1,
        about_us: this.state.aboutus,
        phone: this.state.phone,
        email_support: this.state.email_support,
        email_tutor:this.state.email_tutor,
        address: this.state.address
      };
      console.log(details, "add-without photo");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/edit_footer", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000"
          },
          body: formBody
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "123");
            if (responseJson.status == 200) {
              this.setState({
                loading: false
                // add_modal:false
              });
              this.props.history.push("/admin/edit-footer");
            } else {
              this.setState({
                loading: false
                // add_modal:false
              });

              swal("Warning!", responseJson.message, "warning");
            }
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
    }
  };

  //HANDLE CHANGE FUNCTIONS


  renderFooterAbout = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          About us:
        </label>
        <input
          value={this.state.aboutus}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={text => this.setState({ aboutus: text.target.value })}
        />
      </div>
    );
  };

  renderFooterEmailTutor = () => {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Email (Tutor):
        </label>
        <input
          value={this.state.email_tutor}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ email_tutor: text.target.value })}
        />
      </div>
    );
  };


  renderFooterEmailSupport = () => {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Email (Support):
        </label>
        <input
          value={this.state.email_support}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={text => this.setState({ email_support: text.target.value })}
        />
      </div>
    );
  };
  renderFooterPhone = () => {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Phone Number:
          </label>
          <input
            value={this.state.phone}
            type="text"
            id="defaultFormRegisterNameEx"
            className="form-control subject-subtitle-form custom-form"
            onChange={text => this.setState({ phone: text.target.value })}
          />
        </div>
      </React.Fragment>
    );
  };

  onEditorChange(e) {
    console.log(e);
    this.setState({
      address: e.editor.getData()
    });
  }
  renderFooterAddress() {
    return (
      <React.Fragment>
           <div className="col-12 subject-description-container  d-flex align-items-center">
           <label htmlFor="defaultFormRegisterNameEx" className="subject-labels h-100">
                           Address:
              </label>
              <div className="descriptions-container">
              {this.renderFooterCK()}
              </div>
            </div>
      </React.Fragment>
    );
  }
renderFooterCK(){
    return ( <React.Fragment>
        <div className="d-flex align-items-start mt-3 mb-3">
     <div className="position-relative w-100 ml-3 mr-4">
     <CKEditor
data={this.state.address}
style={{width:'88%'}}
  onChange={this.onEditorChange} 
  className="textFontSize"/> 
      </div>
      </div>
      <div className="w-100" />
      </React.Fragment>
  )
}
  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={1} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div
                    className="col-12 top_button_bar d-flex"
                    style={{ alignItems: "center" }}
                  >
                    {/* <button className="admin-button-style admin-button-style-margin" onClick={()=>this.props.history.push('/admin/faculties')}>
                            All Faculties
                        </button> */}
                    <></>

                    <button
                      className="admin-button-style ml-auto"
                      onClick={this.editFooter}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Details for Footer</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="subject-content-container ml-4 "
                    style={{ width: "80%" }}
                  >
                    <div className="row px-0 mx-0 ">
                      {this.renderFooterAbout()}
                      {this.renderFooterAddress()}
                      {this.renderFooterEmailTutor()}
                      {this.renderFooterEmailSupport()}
                      {this.renderFooterPhone()}
                    </div>
                  </div>
                </div>

                <hr />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default editFooter;
