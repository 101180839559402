import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../Sidebar/sidebarPage";
import GridLoader from "react-spinners/GridLoader";

import swal from "sweetalert";
import "../Lectures/Addlecture.css";

import { css } from '@emotion/core';


const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:30% 45%;
`;


export default class UserSEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountTitle:'',
      details:[],
      accountDescription:'',
      cartTitle:'',
      cartDescription:'',
      purchaseTitle:'',
      purchaseDescription:'',
      courseTitle:'',
      courseDescription:''
    };
  }
  componentDidMount() {
  }
  componentWillMount() {
    // this.getVerticals();
    this.getUserSEO()
  }

  getUserSEO=async()=>{
    this.setState({
        loading:true
    })
  this.setState({
    response: fetch('https://www.vimasmiham.com/get_user_seo', {
     method: 'GET',
     headers: {
      
       'Content-Type': 'application/x-www-form-urlencoded',
     
    },
    
    
     
    }).then((response) => response.json())
    .then((responseJson)=>{
    console.log(responseJson,'clients')
      this.setState({
          accountTitle:responseJson[0].account_title_seo,
          accountDescription:responseJson[0].account_description_seo,
          courseTitle:responseJson[0].course_title_seo,
          courseDescription:responseJson[0].course_description_seo,
          cartTitle:responseJson[0].cart_title_seo,
          cartDescription:responseJson[0].cart_description_seo,
          purchaseTitle:responseJson[0].purchase_title_seo,
          purchaseDescription:responseJson[0].purchase_description_seo
          ,loading:false
      })
      
    }
    
    )
     .catch((error) => {
    this.setState({
      loading:false
    })
    alert("Warning!", "Check your network!", "warning");
    console.log(error)
      })
     })
  }

  renderAccountTitle = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Account Title:
                  </label>
                  <input    
                    value={this.state.accountTitle}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({accountTitle:text.target.value})}
                />
        </div>
    );
  };
  renderAccountDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Account Title:
                  </label>
                  <input    
                    value={this.state.accountDescription}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({accountDescription:text.target.value})}
                />
        </div>
    );
  };

  renderCourseTitle = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Course Title:
                  </label>
                  <input    
                    value={this.state.courseTitle}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({courseTitle:text.target.value})}
                />
        </div>
    );
  };
  renderCourseDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Course Description:
                  </label>
                  <input    
                    value={this.state.courseDescription}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({courseDescription:text.target.value})}
                />
        </div>
    );
  };
  renderCartTitle = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Cart Title:
                  </label>
                  <input    
                    value={this.state.cartTitle}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({cartTitle:text.target.value})}
                />
        </div>
    );
  };
  renderCartDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Cart Description:
                  </label>
                  <input    
                    value={this.state.cartDescription}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({cartDescription:text.target.value})}
                />
        </div>
    );
  };

  renderPurchaseTitle= () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Purchase Title:
                  </label>
                  <input    
                    value={this.state.purchaseTitle}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({purchaseTitle:text.target.value})}
                />
        </div>
    );
  };

  renderPurchaseDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
                  <label htmlFor="defaultFormRegisterNameEx" className="subject-name-label subject-labels">
                      Purchase Description:
                  </label>
                  <input    
                    value={this.state.purchaseDescription}
                    type="text"
                    id="defaultFormRegisterNameEx"
                    className="form-control subject-name-form custom-form"
                    onChange={(text)=>this.setState({purchaseDescription:text.target.value})}
                />
        </div>
    );
  };





 

  editUserSEO() {

  

    let formData = new FormData();
    formData.append("account_title_seo", this.state.accountTitle);
    formData.append("account_description_seo", this.state.accountDescription);
    formData.append("course_title_seo", this.state.courseTitle);
    formData.append("course_description_seo", this.state.courseDescription);
    formData.append("purchase_title_seo", this.state.purchaseTitle);
    formData.append("purchase_description_seo", this.state.purchaseDescription);
    formData.append("cart_title_seo",this.state.cartTitle)
    formData.append("cart_description_seo",this.state.cartDescription)
    formData.append("id",1)
    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_user_seo", {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "QWERTy");
          swal("Success", "User SEO added", "success");
          this.props.history.push("/admin/user-details");
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  //PART OF MEDIA COMPONENT
  handleMediaChange = item => {
    console.log(item, "handleMediaChange");
    this.setState({ inputLink: `https://www.vimasmiham.com/${item.file}` });
  };

  render() {
    console.log(this.state, "state");
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={11} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                
                      <div className="row my-3 px-4">
                      <div className="col-12 top_button_bar d-flex align-items-center">
                    {/* <button className="admin-button-style admin-button-style-margin" onClick={()=>this.props.history.push('/admin/lecture')}>
                        All Lectures
                    </button>
                    <MediaComponent onSelectMediaItem={this.handleMediaChooseImage} buttonName={<MDBIcon icon="camera" /> } restriction= 'image/*' />  */}
                    <button className="admin-button-style ml-auto" onClick={()=>this.editUserSEO()}> Save &amp; Publish</button>
                    </div>
                    <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> User Details SEO</h3> 
                    
                    </div>
                      </div>

                  <div className="d-flex">
                    <div className="subject-content-container ml-4 fit-content">
                      <div className="row px-0 mx-0">
                        {this.renderAccountTitle()}
                        {this.renderAccountDescription()}
                        {this.renderCartTitle()}
                        {this.renderCartDescription()}
                        {this.renderCourseTitle()}
                        {this.renderCourseDescription()}
                        {this.renderPurchaseTitle()}
                        {this.renderPurchaseDescription()}
                       
                      </div>
                    </div>
                    <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4 h-100">
                      {/* {this.renderChooseFileType()} */}
                 
                    </div>
                  </div>

                   {/* <button className='add-subject my-5 float-right mr-4' onClick={()=>this.addlectures()}>Save &amp; Publish</button>  */}
              
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
