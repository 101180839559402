import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import swal from "sweetalert";

import { MDBIcon } from "mdbreact";
import CKEditor from "ckeditor4-react";
import "./about-us.css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;
export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descriptions: [{ id: 1, title: "", description: "", image: "" }],
      pageName: "",
      page_description_seo: "",
      page_title_seo: "",
      loading: true,
    };

    //this.onEditorChange = this.onEditorChange.bind( this );
  }

  onEditorChange(e, id) {
    console.log(e);
    let { descriptions } = this.state;
    let description = descriptions.find((item) => item.id == id);
    description.description = e.editor.getData();
    this.setState(
      {
        descriptions,
      },
      () => console.log(this.state)
    );
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.getAboutUs();
  }

  getAboutUs() {
    fetch("https://www.vimasmiham.com/get_new_about_us", {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responsejson) => {
        console.log(responsejson);
        this.setState({
          aboutUs: responsejson,
          descriptions: JSON.parse(responsejson[0].descriptions),
          page_description_seo: responsejson[0].page_description_seo,
          page_title_seo: responsejson[0].page_title_seo,
          loading: false,
        });
      });
  }

  componentDidMount() {
    // this.setState({ loading: false });
  }

  addDescription() {
    let { descriptions } = this.state;
    descriptions.push({
      id: descriptions.length + 1,
      description: "",
    });
    this.setState({ descriptions });
  }

  removeDescription(index) {
    let { descriptions } = this.state;
    if (descriptions.length > 1) {
      descriptions.splice(index, 1);
    }
    for (var i = 0; i < descriptions.length; i++) {
      descriptions[i].id = i + 1;
    }
    this.setState({ descriptions });
  }

  handleTitleChange(e, id) {
    console.log(e);
    let { descriptions } = this.state;
    let description = descriptions.find((item) => item.id == id);
    description.title = e.target.value;
    this.setState(
      {
        descriptions,
      },
      () => console.log(this.state)
    );
  }

  handleLinkChange(e, id) {
    console.log(e);
    let { descriptions } = this.state;
    let description = descriptions.find((item) => item.id == id);
    description.image = e.target.value;
    this.setState(
      {
        descriptions,
      },
      () => console.log(this.state)
    );
  }

  addAboutUs() {
    let formData = new FormData();

    formData.append("about_us_id", 1);
    formData.append("descriptions", JSON.stringify(this.state.descriptions));
    formData.append("page_title_seo", this.state.page_title_seo);
    formData.append("page_description_seo", this.state.page_description_seo);
    formData.append("status", 1);

    this.setState({
      response: fetch("https://www.vimasmiham.com/add_new_about_us", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "QWERTy");
          swal("Success", "About us succesfullly updated", "success");
          this.props.history.push("/admin/about-us");
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  }

  renderAboutUsDescriptionContainer() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <div className="descriptions-container">
            {this.renderAboutUsDescription()}
            <button
              className="admin-button-style ml-30px float-right mr-4 mb-3"
              onClick={() => this.addDescription()}
            >
              Add para
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderAboutUsDescription() {
    return this.state.descriptions.map((item, index) => {
      return (
        <React.Fragment key={item.id}>
          <div className="col-12  subject-name-section border-0 d-flex about-us-title-container">
            <label
              htmlFor="defaultFormRegisterNameEx"
              className="subject-name-label subject-labels w-100"
            >
              {index + 1}.&nbsp; Section Heading:
            </label>
            <input
              value={item.title}
              type="text"
              id="defaultFormRegisterNameEx"
              className="form-control subject-name-form custom-form"
              onChange={(text) => this.handleTitleChange(text, item.id)}
            />
          </div>
          <div className="d-flex align-items-start  px-5 mt-3">
            <label className="p-1 d-none"> {index + 1}.&nbsp; </label>
            <div className="position-relative w-100 mr-4">
              <CKEditor
                data={item.description}
                onChange={(e) => this.onEditorChange(e, item.id)}
                className=""
              />

              <MDBIcon
                className="cross_icon"
                onClick={() => this.removeDescription(index)}
                icon="times-circle"
              />
            </div>
          </div>
          <div className="w-100" />
          <div className="d-flex align-items-start  px-5 mt-3 about-us-title-container">
            <label className="p-1"> Image: </label>
            <div className="position-relative w-100 mr-4">
              <input
                value={item.image}
                type="text"
                id="defaultFormRegisterNameEx"
                className="form-control subject-name-form custom-form w-100"
                onChange={(text) => this.handleLinkChange(text, item.id)}
              />
            </div>
          </div>
          <hr />
        </React.Fragment>
      );
    });
  }

  handleMediaChange = (item) => {
    console.log(item, "handleMediaChange");
    this.setState({ inputLink: `https://www.vimasmiham.com/${item.file}` });
  };

  renderAboutUsSEOTitle() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Page Title:
        </label>
        <input
          value={this.state.page_title_seo}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) =>
            this.setState({ page_title_seo: text.target.value })
          }
        />
      </div>
    );
  }

  renderAboutUsSEODescription() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Page Description:
        </label>
        <input
          value={this.state.page_description_seo}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) =>
            this.setState({ page_description_seo: text.target.value })
          }
        />
      </div>
    );
  }

  render() {
    console.log(this.state);
    return (
      <div id="add-subject-page" className="about-us-admin-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={3} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <MediaComponent
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    />
                    <button
                      className="admin-button-style"
                      onClick={() => this.addAboutUs()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                    {/* <MediaComponent onSelectMediaItem={this.handleMediaChooseImage} buttonName={<MDBIcon icon="camera" /> } restriction= 'image/*' />  */}
                  </div>

                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> About Us</h3>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="subject-content-container ml-4">
                    <div className="row px-0 mx-0">
                      {/* {this.renderPageNameSection()} */}

                      {this.renderAboutUsDescriptionContainer()}

                      <h4
                        className="mb-0 p-2 w-100"
                        style={{ backgroundColor: "#F9F9F9" }}
                      >
                        SEO
                      </h4>
                      {this.renderAboutUsSEOTitle()}
                      {this.renderAboutUsSEODescription()}
                    </div>
                  </div>
                  <div className="subject-select-fields-container ml-2 row align-self-start flex-column mr-4  ">
                    {/* {this.renderExamSection()}
                      {this.renderRelatedSubjectsSection()} */}
                  </div>
                </div>

                <hr />

                <button
                  className="add-subject my-5 float-right mr-4"
                  onClick={() => this.addAboutUs()}
                >
                  Save &amp; Publish
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
