import React from "react";
import "../../Components/Exam-News/Exam-news.css";
import {
  MDBIcon,
  MDBCollapse
} from "mdbreact";
import MetaTags from "react-meta-tags";
import '../../Components/Homepage/homepage.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from 'sweetalert'
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./faq.css";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import {Link} from 'react-router-dom'


const redirectButtons = {
  textTransform:'capitalize',
  borderRadius:'4px',
  fontSize: '16px',
  fontFamily: 'Ubuntu-M',
  color: '#908b8b'
}



class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainImage: "",
      mainTitle: "",
      faculties:[],
      mainText: "",
      objectiveImage: ``,
      faqTitle:'',
      faqDescription:'',
      objectiveText:'',
      objectiveTitle: "",
      missionText: "",
      missionImage: ``,
      missionTitle: "",
      visionTitle: "",
      visionText: "",
      visionImage: ``,faqs:[],cart:[],collapseID:0
    };
  }
  componentWillMount() {
   
      var userdata = window.localStorage.getItem("userdata");
      if (userdata != null) {
        this.setState({
          userdata: JSON.parse(userdata)
        },()=>this.getCart());
      }
    
    this.getFaqs();
  }
 
  toggleCollapse(id) {
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0
      });
    } else {
      this.setState({
        collapseID: id
      });
    }
  }
  getFaqSEO = async () => {
  
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_faq_seo", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "clients");
          this.setState({
            faqTitle: responseJson[0].faq_title_seo,
            faqDescription: responseJson[0].faq_description_seo,
            loading: false
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };

  getFaqs = () => {
  this.setState({
    loading:true
  })
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_faqs", {
        method: "GET",
        headers: {
                   
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson[0]);
var data = []
for(var v=0;v<responseJson.length;v++){
  if(responseJson[v].status==1){
    data.push(responseJson[v])
  }
}
          this.setState({
           faqs:data
           
          },()=>this.getFaqSEO());
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };
  next() {
    this.slider.slickNext();
  }

  prev() {
    this.slider.slickPrev();
  }
  renderObjectives(){

    return(
      <div className="row mission-row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">

      <img src={this.state.objectiveImage}  className='image-about-us'/>
 
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
     <p className='about-us-description-font'>{this.state.objectiveText}</p> 
      </div>
    </div>

    )
  }


  renderAboutNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p className="productMainFirstText">
         
        <span style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/')}  >Home</span> / FAQs
        </p>
      </div>
    );
  }
  

  renderVision(){

    return(
      <div className="row mission-row">
     
      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
     <p className='about-us-description-font'>{this.state.visionText}</p> 
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6 ">

<img src={this.state.visionImage}  className='image-about-us'/>

</div>
    </div>

    )
  }

  renderFaqs(){
    return this.state.faqs.map((faq,index) => {
    
      return ( <div key={index} className='ques-main'>
        <p className="faq-main-question"  onClick={()=>this.toggleCollapse(faq.faq_id)} >{faq.faq_question}
         <MDBIcon className='plus-icon-main' icon={faq.faq_id==this.state.collapseID?"minus":"plus"}  /></p> 
        <MDBCollapse
                  id={faq.faq_id}
                  isOpen={this.state.collapseID}
                 
                >

<p className="faq-answer">{faq.faq_answer}</p>
                </MDBCollapse> 
       
        </div>
      )
     }
    )
 }


 getCart = () => {
  let details = {
    user_id: this.state.userdata.user_id
  };

  console.log(details, "fetching-details");
  let formBody = [];
  for (let property in details) {
    let encodedKey = encodeURIComponent(property);
    let encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  this.setState({
    response: fetch("https://www.vimasmiham.com/get_cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000"
      },
      body: formBody
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, "cart");

        this.setState({
          cart: responseJson
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });

        console.log(error);
      })
  });
};



  render() {
    {console.log(this.state.faculties)}
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
        

        <NewHeader
            {...this.props}
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            active="My Dashboard"
          />
 
<div id="aboutUs">
          <div className="about-main ">
            <h1 className="about-us-heading">FAQs</h1>
          </div>

          <div>{this.renderAboutNavigation()}</div>
         

          

       
               
            <div className="faq-container py-3">
            <MetaTags>
                    <title>{this.state.faqTitle}</title>

                    <meta
                      id="home"
                      name="description"
                      content={this.state.faqDescription}
                    />

                    {/* <link rel="canonical" href="https://vimasmiham.com" /> */}
                  </MetaTags>

                  <div className="redirect-boxes d-none d-md-flex mt-3" style={{display: 'flex',justifyContent: 'center',flexWrap: 'wrap'}}>
                <Link className="btn" style={redirectButtons} to="/acts_and_regulations" >Acts &amp; Regulations</Link>
                <Link className="btn" style={redirectButtons} to="/categories/promotional-exams">Promotional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/categories/professional-exams" >Professional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/latest-news" >Latest News</Link>
                <Link className="btn" style={redirectButtons} to="/test-series" >Test Series</Link>
                <Link className="btn" style={redirectButtons} to="/all-faculty" >All Faculty</Link>
                <Link className="btn" style={redirectButtons} to="/upcoming-exams" >Upcoming Exams</Link>
            
            </div>

            <div className="row py-4 " style={{justifyContent:'space-between'}} >
                <div className="col-12 col-md-8 order-2 order-md-1 faq-content-column">
                  <div className="faqs-content">
                      {this.renderFaqs()}
                  </div>

                 
                </div>

                <div className="col-3 col-md-4 order-1 order-md-2 text-center text-md-right faq-im-con">
                  <img
                    className="faq-image faq-main-page-image img-fluid"
                    src="https://www.vimasmiham.com/FAQSImage.png"
                  />
                </div>
              </div>
            </div>       
          
                   
         

         

            <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
        </div>
        </div>
      );
    }
  }
}

export default FAQ;
