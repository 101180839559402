import React from "react";
import "./Exam-news.css";
import { MDBCardBody, MDBCardText, MDBCardTitle } from "mdbreact";

import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
// import {Link} from 'react-router-dom'
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";
import { MetaTags } from "react-meta-tags";

class ExamNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news_data: [],
      startDate: new Date(),
    };
  }
  componentWillMount() {
    this.getNewsUpdate();
  }

  getNewsUpdate = () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_upcoming_exams", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var response = responseJson.reverse();
          console.log(response, "news_data");
          this.setState(
            {
              news_data: response,
              news_data_date: response.creation_time,
            },
            () => this.getSEO()
          );
        }),
    });
  };

  getSEO = () => {
    fetch("https://vimasmiham.com/get_upcoming_exams_seo")
      .then((res) => res.json())
      .then(({ data }) =>
        this.setState({
          seo_title: data[0].seo_title,
          seo_description: data[0].seo_description,
          content: data[0].content,
          loading: false,
        })
      );
  };

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd;
  }

  commentMonth(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return monthNames[Number(dt.getMonth())];
  }

  commentYear(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth();
    console.log(dt.getMonth() + 1);
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dt.getFullYear().toString();
  }

  renderDate(item, v) {
    return (
      <div className="newsDateDisplay">
        <div className="newsDate">
          <span className="newsDate_font">
            {this.commentdate(item.upcoming_exam_date)}
          </span>
          <span className="newsMonth_font">
            {this.commentMonth(item.upcoming_exam_date).toUpperCase()}
          </span>
        </div>
        <div className="newsYear">
          <span className="newsYear_font">
            {this.commentYear(item.upcoming_exam_date)}
          </span>
        </div>
        {/* <text className='rotateText'>Upcoming Exam</text> */}
      </div>
    );
  }

  renderNews() {
    let today = new Date();
    return this.state.news_data
      .sort(function (x, y) {
        return new Date(x.upcoming_exam_date) - new Date(y.upcoming_exam_date);
      })
      .map((item, index) => {
        let examDate = new Date(item.upcoming_exam_date);
        // console.log('date stuff', `${examDate} is greater than today ${examDate >= today}`);
        if (item.status == 1 && examDate >= today) {
          return (
            <div>
              <MDBCardBody className="newsDivPosition">
                <div className="news_justify_content">
                  <div className="NewsDivWidth">
                    <MDBCardTitle tag="h5">
                      {this.renderDate(item, index)}
                    </MDBCardTitle>
                  </div>

                  <div className="NewsDivWidth-title-description">
                    <MDBCardTitle
                      tag="h4"
                      style={{ color: "#303030" }}
                      className="newsHeadingFont"
                    >
                      {item.upcoming_exam_title.toUpperCase()}
                    </MDBCardTitle>

                    <MDBCardText
                      style={{ color: "#303030" }}
                      className="newsText"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.upcoming_exam_description,
                        }}
                      ></div>
                    </MDBCardText>
                  </div>
                </div>{" "}
              </MDBCardBody>
            </div>
          );
        }
      });
  }

  renderExamNavigation() {
    return (
      <div style={{ width: "100%" }} className="mt-2">
        <p style={{ fontSize: "14px", margin: "0% 0% 0% 8%" }}>
          {" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => this.props.history.push("/")}
            on
          >
            Home
          </span>{" "}
          / Upcoming Exams
        </p>
      </div>
    );
  }

  renderExamTab() {
    return (
      <div className="news-tab-btn-div d-flex">
        <button className="btn-news-half"></button>
        <button className="btn-news" onClick={() => this.selectExams()}>
          <span className="btn-news-text"> IRDAI</span>{" "}
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Industry</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Exams</span>
        </button>
        <button className="btn-news">
          <span className="btn-news-text">Others</span>
        </button>
        <button className="btn-news-half"></button>
      </div>
    );
  }

  renderContent() {
    return (
      <div
        className="container-c my-5"
        dangerouslySetInnerHTML={{ __html: this.state.content }}
      />
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <MetaTags>
            <title>{this.state.seo_title}</title>

            <meta name="description" content={this.state.seo_description} />
          </MetaTags>

          <div className="">
            <NewHeader
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div>

          <div className="exam-news-main mb-5">
            <div className="w-10 d-flex justify-content-center News-heading">
              <h1 className="exam-news-heading-font">Upcoming Exams</h1>
            </div>

            <div>{this.renderExamNavigation()}</div>

            <Quicklinks />

            <div className="d-none">{this.renderExamTab()}</div>

            <div>{this.renderContent()}</div>

            <div>{this.renderNews()}</div>
          </div>

          <div className="windowView">
            <MainFooterPage />
          </div>

          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}

export default ExamNews;
