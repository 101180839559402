import React from "react";
import Logo from "../../Assets/images/logo.png";
import "./newHeader.css";
import WishlistPNG from "../../Assets/images/wishlist.png";
import NotifPNG from "../../Assets/images/notification.png";
import CartPNG from "../../Assets/images/cart.png";
import UserPNG from "../../Assets/images/dp1.png";
import TogglePNG from "../../Assets/images/Toggle2.png";
import LeftArrow from "../../Assets/images/left-arrow.png";
import RightArrow from "../../Assets/images/buttons-arrow.png";
import "../../Components/commonStyles.css";
import ExamPNG from "../../Assets/images/exams-toggle.png";
import swal from "sweetalert";
import "../../Components/HomeHeader/header.css";
import "../../index.css";
import { ButtonToolbar } from "react-bootstrap";
import { css } from "@emotion/core";
import Fade from "react-reveal";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import {
  MDBIcon,
  MDBCollapse,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import GoogleImg from "../../Assets/images/google.png";
import FbImg from "../../Assets/images/facebook.png";
import { matchPath } from "react-router-dom";
import Searchbar from "../Searchbar/Searchbar";
import { Link } from "react-router-dom";

class NewHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
      header_data: [],
      menu_data: [],
      email: "",
      password: "",
      clicked: [],
      activeIndex: 0,
      login: false,
      forgetPassword: false,
      userdata: null,
      otp: false,
      menu_selected: false,
      social_user: null,
      type: 1,
      buttons: [
        {
          name: "My Account",
          url: "/my-account",
        },
        {
          name: "My Cart",
          url: "/my-cart",
        },
        {
          name: "My Courses",
          url: "/my-courses",
        },
      ],
      menuItems: [
        {
          itemName: "Home",
          url: "/",
        },
        {
          itemName: "Blog",
          url: "/blog",
        },
        {
          itemName: "Exams",
          subItems: true,
        },
        {
          itemName: "Online MCQs",
          url: "/test-series",
        },
        {
          itemName: "Case Laws",
          url: "/case-laws",
        },
        {
          itemName: "News",
          url: "/latest-news",
        },
        {
          itemName: "III Licentiate Exam",
          url: "/exams/licentiate",
        },
        {
          itemName: "Associateship Exams",
          url: "/exams/associateship",
        },
        {
          itemName: "Fellowship Exams",
          url: "/exams/fellowship",
        },
      ],
      activeButton: props.active,
      userImage: `${UserPNG}`,
      userName: `Priyanka Singh`,
      userEmail: `priyankasingh@gmail.com`,
      viewForm: "",
      userdata: null,
      login: props.showLogin,
      forgetPassword: false,
      render_menu: false,
      otp: false,
      email: "",
      password: "",
      loading: false,
      examsMenu: [],
      verticalsMenu: [],
      showSubmenu: false,
    };
  }

  componentWillMount() {
    this.getDetails();
    this.getSubjects();
  }

  componentDidMount() {
    this.setState({ viewForm: "My Account" });
    this.getExams();
    this.getVerticals();
  }

  componentWillReceiveProps({ showLogin }) {
    this.setState({ ...this.state, login: showLogin });
  }

  getVerticals = () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            // verticals:responseJson.reverse(),
            verticalsMenu: responseJson.reverse(),
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getExams = async () => {
    console.log("fetching exams");
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            examsMenu: responseJson.reverse(),
          });
        })
        .catch((error) => {
          console.log(error);
        }),
    });
  };

  openHeaderMobileNav() {
    this.setState({
      render_menu: !this.state.render_menu,
    });
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  closeHeaderMobileNav() {
    this.setState({
      render_menu: false,
    });
  }

  setActiveButton(data) {
    this.setState({ activeButton: data.name }, () =>
      this.props.history.push(data.url)
    );
  }

  renderButtons() {
    return this.state.buttons.map((data, index) => {
      return index <= 4 ? (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {data.name}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      ) : (
        ""
      );
    });
  }

  renderMobileButtons() {
    return this.state.buttons.map((data, index) => {
      return index !== 4 ? (
        <button
          className={
            this.state.activeButton === data.name
              ? "navigation-button bg-active"
              : "navigation-button"
          }
          value={data.name}
          onClick={() => this.setActiveButton(data)}
          key={index}
        >
          {data.name}{" "}
          <span className="">
            <img className="arrow-image d-none d-sm-block" src={RightArrow} />
          </span>
        </button>
      ) : (
        <React.Fragment>
          <button
            className={
              this.state.activeButton === data.name
                ? "navigation-button bg-active"
                : "navigation-button"
            }
            value={data.name}
            onClick={() => this.setActiveButton(data)}
            key={index}
          >
            {data.name}{" "}
            <span className="">
              <img className="arrow-image d-none d-sm-block" src={RightArrow} />
            </span>
          </button>
          <hr className="mobile-menu-items-divider" />
        </React.Fragment>
      );
    });
  }

  renderProfilePic() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }
  renderProfilePic2() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          className="user-img cursor-pointer mr-2 avatar navBarDP"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          className="user-img cursor-pointer mr-2 avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }
  // logout(){
  //   window.localStorage.removeItem('userdata')
  //   window.location.reload()
  // }
  renderMenu() {
    if (this.state.menu_selected) {
      return (
        <div className="headerToggleMenu" style={{ position: "absolute" }}>
          <div
            className="rowDefault"
            style={{ alignItems: "center", marginBottom: 10, paddingLeft: 20 }}
          >
            {this.renderProfilePic()}
            <div className="colDefault">
              <text>{this.state.userdata.name}</text>
              <text className="email-text-profile-menu">
                {this.state.userdata.email}
              </text>
            </div>
          </div>
          <p
            onClick={() => this.props.history.push("/my-account")}
            className="profile-menu-list"
          >
            My Account
          </p>
          <p
            onClick={() => this.props.history.push("/my-courses")}
            className="profile-menu-list"
          >
            My Courses
          </p>
          <p
            onClick={() => this.props.history.push("/my-cart")}
            className="profile-menu-list"
          >
            My Cart
          </p>
          <p
            onClick={() => this.props.history.push("/purchase-history")}
            className="profile-menu-list"
          >
            Purchase History
          </p>

          <hr />
          {/* <p className="profile-menu-list">Help</p> */}
          <p
            style={{ marginBottom: 20 }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p>
        </div>
      );
    }
  }
  getSubjects = async () => {
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            subjects: responseJson,

            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata),
      });
    }
  }

  toggle_edit = () => {
    this.setState({
      login: !this.state.login,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.LoginForm();
    }
  };

  renderModal() {
    if (this.state.login) {
      return (
        <MDBModal isOpen={this.state.login} toggle={this.toggle_edit}>
          <MDBModalHeader toggle={this.toggle_edit}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              Log in to Your Vima Smiham Account
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="socialButtonDiv">
                <FacebookLogin
                  appId="169569684487644"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={this.responseFacebook}
                  render={(renderProps) => (
                    <button
                      className="socialIconbutton1"
                      onClick={renderProps.onClick}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={FbImg}
                          alt="google-logo"
                          className="logoHeight"
                          height="20px"
                        />
                      </div>
                      <span style={{ float: "left", marginLeft: "10%" }}>
                        {" "}
                        Continue with Facebook
                      </span>
                    </button>
                  )}
                />
              </div>

              <div className="socialButtonDiv">
                <GoogleLogin
                  clientId="854571101897-u01v27l8i6b06fo6okvi0tt5akkq5hck.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className="socialIconbutton2"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={GoogleImg}
                          alt="google-logo"
                          className="logoHeight-margin"
                          height="20px"
                        />
                      </div>{" "}
                      <span
                        style={{
                          float: "left",
                          marginLeft: "7%",
                          color: "#4A4A4A",
                        }}
                      >
                        {" "}
                        Continue with Google
                      </span>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseFailureGoogle}
                  cookiePolicy={"single_host_origin"}
                  // onLogoutSuccess={logout}
                />
              </div>
              <form onSubmit={() => this.LoginForm()} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input
                    placeholder="Email or Phone"
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff",
                    }}
                    type="Email"
                    onChange={(text) =>
                      this.setState({ email1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff",
                    }}
                    placeholder="Password"
                    type="password"
                    onChange={(text) =>
                      this.setState({ password1: text.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <button
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      backgroundColor: "#dbaa34",
                      border: "1.5px solid #dbaa34",
                      color: "#fff",
                    }}
                    className="ButtonLogIn"
                    type="button"
                    onClick={() => this.LoginForm()}
                  >
                    Log in
                  </button>

                  <p
                    className="forgetPassword"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A",
                    }}
                  >
                    <text>or </text>
                    <span style={{ color: "#0093D9" }}>
                      <a
                        onClick={() =>
                          this.setState({ login: false, forgetPassword: true })
                        }
                      >
                        <text>Forgot Password</text>
                      </a>
                    </span>
                  </p>
                  <p
                    className="dontHaveAccount"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A",
                    }}
                  >
                    Don't have a account ?{" "}
                    <a
                      onClick={() =>
                        this.setState({ login: false }, () =>
                          this.props.history.push("/sign-up")
                        )
                      }
                    >
                      <span style={{ color: "#0093D9" }}>Sign Up</span>
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }
  renderExamsMenu() {
    if (this.state.showSubmenu) {
      return (
        <Fade>
          {" "}
          <div className="exam-submenu-container">
            <div className="row_reverse">
              <div
                className="cross_div"
                id="up"
                onClick={() => this.setState({ showSubmenu: false })}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={require("../../Assets/images/cross.png")}
                  className="cross"
                  alt="cross"
                />
              </div>

              <div className="exam_menu_logo d-none">
                <img src={Logo} alt="Vima Logo" className="exams_menu_logo" />
              </div>
            </div>
            <div className="exam-submenu">
              {this.state.verticalsMenu
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((item, index) => {
                  if (item.status == 1)
                    return (
                      <ul className="px-0 w-25" key={index}>
                        {item.vertical_name}
                        <hr
                          className="mt-1 mx-0 w-50"
                          style={{ border: "1px solid #d2d2d2" }}
                        />
                        {this.renderVerticalsSubMenu(
                          item.vertical_id,
                          item.url
                        )}
                      </ul>
                    );
                })}
            </div>
          </div>
        </Fade>
      );
    } else {
      return null;
    }
  }

  renderVerticalsSubMenu(vertical_id, url) {
    return this.state.examsMenu
      .filter((item) => item.status == 1)
      .map((examItem) => {
        var exam_verticals = JSON.parse(examItem.verticals);
        return exam_verticals.map((item, index) => {
          if (item.vertical_id === vertical_id && examItem.status == 1) {
            return (
              <>
                <li
                  key={index}
                  className="d-flex align-items-center justify-content-betweeen"
                  style={{ marginBottom: "1rem" }}
                >
                  <Link
                    to={`/categories/${url}`}
                    onClick={() => this.selectVertical(item, index, url)}
                  >
                    <span>{examItem.exam_name} </span>
                  </Link>
                  <MDBIcon
                    icon="plus"
                    className="iconPlus"
                    onClick={this.toggleCollapse(examItem.exam_name)}
                    color="primary"
                    style={{ marginLeft: "0.5rem" }}
                  />
                </li>
                <MDBCollapse
                  id={examItem.exam_name}
                  isOpen={this.state.collapseID}
                >
                  <MDBContainer className="submenu-subject-list-container">
                    <ul className="pl-0">
                      {this.state.subjects == null
                        ? "Loading.."
                        : this.state.subjects.map((item, index) => {
                            return this.renderSubjectsInExams(
                              item,
                              examItem.exam_id
                            );
                          })}
                    </ul>
                  </MDBContainer>
                </MDBCollapse>
              </>
            );
          }
        });
      });
  }
  renderSubjectsInExams = (item, exam_id) => {
    if (item.status == 1) {
      let subjectExams = JSON.parse(item.exams);
      let exam_ids = [];
      subjectExams.forEach((element) => {
        exam_ids.push(element.exam_id);
      });
      if (exam_ids.includes(exam_id)) {
        return (
          <li
            className="border-bottom pb-2 w-75 pt-2 ml-3"
            //  onClick={() => this.setSubject(item)}
          >
            <Link
              onClick={() => this.setSubject(item)}
              to={`/courses/${item.url}`}
            >
              {item.subject_name}{" "}
            </Link>
          </li>
        );
      }
    }
  };
  setSubject = (item) => {
    this.props.history.push(`/courses/${item.url}`);
  };
  selectVertical(item, index, url) {
    // window.localStorage.removeItem("selected_vertical");
    // window.localStorage.removeItem("collapse");
    // window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    // window.localStorage.setItem('collapse',0)

    this.props.history.push(`/categories/${url}`);
    window.location.reload();
  }

  handleClick(index, e) {
    let clicked = this.state.clicked;
    clicked[index] = !clicked[index];
    this.setState({ clicked: clicked });
  }
  handleOutsideClick(event) {
    if (!this.refs.megaMenu.contains(event.target)) {
      this.setState({
        clicked: [],
      });
    }
  }

  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Password and confirm Password not Match!", {
        className: "red-bg",
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
      otp: this.state.otp,
      password: this.state.newPassword,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: false,
              forgetPassword: false,
              login: true,

              // add_modal:false
            });
            swal(
              "Password changed!",
              "Your password has been reset. Please Login!",
              "success"
            );
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  forgotPassword = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: true,
              forgetPassword: false,

              // add_modal:false
            });
            swal(
              "OTP Verification!",
              "An OTP has been sent to your registered email!",
              "info"
            );
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  responseFacebook = (response) => {
    this.setState(
      {
        social_user: {
          email: response.email,
          name: response.name,
          profile_pic: response.picture.data.url,
        },
        type: 3,
      },
      () => this.socialLogin()
    );
  };

  responseGoogle = (response) => {
    this.setState(
      {
        social_user: {
          email: response.profileObj.email,
          name: response.profileObj.name,
          profile_pic: response.profileObj.imageUrl,
          type: this.state.type,
        },
        type: 2,
      },
      () => this.socialLogin()
    );
  };
  responseFailureGoogle = (response) => {};

  socialLogin = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.social_user.email,
      name: this.state.social_user.name,
      profile_pic: this.state.social_user.profile_pic,
      type: this.state.type,
      status: 1,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/social_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            window.location.reload();
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  LoginForm = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email1,
      password: this.state.password1,
      type: 1,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/user_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            if (matchPath(this.props.location.pathname, "/sign-up")) {
              this.props.history.push("/");
            } else {
              window.location.reload();
            }
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal({ text: responseJson.message, className: "Login-alert-form" });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  toggle_edit11 = () => {
    this.setState({
      otp: !this.state.otp,
    });
  };

  renderModalOtp() {
    if (this.state.otp) {
      return (
        <MDBModal isOpen={this.state.otp} toggle={this.toggle_edit11}>
          <MDBModalHeader toggle={this.toggle_edit11}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              One Time Password{" "}
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div style={{ textAlign: "center", margin: "3%" }}>
                <text>Set New Password</text>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  placeholder="OTP"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) => this.setState({ otp: text.target.value })}
                />
              </div>

              <div style={{ width: "100%" }} className="d-none">
                <input
                  placeholder="Email or Phone"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="Email"
                  onChange={(text) =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter New Password"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) =>
                    this.setState({ newPassword: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter Password Again"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) =>
                    this.setState({ ConfirmNewPassword: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#FF8500",
                    color: "#fff",
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.handleOtp()}
                >
                  Change Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  toggle_edit1 = () => {
    this.setState({
      forgetPassword: !this.state.forgetPassword,
    });
  };

  renderModalForget() {
    if (this.state.forgetPassword) {
      return (
        <MDBModal isOpen={this.state.forgetPassword} toggle={this.toggle_edit1}>
          <MDBModalHeader toggle={this.toggle_edit1}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              Forgot Password
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "5%" }}>
                <text>Please Enter Your Email </text>
              </div>
              <div style={{ width: "100%", marginBottom: "2%" }}>
                <input
                  placeholder="Email"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="Email"
                  onChange={(text) =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: ".5px solid #E5E5E5",
                    backgroundColor: "#FF8500",
                    color: "#fff",
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.forgotPassword()}
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  renderModals() {
    return this.state.verticalsMenu.map((modal, index) => {
      if (modal.status == 1) {
        return (
          <div style={{ cursor: "pointer" }}>
            <Link
              to={`/categories/${modal.url}`}
              key={index}
              onClick={() => this.selectVertical(modal, index, modal.url)}
              className="mobile-sidebar-exams"
            >
              {modal.vertical_name}
            </Link>

            {/* <p className="mobile-sidebar-exams">{modal.vertical_name}</p> */}
          </div>
        );
      }
    });
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  renderMobileMenuitems() {
    return this.state.menuItems.map((item, index) => {
      return !item.subItems ? (
        <Link
          to={item.url}
          className="profile-menu-list"
          style={{ color: "white", marginBottom: 10 }}
          variant=""
          key={index}
        >
          {" "}
          {item.itemName}{" "}
        </Link>
      ) : (
        <>
          <p
            color="#0093d9"
            onClick={this.toggleCollapse(index)}
            className="profile-menu-list"
            style={{
              color: "white",
              marginBottom: this.state.collapseID === index ? 0 : 10,
            }}
          >
            {item.itemName}&nbsp;{" "}
            <MDBIcon
              className={
                this.state.collapseID === index
                  ? "rotateIcon_new menu_ic"
                  : "rotateIcon_new2 menu_ic"
              }
              icon="chevron-down"
            />
          </p>
          <MDBCollapse
            className="collapse_m"
            id={index}
            isOpen={this.state.collapseID}
            style={{ backgroundColor: "rgb(41, 128, 169)" }}
          >
            {this.renderModals()}
          </MDBCollapse>
        </>
      );
    });
  }

  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata),
      });
    }
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            cart: responseJson,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  logout() {
    window.localStorage.clear();
    this.props.history.push("/");
    window.location.reload();
  }
  renderLogOut() {
    if (this.state.userdata == null) {
      return <div className="button-mobile-side-bar"></div>;
    } else {
      return (
        <div className="headerToggleMenu side-bar-mobile-help-loin-top">
          <hr />
          <p
            style={{ color: "white", marginBottom: 10 }}
            className="profile-menu-list d-none"
          >
            Help
          </p>
          <p
            style={{ marginBottom: "10px", color: "white" }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p>
        </div>
      );
    }
  }

  renderMenu1() {
    if (this.state.userdata == null) {
      return <></>;
    } else {
      return (
        <div className="headerToggleMenu">
          <div
            className="rowDefault"
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginRight: "0px",
            }}
          >
            <div>{this.renderProfilePic2()} </div>
            <div className="colDefault">
              <text className="side-bar-name-email">
                {this.state.userdata.name}
              </text>
              <text className="side-bar-name-email">
                {this.state.userdata.email}
              </text>
            </div>
          </div>
          <p
            style={{ color: "white", marginBottom: 10 }}
            onClick={() => this.props.history.push("/my-account")}
            className="profile-menu-list"
          >
            My Account
          </p>
          <p
            style={{ color: "white", marginBottom: 10 }}
            onClick={() => this.props.history.push("/my-courses")}
            className="profile-menu-list"
          >
            My Courses
          </p>
          <p
            style={{ color: "white", marginBottom: 10 }}
            onClick={() => this.props.history.push("/purchase-history")}
            className="profile-menu-list"
          >
            Purchase History
          </p>
        </div>
      );
    }
  }
  renderButtonsLogin() {
    if (this.state.userdata == null) {
      return (
        <div className="button-mobile-side-bar">
          <button
            style={{ color: "white", padding: "0px 0px 0px 10px !important" }}
            onClick={() =>
              this.setState({ render_menu: false }, this.toggle_edit)
            }
            id="login-button-mobile"
          >
            Log In
          </button>
          <button
            style={{ color: "white" }}
            onClick={() => this.props.history.push("/sign-up")}
            id="register-button-mobile"
          >
            Register
          </button>
          {/* <button style={{marginLeft:'50px'}} className="header-options"> <img src={PinterestLogo} alt="pinterest logo" /> </button> */}
        </div>
      );
    }
  }

  renderProfilePic() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }

  renderLogin() {
    if (this.state.userdata == null) {
      return (
        <div className="col-3 text-right py-3 login-column padding-right-80">
          <a
            onClick={this.toggle_edit}
            className="header-options"
            id="login-button"
          >
            Log In
          </a>
          <a
            onClick={() => this.props.history.push("/sign-up")}
            className="header-options mr-4"
            id="register-button"
          >
            Register
          </a>
          <a href="https://www.facebook.com/vimasmiham/">
            <button className="header-options-fb">
              <MDBIcon fab icon="facebook-f" />
            </button>
          </a>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative", paddingRight: "10px" }}>
          <div className="rowDefault justify-content-between align-items-center icon-div-home2 ">
            {/* <img
              className="wishlist-img cursor-pointer mr-2"
              src={WishlistPNG}
            /> */}
            <a
              href="https://www.facebook.com/vimasmiham/"
              target="_blank"
              style={{ color: "unset" }}
            >
              <button className="header-options-fb">
                <MDBIcon fab icon="facebook-f" />
              </button>
            </a>
            <div
              onClick={() => this.props.history.push("/my-cart")}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <img
                className="cart-img cursor-pointer mr-2"
                style={{ position: "relative" }}
                src={CartPNG}
              />
              {this.props.cart == 0 ? null : (
                <span aria-label="2 items" class="badge">
                  {this.props.cart}
                </span>
              )}
            </div>
            <img
              className="notification-img cursor-pointer mr-2"
              src={NotifPNG}
            />
            {this.renderProfilePic()}
          </div>
          {this.renderMenu()}
        </div>
      );
    }
  }

  renderMobileCartAndFav() {
    if (this.state.userdata == null) {
      //     return( <div >
      //     {/* <a onClick={this.toggle_edit} className="header-options" id="login-button">Log In</a>
      //     <a onClick={()=>this.props.history.push('/sign-up')} className="header-options mr-4" id="register-button">Register</a>
      //     <button className="header-options"> <img src={PinterestLogo} alt="pinterest logo" /> </button> */}
      // </div>)
    } else {
      return (
        <div className="mobile-header-icons-container ">
          <div className="mobile-wishlist-container mobile-icon">
            <img className="wishlist-img cursor-pointer" src={WishlistPNG} />
          </div>
          <div
            onClick={() => this.props.history.push("my-cart")}
            style={{ cursor: "pointer" }}
            className="mobile-icon mobile-cart-icon-container"
          >
            <img
              className="cart-img cursor-pointer"
              style={{ position: "relative" }}
              src={CartPNG}
            />
            <span aria-label="2 items" class="cart-badge">
              {this.props.cart}
            </span>
          </div>
          <div className="mobile-wishlist-container mobile-icon">
            <img
              className="notification-img cursor-pointer"
              src={NotifPNG}
              style={{ width: "17px" }}
            />
          </div>
          {/* {this.renderProfilePic()} */}

          {/* {this.renderMenu()} */}
        </div>
      );
    }
  }

  checklogin = () => {
    return (
      <div className="col-7 text-center exam-input-column">
        <button
          className="exam-toggle"
          onClick={() =>
            this.setState({ showSubmenu: !this.state.showSubmenu })
          }
        >
          <img src={ExamPNG} />
          &nbsp;&nbsp;&nbsp;<span id="exam-toggle-text">Exams</span>
        </button>
        <div className="banner-text-container" style={{ position: "initial" }}>
          <Searchbar showIcon="none" {...this.props} />
        </div>
      </div>
    );
  };
  renderoverLay() {
    if (this.state.render_menu) {
      return (
        <div
          id="overlay"
          onClick={() => this.setState({ render_menu: false })}
        ></div>
      );
    }
  }
  renderMenudata() {
    if (this.state.render_menu) {
      return (
        <ScrollAnimation
          className="header-mobile-nav"
          animateIn="slideInLeft"
          style={{
            opacity: 1,
            width: 250,
            minHeight: "100vh",
            position: "fixed",
            top: 0,
            zIndex: 9999,
          }}
        >
          <div id="header-mobile-nav">
            <div style={{ paddingTop: "35px" }}>{this.renderMenu1()}</div>
            <button
              class="close-mob-menu-button btn-custom"
              onClick={() => this.closeHeaderMobileNav()}
            >
              <img src={LeftArrow} alt="Close Account Menu" />
            </button>
            {/* {this.renderMobileButtons()} */}
            <ButtonToolbar className="mobile-menu-button-toolbar">
              {this.renderMobileMenuitems()}

              {this.renderLogOut()}
              {this.renderButtonsLogin()}
            </ButtonToolbar>
          </div>
        </ScrollAnimation>
      );
    }
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    //   if(this.state.loading){
    //     return(
    //     <div >

    //     </div>)
    // }
    // else{
    return (
      <React.Fragment>
        <div className="header border-bottom display-header px-5">
          <div
            className="row align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="col-2 logo-container px-3 text-left pt-1 pb-3"
              style={{ padding: "1.2rem", paddingTop: "0.30rem" }}
            >
              <img
                style={{ cursor: "pointer", position: "relative" }}
                className="new-header-logo"
                src={Logo}
                onClick={() => this.props.history.push("/")}
              />
            </div>
            {this.checklogin()}

            {this.renderLogin()}
          </div>
        </div>

        <div className="mobile-header">
          <div className="row align-items-center py-2 border-bottom h-100 mobile-header-padding">
            <div className="col-1 pl-0 ml-2" style={{ cursor: "pointer" }}>
              <button
                style={{ cursor: "pointer" }}
                className="menu-toggle btn-custom bg-transparent"
                onClick={() => this.openHeaderMobileNav()}
              >
                <img src={TogglePNG} />
              </button>
            </div>
            <div
              className={
                this.state.userdata != null
                  ? "col-2 mobile-header-logo-div"
                  : "mobile-header-logo-div2"
              }
            >
              <img
                src={Logo}
                className="new-header-logo"
                onClick={() => this.props.history.push("/")}
              />
            </div>
            <div
              className="col-7 px-0 "
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {this.renderMobileCartAndFav()}
            </div>
          </div>
        </div>
        {this.renderMenudata()}

        {this.renderModal()}
        {this.renderModalForget()}
        {this.renderModalOtp()}
        {this.renderExamsMenu()}
        {this.renderoverLay()}
      </React.Fragment>
    );
  }
}
//}

export default NewHeader;
