import React, { Component } from "react";
import "./header.css";
import "../../index.css";
import MobMenuBar from "../../Reusable/NewHeader/newHeader";
import Hamburger from "../../Assets/images/hamburger-menu.svg";
import { Button, ButtonToolbar, Dropdown } from "react-bootstrap";
import "../../Reusable/NewHeader/newHeader.css";
import swal from "sweetalert";
import DropCaret from "../../Assets/images/dropdown-caret.png";
import Fade from "react-reveal";
import {
  MDBIcon,
  MDBCollapse,
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
} from "mdbreact";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import GoogleImg from "../../Assets/images/google.png";
import FbImg from "../../Assets/images/facebook.png";
import WishlistPNG from "../../Assets/images/wishlist.png";
import NotifPNG from "../../Assets/images/notification.png";
import CartPNG from "../../Assets/images/cart.png";
import Loader from "react-spinners/GridLoader";
import { Link, Redirect } from "react-router-dom";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tagline: "Your tagline will be here",
      header_data: [],
      menu_data: [],
      email: "",
      password: "",
      clicked: [],
      activeIndex: 0,
      collapseID: "",
      login: false,
      logoHeight: false,
      forgetPassword: false,
      socail_user: null,
      type: 1,
      userdata: null,
      otp: false,
      subjects: null,
      userdata: null,
      menu_selected: false,
      menuItems: [
        {
          itemName: "About",
        },
        {
          itemName: "Appraisal of Insurance Sector",
        },
        {
          itemName: "About III",
        },
        {
          itemName: "About IRDAI",
        },
        {
          itemName: "Exams",
          subItems: true,
        },
        {
          itemName: "Acts & Regulations",
        },
        {
          itemName: "Study Materials",
        },
        {
          itemName: "Test Series",
        },
      ],
      examsMenu: props.examsMenu,
      verticalsMenu: props.verticalsMenu,
      cart: [],
      examsMenu: [],
      verticalsMenu: [],
      showSubmenu: false,
    };

    this.myMobRef = this.r;
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount() {
    console.log("will mount");
  }

  componentDidMount() {
    console.log("did mount");
    this.getOfflineMenu();
  }

  componentWillReceiveProps(props) {
    this.setState({
      examsMenu: props.examsMenu,
      verticalsMenu: props.verticalsMenu.reverse(),
      subjects: props.headerSubjects,
      userdata: props.userdata,
    });
  }

  componentWillUnmount() {}

  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  isViewable = (url) => {
    if (url.login_required == 1 && this.state.userdata == null) {
      return "d-none";
    } else {
      return "";
    }
  };

  renderRightMenuItems() {
    return this.state.menu_data.map((item, index) => {
      console.log(item, "menudata");
      return index >= this.state.menu_data.length / 2 ? (
        item.sub_items == 0 ? (
          <Link
            to={`/${item.page_url}`}
            key={index}
            className={this.isViewable(item)}
            style={{ textTransform: "capitalize" }}
          >
            {item.name}
          </Link>
        ) : (
          <a
            className=""
            onClick={() =>
              this.setState({ showSubmenu: !this.state.showSubmenu })
            }
          >
            {" "}
            {item.name} <img className="drowpdown-caret-icon" src={DropCaret} />{" "}
          </a>
        )
      ) : (
        ""
      );
    });
  }

  renderLeftMenuItems() {
    console.log(this.state.menu_data, "menudata");
    return this.state.menu_data.map((item, index) => {
      return index < this.state.menu_data.length / 2 ? (
        item.sub_items == 0 ? (
          <Link
            to={`/${item.page_url}`}
            key={index}
            className={this.isViewable(item)}
            style={{ textTransform: "capitalize" }}
          >
            {item.name}
          </Link>
        ) : (
          <a className="" onClick={() => this.setState({ showSubmenu: true })}>
            {" "}
            {item.name} <img className="drowpdown-caret-icon" src={DropCaret} />{" "}
          </a>
        )
      ) : (
        ""
      );
    });
  }

  renderMobileMenuitems() {
    console.log(this.state.menu_data);
    return this.state.menu_data.map((item, index) => {
      return item.sub_items == 0 ? (
        <Button
          onClick={() => this.props.history.push("/" + item.page_url)}
          variant=""
          key={index}
        >
          {item.name}
        </Button>
      ) : (
        <Dropdown>
          <Dropdown.Toggle variant="" id="dropdown-basic">
            {item.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {/* {item.subItems.map((subItem, index)=>{
                return <Dropdown.Item href="">{subItem.name}</Dropdown.Item>
            })} */}
          </Dropdown.Menu>
        </Dropdown>
      );
    });
  }

  // componentDidMount() {
  //   // this.getHeader()
  //   this.setState({
  //     examsMenu: this.props.examsMenu,
  //     verticalsMenu: this.props.verticalsMenu.reverse()
  //   });
  // console.log(this.state.examsMenu)

  getSubjects = async () => {
    this.setState({
      subjectLoading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            subjects: responseJson,

            subjectLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getOfflineMenu() {
    var menu_items = window.localStorage.getItem("menu_items");
    console.log(menu_items);
    if (menu_items) {
      this.setState({ menu_data: JSON.parse(menu_items) });
    } else {
      this.getMenu();
    }
    this.getMenu();
  }

  getMenu = async () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_menu", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          responseJson.sort((a, b) => {
            return a.sort_order - b.sort_order;
          });
          window.localStorage.removeItem("menu_items");
          window.localStorage.setItem(
            "menu_items",
            JSON.stringify(responseJson)
          );

          this.setState({
            menu_data: responseJson,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  handleClick(index, e) {
    let clicked = this.state.clicked;
    clicked[index] = !clicked[index];
    this.setState({ clicked: clicked });
  }

  // handleOutsideClick() {}

  handleOtp = () => {
    const { newPassword, ConfirmNewPassword } = this.state;
    // perform all neccassary validations
    if (newPassword !== ConfirmNewPassword) {
      swal("Password and confirm Password not Match!", {
        className: "red-bg",
      });
    } else {
      this.otpSet();
    }
  };

  otpSet = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
      otp: this.state.otp,
      password: this.state.newPassword,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: false,
              forgetPassword: false,
              login: true,

              // add_modal:false
            });
            swal(
              "Password changed!",
              "Your password has been reset. Please Login!",
              "success"
            );
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  forgotPassword = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email11,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/forgot_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              otp: true,
              forgetPassword: false,

              // add_modal:false
            });
            swal(
              "OTP Verification!",
              "An OTP has been sent to your registered email!",
              "info"
            );
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
            // this.props.history.push('/product')
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  responseFacebook = (response) => {
    console.log(response);
    this.setState(
      {
        social_user: {
          email: response.email,
          name: response.name,
          profile_pic: response.picture.data.url,
        },
        type: 3,
      },
      () => this.socialLogin()
    );
  };

  responseGoogle = (response) => {
    console.log(response, "oooo");
    this.setState(
      {
        social_user: {
          email: response.profileObj.email,
          name: response.profileObj.name,
          profile_pic: response.profileObj.imageUrl,
          type: this.state.type,
        },
        type: 2,
      },
      () => this.socialLogin()
    );
  };

  responseFailureGoogle = (response) => {
    console.log(response, "oooo");
  };

  socialLogin = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.social_user.email,
      name: this.state.social_user.name,
      profile_pic: this.state.social_user.profile_pic,
      type: this.state.type,
      status: 1,
    };
    console.log(details, "add-without photo");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/social_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "123");
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            window.location.reload();
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });

            swal("Warning!", responseJson.message, "warning");
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };
  LoginForm = () => {
    this.setState({
      loading: true,
    });

    let details = {
      // product_id:this.state.selected_products.product_id,

      email: this.state.email1,
      password: this.state.password1,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/user_login", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status == 200) {
            this.setState({
              loading: false,
              // add_modal:false
            });
            window.localStorage.removeItem("userdata");
            window.localStorage.setItem(
              "userdata",
              JSON.stringify(responseJson)
            );
            window.location.reload();
          } else {
            this.setState({
              loading: false,
              // add_modal:false
            });
            swal({ text: responseJson.message, className: "Login-alert-form" });
          }
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  toggle_edit11 = () => {
    this.setState({
      otp: !this.state.otp,
    });
  };

  renderModalOtp() {
    if (this.state.otp) {
      return (
        <MDBModal isOpen={this.state.otp} toggle={this.toggle_edit11}>
          <MDBModalHeader toggle={this.toggle_edit11}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              One Time Password{" "}
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div style={{ textAlign: "center", margin: "3%" }}>
                <text>Set New Password</text>
              </div>
              <div style={{ width: "100%" }}>
                <input
                  placeholder="OTP"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) => this.setState({ otp: text.target.value })}
                />
              </div>

              <div style={{ width: "100%" }} className="d-none">
                <input
                  placeholder="Email or Phone"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="Email"
                  onChange={(text) =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter New Password"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) =>
                    this.setState({ newPassword: text.target.value })
                  }
                />
              </div>

              <div style={{ width: "100%" }}>
                <input
                  placeholder="Enter Password Again"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="text"
                  onChange={(text) =>
                    this.setState({ ConfirmNewPassword: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#FF8500",
                    color: "#fff",
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.handleOtp()}
                >
                  Change Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  toggle_edit1 = () => {
    this.setState({
      forgetPassword: !this.state.forgetPassword,
    });
  };

  renderModalForget() {
    if (this.state.forgetPassword) {
      return (
        <MDBModal isOpen={this.state.forgetPassword} toggle={this.toggle_edit1}>
          <MDBModalHeader toggle={this.toggle_edit1}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              Forgot Password
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                margin: "5%",
              }}
            >
              <div style={{ textAlign: "center", marginBottom: "5%" }}>
                <text>To Get Your Password Please Enter Your Email </text>
              </div>
              <div style={{ width: "100%", marginBottom: "2%" }}>
                <input
                  placeholder="Email"
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: "1.5px solid #E4E4E4",
                    backgroundColor: "#fff",
                  }}
                  type="Email"
                  onChange={(text) =>
                    this.setState({ email11: text.target.value })
                  }
                />
              </div>

              <div>
                <button
                  style={{
                    width: "80%",
                    margin: "1% 0% 2% 10%",
                    height: "45px",
                    borderRadius: "5px",
                    border: ".5px solid #E5E5E5",
                    backgroundColor: "#FF8500",
                    color: "#fff",
                  }}
                  className="ButtonLogIn"
                  type="button"
                  onClick={() => this.forgotPassword()}
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }

  validateEmail = (text) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  validateCompany = (text) => {
    var reg = /^[\w]+([-_\s]{1}[a-z0-9]+)*$/i;

    if (reg.test(text) === false) {
      this.setState({ company: text, valid_company: false });

      return false;
    } else {
      this.setState({ company: text, valid_company: true });
    }
  };

  //  toggle_edit = () => {

  //     this.setState({
  //       login: !this.state.login
  //     });
  //    }

  // renderLogoHeight(){
  //   if(this.state.logoHeight){
  //     return(
  //     <div>
  //      {console.log('sdd')}
  //     </div>
  //     )
  // }
  // else{
  //   return(
  // <div>
  // {console.log('sdd1212')}
  //     </div>
  //   )
  // }
  // }

  toggle_edit = () => {
    this.setState({
      login: !this.state.login,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.LoginForm();
    }
  };

  renderModal() {
    if (this.state.login) {
      return (
        <MDBModal isOpen={this.state.login} toggle={this.toggle_edit}>
          <MDBModalHeader toggle={this.toggle_edit}>
            <span
              style={{
                fontFamily: "Ubuntu-r",
                fontSize: "18px",
                color: "#4A4A4A",
              }}
            >
              Log in to Your Vima Smiham Account
            </span>{" "}
          </MDBModalHeader>
          <MDBModalBody>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="socialButtonDiv">
                <FacebookLogin
                  appId="169569684487644"
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={componentClicked}
                  callback={this.responseFacebook}
                  render={(renderProps) => (
                    <button
                      className="socialIconbutton1"
                      onClick={renderProps.onClick}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={FbImg}
                          alt="google-logo"
                          className="logoHeight"
                          height="20px"
                        />
                      </div>
                      <span style={{ float: "left", marginLeft: "10%" }}>
                        {" "}
                        Continue with Facebook
                      </span>
                    </button>
                  )}
                />
              </div>

              <div className="socialButtonDiv">
                <GoogleLogin
                  clientId="854571101897-u01v27l8i6b06fo6okvi0tt5akkq5hck.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button
                      className="socialIconbutton2"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <div style={{ float: "left", marginLeft: "5%" }}>
                        <img
                          src={GoogleImg}
                          alt="google-logo"
                          className="logoHeight"
                          height="20px"
                        />
                      </div>{" "}
                      <span
                        style={{
                          float: "left",
                          marginLeft: "7%",
                          color: "#4A4A4A",
                        }}
                      >
                        {" "}
                        Continue with Google
                      </span>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseFailureGoogle}
                  cookiePolicy={"single_host_origin"}
                  // onLogoutSuccess={logout}
                />
              </div>
              <form onSubmit={() => this.LoginForm()} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input
                    placeholder="Email or Phone"
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff",
                    }}
                    type="Email"
                    onChange={(text) =>
                      this.setState({ email1: text.target.value })
                    }
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      border: "1.5px solid #E4E4E4",
                      backgroundColor: "#fff",
                    }}
                    placeholder="Password"
                    type="password"
                    onChange={(text) =>
                      this.setState({ password1: text.target.value })
                    }
                    onKeyDown={this.handleKeyDown}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <button
                    style={{
                      width: "80%",
                      margin: "1% 0% 2% 10%",
                      height: "45px",
                      borderRadius: "5px",
                      backgroundColor: "#dbaa34",
                      border: "1.5px solid #dbaa34",
                      color: "#fff",
                    }}
                    className="ButtonLogIn"
                    type="button"
                    onClick={() => this.LoginForm()}
                  >
                    Log in
                  </button>

                  <p
                    className="forgetPassword"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A",
                    }}
                  >
                    <text>or </text>
                    <span style={{ color: "#0093D9" }}>
                      <a
                        onClick={() =>
                          this.setState({ login: false, forgetPassword: true })
                        }
                      >
                        <text>Forgot Password</text>
                      </a>
                    </span>
                  </p>
                  <p
                    className="dontHaveAccount"
                    style={{
                      width: "80%",
                      fontFamily: "Ubuntu-r",
                      fontSize: "16px",
                      color: "#4A4A4A",
                    }}
                  >
                    Don't have a account ?{" "}
                    <a
                      onClick={() =>
                        this.setState({ login: false }, () =>
                          this.props.history.push("/sign-up")
                        )
                      }
                    >
                      <span style={{ color: "#0093D9" }}>Sign Up</span>
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </MDBModalBody>
        </MDBModal>
      );
    }
  }
  getDetails() {
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState({
        userdata: JSON.parse(userdata),
      });
    }
  }
  getCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            cart: responseJson,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };
  logout() {
    window.localStorage.clear();
    window.location.reload();
  }
  renderMenu() {
    if (this.state.menu_selected) {
      return (
        <div className="headerToggleMenu" style={{ position: "absolute" }}>
          <div
            className="rowDefault"
            style={{ alignItems: "center", marginBottom: 10, paddingLeft: 20 }}
          >
            {this.renderProfilePic2()}
            <div className="colDefault">
              <text>{this.state.userdata.name}</text>
              <text className="email-text-profile-menu">
                {this.state.userdata.email}
              </text>
            </div>
          </div>
          <p
            onClick={() => this.props.history.push("/my-account")}
            className="profile-menu-list"
          >
            My Account
          </p>
          <p
            onClick={() => this.props.history.push("/my-courses")}
            className="profile-menu-list"
          >
            My Courses
          </p>
          <p
            onClick={() => this.props.history.push("/my-cart")}
            className="profile-menu-list"
          >
            My Cart
          </p>
          <p
            onClick={() => this.props.history.push("/purchase-history")}
            className="profile-menu-list"
          >
            Purchase History
          </p>

          <hr />
          {/* <p className="profile-menu-list">Help</p> */}
          <p
            style={{ marginBottom: 20 }}
            className="profile-menu-list"
            onClick={() => this.logout()}
          >
            Log Out
          </p>
        </div>
      );
    }
  }
  renderProfilePic() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          onClick={() =>
            this.setState({ menu_selected: !this.state.menu_selected })
          }
          className="user-img cursor-pointer mr-2 avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }
  renderProfilePic2() {
    if (this.state.userdata.profile_pic == null) {
      return (
        <img
          className="user-img cursor-pointer mr-2 avatar"
          src={
            this.state.userdata.gender == "Male"
              ? require("../../Assets/images/male.png")
              : require("../../Assets/images/female.png")
          }
        />
      );
    } else {
      return (
        <img
          className="user-img cursor-pointer mr-2 avatar"
          src={this.state.userdata.profile_pic}
        />
      );
    }
  }
  renderLogin() {
    if (this.state.userdata == null) {
      return (
        <div className="col-lg-6 text-right py-3 login-column padding-right-80">
          <a
            onClick={this.toggle_edit}
            className="header-options"
            id="login-button"
          >
            Log In
          </a>
          <a
            onClick={() => this.props.history.push("/sign-up")}
            className="header-options mr-4"
            id="register-button"
          >
            Register
          </a>
          <a
            href="https://www.facebook.com/vimasmiham/"
            target="_blank"
            style={{ color: "unset" }}
          >
            <button className="header-options-fb">
              <MDBIcon fab icon="facebook-f" />
            </button>
          </a>
        </div>
      );
    } else {
      return (
        <div style={{ position: "relative", paddingRight: "80px" }}>
          <div className="rowDefault justify-content-between align-items-center icon-div-home2 ">
            {/* <img
              className="wishlist-img cursor-pointer mr-2"
              src={WishlistPNG}
            /> */}
            <a
              href="https://www.facebook.com/vimasmiham/"
              target="_blank"
              style={{ color: "unset" }}
            >
              <button className="header-options-fb">
                <MDBIcon fab icon="facebook-f" />
              </button>
            </a>
            <div
              onClick={() => this.props.history.push("my-cart")}
              style={{ cursor: "pointer", position: "relative" }}
            >
              <img
                className="cart-img cursor-pointer mr-2"
                style={{ position: "relative" }}
                src={CartPNG}
              />
              {this.props.cart == 0 ? null : (
                <span aria-label="2 items" class="badge">
                  {this.props.cart}
                </span>
              )}
            </div>
            <img
              className="notification-img cursor-pointer mr-2"
              src={NotifPNG}
            />
            {this.renderProfilePic()}
          </div>
          {this.renderMenu()}
        </div>
      );
    }
  }

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  renderExamsMenu() {
    if (this.state.showSubmenu) {
      return (
        <Fade>
          <div className="exam-submenu-container">
            <div className="row_reverse">
              <div
                className="cross_div"
                id="up"
                onClick={() => this.setState({ showSubmenu: false })}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={require("../../Assets/images/cross.png")}
                  className="cross"
                  alt="cross"
                />
              </div>

              <div className="exam_menu_logo">
                <img
                  src="https://www.vimasmiham.com/HomeLogo.png"
                  alt="Vima Logo"
                  className="exams_menu_logo"
                />
              </div>
            </div>
            <div className="exam-submenu">
              {this.state.verticalsMenu
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((item, index) => {
                  if (item.status == 1)
                    return (
                      <ul className="px-0 w-25" key={index}>
                        {item.vertical_name}
                        <hr
                          className="mt-1 mx-0 w-50"
                          style={{ border: "1px solid #d2d2d2" }}
                        />
                        {this.renderVerticalsSubMenu(
                          item.vertical_id,
                          item.url
                        )}
                      </ul>
                    );
                })}
            </div>
          </div>
        </Fade>
      );
    } else {
      return null;
    }
  }

  renderVerticalsSubMenu(vertical_id, url) {
    return this.state.examsMenu.map((examItem) => {
      var exam_verticals = JSON.parse(examItem.verticals);
      return exam_verticals.map((item, index) => {
        if (item.vertical_id === vertical_id && examItem.status == 1) {
          return (
            <>
              <li
                key={index}
                className="d-flex align-items-center justify-content-betweeen"
                style={{ marginBottom: "1rem" }}
              >
                <Link
                  to={`/categories/${url}`}
                  onClick={() => this.selectVertical(item, index, url)}
                >
                  <span>{examItem.exam_name} </span>
                </Link>
                <MDBIcon
                  icon="plus"
                  className="iconPlus"
                  onClick={this.toggleCollapse(examItem.exam_name)}
                  color="primary"
                  style={{ marginLeft: "0.5rem" }}
                />
              </li>
              <MDBCollapse
                id={examItem.exam_name}
                isOpen={this.state.collapseID}
              >
                <MDBContainer className="submenu-subject-list-container">
                  <ul className="pl-0">
                    {this.state.subjects == null
                      ? "Loading.."
                      : this.state.subjects.map((item, index) => {
                          if (item.status == 1) {
                            return this.renderSubjectsInExams(
                              item,
                              examItem.exam_id
                            );
                          }
                        })}
                  </ul>
                </MDBContainer>
              </MDBCollapse>
            </>
          );
        }
      });
    });
  }
  renderSubjectsInExams = (item, exam_id) => {
    if (this.state.subjectLoading) {
      return (
        <Loader
          type="Bars"
          color="rgb(0, 147, 217)"
          height={80}
          width={80}
          timeout={200000} //3 secs
          className="svg-component"
        />
      );
    } else {
      let subjectExams = JSON.parse(item.exams);
      let exam_ids = [];
      subjectExams.forEach((element) => {
        exam_ids.push(element.exam_id);
      });
      if (exam_ids.includes(exam_id)) {
        return (
          <li className="border-bottom pb-2 w-75 pt-2 ml-3">
            <Link to={`/courses/${item.url}`}>{item.subject_name} </Link>
          </li>
        );
      }
    }
  };
  // setSubject = item => {
  //   console.log(item);
  //   window.localStorage.removeItem("selected_subject");
  //   window.localStorage.setItem("selected_subject", JSON.stringify(item));
  //   this.props.history.push("/product-details");
  // };

  selectVertical(item, index, url) {
    item.collapse = index + 1;

    window.localStorage.removeItem("selected_vertical");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    if (this.props.location.pathname.indexOf("/categories") >= 0) {
      document.location.reload();
    } else {
      this.props.history.push(`/categories/${url}`);
    }
  }

  // handleOutsideClick(){
  //   if(this.state.showSubmenu==true){
  //     this.setState({showSubmenu:false})
  //   }
  // }

  render() {
    console.log(this.state);
    return (
      <React.Fragment>
        <div>
          <div className="windowView-home">
            <div className="top-header row mx-0 top-icons-container-home  ">
              <div className="d-none d-lg-block col-lg-6"></div>

              {this.renderLogin()}
            </div>

            <div className="menu-header bg-menu">
              <div className="row mx-0">
                <div className="col-5 left-menu-options padding-left-80 pr-3 d-none d-lg-flex order-xl-1 order-md-2 justify-content-between">
                  {this.renderLeftMenuItems()}
                </div>

                <div className="col-4 col-lg-2 text-left text-xl-center order-xl-2 order-md-1 pl-3">
                  <img
                    src="https://www.vimasmiham.com/HomeLogo.png"
                    alt="Vima Logo"
                    className="logo"
                  />
                </div>

                <div className="col-5 right-menu-options  pl-3 padding-right-80 d-none d-lg-flex order-xl-3 order-md-3 justify-content-between">
                  {this.renderRightMenuItems()}
                </div>

                <div className="hamburger-menu col-8 d-flex d-lg-none text-right order-4">
                  <div className="col">
                    <img
                      src={Hamburger}
                      alt="menu"
                      className="hamburger-image"
                      onClick={() => this.openNav()}
                    />
                  </div>
                </div>

                <div id="mySidenav" className="sidenav">
                  <a
                    href="javascript:;"
                    className="closebtn"
                    onClick={() => this.closeNav()}
                  >
                    &times;
                  </a>
                  <ButtonToolbar className="mobile-menu-button-toolbar">
                    {this.renderMobileMenuitems()}
                  </ButtonToolbar>
                </div>
              </div>
              {this.renderModal()}
              {this.renderModalForget()}
              {this.renderModalOtp()}
              {this.renderExamsMenu()}
              {/* {this.renderLogoHeight()} */}
            </div>
          </div>
          <div className="vsMobView-home">
            <MobMenuBar
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
