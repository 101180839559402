import React from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem,MDBNavbarBrand,MDBIcon } from "mdbreact";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import './adminNavbar.css';


class AdminNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state={}
    
      }
    logout(){
        window.localStorage.clear()
        this.props.history.push('/login')
      }

      componentDidMount(){
     
    }
    getDetails(){
        var admindata= window.localStorage.getItem('admindata')
        if(admindata==null){
            this.props.history.push('/login')
        }
    }
    render(){
        return(
            <div className="textFontSize">
                <MDBNavbar style={{backgroundColor:'#303336', color:'white'}} dark expand="md">
                <MDBNavbarBrand style={{display:'flex',alignItems:'center',padding:5,margin:3}}>
                 <img src="https://www.vimasmiham.com/logo.png" alt="vima-logo" className="adminLogoHeight"/>
                </MDBNavbarBrand>
                  <MDBNavbarNav right className="pr-5"> 
                    <MDBNavItem onClick={()=> this.logout()}>
                      
                        <MDBIcon icon="sign-out-alt" size="lg"/>
                       
                    </MDBNavItem>
                  </MDBNavbarNav>
                
              </MDBNavbar>



              <div className="floating-media-button " >
              <MediaComponent
           
           onSelectMediaItem={this.handleMediaChooseImage}
           buttonName={<MDBIcon icon="camera" />}
           restriction="image/*"
         />
              </div>
            </div>
        )
    }
  
}

export default AdminNavbar;
