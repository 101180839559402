import React, { Component } from "react";
import SidebarPage from "../Sidebar/sidebarPage";
import {
  MDBTable,
  MDBTableBody,
} from "mdbreact";
import { css } from "@emotion/core";
//import {MDBInput,MDBTable,MDBTableBody,MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter  } from "mdbreact";
import GridLoader from "react-spinners/GridLoader";
import AdminNavbar from "../Navbar/adminNavbar";
import "../UserDetail/userDetail.css";
import swal from "sweetalert";
import ActivePNG from "../../../Assets/images/activestatus.png";
import InactivePNG from "../../../Assets/images/inactivestatus.png";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;
class Faculty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showAction: false,
      draft_count: 0,
      published_count: 0,
      trash_count: 0,
      all_selected: false,
      faculties:[],
      draft_selected: false,
      facultyDescription:'',
      facultyTitle:'',
      published_selected: true,
      trash_selected: false,
      selected_data: []
    };
  }
  componentWillMount() {
    this.getFacultySEO()
    this.setState({ loading: true });
    this.getFaculty()
    
  }
  getFacultySEO(){
      this.setState({
        loading: true
      });
      this.setState({
        response: fetch("https://www.vimasmiham.com/get_faculty_seo", {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => response.json())
          .then(responseJson => {
            console.log(responseJson, "clients");
            this.setState({
              facultyTitle: responseJson[0].faculty_title_seo,
              facultyDescription: responseJson[0].faculty_description_seo,
            });
          })
          .catch(error => {
            this.setState({
              loading: false
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          })
      });
  }
  getFaculty=async()=>{
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
        response: fetch("https://www.vimasmiham.com/get_our_faculty", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson);
          var draft_count=0,published_count=0,trash_count=0
                for(var v=0;v<responseJson.length;v++){
                   
                    responseJson[v].selected = 0
                    if(responseJson[v].status==0){
                      draft_count=draft_count+1
                    }
                    else if(responseJson[v].status==1){
                      published_count=published_count+1
                                        }
                    else if(responseJson[v].status==2){
                      trash_count=trash_count+1
                         }
                    }
                    this.setState({
                      faculties:responseJson,
                      draft_count:draft_count,
                      published_count:published_count,
                      trash_count:trash_count,
                      loading:false
                      })

          console.log(this.state);
        })
        .catch(error => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  componentDidMount() {
    window.localStorage.removeItem("subject_item");
  }

  handleChangeAddName = e => {
    this.setState({ name: e.target.value });
  };
  handleChangeAddLink = e => {
    this.setState({ website_link: e.target.value });
  };

  handleChangeAddLogo = e => {
    this.setState({ logo: e.target.files[0] });
  };


  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec"
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  commenttime(timestamp) {
    var convertdLocalTime = new Date(timestamp);
    var hours = convertdLocalTime.getHours();
    var minutes = convertdLocalTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  handleEditFaculty(item) {
    window.localStorage.setItem("faculty", JSON.stringify(item));
    window.localStorage.setItem(
      "faculty_list",
      JSON.stringify(this.state.faculties)
    );
    this.props.history.push("/admin/edit-faculty");
  }

  handleAddNewSubject() {
    window.localStorage.setItem(
      "subject_list",
      JSON.stringify(this.state.subjects)
    );
    this.props.history.push("/admin/add-lecture");
  }

  renderActions(item, index) {
    if (item.selected == 1) {
      if (this.state.draft_selected ) {
        return (
          <div style={{ width: "15%" }} className="actions_div">
            {" "}
            <span
              onClick={() => this.restoreAlert(item)}
              className="actionstext"
            >
              Restore
            </span>
            {" "}
            {/* <span
              onClick={() => this.deleteAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Delete
            </span> */}
          </div>
        );
      } else {
        return (
          <div className="actions_div">
            <span
              onClick={() => this.handleEditFaculty(item, index)}
              className="actionstext"
            >
              Edit
            </span>{" "}
            {" "}
            {/* <span
              onClick={() => this.trashAlert(item)}
              className="actionstext"
              style={{ color: "#a00" }}
            >
              Trash
            </span>{" "} */}
          </div>
        );
      }
    } else {
      return (
        <div className="actions_div" style={{ visibility: "hidden" }}>
          <span className="actionstext">Edit</span> {" "}
          {/* <span className="actionstext" style={{ color: "#a00" }}>
            Trash
          </span>{" "} */}
        </div>
      );
    }
  }
  showactionbutton(item, index) {
    let { faculties } = this.state;
    let selected_page = faculties[index];
    for (var v = 0; v < faculties.length; v++) {
     faculties[v].selected = 0;
    }
    selected_page.selected = 1;
    this.setState({
      faculties
    });
  }
  hideactionbutton() {
    let { faculties } = this.state;

    for (var v = 0; v < faculties.length; v++) {
      faculties[v].selected = 0;
    }

    this.setState({
      faculties
    });
  }
  renderStatus(item) {
    if (item.status == 0) {
      return "Last Modified";
    } else {
      return "Published";
    }
  }
  trashAlert = item => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to move this page to trash",
      buttons: true,
      dangerMode: true
    }).then(willAdd => {
      if (willAdd) {
        this.trashPage(item);
      } else return;
    });
  };
  deleteAlert = item => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to delete this page",
      buttons: true,
      dangerMode: true
    }).then(willAdd => {
      if (willAdd) {
        this.deletePage(item);
      } else return;
    });
  };
  restoreAlert = item => {
    console.log(item, "xxx");
    swal({
      title: "Are you sure?",
      text: "You want to restore this page",
      buttons: true,
      dangerMode: true
    }).then(willAdd => {
      if (willAdd) {
        this.restorePage(item);
      } else return;
    });
  };
  trashPage(item) {
    this.setState({
      loading: true
    });
    let details = {
      id: item.subject_id
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("http://18.221.183.249:3000/trash_content", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          window.location.reload();
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }

  changeStatus(item, status) {
  
    let details = {
      id: item.id,
      status: status
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({

      response: fetch("https://www.vimasmiham.com/edit_lecture", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000"
        },
        body: formBody
      })
        .then(response => response.json())
        .then(responseJson => {
          window.location.reload();
        })
        .catch(error => {
          this.setState({
            loading: false
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }
  restorePage(item) {
       let details = {
       faculty_id:item.faculty_id,
     };
     console.log(details,'details')
     let formBody = [];
     for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
     }
     formBody = formBody.join("&");
     this.setState({
      response: fetch('https://www.vimasmiham.com/restore_faculty', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
      },
     body:formBody
      }).then((response) => response.json())
      .then((responseJson)=>{
           window.location.reload()
      }
      )
        .catch((error) => {
       this.setState({
         loading:false
       })
       swal("Warning!", "Check your network!", "warning");
     console.log(error)
         })
        })
  }

  renderActiveStatus(item) {
    if (item.status == 1) {
      return (
        <img
          src={ActivePNG}
          alt="Course is active"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 0)}
        />
      );
    } else {
      return (
        <img
          src={InactivePNG}
          alt="Course is INactive"
          className="course-status-icon"
          onClick={() => this.changeStatus(item, 1)}
        />
      );
    }
  }

  renderTableData(item, index) {
    if (this.state.all_selected == true) {
      if (item.status != 2) {
        console.log(this.state.all_selected, item.status);
        return (
          <tr key={item.faculty_id}>
            <td>
              <input
                onChange={() => this.onToggle(index)}
                checked={item.checked == 1 ? true : false}
                type="checkbox"
              />
            </td>
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "Ubuntu-B", color: "   #0073aa" }}
                >
                  {item.faculty_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td className="">
              {/* {this.renderStatus(item)} <br />{" "} */}
              <span dangerouslySetInnerHTML={{__html:item.faculty_profession}}  className="">
                {/* {this.commentdate(item.creation_time)} */}
              </span>
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.published_selected == true) {
      if (item.status == 1) {
        return (
          <tr key={item.subject_id}>
            <td>
            <input
                onChange={() => this.onToggle(index)}
                checked={item.checked == 1 ? true : false}
                type="checkbox"
              />
            </td>
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                >
                  {item.faculty_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td className="datefontsize">
              {/* {this.renderStatus(item)} <br />{" "} */}
              <span dangerouslySetInnerHTML={{__html:item.faculty_profession}}  className="" />
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.draft_selected == true) {
      if (item.status == 0) {
        return (
          <tr key={item.subject_id}>
            <td>
            <input
                onChange={() => this.onToggle(index)}
                checked={item.checked == 1 ? true : false}
                type="checkbox"
              />
            </td>
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                <span
                  style={{ fontFamily: "Ubuntu-B", color: "#0073aa" }}
                >
                  {item.faculty_name}
                </span>
                {this.renderActions(item, index)}
              </div>
            </td>
            <td className="datefontsize">
              {/* {this.renderStatus(item)} <br />{" "} */}
              <span dangerouslySetInnerHTML={{__html:item.faculty_profession}}  className="" />
            </td>
            {/* <td>{this.renderActiveStatus(item)}</td> */}
          </tr>
        );
      }
    } else if (this.state.trash_selected == true) {
      if (item.status == 2) {
        return (
          <tr key={item.subject_id}>
            <td>
              <input className="checkbox_content" type="checkbox" />
            </td>
            <td style={{ width: "25%" }} className="tdName textFontSize">
              <div
                onMouseEnter={() => this.showactionbutton(item, index)}
                onMouseLeave={() => this.hideactionbutton()}
                className="name_div_table"
              >
                 <span dangerouslySetInnerHTML={{__html:item.faculty_description}}  className="" />
                {this.renderActions(item, index)}
              </div>
            </td>
            <td className="datefontsize">
              {/* {this.renderStatus(item)} <br />{" "} */}
              <span className="tabledate">
                {/* {this.commentdate(item.creation_time)} */}
                {item.faculty_description}
              </span>
            </td>
          </tr>
        );
      }
    }
  }

  onToggle(index, e) {
    console.log(index, "kjkk");
    let { faculties, selected_data } = this.state;
    let selected_item = faculties[index];
    if (selected_item.checked == 1) {
      selected_item.checked = 0;
      selected_data.splice(index, 1);
    } else {
      selected_item.checked = 1;
      selected_data.push(selected_item);
    }

    this.setState({ selected_data });
    this.setState({ faculties });
  }

  handleAllChecked = event => {
    let { subjects } = this.state;
    if (this.state.item_selected) {
      for (var v = 0; v < subjects.length; v++) {
        subjects[v].checked = 1;
      }
      this.setState({ selected_data: subjects });
    } else {
      for (var v = 0; v < subjects.length; v++) {
        subjects[v].checked = 0;
      }
      this.setState({ selected_data: [] });
    }
    this.setState({ subjects });
  };

  deleteItems(){
    let data = this.state.selected_data;
    let facultyIds = []
    data.forEach(element => {
        let faculty = new Object();
        faculty.faculty_id = element.faculty_id;
        facultyIds.push(faculty)
    });

    let formData = new FormData();
    formData.append('faculty_ids',JSON.stringify(facultyIds));
    console.log(facultyIds)
    fetch("https://www.vimasmiham.com/trash_faculty", {
      method:'POST',
      body:formData
    })
    .then(()=>{
      window.location.reload()
    })
    .catch(error=>window.location.reload())
  }

  renderFacultySEOTitle(){
    return(
      <div className="col-12  subtitle-section d-flex mt-3 ">
           <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2 mr-5">
                Faculty SEO Title:
           </label>
           <input
              
              value={this.state.facultyTitle}
                  type="text"
                  id="defaultFormRegisterNameEx"
                  className="form-control subject-subtitle-form custom-form ml-4"
                  onChange={(text)=>this.setState({facultyTitle:text.target.value})}
              />
      </div>
  )
  }
  editFacultySEO() {

    let formData = new FormData();
    formData.append("faculty_title_seo", this.state.facultyTitle);
    formData.append("faculty_description_seo", this.state.facultyDescription);
    formData.append("id",1)
    this.setState({
      response: fetch("https://www.vimasmiham.com/edit_faculty_seo", {
        method: "POST",
        body: formData
      })
        .then(response => response.json())
        .then(responseJson => {
          console.log(responseJson, "QWERTy");
          swal("Success", "Faculty SEO succesfullly added", "success");
          this.props.history.push("/admin/faculties");
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  }
  renderFacultySEODescription(){
    return(
      <div className="col-12  subtitle-section d-flex mt-3 mr-5 ">
           <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2">
                Faculty SEO Description:
           </label>
           <input
              
              value={this.state.facultyDescription}
                  type="text"
                  id="defaultFormRegisterNameEx"
                  className="form-control subject-subtitle-form custom-form ml-4"
                  onChange={(text)=>this.setState({facultyDescription:text.target.value})}
              />
      </div>
  )
  }
  renderFacultySEOSave(){
  
     return(
      <div className="col-12  subtitle-section d-flex mt-3 mr-5 ">
           <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2">
                
           </label>
           <button className="  custom-form  admin-button-style mb-5 " style={{marginLeft:'200px'}} onClick={()=>this.editFacultySEO()}> Save &amp; Publish</button>
      </div>
  )
  }

  render() {
   console.log(this.state)
      return (
        <div id="subject-page-list">
          <AdminNavbar {...this.props} />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <SidebarPage {...this.props} active={11} />

            <div
              className="colDefault"
              style={{ width: "100%", backgroundColor: "#F9F9F9" }}
            >
               {this.state.loading?
                     (
                    <div className='sweet-loading'>
                        <GridLoader
                        css={override}
                        sizeUnit={"px"}
                        size={5}
                        color={'#2fb2eb'}
                        loading={this.state.loading}
                        />
                        </div>
                    )
                    :
                    (
             <React.Fragment>
               {console.log(this.state)}
              <div className="page_heading">
                <h3 style={{ marginBottom: 0 }}>Team Vima Smiham </h3>
                <span
                  onClick={() =>this.props.history.push("/admin/add-faculty")}
                  className="add_new_button"
                >
                  Add New
                </span>
              </div>
              <div>
                <ul className="subsubsub">
                  <li className="all d-none">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: true,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: false
                        })
                      }
                      className={
                        this.state.all_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                      aria-current="page"
                    >
                      All <span className="count">    ({this.state.draft_count+this.state.published_count})</span>
                    </span>
                    |
                  </li>

                  <li className="publish">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: true,
                          trash_selected: false,
                          draft_selected: false
                        })
                      }
                      className={
                        this.state.published_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      Actvie <span className="count">({this.state.published_count})</span>
                    </span>
                    |
                  </li>

                  <li className="draft">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: false,
                          trash_selected: false,
                          draft_selected: true
                        })
                      }
                      className={
                        this.state.draft_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      Trash <span className="count">({this.state.draft_count})</span>
                    </span>{" "}
                    |
                  </li>

                  <li className="draft">
                    <span
                      onClick={() =>
                        this.setState({
                          all_selected: false,
                          published_selected: false,
                          trash_selected: true,
                          draft_selected: false
                        })
                      }
                      style={{
                        color: this.state.trash_selected ? "#000" : "#a00"
                      }}
                      className={
                        this.state.trash_selected
                          ? "text_Pub current"
                          : "text_Pub"
                      }
                    >
                      {/* Trash <span className="count">()</span> */}
                    </span>
                  </li>
                </ul>
                {/* <div className="tablenav">
                  <div className="alignleft actions bulkactions">
                    <select name="action" id="bulk-action-selector-top">
                      <option value="-1">Bulk Actions</option>

                      <option value="trash">Move to Trash</option>
                    </select>
                    <span
                      onClick={() => this.deleteItems(this.state.selected_data)}
                      className="apply"
                    >
                      Apply
                    </span>
                  </div>
                </div> */}
                <MDBTable
                  striped
                  className="pages_table"
                  style={{ backgroundColor: "#F9F9F9" }}
                >
                  <tr style={{ backgroundColor: "white" }}>
                    <th>
                      <input
                        type="checkbox"
                        onClick={event =>
                          this.setState(
                            { item_selected: !this.state.item_selected },
                            () => this.handleAllChecked(event)
                          )
                        }
                        checked={this.state.item_selected}
                        value="checkedall"
                      />
                    </th>
                    <th className="">Name</th>
                    <th className="">Details</th>
                  </tr>

                  <MDBTableBody>
                    {this.state.faculties.map((item, index) => {
                      return this.renderTableData(item, index);
                    })}
                  </MDBTableBody>
                </MDBTable>


                <div className="mt-5 ml-3">
                 <h3>Faculty SEO</h3>
                 {this.renderFacultySEOTitle()}
                 {this.renderFacultySEODescription()}
               {this.renderFacultySEOSave()}
                
                </div>
              </div>
              </React.Fragment>
                    )}

            </div>
          </div>
        </div>
      );
    }
  
}

export default Faculty;
