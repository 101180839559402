import React from 'react';
import TestBg from '../../Assets/images/Evolution-BG.jpg';
import MainFooterPage from '../../Reusable/Footer/footer';
import NewHeader from '../../Reusable/NewHeader/newHeader';
import { css } from '@emotion/core';
import GridLoader from 'react-spinners/GridLoader';
import '../StarTest/startTest.css'


import StickyFooterPage from '../../Reusable/Footer/stickyFooterRegister';



class StartExam extends React.Component {

  constructor(props){
    super(props);
    this.state={
        loading:false,
        InstructionAndDisclaimer:['Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.','It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ','It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.','Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.','There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isnt anything embarrassing hidden in the middle of text.'],

         }
      }


      componentWillMount(){
        this.setState({loading:true},()=>this.getExamConfig());
      }

      randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

      
      getExamConfig =  () => {
        if(window.localStorage.getItem("examination_item")){
          var examItem = JSON.parse(window.localStorage.getItem("examination_item"));
          console.log(examItem)
            this.setState({examItem, duration:examItem.duration})
            this.getAllQuestions()
        }
        else{
            this.props.history.push('test-series')
        }
      };

      getAllQuestions = () => {
        this.setState({
          loading:true
        })
        this.setState({
          response: fetch("https://www.vimasmiham.com/get_specific_questions", {
            method: "GET"
          })
            .then(response => response.json())
            .then(responseJson => {
              var response = responseJson.reverse();
              console.log(response,"questions")
              response.forEach(question => {
                let topics = [];
                topics.push(question.topic);
                let answers = [];
                answers[0] = new Object();
                answers[0].id = 0;
                answers[0].answer = question.correct_answer;
                answers[0].value = 1;
                answers[0].link = "";
                //other answers
                answers[1] = new Object();
                answers[1].id = 1;
                answers[1].answer = question.other_option_1;
                answers[1].value = 0;
                answers[1].link = "";
    
                answers[2] = new Object();
                answers[2].id = 2;
                answers[2].answer = question.other_option_2;
                answers[2].value = 0;
                answers[2].link = "";
    
                answers[3] = new Object();
                answers[3].id = 3;
                answers[3].answer = question.other_option_3;
                answers[3].value = 0;
                answers[3].link = "";
                question.answers = JSON.stringify(answers);
                question.topics = topics;
                console.log(question);
              });

              this.setState({questions:response, loading:false})
              })
             .then(()=>this.groupExamQuestions())
          })
        }

      pickRandomQuestions(questions, limit){
        var questionIds = []
        var questionObject = [];
        let question = null;
        
        questions.forEach(element => {
          questionIds.push(element.question_id)
        });

        let visitedIds = []
        console.log(questionIds)
        for(var i = 0; i < limit; i++){
          if(limit!==0){
            let index = null;
            let isUnique = false;
            while (!isUnique) {
              index = this.randomIntFromInterval(0, questionIds.length - 1);
              console.log(index, "generated-index");
              console.log(questions.length, "questions-length");
              console.log(visitedIds, "generated indices");
              console.log(questionIds[index], "id at index generated");
              //  console.log(visitedIds.includes(questionIds[index]))
              if (!visitedIds.includes(questionIds[index])) {
                console.log("this index", index);
                //  visitedIds.push(questions[questionIds[index]].question_id);
    
                console.log(visitedIds);
                isUnique = true;
              } else {
                isUnique = true;
              }
            }
          let question = questions.find(item=>item.question_id === questionIds[index])
          console.log(question)
          if(question)
          questionObject.push(question)
            else{
           // questionObject.push({})
          }
          }
        }
        return questionObject
      }


      shuffleArray(array) {
        console.log(array);
        var shuffledArray = array;
        for (var i = shuffledArray.length - 1; i > 0; i--) {
          var j = Math.floor(Math.random() * (i + 1));
          var temp = shuffledArray[i];
          shuffledArray[i] = shuffledArray[j];
          shuffledArray[j] = temp;
        }
        console.log(shuffledArray);
        return shuffledArray;
      }


    groupExamQuestions(){
      let examConfig = JSON.parse(this.state.examItem.exam_config)
      console.log(examConfig)
      let finalExamQuestions = []
      examConfig.forEach(element => {
          finalExamQuestions = [...finalExamQuestions, ...this.groupTestQuestions(element.subject_config)]
      });

      this.setState({finalExamQuestions})
    }

      groupTestQuestions(testConfig){
        console.log(this.state.testConfig,"state testconfig")
        console.log(this.state.questions, "state questions")
        let questions = this.state.questions;
        //Tests Array of Objects consisting of the sorted questions
        var testsArray = []
        
        testConfig.forEach(element => {
          var testObject = new Object;
          testObject.topic_id = element.topic_id;
          testObject.questions = []
          testsArray.push(testObject)
        });
        console.log(testsArray,"test array")
        // let topics = questions[].topics;  
        //console.log(topics, "topics")
        for(var i = 0; i < testConfig.length;i++){
          for(var j = 0 ; j< questions.length;j++){
                    let question =questions[j]
            // if(!questionsVisited.includes(question.question_id)){
            for(var k = 0;k < questions[j].topics.length;k++){
              if(testConfig[i].topic_id == questions[j].topics[k]){
                for(var l =0;l<testsArray.length;l++){
                  if(testConfig[i].topic_id==testsArray[l].topic_id){
                      testsArray[l].questions.push(question);
                    //  questionsVisited.push(question.question_id);
            //      }
                }
              }
            }
          }
          }
        }
        console.log(testsArray, "testsArray")
        var evaluationQuestions = []
        testsArray.forEach(element => {
            var topicConfig = testConfig.find(item=>item.topic_id == element.topic_id);
            console.log(topicConfig)
             var questionObject = this.sortGroupedQuestions(element, topicConfig)
            console.log(questionObject)
             evaluationQuestions = [...evaluationQuestions,questionObject]
            console.log(evaluationQuestions,'evaluating questins')
            // console.count("loop")
            // console.log(evaluationQuestions,"evaluating questions");
        });
    


        var finalTestQuestions=[]
        evaluationQuestions.forEach(element => {
            for(var i = 0;i<element.length;i++){
              console.log(element);
              finalTestQuestions.push(element[i]);
            }
        });


      //  console.log(finalTestQuestions)
        
        
        console.log(finalTestQuestions,"final final fingers crossed")
        finalTestQuestions.forEach(element => {
          if(element.question_id){
          let answers = JSON.parse(element.answers)
          answers = this.shuffleArray(answers);
          element.answers = JSON.stringify(answers)
          }

        });

        console.log(finalTestQuestions)
        return finalTestQuestions
       // this.setState({finalTestQuestions, loading:false})
      }
      
      

      sortGroupedQuestions(topicQuestions, topicConfig){
        let questions = topicQuestions.questions.filter(item=>item.difficulty_level==1);
        console.log(questions)
        var easyQuestions = this.pickRandomQuestions(topicQuestions.questions.filter(item=>item.difficulty_level==1),topicConfig.easy)
        var moderateQuestions = this.pickRandomQuestions(topicQuestions.questions.filter(item=>item.difficulty_level==2),topicConfig.moderate)
        var toughQuestions = this.pickRandomQuestions(topicQuestions.questions.filter(item=>item.difficulty_level==3),topicConfig.tough);
        console.log(easyQuestions,'easy')
        console.log(moderateQuestions,'modeate')
        console.log(toughQuestions,'tough') 
      
        return [...easyQuestions, ...moderateQuestions, ...toughQuestions]
      }
   
      renderInstructionAndDisclaimer(){

        return this.state.InstructionAndDisclaimer.map(member => {
            return ( <div key={member}>
                        <div  >
                              <div >

                                  <ul>
            <li>{member}</li></ul>
                              </div>
                              <div >
                              
      
                              </div>
                        </div>
                   </div> )
               })
      }


      handleStartExam(){
        this.props.history.push({pathname:'/newEvaluation', state:{questions:this.state.finalExamQuestions,duration:this.state.duration}})
      }



      render(){
        const override = css`
        display: block;
        margin: 0 auto;
        border-color: black;
        margin: 30% 45%;
      `;

        if(this.state.loading){
         return(
          <div className='sweet-loading-main' >
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={'#2fb2eb'}
            loading={this.state.loading}
          />
          </div>)
        }
        else{
          console.log(this.state.finalExamQuestions)
         return(
             <div style={{display:'flex',flexDirection:'column',overflow:'hidden'}}>
 
         <div className="windowView">
         <NewHeader  {...this.props} backgroundColor='#FFFFFF' textcolor='#000' width='100%' height='auto'/>
         </div>
 
      
              <div className='vsMobView'>
             <NewHeader  {...this.props} backgroundColor='white' textcolor='#000' 
                 height= 'auto'
                 width= '100%'
                 
             />
             
           </div>
            

           
           <div className="start-test">

           <div style={{position:'relative'}}> <div className='bg_img_div'><img className='bg_img' src={TestBg}/></div> 
           <div className='button_and_text'>
           <div className='text_on_test_BGimg' ><text>Ensure your marks in the Insurance Industry by completing this test. </text></div>
           <div className='buttons_flex'>
           <div><button className='Button_NotNow' onClick={()=>this.props.history.push('/lessons')} > Not Now</button></div>

           <div style={{marginLeft:'10px'}}><button className='Button_startTest' onClick={()=>this.handleStartExam()}> Start Exam </button></div>

           </div>
           </div>
           </div>

         <div className="startTestDecrpition">
             <div><h1>Instructions And Disclaimer</h1>
             <div className='RegistrationCheckBoxhr' ></div>
             </div>
             <div className="startTestList">
             {this.renderInstructionAndDisclaimer()}</div></div>
         </div>
  
            
           <div style={{height:'auto'}} className='windowView'>
           <MainFooterPage {...this.props} />
           </div>
           <div className='vsMobView '>
            <StickyFooterPage {...this.props}
            
            />
            </div>
             </div>
         )
        }
       
     }
        
      }


export default StartExam;
