import React, { Component } from "react";
import "./products.css";

import MainFooterPage from "../../Reusable/Footer/footer";
import swal from "sweetalert";
import { Container } from "reactstrap";

import { MDBCollapse, MDBIcon, MDBBtn, MDBInput } from "mdbreact";

import CoreImg from "../../Assets/images/v1.PNG";
import ShareImg from "../../Assets/images/share-option.png";

import ReviewImg from "../../Assets/images/lac.PNG";

import StarRatings from "react-star-ratings";
import ScrollAnimation from "react-animate-on-scroll";

import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import subject from "./subjects.json";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

class product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      hasDemoTest: false,
      clicked: [],
      activeIndex: 0,
      collapseID: "",
      subjectstobedisplayed: [],
      expanded: false,
      shown: true,
      expanded1: false,
      shown1: true,
      expandedYes: false,
      expandedNo: false,
      userdata: null,
      cart_status: 0,
      showAdded: false,
      test_selected: false,
      course_purchase: 0,
      item_in_cart: false,
      subject_data: {},
      subjects: [],
      cart: [],
      all_items: [],
      test_cost_zero: false,
      course_selected: true,
      test_cost: 0,
      topicSortOrder: {},

      //CourseContent
      CourseContentArray: {
        courseImg: `${CoreImg}`,
        heading: ``,
        subheading: ``,
        rating: "",
        creatorName: ``,
        lastUpdate: `Last Updated 8/2019`,
        language: `English,Hindi,Panjabi,Tamil`,
        learnHeading: `what you'll learn`,
        para: {},
        costAfterDiscount: ``,
        actualCost: `₹ 11000 `,

        instructorName: ``,
        instructorSub: ``,
        instructorDescription: ``,
        instructorRating: ``,
        instructorTotalReviews: `183,129`,
        reviewrName: "Anass N. Alnajjar",
        reviewrCourseandReview: "14 courses, 5 reviews ",
        timeofReview: `one month age`,
        reviewContent: `it was a tough course because i have learned a lot of concepts in only 16 Hours but it was an awesome journey and it was 100% help full for beginners in react-native and am going to buy the advanced version of this course also and before i even do i will recommend it because after taking this course i know that the next one will be awesome too.`,

        RequrmentList: [],
      },
      CourseInformation: [],
      descriptionCourse: [],
      studentsAlsobought: [],

      selected_subject: {},
      loginToggle: false,
      loading: true,
    };
  }
  componentDidMount() {
    console.log(this.props);
  }

  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  checkCart() {
    if (this.state.cart.length != 0) {
      for (var v = 0; v < JSON.parse(this.state.cart[0].items).length; v++) {
        // console.log(this.state.cart[v], subject.subject_id);
        if (
          JSON.parse(this.state.cart[0].items)[v].subject_id ==
          this.state.selected_subject.subject_id
        ) {
          this.setState({
            item_in_cart: true,
          });
        }
      }
    }
  }

  getCart = () => {
    this.getOrders();
    let details = {
      user_id: this.state.userdata.user_id,
    };

    //console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              cart: responseJson,
            },
            () => this.checkCart()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          //  console.log(error);
        }),
    });
  };

  componentWillMount() {
    this.setState(
      {
        loading: true,
      },
      () => this.getsubjects()
    );
  }

  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     if (nextProps.match.params.product !== prevState.selected_subject.url){
  //        const currentUrl = nextProps.match.params.product
  //        const result = prevState.subjects.filter(obj => {

  //          return obj.url === currentUrl;

  //        })
  //       return {

  //          product: result[0],
  //         // currentId: currentProductId,
  //         // result

  //        }
  //    }
  //    return null;
  //  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.product !== this.props.match.params.product) {
      const currentUrl = nextProps.match.params.product;
      const result = this.state.subjects.filter((obj) => {
        return obj.url === currentUrl;
      });
      this.setState(
        {
          product: result[0],
        },
        () => window.location.reload()
      );
    }
  }
  getsubjects = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_subjects", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState(
            {
              subjects: responseJson,
            },
            () => {
              this.getDetails();
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          //console.log(error);
        }),
    });
  };

  calCulatedTest() {
    console.log(this.state.selected_subject);
    let details = {
      subject_id: this.state.selected_subject.subject_id,
    };
    console.log(details, "details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    fetch("https://www.vimasmiham.com/get_demo_test", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("here result", result);
        result.length > 0 && this.setState({ hasDemoTest: true });
      });

    fetch("https://www.vimasmiham.com/get_test", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson, "test for subject");
        let data = responseJson;
        let { test_cost } = this.state;
        var test_cost_var = 0;
        for (var v = 0; v < data.length; v++) {
          test_cost_var = Number(data[v].cost) + test_cost_var;
        }
        if (test_cost_var == 0) {
          this.setState(
            {
              test_cost_zero: true,
              subject_test: responseJson[0],
              // loading: false
            },
            () => this.getTopicsForSubject()
          );
        } else {
          this.setState(
            {
              test_cost: test_cost_var,
              test_cost_zero: false,
              subject_test: responseJson[0],
              test_selected: responseJson[0]?.status === 1 ? true : false,
              // loading: false
            },
            () => this.getTopicsForSubject()
          );
        }
      })
      .catch((err) => console.log(err));
  }

  getTopicsForSubject() {
    let formBody = new FormData();
    formBody.append("subject_id", this.state.selected_subject.subject_id);
    fetch("https://www.vimasmiham.com/get_subject_topics", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        let topicData = [];
        let topicSortOrder = {};
        JSON.parse(responseJson[0].topics).forEach((element) => {
          topicData.push(element.topic_id);
          topicSortOrder[element.topic_id] = element.sortOrder;
        });
        console.log("topoics", topicSortOrder);
        this.setState({ selected_topics: topicData, topicSortOrder }, () =>
          this.getLecturesForTopics()
        );
      });
  }

  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;
    return hours + " hours " + minutes + " minutes";
  }

  getLecturesForTopics() {
    let formBody = new FormData();
    formBody.append("topic_ids", this.state.selected_topics);
    fetch("https://www.vimasmiham.com/get_all_topic_lectures", {
      method: "POST",
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        let sampleExists =
          responseJson.filter((item) => item.sample == 1)[0] == undefined
            ? false
            : true;
        console.log(sampleExists);
        this.setState({
          selected_subject_topics: responseJson,
          sampleExists,
          loading: false,
        });
      });
  }

  getDeta = () => {
    var userdata = window.localStorage.getItem("userdata");
    var subjectdata = window.localStorage.getItem("selected_subject");
    let subjects = JSON.parse(subjectdata);
    var subjectfinal = [];
    let relatedsub = JSON.parse(subjects.related_subjects);
    relatedsub.forEach(function (item, index) {
      let formData = new FormData();
      formData.append("subject_id", item.value);
      fetch("https://vimasmiham.com/get_subject", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          subjectfinal.push(responseJson[0]);
          //console.log(subjectfinal) //gives me the subject data i,e array contaning 3 objects in it
          this.setState(
            { subjectstobedisplayed: subjectfinal },
            () => console.log(this.state.subjectstobedisplayed) // gives error
          );
        })
        .catch((error) => {
          swal("Warning!", "Check your network!", "warning");
          //console.log(error);
        });
    });
  };

  getDetails() {
    // console.log(this.state.subjects)
    var userdata = window.localStorage.getItem("userdata");
    const subjects = this.state.subjects.find(
      (subject) => subject.url == this.props.match.params.product
    );
    if (subjects == undefined || typeof subjects == undefined) {
      this.props.history.push("/error");
    } else {
      var subjectfinal = [];
      console.log(subjects);
      let relatedsub = JSON.parse(subjects.related_subjects);
      //console.log(relatedsub);
      if (relatedsub.length == 0) {
        this.setState({ subjectstobedisplayed: null });
      } else {
        let subjectIDs = relatedsub.map((item) => item.value);
        //console.log(typeof JSON.stringify(subjectIDs));
        let formData = new FormData();
        formData.append("subject_ids", JSON.stringify(subjectIDs));
        fetch("https://www.vimasmiham.com/get_related_subjects", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson);
            this.setState({ subjectstobedisplayed: responseJson });
          })
          .catch((error) => {
            swal("Warning!", "Check your network!", "warning");
            //console.log(error);
          });
      }

      if (userdata != null) {
        this.setState(
          {
            userdata: JSON.parse(userdata),
          },
          () => this.getCart()
        );
      } else {
        console.log(subjects);
        if (subjects.test_id == "false") {
          console.log("no test");
          this.setState({ course_purchase: 5 });
        } else {
          console.log("yyes test");

          this.setState({
            course_purchase: 2,
          });
        }
      }

      if (subjects == null || subjects == undefined) {
        this.props.history.push("/error");
      } else {
        let data2 = this.state.subjects.find(
          (subject) => subject.url === this.props.match.params.product
        );
        if (data2 === undefined) {
          // this.props.history.push('/')
        } else {
          // data2.test_included = data2.test_included == undefined?1:data2.test_included;
          // data2.subject_included = data2.subject_included == undefined?1:data2.subject_included;
          //console.log(data2);
          this.setState(
            {
              selected_subject: data2,
            },
            () => this.calCulatedTest()
          );
        }
      }
    }
  }
  showButton = () => {
    this.setState({ expanded: !this.state.expanded, shown: !this.state.shown });
  };
  showButtonYes = () => {
    this.setState({
      expandedYes: !this.state.expandedYes,
      shown: !this.state.shown,
    });
  };
  showButtonNo = () => {
    this.setState({
      expandedNo: !this.state.expanded,
      shown: !this.state.shown,
    });
  };
  showButton1 = () => {
    this.setState({
      expanded1: !this.state.expanded1,
      shown1: !this.state.shown1,
    });
  };

  toggleCollapse(id) {
    //console.log(this.state.collapseID, "cccccccc");
    if (this.state.collapseID === id) {
      this.setState({
        collapseID: 0,
      });
    } else {
      this.setState({
        collapseID: id,
      });
    }
  }

  showcourseduration = (time) => {
    var num = time;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hours " + rminutes + " minutes";
  };

  handleStudentAlsoBought(subject) {
    window.localStorage.removeItem("selected_subject");
    window.localStorage.setItem("selected_subject", JSON.stringify(subject));
    window.location.reload();
  }

  renderStudentsAlsobought() {
    if (this.state.subjectstobedisplayed == null) {
      return null;
    }
    return (
      <React.Fragment>
        <h1 style={{ marginBottom: "20px" }} className="StudentsAlsoboughtH1">
          Other Related Subjects
        </h1>

        {this.state.subjectstobedisplayed.map((modal) => {
          if (modal != undefined) {
            //console.log(modal[0]);

            return (
              <Container
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <Link to={`${modal[0].url}`}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      height: "90px",
                    }}
                  >
                    <div className="picAndTime">
                      <div>
                        <img
                          src={modal[0].image}
                          alt="vima-logo"
                          className="logoHeight1"
                          width="100%"
                          height="auto"
                        />
                      </div>
                      <div
                        style={{
                          backgroundColor: "#E5E5E5",
                          textAlign: "center",
                        }}
                      >
                        
                        <p className="StudentsAlsoboughtHeadP">
                          
                          {this.showcourseduration(modal[0].duration)}
                        </p>
                      </div>
                    </div>
                    <div className="StudentsAlsoboughtHead">
                      <h3 className="StudentsAlsoboughtHeadH3">
                        {modal.subject_name}
                      </h3>
                      <p className="StudentsAlsoboughtHeadP">
                        {modal[0].subject_name} <br />
                        Updated at {this.commentdate(modal[0].creation_time)}
                      </p>
                    </div>
                    <div className="StudentsAlsoboughtRate opacity-0">
                      <text className="StudentsAlsoboughtRateText">
                        <MDBIcon
                          className="StudentsAlsoboughtRateTextStar"
                          icon="star"
                        />
                        {modal.ratings}
                      </text>
                    </div>
                    <div className="StudentsAlsoboughtUser opacity-0">
                      <text className="StudentsAlsoboughtUserText">
                        <i class="fas fa-user"></i> {modal[0].enrollments}
                      </text>
                    </div>
                    <div className="StudentsAlsoboughtCost">
                      <h4 className="StudentsAlsoboughtCostH4">
                        Rs. {modal[0].cost}
                      </h4>
                      <strike>
                        <h4 className="StudentsAlsoboughtCostP">
                          Rs. {modal[0].old_cost}
                        </h4>
                      </strike>
                    </div>
                    <div className="StudentsAlsoboughtHeart d-none">
                      <i className="far fa-heart"></i>
                    </div>
                  </div>
                  <br />
                </Link>
                <hr className="StudentsHR"></hr>
              </Container>
            );
          }
        })}
      </React.Fragment>
    );
  }

  renderX() {
    const { expandedYes } = this.state;
    return (
      <div key={this.state.CourseContentArray.id}>
        <div>
          <h5 className="review2H5">
            {this.state.CourseContentArray.reviewrName}
            <span className="review2H5P">
              ( {this.state.CourseContentArray.reviewrCourseandReview} )
            </span>
          </h5>
        </div>
        <div className="review2P">
          <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <div>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star checked"></span>
              <span class="fa fa-star"></span>
              <span class="fa fa-star"></span>
            </div>
            <div>
              {" "}
              <p
                style={{ margin: "0% 0% 0% 5%" }}
                className="productMainSecondTextp"
              >
                3.0{" "}
              </p>
            </div>
          </div>{" "}
          <br />
          <p className="review2P">
            {this.state.CourseContentArray.timeofReview}{" "}
          </p>
        </div>
        <div>
          <p className="review2P1">
            {this.state.CourseContentArray.reviewContent}{" "}
          </p>
        </div>

        <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
          <div>
            <text className="review2P">Was this review helpful? </text>
          </div>
          <div style={{ marginRight: ".5%", marginLeft: "2%" }}>
            <button
              className="addYes"
              type="button"
              onClick={this.showButtonYes}
            >
              Yes
            </button>
          </div>
          <div style={{ marginRight: "3%", marginLeft: ".5%" }}>
            <button
              className="addNo"
              type="button"
              onClick={this.showButtonYes}
            >
              No
            </button>
          </div>
          <div>
            {" "}
            <text className="review2P"> Report </text>{" "}
          </div>
        </div>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div>
            <div>
              <Container
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                {expandedYes && ( //show if expanded is true
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <p className="DescriptionP">
                        Thank you! You have successfully submitted feedback for
                        this review
                      </p>
                    </div>
                  </div>
                )}

                {/* 
         <a> <p style={{color:'#0093D9',fontWeight:'500',fontSize:'18px'}} >
         {this.state.shown ? "+ See more" : "- See less"} </p>  </a> */}
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDescription() {
    const { expanded } = this.state;

    return JSON.parse(this.state.selected_subject.description).map((modal) => {
      return (
        <>
          <Container style={{ justifyContent: "center", alignItems: "center" }}>
            <div>
              <p className="DescriptionP"></p>
              {modal.description}
            </div>

            {expanded && ( //show if expanded is true
              <div>
                <div>
                  <p className="DescriptionP">{modal.description}</p>
                </div>
              </div>
            )}
          </Container>
        </>
      );
    });
  }

  renderRequirement() {
    return JSON.parse(this.state.selected_subject.requirements).map((modal) => {
      return (
        <>
          <ul style={{ listS: "disc" }} className="RequrmentList">
            <li className="RequrmentList1"> {modal.requirement} </li>
          </ul>
        </>
      );
    });
  }

  renderCollapse() {
    if (this.state.selected_subject_topics) {
      console.log(this.state.selected_subject_topics);
      return [...this.state.selected_subject_topics]
        .map((ele) => {
          ele.order = this.state.topicSortOrder[ele.topic_id];
          return ele;
        })
        .sort((a, b) => a.order - b.order)
        .map((modal, index) => {
          if (modal.sample == 0) {
            const lectures = JSON.parse(modal.lectures);
            let duration = 0;
            lectures.forEach((element) => {
              duration = duration + element.duration;
            });
            return (
              <>
                <MDBBtn
                  className="CollapseBtn"
                  color="primary"
                  onClick={() => this.toggleCollapse(modal.topic_id)}
                  style={{ paddingTop: "15px", marginBottom: "1rem" }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div style={{ float: "left", marginRight: "4%" }}>
                      <i
                        style={{ color: "#0093D9" }}
                        className={
                          this.state.collapseID == modal.topic_id
                            ? "fas fa-minus"
                            : "fas fa-plus"
                        }
                      ></i>
                    </div>
                    <div className="CollapseBtnIntroduction">
                      {" "}
                      <text>{modal.topic_name} </text>{" "}
                    </div>
                    <div className="CollapseBtnLectures">
                      {" "}
                      <text></text>{" "}
                    </div>
                    <div className="CollapseBtnTime">
                      {" "}
                      <text> {duration} Minutes </text>{" "}
                    </div>
                  </div>
                </MDBBtn>

                <MDBCollapse
                  className="CollapseInside "
                  id={modal.topic_id}
                  isOpen={this.state.collapseID}
                >
                  <ul>
                    {lectures.map((item) => {
                      return <li>{item.lecture_name}</li>;
                    })}
                  </ul>
                </MDBCollapse>
              </>
            );
          }
          // }
        });
    }
  }

  fetchSubject = (subject) => {
    //console.log(subject, "ssssss");
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({
        id: subject.subject_id,
        test_included: subject.test_included,
      })
    );
    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(false));
    this.props.history.push({
      pathname: "/lessons",
      state: {
        id: subject.subject_id,
      },
    });
  };

  startTest() {
    //console.logthis.state.test_included);

    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem(
      "selected_test",
      this.state.selected_subject.subject_id
    );
    this.props.history.push("/start-test");
  }

  renderAddToCart() {
    //console.log(this.state.loading, this.state.selected_subject);

    if (this.state.selected_subject.subject_id == 140) {
      return (
        <React.Fragment>
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            <p className="text-muted already-purchased-text d-none">
              You have purchased the study material for this course.
            </p>

            <button
              onClick={() => this.fetchSubject(this.state.selected_subject)}
              style={{ backgroundColor: "green", color: "white" }}
              className="addCart d-none"
              type="button"
            >
              Go to Course
            </button>

            <button
              onClick={() => this.buyTestWOSubject()}
              style={{ backgroundColor: "#ff9900", color: "white" }}
              className="addCart mt-2"
              type="button"
            >
              Buy Test
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 1) {
      return (
        <React.Fragment>
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            <p className="text-muted already-purchased-text">
              You have purchased this course and its test series
            </p>
            <button
              onClick={() => this.fetchSubject(this.state.selected_subject)}
              style={{ backgroundColor: "green", color: "white" }}
              className="addCart"
              type="button"
            >
              Go to Course
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 2) {
      if (this.state.item_in_cart) {
        return (
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
          >
            <button
              onClick={() =>
                this.addToCart().then(() => {
                  this.props.history.push("/my-cart");
                })
              }
              className="addCart"
              type="button"
            >
              Go to Cart
            </button>
          </div>
        );
      } else {
        return (
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "40px" }}
          >
            <button
              onClick={() => this.addToCart()}
              className="addCart"
              type="button"
              style={{ fontSize: "18px" }}
            >
              Add to Cart
            </button>
          </div>
        );
      }
    } else if (this.state.course_purchase == 3) {
      return (
        <React.Fragment>
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            <p className="text-muted already-purchased-text">
              You have purchased the study material for this course.
            </p>

            <button
              onClick={() => this.fetchSubject(this.state.selected_subject)}
              style={{ backgroundColor: "green", color: "white" }}
              className="addCart"
              type="button"
            >
              Go to Course
            </button>

            <button
              onClick={() => this.buyTestWOSubject()}
              style={{ backgroundColor: "#ff9900", color: "white" }}
              className={`${
                this.state.selected_subject.test_id == "false"
                  ? "d-none"
                  : "addCart mt-2"
              }`}
              type="button"
            >
              Buy Test
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 4) {
      return (
        <React.Fragment>
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            <p className="text-muted already-purchased-text">
              You have purchased the test series for this course
            </p>
            <button
              onClick={() => this.startTest()}
              style={{ backgroundColor: "#ff9900", color: "white" }}
              className="addCart purchased-test"
              type="button"
            >
              Start Test
            </button>

            <button
              onClick={() => this.buyCourseWOTest()}
              style={{ backgroundColor: "white", color: "black" }}
              className="addCart mt-2"
              type="button"
            >
              Buy Course
            </button>
          </div>
        </React.Fragment>
      );
    } else if (this.state.course_purchase == 5) {
      return (
        <React.Fragment>
          <div
            className="productCourseContentPic6"
            style={{ marginTop: "2%", marginBottom: "2%", height: "auto" }}
          >
            {/* <p className="text-muted already-purchased-text">
              You have purchased the test series for this course
            </p>
            <button
              onClick={() => this.startTest()}
              style={{ backgroundColor: "#ff9900", color: "white" }}
              className="addCart purchased-test"
              type="button"
            >
              Start Test
            </button> */}

            <button
              onClick={() => this.buyCourseWOTest()}
              style={{
                backgroundColor: "#aaa",
                color: "black",
                fontWeight: 800,
              }}
              className="addCart mt-2"
              type="button"
            >
              Add to Cart
            </button>
          </div>
          <div
            className="productCourseContentPic7"
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            <button
              onClick={() => this.buyNoWOTest()}
              className="buyNow"
              type="button"
              onclick="alert('Hello world!')"
              style={{ fontSize: "18px", fontWeight: "800", color: "black" }}
            >
              Buy Now
            </button>
          </div>
        </React.Fragment>
      );
    }
  }

  changeSubjectID() {
    let { selected_subject } = this.state;
    if (this.state.course_selected == false) {
      selected_subject.subject_included = 0;
    } else {
      selected_subject.subject_included = 1;
    }
    this.setState({ selected_subject });
  }
  handleCheckComplete() {
    if (this.state.test_cost == 0) {
    } else {
      if (
        this.state.course_selected == true &&
        this.state.test_selected == false
      ) {
      } else {
        this.setState({ course_selected: !this.state.course_selected }, () =>
          this.changeSubjectID()
        );
      }
    }
  }
  changeTestID() {
    let { selected_subject } = this.state;
    if (this.state.test_selected == false) {
      selected_subject.test_included = 0;
    } else {
      selected_subject.test_included = 1;
    }
    this.setState({ selected_subject });
  }
  handleCheckQuestion() {
    if (this.state.test_cost == 0) {
    } else {
      if (
        this.state.course_selected == false &&
        this.state.test_selected == true
      ) {
      } else {
        this.setState({ test_selected: !this.state.test_selected }, () =>
          this.changeTestID()
        );
      }
    }
  }
  renderCostTest() {
    if (this.state.test_cost == 0) {
      return " Free";
    } else {
      return ` Rs.${this.state.test_cost}`;
    }
  }

  handleSampleCourseContent(subjectid) {
    //console.log(this.state);
    window.localStorage.removeItem("selected_course_user");
    window.localStorage.setItem(
      "selected_course_user",
      JSON.stringify({ id: subjectid })
    );

    window.localStorage.removeItem("sample_mode");
    window.localStorage.setItem("sample_mode", JSON.stringify(true));

    window.open(
      `https://vimasmiham.com/sample-lessons/${btoa(subjectid)}`,
      "_blank",
      "width=1366,height=768"
    );
    // this.props.history.push({
    //   pathname: "/lessons",
    //   state: {
    //     sampleMode: true
    //   }
    // });
  }

  renderOptions() {
    if (
      this.state.selected_subject.subject_id == 140 &&
      this.state?.subject_test?.status === 1
    ) {
      return (
        <div
          style={{ width: "100%", alignItems: "center" }}
          className="colDefault"
        >
          <div
            className="productCourseContent-box-QuestionBank"
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox2"
              className="position-static"
              containerClass="align-items-center mr-0 d-flex"
              onChange={() => this.handleCheckQuestion()}
              checked={this.state.test_selected}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0",
                }}
              >
                <span>Question Bank</span>
                <span style={{ fontSize: "11px" }}>[Online]</span>
              </p>
              <text>{this.renderCostTest()}</text>
            </div>
          </div>
        </div>
      );
    }

    if (
      this.state.course_purchase == 2 &&
      this.state?.subject_test?.status === 1
    ) {
      return (
        <div
          style={{ width: "100%", alignItems: "center" }}
          className="colDefault"
        >
          <div
            className="productCourseContent-box-QuestionBank"
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox1"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex"
              checked={this.state.course_selected}
              onChange={() => this.handleCheckComplete()}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <text
                style={{
                  marginLeft: "0%",
                  fontFamily: "Ubuntu-r",
                  fontSize: "18px",
                }}
              >
                Course
              </text>
              <text>{` Rs.${this.state.selected_subject.cost}`}</text>
            </div>
          </div>

          <div
            className="productCourseContent-box-QuestionBank"
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox2"
              className="position-static"
              containerClass="align-items-center mr-0 d-flex"
              onChange={() => this.handleCheckQuestion()}
              checked={this.state.test_selected}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "0",
                }}
              >
                <span>Question Bank</span>
                <span style={{ fontSize: "11px" }}>[Online]</span>
              </p>
              <text>{this.renderCostTest()}</text>
            </div>
          </div>

          <div
            className={
              !this.state.sampleExists
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() =>
                this.handleSampleCourseContent(
                  this.state.selected_subject.subject_id
                )
              }
            >
              View course demo
            </p>
          </div>

          <div
            className={
              !this.state.hasDemoTest
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() => {
                // this.handleSampleCourseContent(
                //   this.state.selected_subject.subject_id
                // )
                window.localStorage.setItem(
                  "selected_test",
                  this.state.selected_subject.subject_id
                );
                // this.props.history.push("/start-demo-test");
                window.open(
                  `/start-demo-test`,
                  "_blank",
                  "width=1366,height=768"
                );
              }}
            >
              View Question Bank Demo
            </p>
          </div>
        </div>
      );
    } else if (this.state.course_purchase == 5) {
      return (
        <div
          style={{ width: "100%", alignItems: "center" }}
          className="colDefault"
        >
          <div
            className="productCourseContent-box-QuestionBank"
            style={{ marginTop: "5%" }}
          >
            <MDBInput
              filled
              type="checkbox"
              id="checkbox1"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex"
              checked={this.state.course_selected}
              onChange={() => this.handleCheckComplete()}
            />

            <div
              className="rowDefault px-3"
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <text
                style={{
                  marginLeft: "0%",
                  fontFamily: "Ubuntu-r",
                  fontSize: "18px",
                }}
              >
                Course
              </text>
              <text>{` Rs.${this.state.selected_subject.cost}`}</text>
            </div>
          </div>

          <div
            className={
              !this.state.sampleExists
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() =>
                this.handleSampleCourseContent(
                  this.state.selected_subject.subject_id
                )
              }
            >
              View course demo
            </p>
          </div>
          <div
            className={
              !this.state.hasDemoTest
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() => {
                // this.handleSampleCourseContent(
                //   this.state.selected_subject.subject_id
                // )
                window.localStorage.setItem(
                  "selected_test",
                  this.state.selected_subject.subject_id
                );
                // this.props.history.push("/start-demo-test");
                window.open(
                  `/start-demo-test`,
                  "_blank",
                  "width=1366,height=768"
                );
              }}
            >
              View Question Bank Demo
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: "none" }}>
          <div className="productCourseContent-box" style={{ marginTop: "5%" }}>
            <MDBInput
              filled
              type="checkbox"
              id="checkbox1"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex"
              checked={this.state.course_selected}
              onChange={() => this.handleCheckComplete()}
            />{" "}
            <span style={{ marginLeft: "15%", fontFamily: "Ubuntu-r" }}>
              Complete Course
            </span>
          </div>
          <div className="productCourseContentPic4" style={{ marginTop: "5%" }}>
            <MDBInput
              filled
              type="checkbox"
              id="checkbox2"
              className="align-items-center position-static"
              containerClass="mr-0 align-items-center d-flex "
              onChange={() => this.handleCheckQuestion()}
              checked={this.state.test_selected}
            />{" "}
            <p
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              <span>Question Bank</span>
              <span style={{ fontSize: "11px" }}>[Online]</span>
            </p>
          </div>

          <div
            className={
              !this.state.sampleExists
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() =>
                this.handleSampleCourseContent(
                  this.state.selected_subject.subject_id
                )
              }
            >
              View course demo
            </p>
          </div>

          <div
            className={
              !this.state.hasDemoTest
                ? "d-none"
                : "productCourseContent-box-QuestionBank justify-content-center align-items-center mt-2"
            }
            style={{ backgroundColor: "red" }}
          >
            <p
              className="POffproductCourseContentPic5 mb-0 text-white"
              style={{ fontSize: "18px", fontWeight: "900" }}
              onClick={() => {
                // this.handleSampleCourseContent(
                //   this.state.selected_subject.subject_id
                // )
                window.localStorage.setItem(
                  "selected_test",
                  this.state.selected_subject.subject_id
                );
                // this.props.history.push("/start-demo-test");
                window.open(
                  `/start-demo-test`,
                  "_blank",
                  "width=1366,height=768"
                );
              }}
            >
              View Question Bank Demo
            </p>
          </div>
        </div>
      );
    }
  }
  buyNow() {
    this.addToCart();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  addToCart = async () => {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: this.state.test_selected ? 1 : 0,
            subject_included: this.state.course_selected ? 1 : 0,
          },
        ]),
      };
      //console.log(details, "fetching-details");

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.trigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            //console.log(error);
          }),
      });
    }
  };

  selectiveTrigger() {
    this.trigger();
    if (this.state.userdata) this.props.history.push("/my-cart");
  }

  buyCourseWOTest() {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: 0,
            subject_included: 1,
          },
        ]),
      };

      //console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            //console.log(error);
          }),
      });
    }
  }

  buyNoWOTest() {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: 0,
            subject_included: 1,
          },
        ]),
      };

      //console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            //console.log(error);
          }),
      });
    }
  }

  buyTestWOSubject() {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      //   this.setState({
      //     loading:true
      // })
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: this.state.selected_subject.subject_id,
            test_included: 1,
            subject_included: 0,
          },
        ]),
      };

      //console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            //console.log(responseJson, "update_cart");
            this.setState(
              {
                showAdded: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            //console.log(error);
          }),
      });
    }
  }
  renderBuy() {
    if (this.state.selected_subject.subject_id == 140) {
      return null;
    }

    if (this.state.course_purchase == 2) {
      return (
        <div
          className="productCourseContentPic7"
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <button
            onClick={() => this.buyNow()}
            className="buyNow"
            type="button"
            onclick="alert('Hello world!')"
            style={{ fontSize: "18px", fontWeight: "800", color: "black" }}
          >
            Buy Now
          </button>
        </div>
      );
    } else {
      return (
        <button
          className="buyNow d-none"
          type="button"
          onclick="alert('Hello world!')"
        >
          Already Purchased
        </button>
      );
    }
  }
  renderPercent(old_cost, new_cost) {
    return;
  }
  renderPrice() {
    if (this.state.selected_subject.old_cost == 0) {
      if (this.state.test_cost == 0) {
        {console.log('this one')}
        return (
          <div
            className="productCourseContentPic2"
            style={{
              marginTop: "6%",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "",
              paddingRight: "",
            }}
          >
            <text className={"productCourseContentPic2Text"} style={{}}>
              Rs.{this.state.selected_subject.cost}
            </text>
          </div>
        );
      } else {
        if (
          this.state.test_selected == false &&
          this.state.course_selected == true
        ) {
        {console.log('this one')}

          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text className="productCourseContentPic2Text" style={{}}>
                Rs.{this.state.selected_subject.cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == false
        ) {
        {console.log('this one')}

          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text className="productCourseContentPic2Text" style={{}}>
                Rs.{this.state.test_cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == true
        ) {
        {console.log('this one')}

          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "",
                paddingRight: "",
              }}
            >
              <text
                className={
                  this.state.course_purchase == 1
                    ? "d-none"
                    : "productCourseContentPic2Text"
                }
                style={{}}
              >
                Rs.
                {this.state.test_cost +
                  Number(this.state.selected_subject.cost)}
              </text>
            </div>
          );
        }
      }
    } else {
      if (this.state.test_cost == 0) {
        {console.log('this one')}
        if(this.state.course_purchase != 3 ){
          
        return (
          <div className="productCourseContentPic2" style={{ marginTop: "6%" }}>
            <text className="productCourseContentPic2Text" style={{}}>
              Rs.{this.state.selected_subject.cost}
              <span className="productCourseContentPic2TextSpan" style={{}}>
                <strike> Rs.{this.state.selected_subject.old_cost} </strike>{" "}
              </span>
              <span className="productCourseContentPic2TextSpan" style={{}}>
                {" "}
                {100 -
                  Math.round(
                    (this.state.selected_subject.cost /
                      this.state.selected_subject.old_cost) *
                      100
                  )}
                % off
              </span>{" "}
            </text>
          </div>
        );
        }
      } else {

        if (
          this.state.test_selected == false &&
          this.state.course_selected == true &&
          this.state.selected_subject.test_included != 0
        ) {
        {console.log('this one')}

          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <text className="productCourseContentPic2Text" style={{}}>
                Rs.{this.state.selected_subject.cost}
                <span className="productCourseContentPic2TextSpan" style={{}}>
                  <strike> Rs.{this.state.selected_subject.old_cost} </strike>{" "}
                </span>
                <span className="productCourseContentPic2TextSpan" style={{}}>
                  {" "}
                  {100 -
                    Math.round(
                      (this.state.selected_subject.cost /
                        this.state.selected_subject.old_cost) *
                        100
                    )}
                  % off
                </span>{" "}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == false
        ) {
        {console.log('this one')}

          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <text className="productCourseContentPic2Text" style={{}}>
                Rs.{this.state.test_cost}
              </text>
            </div>
          );
        } else if (
          this.state.test_selected == true &&
          this.state.course_selected == true
        ) {
        {console.log('this one')}

          //console.log();
          return (
            <div
              className="productCourseContentPic2"
              style={{
                marginTop: "6%",
                display: "",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <text
                className={
                  this.state.course_purchase == 1
                    ? "d-none"
                    : "productCourseContentPic2Text"
                }
                style={{}}
              >
                Rs.
                {Number(this.state.selected_subject.cost) +
                  Number(this.state.test_cost)}
                <span className="productCourseContentPic2TextSpan" style={{}}>
                  <strike>
                    {" "}
                    Rs.
                    {Number(this.state.selected_subject.old_cost) +
                      Number(this.state.test_cost)}{" "}
                  </strike>{" "}
                </span>
                <span className="productCourseContentPic2TextSpan" style={{}}>
                  {" "}
                  {100 -
                    Math.round(
                      ((Number(this.state.selected_subject.cost) +
                        Number(this.state.test_cost)) /
                        (Number(this.state.selected_subject.old_cost) +
                          Number(this.state.test_cost))) *
                        100
                    )}
                  % off
                </span>{" "}
              </text>
            </div>
          );
        }
      }
    }
  }
  rendeCourseCard() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        key={this.state.CourseContentArray.id}
      >
        <div className="productCourseContentPic1">
          <img
            src={this.state.selected_subject.image}
            alt="vima-logo"
            className="logoHeight productCourseContentPic1"
          />
        </div>
        {this.renderPrice()}

        {this.renderOptions()}

        {this.renderAddToCart()}

        {this.renderBuy()}

        <div
          className="productCourseContentPic8 d-none"
          style={{ marginTop: "2%", marginBottom: "2%" }}
        >
          <button
            className="shareIt"
            type="button"
            onclick="alert('Hello world!')"
          >
            <div style={{ float: "left", width: "100%" }}>
              <img
                src={ShareImg}
                alt="google-logo"
                className=""
                height="20px"
                color="#fff"
              />{" "}
              <text style={{ marginLeft: "" }}>Share</text>{" "}
            </div>{" "}
          </button>
        </div>
      </div>
    );
  }

  renderlearn() {
    let columns = [];

    JSON.parse(this.state.selected_subject.learning_topic).forEach(
      (item, key) => {
        columns.push(
          <div className="productCourseContentText1">
            <div className="productCourseContentTextInner1">
              <div>
                <i class="fas fa-check"></i>{" "}
              </div>

              <div style={{ marginLeft: "2%" }}>
                <text> {item.topic} </text>
              </div>
            </div>
          </div>
        );
        if ((key + 1) % 2 === 0) {
          columns.push(<div className="w-100"></div>);
        }
      }
    );

    return (
      <div>
        <div className="productCourseContentTextHeading">
          <h1 className="productCourseContentTextHeadingH1">
            What will you learn
          </h1>
        </div>
        <div className="row">{columns}</div>
      </div>
    );
  }

  rendeCourseContent() {
    return (
      <div key={this.state.selected_subject.subject_id}>
        <h2 className="productMainSecondTextH2">
          {" "}
          {this.state.selected_subject.subject_name}{" "}
        </h2>
        <h5 className="productMainSecondTextH5">
          {this.state.selected_subject.sub_title}{" "}
        </h5>

        <div
          className="product-page-course-rating d-flex align-items-center"
          style={{ opacity: 0 }}
        >
          <StarRatings
            rating={Number(this.state.selected_subject.ratings)}
            starDimension="16px"
            starSpacing="2px"
            starRatedColor="#dbaa34"
            style={{ marginRight: 4 }}
            name="product-page-class-rating"
          />

          <p style={{ color: "white" }} className="product-page-review-font">
            ({this.state.selected_subject.total_ratings}),{" "}
            {this.state.selected_subject.enrollments} Students Enrolled
          </p>
        </div>

        <p className="productMainSecondTextp" style={{ marginBottom: 0 }}>
          <span style={{ marginLeft: 0 }}>
            {" "}
            {/* {this.state.CourseContentArray.lastUpdate}{" "} */}
          </span>{" "}
        </p>
      </div>
    );
  }

  trigger() {
    this.getCart();
    setTimeout(() => {
      this.setState({
        showAdded: false,
      });
    }, 2000);
  }

  showUpdateBar() {
    if (this.state.showAdded) {
      if (this.state.cart_status == 240) {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className="slideInLeft toast2"
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart</div>
          </ScrollAnimation>
        );
      } else {
        return (
          <ScrollAnimation
            animateOnce={false}
            animateIn="slideInLeft"
            className="slideInLeft toast2"
            animationOut="slideOutLeft"
            isVisible={true}
          >
            <div id="snackbar">Added to cart </div>
          </ScrollAnimation>
        );
      }
    }
  }
  getOrders = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      loading: true,
      response: fetch("https://www.vimasmiham.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              items.push(data);
            }
          }
          var data_to_send = items;

          this.checkDuplicates(data_to_send);
          //count = 0 NOT PURCHASED
          //count = 1 COURSE AND TEST PURCHASED
          //count = 2 ONLY COURSE PURCHASED
          //count = 3 ONLY TEST PURCHASED
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          //console.log(error);
        }),
    });
  };

  checkDuplicates(originalArray) {
    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            //console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              //   console.log(originalArray[i],originalArray[i1],'iii')
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    //console.log(all_courses, "iii");

    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);
          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  removeDuplicates(originalArray, objKey) {
    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    this.setState(
      {
        all_items: trimmedArray,
        loading: false,
      },
      () => {
        var items = [];
        var count = 0;
           //count = 0 NOT PURCHASED
          //count = 1 COURSE AND TEST PURCHASED
          //count = 2 ONLY COURSE PURCHASED
          //count = 3 ONLY TEST PURCHASED
        for (var v = 0; v < this.state.all_items.length; v++) {
          for (var v1 = 0; v1 < this.state.all_items.length; v1++) {
            if (
              this.state.all_items[v].subject_id ==
              this.state.selected_subject.subject_id
            ) {
              if (this.state.all_items[v].course_status == "active") {
                if (
                  this.state.all_items[v].subject_included == 0 &&
                  this.state.all_items[v].test_included == 1
                ) {
                  count = 3;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].test_included == 0
                ) {
                  count = 2;
                } else if (
                  this.state.all_items[v].subject_included == 1 &&
                  this.state.all_items[v].test_included == 1
                ) {
                  count = 1;
                }
              }
            }
          }
        }

        console.log('count',count)
        if (count == 0) {
          let { selected_subject } = this.state;

          if (selected_subject.test_id == "false") {
            selected_subject.test_included = 0;
            this.setState({
              course_purchase: 5,
              loading: false,
            });
          } else {
            let { selected_subject } = this.state;
            selected_subject.test_included = 0;
            this.setState({ selected_subject });
            this.setState({
              course_purchase: 2,
              loading: false,
            });
          }
        } else if (count == 1) {
          let { selected_subject } = this.state;
          selected_subject.test_included = 1;
          this.setState({ selected_subject });
          this.setState({
            course_purchase: 1,
            test_selected: true,
            course_selected: true,
            loading: false,
          });
        } else if (count == 2) {
          let { selected_subject } = this.state;
          selected_subject.test_included = 0;
          this.setState({ selected_subject });
          this.setState({
            course_purchase: 3,
            test_selected: false,
            course_selected: true,
            loading: false,
          });
        } else if (count === 3) {
          let { selected_subject } = this.state;
          selected_subject.test_included = 1;
          this.setState({ selected_subject });
          this.setState({
            course_purchase: 4,
            test_selected: true,
            course_selected: false,
            loading: false,
          });
        }
      }
    );
  }

  validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ email: text, valid_email: false });
      return false;
    } else {
      this.setState({ email: text, valid_email: true });
    }
  };

  addContact = async () => {
    if (this.state.valid_email == false) {
      swal("Warning!", "Please enter valid Email Id", "warning");
    } else {
      if (
        this.state.email.length === 0 ||
        this.state.phone.length === 0 ||
        this.state.name.length === 0 ||
        this.state.message.length === 0
      ) {
        swal("Warning!", "all fields are mandatory", "warning");
      } else {
        this.setState({
          loading: true,
        });
        let details = {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          message: this.state.message,
        };

        //console.log(details, "add-details");
        let formBody = [];
        for (let property in details) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        this.setState({
          response: fetch("https://www.vimasmiham.com/add_contact_query", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "max-age=31536000",
            },
            body: formBody,
          })
            .then((response) => response.json())
            .then((responseJson) => {
              //console.log(responseJson);
              this.setState(
                {
                  name: "",
                  email: "",
                  message: "",
                  phone: "",
                  loading: false,
                },
                () =>
                  swal("Success!", "We will contact you shortly!", "success")
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });

              //console.log(error);
            }),
        });
      }
    }
  };

  renderTotalDuration() {
    let total = 0;
    if (this.state.selected_subject_topics) {
      this.state.selected_subject_topics.forEach((element) => {
        console.log(element);
        // if(element.sample == 0){
        JSON.parse(element.lectures).forEach((item) => {
          total = total + item.duration;
        });
        // }
      });
    }
    return total;
  }

  render() {
    const { expandedNo } = this.state;
    const { expanded } = this.state;
    //console.log(this.state);
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      const topics = this.state.selected_subject_topics;
      console.log(this.state.sampleExists);
      console.log(topics);
      console.log(this.state.selected_subject);
      return (
        <div
          id="product-page"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.selected_subject.page_title_seo}</title>
            <link
              rel="canonical"
              href={`https://www.vimasmiham.com/courses/${this.state.selected_subject.url}`}
            />
            <meta
              name="description"
              content={this.state.selected_subject.page_description_seo}
            />
          </Helmet>

          <NewHeader
            cart={
              this.state.cart.length == 0
                ? 0
                : JSON.parse(this.state.cart[0].items).length
            }
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
            showLogin={this.state.loginToggle}
          />
          <div className="productMain">
            <div className="productMainFirst d-none">
              <text className="productMainFirstText">
                Home &gt;{" "}
                <span onClick={() => this.props.history.push("/dashboard")}>
                  Exams
                </span>{" "}
                &gt;{" "}
                <span onClick={() => this.props.history.push("/dashboard")}>
                  Insurance Institute Exams{" "}
                </span>{" "}
                &gt;{" "}
                <span onClick={() => this.props.history.push("/dashboard")}>
                  {" "}
                  Associateship
                </span>{" "}
                &gt; {this.state.selected_subject.subject_name}{" "}
              </text>
            </div>

            <div className="productMainSecond">
              <div className="productMainSecondText">
                {this.rendeCourseContent()}
              </div>
            </div>

            <div className="row px-1 px-md-5">
              <div className="col-12 col-md-8 order-2 order-md-1 px-1 px-lg-5">
                <div className="productCourseContent">
                  {this.state.selected_subject.learning_topic.length == 0 ? (
                    ""
                  ) : (
                    <div className="productCourseContentText">
                      {this.renderlearn()}
                    </div>
                  )}
                </div>

                <div className="productCourseContentFull">
                  <div className="productCourseContentFullHeading">
                    <h1 className="productCourseContentFullHeadingH1">
                      Course content
                    </h1>

                    <p
                      className="productCourseContentFullHeadingp mb-0"
                      style={{ color: "#fff" }}
                    >
                      <a onClick={() => this.toggleCollapse()}>Expand All</a>
                    </p>

                    <p className="productCourseContentFullHeadingp mb-0">
                      {JSON.parse(this.state.selected_subject.topics).length}{" "}
                      Topics
                    </p>

                    <p className="productCourseContentFullHeadingp mb-0">
                      {this.time_convert(this.renderTotalDuration())}
                    </p>
                  </div>
                </div>

                <div className="CollapseMain">{this.renderCollapse()}</div>

                {this.state.selected_subject.requirements.length == 0 ? (
                  ""
                ) : (
                  <div className="RequrmentMain">
                    <h2 className="RequrmentMainH2">Requirments</h2>
                    {this.renderRequirement()}
                    <hr className="productHR"></hr>
                  </div>
                )}

                {this.state.selected_subject.description.length == 0 ? (
                  ""
                ) : (
                  <div className="Description">
                    <h1 className="DescriptionH1">Description</h1>
                    {this.renderDescription()}

                    <hr className="productHR"></hr>
                  </div>
                )}

                <div className="review d-none">
                  <div>
                    <h2 className="review1">Featured review</h2>
                  </div>

                  <div className="review2">{this.renderX()}</div>
                </div>

                {/* {console.log(
                  this.state.selected_subject.requirements.length,
                  "9999"
                )} */}

                {this.state.subjectstobedisplayed == null ? (
                  <>{console.log("related subjects exist")}</>
                ) : (
                  <div className="StudentsAlsobought">
                    {console.log("related subjects exist 2s")}

                    {this.renderStudentsAlsobought()}

                    <a>
                      <p
                        style={{
                          color: "#0093D9",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      ></p>
                    </a>
                  </div>
                )}
                <div className="InstructorRating d-none">
                  <div className="InstructorRating1">
                    <div className="InstructorRating1RoundPic">
                      <img
                        src={ReviewImg}
                        alt="vima-logo"
                        className="logoHeight logoHeight12"
                      />
                    </div>

                    <div className="InstructorRating1A">
                      <div class>
                        <i class="fas fa-star"></i>
                      </div>{" "}
                      <div style={{ marginLeft: "5%" }}>
                        <p className="InstructorRating1P">
                          <span className="InstructorRating1PSpan"> </span>{" "}
                          {this.state.CourseContentArray.instructorRating}{" "}
                        </p>
                      </div>{" "}
                    </div>
                    <div className="InstructorRating1B">
                      <div>
                        <i class="fas fa-comment"></i>{" "}
                      </div>{" "}
                      <div style={{ marginLeft: "5%" }}>
                        <p className="InstructorRating1P">
                          {" "}
                          <span className="InstructorRating1PSpan">
                            {" "}
                            {
                              this.state.CourseContentArray
                                .instructorTotalReviews
                            }{" "}
                          </span>{" "}
                          Reviews{" "}
                        </p>
                      </div>{" "}
                    </div>
                  </div>

                  <div className="InstructorRating2">
                    <h4 className="InstructorRating2h4">
                      {" "}
                      {this.state.CourseContentArray.instructorName}
                    </h4>
                    <p className="InstructorRating2P">
                      {" "}
                      {this.state.CourseContentArray.instructorSub}
                    </p>
                    <p className="InstructorRating2P1">
                      {this.state.CourseContentArray.instructorDescription}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 order1 order-md-2">
                <div className="productCourseContentPic">
                  {this.rendeCourseCard()}
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
          {this.showUpdateBar()}

          {/* {this.renderContactUs()} */}
        </div>
      );
    }
  }
}
export default product;
