import React from "react";
import Loader from "react-loader-spinner";

import "./footer.css";

import swal from "sweetalert";
import { Link } from "react-router-dom";
class MainFooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoText: `We are a group of insurance professionals from diverse backgrounds with more than 3.5 million hours of experience behind us. Although we come from different companies and have diverse experiences, we are united by our love for academics. We are all academically inclined and committed professionals who believe in achieving excellence through constant learning. Our aim is to contribute towards improvement in the academic and professional ambience in the industry.`,
      insight_mail: "",
      valid_email: false,
      verticalLoading: true,
      about_us: "",
      email_support: "",
      email_tutor: "",
      phone: "",
      examFooter: [],
      address: "",
    };
  }

  componentWillMount() {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://www.vimasmiham.com/get_exams", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          let exams = [];
          responseJson.forEach((item) => {
            if (item.exam_id == 1 || item.exam_id == 2 || item.exam_id == 3) {
              exams.push(item);
            }
          });
          exams = exams.sort((a, b) => a.exam_id - b.exam_id);
          this.setState({ examFooter: exams });
        })
        .catch((error) => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
    this.getOffVerticals();
    this.getFooterDetails();
  }
  componentDidMount() {}

  getFooterDetails = async () => {
    this.setState({
      // response: fetch("http://localhost:8000/get_lectures", {
      response: fetch("https://www.vimasmiham.com/get_footer", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          this.setState({
            aboutus: responseJson[0].about_us,
            phone: responseJson[0].phone,
            email_support: responseJson[0].email_support,
            email_tutor: responseJson[0].email_tutor,
            address: responseJson[0].address,
            loading: false,
          });
        })
        .catch((error) => {
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  validateEmail = (text) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      this.setState({ insight_mail: text, valid_email: false });
      return false;
    } else {
      this.setState({ insight_mail: text, valid_email: true });
    }
  };
  addInsight = async () => {
    if (this.state.insight_mail.length == 0) {
      return swal("Enter email for subscription!", "", "info");
    } else if (this.state.valid_email == false) {
      return swal("Enter valid email!", "", "info");
    } else {
      let details = {
        email: this.state.insight_mail,
      };

      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/insight_mail", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "max-age=31536000",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            this.setState(
              {
                insight_mail: "",

                loading: false,
              },
              () => {
                if (responseJson.status == 200) {
                  swal("", responseJson.message, "success");
                } else {
                  swal("", responseJson.message, "info");
                }
              }
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            //  swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  getOffVerticals() {
    var footerVerticals = JSON.parse(
      window.localStorage.getItem("footerVerticals")
    );

    if (footerVerticals) {
      this.setState({
        footerVerticals,
        footerLoading: false,
      });
    } else {
      this.setState({
        footerLoading: true,
      });
    }
    this.getVerticals();
  }

  getVerticals = () => {
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_verticals", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          responseJson = responseJson.reverse();
          for (var v = 0; v < responseJson.length; v++) {
            responseJson[v].data_type = "vertical";
          }

          window.localStorage.removeItem("footerVerticals");
          window.localStorage.setItem(
            "footerVerticals",
            JSON.stringify(responseJson)
          );
          this.setState({
            footerVerticals: responseJson,
            footerLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  selectVertical(item) {
    window.localStorage.removeItem("selected_vertical");
    window.localStorage.removeItem("collapse");
    window.localStorage.setItem("selected_vertical", JSON.stringify(item));
    window.localStorage.setItem("collapse", 0);
    this.props.history.push("/dashboard");
  }

  render() {
    return (
      <div className="">
        <div className="footer-container">
          <div className="footer-top">
            <div className="row">
              <div className="col-12 col-md-4">
                <h3>VIMA SMIHAM</h3>
                <hr />
                <p className="footer-info-text pr-md-4 text-justify">
                  {this.state.infoText}
                </p>
              </div>
              <div className="col-6 col-md-2 px-1 px-md-4">
                <h3>QUICK LINKS</h3>
                <hr />
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/all-faculty">Faculty</Link>
                  </li>

                  <li>
                    <Link to="/acts_and_regulations">Acts</Link>
                  </li>
                  <li>
                    <Link to="/regulations">Regulations</Link>
                  </li>
                  <li>
                    <Link to="/upcoming-exams">Upcoming Exams</Link>
                  </li>
                  <li>
                    <Link
                      to={`/exams/${
                        this.state.examFooter.length > 0
                          ? this.state.examFooter[0].url
                          : ""
                      }`}
                    >
                      {this.state.examFooter.length > 0
                        ? this.state.examFooter[0].exam_name
                        : ""}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/exams/${
                        this.state.examFooter.length > 0
                          ? this.state.examFooter[1].url
                          : ""
                      }`}
                    >
                      {this.state.examFooter.length > 0
                        ? this.state.examFooter[1].exam_name
                        : ""}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`/exams/${
                        this.state.examFooter.length > 0
                          ? this.state.examFooter[2].url
                          : ""
                      }`}
                    >
                      {this.state.examFooter.length > 0
                        ? this.state.examFooter[2].exam_name
                        : ""}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-6 col-md-2 px-1 px-md-5">
                <h3>VERTICALS</h3>
                <hr />
                <ul>
                  {this.state.footerLoading ? (
                    <Loader
                      type="Bars"
                      color="rgb(0, 147, 217)"
                      height={80}
                      width={80}
                      timeout={200000} //3 secs
                      className="svg-component"
                    />
                  ) : (
                    this.state.footerVerticals.map((vertical) => {
                      if (vertical.status != 0)
                        return (
                          <li style={{ lineHeight: 1.3, marginBottom: 10 }}>
                            <Link to={`/categories/${vertical.url}`}>
                              {vertical.vertical_name}
                            </Link>
                          </li>
                        );
                    })
                  )}
                </ul>
              </div>
              <div className="col-12 col-md-4 px-1 px-md-5 footer-company-column-details">
                <h3>COMPANY</h3>
                <hr />
                <ul>
                  <li
                    className={
                      this.state.address.length == 0
                        ? "d-none"
                        : "footer-address-text pr-1 pr-md-5"
                    }
                  >
                    {this.state.address}
                  </li>
                  <li className={this.state.phone.length == 0 ? "d-none" : ""}>
                    Phone:{this.state.phone}
                  </li>
                  <li
                    className={
                      this.state.email_support.length <= 5 ? "d-none" : ""
                    }
                  >
                    Email :{" "}
                    <a href={`mailto:'${this.state.email_support}`}>
                      {this.state.email_support}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.email_tutor.length <= 5 ? "d-none" : ""
                    }
                  >
                    Email :{" "}
                    <a className="" href={`mailto:'${this.state.email_tutor}`}>
                      {this.state.email_tutor}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.email_tutor.length <= 5 ? "d-none" : ""
                    }
                    onClick={() =>
                      (window.parent.location.href = "tel:+9170428 95209")
                    }
                  >
                    <p>Phone No : +9170428 95209</p>
                  </li>
                  <li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p className="mb-0 px-1">
            {" "}
            Copyright &copy; 2019 <span>Vima Smiham</span>. All Rights Reserved.
          </p>
        </div>
      </div>
    );
  }
}

export default MainFooterPage;
