import React,{Component} from 'react';
import './allFAQ.css';
import SidebarPage from '../Sidebar/sidebarPage';
import AdminNavbar from '../Navbar/adminNavbar';
import swal from 'sweetalert';
import { css } from '@emotion/core';
import { MDBTable, MDBTableBody } from 'mdbreact';
import GridLoader from 'react-spinners/GridLoader'; 
import ActivePNG from '../../../Assets/images/activestatus.png';
import InactivePNG from '../../../Assets/images/inactivestatus.png';


const override = css`
display: block;
margin: 0 auto;
border-color: black;
margin:20% 45%;
`;


class allFAQ extends Component {
  constructor( props ) {
      super( props );

      this.state = {
        loading:false,
        subjects:[],
        showAction:false,
        draft_count:0,
        faqTitle:'',
        faqDescription:'',
        published_count:0,
        trash_count:0,all_selected:true,draft_selected:false,
        published_selected:false,trash_selected:false,selected_data:[]
      };

  }
componentWillMount(){
  
      this.setState({loading:true}, ()=>this.getSubjects())

}
getFaqSEO = async () => {
  
  this.setState({
    response: fetch("https://www.vimasmiham.com/get_faq_seo", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        console.log(responseJson, "clients");
        this.setState({
          faqTitle: responseJson[0].faq_title_seo,
          faqDescription: responseJson[0].faq_description_seo,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false
        });
        alert("Warning!", "Check your network!", "warning");
        console.log(error);
      })
  });
};

componentDidMount(){
      window.localStorage.removeItem('subject_item');
    }




  getSubjects=async()=>{

    this.setState({
        loading:true
      })         
          this.setState({
          response: fetch('https://www.vimasmiham.com/get_faqs', {
          method: 'GET',
          }).then((response) => response.json())
          .then((responseJson)=>{
              console.log(responseJson,'xxx')
            var draft_count=0,published_count=0,trash_count=0
              for(var v=0;v<responseJson.length;v++){
                 
                  responseJson[v].selected = 0
                  if(responseJson[v].status==0){
                    draft_count=draft_count+1
                  }
                  else if(responseJson[v].status==1){
                    published_count=published_count+1
                                      }
                  else if(responseJson[v].status==2){
                    trash_count=trash_count+1
                       }
                  }
            this.setState({
                subjects:responseJson,
                draft_count:draft_count,
                published_count:published_count,
                trash_count:trash_count
                },()=>this.getFaqSEO())
               } 
          )
          .catch((error) => {
          this.setState({
          loading:false
          })
          swal("Warning!", "Check your network!", "warning");
        console.log(error)
              })
          })
    } 

  

    handleEditSubject(item){
      window.localStorage.removeItem('saved_faq')
        window.localStorage.setItem('saved_faq', JSON.stringify(item))
      
        this.props.history.push('/admin/edit-faq');
     }

     handleAddNewSubject(){
      window.localStorage.setItem('subject_list',JSON.stringify(this.state.subjects))
      this.props.history.push('/admin/add-faq');
   }

      renderActions(item,index){
          if(item.selected==1){
              if(this.state.trash_selected){
                return( <div style={{width:'15%'}} className='actions_div'> <span onClick={()=>this.restoreAlert(item)} className='actionstext'>Restore</span>
                 <span onClick={()=>this.deleteAlert(item)} className='actionstext' style={{color:'#a00'}}>Delete</span>
                </div>)
              }
              else{
                return( <div  className='actions_div'><span onClick={()=>this.handleEditSubject(item,index)} 
                className='actionstext'>Edit</span> 
                </div>)
              }
          }
          else{
            return( <div className='actions_div' style={{visibility:'hidden'}}><span className='actionstext'>Edit</span> </div>)    
          }
      }
      showactionbutton(item,index){
          let {subjects} = this.state
          let selected_page = subjects[index]
          for(var v=0;v<subjects.length;v++){
            subjects[v].selected=0
          }
          selected_page.selected=1 
          this.setState({
            subjects
          })
      }
      hideactionbutton(){
        let {subjects} = this.state
       
        for(var v=0;v<subjects.length;v++){
            subjects[v].selected=0
        }
      
        this.setState({
            subjects
        })
      }
      renderStatus(item){
          if(item.status==0){
              return 'Last Modified'
          }
          else{
              return 'Published'
          }
      }
      renderFacultySEODescription(){
        return(
          <div className="col-12  subtitle-section d-flex mt-3 mr-5 ">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2">
                    Faq SEO Description:
               </label>
               <input
                  
                  value={this.state.faqDescription}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-subtitle-form custom-form ml-4"
                      onChange={(text)=>this.setState({faqDescription:text.target.value})}
                  />
          </div>
      )
      }
      editFaqSEO() {
        console.log(this.state)
        let formData = new FormData();
        formData.append("faq_title_seo", this.state.faqTitle);
        formData.append("faq_description_seo", this.state.faqDescription);
        formData.append("id",1)
        this.setState({
          response: fetch("https://www.vimasmiham.com/edit_faq_seo", {
            method: "POST",
            body: formData
          })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson, "QWERTy");
              swal("Success", "Faculty SEO succesfullly added", "success");
              this.props.history.push("/admin/all-faqs");
            })
            .catch(error => {
              swal("Warning!", "Check your network!", "warning");
              console.log(error);
            })
        });
      }
      renderFacultySEOSave(){
      
         return(
          <div className="col-12  subtitle-section d-flex mt-3 mr-5 ">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2">
                    
               </label>
               <button className="  custom-form  admin-button-style mb-5 " style={{marginLeft:'175px'}} onClick={()=>this.editFaqSEO()}> Save &amp; Publish</button>
          </div>
      )
      }
      renderFacultySEOTitle(){
        return(
          <div className="col-12  subtitle-section d-flex mt-3 ">
               <label htmlFor="defaultFormRegisterNameEx" className="subject-subtitle-label subject-labels mt-2 mr-5">
                    Faq SEO Title:
               </label>
               <input
                  
                  value={this.state.faqTitle}
                      type="text"
                      id="defaultFormRegisterNameEx"
                      className="form-control subject-subtitle-form custom-form ml-4"
                      onChange={(text)=>this.setState({faqTitle:text.target.value})}
                  />
          </div>
      )
      }


        changeStatus(item,status){
            this.setState({
                loading:true
              })
               let details = {
               faq_id:item.faq_id,
               status:status
             };
             console.log(details,'details')
             let formBody = [];
             for (let property in details) {
              let encodedKey = encodeURIComponent(property);
              let encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
             }
             formBody = formBody.join("&");
             
             this.setState({
              response: fetch('https://www.vimasmiham.com/edit_faq', {
                method: 'POST',
                headers: {
                 
                  'Content-Type': 'application/x-www-form-urlencoded',
                  'Cache-Control': 'max-age=31536000'
              },
             body:formBody
               
                
              }).then((response) => response.json())
              .then((responseJson)=>{
                   window.location.reload()
                
              }
             
              )
                .catch((error) => {
               this.setState({
                 loading:false
               })
               swal("Warning!", "Check your network!", "warning");
             console.log(error)
                 })
                })
        }

        
        // restorePage(item){
        //     // this.setState({
        //     //     loading:true
        //     //   })
        //     //    let details = {
        //     //    id:item.id,
              
        //     //  };
        //     //  console.log(details,'details')
        //     //  let formBody = [];
        //     //  for (let property in details) {
        //     //   let encodedKey = encodeURIComponent(property);
        //     //   let encodedValue = encodeURIComponent(details[property]);
        //     //   formBody.push(encodedKey + "=" + encodedValue);
        //     //  }
        //     //  formBody = formBody.join("&");
             
        //     //  this.setState({
        //     //   response: fetch('http://18.221.183.249:3000/restore_content', {
        //     //     method: 'POST',
        //     //     headers: {
                 
        //     //       'Content-Type': 'application/x-www-form-urlencoded',
        //     //       'Cache-Control': 'max-age=31536000'
        //     //   },
        //     //  body:formBody
               
                
        //     //   }).then((response) => response.json())
        //     //   .then((responseJson)=>{
        //     //        window.location.reload()
                
        //     //   }
             
        //     //   )
        //     //     .catch((error) => {
        //     //    this.setState({
        //     //      loading:false
        //     //    })
        //     //    swal("Warning!", "Check your network!", "warning");
        //     //  console.log(error)
        //     //      })
        //     //     })
        // }
  

  renderActiveStatus(item){
    if(item.status==1){
      return <img src={ActivePNG} alt="Course is active" className="course-status-icon" onClick={()=>this.changeStatus(item,0)}/>
    }
    else{
      return <img src={InactivePNG} alt="Course is INactive" className="course-status-icon" onClick={()=>this.changeStatus(item,1)} />
    }
  }
    
commentdate(timestamp){ 
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Novr", "Dec"
];
  var dt = new Date(timestamp);
  var dd = dt.getDate(); 
  var mm = (dt.getMonth()+1); 
  if (dd < 10) { 
      dd = '0' + dd; 
  } 
  if (mm < 10) { 
      mm = '0' + mm; 
  } 
 return (  dd +"/"+mm+ "/" +(dt.getFullYear()).toString());
}
  renderTableData(item,index){
      
  if(this.state.all_selected==true ){
      if( item.status!=2){
      // console.log(this.state.all_selected,item.status,'bigE')
      return (     
        <tr key={item.tax_id}>
        {/* <td><input  onChange={()=>this.onToggle(index)} checked={item.checked==1 ? true : false} type='checkbox' /></td> */}
        <td style={{width:'75%'}} className="tdName textFontSize" >
        <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
        className='faq_name_div_table'>
        <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.faq_question}</span>
        {this.renderActions(item,index)}
        </div></td>
        <td >   <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{this.commentdate(item.ts)}</span> 
        </td>
        <td>{this.renderActiveStatus(item)}</td>
        </tr>
         )
      }

  
  }
  else if(this.state.published_selected==true ){
  if(item.status==1){
  return (  
      <tr key={item.tax_id}>
      {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
   <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_name}</span>
      {this.renderActions(item,index)}
      </div></td>
      <td >   <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_value}</span> </td>
                          <td>{this.renderActiveStatus(item)}</td>
      
                              </tr>)
  }
  

}
else if(this.state.draft_selected==true){
  if( item.status==0){
  return (      
    <tr key={item.tax_id}>
      {/* <td><input className='checkbox_content' type='checkbox' /></td> */}
      <td style={{width:'25%'}} className="tdName textFontSize" >
      <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
      className='name_div_table'>
     <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_name}</span>
      {this.renderActions(item,index)}
      </div></td>
      <td >   <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_value}</span> </td>
                 <td>{this.renderActiveStatus(item)}</td>
                           </tr>)
  }


}
// else if(this.state.trash_selected==true){
// if( item.status==2){
//   return (         <tr key={item.tax_id}>
//       <td><input className='checkbox_content' type='checkbox' /></td>
//       <td style={{width:'25%'}} className="tdName textFontSize" >
//       <div onMouseEnter={()=>this.showactionbutton(item,index)} onMouseLeave={()=>this.hideactionbutton()} 
//       className='name_div_table'>
//       <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_name}</span>
//       {this.renderActions(item,index)}
//       </div></td>
//           <td className="datefontsize">{this.renderStatus(item)} <br />
//           <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}}>{item.tax_value}</span></td>     </tr>)
//                       }
//             }
    }


  onToggle(index, e){
    console.log(index,'kjkk')
  let {subjects,selected_data} = this.state
  let selected_item=subjects[index]
  if(selected_item.checked==1){
   selected_item.checked=0
   selected_data.splice(index,1)
  }
  else{
    selected_item.checked=1
  selected_data.push(selected_item)
  }
  
  this.setState({selected_data})
  this.setState({subjects})
  
  }
  
  
handleAllChecked = (event) => {
  let {subjects} = this.state
if(this.state.item_selected){
  for(var v=0;v<subjects.length;v++){
    subjects[v].checked=1
  }
  this.setState({selected_data:subjects})
}
else{
  for(var v=0;v<subjects.length;v++){
    subjects[v].checked=0
  }
  this.setState({selected_data:[]})
}
  this.setState({subjects})
  
}

  render() {
    if(this.state.loading){
      return( <div className='sweet-loading'>
      <GridLoader
      css={override}
      sizeUnit={"px"}
      size={25}
      color={'#2fb2eb'}
      loading={this.state.loading}
      />
  </div>)
    } 
   else{
    return (
      <div id="subject-page-list">
           <AdminNavbar {...this.props}/>
      <div style={{display:'flex',flexDirection:'row'}}>
      <SidebarPage {...this.props}  active={6} />
      
        <div className='colDefault' style={{width:'100%',backgroundColor:'#F9F9F9'}}>
        <div className="page_heading"><h3 style={{marginBottom:0,marginRight:'5px'}}>All FAQs</h3>
        <span onClick={()=>this.handleAddNewSubject()} className='add_new_button'>Add New</span>
        </div> 
        <div>
        <ul className="subsubsub">
	<li className="all">
    <span onClick={()=>this.setState({all_selected:true,published_selected:false,trash_selected:false,draft_selected:false})}  className={this.state.all_selected?"text_Pub current":"text_Pub"} aria-current="page">All <span className="count">({this.state.draft_count+this.state.published_count})</span></span> |</li>
	<li className="publish">
    <span onClick={()=>this.setState({all_selected:false,published_selected:true,trash_selected:false,draft_selected:false})} className={this.state.published_selected ?"text_Pub current":"text_Pub"}  >Active <span className="count">({this.state.published_count})</span></span> |</li>
	<li className="draft">
    <span onClick={()=>this.setState({all_selected:false,published_selected:false,trash_selected:false,draft_selected:true})} className={this.state.draft_selected ?"text_Pub current":"text_Pub"}  >In-Active <span className="count">({this.state.draft_count})</span></span> |</li>
  
</ul>
        {/* <div className='tablenav'>
        <div className="alignleft actions bulkactions">
			<select name="action" id="bulk-action-selector-top">
<option value="-1">Bulk Actions</option>

	<option value="trash">Move to Trash</option>
</select>
<span onClick={()=>console.log(this.state.selected_data)} className='apply'>Apply</span>
		</div>
        </div> */}


              <MDBTable  striped className="pages_table" style={{backgroundColor:'#F9F9F9'}}>
              
                  <tr style={{backgroundColor:'white'}}>
                  {/* <th><input type="checkbox" onClick={(event)=> this.setState({item_selected:!this.state.item_selected},()=>this.handleAllChecked(event))}  checked={this.state.item_selected}  value="checkedall" /></th> */}
                       <th className="" >Question</th>
                      <th className="textFontSize">Date of Creation</th>
                      <th>Status</th>
                  </tr>
                 
                  <MDBTableBody>
                  { this.state.subjects.map((item,index) => {
                    return this.renderTableData(item,index)
                              })}
                  </MDBTableBody>
              </MDBTable>
              <div className="mt-5 ml-3">
                 <h3>Faq SEO</h3>
                 {this.renderFacultySEOTitle()}
                 {this.renderFacultySEODescription()}
               {this.renderFacultySEOSave()}
                
                </div>
         
        </div>
       
      
             
           </div>
        </div>
      </div>

  );
   }
}
}

export default allFAQ;


