import React from "react";
import { Link } from "react-router-dom";

export default function Quicklinks() {
  return (
    <div
      className="redirect-boxes d-none d-md-flex"
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Link className="btn" style={redirectButtons} to="/acts_and_regulations">
        Acts
      </Link>
      <Link className="btn" style={redirectButtons} to="/regulations">
        Regulations
      </Link>
      <Link className="btn" style={redirectButtons} to="/circulars">
        Circulars
      </Link>
      <Link className="btn" style={redirectButtons} to="/case-laws">
        Case Laws
      </Link>
      <a
        className="btn"
        style={redirectButtons}
        href="https://vimasmiham.com/blog/"
        target="_black"
      >
        Blog
      </a>
      <Link className="btn" style={redirectButtons} to="/latest-news">
        News
      </Link>
    </div>
  );
}

const redirectButtons = {
  textTransform: "capitalize",
  borderRadius: "4px",
  fontSize: "16px",
  fontFamily: "Ubuntu-M",
  color: "#908b8b",
};
