import React, { Component } from "react";

import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import "./examSeries.css";
import swal from "sweetalert";
import DashboardBannerImage from "../../Assets/images/DashboardBannerImage.png";
import "../Dashboard/dashboard.css";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import { Link } from "react-router-dom";
import Quicklinks from "../../Reusable/QuickLinks/Quicklinks";
import ScrollAnimation from "react-animate-on-scroll";

const redirectButtons = {
  textTransform: "capitalize",
  borderRadius: "4px",
  fontSize: "16px",
  fontFamily: "Ubuntu-M",
  color: "#908b8b",
};

export default class ExamSeries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bannerImage: `${DashboardBannerImage}`,
      testsArray: [],
      examsArray: [],
      order_items: [],
      purchased_exams: [],
      loginToggle: false,
      showToast: false,
      addedToCartTests: {},
      cart: null,
    };
  }

  componentWillMount() {
    this.getUserDetails();
  }

  componentDidUpdate() {}

  getUserDetails() {
    console.log("get user details");
    this.setState({ loading: true });
    var userdata = window.localStorage.getItem("userdata");
    if (userdata != null) {
      this.setState(
        {
          userdata: JSON.parse(userdata),
        },
        () => this.getCart()
      );
    } else {
      this.setState({ userdata: null }, () => this.getExaminations());
    }
  }

  getCart = () => {
    console.log("get cart");

    let details = {
      user_id: this.state.userdata.user_id,
    };

    console.log(details, "fetching-details");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "cart");

          this.setState(
            {
              cart: responseJson,
            },
            () => this.getExaminations()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getTestsInCart = () => {
    const cart =
      (this.state.cart &&
        this.state.cart.length !== 0 &&
        this.state.cart[0].items &&
        JSON.parse(this.state.cart[0].items)) ||
      [];
    console.log(cart, "tests in cart");
    const addedToCartTests = {};
    // console.log(this.state.cart, this.state.cart?.items, this.state.cart.items && JSON.parse(this.state.cart.items), 'cart')
    cart.forEach((item) => {
      console.log(item, "cart item");
      if (item.test_included == 1) {
        addedToCartTests[item.subject_id] = true;
      }
    });
    console.log(addedToCartTests);
    this.setState({ addedToCartTests, loading: false });
  };

  getNewCart = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    //  console.log(details,'fetching-details')
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_cart", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          //   console.log(responseJson,'cart')
          this.setState(
            {
              cart: responseJson,
            },
            () => this.getTestsInCart()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  };

  getExaminations = () => {
    console.log("get examinations");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_examinations", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          this.setState(
            {
              examsArray: responseJson,
            },
            () => this.getTests()
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  getTests() {
    console.log("get tests");

    fetch("https://www.vimasmiham.com/get_tests", {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (this.state.userdata == null) {
          this.setState({
            testsArray: responseJson,
            loading: false,
          });
        } else {
          this.setState(
            {
              testsArray: responseJson,
            },
            () => this.getOrders()
          );
        }
      });
  }

  getOrders = () => {
    let details = {
      user_id: this.state.userdata.user_id,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/get_my_orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var items = [];
          var exam_items = [];
          for (var v = 0; v < responseJson.length; v++) {
            for (
              var v1 = 0;
              v1 < JSON.parse(responseJson[v].items).length;
              v1++
            ) {
              var data = JSON.parse(responseJson[v].items)[v1];
              data.creation_time = responseJson[v].ts;
              if (data.exam_included == false) {
                items.push(data);
              }
              if (data.exam_included == true) {
                exam_items.push(data);
              }
            }
          }

          this.setState({ purchased_exams: exam_items });
          console.log(items, "orig");
          this.checkDuplicates(items);
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  checkDuplicates(originalArray) {
    console.log("checking duplicates");

    var trimmedArray = [];

    var all_courses = originalArray;
    if (originalArray.length != 0) {
      for (var i = 0; i < originalArray.length; i++) {
        for (var i1 = 0; i1 < originalArray.length; i1++) {
          if (i != i1) {
            console.log(originalArray[i], i, originalArray[i1], i1);
            if (originalArray[i].subject_id == originalArray[i1].subject_id) {
              //   console.log(originalArray[i],originalArray[i1],'iii')
              var matched_object = originalArray[i];

              matched_object.subject_included = 1;
              matched_object.test_included = 1;
              trimmedArray.push(matched_object);
            }
          }
        }
      }
    }
    console.log(all_courses, "iii");

    // }
    for (var i3 = 0; i3 < all_courses.length; i3++) {
      for (var i4 = 0; i4 < trimmedArray.length; i4++) {
        if (all_courses[i3].subject_id == trimmedArray[i4].subject_id) {
          all_courses.splice(i3, 1);

          all_courses.splice(i3, 0, trimmedArray[i4]);
        }
      }
    }
    this.removeDuplicates(all_courses, "subject_id");
  }

  removeDuplicates(originalArray, objKey) {
    console.log("removing duplicates");

    var trimmedArray = [];
    var values = [];
    var value;

    for (var i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];

      if (values.indexOf(value) === -1) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    let order_items = trimmedArray.filter((item) => item.test_included === 1);
    this.setState(
      {
        order_items,
      },
      () => this.getTestsInCart()
    );
  }

  handleExamStart(item) {
    window.localStorage.removeItem("examination_item");
    window.localStorage.setItem("examination_item", JSON.stringify(item));
    this.props.history.push({
      pathname: "/start-exam",
    });
  }

  startTest(item) {
    window.localStorage.removeItem("selected_test");
    window.localStorage.setItem("selected_test", item.subject_id);

    this.props.history.push("start-test");
  }

  handleTestPurchase(item, redirect = true) {
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            subject_id: item.subject_id,
            test_included: 1,
            subject_included: 0,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showToast: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger(redirect)
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  handleExamPurchase(item) {
    console.log(item);
    if (this.state.userdata == null) {
      this.setState({ loginToggle: true });
    } else {
      let details = {
        user_id: this.state.userdata.user_id,
        items: JSON.stringify([
          {
            type: 1,
            exam_id: item.exam_id,
          },
        ]),
      };

      console.log(details, "fetching-details");
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      this.setState({
        response: fetch("https://www.vimasmiham.com/update_cart", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "update_cart");
            this.setState(
              {
                showToast: true,
                cart_status: responseJson.status,
                item_in_cart: true,
                loading: false,
              },
              () => this.selectiveTrigger()
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  selectiveTrigger(redirect) {
    this.trigger();
    if (this.state.userdata && redirect) this.props.history.push("/my-cart");
  }

  trigger() {
    this.getNewCart();
    setTimeout(() => {
      this.setState({
        showToast: false,
      });
    }, 2000);
  }

  renderExamsList() {
    if (this.state.examsArray.length > 0)
      return (
        <React.Fragment>
          <h3 className="all-exams-heading mt-3 mb-3">
            Exams for Combined Subjects
          </h3>
          {this.state.examsArray.map((item) => {
            console.log(item, "examsArrayy");
            if (item.status != 0) {
              var examItem = this.state.purchased_exams.find(
                (element) => element.exam_id == item.exam_id
              );
              if (examItem === undefined) {
                return (
                  <div
                    key={item.exam_id}
                    className="row align-items-center  py-4 px-3 test-series-item my-2"
                  >
                    <div className="test-name">
                      <span style={{ fontWeight: "bold", fontSize: 20 }}>
                        {item.exam_name}{" "}
                      </span>
                      <p
                        style={{
                          color: "#0074c5",
                          marginBottom: 0,
                          fontSize: 13,
                        }}
                      >
                        Subjects Covered
                      </p>
                      {JSON.parse(item.exam_config).map((item1, index1) => {
                        return (
                          <p style={{ fontSize: 13, marginBottom: 0 }}>
                            {item1.subject_name}
                          </p>
                        );
                      })}
                    </div>
                    <div className="test-detail text-right d-flex flex-column align-items-center">
                      <p style={{ marginBottom: 0 }}>
                        Test Duration: {item.duration} minutes
                      </p>
                      <span className="mb-1">Rs.{item.cost}/-</span>
                      <button
                        className="start-learning btn-custom text-white"
                        onClick={() => this.handleExamPurchase(item)}
                      >
                        Buy now
                      </button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={item.exam_id}
                    className="row align-items-center  py-4 px-3 test-series-item my-2"
                  >
                    <div className="test-name">
                      <span style={{ fontWeight: "bold", fontSize: 20 }}>
                        {item.exam_name}{" "}
                      </span>
                      <p
                        style={{
                          color: "#0074c5",
                          marginBottom: 0,
                          fontSize: 13,
                        }}
                      >
                        Subjects Covered
                      </p>
                      {JSON.parse(item.exam_config).map((item1, index1) => {
                        return (
                          <p style={{ fontSize: 13, marginBottom: 0 }}>
                            {item1.subject_name}
                          </p>
                        );
                      })}
                    </div>
                    <div className="test-detail text-right d-flex flex-column align-items-center">
                      <p style={{ marginBottom: 0 }}>
                        Test Duration: {item.duration} minutes
                      </p>
                      <span className="mb-1 text-muted">
                        <i>Item purchased</i>
                      </span>
                      <button
                        className="start-learning btn-custom text-white"
                        onClick={() => this.handleExamStart(item)}
                      >
                        Start Exam
                      </button>
                    </div>
                  </div>
                );
              }
            }
          })}
        </React.Fragment>
      );
  }

  toastBar = () => {
    return (
      <ScrollAnimation
        animateOnce={false}
        animateIn="slideInUp"
        className="slideInUp toast2"
        animationOut="slideOutLeft"
        isVisible={this.state.showToast}
      >
        <div
          id="snackbar"
          className={`${this.state.showToast ? "show" : ""}`}
          style={{
            width: "200px",
            height: "fit-content",
            borderRadius: "15px",
            justifyContent: "center",
          }}
        >
          Added to cart{" "}
        </div>
      </ScrollAnimation>
    );
  };

  goToCart() {
    this.props.history.push("/my-cart");
  }

  renderTestsList() {
    return this.state.testsArray
      .filter((item) => item.status == 1)
      .map((item) => {
        var orderItem = this.state.order_items.find(
          (element) => element.subject_id == item.subject_id
        );
        //IF NOT LOGGED IN
        if (orderItem === undefined) {
          return (
            <div
              className="row align-items-center  py-4 px-3 test-series-item my-2 "
              key={item.test_id}
            >
              <div className="test-name">{item.test_name}</div>
              <div className="test-detail text-right d-flex flex-column align-items-center">
                <p style={{ marginBottom: 0 }}>
                  Test Duration: {item.duration} minutes
                </p>

                <span className="mb-1">Rs.{item.cost}/-</span>
                <div className="d-flex">
                  <button
                    className="start-learning btn-custom mr-2"
                    style={{
                      background: "white",
                      color: "black !important",
                      fontWeight: 700,
                    }}
                    onClick={() =>
                      this.state.addedToCartTests[item.subject_id]
                        ? this.goToCart()
                        : this.handleTestPurchase(item, false)
                    }
                  >
                    {this.state.addedToCartTests[`${item.subject_id}`]
                      ? "Go to cart"
                      : "Add to cart"}
                  </button>
                  <button
                    className="start-learning btn-custom text-white"
                    onClick={() => this.handleTestPurchase(item)}
                  >
                    Buy now
                  </button>
                </div>
                <p className="text-muted text-center test-pc-or-desktop d-none">
                  <i>Tests can only be attempted on a PC or Laptop.</i>
                </p>
              </div>
            </div>
          );
        }

        //IF LOGGED IN
        else {
          if (orderItem.test_included != 1) {
            console.log("this one");

            return (
              <div
                className="row align-items-center py-4 px-3 test-series-item my-2 "
                key={item.test_id}
              >
                <div className="test-name">{item.test_name}</div>
                <div className="test-detail text-right d-flex flex-column align-items-center">
                  <p style={{ marginBottom: 0 }}>
                    Test Duration: {item.duration} minutes
                  </p>
                  <span className="mb-1">Rs.{item.cost}/-</span>
                  <div className="d-flex">
                    <button
                      className="start-learning btn-custom mr-2"
                      style={{
                        background: "white",
                        color: "black !important",
                        fontWeight: 700,
                      }}
                      onClick={() =>
                        this.state.addedToCartTests[item.subject_id]
                          ? this.goToCart()
                          : this.handleTestPurchase(item, false)
                      }
                    >
                      {this.state.addedToCartTests[`${item.subject_id}`]
                        ? "Go to cart"
                        : "Add to cart"}
                    </button>
                    <button
                      className="start-learning btn-custom text-white"
                      onClick={() => this.handleTestPurchase(item)}
                    >
                      Buy now
                    </button>
                  </div>
                  <p className="text-muted text-center d-none">
                    <i>Tests can only be attempted on a PC or Laptop.</i>
                  </p>
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="row align-items-center py-4 px-3 test-series-item my-2 "
                key={item.test_id}
              >
                <div className="test-name">{item.test_name}</div>
                <div className="test-detail text-right d-flex flex-column align-items-center">
                  <span className="mb-1 text-muted">
                    <i>Item purchased</i>
                  </span>
                  <button
                    className="start-learning start-test-button-testseries btn-custom text-white"
                    onClick={() => this.startTest(item)}
                  >
                    Start Test
                  </button>
                  <p className="text-muted text-center d-none">
                    <i>Tests can only be attempted on a PC or Laptop.</i>
                  </p>
                </div>
              </div>
            );
          }
        }
      });
  }

  renderTestSeriesBanner() {
    return (
      <div className="TestSeries-main ">
        <div className="TestSeries-overlay d-block d-sm -none d-lg-block"></div>
        <div className="TestSeries-Banner-Text">
          <h1 className="TestSeries-heading">'TEST SERIES'</h1>

          <span className="TestSeries-Para">
            The exam series has been designed by industry experts to mimic the
            actual exam, and uses fuzzy logic to randomly pick questions from a
            bank of more than 3000 questions. The candidates are therefore
            encouraged to take the mock tests as many times as possible,
            especially since additional questions are also being added to the
            question bank every week.
          </span>
        </div>
      </div>
    );
  }

  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div id="test-series-page">
            <NewHeader
              {...this.props}
              cart={
                this.state.cart
                  ? this.state.cart.length == 0
                    ? 0
                    : JSON.parse(this.state.cart[0].items).length
                  : 0
              }
              showLogin={this.state.loginToggle}
            />

            {this.renderTestSeriesBanner()}

            <Quicklinks />
            <div className="exam-series-container">
              {this.renderExamsList()}
              <h3 className="all-tests-heading mt-3 mb-3">
                {" "}
                Test Series for Individual Subjects
              </h3>
              {this.renderTestsList()}
            </div>
          </div>
          <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div className="mobView fixed-bottom">
            <StickyFooterPage {...this.props} />
          </div>
          {this.toastBar()}
        </React.Fragment>
      );
    }
  }
}
