import React from "react";
import "../../Components/Exam-News/Exam-news.css";
import '../../Components/Homepage/homepage.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import swal from 'sweetalert'
import "react-responsive-carousel/lib/styles/carousel.min.css";


import "./AddPage.css";

import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import MainFooterPage from "../../Reusable/Footer/footer";
import {Link} from 'react-router-dom'

class AddPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      getData:null,

    };
  }
  componentWillMount() {
   
    this.getData();
  }
 

  getData = () => {
  this.setState({
    loading:true
  })
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_content", {
        method: "GET",
        headers: {
                   
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'max-age=31536000'
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          let allData=responseJson
          const currentRoute= this.props.location.pathname.substr(1);

          for(let i=0;i<allData.length;i++){
            let x =allData[i].page_url
            if (currentRoute === x) {


              this.setState({
                getData:allData[i],
               loading:false
              });

           
            }
      

          }

        
        })
        .catch(error => {
          swal("Warning!", "Check your network!", "warning");
          console.log(error);
        })
    });
  };


  render() {
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: black;
      margin: 30% 45%;
    `;

    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
        

        <NewHeader
            {...this.props}
            
            
            active="Court Cases"
          />
 
<div id="AddPages">
          
      <div>
 

      <div className='BannerImageDiv' >

        <img className='BannerImage'
            src={this.state.getData.image}
         
            alt="vima-logo"
     
          />
        
          <span className='BannerText'>{this.state.getData.name}</span>
         
        </div>

        <div className="redirect-boxes d-none d-md-flex" style={{display: 'flex',justifyContent: 'center',flexWrap: 'wrap'}}>
                <Link className="btn" style={redirectButtons} to="/acts_and_regulations" >Acts &amp; Regulations</Link>
                <Link className="btn" style={redirectButtons} to="/categories/promotional-exams">Promotional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/categories/professional-exams" >Professional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/latest-news" >Latest News</Link>
                <Link className="btn" style={redirectButtons} to="/test-series" >Test Series</Link>
                <Link className="btn" style={redirectButtons} to="/all-faculty" >All Faculty</Link>
                <Link className="btn" style={redirectButtons} to="/upcoming-exams" >Upcoming Exams</Link>
            
            </div> 
              <div   dangerouslySetInnerHTML={{__html:this.state.getData.description } } className='container-c'></div>
          
      
      </div>
          
                   
         

         

            <div style={{ height: "auto" }} className="windowView">
            <MainFooterPage {...this.props} />
          </div>
          <div  className="mobView">
            <StickyFooterPage {...this.props}  />
          </div>
        </div>
        </div>
      );
    }
  }
}

const redirectButtons = {
  textTransform:'capitalize',
  borderRadius:'4px',
  fontSize: '16px',
  fontFamily: 'Ubuntu-M',
  color: '#908b8b'
}


export default AddPage;
