import React, { Component } from "react";
import AdminNavbar from "../Navbar/adminNavbar";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import "../AddSubject/addSubject.css";
import "../Lectures/Addlecture.css";
import Select from "react-select";
import MediaComponent from "../MediaComponent/chooseMediaFile";
import {
  MDBNavbar,
  MDBBtn,
  MDBNavItem,
  MDBNavbarNav,
  MDBIcon,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBInput,
} from "mdbreact";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import swal from "sweetalert";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import Fade from "react-reveal";
import CKEditor from "ckeditor4-react";

// const topicsList=[];
// for(var i=1;i<=100;i++){
//     topicsList.push({id:i,name:`Topic ${i}`})
// }

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

class EditInfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadng: true,
      pageId: "",
      pageTitle: "",
      pageDescription: "",
      pageURL: "",
      inputLink: "",
      hometitle: "",
      homeDescription: "",
    };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(e) {
    console.log(e);
    this.setState({
      pageDescription: e.editor.getData(),
    });
  }
  componentWillMount() {
    // this.getpages()
    let pages = JSON.parse(localStorage.getItem("pages"));
    this.setState({
      pages: pages,
      pageTitle: pages.page_title,
      pageDescription: pages.content,
      pageURL: pages.page_url,
      pageId: pages.id,
      homeTitle: pages.seo_title,
      homeDescription: pages.seo_description,
    });
  }

  editPage() {
    if (this.state.pageTitle.length == 0) {
      swal("Check  name!", "Please enter valid name", "error");
    } else if (!this.state.pageURL) {
      swal("Check pageURL", "Please enter Page URL!", "error");
    } else {
      this.setState({
        loading: true,
        // add_modal:false
      });

      let formData = new FormData();
      formData.append("page_title", this.state.pageTitle);
      formData.append("content", this.state.pageDescription);
      formData.append("page_url", this.state.pageURL);
      formData.append("seo_title", this.state.homeTitle);
      formData.append("seo_description", this.state.homeDescription);
      formData.append("id", this.state.pageId);
      console.log(formData);
      // console.log(this.state.facultyName,this.state.facultyThumbnail,this.state.profession,this.state.description)
      this.setState({
        response: fetch("https://vimasmiham.com/edit_info_page", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "QWERTy");
            swal("Success", "Page succesfullly edited", "success").then(
              (value) => {
                this.props.history.push("/admin/info-pages");
              }
            );
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            swal("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  }

  renderHomeTitle = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          SEO Title:
        </label>
        <input
          value={this.state.homeTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.setState({ homeTitle: text.target.value })}
        />
      </div>
    );
  };
  renderHomeDescription = () => {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          SEO Description:
        </label>
        <input
          value={this.state.homeDescription}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) =>
            this.setState({ homeDescription: text.target.value })
          }
        />
      </div>
    );
  };
  //RENDER FUNCTIONS
  renderPageURL() {
    return (
      <div className="col-12  subject-name-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-name-label subject-labels"
        >
          Page URL:
        </label>
        <input
          value={this.state.pageURL}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-name-form custom-form"
          onChange={(text) => this.setState({ pageURL: text.target.value })}
        />
      </div>
    );
  }

  renderPageNameSection() {
    return (
      <div className="col-12  subtitle-section d-flex">
        <label
          htmlFor="defaultFormRegisterNameEx"
          className="subject-subtitle-label subject-labels"
        >
          Page Title:
        </label>
        <input
          value={this.state.pageTitle}
          type="text"
          id="defaultFormRegisterNameEx"
          className="form-control subject-subtitle-form custom-form"
          onChange={(text) => this.setState({ pageTitle: text.target.value })}
        />
      </div>
    );
  }

  renderPageDescription() {
    return (
      <React.Fragment>
        <div className="col-12 subject-description-container  d-flex align-items-center">
          <label
            htmlFor="defaultFormRegisterNameEx"
            className="subject-labels h-100"
          >
            Page Description:
          </label>
          <div className="descriptions-container">
            {this.renderFacultyDescriptions()}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderFacultyDescriptions() {
    console.log(this.state);
    return (
      <React.Fragment>
        <div className="d-flex align-items-start mt-3 mb-3">
          <div className="position-relative w-100 ml-3 mr-4">
            <CKEditor
              data={this.state.pageDescription}
              style={{ width: "88%" }}
              onChange={this.onEditorChange}
              className="textFontSize"
            />
          </div>
        </div>
        <div className="w-100" />
      </React.Fragment>
    );
  }
  render() {
    return (
      <div id="add-subject-page">
        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={1} />

          <div className="add-subject-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row my-3 px-4">
                  <div
                    className="col-12 top_button_bar d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <button
                      className="admin-button-style admin-button-style-margin"
                      onClick={() => this.props.history.push("/admin/info-pages")}
                    >
                      All Pages
                    </button>
                    <MediaComponent
                      onSelectMediaItem={this.handleMediaChooseImage}
                      buttonName={<MDBIcon icon="camera" />}
                      restriction="image/*"
                    />
                    <button
                      className="admin-button-style ml-auto"
                      onClick={() => this.editPage()}
                    >
                      {" "}
                      Save &amp; Publish
                    </button>
                  </div>
                  <div className="col-12 mt-4 d-flex justify-content-between align-items-center">
                    <h3> Edit Page</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="subject-content-container ml-4"
                    style={{ width: "80%" }}
                  >
                    <div className="row px-0 mx-0 ">
                      {this.renderPageNameSection()}
                      {this.renderPageDescription()}
                      {this.renderPageURL()}
                      {/* {this.renderChooseFile()} */}
                      {this.renderHomeTitle()}
                      {this.renderHomeDescription()}
                    </div>
                  </div>
                </div>

                <hr />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default EditInfoPage;
