import React from "react";
import SidebarPage from "../../../Components/Admin/Sidebar/sidebarPage";
import AdminNavbar from "../Navbar/adminNavbar";
import {
  MDBTable,
  MDBTableBody,
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBTableHead,
  MDBIcon,
} from "mdbreact";
import ReactToPrint from "react-to-print";
import ReactTooltip from "react-tooltip";
import { PDFExport } from "@progress/kendo-react-pdf";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import "./orderPage.css";
import { FormGroup, Label } from "reactstrap";
import RangePicker from "react-range-picker";
import "../TopicTests/test.css";
import swal from "sweetalert";
import Xlsx from "xlsx";
import Logo from "../../../Assets/images/logo.png";
let toWords = require("to-words");

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

class OrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      order_data: [],
      showModal: false,
      modalData: null,
      toggle: false,
      start_date: null,
      end_date: null,
      showToggle: true,
      showInvoiceModal: false,
      tax: [],
    };
  }

  componentWillMount() {
    this.getOrders();
  }

  downloadAsExcel = () => {
    const data = this.state.selected_orders.map((ele) => {
      return {
        "Invoice No": ele.invoice_number,
        "Customer Name": ele.name,
        Amount: ele.cost_exc_tax,
        SGST: ele.SGST,
        CGST: ele.CGST,
        IGST: ele.IGST,
        total: ele.amount,
        "State Name": JSON.parse(ele.address).state || "-",
      };
    });

    const worksheet = Xlsx.utils.json_to_sheet(data);

    const workbook = {
      Sheets: {
        data: worksheet,
      },
      SheetNames: ["data"],
    };

    const excelBuffer = Xlsx.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    console.log("excel buffer", excelBuffer);
    this.saveAsExcel(
      excelBuffer,
      `invoice_exported_at_${new Date().getTime()}.xlsx`
    );
  };

  saveAsExcel = (buffer, filename) => {
    const data = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    window.saveAs(data, filename);
  };

  //GET FUNCTION
  getOrders = async () => {
    console.log("fetching orders");
    this.setState({
      loading: true,
    });
    this.setState({
      response: fetch("https://www.vimasmiham.com/get_orders", {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson, "orders");

          var selected_orders = responseJson.filter((item) => {
            var today = new Date();
            var order_date = new Date(item.ts);
            if (today.getTime() - order_date.getTime() < 269200000) return item;
          });

          this.setState({
            order_data: responseJson,
            selected_orders,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });
          alert("Warning!", "Check your network!", "warning");
          console.log(error);
        }),
    });
  };

  componentDidMount() {
    window.localStorage.removeItem("subject_item");
  }

  //TIMESTAMP FUNCTION
  commentdate(timestamp) {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Novr",
      "Dec",
    ];
    var dt = new Date(timestamp);
    var dd = dt.getDate();
    var mm = dt.getMonth() + 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "/" + mm + "/" + dt.getFullYear().toString();
  }

  //CLICK HANDLERS
  handleOptionChange = (index) => {
    let { subjects } = this.state;
    var subject = subjects[index];
    subject.status == 1 ? (subject.status = 0) : (subject.status = 1);
    this.setState({ subjects });
  };

  handleEditSubject(item) {
    window.localStorage.setItem("subject_item", JSON.stringify(item));
    this.props.history.push("/admin/edit-subject");
  }

  initiateRefund(data) {
    let details = {
      order_id: data.order_id,
      payment_id: data.payment_id,
    };

    console.log(details, "initiating refund");
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    this.setState({
      response: fetch("https://www.vimasmiham.com/refund", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "max-age=31536000",
        },
        body: formBody,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          window.location.reload();
          console.log(responseJson, "refund");

          this.setState({
            cart: responseJson,
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loading: false,
          });

          console.log(error);
        }),
    });
  }

  toggle() {
    this.setState({ showModal: false });
  }

  toggleData(item) {
    this.setState({ showModal: true, modalData: item });
    console.log(this.state.modalData);
  }

  renderRefundStatus(data) {
    if (data.refund == 0) {
      return (
        <div className="col-12 refund-column text-center">
          <MDBBtn
            color="danger"
            size="md"
            onClick={() => this.initiateRefund(data)}
          >
            Initiate Refund
          </MDBBtn>
        </div>
      );
    } else {
      return (
        <div className="col-12 refund-column text-center">
          <p>Order refunded</p>
        </div>
      );
    }
  }

  handleViewAll() {
    var selected_orders = this.state.order_data.filter((item) => {
      var today = new Date();
      var order_date = new Date(item.ts);
      if (today.getTime() - order_date.getTime() < 269200000) return item;
    });
    this.setState({ selected_orders: this.state.order_data, showToggle: true });
  }

  renderOrderdetailsModal(modalData) {
    if (modalData != null)
      return (
        <MDBContainer>
          <MDBBtn onClick={() => this.toggle()}>Modal</MDBBtn>
          <MDBModal
            size="lg"
            isOpen={this.state.showModal}
            toggle={() => this.toggle()}
          >
            <MDBModalHeader toggle={() => this.toggle()}>
              Order Details
            </MDBModalHeader>
            <MDBModalBody>
              <div className="order-details-modal-body">
                <div className="row my-3">
                  <div className="col-6 px-5 text-left">
                    Name: {modalData.name}
                  </div>
                  <div className="col-6 text-left"></div>
                  <div className="w-100" />
                  <div className="col-6 px-5 text-left">
                    Phone: {modalData.phone}
                  </div>
                  <div className="col-6 text-left"></div>
                </div>
                <div className="product-order-details-container">
                  <div className="row">
                    <div className="col-12 package-name-column">
                      Package Name <br />
                      {JSON.parse(modalData.items)[0].subject_name}
                    </div>
                    <hr />
                    <div className="col-12 purchase-date-amount-column">
                      <span>
                        {" "}
                        Purchase Date: <br />{" "}
                        {this.commentdate(
                          JSON.parse(modalData.items)[0].creation_time
                        )}
                      </span>
                      <span>
                        {" "}
                        Purchase Amount: <br />
                        &#8377; {modalData.amount}
                      </span>
                    </div>
                    <hr />
                    <div className="col-12 status-column">
                      <span>
                        {" "}
                        Status:{" "}
                        {modalData.status == 0 ? (
                          <span style={{ color: "red" }}>Expired</span>
                        ) : (
                          <span style={{ color: "green" }}>Active</span>
                        )}
                      </span>
                    </div>
                    <hr />
                    <div className="col-12 transaction-column">
                      <span>Mode of transaction: UPI </span>
                      <span>Transaction ID: {modalData.payment_id}</span>
                    </div>
                    <hr />
                    {this.renderRefundStatus(modalData)}
                  </div>
                </div>
              </div>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={() => this.toggle()}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModal>
        </MDBContainer>
      );
  }

  //RENDER

  renderActiveStatus(item, index) {
    if (item.status === 1) {
      return (
        <FormGroup style={{ display: "flex" }}>
          <Label
            for="exampleEmail"
            className="grey-text"
            style={{ color: "#008EC2" }}
          >
            Active
          </Label>
          {/* <Input value={item.status} onClick={()=>this.handleOptionChange(index)} checked={item.status==1 ? true:false}             label='Active'
              type="radio" /> */}
        </FormGroup>
      );
    } else if (item.status === 0) {
      return (
        <div>
          <FormGroup style={{ display: "flex" }}>
            <Label
              for="exampleEmail"
              className="grey-text"
              style={{ color: "red" }}
            >
              Expired
            </Label>
          </FormGroup>
        </div>
      );
    }
  }

  handleRegenerateInvoice(data) {
    let details = {
      user_id: data.user_id,
    };
    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    this.setState({ loading: true });
    fetch("https://www.vimasmiham.com/get_user_details", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "max-age=31536000",
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson, "my user");
        const parseCart = JSON.parse(data.items);
        console.log("parse cart", parseCart);
        this.setState({
          // userdata: responseJson[0],
          userdata: {
            name: data.name,
            email: responseJson[0].email,
            phone: responseJson[0].phone,
          },
          state: responseJson[0].city,
          address_edit_text: responseJson[0].address,
          date: new Date(data.ts),
          myCart: parseCart,
          old_total: data.cost_exc_tax,
          calculated_tax: data.CGST + data.SGST + data.IGST,
          promoApplied: data.promo_id > 0,
          discount: (
            parseCart.reduce((accumulator, ele) => {
              return (
                (ele.test_included ? JSON.parse(ele.test_id)[0].cost : 0) +
                accumulator +
                Number(ele.cost)
              );
            }, 0) - data.cost_exc_tax
          ).toFixed(2),
          tax: [
            { tax_name: "IGST", tax_value: data.IGST },
            { tax_name: "CGST", tax_value: data.CGST },
            { tax_name: "SGST", tax_value: data.SGST },
          ],
          invoiceNumber: data.invoice_number,
          order_id: data.order_id,
          loading: false,
          showInvoiceModal: true,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        console.log(error);
      });

    console.log("regen", data);

    // console.log("tax", this.state.tax);
  }

  renderDiscountRow() {
    if (this.state.promoApplied) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td></td>
          <td>Discount </td>

          <td style={{ textAlign: "end" }}>- {this.state.discount}</td>
        </tr>
      );
    }
  }

  renderTestCostRow(data, key) {
    if (data.test_included == 1) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td>{data.subject_included ? key + 2 : key + 1}</td>
          <td>{data.subject_name} Test</td>

          <td style={{ textAlign: "end" }}>
            {Number(JSON.parse(data.test_id)[0].cost)}
          </td>
        </tr>
      );
    }
  }

  renderSujectCostRow(data, key) {
    if (data.subject_included == 1) {
      return (
        <tr style={{ fontSize: 14 }}>
          <td>{key + 1}</td>
          <td>{data.subject_name} </td>

          <td style={{ textAlign: "end" }}>{Number(data.cost)}</td>
        </tr>
      );
    }
  }

  renderCostWithoutTax() {
    return (
      <>
        <tr>
          <td></td>
          <td>
            <strong>Taxable value</strong>
          </td>
          <td style={{ textAlign: "right" }}>{Number(this.state.old_total)}</td>
        </tr>
      </>
    );
  }

  renderTaxColumn() {
    return this.state.tax.map((tax_data) => (
      <>
        <tr style={{ fontSize: 14 }}>
          <td>{tax_data.tax_name}</td>
          <td>{tax_data.tax_value}%</td>

          <td style={{ textAlign: "end" }}>
            {(Number(this.state.old_total) * tax_data.tax_value) / 100}
          </td>
        </tr>
      </>
    ));
  }
  exportPDF = () => {
    this.resume.save();
  };

  renderInvoiceModal(fileData) {
    return this.state.showInvoiceModal ? (
      <React.Fragment>
        <div className="invoice_container" id="save_button">
          <div className="cart-invoice-modal">
            <MDBModalBody style={{ padding: 10, opacity: 1 }}>
              <div className="save_pdf_button_container">
                <MDBIcon
                  style={{
                    color: "#000",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ download_invoice: 1 }, this.exportPDF)
                  }
                  icon="save"
                  data-tip="Save Invoice"
                  size="lg"
                />
                <ReactTooltip place="bottom" />
                <ReactToPrint
                  trigger={() => (
                    <MDBIcon
                      style={{
                        color: "#000",
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      pageStyle="@page { size: auto; margin: 100mm; } }"
                      icon="print"
                      data-tip="Print Invoice"
                      size="lg"
                    />
                  )}
                  content={() => this.resume}
                />
                <MDBIcon
                  style={{
                    color: "#000",
                    marginRight: 10,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState(
                      { download_invoice: 0, showInvoiceModal: false }
                      // this.exportPDF
                    )
                  }
                  icon="times"
                  data-tip="Close"
                  size="lg"
                />
              </div>
              <PDFExport
                paperSize={"A4"}
                scale={0.8}
                // JSON.stringify(fileData)
                fileName={JSON.stringify({
                  order_id: this.state.order_id,
                  invoice_number: this.state.invoiceNumber,
                })}
                title=""
                subject=""
                // proxyTarget={this.state.download_invoice == 1 ? "_blank" : ""}
                proxyTarget={"_blank"}
                keywords=""
                forceProxy={true}
                crossorigin="anonymous"
                proxyURL="https://www.vimasmiham.com/add_order_invoice_regenerated"
                ref={(r) => (this.resume = r)}
              >
                <div id="invoice" style={{ margin: 10, marginTop: 0 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="logoDiv" stylle={{ width: "25%" }}>
                      <img
                        className="logo"
                        style={{ width: 100, marginTop: "80px" }}
                        src={Logo}
                        crossorigin="anonymous"
                      />
                      <div style={{ marginTop: "20px", fontSize: "14px" }}>
                        Name&nbsp;:&nbsp;
                        <text style={{ color: "black" }}>
                          <strong>{this.state.userdata.name}</strong>
                        </text>
                        <br />
                        Address&nbsp;:&nbsp;
                        <strong>
                          <text>{this.state.address_edit_text}</text>,
                          <text className="">
                            {this.state.state}
                            {/* {","} */}
                            {/* {this.state.pincode == null ||
                            this.state.pincode == "null"
                              ? ""
                              : this.state.pincode} */}
                          </text>
                        </strong>
                        <br />
                        Phone&nbsp;: &nbsp;
                        <text style={{ color: "black" }}>
                          <strong>{this.state.userdata.phone}</strong>
                        </text>
                        <br />
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        Email&nbsp;:&nbsp;
                        <text style={{ color: "black" }}>
                          <strong>{this.state.userdata.email}</strong>
                        </text>
                        <br />
                      </div>
                      <div style={{ marginTop: "20px", fontSize: "14px" }}>
                        GSTIN : <strong>NA</strong>
                        <br />
                        Place of Supply :{" "}
                        <strong>
                          {/* {this.state.state} */}
                          DELHI
                        </strong>
                        <br />
                        Type of Supply :{" "}
                        <strong>
                          {/* {this.state.state.toLowerCase() == "delhi"
                          ? "Intra-State Supply"
                          : "Inter-State Supply"} */}
                          Intra-State Supply
                        </strong>
                        <br />
                        State Code :{" "}
                        <strong>
                          07
                          {/* {StateCodes[this.state.state]} */}
                        </strong>
                        <br />
                        Reverse Charge Applicable (Yes or No) :{" "}
                        <strong>No</strong>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "75%",
                        padingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <text
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      ></text>
                      <div style={{ padding: 5, marginLeft: 10, fontSize: 14 }}>
                        <strong>
                          <span style={{ color: "#0099CC" }}>
                            <strong>VIMA SMIHAM</strong>
                          </span>
                          <br />
                          Sarswati Block-6 Pocket-6
                          <br />
                          Flat No. 202, Sector D, Vasant Kunj
                          <br />
                          New Delhi, South Delhi, Delhi, 110070
                          <br />
                          Tel&nbsp;: 0121-2233440
                          <br />
                        </strong>
                        <br />
                        GSTIN&nbsp;:&nbsp;<strong>07AAAPP7540E1ZV</strong>
                        <br />
                        Description of Service&nbsp;:&nbsp;
                        <strong>
                          Other Educational &amp; Training Services N.E.C.
                        </strong>
                        <br />
                        SAC&nbsp;:&nbsp;<strong>999299</strong>
                        <br />
                        <hr
                          style={{
                            backgroundColor: "#0099CC",
                            padding: "1px",
                            margin: "20px 0px",
                          }}
                        />
                      </div>

                      <div
                      // style={{
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "flex-start"
                      // }}
                      ></div>

                      <div
                        style={{
                          padingLeft: 10,
                          paddingRight: 10,
                        }}
                      >
                        <div>
                          Date:{" "}
                          <text style={{ color: "black", fontSize: "14px" }}>
                            <strong>
                              {this.state.date.getDate()}-
                              {this.state.date.getMonth() + 1}-
                              {this.state.date.getFullYear()}
                            </strong>
                          </text>
                          <br />
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          Invoice No.:{" "}
                          <text style={{ color: "black" }}>
                            <strong>{this.state.invoiceNumber}</strong>
                          </text>
                          <br />
                        </div>
                      </div>
                      <div
                      // style={{
                      //   display: "flex",
                      //   flexDirection: "row",
                      //   alignItems: "center",
                      //   justifyContent: "space-between",
                      //   padingLeft: 10,
                      //   paddingRight: 10,
                      //   marginBottom: 10
                      // }}
                      >
                        <p style={{ textAlign: "center", marginTop: "20px" }}>
                          <strong>TAX INVOICE</strong>
                        </p>

                        <MDBTable
                          className="table table-sm dataTable"
                          style={{ borderCollapse: "seperate" }}
                        >
                          <MDBTableHead color="">
                            <tr style={{ color: "", fontSize: 14 }}>
                              <th style={{ width: "25%", borderBottom: "0px" }}>
                                <strong>S.NO</strong>
                              </th>
                              <th style={{ width: "50%", borderBottom: "0px" }}>
                                <strong>Particulars</strong>
                              </th>
                              <th style={{ width: "20%", borderBottom: "0px" }}>
                                <strong>Amount(INR)</strong>
                              </th>
                            </tr>
                          </MDBTableHead>
                          <MDBTableBody>
                            {this.state.myCart.map((data, key) => {
                              if (data.exam_included == false) {
                                return (
                                  <>
                                    {this.renderSujectCostRow(data, key)}
                                    {this.renderTestCostRow(data, key)}
                                  </>
                                );
                              } else {
                                return <>{this.renderExamCostRow(data, key)}</>;
                              }
                            })}
                            {this.renderDiscountRow()}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            {this.renderCostWithoutTax()}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr style={{ fontSize: "14px" }}>
                              <td>
                                <strong>Taxes Applicable</strong>
                              </td>
                              <td>
                                <strong>Rate</strong>
                              </td>
                              <td style={{ textAlign: "right" }}>
                                <strong>GST</strong>
                              </td>
                            </tr>
                            {this.renderTaxColumn()}
                            <tr style={{ fontSize: "14px" }}>
                              <td>
                                <strong>Total</strong>
                              </td>
                              <td></td>
                              <td style={{ textAlign: "end" }}>
                                Rs.{" "}
                                {parseFloat(
                                  this.state.old_total +
                                    (this.state.old_total *
                                      this.state.calculated_tax) /
                                      100
                                ).toFixed(2)}
                              </td>
                            </tr>
                            {/* <tr style={{fontSize:15}}>

<td style={{fontWeight:'bold'}}>Grand Total</td>
<td ></td>
<td style={{textAlign:'end'}}>Rs. {this.state.total_cost}</td>
</tr> */}
                            <tr>
                              <td colSpan="3">
                                In Words:{" "}
                                <strong>
                                  {toWords(
                                    Number(
                                      parseFloat(
                                        this.state.old_total +
                                          (this.state.old_total *
                                            this.state.calculated_tax) /
                                            100
                                      ).toFixed(2)
                                    )
                                  )}{" "}
                                  Only
                                </strong>
                              </td>
                            </tr>
                          </MDBTableBody>
                        </MDBTable>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      className="sign"
                      src={require("../../../Assets/images/sign.svg")}
                    />
                    <text
                      style={{
                        fontSize: 14,
                        textAlign: "end",
                        paddingRight: 5,
                      }}
                    >
                      Authorised Signatory
                    </text>
                  </div>
                </div>
              </PDFExport>
            </MDBModalBody>
            <MDBModalFooter>
              <button
                // onClick={() =>
                //   this.setState({ download_invoice: 0 }, this.exportPDF)
                // }
                onClick={() => {
                  console.log("close");
                  this.setState({ showInvoiceModal: false });
                }}
                className="view-details-button btn-custom"
                type="button"
              >
                Close
              </button>
            </MDBModalFooter>
          </div>
          <div className="d-block d-md-none  mobile-save-reciept-button">
            <button
              onClick={() =>
                this.setState({ download_invoice: 0 }, this.exportPDF)
              }
            >
              Go to My Courses
            </button>
          </div>
        </div>
      </React.Fragment>
    ) : null;
  }

  renderOrderTableContent() {
    return this.state.selected_orders.map((item, index) => {
      return (
        <tr key={index}>
          <td>
            <p
              onClick={() => {
                // console.log("data", item);
                window.localStorage.removeItem("userdata");
                window.localStorage.setItem(
                  "userdata",
                  JSON.stringify({ user_id: item.user_id })
                );
                window.open("/admin/edit-user");
              }}
            >
              {item.name}
            </p>
            <a
              className="order-details-button"
              href={item.invoice_pdf}
              target="_blank"
            >
              {" "}
              View Invoice
            </a>{" "}
          </td>
          <td>{item.phone}</td>
          <td>{item.amount} &#8377;</td>
          <td>{item.invoice_number || "-"}</td>
          <td>{this.commentdate(item.ts)}</td>
          <td>
            {this.renderActiveStatus(item, index)}
            <button onClick={() => this.handleRegenerateInvoice(item)}>
              Regenerate invoice
            </button>
          </td>
        </tr>
      );
    });
  }

  renderOrdersTable() {
    return (
      <MDBTable
        striped
        className="pages_table"
        style={{ backgroundColor: "#F9F9F9" }}
      >
        <MDBTableHead>
          <tr style={{ backgroundColor: "white" }}>
            {/* <th><input type="checkbox" onClick={(event)=> this.setState({all_selected:!this.state.all_selected},()=>this.handleAllChecked(event))   }  checked={this.state.all_selected}  value="checkedall" /></th> */}

            <th className="">Name</th>
            <th>Phone</th>
            <th>Amount</th>
            <th>Invoice Number</th>
            <th>Date created</th>
            <th>Order Status</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>{this.renderOrderTableContent()}</MDBTableBody>
      </MDBTable>
    );
  }

  handleDateSelect(start, end) {
    this.setState({ start_date: start, end_date: end });
  }

  handleOnClose() {
    let startDate = new Date(this.state.start_date);
    //  console.log(startDate.getTime())
    let endDate = new Date(this.state.end_date);
    let selected_orders = this.state.order_data.filter((item) => {
      let order_date = new Date(item.ts);
      if (
        order_date.getTime() >= startDate.getTime() - 86400000 &&
        order_date.getTime() <= endDate.getTime() + 86400000
      ) {
        return item;
      }
    });
    this.setState({ selected_orders, showToggle: false });
  }
  renderModal() {
    if (this.state.toggle == true)
      return (
        <div className="report">
          <a
            href="https://vimasmiham.com/report"
            download
            onClick={(e) => this.handleReportDownload(e)}
          >
            <span className="content">Click here to download report.</span>
          </a>
        </div>
      );
  }

  handleReportDownload(e) {
    this.setState({ toggle: false, loading: false });
  }

  generateReport = () => {
    if (this.state.start_date == null || this.state.end_date == null) {
      swal("Select date range", "", "info");
    } else {
      let start_date = new Date(this.state.start_date).toLocaleDateString();
      start_date = start_date.split("/").reverse();
      var temp = start_date[1];
      start_date[1] = start_date[2];
      start_date[2] = temp;
      start_date = start_date.join("-");
      console.log(start_date);

      let end_date = new Date(this.state.end_date).toLocaleDateString();
      end_date = end_date.split("/").reverse();
      var temp = end_date[1];
      end_date[1] = end_date[2];
      end_date[2] = temp;
      end_date = end_date.join("-");
      console.log(end_date);

      let details = {
        start_date,
        end_date,
      };

      console.log(details);
      let formBody = [];
      for (let property in details) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(details[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      this.setState({
        loading: true,
        response: fetch("https://www.vimasmiham.com/get_report", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: formBody,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson, "orders");
            this.setState({ toggle: true });
          })
          .catch((error) => {
            this.setState({
              loading: false,
            });
            alert("Warning!", "Check your network!", "warning");
            console.log(error);
          }),
      });
    }
  };

  render() {
    console.log(this.state);

    return (
      <div id="order-list-page">
        {this.renderModal()}
        {this.renderInvoiceModal()}

        <AdminNavbar {...this.props} />

        <div className="d-flex">
          <SidebarPage {...this.props} active={4} />

          <div className="order-list-column">
            {this.state.loading ? (
              <div className="sweet-loading">
                <GridLoader
                  css={override}
                  sizeUnit={"px"}
                  size={5}
                  color={"#2fb2eb"}
                  loading={this.state.loading}
                />
              </div>
            ) : (
              <React.Fragment>
                <div className="row align-items-center justify-content-between mx-0 pr-5">
                  <h2 className="pl-2 my-3 mr-3">
                    {" "}
                    Orders{" "}
                    <span
                      className="add_new_button viewallOrder"
                      style={{ fontSize: "18px" }}
                      onClick={() => this.handleViewAll()}
                    >
                      {" "}
                      View all
                    </span>
                    <span
                      className="add_new_button viewallOrder"
                      style={{ fontSize: "18px" }}
                      // onClick={() => this.generateReport()}
                      onClick={() => this.downloadAsExcel()}
                    >
                      {" "}
                      Generate Report
                    </span>{" "}
                  </h2>
                  <RangePicker
                    onDateSelected={(start, end) =>
                      this.handleDateSelect(start, end)
                    }
                    onClose={() => this.handleOnClose()}
                  />
                </div>
                {/* <div className="row mx-0">
                                <Button className="float-right ml-auto mr-5" onClick={()=> this.props.history.push('/admin/add-subject')}>Add New Subject</Button>
                            </div> */}
                <p
                  className={
                    this.state.showToggle
                      ? "text-muted font-italic ml-2 px-1 mt-2 mb-0"
                      : "d-none"
                  }
                >
                  Showing orders for last three days
                </p>
                {this.renderOrdersTable()}
              </React.Fragment>
            )}
          </div>
        </div>
        {this.renderOrderdetailsModal(this.state.modalData)}
      </div>
    );
  }
}

export default OrderPage;
