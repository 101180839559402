import React, { Component } from "react";
import MainFooterPage from "../../Reusable/Footer/footer";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import swal from "sweetalert";
import Timer from "react-compound-timer";
import Fade from "react-reveal";

export default class DemoQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correctAnswers: 0,
      wrongAnswers: 0,
      skippedAnswers: 0,
      selected_answer: null,
      count: "0",
      shouldBlockNavigation: true,
      abc: false,
      testType: "",
      currentIndex: 0,
    };
  }

  componentWillMount() {
    console.log(this.props.history.location.state);
    if (this.props.history.location.state == undefined) {
      this.props.history.push("/start-test");
    } else {
      this.setState({
        questions: this.props.history.location.state.questions.filter(
          (item) => item.question_id
        ),
        count: `${(
          this.props.history.location.state.duration * 60000
        ).toString()}`,
        currentIndex: 0,
        testType: this.props.history.location.state.test_type,
        test_name: this.props.history.location.state.test_name,
      });
    }
  }

  renderAnswers(answers) {
    return answers.map((item) => {
      if (item.answer != null)
        return (
          <div
            key={item.id}
            className="d-flex"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              padding: "10px 15px",
            }}
          >
            <div>
              <input
                type="radio"
                checked={
                  this.state.selected_answer
                    ? item.id === this.state.selected_answer.id
                      ? true
                      : false
                    : false
                }
                onChange={() => this.setState({ selected_answer: item })}
              />
            </div>

            <div>
              <span style={{ marginLeft: "5px" }}>{item.answer}</span>
            </div>
          </div>
        );
    });
  }

  renderQuestion() {
    console.log(this.props.history.location.state);

    if (this.state.questions != undefined) {
      let question = this.state.questions[this.state.currentIndex];
      console.log(question);
      question.visited = true;
      return (
        <div className="question-container">
          <div
            className="question-container-question"
            dangerouslySetInnerHTML={{
              __html: `Q${this.state.currentIndex + 1}. ${
                question.question_title
              }`,
            }}
          />

          {question.type == "objective" ? (
            this.renderAnswers(JSON.parse(question.answers))
          ) : (
            <textarea
              onChange={(e) =>
                this.setState({ selected_answer: e.target.value })
              }
              value={this.state.selected_answer}
              style={{
                borderRadius: "5px",
                display: "block",
                width: "90%",
                margin: "10px auto",
                height: "100px",
              }}
            />
          )}
        </div>
      );
    }
  }

  handleChangeBox(question, index) {
    console.log(question, index);
    if (question.visited) {
      if (!question.selected_answer) {
        this.setState({
          selected_answer: null,
          currentIndex: index,
        });
      } else {
        var sel_answer = question.selected_answer;
        this.setState({
          selected_answer: sel_answer,
          currentIndex: index,
        });
      }
    } else {
      this.setState({
        selected_answer: null,
        currentIndex: index,
      });
    }
  }
  renderQuestionBoxes() {
    if (this.state.questions != undefined) {
      return this.state.questions.map((question, index) => {
        return (
          <div
            className={
              this.state.currentIndex == index
                ? "question-box bg-orange-box"
                : question.visited
                ? question.review
                  ? "question-box review-box"
                  : question.selected_answer == null
                  ? "question-box white-box"
                  : "question-box blue-box"
                : "question-box"
            }
            onClick={() => this.handleChangeBox(question, index)}
          >
            <span>Q{index + 1}</span>
          </div>
        );
      });
    }
  }

  endTest() {
    return this.state.testType == "objective"
      ? (() => {
          this.setState({
            abc: true,
          });
          window.localStorage.setItem(
            "test_questions",
            JSON.stringify(this.state.questions)
          );
          window.close();
          // this.props.history.push({
          //   pathname: "/feedback",
          //   state: {
          //     correct: this.state.correctAnswers,
          //     wrong: this.state.wrongAnswers,
          //     size: this.state.questions.length,
          //     questions: this.state.questions,
          //   },
          // });
        })()
      : this.finishSubjectiveTest();
  }

  finishSubjectiveTest = () => {
    let questions = JSON.stringify(
      this.state.questions.map((item) => ({
        ...item,
        answer: item.selected_answer,
      }))
    );
    window.localStorage.setItem("test_questions", questions);
    let userDetails = JSON.parse(localStorage.getItem("userdata"));
    let user_name = userDetails.name;
    let user_id = userDetails.user_id;
    let user_email = userDetails.email;
    let test_id = localStorage.getItem("test_id");
    let test_questions = questions;
    fetch("https://vimasmiham.com/add_evaluation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_name,
        user_email,
        user_id,
        test_id,
        test_questions,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status == 200) {
          return swal(
            "Thank you! ",
            "Your test has been sent for evaluation. You will receive an email once it is done.",
            "success"
          ).then(() => this.props.history.push("/my-courses"));
        }
      });
  };
  //HANDLE FUNTION

  handleObjectiveQuestionSaveAndNext() {
    let questions = this.state.questions;
    let question = questions[this.state.currentIndex];
    let correct = 0;
    let wrong = 0;
    let skipped = 0;
    if (this.state.selected_answer == null) {
      swal({
        title: "Please select an option",
        text: "",
        icon: "info",
        dangerMode: true,
      }).then(() => {
        return;
      });
    } else {
      question.selected_answer = this.state.selected_answer;
      questions.forEach((element) => {
        if (element.selected_answer) {
          if (element.selected_answer.value == 0) {
            wrong++;
          }
          if (element.selected_answer.value == 1) {
            correct++;
          }
        }
      });
      if (question.review) {
        delete question["review"];
      }

      if (this.state.currentIndex < questions.length - 1) {
        let selected_answer =
          typeof questions[this.state.currentIndex + 1].selected_answer ===
          "undefined"
            ? null
            : questions[this.state.currentIndex + 1].selected_answer;
        this.setState({
          correctAnswers: correct,
          wrongAnswers: wrong,
          selected_answer,
          currentIndex: this.state.currentIndex + 1,
        });
      } else {
        this.endTest();
      }
    }
  }

  handleSubjectiveQuestionSaveAndNext() {
    let calculateAnswersInWords = this.state.selected_answer.split(" ");
    console.log(calculateAnswersInWords);

    if (calculateAnswersInWords.length < 10) {
      return swal(
        "Warning!",
        "Answers should be more than 10 words!",
        "warning"
      );
    }
    console.log(this.state);
    console.log(this.state.selected_answer);
    if (this.state.selected_answer.length == 0) {
      swal({
        title: "Please write an answer",
        text: "",
        icon: "warning",
        dangerMode: true,
      }).then(() => {
        return;
      });
    } else {
      let questions = this.state.questions;
      let question = questions[this.state.currentIndex];
      question.selected_answer = this.state.selected_answer;
      console.log(question);
      delete question.review;

      if (this.state.currentIndex < questions.length - 1) {
        this.setState({
          selected_answer:
            this.state.testType == "subjective"
              ? questions[this.state.currentIndex + 1].answer == undefined
                ? ""
                : questions[this.state.currentIndex + 1].answer
              : questions[this.state.currentIndex + 1].selected_answer ==
                undefined
              ? ""
              : questions[this.state.currentIndex + 1].selected_answer,
          currentIndex: this.state.currentIndex + 1,
        });
      } else {
        this.finishSubjectiveTest();
      }
    }
  }
  handleSaveAndNext() {
    if (this.state.testType == "objective") {
      this.handleObjectiveQuestionSaveAndNext();
    }

    if (this.state.testType == "subjective") {
      // alert('is subjective')
      this.handleSubjectiveQuestionSaveAndNext();
    }

    if (this.state.testType == "mixed") {
      if (
        this.state.questions[this.state.currentIndex] &&
        this.state.questions[this.state.currentIndex].type == "objective"
      ) {
        this.handleObjectiveQuestionSaveAndNext();
      } else {
        this.handleSubjectiveQuestionSaveAndNext();
      }
    }

    console.log(this.state.testType);

    //function declarations
  }

  //(typeof my_obj.someproperties === "undefined")

  handleSkip() {
    let questions = this.state.questions;
    let question = questions[this.state.currentIndex];
    let skipped = 0;
    question.selected_answer = null;

    if (question.review) {
      delete question["review"];
    }
    if (this.state.currentIndex < questions.length - 1) {
      let selected_answer =
        this.state.testType == "objective"
          ? typeof questions[this.state.currentIndex + 1].selected_answer ===
            "undefined"
            ? null
            : questions[this.state.currentIndex + 1].selected_answer
          : typeof questions[this.state.currentIndex + 1].answer === "undefined"
          ? ""
          : questions[this.state.currentIndex + 1].answer;

      this.setState({
        skippedAnswers: skipped,
        selected_answer,
        currentIndex: this.state.currentIndex + 1,
      });
    } else {
      if (this.state.testType == "objective") {
        window.localStorage.setItem(
          "test_questions",
          JSON.stringify(this.state.questions)
        );
        window.close();
        // this.props.history.push({
        //   pathname: "/feedback",
        //   state: {
        //     correct: this.state.correctAnswers,
        //     wrong: this.state.wrongAnswers,
        //     size: this.state.questions.length,
        //   },
        // });
      } else {
        this.finishSubjectiveTest();
      }
    }
  }

  handleBack() {
    let index = this.state.currentIndex;
    let { questions } = this.state;
    let previous = questions[index - 1];

    this.setState({
      selected_answer:
        this.state.testType == "objective"
          ? previous.selected_answer
          : this.state.testType == "subjective"
          ? previous.answer
          : previous.selected_answer,
      currentIndex: index - 1,
    });
  }

  renderTimer() {
    return (
      <Timer
        initialTime={this.state.count}
        direction="backward"
        checkpoints={[
          {
            time: 600,
            callback: () => swal("You have 10 mins left !"),
          },
          {
            time: 0,
            callback: () => {
              swal("Time over !");
              window.localStorage.setItem(
                "test_questions",
                JSON.stringify(this.state.questions)
              );
              window.close();
              // this.props.history.push({
              //   pathname: "/feedback",
              //   state: {
              //     correct: this.state.correctAnswers,
              //     wrong: this.state.wrongAnswers,
              //     size: this.state.questions.length,
              //   },
              // });
            },
          },
        ]}
      >
        {() => (
          <React.Fragment>
            <Timer.Hours /> :&nbsp;
            <Timer.Minutes /> :&nbsp;
            <Timer.Seconds />
          </React.Fragment>
        )}
      </Timer>
    );
  }

  handleReviewAnswer() {
    let questions = this.state.questions;
    let question = this.state.questions[this.state.currentIndex];
    if (this.state.testType == "objective" || this.state.testType == "mixed") {
      question.selected_answer = this.state.selected_answer;
    } else {
      question.answer = this.state.selected_answer;
    }
    question.review = true;
    this.setState({
      questions,
      currentIndex: this.state.currentIndex + 1,
      selected_answer:
        this.state.testType == "objective" || this.state.testType == "mixed"
          ? questions[this.state.currentIndex + 1].selected_answer == undefined
            ? ""
            : questions[this.state.currentIndex + 1].selected_answer
          : questions[this.state.currentIndex + 1].answer == undefined
          ? ""
          : questions[this.state.currentIndex + 1].answer,
    });
  }

  renderLegend() {
    return (
      <div className="legend_container">
        {" "}
        <div className="single_legend_container">
          {" "}
          <div
            style={{ backgroundColor: "#ff8500" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Current Question</div>{" "}
        </div>{" "}
        <div className="single_legend_container">
          <div
            style={{ border: "3px solid #ff8500" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Not Attempted</div>
        </div>{" "}
        <div className="single_legend_container">
          {" "}
          <div
            style={{ backgroundColor: "#0093D9" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Attempted & Saved</div>{" "}
        </div>{" "}
        <div className="single_legend_container">
          <div
            style={{ border: "3px solid rgb(93, 179, 14)" }}
            className="legend_color_div"
          ></div>
          <div className="legend_text">Review Later</div>
        </div>{" "}
      </div>
    );
  }

  componentDidMount() {
    //  window.onbeforeunload = function () {
    //       return  <Prompt message={this.state.abc?'Do you want to submit? ':'All your saved answers will be discarded, are you sure you want to leave?'}  />
    //     }
  }

  componentDidUpdate = () => {
    window.onbeforeunload = undefined;
    // let { shouldBlockNavigation } = this.state;
    // if (shouldBlockNavigation) {
    //   window.onbeforeunload = undefined;
    // } else {
    //   window.onbeforeunload = undefined;
    // }
  };

  checkSubmit() {
    if (this.state.questions != undefined)
      if (this.state.questions.length - 1 == this.state.currentIndex) {
        return false;
      }

    return true;
  }

  deleteAlert = () => {
    swal({
      title: "Are you sure?",
      text: "No data will be saved if you leave the test,",
      buttons: true,
      dangerMode: true,
    }).then((willAdd) => {
      if (willAdd) {
        this.props.history.push("/my-courses");
      } else return;
    });
  };
  render() {
    console.log(this.state, "state");
    return (
      <div
        style={{ display: "flex", flexDirection: "column", overflow: "hidden" }}
      >
        {/* <Prompt  when={this.checkSubmit()}   message='All your saved answers will be discarded, are you sure you want to leave?'    /> */}
        <div className="windowView">
          <NewHeader
            {...this.props}
            backgroundColor="#FFFFFF"
            textcolor="#000"
            width="100%"
            height="auto"
          />
        </div>
        <div className="vsMobView">
          <NewHeader
            {...this.props}
            backgroundColor="white"
            textcolor="#000"
            height="auto"
            width="100%"
          />
        </div>

        <div className="windowView">
          <div id="test-page-container">
            <div className="timer-row d-flex">
              <div className="evalutaion-legend w-100">
                {this.renderLegend()}
              </div>
            </div>
            <div className="evaluation-heading-container">
              <h4 className="pl-1">{this.state.test_name}</h4>
            </div>

            <div className="row">
              <Fade>
                <div className="col-7 pr-0">{this.renderQuestion()}</div>
              </Fade>
              <div className="col-5 pl-1 question-boxes-container">
                {this.renderQuestionBoxes()}
              </div>
            </div>

            <div className="evaluation_buttons_div">
              <div className="d-flex">
                <button
                  className="quit-evaluation-button btn btn-danger p-0"
                  onClick={() => this.deleteAlert()}
                >
                  Leave Test
                </button>

                <button
                  className={
                    this.state.currentIndex == 0
                      ? "d-none"
                      : "evalutaion-back-button btn mx-2 p-0"
                  }
                  onClick={() => this.handleBack()}
                >
                  Back
                </button>
              </div>

              <div className="d-flex">
                <button
                  className={
                    this.state.questions != undefined
                      ? this.state.questions.length - 1 ==
                        this.state.currentIndex
                        ? "d-none"
                        : "review-answer-button btn mx-2 p-0"
                      : ""
                  }
                  onClick={() => this.handleReviewAnswer()}
                >
                  Review later
                </button>

                <button
                  className="skipBtnDiv btn mx-2 p-0"
                  onClick={() => this.handleSkip()}
                >
                  Skip
                  {this.state.questions != undefined
                    ? this.state.questions.length - 1 == this.state.currentIndex
                      ? " and Submit"
                      : ""
                    : ""}
                </button>

                <button
                  className="saveBtnDiv btn mx-2 p-0"
                  onClick={() => this.handleSaveAndNext()}
                >
                  Save and{" "}
                  {this.state.questions != undefined
                    ? this.state.questions.length - 1 == this.state.currentIndex
                      ? "Submit"
                      : "Next"
                    : ""}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="vsMobView">
          <div className="mobile-test-page-container">
            <div className="row">
              <Fade>
                <div className="col-12 pr-0">{this.renderQuestion()}</div>
              </Fade>
              <div className="col-12 pl-1 question-boxes-container">
                {this.renderQuestionBoxes()}
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="quit-evaluation-button btn btn-danger p-0"
                onClick={() => this.deleteAlert()}
              >
                Leave Test
              </button>
              <button
                className="saveBtnDiv btn mx-2 p-0"
                onClick={() => this.handleSaveAndNext()}
              >
                Save and{" "}
                {this.state.questions != undefined
                  ? this.state.questions.length - 1 == this.state.currentIndex
                    ? "Submit"
                    : "Next"
                  : ""}
              </button>
            </div>
          </div>
        </div>
        <div style={{ height: "auto" }} className="windowView">
          <MainFooterPage {...this.props} />
        </div>
        <div className="vsMobView ">
          <StickyFooterPage {...this.props} />
        </div>
      </div>
    );
  }
}
