import React, { Component } from 'react'
import SidebarPage from '../Sidebar/sidebarPage';
import AdminNavbar from '../Navbar/adminNavbar';
import swal from 'sweetalert';
import GridLoader from "react-spinners/GridLoader";
import { MDBTable, MDBTableBody, MDBTableHead,MDBIcon, MDBCollapse } from 'mdbreact';
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 20% 45%;
`;

export default class Funfacts extends Component {               

    constructor(props){
        super(props);
        this.state={
            funfacts:[],
            loding:true
        }
    }

    componentDidMount(){
        this.getFunFacts()
    }

    renderActions(item, index) {
          return (
            <div className="actions_div" style={{ visibility: "" }}>
              <span className="actionstext" onClick={() => this.handleEditFunFacts(item, index)}>Edit</span>
            </div>
          );
        }

    handleEditFunFacts(item){
        window.localStorage.setItem('funfact_item', JSON.stringify(item))
        this.props.history.push('/admin/edit-fun-facts');
    }



    getFunFacts(){
            fetch('https://vimasmiham.com/get_funfacts')
            .then(response=>response.json())
            .then(responseJson=>{
                console.log(responseJson)
                this.setState({funfacts:responseJson.data, loading:false})
            })
            .catch(err=>{
                swal('Warning', 'No Fun Facts found', 'warning')
                this.setState({loading:false})
            })
    }


    renderTableData(item,index){
      
    
            return (     
              <tr key={item.funfacts_id} style={{backgroundColor:`${item.status==1?'#d2f7d2':''}`}}>
            <td>
                {index+1}
            </td>
              <td style={{width:'25%'}} className="tdName textFontSize" >

            <div className='name_div_table'>
              <span style={{fontFamily:'Ubuntu-B',color:'#0073aa'}} dangerouslySetInnerHTML={{__html:item.funfacts_text}}/>
              {this.renderActions(item,index)}
              </div>
             
              </td>
              <td><img style={{width:'50px'}} src={item.funfacts_image} /></td>
              </tr>
               )
        
          }

    render() {
  
        return (
          <div id="subject-page-list">
               <AdminNavbar {...this.props}/>
          <div style={{display:'flex',flexDirection:'row'}}>
          <SidebarPage {...this.props} active={3}/>
          
            <div className='colDefault' style={{width:'100%',backgroundColor:'#F9F9F9'}}>
    
            {this.state.loading?
                         (
                        <div className='sweet-loading'>
                            <GridLoader
                            css={override}
                            sizeUnit={"px"}
                            size={5}
                            color={'#2fb2eb'}
                            loading={this.state.loading}
                            />
                            </div>
                        )
                        :
                        (
                 <React.Fragment>
            <div className="page_heading"><h3 style={{marginBottom:0}}>Fun Facts</h3>
            <span onClick={()=>this.props.history.push('/admin/add-fun-facts')} className='add_new_button'>Add New</span>
            </div> 
            <div>
    
                   <MDBTable  striped className="pages_table" style={{backgroundColor:'#F9F9F9'}}>
                  
                      <tr style={{backgroundColor:'white'}}>
                     
                           <th className="" >No.</th>
                           <th>Fun Fact</th>
                          <th className="textFontSize">Thumbnail</th>
                                                </tr>
                     
                      <MDBTableBody>
                      { this.state.funfacts.map((item,index) => {
                        return this.renderTableData(item,index)
                                  })}
                      </MDBTableBody>
                  </MDBTable> 
      
                 </div>
                 </React.Fragment>
                       )
                    }       
               </div>
            </div>
          </div>
    
      );
       
    }
}
