import React from "react";
import MainFooterPage from "../../Reusable/Footer/footer.jsx";
import NewHeader from "../../Reusable/NewHeader/newHeader";
import { css } from "@emotion/core";
import GridLoader from "react-spinners/GridLoader";
import StickyFooterPage from "../../Reusable/Footer/stickyFooterRegister";
import Image404 from "../../Assets/images/404image.png";
import { Link } from "react-router-dom";
import './Page404.css'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: black;
  margin: 30% 45%;
`;

const imageStyle = {
    margin:'20px auto',
    width:'100vw',
    maxWidth:'600px'
};

const redirectButtons = {
    textTransform:'capitalize',
    borderRadius:'4px',
    fontSize: '16px',
    fontFamily: 'Ubuntu-M',
    color: '#908b8b'
}

const headingSubText = {
    fontSize:'18px',
    fontFamily:'Ubuntu-R'
}


const bottomText = {
    fontSize: '20px',
    fontFamily: 'Ubuntu-L',
    color: '#4e4e4e',
    margin: '30px 0 20px 0px'
}

const bottomSpan = {
    fontSize: '20px',
    fontFamily: 'Ubuntu-M',
    color: '#4e4e4e',
    margin: '30px 0 20px 0px'
}

export default class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount(){
      this.setState({loading:false})
  }


  render() {
    if (this.state.loading) {
      return (
        <div className="sweet-loading-main">
          <GridLoader
            css={override}
            sizeUnit={"px"}
            size={5}
            color={"#2fb2eb"}
            loading={this.state.loading}
          />
        </div>
      );
    } else {
      return (
        <div
          id="color-footer"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
          <div className="">
            <NewHeader
              {...this.props}
              backgroundColor="white"
              textcolor="#000"
              height="auto"
              width="100%"
            />
          </div>

          <div className="exam-news-main mb-5 w-100 d-flex flex-column align-items-center justify-content-center mt-2 error-page-margin-top">
              <h1>Page Not found!</h1>
          <p className="text-muted font-italic" style={headingSubText}>It seems you have landed on a page that does not exist. Why not try accessing one of the links below.</p>
            <img src={Image404} alt="Page not found!" style={imageStyle} />
            <h5 className="" style={bottomText}>Continue to <span style={bottomSpan}>check out Vima Smiham </span> and keep learning through one of these links:</h5>
            <div className="redirect-boxes">
                <Link className="btn" style={redirectButtons} to="/acts_and_regulations" >Acts &amp; Regulations</Link>
                <Link className="btn" style={redirectButtons} to="/categories/promotional-exams">Promotional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/categories/professional-exams" >Professional Exams</Link>
                <Link className="btn" style={redirectButtons} to="/latest-news" >Latest News</Link>
                <Link className="btn" style={redirectButtons} to="/test-series" >Test Series</Link>
                <Link className="btn" style={redirectButtons} to="/all-faculty" >All Faculty</Link>
                <Link className="btn" style={redirectButtons} to="/upcoming-exams" >Upcoming Exams</Link>
            
            </div>
          </div>

          <div className="windowView">
            <MainFooterPage />
          </div>

          <div className="vsMobView ">
            <StickyFooterPage {...this.props} />
          </div>
        </div>
      );
    }
  }
}
